import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Checkbox,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import MailRoundedIcon from "@mui/icons-material/MailRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import "./Strategy.scss";
import { NavLink, useNavigate } from "react-router-dom";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  getStratergy,
  unDeployStrategy,
} from "../../redux/actions/stratergyAction";
import { generatePopup } from "../../utils/popup";
import CloseIcon from "@mui/icons-material/Close";

export default function Passiveincome(props) {
  const [open, setOpen] = useState();
  const location = useLocation();
  const data = location?.state?.data;
  const name = location?.state?.name;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStratergy());
  }, []);

  const stretegies = useSelector((state) => state?.Stratergy?.stratergy);

  const list =
    name === "marketplace"
      ? data
      : stretegies?.find((e) => e?.id === data?.stratergy);
  const navigate = useNavigate();

  const handleUndeploy = () => {
    dispatch(unDeployStrategy({ id: data?.id })).then((res) => {
      setOpen(false);
      if (res) {
        if (res?.status === 200) {
          generatePopup("success", res?.data?.message);
          navigate("/strategy");
        } else {
          generatePopup("error", "can not undeploy strategy");
        }
      }
    });
  };
  const handleDeploy = () => {
    dispatch(unDeployStrategy({ id: data?.id })).then((res) => {
      if (res) {
        generatePopup("success", res?.data?.message);
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <NavLink to={"/strategy"} className="backto_home">
        <KeyboardBackspaceOutlinedIcon />
        Home
      </NavLink>
      <Box className="passive_income">
        {/* <Typography component={"p"} className="created">
          Created : | Year Ago | Live deployment : 387
        </Typography> */}

        <Box className="strategy_name_box">
          <Box className="strategy_name">
            <Box className="by_from">
              <Typography component={"p"}>Created At :</Typography>
              <Typography component={"span"} className="by_name">
                {dayjs().format("DD-MM-YYYY")}
              </Typography>
            </Box>
            <Typography component={"h5"}>{list?.full_name}</Typography>
            <Box className="by_from">
              <Typography component={"p"}>By :</Typography>
              <Typography component={"span"} className="by_name">
                {list?.owner}
              </Typography>
            </Box>
          </Box>

          <Box>
            {/* <Button className="formSolid-btn favorite">
              <Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />} />
            </Button> */}
            {name === "marketplace" && (
              <Button className="formSolid-btn deployed">Deploy</Button>
            )}
            {name === "myStrategy" && (
              <Button
                onClick={() => {
                  setOpen(true);
                }}
                className="formSolid-btn deployed undeployed"
              >
                Undeploy
              </Button>
            )}
          </Box>
        </Box>
        <Box className="strategy_desc">
          <Typography component={"h4"}>Strategy Tags</Typography>
        </Box>
        <Box className="strategy_badges" sx={{ marginTop: "1rem !important" }}>
          {list?.tag?.map((e) => (
            <Typography component={"p"} className="badges">
              {e}
            </Typography>
          ))}
        </Box>

        <Box className="strategy_desc">
          <Typography component={"h4"}>Strategy Description</Typography>
          <Typography component={"p"}>{list?.stratergy_description}</Typography>
        </Box>
        <Box className="strategy_desc">
          <Typography component={"h4"}>
            The Strategy would perform well in following segments :
          </Typography>
        </Box>

        <Box className="strategy_badges" sx={{ marginTop: "1rem !important" }}>
          {list?.segment?.map((e) => (
            <Typography component={"p"} className="badges">
              {e}
            </Typography>
          ))}
        </Box>

        {/*
        <Box className="strategy_desc">
          <Typography component={"h4"}>Disclaimer</Typography>
          <Typography component={"p"}>
            Tradearth does not display any information about retums or profit of
            any strategy. The responsibility of all content in description of
            strategies solely lies with the creator of the strategy
          </Typography>
        </Box>

        <Box className="strategy_desc">
          <Typography component={"h4"}>Strategy description</Typography>
          <Typography component={"p"}>
            This strategy is designed with Nifty & Bank Nifty ATM straddles.
            This is an intraday option selling strategy which sells total 4
            legs. According to the market movement, loss making logs exits and
            takes fresh straddles on reversals. We earn theta decay if market is
            range bound. If market is trending, the strategy becomes
            directional.
          </Typography>
        </Box>

        <Box className="strategy_desc">
          <Typography component={"h5"}>
            The strategy would perform well in following situations:
          </Typography>
          <Typography component={"p"}>1) Fall in Vix</Typography>
          <Typography component={"p"}>2) Range Bound movements</Typography>
          <Typography component={"p"}>
            3) V shape movements to an extent
          </Typography>
          <Typography component={"p"}>
            4) Directional Move with fall in premiums
          </Typography>
        </Box>

        <Box className="strategy_desc">
          <Typography component={"h5"}>
            The strategy would struggle in following situations:
          </Typography>
          <Typography component={"p"}>1) Rise in vix</Typography>
          <Typography component={"p"}>
            2)High Volatility in market with increasing premiums
          </Typography>
          <Typography component={"p"}>3)No decay in premiums</Typography>
        </Box> */}

        <Box className="strategy_desc">
          <Typography component={"h3"}>Frequently Asked Questions</Typography>
        </Box>

        <Box className="strategy_desc">
          <Typography component={"h5"}>Capital Requirement: </Typography>
          <Typography component={"p"}>
            {list?.captial_requirement} required for 1 lot with risk
            management,Please check with your broker for exact margin
            requirement.
          </Typography>
        </Box>

        {/* <Box className="strategy_desc">
          <Typography component={"p"}>
            Capital requirement comes down to {list?.target} Rs by using this
            hedge strategy.{" "}
            <Typography component={"a"} href="#">
              https://trade-panel.tradearth.in/strategy
            </Typography>
          </Typography>
        </Box> */}

        <Box className="strategy_desc">
          <Typography component={"h5"}>Entry and Exit times: </Typography>
          <Typography component={"p"}>
            Entry time is {list?.entry_time} hrs and Exit time is{" "}
            {list?.exit_time} hrs.
          </Typography>
        </Box>

        <Box className="strategy_desc">
          <Typography component={"h5"}>Exit Target: </Typography>
          <Typography component={"p"}>
            Min Target is {list?.target} rs approx per day.
          </Typography>
        </Box>
        <Box className="strategy_desc">
          <Typography component={"h5"}>Entry Stop loss: </Typography>
          <Typography component={"p"}>
            Max Stop loss is {list?.sl} rs per day.
          </Typography>
        </Box>

        <Box className="strategy_desc">
          <Typography component={"h5"}>Disclaimer</Typography>
          <Typography component={"p"}>
            All algorithms provided by TradeArth are based on backtested data,
            but there is no guarantee of their future performance.
          </Typography>
          <Typography component={"p"}>
            Past performance is not indicative of future results, We are not
            responsible for your profit or loss. Although this strategy is fully
            automated, you are advised to keep a track on your account to
            monitor any deviations or errors.
          </Typography>
          <Typography component={"p"}>
            Any algorithm running in an automated system is agreed upon with the
            user before deployment, and Trade Arth does not accept liability for
            any losses generated by the use of these algorithms.
          </Typography>
          <Typography component={"p"}>
            It is essential to understand that trading and investing in
            financial markets carry inherent risks, and you should carefully
            consider your financial situation, risk tolerance, and investment
            goals before making any decisions. Please consult your financial
            advisor before investing.
          </Typography>
        </Box>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        className="commonModal squareOff"
        fullWidth
      >
        <Box className="modalHeader">
          <Typography component={"h4"}>
            {name === "myStrategy" ? "Undeploy " : "Deploy "} Strategy
          </Typography>
          <Button onClick={handleClose} className="closeModal">
            <CloseIcon />
          </Button>
        </Box>
        <DialogContent sx={{ padding: "0" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="alertIcons">
              <svg
                width="1052"
                height="1052"
                viewBox="0 0 1052 1052"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M558 334C558 316.3 543.7 302 526 302C508.3 302 494 316.3 494 334V590C494 607.7 508.3 622 526 622C543.7 622 558 607.7 558 590V334ZM526 750C536.609 750 546.783 745.786 554.284 738.284C561.786 730.783 566 720.609 566 710C566 699.391 561.786 689.217 554.284 681.716C546.783 674.214 536.609 670 526 670C515.391 670 505.217 674.214 497.716 681.716C490.214 689.217 486 699.391 486 710C486 720.609 490.214 730.783 497.716 738.284C505.217 745.786 515.391 750 526 750Z"
                  fill="#4987FE"
                />
                <circle
                  cx="526"
                  cy="526"
                  r="507"
                  stroke="#5086EE"
                  stroke-width="38"
                />
              </svg>
            </Box>
            <Typography
              component={"h4"}
              sx={{
                fontSize: "1.8rem",
                textAlign: "center",
                marginTop: "2rem",
              }}
              className="alertText"
            >
              Are You Sure You Want to{" "}
              {name === "myStrategy" ? "Undeploy " : "Deploy "} Strategy !
            </Typography>

            <Box sx={{ display: { xs: "flex" } }}>
              <Button
                onClick={handleClose}
                className="modal-btn btn-danger"
                sx={{ marginRight: "0.5rem" }}
              >
                Discard
              </Button>
              <Button
                onClick={name === "myStrategy" ? handleUndeploy : handleDeploy}
                className="modal-btn btn-primary"
                sx={{ marginLeft: "0.5rem" }}
              >
                Confirm
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
