import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";
import AdbIcon from "@mui/icons-material/Adb";
import "../Dashboard.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardCount,
  getTodayCount,
} from "../../../../redux/actions/adminActions";
import copy from "copy-text-to-clipboard";
import dayjs from "dayjs";
import { PANEL_API_URL, SITE_URL } from "../../../../config";
import { generatePopup } from "../../../../utils/popup";

export default function Dashboard() {
  const defaultData = [
    { category: "Overall Customer", customer: "00" },
    {
      category: "Subscription Active",
      customer: "00",
    },
    { category: "Vendors", customer: "00" },
    { category: "Payment", customer: "00" },
    { category: "Commission", customer: "00" },
  ];
  const [month, setmonth] = React.useState([]);
  const [year, setyear] = React.useState("select");
  const [todayCounts, setTodayCounts] = React.useState(defaultData);
  const [dashBoardCounts, setDashBoardCounts] = React.useState(defaultData);

  const handleChangemonth = (event) => {
    const {
      target: { value },
    } = event;

    setmonth(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeyear = (event) => {
    const {
      target: { value },
    } = event;

    if (value === "select") {
      setyear("select");
    } else {
      setyear(
        // On autofill we get a stringified value.
        typeof value === "string" ? JSON.parse(value) : value
      );
    }
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const years = [
    "2000",
    "2001",
    "2002",
    "2004",
    "2005",
    "2006",
    "2007",
    "2008",
    "2009",
    "2010",
    "2011",
    "2012",
    "2013",
    "2014",
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
    "2023",
    "2024",
  ];

  const icon = (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.25 10.75C10.2389 10.75 11.2056 10.4568 12.0279 9.90735C12.8501 9.35794 13.491 8.57705 13.8694 7.66342C14.2478 6.74979 14.3469 5.74446 14.1539 4.77455C13.961 3.80465 13.4848 2.91373 12.7855 2.21447C12.0863 1.51521 11.1954 1.039 10.2255 0.846076C9.25555 0.65315 8.25021 0.752166 7.33658 1.1306C6.42295 1.50904 5.64206 2.14991 5.09265 2.97215C4.54324 3.7944 4.25 4.7611 4.25 5.75C4.25 7.07608 4.77678 8.34785 5.71447 9.28554C6.65215 10.2232 7.92392 10.75 9.25 10.75Z"
        fill="white"
      />
      <path
        d="M18.75 13.5C19.4917 13.5 20.2167 13.2801 20.8334 12.868C21.4501 12.456 21.9307 11.8703 22.2145 11.1851C22.4984 10.4998 22.5726 9.74584 22.4279 9.01841C22.2833 8.29098 21.9261 7.6228 21.4017 7.09835C20.8772 6.5739 20.209 6.21675 19.4816 6.07206C18.7542 5.92736 18.0002 6.00162 17.3149 6.28545C16.6297 6.56928 16.044 7.04993 15.632 7.66661C15.2199 8.2833 15 9.00832 15 9.75C15 10.7446 15.3951 11.6984 16.0983 12.4017C16.8016 13.1049 17.7554 13.5 18.75 13.5Z"
        fill="white"
      />
      <path
        d="M18 22.0001C18 21.3425 24.8995 21.8684 25.1339 21.634C25.3683 21.3996 25.5 21.0817 25.5 20.7501C25.499 19.5819 25.1706 18.4373 24.552 17.4462C23.9334 16.4552 23.0495 15.6573 22.0004 15.1432C20.9514 14.6291 19.7792 14.4193 18.617 14.5376C17.4547 14.656 16.3489 15.0977 15.425 15.8126C14.2006 14.5931 12.6426 13.7634 10.9473 13.4282C9.25199 13.0929 7.4954 13.2672 5.89903 13.929C4.30267 14.5909 2.93802 15.7106 1.97718 17.1469C1.01634 18.5833 0.502338 20.272 0.5 22.0001C0.5 22.3317 0.631696 22.6496 0.866116 22.884C1.10054 23.1184 1.41848 23.2501 1.75 23.2501H16.75C17.0815 23.2501 17.3995 23.1184 17.6339 22.884C17.8683 22.6496 18 22.3317 18 22.0001Z"
        fill="white"
      />
    </svg>
  );
  const dashboardData = useSelector((state) => state?.Admin?.dashboardCount);
  const todayData = useSelector((state) => state?.Admin?.todayCount);

  useEffect(() => {
    setTodayCounts([
      {
        category: "Overall Customer",
        customer: todayData?.totalcustomer || "00",
      },
      {
        category: "Subscription Active",
        customer: todayData?.totalactive || "00",
      },
      { category: "Vendors", customer: todayData?.totalvendors || "00" },
      { category: "Payment", customer: todayData?.total_amount || "00" },
      { category: "Commission", customer: todayData?.total_comission || "00" },
    ]);
  }, [todayData]);

  useEffect(() => {
    setDashBoardCounts([
      {
        category: "Overall Customer",
        customer: dashboardData?.totalcustomer || "00",
      },
      {
        category: "Subscription Active",
        customer: dashboardData?.totalactive || "00",
      },
      { category: "Vendors", customer: dashboardData?.totalvendors || "00" },
      { category: "Payment", customer: dashboardData?.total_amount || "00" },
      {
        category: "Commission",
        customer: dashboardData?.total_comission || "00",
      },
    ]);
  }, [dashboardData]);

  const android = (
    <Typography sx={{ fontSize: "1.4rem" }}>Android App</Typography>
  );

  const userData = useSelector((state) => state?.Auth?.authProfile?.[0]);

  const referLink = (
    <Typography
      sx={{ fontSize: "1.4rem" }}
    >{`${PANEL_API_URL}/signup?referal_code=${userData?.username}`}</Typography>
  );

  const referLinkVendor = (
    <Typography
      sx={{ fontSize: "1.4rem" }}
    >{`${SITE_URL}/affiliate?referal_code=${userData?.username}`}</Typography>
  );

  const IOS = <Typography sx={{ fontSize: "1.4rem" }}>IOS App</Typography>;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getDashboardCount({
        month:
          month?.length > 0
            ? month
            : ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
        year: year === 0 ? "select" : year,
      })
    );
  }, [month, year]);

  useEffect(() => {
    dispatch(getTodayCount());
  }, []);

  const copyLink = () => {
    copy(`${PANEL_API_URL}signup?referal_code=${userData?.username}`);
    generatePopup("success", "Refer Link copied!");
  };
  const copyVendorLink = () => {
    copy(`${SITE_URL}affiliate?referal_code=${userData?.refrecode}`);
    generatePopup("success", "Refer Link copied!");
  };

  return (
    <>
      <Box className="overAll-view">
        <Box
          sx={{
            display: {
              xs: "block",
              md: "flex",
            },
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography component={"h2"} className="adminD-title">
            Overall View
          </Typography>

          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              alignItems: "center",
              justifyContent: {
                xs: "left",
                md: "right",
                marginBottom: { xs: 2, md: 0 },
              },
              paddingRight: { xs: 0, md: 4 },
            }}
          >
            <Box className="selectionDiv select_check">
              <Typography component={"label"} className="label">
                Select Month:{" "}
              </Typography>
              <FormControl
                sx={{ maxWidth: 180, width: 180 }}
                className="dropdown-ap"
              >
                <Select
                  multiple
                  value={month}
                  onChange={handleChangemonth}
                  renderValue={(selected) =>
                    month?.map((i) => months?.[i - 1])?.join(",")
                  }
                  defaultValue={"Select Month"}
                >
                  {months.map((name, index) => (
                    <MenuItem key={index} value={String(index + 1)}>
                      <Checkbox checked={month?.includes(String(index + 1))} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box className="selectionDiv select_check">
              <Typography sx={{}} component={"label"} className="label">
                Select Year:{" "}
              </Typography>
              <FormControl
                sx={{ maxWidth: 180, width: 180 }}
                className="dropdown-ap inputFields"
              >
                <Select
                  inputProps={{ sx: { padding: "auto" } }}
                  value={year}
                  onChange={handleChangeyear}
                  className="dropdown"
                >
                  <MenuItem value={"select"} key={"select"}>
                    <ListItemText primary={"Select"} />
                  </MenuItem>
                  {years.map((vals, index) => (
                    <MenuItem value={vals} key={index}>
                      <ListItemText primary={vals} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>

        <Box className="overAll-flex">
          {dashBoardCounts?.map((overalls, index) => (
            <Box className="overAll-item" key={index}>
              <Box className="overAll-left">
                <Box className="overAll-icon">{icon}</Box>
                <Typography component={"p"}>{overalls.category}</Typography>
              </Box>
              <Box className="overAll-count">{overalls.customer}</Box>
            </Box>
          ))}
        </Box>
      </Box>

      <Box className="overAll-view">
        <Typography component={"h2"} className="adminD-title">
          Today View
        </Typography>

        <Box className="overAll-flex">
          {todayCounts?.map((overalls, index) => (
            <Box className="overAll-item" key={index}>
              <Box className="overAll-left">
                <Box className="overAll-icon">{icon}</Box>
                <Typography component={"p"}>{overalls?.category}</Typography>
              </Box>
              <Box className="overAll-count">{overalls?.customer}</Box>
            </Box>
          ))}
        </Box>
      </Box>

      <Box className="formItems" sx={{ marginTop: 4 }}>
        <Tooltip title={referLink} arrow placement="top">
          <Button onClick={copyLink} className="formSolid-btn">
            Copy Your Refer Link
          </Button>
        </Tooltip>
        {(userData?.is_staff || userData?.is_powerbroker) && (
          <Tooltip title={referLinkVendor} arrow placement="top">
            <Button onClick={copyVendorLink} className="formSolid-btn">
              Copy Your Refer Link For Vendor
            </Button>
          </Tooltip>
        )}
        <Tooltip title={android} arrow placement="top">
          <Button
            onClick={() => {
              window.open(
                "https://play.google.com/store/apps/details?id=dotin.tradearth",
                "_blank"
              );
            }}
            className="download-btn solidButton android"
            sx={{ marginLeft: 1, backgroundColor: "#008000 !important" }}
          >
            <AdbIcon />
          </Button>
        </Tooltip>
        <Tooltip title={IOS} arrow placement="top">
          <Button
            onClick={() => {
              window.open(
                "https://apps.apple.com/in/app/trade-arth/id6470361389",
                "_blank"
              );
            }}
            className="download-btn solidButton ios"
            sx={{ marginLeft: 1 }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 384 512"
            >
              <path
                d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                fill="currentColor"
              />
            </svg>
          </Button>
        </Tooltip>
      </Box>
    </>
  );
}
