import React, { useState } from "react";
import {
  Box,
  Tooltip,
  Button,
  TextField,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import "../../Dashboard/Tradedetails/Tradedetails.scss";
import Dropdown from "../../../Dropdown/Dropdown";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Table from "../../../Table/Table";
import moment from "moment";
import { searchInvoice } from "../../../../redux/actions/brokerAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import dayjs from "dayjs";

export default function Paymentreport() {
  // Dropdown
  const drpValue = ["select", "Cash", "UPI"];
  const drpValue2 = ["select", "cash", "Failed"];

  const search = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      Add Data
    </Typography>
  );

  const download = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      Download
    </Typography>
  );
  const dates = (
    <Box sx={{ width: "20rem", margin: "auto" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker className="datePicker" />
      </LocalizationProvider>
    </Box>
  );
  const reportData = useSelector((state) => state?.Broker?.paymentReport);

  const col = [
    "Id",
    "Customer Id",
    "Owner",
    "SubBroker",
    "Fees",
    "Comission",
    "Comission Type",
    "Payment",
    "GST Commision",
    "Slab",
    "Method",
    "Plan Activated",
    "Plan Expired",
    "Plan Status",
    "Status",
    "Date",
  ];

  const rows = reportData?.map((e, index) => {
    return {
      id: index + 1,
      Customer_Id: e?.customer_id,
      Owner: e?.owner,
      SubBroker: e?.subbroker !== "NA" ? e?.subbroker : "-",
      Fees: 0,
      Comission: e?.comission,
      ComissionType: e?.comission_type,
      Payment: e?.total_amount_paid,
      GST_Commision: e?.gst_comission,
      Slab: e?.slab,
      Method: e?.method,
      Plan_Activated: e?.plan_activate,
      Plan_Expired: e?.plan_expire,
      Plan_Status: e?.plan_status,
      Status: (
        <Typography
          sx={{
            color: e?.status?.toUpperCase() === "COMPLETE" ? "#24a959" : "red",
            fontWeight: "bold",
            // fontSize: "12px",
          }}
        >
          {e?.status}
        </Typography>
      ),
      Date: e?.date,
    };
  });

  const newArray = rows.map((obj) => {
    console.log("obj?.Status :>> ", obj?.Status);
    return {
      ...obj,
      Status: obj?.Status?.props?.children,
    };
  });

  const [todate, setTodate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [fromdate, setFromdate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [customerid, setCustomerId] = useState();
  const [type, setType] = useState("select");
  const [searchvalue, setSearchvalue] = useState("");

  //select to date
  const handleTodate = (date) => {
    setTodate(dayjs(date).format("YYYY-MM-DD"));
  };

  //select fromdate
  const handleFromdate = (date) => {
    setFromdate(dayjs(date).format("YYYY-MM-DD"));
  };

  //select CustomerId
  const handleCustomerId = (event) => {
    setCustomerId(event.target.value);
  };

  //select type
  const handelType = (event) => {
    setType(event.target.value);
  };

  //input Searchvalue
  const handleSearchvalue = (event) => {
    setSearchvalue(event.target.value);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const obj = {
      fromdate: fromdate,
      todate: todate,
      customerid: searchvalue.trim() === "" ? "select" : searchvalue,
      method: "select",
    };
    dispatch(searchInvoice(obj)).then((res) => {
      console.log("res", res);
    });
  }, [fromdate, todate]);

  const handleSearch = () => {
    const obj = {
      fromdate: fromdate,
      todate: todate,
      customerid: searchvalue.trim() === "" ? "select" : searchvalue,
      method: "select",
    };
    dispatch(searchInvoice(obj));
  };

  function downloadCSV(data) {
    // Step 2: Convert the JSON data to CSV format
    const csvContent =
      "data:text/csv;charset=utf-8," +
      Object.keys(data[0])
        .map((key) => key)
        .join(",") +
      "\n" +
      data
        .map((row) =>
          Object.values(row)
            .map((value) => `"${value}"`)
            .join(",")
        )
        .join("\n");

    // Step 3: Create a data URI and trigger the download
    const encodedURI = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedURI);
    link.setAttribute("download", "profit_and_loss_report .csv");
    document.body.appendChild(link);
    link.click();
  }

  return (
    <>
      <Box className="tabelBox">
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12}>
            <Box
              className="selectiondiv-box m-rb"
              sx={{ paddingLeft: { xs: "0", md: "1rem " } }}
            >
              <Box className="selectionDiv searchFlex">
                <Typography component={"label"} className="label">
                  From Date:{" "}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={dayjs(fromdate)}
                    className="datePicker"
                    onChange={handleFromdate}
                  />
                </LocalizationProvider>
              </Box>
              <Box className="selectionDiv searchFlex">
                <Typography component={"label"} className="label">
                  To Date:{" "}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={dayjs(todate)}
                    className="datePicker"
                    onChange={handleTodate}
                  />
                </LocalizationProvider>
              </Box>

              {/* <Dropdown
                val={drpValue2}
                handleSelect={handleCustomerId}
                value={customerid}
                title={"Customer ID"}
              /> */}
              {/* <Dropdown
                val={drpValue}
                handleSelect={handelType}
                value={type}
                title={"Payment Type"}
              /> */}
              <Box className="selectionDiv searchFlex">
                <Box className="inputFields" sx={{ margin: "0" }}>
                  <TextField
                    placeholder="Customer Id"
                    onChange={(event) => handleSearchvalue(event)}
                  />
                  {/* <Box className="searchIcon">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.85722 14.7144C11.6444 14.7144 14.7144 11.6444 14.7144 7.85722C14.7144 4.07008 11.6444 1 7.85722 1C4.07008 1 1 4.07008 1 7.85722C1 11.6444 4.07008 14.7144 7.85722 14.7144Z"
                        stroke="#6A6D78"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13 13.0001L17 17.0002"
                        stroke="#6A6D78"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Box> */}
                </Box>
                <Tooltip title={search} arrow>
                  <Button
                    className="download-btn solidButton"
                    sx={{ marginLeft: "1rem" }}
                    onClick={handleSearch}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                        fill="#FFFFFF"
                      />
                    </svg>
                  </Button>
                </Tooltip>
                {rows?.length > 0 && (
                  <Tooltip title={download} arrow>
                    <Button
                      onClick={() => downloadCSV(newArray)}
                      className="download-btn solidButton"
                      sx={{ marginLeft: 1 }}
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17 11.6669V15.2224C17 15.6939 16.8127 16.1461 16.4793 16.4795C16.1459 16.8129 15.6937 17.0002 15.2222 17.0002H2.77778C2.30628 17.0002 1.8541 16.8129 1.5207 16.4795C1.1873 16.1461 1 15.6939 1 15.2224V11.6669"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4.55542 7.22229L8.99987 11.6667L13.4443 7.22229"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9 11.6667V1"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Button>
                  </Tooltip>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Table col={col} rows={rows} />
      </Box>
    </>
  );
}
