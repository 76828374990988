import * as actionTypes from "../actionTypes";

const initState = {
  signaldetails: [],
  positionalSignal: [],
  brokerDetails: [],
  error: "",
  closePosition: [],
  livePositions: [],
  orderBookData: [],
  segmentList: [],
  copytrade: [],
  copyPostion: [],
  paperPositions: [],
  paperAdminPositions: [],
  liveAdminPositions: [],
  strategyFlag: [],
  strategyDetail: [],
  copyTradeDetails: [],
  pnlReport: [],
  brokerPosition: [],
  watchlist: [],
};

const store = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.SIGNAL_DETAILS_INIT:
      return {
        ...state,
        error: "",
      };
    case actionTypes.SIGNAL_DETAILS_SUCCESS:
      return {
        ...state,
        signaldetails: action.payload,
        error: "",
      };
    case actionTypes.SIGNAL_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.GET_LIVE_POSITIONS_SUCCESS:
      return {
        ...state,
        livePositions: action.payload,
      };
    case actionTypes.GET_STRATEGY_FLAG_SUCCESS:
      return {
        ...state,
        strategyFlag: action.payload,
      };
    case actionTypes.GET_STRATEGY_DETAILS_SUCCESS:
      return {
        ...state,
        strategyDetail: action.payload,
      };
    case actionTypes.GET_COPYTRADE_DETAILS_SUCCESS:
      return {
        ...state,
        copyTradeDetails: action.payload,
      };
    case actionTypes.GET_PAPER_POSITIONS_SUCCESS:
      return {
        ...state,
        paperPositions: action.payload,
      };
    case actionTypes.GET_ADMIN_PAPER_POSITIONS_SUCCESS:
      return {
        ...state,
        paperAdminPositions: action.payload,
      };
    case actionTypes.GET_ADMIN_LIVE_POSITIONS_SUCCESS:
      return {
        ...state,
        liveAdminPositions: action.payload,
      };
    case actionTypes.OPEN_POSITION_DATA_SUCCESS:
      return {
        ...state,
        brokerPosition: action.payload,
      };
    case actionTypes.GET_LIVE_TRADE_DETAILS_SUCCESS:
      return {
        ...state,
        closePosition: action.payload,
      };
    case actionTypes.PAPER_TRADE_DETAILS_SUCCESS:
      return {
        ...state,
        closePosition: action.payload,
      };
    case actionTypes.GET_LIVE_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orderBookData: action.payload,
      };
    case actionTypes.PAPER_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orderBookData: action.payload,
        error: "",
      };
    case actionTypes.GET_SEGMENT_SUCCESS:
      return {
        ...state,
        segmentList: action.payload,
        error: "",
      };
    case actionTypes.GET_BROKERS_SUCCESS:
      return {
        ...state,
        brokerDetails: action.payload,
        error: "",
      };
    case actionTypes.GET_COPYTRADEDETATIS_SUCCESS:
      return {
        ...state,
        copytrade: action.payload,
        error: "",
      };
    case actionTypes.GET_COPYPOSITIONDETAILS_SUCCESS:
      return {
        ...state,
        copyPostion: action.payload,
        error: "",
      };
    case actionTypes.ADMIN_PNL_SUCCESS:
      return {
        ...state,
        pnlReport: action.payload,
        error: "",
      };
    case actionTypes.ADMIN_PAPER_PNL_SUCCESS:
      return {
        ...state,
        pnlReport: action.payload,
        error: "",
      };
    case actionTypes.GET_POSITIONAL_SIGNAL_SUCCESS:
      return {
        ...state,
        positionalSignal: action.payload,
      };
    case actionTypes.GET_SYMBOL_DATA_SUCCESS:
      return {
        ...state,
        watchlist: action.payload,
      };

    default:
      return state;
  }
};

export default store;
