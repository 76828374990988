import * as actionTypes from "../actionTypes";
import axios from "axios";
import { API_URL, PAPER_API_URL } from "../../config";
import { generatePopup } from "../../utils/popup";

export const liveProfitDetails = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.LIVE_PROFIT_DETAILS_INIT,
    });
    axios
      .post(`${API_URL}/position/profitdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.LIVE_PROFIT_DETAILS_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.LIVE_PROFIT_DETAILS_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get live profit details.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.LIVE_PROFIT_DETAILS_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const paperProfitDetails = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.PAPER_PROFIT_DETAILS_INIT,
    });
    axios
      .post(`${PAPER_API_URL}/position/paperprofitdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.PAPER_PROFIT_DETAILS_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.PAPER_PROFIT_DETAILS_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get paper profit details.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.PAPER_PROFIT_DETAILS_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};
