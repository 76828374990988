import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  Tooltip,
  Dialog,
  DialogContent,
  DialogContentText,
  TextField,
  Autocomplete,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import Table from "../../../Table/Table";
import close from "../../../../images/close.png";
import Dropdown from "../../../Dropdown/Dropdown";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  adminCloseOrder,
  adminOpenPosition,
  closeAllAdminOrder,
  closeAllAdminPaperOrder,
  getSegment,
} from "../../../../redux/actions/positionAction";
import { getStratergy } from "../../../../redux/actions/stratergyAction";
import { generatePopup } from "../../../../utils/popup";
import { getUserRefreCode } from "../../../../redux/actions/brokerAction";
import { messageEvent, socket } from "../../../../hooks/useSocket";
import { socketOpen } from "../../../../redux/actions/commonActions";

export default function Livepostion() {
  const sqyuareoff = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      Square Off
    </Typography>
  );
  const search = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      Search
    </Typography>
  );
  const download = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      Download
    </Typography>
  );

  // const searchTip = (
  //   <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
  //     Add Data
  //   </Typography>
  // );

  const [open, setOpen] = useState(false);
  const [refreCode, setRefreCode] = useState("");
  const [id, setId] = useState("");
  const [type, setType] = useState("");
  const [segment, setSegment] = useState("select");
  // const [exchange, setExchange] = useState("select");
  const [strategy, setStrategy] = useState("select");
  const [alignment, setAlignment] = useState("live");
  const [liveWs, setLiveWs] = React.useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [paperWs, setPaperWs] = React.useState(false);
  const [pageno, setPageno] = useState(0);

  const dispatch = useDispatch();
  const positions = useSelector((state) => state?.Position?.liveAdminPositions);
  const data = Array.isArray(positions) ? positions : []
  //select segment
  const handleSegment = (event) => {
    setSegment(event.target.value);
  };

  //select strategy
  const handleStrategy = (event) => {
    setStrategy(event.target.value);
  };

  //modal open close
  const handleClickOpen = (ID, type) => {
    if (type === "all") {
      if (strategy?.toLowerCase() === "select") {
        return generatePopup("error", "Please select strategy");
      }
      if (segment?.toLowerCase() === "select") {
        return generatePopup("error", "Please select segment");
      }
    }
    setOpen(true);
    setId(ID);
    setType(type);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePageChange = (e) => {
    setPageno(e);
  };

  const userData = useSelector((state) => state?.Auth?.authProfile?.[0]);

  useEffect(() => {
    if (userData) {
      const obj = {
        refrecode: userData?.username,
      };
      dispatch(getUserRefreCode(obj));
    }
  }, [userData]);

  const refrecodes = useSelector((state) => state?.Broker?.userRefreCodes);

  const handleSearchData = () => {
    const obj = {
      segment:
        segment?.toLowerCase() !== "select" ? segment?.toUpperCase() : "select",
      stratergy:
        strategy?.toLowerCase() !== "select"
          ? strategy?.toUpperCase()
          : "select",
      clientid: refreCode ? refreCode?.toUpperCase() : "select",
    };
    dispatch(adminOpenPosition(obj));

  };

  useEffect(() => {
    handleSearchData();
  }, [segment, strategy]);

  const col = [
    "Symbol",
    "Trade Type",
    "Exchange",
    "Lot",
    "Order Type",
    "Trend",
    "Price",
    "Live Price",
    "P&L",
    "Status",
    "Remark",
    "Date",
    "Action",
  ];
  const rows = data?.map((e) => {
    return {
      symbol: e.symbol,
      tradeType: e.positiontype,
      exchange: e.exchange,
      lot: e?.quantity,
      orderType: e?.prctyp,
      trend: (
        <Typography
          component={"span"}
          className={e?.side?.toUpperCase() === "BUY" || e?.side?.toUpperCase() === "B" ? "up" : "down"}
        >
          {e?.side?.toUpperCase() === "BUY" || e?.side?.toUpperCase() === "B" ? "BUY" : "SELL"}
        </Typography>
      ),

      price: e?.price,
      liveprice: e?.liveprice,
      profit: Number(e?.profit)?.toFixed(2),
      status: (
        <Typography
          component={"span"}
          className={e?.status === "COMPLETE" ? "up" : "down"}
        >
          {e?.status}
        </Typography>
      ),
      remark: (
        <Tooltip
          title={
            <Typography sx={{ fontSize: "1.3rem" }}>{e?.remark}</Typography>
          }
          arrow
          placement="left"
        >
          <Button className="rejectMessage">
            <MessageIcon sx={{ color: "#fff !important" }} />
          </Button>
        </Tooltip>
      ),
      date: e?.date,
      squer: (
        <Button
          onClick={() => handleClickOpen(e?.id, "single")}
          className="squerOff"
        >
          <CloseIcon />
        </Button>
      ),
    };
  });

  useEffect(() => {
    dispatch(getStratergy());
    dispatch(getSegment());
  }, []);

  useEffect(() => {
    if (alignment === "paper") {
      let ws;
      const setupWebSocket = async () => {
        ws = await socket(
          "subbrokerpaperposition",
          process.env.REACT_APP_PAPER_CUSTOMER_WEBSOCKET_URL
        );
        setPaperWs(ws);
        if (ws) {
          dispatch(socketOpen({ name: "subbrokerpaperposition", socket: ws }));
          messageEvent(ws, "subbrokerpaperposition", dispatch, {
            remark: "heartbeat",
            pageno: 1,
          });
        }
      };
      setupWebSocket();
      window.onbeforeunload = async function (event) {
        console.log("closed");
        return await ws?.send({ remark: "disconnect" });
      };

      return () => {
        if (ws && ws.readyState === WebSocket.OPEN) {
          ws?.send("disconnect");
        }
      };
    }
  }, [alignment]);

  useEffect(() => {
    if (alignment === "live") {
      let ws;
      const setupWebSocket = async () => {
        ws = await socket(
          "subbrokerposition",
          process.env.REACT_APP_LIVE_CUSTOMER_WEBSOCKET_URL
        );
        setLiveWs(ws);
        if (ws) {
          dispatch(socketOpen({ name: "subbrokerposition", socket: ws }));
          messageEvent(ws, "subbrokerposition", dispatch, {
            remark: "heartbeat",
            pageno: 1,
          });
        }
      };

      setupWebSocket();
      window.onbeforeunload = async function (event) {
        console.log("closed");
        return await ws?.send("disconnect");
      };

      return () => {
        if (ws && ws.readyState === WebSocket.OPEN) {
          ws?.send("disconnect");
        }
      };
    }
  }, [alignment]);

  useEffect(() => {
    console.log("liveWs", liveWs);
    if (alignment === "live" && paperWs) {
      messageEvent(paperWs, "liveposition", dispatch, {
        remark: "disconnect",
      });
    } else if (alignment === "paper" && liveWs) {
      messageEvent(liveWs, "paperposition", dispatch, {
        remark: "disconnect",
      });
    }
  }, [alignment]);

  useEffect(() => {
    if (alignment === "live") {
      if (liveWs) {
        console.log("come to the live position");
        messageEvent(liveWs, "subbrokerposition", dispatch, {
          remark: "pageno",
          pageno: pageno,
        });
      }
    }
    if (alignment === "paper") {
      if (paperWs) {
        messageEvent(paperWs, "subbrokerpaperposition", dispatch, {
          remark: "pageno",
          pageno: pageno,
        });
      }
    }
  }, [pageno, liveWs, paperWs]);

  const strategies = useSelector((state) => state?.Stratergy?.stratergy);
  const segments = useSelector((state) => state?.Position?.segmentList);

  const strategyList = strategies?.map((e) => e?.name);

  const handleChange = (e, newAlignment) => {
    if (newAlignment) {
      setAlignment(newAlignment);
    }
  };

  // function downloadCSV(data) {
  //   // Step 2: Convert the JSON data to CSV format
  //   const csvContent =
  //     "data:text/csv;charset=utf-8," +
  //     Object.keys(data[0])
  //       .map((key) => key)
  //       .join(",") +
  //     "\n" +
  //     data
  //       .map((row) =>
  //         Object.values(row)
  //           .map((value) => `"${value}"`)
  //           .join(",")
  //       )
  //       .join("\n");

  //   // Step 3: Create a data URI and trigger the download
  //   const encodedURI = encodeURI(csvContent);
  //   const link = document.createElement("a");
  //   link.setAttribute("href", encodedURI);
  //   link.setAttribute("download", "Business_Report.csv");
  //   document.body.appendChild(link);
  //   link.click();
  // }

  const handleSquareOffAll = () => {
    const obj = {
      segment: segment ? segment?.toUpperCase() : "select",
      stratergy: strategy ? strategy?.toUpperCase() : "select",
    };
    if (type === "all") {
      if (alignment === "live") {
        dispatch(closeAllAdminOrder(obj)).then((res) => {
          if (res) {
            setOpen(false);
          }
        });
      } else if (alignment === "paper") {
        dispatch(closeAllAdminPaperOrder(obj)).then((res) => {
          if (res) {
            setOpen(false);
            if (res?.status === 200) {
              generatePopup("success", res?.data?.message);
            }
          }
        });
      }
    } else {
      dispatch(adminCloseOrder({ id: Number(id) })).then((res) => {
        if (res) {
          setOpen(false);
          if (res?.status === 200) {
            handleSearchData();
          }
        }
      });
    }
  };

  const handleRefreSelect = (e, value) => {
    setRefreCode(value);
  };

  const handleRefreSearch = (e, value) => {
    setRefreCode(e?.target?.value || "");
  };

  const handleSearchPosition = () => {
    const obj = {
      segment:
        segment?.toLowerCase() !== "select" ? segment?.toUpperCase() : "select",
      stratergy:
        strategy?.toLowerCase() !== "select"
          ? strategy?.toUpperCase()
          : "select",
      clientid: refreCode ? refreCode?.toUpperCase() : "select",
    };
    dispatch(adminOpenPosition(obj));
  };
  return (
    <>
      <Box className="tabelBox">
        <Grid container spacing={2} alignItems={"center"} padding={"1rem 0"}>
          <Grid item xs={12}>
            <Box
              className="selectiondiv-box m-rb"
              sx={{ paddingRight: { xs: "0", md: "1rem" } }}
            >
              <Box className="selectionDiv searchFlex">
                {/* <Box className="inputFields">
                  <TextField
                    onChange={handleClientID}
                    placeholder="Broker Client id"
                  />
                  <Box className="searchIcon">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.85722 14.7144C11.6444 14.7144 14.7144 11.6444 14.7144 7.85722C14.7144 4.07008 11.6444 1 7.85722 1C4.07008 1 1 4.07008 1 7.85722C1 11.6444 4.07008 14.7144 7.85722 14.7144Z"
                        stroke="#6A6D78"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13 13.0001L17 17.0002"
                        stroke="#6A6D78"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Box>
                </Box>
                <Tooltip title={searchTip} arrow>
                  <Button
                    className="download-btn solidButton"
                    sx={{ marginLeft: "1rem" }}
                    onClick={handleSearch}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                        fill="#FFFFFF"
                      />
                    </svg>
                  </Button>
                </Tooltip> */}
              </Box>
              {/* <Grid item md={6} sx={{ paddingLeft: "1rem" }}></Grid> */}
              <Grid container md={3} sx={{ paddingLeft: "1rem" }}>
                <Grid item md={12}>
                  <Box className="dropdown-ap inputFields fullWidth">
                    <Autocomplete
                      onInputChange={handleRefreSearch}
                      placeholder="Select Broker"
                      value={refreCode || ""}
                      inputValue={refreCode || ""}
                      className="dropdown"
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select Broker" />
                      )}
                      options={refrecodes?.map((e) => e)}
                      onChange={handleRefreSelect}
                      getOptionLabel={(option) => option}
                      sx={{
                        "& > .MuiFormControl-root > .MuiInputBase-root": {
                          padding: "0.5rem !important",
                        },
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Tooltip title={search} arrow>
                <Button
                  onClick={handleSearchPosition}
                  className="download-btn solidButton"
                  sx={{ margin: "0 1rem" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                      fill="#FFFFFF"
                    />
                  </svg>
                </Button>
              </Tooltip>
              {/* <Dropdown
                val={["select"].concat(refrecodes)}
                handleSelect={handleRefreCode}
                value={refreCode}
                title={"RefreCode"}
              /> */}
              <Dropdown
                val={["select"].concat(segments)}
                handleSelect={handleSegment}
                value={segment}
                title={"Segment"}
              />
              <Dropdown
                val={["select"].concat(strategyList)}
                handleSelect={handleStrategy}
                value={strategy}
                title={"Strategy"}
              />
              {/* <Dropdown
                val={drpValue}
                handleSelect={handleExchange}
                value={exchange}
                title={"Exchange"}
              /> */}
              {/* <Dropdown
                val={drpValue3}
                handleSelect={handlePl}
                value={pl}
                title={"P&L"}
              /> */}
              <Box className="selectionDiv searchFlex">
                {/* <Typography className="label" component={"label"}>
                  Select Trade Type
                </Typography> */}
                <ToggleButtonGroup
                  value={alignment}
                  exclusive
                  onChange={handleChange}
                  className="tradeType-toggle"
                >
                  <ToggleButton value="paper">Paper</ToggleButton>
                  <ToggleButton value="live">Live</ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Box className="selectionDiv searchFlex">
                <Tooltip title={download} arrow>
                  <Button className="download-btn solidButton">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17 11.6669V15.2224C17 15.6939 16.8127 16.1461 16.4793 16.4795C16.1459 16.8129 15.6937 17.0002 15.2222 17.0002H2.77778C2.30628 17.0002 1.8541 16.8129 1.5207 16.4795C1.1873 16.1461 1 15.6939 1 15.2224V11.6669"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.55542 7.22229L8.99987 11.6667L13.4443 7.22229"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9 11.6667V1"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Button>
                </Tooltip>
              </Box>
              <Box className="selectionDiv searchFlex">
                <Tooltip title={sqyuareoff} arrow>
                  <Button
                    className="squareOff-btn solidButton"
                    onClick={() => handleClickOpen("", "all")}
                  >
                    <CloseIcon />
                  </Button>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Table
          col={col}
          rows={rows}
          handlePageChange={handlePageChange}
          pageno={pageno}
          totalCount={totalCount}
          type="openPosition"
        />
      </Box>

      {/* Sqaure Off Modal */}
      <Dialog
        open={open}
        onClose={handleClose}
        className="commonModal squareOff"
        fullWidth
      >
        <Box className="modalHeader">
          <Typography component={"h4"}>Square Off</Typography>
          <Button onClick={handleClose} className="closeModal">
            <img alt="close" src={close} />
          </Button>
        </Box>

        <DialogContent sx={{ padding: "0" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="alertIcons">
              <svg
                width="1052"
                height="1052"
                viewBox="0 0 1052 1052"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M558 334C558 316.3 543.7 302 526 302C508.3 302 494 316.3 494 334V590C494 607.7 508.3 622 526 622C543.7 622 558 607.7 558 590V334ZM526 750C536.609 750 546.783 745.786 554.284 738.284C561.786 730.783 566 720.609 566 710C566 699.391 561.786 689.217 554.284 681.716C546.783 674.214 536.609 670 526 670C515.391 670 505.217 674.214 497.716 681.716C490.214 689.217 486 699.391 486 710C486 720.609 490.214 730.783 497.716 738.284C505.217 745.786 515.391 750 526 750Z"
                  fill="#4987FE"
                />
                <circle
                  cx="526"
                  cy="526"
                  r="507"
                  stroke="#5086EE"
                  stroke-width="38"
                />
              </svg>
            </Box>
            <Typography
              component={"h4"}
              sx={{
                fontSize: "1.8rem",
                textAlign: "center",
                marginTop: "2rem",
              }}
              className="alertText"
            >
              Are You Sure You Want to Close Position !
            </Typography>

            <Box sx={{ display: { xs: "flex" } }}>
              <Button
                onClick={handleClose}
                className="modal-btn btn-danger"
                sx={{ marginRight: "0.5rem" }}
              >
                Discard
              </Button>
              <Button
                onClick={handleSquareOffAll}
                className="modal-btn btn-primary"
                sx={{ marginLeft: "0.5rem" }}
              >
                Confirm
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
