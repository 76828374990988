import React from 'react';
import { List, ListItem, Typography } from '@mui/material';

export default function How_it_work() {

  return (
    <>
      <List className='profile-content'>
        <ListItem>
          <Typography component='h4'>How it works</Typography>
          <Typography component='p' className='list'> Download the App from Playstore</Typography>
          <Typography component='p' className='list'>If you are a new user then do signup first by entering your name,email and mobile number and click on get otp</Typography>
          <Typography component='p' className='list'>Enter the otp once you receive on mobile or email</Typography>
          <Typography component='p' className='list'> Tick on I agree terms and services and click on sign up button</Typography>
          <Typography component='p' className='list'>Registration is a one time process which need not to be performed daily</Typography>
          <Typography component='p' className='list'>Go to the Sign In page</Typography>
          <Typography component='p' className='list'>Enter your mobile number and click on get otp</Typography>
          <Typography component='p' className='list'>Enter OTP once you receive on mobile or email</Typography>
          <Typography component='p' className='list'> Click on sign in</Typography>
          <Typography component='p' className='list'> After Sign in click on the broker login at the left side of the panel to connect with your broker</Typography>
          <Typography component='p' className='list'>Select your broker and enter your broker login credentials and connect</Typography>
          <Typography component='p' className='list'>Once you connects with your broker it will show you as online at the top right hand corner</Typography>
          <Typography component='p' className='list'>Now you are ready to do paper trade or live trade as per your preferred segment.</Typography>
        </ListItem>

        <ListItem>
          <Typography component='h4'>What is costs</Typography>
          <Typography component='p'>The entire process, from downloading the APP to broker login, is entirely free.</Typography>
        </ListItem>

        <ListItem>
          <Typography component='h4'>Do you provide monthly subscription plans?</Typography>
          <Typography component='p'>Yes Monthly, quarterly, half-annual, and yearly subscriptions are also available.</Typography>
        </ListItem>

        <ListItem>
          <Typography component='h4'>What is the minimum capital required to trade.?</Typography>
          <Typography component='p'>The minimum capital required is 25,000, with the amount increasing as the segment capital grows.</Typography>
        </ListItem>

        <ListItem>
          <Typography component='h4'>Do you have a demo video to know how this works?</Typography>
          <Typography component='p'><Typography href='#' component={'a'}>https://www.youtube.com/watch?v=NRe1fzmKQYI</Typography> Here is the link.</Typography>
        </ListItem>

        <ListItem>
          <Typography component='h4'>Do you provide any trial plan?</Typography>
          <Typography component='p'>After a consumer signs up for a subscription, Dematade offers a 7-day free trial for live trading, and paper trading is always an option.</Typography>
        </ListItem>

        <ListItem>
          <Typography component='h4'>How are you ensuring the safety of my details on the app?</Typography>
          <Typography component='p'>When creating code and maintaining our infrastructure, we take significant precautions and include security as a standard practice in all of our activities. The following are some sensible procedures we employ to protect your data:</Typography>
          <Typography component='p' className='list'>Regular audits and penetration tests both internally and externally.</Typography>
          <Typography component='p' className='list'>Consistent manual and automated assessments of the infrastructure.</Typography>
          <Typography component='p' className='list'>To keep systems apart from one another, they are placed on separate networks.</Typography>
          <Typography component='p' className='list'>To access crucial systems, developers employ passwordless certificate (+2FA) based SSH logins. A system is only accessible to developers who actually require it.</Typography>
          <Typography component='p' className='list'>We provide actual two-factor authentication with app-based TOTP for client accounts (activate under Broker  Account  Password and security).</Typography>
          <Typography component='p'>All one can do to ensure security is to exercise caution and use any practical common sense security rules. In sophisticated, linked systems, it just takes one small mistake—whether technological or human—often one that seems silly—to release a Pandora's box Therefore, there is no such thing as 100% security, and the most anyone can hope for is to maintain constant watch, both technically and otherwise. We make every effort and use extreme caution.</Typography>
        </ListItem>

        <ListItem>
          <Typography component='h4'>How secure is linking my bank account details with the trading app?</Typography>
          <Typography component='p'>DeMatade software does not request data about linked bank accounts. All the bank account information is stored on the broker end, and all that is asked for is the download of the app and login using the broker username and password.</Typography>
        </ListItem>

        <ListItem>
          <Typography component='h4'>Can two people use the same algorithm?</Typography>
          <Typography component='p'>Yes, a public algorithm may be accessed by a variety of users, while a private algorithm can only be used by those who have access to it.</Typography>
        </ListItem>

        <ListItem>
          <Typography component='h4'>Can two people use the app at the same time if I share my username and password ?</Typography>
          <Typography component='p'>No, the app can only be accessed by the one person who registers it, and anyone else who uses the same login and password will be unable to access it.</Typography>
        </ListItem>
      </List>
    </>
  )
}
