import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Tooltip,
  Typography,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import Table from "../../../Table/Table";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import close from "../../../../images/close.png";
import Tableaction from "../../../Tableaction/Tableaction";
import { useDispatch, useSelector } from "react-redux";
import {
  getServiceCharges,
  updateServiceCharges,
} from "../../../../redux/actions/authActions";
import { generatePopup } from "../../../../utils/popup";

export default function Strategycharges() {
  const add = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      Add Startegy
    </Typography>
  );
  const [open, setOpen] = React.useState(false);
  const [report, setreport] = React.useState(" ");
  const [strategy, setstrategy] = React.useState(" ");
  const [editable, setEditable] = React.useState(false);
  const [state, setState] = React.useState({});
  const segment = [
    "Select Segment",
    "Select All",
    "Banknifty Fut",
    "Nifty Fut",
    "BANKNIFTY OPTION",
    "NIFTY OPTION",
    "MCX FUT",
    "CURRENCY FUT",
    "NSE CASH",
    "STOCK FUT",
    "BANKNIFTY OPTION SELLI...",
    "NIFTY OPTION SELLING",
    "MCX OPTION",
    "FINNIFTY FUT",
    "FINNIFTY OPTION",
  ];
  const strategyName = [
    "Select Strategy",
    "ORBATM",
    "DCASH",
    "DEMSMIC",
    "DEMATADE",
    "RSIOPTION",
    "TRADE",
  ];

  const edit = <Typography sx={{ fontSize: "1.4rem" }}>Edit</Typography>;
  const save = <Typography sx={{ fontSize: "1.4rem" }}>Save</Typography>;
  const del = <Typography sx={{ fontSize: "1.4rem" }}>Delete</Typography>;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // Dropdown tab
  const drpchnage = (event) => {
    setreport(event.target.value);
  };
  const drpstrategy = (event) => {
    setstrategy(event.target.value);
  };

  const col = ["index", "Gateway charges", "Gst charges", "Action"];
  // const rows = [
  //   {
  //     segmant: "OptionBankNifty",
  //     strategyName: "DEM0001",
  //     setCharges: 400,
  //     action: <Tableaction />,
  //   },
  // ];

  const [search, setSearch] = useState();

  //input Search
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getServiceCharges());
  }, []);

  const handleEdit = () => {
    dispatch(
      updateServiceCharges({
        gst: state?.gst_percent,
        gateway: state?.gateway_percent,
      })
    ).then((res) => {
      if (res) {
        setEditable(false);
        dispatch(getServiceCharges());
      }
    });
  };

  const serviceCharges = useSelector((state) => state?.Auth?.serviceCharges);

  useEffect(() => {
    setState(serviceCharges);
  }, [serviceCharges]);

  var rows = [];
  rows.push({
    index: 1,
    gateway: editable ? (
      <TextField
        size="small"
        name="gateway_percent"
        value={state?.gateway_percent}
        sx={{
          border: "1px solid gray",
          borderRadius: "5px",
        }}
        inputProps={{ style: { fontSize: "small" } }}
      ></TextField>
    ) : (
      serviceCharges?.gateway_percent
    ),
    gst: editable ? (
      <TextField
        size="small"
        name="gst_percent"
        value={state?.gst_percent}
        sx={{
          border: "1px solid gray",
          borderRadius: "5px",
        }}
        onChange={handleChange}
        inputProps={{ style: { fontSize: "small" } }}
      ></TextField>
    ) : (
      serviceCharges?.gst_percent
    ),
    action: (
      <>
        <Box className="tableActions">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="actionButton"
          >
            {!editable && (
              <Tooltip title={edit} arrow placement="top">
                <Button
                  onClick={() => {
                    setEditable(true);
                  }}
                  className="edit"
                  variant="text"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.26 3.59997L5.04997 12.29C4.73997 12.62 4.43997 13.27 4.37997 13.72L4.00997 16.96C3.87997 18.13 4.71997 18.93 5.87997 18.73L9.09997 18.18C9.54997 18.1 10.18 17.77 10.49 17.43L18.7 8.73997C20.12 7.23997 20.76 5.52997 18.55 3.43997C16.35 1.36997 14.68 2.09997 13.26 3.59997Z"
                      stroke="CurrentColor"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.89 5.05005C12.32 7.81005 14.56 9.92005 17.34 10.2"
                      stroke="CurrentColor"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3 22H21"
                      stroke="CurrentColor"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Button>
              </Tooltip>
            )}
            {editable && (
              <Tooltip title={save} arrow placement="top">
                <Button onClick={handleEdit} className="save" variant="text">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.8199 2H7.17995C5.04995 2 3.31995 3.74 3.31995 5.86V19.95C3.31995 21.75 4.60995 22.51 6.18995 21.64L11.0699 18.93C11.5899 18.64 12.4299 18.64 12.9399 18.93L17.8199 21.64C19.3999 22.52 20.6899 21.76 20.6899 19.95V5.86C20.6799 3.74 18.9499 2 16.8199 2Z"
                      stroke="CurrentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Button>
              </Tooltip>
            )}
            {/* <Tooltip title={del} arrow placement="top">
              <Button className="del" variant="text">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"
                    stroke="CurrentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                    stroke="CurrentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.85 9.14001L18.2 19.21C18.09 20.78 18 22 15.21 22H8.79002C6.00002 22 5.91002 20.78 5.80002 19.21L5.15002 9.14001"
                    stroke="CurrentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.33 16.5H13.66"
                    stroke="CurrentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.5 12.5H14.5"
                    stroke="CurrentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Button>
            </Tooltip> */}
          </Box>
        </Box>
      </>
    ),
  });

  return (
    <>
      <Box className="tabelBox">
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12}>
            <Box className="selectiondiv-box m-rb">
              <Box className="selectionDiv searchFlex">
                <Box className="inputFields" sx={{ marginBottom: "0" }}>
                  <TextField
                    placeholder="Search Here..."
                    onChange={(event) => handleSearch(event)}
                  />
                  <Box className="searchIcon">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.85722 14.7144C11.6444 14.7144 14.7144 11.6444 14.7144 7.85722C14.7144 4.07008 11.6444 1 7.85722 1C4.07008 1 1 4.07008 1 7.85722C1 11.6444 4.07008 14.7144 7.85722 14.7144Z"
                        stroke="#6A6D78"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13 13.0001L17 17.0002"
                        stroke="#6A6D78"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Box>
                </Box>
                {/* <Tooltip title={add} arrow>
                  <Button
                    className="download-btn solidButton"
                    sx={{ marginLeft: "1rem" }}
                    onClick={handleClickOpen}
                  >
                    <AddCircleIcon />
                  </Button>
                </Tooltip> */}
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Table col={col} rows={rows} />
      </Box>

      {/* Deploy Modal */}
      <Dialog
        open={open}
        onClose={handleClose}
        className="commonModal AddStrategyPrice"
      >
        <Box className="modalHeader">
          <Typography component={"h4"}>Set Strategy Charges</Typography>
          <Button onClick={handleClose} className="closeModal">
            <img src={close} />
          </Button>
        </Box>
        <DialogContent
          className="modalBody"
          sx={{ paddingTop: "6rem !important" }}
        >
          <DialogContentText>
            <Box className="formBox">
              <Grid container spacing={1} justifyContent="right">
                <Grid item xs={12}>
                  <Box className="formItems">
                    <Typography component={"label"} className="label">
                      Select Segment:
                    </Typography>
                    <Box className="selectionDiv">
                      <FormControl
                        className="dropdown-ap"
                        sx={{ width: "100%" }}
                      >
                        <Select
                          value={report}
                          onChange={drpchnage}
                          className="dropdown"
                        >
                          {segment.map((vals, index) => (
                            <MenuItem
                              value={index === 0 ? " " : index}
                              key={index}
                            >
                              {vals}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="formItems">
                    <Typography component={"label"} className="label">
                      Select Strategy:
                    </Typography>
                    <Box className="selectionDiv">
                      <FormControl
                        className="dropdown-ap"
                        sx={{ width: "100%" }}
                      >
                        <Select
                          value={strategy}
                          onChange={drpstrategy}
                          className="dropdown"
                        >
                          {strategyName.map((vals, index) => (
                            <MenuItem
                              value={index === 0 ? " " : index}
                              key={index}
                            >
                              {vals}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box className="inputFields space fullWidth">
                    <Typography component={"label"} className="label">
                      Set Charges :
                    </Typography>
                    <TextField
                      value={1200}
                      placeholder="Set Charges"
                      className="inputFiled"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <Button
                    onClick={handleClose}
                    className="modal-btn btn-primary"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
