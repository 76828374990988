// ResizeObserver loop completed with undelivered notifications.

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  FormControlLabel,
  Radio,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  InputBase,
  Tooltip,
  RadioGroup,
  TablePagination,
  Switch,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Table,
  TableBody,
  Tab,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  TextField,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Dropdown from "../Dropdown/Dropdown";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import close from "../../images/close.png";
import noData from "../../images/page_456.jpg";
import RefreshIcon from "@mui/icons-material/Refresh";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import Position from "../Position/Position";
import "../Home/Home.scss";
import "../Table/Table.scss";
import Autoselectdrp from "../Strategybuilder/Autoselectdrp";
import Autoselect from "../Strategybuilder/Autoselect";
import "./Marketwatch.scss";
import { messageEvent, socket } from "../../hooks/useSocket";
import { useDispatch, useSelector } from "react-redux";
import {
  addWatchListData,
  deleteWatchList,
  expiryExchange,
  futureExchange,
  nseExchange,
  optExpiryExchange,
  strikePriceExchange,
} from "../../redux/actions/marketWatchAction";
import { generatePopup } from "../../utils/popup";
import {
  createPaperPosition,
  createPosition,
} from "../../redux/actions/positionAction";
import dayjs from "dayjs";
import { getProfile } from "../../redux/actions/authActions";
import { BrokerStatus } from "../../redux/actions/brokerAction";

const up = (
  <svg
    width="8"
    height="9"
    viewBox="0 0 8 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.51526 1.03329V8.06192M0.58667 3.96189L3.51526 1.03329L0.58667 3.96189ZM3.51526 1.03329L6.44386 3.96189L3.51526 1.03329Z"
      stroke="#24a959"
      strokeWidth="1.17144"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);
const down = (
  <svg
    width="8"
    height="9"
    viewBox="0 0 8 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.60241 8.06195L3.60241 1.03332M6.53101 5.13336L3.60241 8.06195L6.53101 5.13336ZM3.60241 8.06195L0.673816 5.13336L3.60241 8.06195Z"
      stroke="#FF231F"
      stroke-width="1.17144"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
);
const arrowUp = (
  <svg
    width="8"
    height="9"
    viewBox="0 0 8 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.51526 1.03329V8.06192M0.58667 3.96189L3.51526 1.03329L0.58667 3.96189ZM3.51526 1.03329L6.44386 3.96189L3.51526 1.03329Z"
      stroke="white"
      strokeWidth="1.17144"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);
const arrowDown = (
  <svg
    width="8"
    height="9"
    viewBox="0 0 8 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.60241 8.06195L3.60241 1.03332M6.53101 5.13336L3.60241 8.06195L6.53101 5.13336ZM3.60241 8.06195L0.673816 5.13336L3.60241 8.06195Z"
      stroke="white"
      stroke-width="1.17144"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
);

export default function Marketwatch() {
  const [open, setOpen] = React.useState(false);
  const [bsopen, setbsopen] = React.useState(false);
  const [sell, setbuy] = React.useState("buy");
  const [buysell, setbuysell] = React.useState("Buy");
  const [prdType, setPrdType] = useState("MIS");
  const [orderType, setOrderType] = useState("Market");
  const [tradeType, setTradeType] = useState("Live");
  const [broker, setBroker] = useState("");
  // const [prc, setPrc] = useState(0);
  const [trp, settTrp] = useState(0);
  // const [removeSymbol, setremoveSymbol] = useState("none");
  // // const [WebSocket, setWebSocket] = useState();
  // const [collapse, setcollapse] = useState("close");
  const [selectedStock, setSelectedStock] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const [search, setSearch] = useState("");
  // const [strikeSearch, setStrikeSearch] = useState("");
  const [selectedAlert, setSelectedAlert] = useState();
  const [exchange, setExchange] = useState("nse");
  const drpValue = ["MIS", "NRML"];
  const sharekhanDrpValue = ["INVESTMENT", "BIGTRADE", "BIGTRADE+"];
  const instrumentTypes = {
    FS: "Future Stocks(FS)",
    FI: "Future Index(FI)",
    OI: " Option Index(OI)",
    OS: "Option Stocks(OS)",
    FUTCUR: "Future Currency(FUTCUR)",
    OPTCUR: "Option Currency(OPTCUR)",
  };
  // const [searchTerm, setSearchTerm] = useState("");
  const [futureExpiryData, setFutureExpiryData] = useState("");
  const [value, setValue] = useState("mw1");
  // const [nfo, setnfo] = useState("future");
  const [options, setOptions] = useState("future");
  const [cpExpiry, setCpExpiry] = useState("");
  const [cpStrike, setCpStrike] = useState("");
  const [deleteData, setDeleteData] = useState([]);
  const [deletePop, setDeletePop] = useState(false);
  const [instType, setInstType] = useState("");
  const [load, setLoad] = useState(false);
  const [qty, setQty] = useState("");
  const dispatch = useDispatch();

  const watchListData = useSelector((state) => {
    return state?.Marketwatch?.exchangeList;
  });

  const expiryList = useSelector((state) => {
    return state?.Marketwatch?.expiryList;
  });

  const optionExpiryList = useSelector((state) => {
    return state?.Marketwatch?.optionExpiry;
  });

  const optionStrikeList = useSelector((state) => {
    return state?.Marketwatch?.optionStrikeData;
  });
  const watchListLiveData = useSelector((state) => {
    return state?.Marketwatch?.watchListLive;
  });

  // Add Symbol Modal
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (broker === "sharekhan") {
      setPrdType("INVESTMENT");
    }
  }, [broker]);

  useEffect(() => {
    dispatch(getProfile());
    dispatch(BrokerStatus());
  }, []);

  const brokerDetails = useSelector(
    (state) => state?.Broker?.brokerstatus?.[0]
  );
  useEffect(() => {
    if (brokerDetails?.brokername) {
      setBroker(brokerDetails?.brokername);
    }
  }, [brokerDetails?.brokername]);

  // Buy/Sell Modal
  const buysellOpen = (value, stock, index) => {
    setQty(stock?.lot === "undefined" || stock?.lot === "0" ? 1 : stock?.lot);
    setSelectedIndex(index);
    setbuy(value);
    setbuysell(value);
    setbsopen(true);
  };

  useEffect(() => {
    setSelectedStock(watchListLiveData?.[selectedIndex]);
  }, [selectedIndex]);

  const buysellClose = () => {
    setbsopen(false);
  };
  const buysellpopup = () => {
    if (sell === "buy") {
      setbuy("sell");
      setbuysell("sell");
    } else {
      setbuy("buy");
      setbuysell("buy");
    }
  };

  const handleSearch = (e) => {
    setSearch(e);
  };

  const handleStrikeSearch = (e) => {
    setCpStrike(e?.target?.value);
  };

  const handleStrikeSelect = (e, value) => {
    let selectedData = optionStrikeList?.filter(
      (d) => d?.strikeprice === value
    );
    setCpStrike(value);
    setSelectedAlert({
      ...selectedAlert,
      strikeprice: selectedData[0]?.strikeprice,
      tradingsymbol: selectedData[0]?.tradingsymbol,
      lot: selectedData[0]?.lot,
      token: selectedData[0]?.token,
      exchange: exchange?.toUpperCase(),
    });
  };

  const handleInstrument = (e) => {
    const { value } = e?.target;
    setInstType(value);
  };
  // console.log("selectedAlert :>> ", selectedAlert);
  // const handleStrikeChange = (e) => {
  //   setStrikeSearch(Number(e).toFixed());
  // };

  let ws = null;
  useEffect(() => {
    getWatchListLiveSocket();

    window.onbeforeunload = async function (event) {
      console.log("closed");
      return await ws?.send("disconnect");
    };
    return () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws?.send("disconnect");
      }
    };
  }, []);

  const getWatchListLiveSocket = async () => {
    ws = await socket("watchlistupdate");

    messageEvent(ws, "watchlistupdate", dispatch);
  };

  const handleChange = (e, newValue = "string") => {
    setValue(newValue);
  };

  const handleExchange = (e, newValue) => {
    setExchange(newValue);
    setCpStrike("");
    setCpExpiry("");
    setFutureExpiryData("");
    setOptions("future");

    // sendWatchListMsg(WebSocket, "", newValue);
  };

  const handlePrdChange = (e) => {
    setPrdType(e.target.value);
  };

  useEffect(() => {
    if (exchange === "nse") {
      dispatch(
        nseExchange({
          exchange: "NSE",
        })
      );
    } else if (
      exchange === "nfo" ||
      exchange === "cds" ||
      exchange === "mcx" ||
      exchange === "bfo"
    ) {
      dispatch(
        futureExchange({
          exchange: exchange?.toUpperCase(),
        })
      );
    }
  }, [exchange]);

  const handleSelectAlert = (value) => {
    setOptions("future");
    setSelectedAlert({ symbol: value, exchange: exchange.toUpperCase() });

    if (
      exchange === "nfo" ||
      exchange === "mcx" ||
      exchange === "cds" ||
      exchange === "bfo"
    ) {
      if (value != null) {
        dispatch(
          expiryExchange({
            exchange: exchange.toUpperCase(),
            symbol: value,
            option: "XX",
          })
        ).then((res) => {
          if (res?.length > 0) {
            setFutureExpiryData(res[0]?.expiry);

            setSelectedAlert({
              ...selectedAlert,
              symbol: value,
              token: res[0]?.token,
              expiry: res[0]?.expiry,
              tradingsymbol: res[0]?.tradingsymbol,
              lot: res[0]?.lot !== "undefined" ? res[0]?.lot : "0",
            });
          }
        });
      }
    }
  };

  const getOptionStrikeDataFun = (payload, optionType) => {
    dispatch(strikePriceExchange(payload, optionType)).then((res) => {
      setCpStrike("");
      setSelectedAlert({
        ...selectedAlert,
        token: res[0]?.token,
        strikeprice: res[0].strikeprice,
        expiry: res[0].expiry,
        tradingsymbol: res[0].tradingsymbol,
        lot: res[0]?.lot,
      });
    });
  };

  const handleChangeOptions = (e, newValue = "string") => {
    setOptions(newValue);

    if (
      exchange === "nfo" ||
      exchange === "mcx" ||
      exchange === "cds" ||
      exchange === "bfo"
    ) {
      if (newValue === "future") {
        dispatch(
          expiryExchange({
            exchange: exchange.toUpperCase(),
            symbol: selectedAlert?.symbol,
            option: "XX",
          })
        ).then((res) => {
          if (res?.length > 0) {
            setSelectedAlert({
              ...selectedAlert,
              symbol: res[0]?.symbol,
              token: res[0]?.token,
              expiry: res[0]?.expiry,
              tradingsymbol: res[0]?.tradingsymbol,
              lot: res[0]?.lot,
            });
          }
        });
      } else if (newValue === "call") {
        dispatch(
          optExpiryExchange({
            exchange: exchange.toUpperCase(),
            symbol: selectedAlert?.symbol,
          })
        ).then((res) => {
          if (res.exchange.length > 0) {
            setCpExpiry(res.exchange[0]);
            getOptionStrikeDataFun({
              exchange: exchange.toUpperCase(),
              symbol: selectedAlert?.symbol,
              expiry: res.exchange[0],
              option: "CE",
            });
          }
        });
      } else if (newValue === "put") {
        dispatch(
          optExpiryExchange({
            exchange: exchange.toUpperCase(),
            symbol: selectedAlert?.symbol,
          })
        ).then((res) => {
          if (res.exchange.length > 0) {
            if (res.exchange.length > 0) {
              setCpExpiry(res.exchange[0]);
              getOptionStrikeDataFun({
                exchange: exchange.toUpperCase(),
                symbol: selectedAlert?.symbol,
                expiry: res.exchange[0],
                option: "PE",
              });
            }
          }
        });
      }
    }
  };

  const handleExpiryChange = (newValue) => {
    let selectedData = expiryList.filter((d) => d.expiry === newValue);
    setFutureExpiryData(newValue);
    setSelectedAlert({
      ...selectedAlert,
      expiry: newValue,
      token: selectedData[0]?.token,
      tradingsymbol: selectedData[0]?.tradingsymbol,
      lot:
        !selectedData[0]?.lot || selectedData[0]?.lot === "undefined"
          ? "0"
          : selectedData[0]?.lot,
    });
  };

  const handleCPExpiryChange = (event) => {
    setCpExpiry(event.target.value);
    getOptionStrikeDataFun({
      exchange: exchange.toUpperCase(),
      symbol: selectedAlert?.symbol,
      expiry: event.target.value,
      option: options == "call" ? "CE" : "PE",
    });
  };

  const handleCPStrikeChange = (value) => {
    let selectedData = optionStrikeList.filter((d) => d.token === value);

    setCpStrike(selectedData[0]?.token);
    setSelectedAlert({
      ...selectedAlert,
      strikeprice: selectedData[0]?.strikeprice,
      tradingsymbol: selectedData[0]?.tradingsymbol,
      lot: selectedData[0]?.lot,
      token: value,
      exchange: exchange.toUpperCase(),
    });
  };

  const addNfoSymbol = () => {
    let obj = {
      exchange: exchange.toUpperCase(),
      // expiry: selectedAlert.expiry,
      expiry: selectedAlert.expiry,
      option:
        options === "call"
          ? "CE"
          : options === "put"
            ? "PE"
            : options === "future"
              ? "XX"
              : "AA",
      strikeprice:
        options === "future"
          ? "0"
          : Math.round(selectedAlert?.strikeprice)?.toString(),
      symbol: selectedAlert?.symbol,
      token: selectedAlert?.token,
      tradingsymbol: selectedAlert?.tradingsymbol,
      lot: String(selectedAlert?.lot),
    };

    if (watchListLiveData.length === 10) {
      return generatePopup("error", "Maximum 10 symbol can be allowed");
    }
    dispatch(addWatchListData(obj));
    handleClear();
    handleClose();
  };

  const addCdsSymbol = () => {
    let obj = {
      exchange: exchange.toUpperCase(),
      expiry: selectedAlert.expiry,
      option:
        options === "call"
          ? "CE"
          : options === "put"
            ? "PE"
            : options === "future"
              ? "XX"
              : "AA",
      strikeprice:
        options === "future"
          ? "0"
          : Math.round(selectedAlert.strikeprice)?.toString(),
      symbol: selectedAlert?.symbol,
      token: selectedAlert?.token,
      tradingsymbol: selectedAlert?.tradingsymbol,
      lot: String(selectedAlert?.lot),
    };

    if (watchListLiveData.length === 10) {
      return generatePopup("error", "Maximum 10 symbol can be allowed");
    }
    dispatch(addWatchListData(obj));
    handleClear();
    handleClose();
  };

  const addMcxSymbol = () => {
    let obj = {
      exchange: exchange.toUpperCase(),
      expiry: selectedAlert.expiry,
      option:
        options === "call"
          ? "CE"
          : options === "put"
            ? "PE"
            : options === "future"
              ? "XX"
              : "AA",
      strikeprice:
        options === "future"
          ? "0"
          : Math.round(selectedAlert.strikeprice)?.toString(),
      symbol: selectedAlert?.symbol,
      token: selectedAlert?.token,
      tradingsymbol: selectedAlert?.tradingsymbol,
      lot: String(selectedAlert?.lot),
    };

    if (watchListLiveData.length === 10) {
      return generatePopup("error", "Maximum 10 symbol can be allowed");
    }
    dispatch(addWatchListData(obj));
    handleClear();
    handleClose();
  };

  const addNseData = () => {
    let obj = {
      exchange: exchange.toUpperCase(),
      expiry: "2023-08-25",
      option: "AA",
      strikeprice: "0",
      symbol: selectedAlert?.symbol?.symbol,
      token: selectedAlert?.symbol?.token,
      tradingsymbol: selectedAlert?.symbol?.tradingsymbol,
      lot: String(selectedAlert?.lot),
    };

    if (watchListLiveData.length === 10) {
      return generatePopup("error", "Maximum 10 symbol can be allowed");
    }
    dispatch(addWatchListData(obj));
    handleClear();
    handleClose();
  };

  const handleClear = () => {
    setExchange("nse");
    setSearch("");
    setSelectedAlert("");
    setFutureExpiryData("");
    setCpExpiry("");
    setCpStrike("");
  };

  const handleRowSelection = (d, allRow = false, e = null) => {
    if (allRow) {
      if (e.target.checked) {
        // let idArr = alertData?.map((val) => val.id);
        setDeleteData(watchListLiveData);
      } else {
        setDeleteData([]);
      }
    } else {
      if (deleteData?.find((val) => val.token === d.token)) {
        setDeleteData(deleteData?.filter((rowId) => rowId.token !== d.token));
      } else {
        setDeleteData([...deleteData, d]);
      }
    }
  };
  const onDeleteWatchList = () => {
    setDeletePop(true);
  };

  const deleteWatchListData = () => {
    const obj = deleteData?.map((e) => {
      return {
        symbol: e?.symbol,
        token: e?.token,
        exchange: e?.exchange,
        tradingsymbol: e?.tradingsymbol,
        option: e?.option,
        expiry: e?.expiry,
        strikeprice: e?.strikeprice,
        lot: e?.lot,
      };
    });

    dispatch(deleteWatchList(obj));
    setDeleteData([]);
    setDeletePop(false);
  };

  const brokerStatus = useSelector((state) => state.Broker.brokerstatus?.[0]);

  const userData = useSelector((state) => state?.Auth?.authProfile?.[0]);

  const createTrade = () => {
    setLoad(true);
    if (tradeType === "Live") {
      if (!userData?.is_subscribe) {
        return generatePopup(
          "error",
          "Subscription is Required for Live Trade"
        );
      } else if (!brokerStatus?.brokerlogin) {
        return generatePopup(
          "error",
          "Broker login is Required for Live Trade."
        );
      }
    }
    if (qty === 0) {
      return generatePopup("error", "Please enter quantity.");
    }

    if (
      selectedStock?.lot &&
      selectedStock?.lot !== "0" &&
      selectedStock?.lot !== "undefined"
    ) {
      if (qty % Number(selectedStock?.lot) !== 0) {
        return generatePopup("error", "Please Select Valid Quantity.");
      }
    }

    let obj = {
      symbol: selectedStock?.symbol,
      product: prdType.toUpperCase(),
      exchange: selectedStock?.exchange,
      prctyp: orderType.toUpperCase(),
      quantity: Number(qty),
      side: buysell.toUpperCase(),
      complexty: "REGULAR",
      signaltype: buysell === "buy" ? "Entry" : "Exit",
      strikeprice: String(selectedStock?.strikeprice),
      prc: trp,
      trp: 0,
      prcvalue: 0,

      expiry: dayjs(selectedStock?.expiry).format("YYYY-MM-DD"),
      type:
        selectedStock?.option === "AA"
          ? "CASH"
          : selectedStock?.option === "XX"
            ? "FUTURE"
            : "OPTION",
      tag:
        selectedStock?.option === "CE"
          ? "CE"
          : selectedStock?.option === "PE"
            ? "PE"
            : "XX",

      instrumenttype:
        selectedStock.exchange === "NSE" || instType === "" ? "NA" : instType,
    };

    if (tradeType === "Live") {
      dispatch(createPosition(obj)).then((res) => {
        if (res) {
          setLoad(false);
          if (res?.status === 200) {
            generatePopup("success", res?.data?.message);
          } else {
            generatePopup("error", "Can Not Create Trade!");
          }
          setSelectedIndex();
          setSelectedStock();
          settTrp(0);
          buysellClose();
        }
      });
    } else {
      dispatch(createPaperPosition(obj)).then((res) => {
        if (res) {
          if (res?.status === 200) {
            generatePopup("success", res?.data?.message);
          }
          setSelectedIndex();
          setSelectedStock();
          settTrp(0);
          buysellClose();
          setTradeType("Live");
        }
      });
    }
  };

  return (
    <>
      <Box className="tabs">
        <TabContext value={value}>
          <TabList className="main-tab" onChange={handleChange}>
            <Tab label="MARKET WATCH" value={"mw1"} />
          </TabList>

          <Box className="tabBox" border={"none !important"}>
            <TabPanel value={"mw1"} sx={{ padding: 0 }}>
              <Box className="border-ap">
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          <Typography sx={{ fontSize: "1.4rem" }}>
                            Add Symbol
                          </Typography>
                        }
                      >
                        <Button
                          className="collapse-btn"
                          onClick={handleClickOpen}
                          sx={{ margin: "1rem 0 0 1rem" }}
                        >
                          <AddIcon />
                        </Button>
                      </Tooltip>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: 1,
                        }}
                      >
                        {/* <Tooltip
                          arrow
                          placement="top"
                          title={
                            <Typography sx={{ fontSize: "1.4rem" }}>
                              Refresh{" "}
                            </Typography>
                          }
                        >
                          <Button
                            className="collapse-btn"
                            sx={{ margin: "0 1rem 0 1rem" }}
                          >
                            <RefreshIcon />
                          </Button>
                        </Tooltip> */}
                        <Button
                          disabled={deleteData.length === 0}
                          onClick={() => onDeleteWatchList()}
                          className="delete_mw"
                        >
                          <DeleteIcon />
                        </Button>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <TableContainer sx={{ MarginTop: 16, maxHeight: 440 }}>
                      <Table
                        stickyHeader
                        sx={{ minWidth: "100%" }}
                        className={`table tableData`}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <Checkbox
                                sx={{
                                  "& .MuiSvgIcon-root": { fontSize: 24 },
                                  padding: 0,
                                }}
                                // onChange={(e) =>
                                //   handleRowSelection(undefined, true, e)
                                // }
                                onClick={(e) =>
                                  handleRowSelection(undefined, true, e)
                                }
                                checked={
                                  watchListLiveData?.length ==
                                  deleteData?.length
                                }
                              />
                            </TableCell>
                            <TableCell>Symbol Name</TableCell>
                            <TableCell>LTP</TableCell>
                            <TableCell>ATP</TableCell>
                            <TableCell>Open</TableCell>
                            <TableCell>High</TableCell>
                            <TableCell>Low</TableCell>
                            <TableCell>Close</TableCell>
                            <TableCell>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {watchListLiveData?.length > 0 ? (
                            <>
                              {watchListLiveData?.map((d, index) => {
                                return (
                                  <>
                                    <TableRow>
                                      <TableCell>
                                        <Checkbox
                                          checked={
                                            deleteData?.find(
                                              (e) => e.token === d.token
                                            )
                                              ? true
                                              : false
                                          }
                                          onChange={() => handleRowSelection(d)}
                                          sx={{
                                            "& .MuiSvgIcon-root": {
                                              fontSize: 24,
                                            },
                                            padding: 0,
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell>{d?.tradingsymbol}</TableCell>
                                      <TableCell>
                                        <Typography
                                          component={"span"}
                                          sx={{ fontWeight: 600 }}
                                        >
                                          {d?.liveprice}
                                        </Typography>
                                        <Typography
                                          component={"span"}
                                          sx={{ fontSize: "1.1rem !important" }}
                                        >
                                          {Number(d?.liveprice - d?.C).toFixed(
                                            2
                                          )}
                                        </Typography>
                                        <Typography
                                          component={"span"}
                                          className={
                                            Math.sign(d?.PC) === 1
                                              ? "up"
                                              : "down"
                                          }
                                        >
                                          {Math.sign(d?.PC) === 1 ? up : down}(
                                          {d.PC}
                                          %)
                                        </Typography>
                                      </TableCell>
                                      <TableCell>{d?.AP}</TableCell>
                                      <TableCell>{d?.O}</TableCell>
                                      <TableCell>
                                        <Typography
                                          component="span"
                                          className="open"
                                        >
                                          {d?.H}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          component="span"
                                          className="close"
                                        >
                                          {d?.L}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>{d?.C}</TableCell>
                                      <TableCell>
                                        <Box className="tableActions">
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                            className="actionButton"
                                          >
                                            <Button
                                              className="buy"
                                              variant="text"
                                              onClick={() =>
                                                buysellOpen("buy", d, index)
                                              }
                                            >
                                              <svg
                                                width="15"
                                                height="19"
                                                viewBox="0 0 15 19"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M10.622 9.718C11.5927 9.89133 12.416 10.4027 13.092 11.252C13.768 12.1013 14.106 13.0633 14.106 14.138C14.106 15.0567 13.8633 15.8887 13.378 16.634C12.91 17.362 12.2253 17.9427 11.324 18.376C10.4227 18.792 9.374 19 8.178 19H0.95V0.929999H7.84C9.07067 0.929999 10.128 1.138 11.012 1.554C11.896 1.97 12.5633 2.53333 13.014 3.244C13.4647 3.93733 13.69 4.71733 13.69 5.584C13.69 6.624 13.4127 7.49067 12.858 8.184C12.3033 8.87733 11.558 9.38867 10.622 9.718ZM3.914 8.522H7.58C8.55067 8.522 9.30467 8.30533 9.842 7.872C10.3967 7.42133 10.674 6.78 10.674 5.948C10.674 5.13333 10.3967 4.50067 9.842 4.05C9.30467 3.582 8.55067 3.348 7.58 3.348H3.914V8.522ZM7.918 16.582C8.92333 16.582 9.712 16.3393 10.284 15.854C10.856 15.3687 11.142 14.6927 11.142 13.826C11.142 12.942 10.8387 12.24 10.232 11.72C9.62533 11.2 8.81933 10.94 7.814 10.94H3.914V16.582H7.918Z"
                                                  fill="white"
                                                />
                                              </svg>
                                            </Button>
                                            <Button
                                              className="sell"
                                              variant="text"
                                              onClick={() =>
                                                buysellOpen("sell", d, index)
                                              }
                                            >
                                              <svg
                                                width="14"
                                                height="20"
                                                viewBox="0 0 14 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M6.904 19.182C5.69067 19.182 4.59867 18.974 3.628 18.558C2.65733 18.1247 1.89467 17.518 1.34 16.738C0.785333 15.958 0.508 15.048 0.508 14.008H3.68C3.74933 14.788 4.05267 15.4293 4.59 15.932C5.14467 16.4347 5.916 16.686 6.904 16.686C7.92667 16.686 8.724 16.4433 9.296 15.958C9.868 15.4553 10.154 14.814 10.154 14.034C10.154 13.4273 9.972 12.9333 9.608 12.552C9.26133 12.1707 8.81933 11.876 8.282 11.668C7.762 11.46 7.034 11.2347 6.098 10.992C4.91933 10.68 3.95733 10.368 3.212 10.056C2.484 9.72667 1.86 9.224 1.34 8.548C0.82 7.872 0.56 6.97067 0.56 5.844C0.56 4.804 0.82 3.894 1.34 3.114C1.86 2.334 2.588 1.736 3.524 1.32C4.46 0.903999 5.54333 0.695999 6.774 0.695999C8.52467 0.695999 9.95467 1.138 11.064 2.022C12.1907 2.88867 12.8147 4.08467 12.936 5.61H9.66C9.608 4.95133 9.296 4.388 8.724 3.92C8.152 3.452 7.398 3.218 6.462 3.218C5.61267 3.218 4.91933 3.43467 4.382 3.868C3.84467 4.30133 3.576 4.92533 3.576 5.74C3.576 6.29467 3.74067 6.754 4.07 7.118C4.41667 7.46467 4.85 7.742 5.37 7.95C5.89 8.158 6.60067 8.38333 7.502 8.626C8.698 8.95533 9.66867 9.28467 10.414 9.614C11.1767 9.94333 11.818 10.4547 12.338 11.148C12.8753 11.824 13.144 12.734 13.144 13.878C13.144 14.7967 12.8927 15.6633 12.39 16.478C11.9047 17.2927 11.1853 17.9513 10.232 18.454C9.296 18.9393 8.18667 19.182 6.904 19.182Z"
                                                  fill="white"
                                                />
                                              </svg>
                                            </Button>
                                            {/* <Button className="bsdel" variant="text">
                                        <DeleteIcon />
                                      </Button> */}
                                          </Box>
                                        </Box>
                                      </TableCell>
                                    </TableRow>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <TableRow className="noHover">
                              <TableCell colSpan={12}>
                                <Box className="no-data">
                                  <img src={noData} />
                                </Box>
                                <Typography
                                  component={"p"}
                                  className="no-dataText"
                                >
                                  You have no position, place an order to open a
                                  new position
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    {/* <Box
                      className="tablePagination"
                      sx={{
                        display: "flex",
                        justifyContent: { xs: "center", md: "end" },
                        padding: "0 1rem",
                      }}
                    >
                      <TablePagination
                        rowsPerPageOptions={[
                          10,
                          20,
                          30,
                          { label: "All", value: -1 },
                        ]}
                        SelectProps={{
                          fontSize: "1.6rem",
                          native: false,
                        }}
                        className="tablePagination"
                      />
                    </Box> */}
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>

            <TabPanel value={"mw2"} sx={{ padding: 0 }}>
              <Box className="border-ap">
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          <Typography sx={{ fontSize: "1.4rem" }}>
                            Add Symbol
                          </Typography>
                        }
                      >
                        <Button
                          className="collapse-btn"
                          onClick={handleClickOpen}
                          sx={{ margin: "1rem 0 0 1rem" }}
                        >
                          <AddIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          <Typography sx={{ fontSize: "1.4rem" }}>
                            Refresh{" "}
                          </Typography>
                        }
                      >
                        <Button
                          className="collapse-btn"
                          sx={{ margin: "0 1rem 0 1rem" }}
                        >
                          <RefreshIcon />
                        </Button>
                      </Tooltip>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <TableContainer sx={{ MarginTop: 16, maxHeight: 440 }}>
                      <Table
                        stickyHeader
                        sx={{ minWidth: "100%" }}
                        className={`table tableData`}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <Checkbox
                                sx={{
                                  "& .MuiSvgIcon-root": { fontSize: 24 },
                                  padding: 0,
                                }}
                              />
                            </TableCell>
                            <TableCell>Symbol Name</TableCell>
                            <TableCell>LTP</TableCell>
                            <TableCell>ATP</TableCell>
                            <TableCell>Open</TableCell>
                            <TableCell>High</TableCell>
                            <TableCell>Low</TableCell>
                            <TableCell>Close</TableCell>
                            <TableCell>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Checkbox
                                sx={{
                                  "& .MuiSvgIcon-root": { fontSize: 24 },
                                  padding: 0,
                                }}
                              />
                            </TableCell>
                            <TableCell>Banknifty23130770</TableCell>
                            <TableCell>
                              <Typography
                                component={"span"}
                                sx={{ fontWeight: 600 }}
                              >
                                1585.50
                              </Typography>
                              <Typography
                                component={"span"}
                                sx={{ fontSize: "1.1rem !important" }}
                              >
                                10.55
                              </Typography>
                              <Typography component={"span"} className="up">
                                {" "}
                                {up}0.14%
                              </Typography>
                            </TableCell>
                            <TableCell>66069.09</TableCell>
                            <TableCell>--</TableCell>
                            <TableCell>
                              <Typography component="span" className="open">
                                23.22
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography component="span" className="close">
                                12.00
                              </Typography>
                            </TableCell>
                            <TableCell>Close</TableCell>
                            <TableCell>
                              <Box className="tableActions">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  className="actionButton"
                                >
                                  <Button
                                    className="buy"
                                    variant="text"
                                    onClick={buysellOpen}
                                  >
                                    <svg
                                      width="15"
                                      height="19"
                                      viewBox="0 0 15 19"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M10.622 9.718C11.5927 9.89133 12.416 10.4027 13.092 11.252C13.768 12.1013 14.106 13.0633 14.106 14.138C14.106 15.0567 13.8633 15.8887 13.378 16.634C12.91 17.362 12.2253 17.9427 11.324 18.376C10.4227 18.792 9.374 19 8.178 19H0.95V0.929999H7.84C9.07067 0.929999 10.128 1.138 11.012 1.554C11.896 1.97 12.5633 2.53333 13.014 3.244C13.4647 3.93733 13.69 4.71733 13.69 5.584C13.69 6.624 13.4127 7.49067 12.858 8.184C12.3033 8.87733 11.558 9.38867 10.622 9.718ZM3.914 8.522H7.58C8.55067 8.522 9.30467 8.30533 9.842 7.872C10.3967 7.42133 10.674 6.78 10.674 5.948C10.674 5.13333 10.3967 4.50067 9.842 4.05C9.30467 3.582 8.55067 3.348 7.58 3.348H3.914V8.522ZM7.918 16.582C8.92333 16.582 9.712 16.3393 10.284 15.854C10.856 15.3687 11.142 14.6927 11.142 13.826C11.142 12.942 10.8387 12.24 10.232 11.72C9.62533 11.2 8.81933 10.94 7.814 10.94H3.914V16.582H7.918Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </Button>
                                  <Button
                                    className="sell"
                                    variant="text"
                                    onClick={buysellOpen}
                                  >
                                    <svg
                                      width="14"
                                      height="20"
                                      viewBox="0 0 14 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M6.904 19.182C5.69067 19.182 4.59867 18.974 3.628 18.558C2.65733 18.1247 1.89467 17.518 1.34 16.738C0.785333 15.958 0.508 15.048 0.508 14.008H3.68C3.74933 14.788 4.05267 15.4293 4.59 15.932C5.14467 16.4347 5.916 16.686 6.904 16.686C7.92667 16.686 8.724 16.4433 9.296 15.958C9.868 15.4553 10.154 14.814 10.154 14.034C10.154 13.4273 9.972 12.9333 9.608 12.552C9.26133 12.1707 8.81933 11.876 8.282 11.668C7.762 11.46 7.034 11.2347 6.098 10.992C4.91933 10.68 3.95733 10.368 3.212 10.056C2.484 9.72667 1.86 9.224 1.34 8.548C0.82 7.872 0.56 6.97067 0.56 5.844C0.56 4.804 0.82 3.894 1.34 3.114C1.86 2.334 2.588 1.736 3.524 1.32C4.46 0.903999 5.54333 0.695999 6.774 0.695999C8.52467 0.695999 9.95467 1.138 11.064 2.022C12.1907 2.88867 12.8147 4.08467 12.936 5.61H9.66C9.608 4.95133 9.296 4.388 8.724 3.92C8.152 3.452 7.398 3.218 6.462 3.218C5.61267 3.218 4.91933 3.43467 4.382 3.868C3.84467 4.30133 3.576 4.92533 3.576 5.74C3.576 6.29467 3.74067 6.754 4.07 7.118C4.41667 7.46467 4.85 7.742 5.37 7.95C5.89 8.158 6.60067 8.38333 7.502 8.626C8.698 8.95533 9.66867 9.28467 10.414 9.614C11.1767 9.94333 11.818 10.4547 12.338 11.148C12.8753 11.824 13.144 12.734 13.144 13.878C13.144 14.7967 12.8927 15.6633 12.39 16.478C11.9047 17.2927 11.1853 17.9513 10.232 18.454C9.296 18.9393 8.18667 19.182 6.904 19.182Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </Button>
                                  <Button className="bsdel" variant="text">
                                    <DeleteIcon />
                                  </Button>
                                </Box>
                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <Box
                      className="tablePagination"
                      sx={{
                        display: "flex",
                        justifyContent: { xs: "center", md: "end" },
                        padding: "0 1rem",
                      }}
                    >
                      <TablePagination
                        rowsPerPageOptions={[
                          10,
                          20,
                          30,
                          { label: "All", value: -1 },
                        ]}
                        SelectProps={{
                          fontSize: "1.6rem",
                          native: false,
                        }}
                        className="tablePagination"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
          </Box>
        </TabContext>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        className="commonModal addSymbol"
        fullWidth
      >
        <Box className="modalHeader" sx={{ justifyContent: "space-between" }}>
          <Typography component={"h4"}>Add Symbol</Typography>
          <Button onClick={handleClose} className="closeModal">
            <img src={close} />
          </Button>
        </Box>
        <DialogContent sx={{ padding: "0" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="tabs">
              <TabContext value={exchange}>
                <TabList className="main-tab" onChange={handleExchange}>
                  <Tab label="NSE" value={"nse"} />
                  <Tab label="NFO" value={"nfo"} />
                  <Tab label="CDS" value={"cds"} />
                  <Tab label="MCX" value={"mcx"} />
                  <Tab label="BFO" value={"bfo"} />
                </TabList>

                <Box className="tabBox" border={"none !important"}>
                  <TabPanel value={"nse"} sx={{ padding: 0 }}>
                    <Autoselectdrp
                      exchanges={watchListData.length > 0 ? watchListData : []}
                      selectedValue={search}
                      handleAlertSearch={handleSearch}
                      handleSelectAlert={handleSelectAlert}
                      selectedAlert={selectedAlert}
                      segment="nse"
                    />
                    <Button
                      className="refresh-btn solidButton"
                      sx={{ width: "auto !important", marginTop: 2 }}
                      onClick={() => addNseData()}
                    >
                      Add Symbol
                    </Button>
                  </TabPanel>

                  <TabPanel value={"nfo"} sx={{ padding: 0 }}>
                    <Autoselectdrp
                      exchanges={
                        watchListData?.exchange?.length > 0
                          ? watchListData.exchange
                          : []
                      }
                      selectedValue={search}
                      handleAlertSearch={handleSearch}
                      handleSelectAlert={handleSelectAlert}
                      selectedAlert={selectedAlert}
                    />
                    <Box className="tabs" sx={{ marginTop: 3 }}>
                      <TabContext value={options}>
                        <TabList
                          className="main-tab subMain-tab"
                          onChange={handleChangeOptions}
                        >
                          <Tab label="FUTURE" value={"future"} />
                          <Tab label="CALL" value={"call"} />
                          <Tab label="PUT" value={"put"} />
                        </TabList>

                        <Box className="tabBox" border={"none !important"}>
                          <TabPanel value={"future"} sx={{ padding: 0 }}>
                            <Autoselect
                              val={expiryList}
                              handleExpiryChange={handleExpiryChange}
                              futureExpiryData={futureExpiryData}
                            />
                          </TabPanel>
                          <TabPanel value={"call"} sx={{ padding: 0 }}>
                            <Grid container>
                              <Grid item md={6} sx={{ paddingRight: "1rem" }}>
                                <Typography
                                  component={"label"}
                                  className="label"
                                >
                                  Expiry
                                </Typography>
                                <Box class="selectiondiv-box fullWidth d-block">
                                  <Dropdown
                                    val={optionExpiryList.exchange ?? []}
                                    value={cpExpiry}
                                    handleSelect={handleCPExpiryChange}
                                  />
                                </Box>
                              </Grid>
                              <Grid item md={6} sx={{ paddingLeft: "1rem" }}>
                                <Typography
                                  component={"label"}
                                  className="label"
                                >
                                  Strike
                                </Typography>
                                <Box className="dropdown-ap inputFields">
                                  <Autocomplete
                                    onInputChange={handleStrikeSearch}
                                    placeholder="Select Broker"
                                    value={cpStrike || ""}
                                    inputValue={cpStrike || ""}
                                    className="dropdown "
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Select Broker"
                                      />
                                    )}
                                    options={optionStrikeList?.map(
                                      (e) => e?.strikeprice
                                    )}
                                    onChange={handleStrikeSelect}
                                    getOptionLabel={(option) => option}
                                    sx={{
                                      "& > .MuiFormControl-root > .MuiInputBase-root":
                                      {
                                        padding: "0.5rem !important",
                                      },
                                    }}
                                  />
                                </Box>
                                {/* <Autoselect
                                  val={optionStrikeList}
                                  futureExpiryData={cpStrike || ""}
                                  handleExpiryChange={handleCPStrikeChange}
                                  showStrikePrice={true}
                                  mt={'1rem'}
                                />
                              </Grid>
                            </Grid>
                          </TabPanel>
                          <TabPanel value={"put"} sx={{ padding: 0 }}>
                            <Grid container>
                              <Grid item md={6} sx={{ paddingRight: '1rem' }}>
                                <Typography
                                  component={"label"}
                                  className="label"
                                >
                                  Expiry
                                </Typography>
                                <Box class="selectiondiv-box fullWidth d-block">
                                  <Dropdown
                                    val={optionExpiryList.exchange ?? []}
                                    value={cpExpiry}
                                    handleSelect={handleCPExpiryChange}
                                  />
                                </Box>
                              </Grid>
                              <Grid item md={6} sx={{ paddingLeft: '1rem' }}>
                                <Typography
                                  component={"label"}
                                  className="label"
                                >
                                  Strike
                                </Typography>
                                <Box className="dropdown-ap">
                                  <Autocomplete
                                    onInputChange={handleStrikeSearch}
                                    placeholder="Select Broker"
                                    value={cpStrike || ""}
                                    inputValue={cpStrike || ""}
                                    className="dropdown"
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Select Broker"
                                      />
                                    )}
                                    options={optionStrikeList?.map(
                                      (e) => e?.strikeprice
                                    )}
                                    onChange={handleStrikeSelect}
                                    getOptionLabel={(option) => option}
                                  />
                                </Box>
                                {/* <Autoselect
                                  val={optionStrikeList}
                                  futureExpiryData={cpStrike || ""}
                                  handleExpiryChange={handleCPStrikeChange}
                                  showStrikePrice={true}
                                /> */}
                              </Grid>
                            </Grid>
                          </TabPanel>
                          <TabPanel value={"put"} sx={{ padding: 0 }}>
                            <Grid container>
                              <Grid item md={6} sx={{ paddingRight: "1rem" }}>
                                <Typography
                                  component={"label"}
                                  className="label"
                                >
                                  Expiry
                                </Typography>
                                <Box class="selectiondiv-box fullWidth d-block">
                                  <Dropdown
                                    val={optionExpiryList.exchange ?? []}
                                    value={cpExpiry}
                                    handleSelect={handleCPExpiryChange}
                                  />
                                </Box>
                              </Grid>
                              <Grid item md={6} sx={{ paddingLeft: "1rem" }}>
                                <Typography
                                  component={"label"}
                                  className="label"
                                >
                                  Strike
                                </Typography>
                                <Box className="dropdown-ap">
                                  <Autocomplete
                                    onInputChange={handleStrikeSearch}
                                    placeholder="Select Broker"
                                    value={cpStrike || ""}
                                    inputValue={cpStrike || ""}
                                    className="dropdown"
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Select Broker"
                                      />
                                    )}
                                    options={optionStrikeList?.map(
                                      (e) => e?.strikeprice
                                    )}
                                    onChange={handleStrikeSelect}
                                    getOptionLabel={(option) => option}
                                    sx={{
                                      "& > .MuiFormControl-root > .MuiInputBase-root":
                                      {
                                        padding: "0.5rem !important",
                                      },
                                    }}
                                  />
                                </Box>
                                {/* <Autoselect
                                  val={optionStrikeList}
                                  futureExpiryData={cpStrike || ""}
                                  handleExpiryChange={handleCPStrikeChange}
                                  showStrikePrice={true}
                                /> */}
                              </Grid>
                            </Grid>
                          </TabPanel>
                        </Box>
                      </TabContext>
                    </Box>
                    <Button
                      className="refresh-btn solidButton"
                      sx={{ width: "auto !important", marginTop: 2 }}
                      onClick={() => addNfoSymbol()}
                    >
                      Add Symbol
                    </Button>
                  </TabPanel>

                  <TabPanel value={"cds"} sx={{ padding: 0 }}>
                    <Autoselectdrp
                      exchanges={
                        watchListData?.exchange?.length > 0
                          ? watchListData.exchange
                          : []
                      }
                      selectedValue={search}
                      handleAlertSearch={handleSearch}
                      handleSelectAlert={handleSelectAlert}
                      selectedAlert={selectedAlert}
                    />

                    <Box className="tabs" sx={{ marginTop: 3 }}>
                      <TabContext value={options}>
                        <TabList
                          className="main-tab subMain-tab"
                          onChange={handleChangeOptions}
                        >
                          <Tab label="FUTURE" value={"future"} />
                          <Tab label="CALL" value={"call"} />
                          <Tab label="PUT" value={"put"} />
                        </TabList>

                        <Box className="tabBox" border={"none !important"}>
                          <TabPanel value={"future"} sx={{ padding: 0 }}>
                            <Autoselect
                              val={expiryList}
                              handleExpiryChange={handleExpiryChange}
                              futureExpiryData={futureExpiryData}
                            />
                          </TabPanel>
                          <TabPanel value={"call"} sx={{ padding: 0 }}>
                            <Grid container>
                              <Grid item md={6} sx={{ paddingRight: "1rem" }}>
                                <Typography
                                  component={"label"}
                                  className="label"
                                >
                                  Expiry
                                </Typography>
                                <Box class="selectiondiv-box fullWidth d-block">
                                  <Dropdown
                                    val={optionExpiryList.exchange ?? []}
                                    value={cpExpiry}
                                    handleSelect={handleCPExpiryChange}
                                  />
                                </Box>
                              </Grid>
                              <Grid item md={6} sx={{ paddingLeft: "1rem" }}>
                                <Typography
                                  component={"label"}
                                  className="label"
                                >
                                  Strike
                                </Typography>
                                <Box className="dropdown-ap">
                                  <Autocomplete
                                    onInputChange={handleStrikeSearch}
                                    placeholder="Select Broker"
                                    value={cpStrike || ""}
                                    inputValue={cpStrike || ""}
                                    className="dropdown"
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Select Broker"
                                      />
                                    )}
                                    options={optionStrikeList?.map(
                                      (e) => e?.strikeprice
                                    )}
                                    onChange={handleStrikeSelect}
                                    getOptionLabel={(option) => option}
                                    sx={{
                                      "& > .MuiFormControl-root > .MuiInputBase-root":
                                      {
                                        padding: "0.5rem !important",
                                      },
                                    }}
                                  />
                                </Box>
                                {/* <Autoselect
                                  val={optionStrikeList}
                                  futureExpiryData={cpStrike || ""}
                                  handleExpiryChange={handleCPStrikeChange}
                                  showStrikePrice={true}
                                /> */}
                              </Grid>
                            </Grid>
                          </TabPanel>
                          <TabPanel value={"put"} sx={{ padding: 0 }}>
                            <Grid container>
                              <Grid item md={6} sx={{ paddingRight: "1rem" }}>
                                <Typography
                                  component={"label"}
                                  className="label"
                                >
                                  Expiry
                                </Typography>
                                <Box class="selectiondiv-box fullWidth d-block">
                                  <Dropdown
                                    val={optionExpiryList.exchange ?? []}
                                    value={cpExpiry}
                                    handleSelect={handleCPExpiryChange}
                                  />
                                </Box>
                              </Grid>
                              <Grid item md={6} sx={{ paddingLeft: "1rem" }}>
                                <Typography
                                  component={"label"}
                                  className="label"
                                >
                                  Strike
                                </Typography>
                                <Box className="dropdown-ap">
                                  <Autocomplete
                                    onInputChange={handleStrikeSearch}
                                    placeholder="Select Broker"
                                    value={cpStrike || ""}
                                    inputValue={cpStrike || ""}
                                    className="dropdown"
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Select Broker"
                                      />
                                    )}
                                    options={optionStrikeList?.map(
                                      (e) => e?.strikeprice
                                    )}
                                    onChange={handleStrikeSelect}
                                    getOptionLabel={(option) => option}
                                    sx={{
                                      "& > .MuiFormControl-root > .MuiInputBase-root":
                                      {
                                        padding: "0.5rem !important",
                                      },
                                    }}
                                  />
                                </Box>
                                {/* <Autoselect
                                  val={optionStrikeList}
                                  futureExpiryData={cpStrike || ""}
                                  handleExpiryChange={handleCPStrikeChange}
                                  showStrikePrice={true}
                                /> */}
                              </Grid>
                            </Grid>
                          </TabPanel>
                        </Box>
                      </TabContext>
                    </Box>
                    <Button
                      className="refresh-btn solidButton"
                      sx={{ width: "auto !important", marginTop: 2 }}
                      onClick={() => addCdsSymbol()}
                    >
                      Add Symbol
                    </Button>
                  </TabPanel>

                  <TabPanel value={"mcx"} sx={{ padding: 0 }}>
                    <Autoselectdrp
                      exchanges={
                        watchListData?.exchange?.length > 0
                          ? watchListData.exchange
                          : []
                      }
                      selectedValue={search}
                      handleAlertSearch={handleSearch}
                      handleSelectAlert={handleSelectAlert}
                      selectedAlert={selectedAlert}
                    />
                    <Box className="tabs" sx={{ marginTop: 3 }}>
                      <TabContext value={options}>
                        <TabList
                          className="main-tab subMain-tab"
                          onChange={handleChangeOptions}
                        >
                          <Tab label="FUTURE" value={"future"} />
                          <Tab label="CALL" value={"call"} />
                          <Tab label="PUT" value={"put"} />
                        </TabList>

                        <Box className="tabBox" border={"none !important"}>
                          <TabPanel value={"future"} sx={{ padding: 0 }}>
                            <Autoselect
                              val={expiryList}
                              handleExpiryChange={handleExpiryChange}
                              futureExpiryData={futureExpiryData}
                            />
                          </TabPanel>
                          <TabPanel value={"call"} sx={{ padding: 0 }}>
                            <Grid container>
                              <Grid item md={6} sx={{ paddingRight: "1rem" }}>
                                <Typography
                                  component={"label"}
                                  className="label"
                                >
                                  Expiry
                                </Typography>
                                <Box class="selectiondiv-box fullWidth d-block">
                                  <Dropdown
                                    val={optionExpiryList.exchange ?? []}
                                    value={cpExpiry}
                                    handleSelect={handleCPExpiryChange}
                                  />
                                </Box>
                              </Grid>
                              <Grid item md={6} sx={{ paddingLeft: "1rem" }}>
                                <Typography
                                  component={"label"}
                                  className="label"
                                >
                                  Strike
                                </Typography>
                                <Box className="dropdown-ap">
                                  <Autocomplete
                                    onInputChange={handleStrikeSearch}
                                    placeholder="Select Broker"
                                    value={cpStrike || ""}
                                    inputValue={cpStrike || ""}
                                    className="dropdown"
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Select Broker"
                                      />
                                    )}
                                    options={optionStrikeList?.map(
                                      (e) => e?.strikeprice
                                    )}
                                    onChange={handleStrikeSelect}
                                    getOptionLabel={(option) => option}
                                    sx={{
                                      "& > .MuiFormControl-root > .MuiInputBase-root":
                                      {
                                        padding: "0.5rem !important",
                                      },
                                    }}
                                  />
                                </Box>

                                {/* <Autoselect
                                  val={optionStrikeList}
                                  futureExpiryData={cpStrike || ""}
                                  handleExpiryChange={handleCPStrikeChange}
                                  showStrikePrice={true}
                                /> */}
                              </Grid>
                            </Grid>
                          </TabPanel>
                          <TabPanel value={"put"} sx={{ padding: 0 }}>
                            <Grid container>
                              <Grid item md={6} sx={{ paddingRight: "1rem" }}>
                                <Typography
                                  component={"label"}
                                  className="label"
                                >
                                  Expiry
                                </Typography>
                                <Box class="selectiondiv-box fullWidth d-block">
                                  <Dropdown
                                    val={optionExpiryList.exchange ?? []}
                                    value={cpExpiry}
                                    handleSelect={handleCPExpiryChange}
                                  />
                                </Box>
                              </Grid>
                              <Grid item md={6} sx={{ paddingLeft: "1rem" }}>
                                <Typography
                                  component={"label"}
                                  className="label"
                                >
                                  Strike
                                </Typography>
                                <Box className="dropdown-ap">
                                  <Autocomplete
                                    onInputChange={handleStrikeSearch}
                                    placeholder="Select Broker"
                                    value={cpStrike || ""}
                                    inputValue={cpStrike || ""}
                                    className="dropdown"
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Select Broker"
                                      />
                                    )}
                                    options={optionStrikeList?.map(
                                      (e) => e?.strikeprice
                                    )}
                                    onChange={handleStrikeSelect}
                                    getOptionLabel={(option) => option}
                                    sx={{
                                      "& > .MuiFormControl-root > .MuiInputBase-root":
                                      {
                                        padding: "0.5rem !important",
                                      },
                                    }}
                                  />
                                </Box>
                                {/* <Autoselect
                                  val={optionStrikeList}
                                  futureExpiryData={cpStrike || ""}
                                  handleExpiryChange={handleCPStrikeChange}
                                  showStrikePrice={true}
                                /> */}
                              </Grid>
                            </Grid>
                          </TabPanel>
                        </Box>
                      </TabContext>
                    </Box>
                    <Button
                      className="refresh-btn solidButton"
                      sx={{ width: "auto !important", marginTop: 2 }}
                      onClick={() => addMcxSymbol()}
                    >
                      Add Symbol
                    </Button>
                  </TabPanel>

                  <TabPanel value={"bfo"} sx={{ padding: 0 }}>
                    <Autoselectdrp
                      exchanges={
                        watchListData?.exchange?.length > 0
                          ? watchListData.exchange
                          : []
                      }
                      selectedValue={search}
                      handleAlertSearch={handleSearch}
                      handleSelectAlert={handleSelectAlert}
                      selectedAlert={selectedAlert}
                    />
                    <Box className="tabs" sx={{ marginTop: 3 }}>
                      <TabContext value={options}>
                        <TabList
                          className="main-tab subMain-tab"
                          onChange={handleChangeOptions}
                        >
                          <Tab label="FUTURE" value={"future"} />
                          <Tab label="CALL" value={"call"} />
                          <Tab label="PUT" value={"put"} />
                        </TabList>

                        <Box className="tabBox" border={"none !important"}>
                          <TabPanel value={"future"} sx={{ padding: 0 }}>
                            <Autoselect
                              val={expiryList}
                              handleExpiryChange={handleExpiryChange}
                              futureExpiryData={futureExpiryData}
                            />
                          </TabPanel>
                          <TabPanel value={"call"} sx={{ padding: 0 }}>
                            <Grid container>
                              <Grid item md={6} sx={{ paddingRight: "1rem" }}>
                                <Typography
                                  component={"label"}
                                  className="label"
                                >
                                  Expiry
                                </Typography>
                                <Box class="selectiondiv-box fullWidth d-block">
                                  <Dropdown
                                    val={optionExpiryList.exchange ?? []}
                                    value={cpExpiry}
                                    handleSelect={handleCPExpiryChange}
                                  />
                                </Box>
                              </Grid>
                              <Grid item md={6} sx={{ paddingLeft: "1rem" }}>
                                <Typography
                                  component={"label"}
                                  className="label"
                                >
                                  Strike
                                </Typography>
                                <Box className="dropdown-ap">
                                  <Autocomplete
                                    onInputChange={handleStrikeSearch}
                                    placeholder="Select Broker"
                                    value={cpStrike || ""}
                                    inputValue={cpStrike || ""}
                                    className="dropdown"
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Select Broker"
                                      />
                                    )}
                                    options={optionStrikeList?.map(
                                      (e) => e?.strikeprice
                                    )}
                                    onChange={handleStrikeSelect}
                                    getOptionLabel={(option) => option}
                                    sx={{
                                      "& > .MuiFormControl-root > .MuiInputBase-root":
                                      {
                                        padding: "0.5rem !important",
                                      },
                                    }}
                                  />
                                </Box>

                                {/* <Autoselect
                                  val={optionStrikeList}
                                  futureExpiryData={cpStrike || ""}
                                  handleExpiryChange={handleCPStrikeChange}
                                  showStrikePrice={true}
                                /> */}
                              </Grid>
                            </Grid>
                          </TabPanel>
                          <TabPanel value={"put"} sx={{ padding: 0 }}>
                            <Grid container>
                              <Grid item md={6} sx={{ paddingRight: "1rem" }}>
                                <Typography
                                  component={"label"}
                                  className="label"
                                >
                                  Expiry
                                </Typography>
                                <Box class="selectiondiv-box fullWidth d-block">
                                  <Dropdown
                                    val={optionExpiryList.exchange ?? []}
                                    value={cpExpiry}
                                    handleSelect={handleCPExpiryChange}
                                  />
                                </Box>
                              </Grid>
                              <Grid item md={6} sx={{ paddingLeft: "1rem" }}>
                                <Typography
                                  component={"label"}
                                  className="label"
                                >
                                  Strike
                                </Typography>
                                <Box className="dropdown-ap">
                                  <Autocomplete
                                    onInputChange={handleStrikeSearch}
                                    placeholder="Select Broker"
                                    value={cpStrike || ""}
                                    inputValue={cpStrike || ""}
                                    className="dropdown"
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Select Broker"
                                      />
                                    )}
                                    options={optionStrikeList?.map(
                                      (e) => e?.strikeprice
                                    )}
                                    onChange={handleStrikeSelect}
                                    getOptionLabel={(option) => option}
                                    sx={{
                                      "& > .MuiFormControl-root > .MuiInputBase-root":
                                      {
                                        padding: "0.5rem !important",
                                      },
                                    }}
                                  />
                                </Box>
                                {/* <Autoselect
                                  val={optionStrikeList}
                                  futureExpiryData={cpStrike || ""}
                                  handleExpiryChange={handleCPStrikeChange}
                                  showStrikePrice={true}
                                /> */}
                              </Grid>
                            </Grid>
                          </TabPanel>
                        </Box>
                      </TabContext>
                    </Box>
                    <Button
                      className="refresh-btn solidButton"
                      sx={{ width: "auto !important", marginTop: 2 }}
                      onClick={() => addMcxSymbol()}
                    >
                      Add Symbol
                    </Button>
                  </TabPanel>
                </Box>
              </TabContext>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* Buy/Sell */}
      <Dialog
        open={bsopen}
        onClose={buysellClose}
        className={`commonModal buysellModal ${sell}`}
      >
        <DialogContent sx={{ padding: "0 !important" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="buysell-box" sx={{ backgroundColor: sell }}>
              <Box className="buysell_header">
                <Typography component={"h4"} className="symbol">
                  {buysell?.toUpperCase() + " " + selectedStock?.tradingsymbol}
                  <Typography component={"small"} className="symbol_type">
                    {selectedStock?.exchange}
                  </Typography>
                </Typography>
                <Switch onChange={buysellpopup} />
              </Box>
              <Box display={"flex"} gap={1}>
                <Typography
                  style={{
                    fontWeight: "bold",
                  }}
                  className={
                    Math.sign(selectedStock?.PC) === 1 ? "sym " : "sym "
                  }
                >
                  {selectedStock?.liveprice}{" "}
                  {Math.sign(selectedStock?.PC) === 1 ? arrowUp : arrowDown}
                </Typography>
                <Typography className="sym ">
                  {Number(selectedStock?.liveprice - selectedStock?.C).toFixed(
                    2
                  )}
                </Typography>
                <Typography className="static">
                  ({selectedStock?.PC} %)
                </Typography>
              </Box>
            </Box>
            <Box className="tabs buysellModal-tabs">
              <Box
                className="tabBox"
                sx={{
                  border: "none !important",
                  padding: 1,
                  width: "auto !important",
                }}
              >
                <Box
                  className="formBox"
                  sx={{
                    border: "none",
                    display: "flex",
                    alignItems: "flex-start",
                    flexWrap: { xs: "wrap", md: "nowrap" },
                    marginTop: 1,
                  }}
                >
                  <Box className="formItems">
                    <Typography
                      component={"label"}
                      className="label"
                      sx={{ fontSize: "1.4rem !important" }}
                    >
                      Quantity
                    </Typography>
                    <Box className="inputFields space">
                      <InputBase
                        value={qty}
                        onChange={(e) => setQty(Number(e.target.value))}
                        placeholder="Quantity"
                      />
                    </Box>
                  </Box>
                  {/* <Box className="formItems" sx={{ padding: "0 0.8rem" }}>
                    <Typography
                      component={"label"}
                      className="label"
                      sx={{ fontSize: "1.4rem !important" }}
                    >
                      Price
                    </Typography>
                    <Box className="inputFields space">
                      <InputBase
                        onChange={(e) => setPrc(Number(e.target.value))}
                        placeholder="Price"
                        type="number"
                        disabled={orderType == "Market" || orderType == "SL-M"}
                      />
                    </Box>

                    <Box className="formItems buysell-radio">
                      <Box className="inputFields space fullWidth">
                        <RadioGroup
                          row
                          value={orderType}
                          type="number"
                          onChange={(e) => setOrderType(e.target.value)}
                        >
                          <FormControlLabel
                            value="Market"
                            sx={{ marginRight: "1rem" }}
                            control={<Radio />}
                            label="Market"
                          />
                          <FormControlLabel
                            value="Limit"
                            sx={{ marginRight: "1rem" }}
                            control={<Radio />}
                            label="Limit"
                          />
                        </RadioGroup>
                      </Box>
                    </Box>
                  </Box> */}
                  <Box className="formItems" sx={{ padding: "0 0 0 0.8rem" }}>
                    <Typography
                      component={"label"}
                      className="label"
                      sx={{ fontSize: "1.4rem !important" }}
                    >
                      TradeType
                    </Typography>
                    <Box
                      className="inputFields space fullWidth"
                      sx={{
                        "& > .selectionDiv": {
                          padding: "0 !important",
                          marginTop: "0 !important",
                          border: "none !important",
                        },
                      }}
                    >
                      <Dropdown
                        val={
                          broker === "sharekhan" ? sharekhanDrpValue : drpValue
                        }
                        value={prdType}
                        handleSelect={handlePrdChange}
                      />
                    </Box>
                  </Box>
                  {broker === "sharekhan" && (
                    <Box className="formItems" sx={{ padding: "0 0 0 0.8rem" }}>
                      <Typography
                        component={"label"}
                        className="label"
                        sx={{ fontSize: "1.4rem !important" }}
                      >
                        Instrument
                      </Typography>
                      <Box
                        className="inputFields space fullWidth"
                        sx={{
                          "& > .selectionDiv": {
                            padding: "0 !important",
                            marginTop: "0 !important",
                            border: "none !important",
                          },
                        }}
                      >
                        <Box
                          className="selectionDiv border scroll display-none"
                          sx={{
                            display: { xs: "flex" },
                            marginLeft: { xs: "0 !important" },
                            padding: "1rem",
                          }}
                        >
                          <Typography component={"p"}>
                            Instrument &nbsp;
                          </Typography>
                          <FormControl className="dropdown-ap">
                            <Select
                              className="dropdown"
                              value={instType}
                              onChange={handleInstrument}
                              renderValue={
                                instType !== ""
                                  ? undefined
                                  : () => (
                                    <Typography sx={{ opacity: 0.5 }}>
                                      Select
                                    </Typography>
                                  )
                              }
                            >
                              {Object.keys(instrumentTypes).map(
                                (vals, index) => (
                                  <MenuItem value={vals} key={index}>
                                    {instrumentTypes[vals]}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {orderType !== "Market" && (
                    <Box className="formItems" sx={{ padding: "0 0.8rem" }}>
                      <Typography
                        component={"label"}
                        className="label"
                        sx={{ fontSize: "1.4rem !important" }}
                      >
                        Trigger Price
                      </Typography>
                      <Box className="inputFields space">
                        <InputBase
                          placeholder="Price"
                          type="number"
                          onChange={(e) => settTrp(Number(e.target.value))}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box
                  className="formItems buysell-radio"
                  sx={{ padding: "1rem 0 0 0.8rem" }}
                >
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    className="inputFields space fullWidth"
                  >
                    <RadioGroup
                      sx={{ width: "50%" }}
                      row
                      value={orderType}
                      type="number"
                      onChange={(e) => setOrderType(e.target.value)}
                    >
                      <FormControlLabel
                        value="Market"
                        sx={{ marginRight: "1rem" }}
                        control={<Radio />}
                        label="Market"
                      />
                      <FormControlLabel
                        value="Limit"
                        sx={{ marginRight: "1rem" }}
                        control={<Radio />}
                        label="Limit"
                      />
                    </RadioGroup>
                    <RadioGroup
                      sx={{ width: "50%" }}
                      row
                      value={tradeType}
                      type="number"
                      onChange={(e) => setTradeType(e.target.value)}
                    >
                      <FormControlLabel
                        value="Live"
                        sx={{ marginRight: "1rem" }}
                        control={<Radio />}
                        label="Live"
                      />
                      <FormControlLabel
                        value="Paper"
                        sx={{ marginRight: "1rem" }}
                        control={<Radio />}
                        label="Paper"
                      />
                    </RadioGroup>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="buysell-footer">
              <Box className="margin">
                {/* <Typography component={'p'}>Margin ₹ 493.70 Charges ₹ 2.17</Typography> */}
                {/* <Button><RefreshIcon /></Button> */}
              </Box>

              <Box className="footer-btn">
                <Button disabled={load} onClick={() => createTrade()}>
                  {buysell}
                </Button>
                <Button onClick={buysellClose}>Cancel</Button>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={deletePop}
        onClose={() => setDeletePop(false)}
        className="commonModal squareOff"
        fullWidth
      >
        <Box className="modalHeader">
          <Typography component={"h4"}>Delete Watchlist</Typography>
          <Button onClick={() => setDeletePop(false)} className="closeModal">
            <img src={close} />
          </Button>
        </Box>

        <DialogContent sx={{ padding: "0" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="alertIcons">
              <svg
                width="1052"
                height="1052"
                viewBox="0 0 1052 1052"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M558 334C558 316.3 543.7 302 526 302C508.3 302 494 316.3 494 334V590C494 607.7 508.3 622 526 622C543.7 622 558 607.7 558 590V334ZM526 750C536.609 750 546.783 745.786 554.284 738.284C561.786 730.783 566 720.609 566 710C566 699.391 561.786 689.217 554.284 681.716C546.783 674.214 536.609 670 526 670C515.391 670 505.217 674.214 497.716 681.716C490.214 689.217 486 699.391 486 710C486 720.609 490.214 730.783 497.716 738.284C505.217 745.786 515.391 750 526 750Z"
                  fill="#4987FE"
                />
                <circle
                  cx="526"
                  cy="526"
                  r="507"
                  stroke="#5086EE"
                  stroke-width="38"
                />
              </svg>
            </Box>
            <Typography
              component={"h4"}
              sx={{
                fontSize: "1.8rem",
                textAlign: "center",
                marginTop: "2rem",
                marginBottom: "2rem",
              }}
              className="alertText"
            >
              Are You Sure You Want to Delete Selected Symbols !
            </Typography>

            <Box sx={{ display: { xs: "flex" } }}>
              <Button
                onClick={() => setDeletePop(false)}
                className="modal-btn btn-danger"
                sx={{ marginRight: "0.5rem" }}
              >
                Discard
              </Button>
              <Button
                onClick={() => deleteWatchListData()}
                className="modal-btn btn-primary"
                sx={{ marginLeft: "0.5rem" }}
              >
                Confirm
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
