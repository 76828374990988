import * as React from "react";
import "./Tablesearch.scss";
import { Box, TextField, Autocomplete } from "@mui/material";

export default function Tableaction(props) {
  const { handleSearch, listData, handleSelectBroker, brokerValue } = props;
  const owners = listData?.map((item) => item.owner);
  // console.log("brokerValue?.toUpperCase()", brokerValue?.toUpperCase());
  // console.log("listData : ", owners);

  return (
    <>
      <Box className="inputFields">
        {/* <Autocomplete
        // placeholder="Select Broker"
        // key={""}
        // value={""}
        // // inputValue={brokerValue ? brokerValue.toUpperCase() : ""}
        // className="dropdown"
        // renderInput={(params) => (
        //   <TextField {...params} placeholder="Select Broker" />
        // )}
        // options={
          //   brokerStatus[0]?.status == true
        //     ? [brokerStatus[0]?.brokername.toUpperCase()]
        //     : brokers
        // }
        // onChange={handleSelectbroker}
        // getOptionLabel={(option) => option}
        // sx={{
        //   "& > .MuiFormControl-root > .MuiInputBase-root": {
        //     padding: "0.5rem !important",
        //   },
        // }}
      /> */}
        <Box>
          {/* <Autocomplete
            value={brokerValue}
            onInputChange={handleSearch}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ width: "20rem" }}
                placeholder="Select Broker"
              />
            )}
            onChange={handleSelectBroker}
            options={owners ? owners : []}
            getOptionLabel={(option) => option}
            sx={{
              "& > .MuiFormControl-root > .MuiInputBase-root": {
                padding: "0.5rem !important",
              },
            }}
          /> */}
        </Box>
      </Box>
    </>
  );
}
