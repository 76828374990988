import React, { useState } from 'react';
import {
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Grid,
  Box,
  TextField,
  FormControl,
  Button,
  Select,
  MenuItem,
  Tooltip
} from '@mui/material';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import EditIcon from '@mui/icons-material/Edit';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function Reviews() {
  const [ordertype, setordertype] = React.useState('ordertype1');
  const [orderinterval, setorderinterval] = React.useState('min');
  const [holding, setholding] = React.useState('MIS');
  const [order, setorder] = React.useState(' ');
  const [strategyCycle, setstrategyCycle] = React.useState(' ');
  const sizingtype = ['Capital Based', 'Risk Based'];
  const strategyCycles = [1, 2, 3, 4, 5, 10, 20, 50, 100];

  const ordertypedrp = (event) => {
    setorder(event.target.value);
  };

  const strategyCycledrp = (event) => {
    setstrategyCycle(event.target.value);
  };


  const ot1 = <Typography sx={{ fontSize: '1.4rem' }}>Candlestick</Typography>
  const ot2 = <Typography sx={{ fontSize: '1.4rem' }}>Heiki-Ashi</Typography>

  const ordertypechange = (e, newAlignment) => {
    setordertype(newAlignment);
  };

  const orderintervalchange = (e, newAlignment) => {
    setorderinterval(newAlignment);
  };

  const holdingchange = (e, newAlignment) => {
    setholding(newAlignment);
  };
  return (
    <Box className='strategy_box'>
      <Typography className='title_positions' sx={{ marginTop: 4 }} component={'h4'}>Order Type</Typography>
      <Box className='order_box'>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <Box className='selectionDiv order_type_switch'>
              <Typography className='label' component={'label'}>Order Type</Typography>
              <ToggleButtonGroup
                value={ordertype}
                exclusive
                onChange={ordertypechange}
                className='tradeType-toggle'
              >
                <ToggleButton value="ordertype1">
                  <Tooltip title={ot1} arrow placement='top'>
                    <CandlestickChartIcon />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="ordertype2">
                  <Tooltip title={ot2} arrow placement='top'>
                    <CandlestickChartIcon />
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Grid>

          <Grid item md={6}>
            <Box className='selectionDiv order_type_switch'>
              <Typography className='label' component={'label'}>Order Interval</Typography>
              <ToggleButtonGroup
                value={orderinterval}
                exclusive
                onChange={orderintervalchange}
                className='tradeType-toggle'
              >
                <ToggleButton value="min">min</ToggleButton>
                <ToggleButton value="3min">3min</ToggleButton>
                <ToggleButton value="5min">5min</ToggleButton>
                <ToggleButton value="10min">10min</ToggleButton>
                <ToggleButton value="15min">15min</ToggleButton>
                <ToggleButton value="30min">30min</ToggleButton>
                <ToggleButton value="hour">hour</ToggleButton>
                <ToggleButton value="day">day</ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Grid>

          <Grid item md={3}>
            <Box className='selectionDiv order_type_switch'>
              <Typography className='label' component={'label'}>Holding type</Typography>
              <ToggleButtonGroup
                value={holding}
                exclusive
                onChange={holdingchange}
                className='tradeType-toggle'
              >
                <ToggleButton value="MIS">MIS</ToggleButton>
                <ToggleButton value="CNC">CNC</ToggleButton>
                <ToggleButton value="NRML">NRML</ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Typography className='title_positions' sx={{ marginTop: 4 }} component={'h4'}>Quantity</Typography>
      <Box className='order_box'>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <Box className='inputFields space fullWidth'>
              <Typography component={'label'} className='label'>Quantity ( in lots )</Typography>
              <TextField value={0} placeholder="Script" className='inputFiled' disabled fullWidth />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className='inputFields space fullWidth'>
              <Typography component={'label'} className='label'>Max Allocation</Typography>
              <TextField value={0} placeholder="Script" className='inputFiled' fullWidth />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className='inputFields space fullWidth'>
              <Typography component={'label'} className='label'>Max Quantity ( in lots )</Typography>
              <TextField value={0} placeholder="Script" className='inputFiled' fullWidth />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box className='formBox'>
              <Box className='formItems'>
                <Typography component={'label'} className='label'>Position sizing type</Typography>
                <Box className='selectionDiv' >
                  <FormControl className='dropdown-ap' sx={{ maxWidth: '100%' }}>
                    <Select value={order} onChange={ordertypedrp} className='dropdown'>
                      {sizingtype.map((vals, index) => (
                        <MenuItem value={index === 0 ? ' ' : index} key={index}>{vals}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>


      <Grid container spacing={2}>
        <Grid item md={6} sx={{ marginTop: 4 }}>
          <Box className='order_box  entry_exit_box'>
            <Box className='entry_exit'>
              <Typography component={'p'}><SouthEastIcon className='northEast' /> Entry <Button className='edit'><EditIcon /></Button></Typography>
            </Box>
            <Typography component={'p'} className='entryExit_desc'>BUY with max allocation 5 and max quantity (in lots) 10 when Ceil( ) at 5min candle interval using Candlestick chart. Enter trade between 09:30 to 15:30</Typography>
          </Box>
        </Grid>
        <Grid item md={6} sx={{ marginTop: 4 }}>
          <Box className='order_box  entry_exit_box'>
            <Box className='entry_exit'>
              <Typography component={'p'}><NorthEastIcon className='northEast' /> Exit <Button className='edit'><EditIcon /></Button></Typography>
              <Typography component={'p'}>TPSL type pct</Typography>
            </Box>
            <Typography component={'p'} className='entryExit_desc'>SELL with max allocation 5 and max quantity (in lots) 10 at stop loss % of 10 or target profit % of 15 at 5min candle interval using Candlestick chart.</Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ marginTop: 4 }}>
          <Box className='order_box  entry_exit_box'>
            <Box className='entry_exit'>
              <Typography component={'p'}><SouthEastIcon className='northEast' /> Backtest Parameters</Typography>
            </Box>


            <Grid container spacing={1}>
              <Grid item md={3} sx={{ marginTop: 2 }}>
                <Box className='inputFields space fullWidth'>
                  <Typography component={'label'} className='label'>Initial Capital</Typography>
                  <TextField value={0} placeholder="Initial Capital" className='inputFiled' fullWidth />
                </Box>
              </Grid>
              <Grid item md={3} sx={{ marginTop: 2 }}>
                <Box className='formBox'>
                  <Box className='formItems'>
                    <Typography component={'label'} className='label'>Position sizing type</Typography>
                    <Box className='selectionDiv' >
                      <FormControl className='dropdown-ap' sx={{ maxWidth: '100%' }}>
                        <Select value={strategyCycle} onChange={strategyCycledrp} className='dropdown'>
                          {strategyCycles.map((vals, index) => (
                            <MenuItem value={index === 0 ? ' ' : index} key={index}>{vals}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item md={6} sx={{ marginTop: 1.5 }}>
                <Typography component={'label'} className='label'>Backtesting Period</Typography>
                <Box className='selectiondiv-box' sx={{ justifyContent: 'left', marginTop: 1 }}>
                  <Box className='selectionDiv' sx={{ maxWidth: { xs: '100%', md: 'calc(50% - 1rem)' } }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker className='datePicker' sx={{ maxWidth: '100%' }} />
                    </LocalizationProvider>
                  </Box>
                  <Box className='selectionDiv' sx={{ maxWidth: { xs: '100%', md: 'calc(50% - 1rem)' } }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker className='datePicker' sx={{ maxWidth: '100%' }} />
                    </LocalizationProvider>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box >
  )
}
