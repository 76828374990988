import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import {
  MenuItem,
  Select,
  Switch,
  FormControl,
  Tooltip,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import Tablesearch from "../../../Tablesearch/Tablesearch";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Table from "../../../Table/Table";
import "../../Dashboard/Tradedetails/Tradedetails.scss";
import Dropdown from "../../../Dropdown/Dropdown";

const col = [
  "Strategy Name",
  "Referal Code",
  "Referal Name",
  "Referal Mobile",
  "Referal Email",
  "Card",
];
const rows = [
  {
    stategyname: "DEM0001",
    referalcode: "ARTRADE",
    refralname: "ANIL REDDY	",
    referalmobile: "9032757441",
    referalemail: "--",
    card: "niftyfut bankniftyoption bankniftyfut niftyoption",
  },
];

// Dropdown
const drpValue = ["Client ID", "ABP272", "KBK2698", "KBK2698", "KJM208"];
const drpValue2 = ["Referal Code", "Strategy"];

const search = (
  <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
    search{" "}
  </Typography>
);
const add = (
  <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
    Add Strategy
  </Typography>
);

export default function Userstrategy() {
  const [strategy, setStrategy] = useState();
  const [clientid, setClientId] = useState();
  const [referal, setReferal] = useState();
  const [searchvalue, setSearchvalue] = useState();

  //input Strategy
  const handleStrategy = (event) => {
    setStrategy(event.target.value);
  };

  //select ClientId
  const handleClientId = (event) => {
    setClientId(event.target.value);
  };

  //select Referal
  const handleReferal = (event) => {
    setReferal(event.target.value);
  };

  //input Search
  const handleSearch = (event) => {
    setSearchvalue(event.target.value);
  };

  return (
    <>
      <Box className="tabelBox">
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12}>
            <Box className="selectiondiv-box m-rb">
              <Box className="selectionDiv">
                <Box
                  className="inputFields space"
                  sx={{ margin: "0 !important" }}
                >
                  <TextField
                    placeholder="Strategy Name"
                    onChange={(event) => handleStrategy(event)}
                  />
                </Box>
              </Box>
              <Dropdown
                val={drpValue}
                handleSelect={handleClientId}
                value={clientid}
                title={"Client ID"}
              />
              <Box className="selectionDiv searchFlex">
                <Tooltip title={add} arrow>
                  <Button className="download-btn solidButton">
                    <AddCircleIcon />
                  </Button>
                </Tooltip>
              </Box>
              <Dropdown
                val={drpValue2}
                handleSelect={handleReferal}
                value={referal}
                title={"Select"}
              />
              <Box className="selectionDiv searchFlex">
                <Tablesearch handlesearch={(event) => handleSearch(event)} />
                <Tooltip title={search} arrow>
                  <Button
                    className="download-btn solidButton"
                    sx={{ marginLeft: 1.5 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                        fill="#FFFFFF"
                      />
                    </svg>
                  </Button>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Table col={col} rows={rows} />
      </Box>
    </>
  );
}
