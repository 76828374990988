import "./Broker.scss";
import { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { BrokerStatus, iciciLogin } from "../../../redux/actions/brokerAction";
import Brokerlogin from "../../Brokerlogin/Brokerlogin";
import { iciciValidation } from "../../../validation/broker";
import { BrokerStatus } from "../../../redux/actions/brokerAction";

const Icici = (props) => {
  const inputFields = { AppKey: "", secretkey: "" };
  const [fields, setFields] = useState(inputFields);
  const [isSubmit, setIsSubmit] = useState(false);
  const [Error, setError] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const brokerStatus = useSelector((state) => state.Broker.brokerstatus);
  const { broker } = props;
  const inputBorder = {
    borderRadius: "5px",
    border: "1px solid #bcbcbc",
  };

  //form input
  const handleChange = (event) => {
    const keyName = event.target.name;
    const keyValue = event.target.value;
    setFields((prevState) => ({
      ...prevState,
      [keyName]: keyValue,
    }));
    if (isSubmit) {
      setError(iciciValidation({ ...fields, [keyName]: keyValue }));
    }
  };

  const handleSubmit = () => {
    setIsSubmit(true);
    setError(iciciValidation(fields));
    let error = iciciValidation(fields);
    if (Object.keys(error) == 0) {
      localStorage.setItem("AppKey", fields?.AppKey);
      localStorage.setItem("secretkey", fields?.secretkey);
      localStorage.setItem("clientId", fields?.clientId);
      localStorage.setItem("brokername", "icici");
      window.location.replace(
        `https://api.icicidirect.com/apiuser/login?api_key=${encodeURIComponent(
          fields.AppKey
        )}`
      );
      // dispatch(iciciLogin(fields, navigate))
    }
  };

  const brokerStatus = useSelector((state) => state.Broker.brokerstatus);

  useEffect(() => {
    dispatch(BrokerStatus());
  }, []);

  useEffect(() => {
    if (brokerStatus?.length > 0) {
      if (brokerStatus[0]?.appkey) {
        setFields({
          ...fields,
          AppKey: brokerStatus[0]?.appkey,
          secretkey: brokerStatus[0]?.secretkey,
          clientId: brokerStatus[0]?.clientId,
        });
      }
    }
  }, [brokerStatus]);

  // useEffect(() => {
  //     dispatch(BrokerStatus());
  // }, []);

  // useEffect(() => {
  //     if (brokerStatus?.length > 0) {
  //         if (brokerStatus[0]?.appkey) {
  //             setFields({
  //                 ...fields,
  //                 AppKey: brokerStatus[0]?.appkey,
  //                 secretkey: brokerStatus[0]?.secretkey,
  //             });
  //         }
  //     }
  // }, [brokerStatus]);

  return (
    <>
      <Box className="border">
        <Box>
          <Grid xs={12} lg={12}>
            <Box className="formBox">
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  APP KEY :
                </Typography>
                <TextField
                  placeholder="Enter App Key..."
                  className="inputFiled"
                  style={inputBorder}
                  name="AppKey"
                  onChange={handleChange}
                  value={fields?.AppKey}
                />
                {Error ? <Box className="error">{Error.AppKey}</Box> : ""}
              </Box>
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  SECRET KEY :
                </Typography>
                <TextField
                  placeholder="Enter Secret Key..."
                  className="inputFiled"
                  style={inputBorder}
                  name="secretkey"
                  onChange={handleChange}
                  value={fields?.secretkey}
                />
                {Error ? <Box className="error">{Error.secretkey}</Box> : ""}
              </Box>
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  CLIENT ID :
                </Typography>
                <TextField
                  placeholder="Enter Client Id..."
                  className="inputFiled"
                  style={inputBorder}
                  name="clientId"
                  onChange={handleChange}
                  value={fields?.clientId}
                />
                {Error ? <Box className="error">{Error.clientId}</Box> : ""}
              </Box>
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  className="btn solidButton fetchReport-btn"
                  sx={{ width: "100% !important" }}
                  onClick={handleSubmit}
                >
                  Login
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Icici;
