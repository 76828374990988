import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Grid,
  Button,
  Tooltip,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { editProfile, getProfile } from "../../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { editProfileValidation } from "../../validation/signin";
import close from "../../images/close.png";

export default function Settings() {
  const edit = <Typography sx={{ fontSize: "1.4rem" }}>Edit</Typography>;

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [registerAt, setRegisterAt] = useState("");
  const [update, setUpdate] = useState(true);
  const [open, setOpen] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const dispatch = useDispatch();

  const obj = {
    firstname: firstname,
    lastname: lastname,
    email: email,
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const profile = useSelector((state) => state.Auth.authProfile);
  useEffect(() => {
    dispatch(getProfile());
  }, []);

  useEffect(() => {
    if (profile && profile.length > 0) {
      setFirstname(profile[0].firstname);
      setLastname(profile[0].lastname);
      setPhone(profile[0].phone);
      setEmail(profile[0].email);
      setRegisterAt(profile[0]?.register_at);
    }
  }, [profile]);

  const alphabeticPattern = /^[A-Za-z]+$/;
  const handleFirstName = (e) => {
    const { value } = e.target;
    if (value === "" || value.match(alphabeticPattern)) {
      if (!(value.length > 10)) {
        setFirstname(value);
      }
    }
    if (isSubmit) {
      setFieldError(editProfileValidation(obj));
    }
  };

  const handleLastName = (e) => {
    const { value } = e.target;
    if (value === "" || value.match(alphabeticPattern)) {
      if (!(value.length > 10)) {
        setLastname(value);
      }
    }
    if (isSubmit) {
      setFieldError(editProfileValidation(obj));
    }
  };

  const handleSave = () => {
    setIsSubmit(true);

    const errors = editProfileValidation(obj);

    if (Object.keys(errors) == 0) {
      dispatch(editProfile(obj));
    }
    setFieldError(editProfileValidation(obj));
    handleClose();
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={4}>
          <Box className="contactDetails-box border-ap" sx={{ padding: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box className="inputFields space fullWidth space-y">
                  <Typography component={"label"} className="label">
                    First Name
                  </Typography>
                  <TextField
                    placeholder="First Name"
                    type="text"
                    id="FirstName"
                    fullWidth
                    value={firstname}
                    disabled={update}
                    onChange={handleFirstName}
                  />
                  {fieldError && (
                    <span className="color">{fieldError.firstname}</span>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box className="inputFields space fullWidth space-y">
                  <Typography component={"label"} className="label">
                    Last Name
                  </Typography>
                  <TextField
                    placeholder="Last Name"
                    type="text"
                    id="LastName"
                    fullWidth
                    value={lastname}
                    disabled={update}
                    onChange={handleLastName}
                  />
                  {fieldError && (
                    <span className="color">{fieldError.lastname}</span>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box className="inputFields space fullWidth space-y">
                  <Typography component={"label"} className="label">
                    Email
                  </Typography>
                  <TextField
                    placeholder="E-mail"
                    type="text"
                    id="Email"
                    fullWidth
                    value={email}
                    disabled={update}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      if (isSubmit) {
                        setFieldError(editProfileValidation(obj));
                      }
                    }}
                  />
                  {fieldError && (
                    <span className="color">{fieldError.email}</span>
                  )}
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box className="inputFields space fullWidth space-y">
                  <Typography component={"label"} className="label">
                    Phone Number
                  </Typography>
                  <TextField
                    className="disabled-inputField"
                    placeholder="Phone"
                    type="text"
                    id="Phone"
                    value={phone}
                    disabled
                  />
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box className="inputFields space fullWidth space-y">
                  <Typography component={"label"} className="label">
                    Register Date
                  </Typography>
                  <TextField
                    className="disabled-inputField"
                    placeholder="Phone"
                    type="text"
                    id="Phone"
                    value={registerAt}
                    disabled
                  />
                </Box>
              </Grid>

              {/* <Grid item xs={12}>
                <Box className='formBox'>
                  <Box className='formItems'>
                    <Typography component={'label'} className='label' >Upload Your Logo Here</Typography>
                    <Typography className='fileUpload' component={'label'} sx={{ maxWidth: '100% !important' }}>
                      <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 8.3335V12.3335C1 13.0699 1.59695 13.6668 2.33333 13.6668H11.6667C12.4031 13.6668 13 13.0699 13 12.3335V8.3335" stroke="#8D8D8D" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M7.33333 10.3333V1M7.33333 1L4 4.62964M7.33333 1L10.6667 4.62963" stroke="#8D8D8D" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      Upload Logo Here
                      <TextField type='file' name='file' sx={{ marginRight: '2.5rem' }} id='file' />
                    </Typography>
                  </Box>
                </Box>
              </Grid> */}

              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right",
                    width: "100%",
                  }}
                >
                  <Tooltip title={edit} arrow placement="top">
                    <Button
                      className="fetchReport-btn solidButton"
                      onClick={handleClickOpen}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                      >
                        {" "}
                        <path
                          d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </Button>
                  </Tooltip>
                  <Button
                    onClick={handleSave}
                    style={{ display: update === true ? "none" : "block" }}
                    className="formSolid-btn"
                    sx={{ marginLeft: 1 }}
                    disabled={update}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <Box
            className="contactDetails-box border-ap"
            sx={{ display: "flex", width: "100%" }}
          > */}
        {/* <Box
              className=" space fullWidth space-y"
              style={{ padding: "10px" }}
            >
              <div style={{ margin: "auto", width: "85%" }}>
                <Typography style={{ whiteSpace: "nowrap" }} className="label">
                  Register Date :- 31 May,2021
                </Typography>
                <Typography style={{ whiteSpace: "nowrap" }} className="label">
                  Plan Status :- Subscription Active
                </Typography>
                <Typography style={{ whiteSpace: "nowrap" }} className="label">
                  Plan Start Date :- 25-jun-2023
                </Typography>
                <Typography style={{ whiteSpace: "nowrap" }} className="label">
                  Plan End Date :- 26-jul-2023
                </Typography>
                <Typography style={{ whiteSpace: "nowrap" }} className="label">
                  Days Left :- 25 days
                </Typography>
              </div>
            </Box> */}
        {/* <Box sx={{ marginLeft: "15px" }}>
              <Typography
                style={{
                  width: "100%",
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Plan Status :
              </Typography>
              <Typography
                style={{
                  whiteSpace: "nowrap",
                  color: "#24a959",
                  fontSize: "14px",
                }}
              >
                Subscription Active{" "}
              </Typography>
              <Typography
                component={"label"}
                style={{ color: "#6a6d78", fontSize: "12px" }}
              >
                (Basic)
              </Typography>
            </Box>
            <Box sx={{ marginLeft: "15px" }}>
              <Typography
                style={{
                  width: "100%",
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Plan Expiry :
              </Typography>
              <Typography
                style={{
                  whiteSpace: "nowrap",
                  color: "#24a959",
                  fontSize: "14px",
                }}
              >
                19-Apr-2030{" "}
              </Typography>
              <Typography
                component={"label"}
                style={{
                  color: "#6a6d78",
                  fontSize: "12px",
                  whiteSpace: "nowrap",
                }}
              >
                (2410 Days Left)
              </Typography>
            </Box> */}
        {/* </Box>
        </Grid> */}
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        className="commonModal squareOff"
      >
        <Box className="modalHeader" sx={{ justifyContent: "end" }}>
          <Typography component={"h4"}>Save</Typography>
          <Button onClick={handleClose} className="closeModal">
            <img src={close} />
          </Button>
        </Box>
        <DialogContent sx={{ padding: "0" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="alertIcons">
              <svg
                width="1052"
                height="1052"
                viewBox="0 0 1052 1052"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M558 334C558 316.3 543.7 302 526 302C508.3 302 494 316.3 494 334V590C494 607.7 508.3 622 526 622C543.7 622 558 607.7 558 590V334ZM526 750C536.609 750 546.783 745.786 554.284 738.284C561.786 730.783 566 720.609 566 710C566 699.391 561.786 689.217 554.284 681.716C546.783 674.214 536.609 670 526 670C515.391 670 505.217 674.214 497.716 681.716C490.214 689.217 486 699.391 486 710C486 720.609 490.214 730.783 497.716 738.284C505.217 745.786 515.391 750 526 750Z"
                  fill="#4987FE"
                />
                <circle
                  cx="526"
                  cy="526"
                  r="507"
                  stroke="#5086EE"
                  stroke-width="38"
                />
              </svg>
            </Box>
            <Typography
              component={"h4"}
              sx={{
                fontSize: "1.8rem",
                textAlign: "center",
                marginTop: "2rem",
              }}
              className="alertText"
            >
              Are You Sure? You wanted to edit your profile.
            </Typography>

            <Box sx={{ display: { xs: "flex" }, marginTop: 2 }}>
              <Button
                onClick={handleClose}
                className="modal-btn btn-danger"
                sx={{ marginRight: "0.5rem" }}
              >
                Discard
              </Button>
              <Button
                onClick={() => {
                  handleClose();
                  setUpdate(false);
                }}
                className="modal-btn btn-primary"
                sx={{ marginLeft: "0.5rem" }}
              >
                Confirm
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
