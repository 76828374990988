import React, { useEffect } from "react";
import moment from "moment";
import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  TextField,
  Tooltip,
  Button,
} from "@mui/material";
import Dropdown from "../../../Dropdown/Dropdown";
import Table from "../../../Table/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  adminOrderDetails,
  adminPaperOrderDetails,
} from "../../../../redux/actions/adminActions";
import { getStratergy } from "../../../../redux/actions/stratergyAction";

export default function Closeposition() {
  const [alignment, setAlignment] = React.useState("live");
  const [strategy, setStrategy] = React.useState("all");
  const [clientId, setClientId] = React.useState("");
  const dispatch = useDispatch();

  const handleChange = (e, newAlignment) => {
    if (newAlignment) {
      setAlignment(newAlignment);
    }
  };

  // Close Position
  const col2 = [
    "Symbol Name",
    "Strategy",
    "Buy Price",
    "Sell Price",
    "Buy Qty",
    "Sell QTY",
    "Owner",
    "Order Type",
    "Trend",
    "Exchange",
    "Profit",
    "Date & Time",
    "Status",
  ];
  // const rows2 = [
  //   {
  //     symbol: "BANKNIFTY2310542900PE",
  //     date: "DEMCRD",
  //     strategyname: 92.45,
  //     Exchange: 150,
  //     OrderType: 150,
  //     BuyQTY: 150,
  //     EntryPrice: "MIS",
  //     ExitPrice: (
  //       <Typography component={"span"} className="up">
  //         Buy
  //       </Typography>
  //     ),
  //     LTP: "NFO",
  //     PL: "1504",
  //     squer: "1504",
  //     datetime: "05-15-2023, 11:20:05PM",
  //     status: (
  //       <Typography component={"span"} className="down">
  //         Sell
  //       </Typography>
  //     ),
  //   },
  // ];

  const data = useSelector((state) => state?.Admin?.adminOrderDetailsData);
  const paperData = useSelector((state) => state?.Admin?.adminPaperDetailsData);

  useEffect(() => {
    if (alignment === "live") {
      dispatch(
        adminOrderDetails({
          clientid: clientId || "select",
        })
      );
    } else if (alignment === "paper") {
      dispatch(
        adminPaperOrderDetails({
          clientid: clientId || "select",
        })
      );
    }
  }, [alignment]);

  useEffect(() => {
    dispatch(getStratergy());
  }, []);

  const strategies = useSelector((state) => state?.Stratergy?.stratergy);
  const strategyList = strategies?.map((e) => e?.name);

  const filteredData =
    strategy === "all"
      ? data
      : data?.filter(
          (e) => e?.stratergy?.toLowerCase() === strategy?.toLowerCase()
        );
  const filteredPaperData =
    strategy === "all"
      ? paperData
      : paperData?.filter(
          (e) => e?.stratergy?.toLowerCase() === strategy?.toLowerCase()
        );

  const sortedAsc = filteredData?.sort(function (a, b) {
    return new Date(b.date) - new Date(a.date);
  });
  const paperSortedAsc = filteredPaperData?.sort(function (a, b) {
    return new Date(b.date) - new Date(a.date);
  });

  // console.log("paperSortedAsc :>> ", paperSortedAsc);

  let rows2 = [];

  if (alignment === "live") {
    rows2 = sortedAsc?.map((e) => {
      return {
        name: e?.symbol,
        stratergy: e?.stratergy,
        buyprice: Number(e?.buyprice).toFixed(2),
        sellprice: Number(e?.sellprice).toFixed(2),
        buyquantity: Math.trunc(e?.buyquantity),
        sellquantity: Math.trunc(e?.sellquantity),
        owner: e?.owner,
        prctyp: e?.prctyp,
        side:
          e?.side?.toLowerCase() === "sell" ||
          e?.side?.toLowerCase() === "s" ? (
            <Typography component={"span"} className="down">
              {e?.side}
            </Typography>
          ) : (
            <Typography component={"span"} className="up">
              {e?.side}
            </Typography>
          ),
        exchange: e?.exchange,
        profit:
          e?.profit < 0 ? (
            <Typography component={"span"} className="down">
              {e?.profit}
            </Typography>
          ) : (
            <Typography component={"span"} className="up">
              {e?.profit}
            </Typography>
          ),
        date: moment.parseZone(e?.date)?.format("YYYY-MM-DD HH:mm"),
        status:
          e?.status === "Rejected" ? (
            <Typography component={"span"} className="down">
              {e?.status}
            </Typography>
          ) : (
            <Typography component={"span"} className="up">
              {e?.status}
            </Typography>
          ),
      };
    });
  } else if (alignment === "paper") {
    rows2 = paperSortedAsc?.map((e) => {
      return {
        name: e?.symbol,
        stratergy: e?.stratergy,
        buyprice: Number(e?.buyprice).toFixed(2),
        sellprice: Number(e?.sellprice).toFixed(2),
        buyquantity: Math.trunc(e?.buyquantity),
        sellquantity: Math.trunc(e?.sellquantity),
        owner: e?.owner,
        prctyp: e?.prctyp,
        side:
          e?.side?.toLowerCase() === "sell" ||
          e?.side?.toLowerCase() === "s" ? (
            <Typography component={"span"} className="down">
              {e?.side}
            </Typography>
          ) : (
            <Typography component={"span"} className="up">
              {e?.side}
            </Typography>
          ),
        exchange: e?.exchange,
        profit:
          e?.profit < 0 ? (
            <Typography component={"span"} className="down">
              {e?.profit}
            </Typography>
          ) : (
            <Typography component={"span"} className="up">
              {e?.profit}
            </Typography>
          ),
        date: moment.parseZone(e?.date)?.format("YYYY-MM-DD HH:mm"),
        status:
          e?.status === "Rejected" ? (
            <Typography component={"span"} className="down">
              {e?.status}
            </Typography>
          ) : (
            <Typography component={"span"} className="up">
              {e?.status}
            </Typography>
          ),
      };
    });
  }

  const search = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      search{" "}
    </Typography>
  );

  const handleSearch = () => {
    if (alignment === "live") {
      dispatch(
        adminOrderDetails({
          clientid: clientId || "select",
        })
      );
    } else if (alignment === "paper") {
      dispatch(
        adminPaperOrderDetails({
          clientid: clientId || "select",
        })
      );
    }
  };

  const handleStrategy = (e) => {
    setStrategy(e?.target?.value);
  };

  return (
    <>
      <Box className="border-ap">
        <Box className="selectiondiv-box m-rb">
          <Box className="selectionDiv searchFlex">
            <Box className="inputFields">
              <TextField
                placeholder="Broker Client id"
                onChange={(e) => {
                  setClientId(e?.target?.value);
                }}
              />

              <Box className="searchIcon">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.85722 14.7144C11.6444 14.7144 14.7144 11.6444 14.7144 7.85722C14.7144 4.07008 11.6444 1 7.85722 1C4.07008 1 1 4.07008 1 7.85722C1 11.6444 4.07008 14.7144 7.85722 14.7144Z"
                    stroke="#6A6D78"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13 13.0001L17 17.0002"
                    stroke="#6A6D78"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Box>
            </Box>
            <Tooltip title={search} arrow>
              <Button
                onClick={handleSearch}
                className="download-btn solidButton"
                sx={{ marginLeft: 1 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                    fill="#FFFFFF"
                  />
                </svg>
              </Button>
            </Tooltip>
          </Box>
          <Box className="selectionDiv searchFlex">
            <Typography className="label" component={"label"}>
              Select Trade Type
            </Typography>
            <ToggleButtonGroup
              value={alignment}
              exclusive
              onChange={handleChange}
              className="tradeType-toggle"
            >
              <ToggleButton value="paper">Paper</ToggleButton>
              <ToggleButton value="live">Live</ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box className="selectionDiv searchFlex">
            <Dropdown
              val={["All"].concat(strategyList)}
              handleSelect={handleStrategy}
              value={strategy}
              title={"Strategy"}
            />
          </Box>
          <Table col={col2} rows={rows2} />
        </Box>
      </Box>
    </>
  );
}
