import React from "react";
import "./Liveprice.scss";
import { Box, Typography } from "@mui/material";
import Marquee from "react-fast-marquee";
import { useLocation } from "react-router-dom";

export default function Liveprice({ liveFeedData }) {
  const liveData = [
    { market_name: "NIFTY50", ltp: 0, pc: 0, c: 0 },
    { market_name: "BANKNIFTY", ltp: 0, pc: 0, c: 0 },
    { market_name: "GOLD", ltp: 0, pc: 0, c: 0 },
    { market_name: "SILVER", ltp: 0, pc: 0, c: 0 },
    { market_name: "SENSEX", ltp: 0, pc: 0, c: 0 },
    { market_name: "COPPER", ltp: 0, pc: 0, c: 0 },
    { market_name: "CRUDE", ltp: 0, pc: 0, c: 0 },
    { market_name: "AUMINIUM", ltp: 0, pc: 0, c: 0 },
    { market_name: "FINNIFTY", ltp: 0, pc: 0, c: 0 },
  ];
  const location = useLocation();

  const mode = localStorage.getItem("mode");

  if (
    location.pathname === "/admin" ||
    location.pathname === "/strategybuilder"
  ) {
    return <></>;
  }
  const down = (
    <svg
      width="8"
      height="9"
      viewBox="0 0 8 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.60241 8.06195L3.60241 1.03332M6.53101 5.13336L3.60241 8.06195L6.53101 5.13336ZM3.60241 8.06195L0.673816 5.13336L3.60241 8.06195Z"
        stroke={"red"}
        strokeWidth="1.17144"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const up = (
    <svg
      width="8"
      height="9"
      viewBox="0 0 8 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.51526 1.03329V8.06192M0.58667 3.96189L3.51526 1.03329L0.58667 3.96189ZM3.51526 1.03329L6.44386 3.96189L3.51526 1.03329Z"
        stroke={"#24a959"}
        strokeWidth="1.17144"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const scrollContainer = document.querySelector(".livePrice-list");
  if (scrollContainer) {
    scrollContainer.addEventListener("wheel", (e) => {
      e.preventDefault();
      scrollContainer.scrollLeft += e.deltaY;
    });
  }

  return (
    <>
      <Box className="livePrice-list">
        {liveFeedData?.length == 0 &&
          liveData.map((items, index) => (
            <Box
              className={`livePrice-item ${index % 2 === 1 ? "high" : "low"}`}
              key={index}
            >
              <Box className="price">
                <Typography component={"h6"}>{items.market_name}</Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography component={"p"}>{items.ltp}</Typography>
                  <Typography component={"small"}>
                    {Number(items?.ltp - items?.c).toFixed(2)}
                    <Typography component={"span"} className={`static`}>
                      {Math.sign(items?.pc) === 1 ? up : down}
                      {items.pc}%
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}

        {liveFeedData?.length > 0 &&
          liveFeedData?.map((items, index) => (
            <Box component={"div"} className="item" key={index}>
              <Box
                className={`livePrice-item ${
                  Math.sign(items?.pc) === 1 ? "high" : "low"
                }`}
              >
                <Box className="price">
                  <Typography component={"h6"}>{items.market_name}</Typography>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      style={{
                        color: Math.sign(items?.pc) === 1 ? "#24a959" : "red",
                        fontWeight: "bold",
                      }}
                      component={"p"}
                    >
                      {items.ltp} {Math.sign(items?.pc) === 1 ? up : down}
                    </Typography>
                    <Typography component={"small"}>
                      {Number(items?.ltp - items?.c).toFixed(2)}
                      <Typography component={"span"} className={`static`}>
                        {items.pc}%
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
    </>
  );
}
