import React, { useState } from "react";
import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Table,
  Tooltip,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";
// import Table from "../../../Table/Table";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Dropdown from "../../../Dropdown/Dropdown";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAdminTutorial,
  editAdminTutorial,
  getAdminTutorial,
  uploadTutorial,
} from "../../../../redux/actions/authActions";
import { generatePopup } from "../../../../utils/popup";
import { getMobileLogo } from "../../../../redux/actions/brokerAction";

export default function Imageadition() {
  // const rows = [
  //   {
  //     title: "Make Your Strategy",
  //     url: "https://dematade-storage.s3.ap-south-1.amazonaws.com/files/how%20to%20automate%20your%20own%20strategy%202.png	",
  //     mediatype: "image",
  //     type: "offer",
  //   },
  // ];
  // const handleChange = (e) => {
  //   const keyName = e?.target?.name;
  //   const keyValue = e?.target?.value;

  //   setFields((prevState) => ({
  //     ...prevState,
  //     [keyName]: keyValue,
  //   }));
  // };

  //  Dropdown
  const drpValue = ["Upload", "URL"];
  const drpValue1 = ["Image", "Video"];
  const drpValue2 = ["All", "Artarde", "Dematade", "Klpt500"];

  const [uploadtype, setUploadtype] = useState();
  const [type, setType] = useState();
  const [select, setSelect] = useState();
  const [title, setTitle] = useState();
  const [url, setUrl] = useState();
  const [errors, setErrors] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [cols, setCols] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [deleteId, setDeleteId] = React.useState(false);
  const [editID, setEditId] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [state, setState] = React.useState({});
  const [showSave, setShowSave] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const adminTutorial = useSelector((state) => {
    return state?.Auth?.adminTutorial;
  });

  const uploadValidation = (fields) => {
    let isValid = {};
    if (!fields.url) {
      isValid.url = "Enter URL";
    }
    if (!fields?.type) {
      isValid.type = "Pleast Select Type";
    }
    if (!fields?.title) {
      isValid.title = "Enter Title";
    }
    return isValid;
  };

  useEffect(() => {
    if (adminTutorial?.length > 0) {
      const columns = Object.keys(adminTutorial?.[0]);
      columns?.push("Action");
      setCols(columns);
    }
  }, [adminTutorial]);

  // get tutorial data for table
  useEffect(() => {
    dispatch(getAdminTutorial());
  }, []);

  //select Uploadtype
  const handleUploadtype = (event) => {
    setErrors(null);
    setUploadtype(event.target.value);
  };

  //select Type
  const handleType = (event) => {
    setErrors(null);
    setType(event.target.value);
  };

  //select Select
  const handleSelect = (event) => {
    setSelect(event.target.value);
  };

  //input Title
  const handleTitle = (event) => {
    setErrors(null);
    setTitle(event.target.value);
  };

  //input URl
  const handleUrl = (event) => {
    setErrors(null);
    setUrl(event.target.value);
  };

  //upload file
  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   setSelectedFile(file);
  // };

  const dispatch = useDispatch();

  // api calling for upload tutorial
  const handleSubmit = () => {
    const obj = {
      title: title,
      type: type,
      url: url,
    };
    setIsSubmit(true);
    const error = uploadValidation(obj);
    if (Object.keys(error).length === 0) {
      dispatch(uploadTutorial(obj)).then((res) => {
        if (res?.status === 200) {
          dispatch(getAdminTutorial());
          setTitle("");
          setUrl("");
          setType("");
        }
      });
    } else {
      setErrors(error);
    }
  };

  const handleDeleteOpen = (id) => {
    setDeleteId(id);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteId(false);
    setDeleteOpen(false);
  };

  const handleDelete = () => {
    const obj = { id: deleteId };
    dispatch(deleteAdminTutorial(obj)).then((res) => {
      if (res) {
        dispatch(getAdminTutorial());
        setDeleteOpen(false);
        setDeleteId(false);
      }
    });
  };

  const handleEditOpen = (row) => {
    setEditId(row?.id);
    setState(row);
    setShowSave(row?.id);
  };

  const handleEditChange = (e) => {
    const { name, value } = e?.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEdit = () => {
    const obj = {
      id: state?.id,
      url: state?.url,
      title: state?.title,
    };

    const error = uploadValidation({
      title: state?.title,
      type: state?.upload_type,
      url: state?.url,
    });

    if (Object.keys(error).length > 0) {
      Object.keys(error).map((e) => {
        generatePopup("error", `${e} is required`);
      });
    } else {
      dispatch(editAdminTutorial(obj)).then((res) => {
        if (res?.status === 200) {
          dispatch(getAdminTutorial());
          setEditId(false);
          setState({});
        }
      });
    }
  };

  return (
    <>
      <Box className="tabelBox">
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12}>
            <Box className="selectiondiv-box m-rb">
              {/* <Dropdown
                val={drpValue2}
                handleSelect={handleSelect}
                value={select}
                title={"Select"}
              /> */}
              <div>
                <Dropdown
                  val={drpValue1}
                  handleSelect={handleType}
                  value={type}
                  title={"Upload Type"}
                />
                {errors && (
                  <p style={{ margin: "0" }} className="color">
                    {errors.type}
                  </p>
                )}
              </div>

              {/* <Dropdown
                val={drpValue}
                handleSelect={handleUploadtype}
                value={uploadtype}
                title={"Type"}
              /> */}

              <div>
                <Box className="selectionDiv">
                  <Box
                    className="inputFields space"
                    sx={{ margin: "0 !important" }}
                  >
                    <TextField
                      value={title}
                      placeholder="Enter Title"
                      onChange={(event) => handleTitle(event)}
                    />
                  </Box>
                </Box>
                {errors && (
                  <p style={{ margin: "0" }} className="color">
                    {errors.title}
                  </p>
                )}
              </div>
              <div>
                <Box className="selectionDiv">
                  <Box
                    className="inputFields space"
                    sx={{ margin: "0 !important" }}
                  >
                    <TextField
                      value={url}
                      placeholder="Enter URL"
                      onChange={(event) => handleUrl(event)}
                    />
                  </Box>
                </Box>
                {errors && (
                  <p style={{ margin: "0" }} className="color">
                    {errors.url}
                  </p>
                )}
              </div>
              <Box className="selectionDiv formBox">
                <Box
                  className="formItems"
                  sx={{
                    margin: "0 !important",
                    display: "flex !important",
                    alignItems: "center",
                  }}
                >
                  {/* <Typography
                    className="fileUpload"
                    sx={{
                      margin: "0 1rem 0 0 !important",
                      maxWidth: {
                        xs: "100% !important",
                        md: "20rem !important",
                      },
                      width: "100%",
                    }}
                    component={"label"}
                  >
                    <svg
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 8.3335V12.3335C1 13.0699 1.59695 13.6668 2.33333 13.6668H11.6667C12.4031 13.6668 13 13.0699 13 12.3335V8.3335"
                        stroke="#8D8D8D"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.33333 10.3333V1M7.33333 1L4 4.62964M7.33333 1L10.6667 4.62963"
                        stroke="#8D8D8D"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Upload File
                    <TextField
                      type="file"
                      sx={{ marginRight: "2.5rem" }}
                      onChange={handleFileChange}
                      accept="image/*,video/*"
                    />
                  </Typography> */}
                  <Button className="formSolid-btn" onClick={handleSubmit}>
                    Submit{" "}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <TableContainer sx={{ MarginTop: 16, maxHeight: 440 }}>
          <Table
            stickyHeader
            sx={{ minWidth: "100%" }}
            className={`table tableData `}
          >
            <TableHead>
              <TableRow>
                {/* <TableCell>Sr No.</TableCell> */}
                {cols?.map((column, index) => (
                  <TableCell key={index}>{column}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? adminTutorial.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : adminTutorial
              ).map((row, index) => (
                <TableRow key={index}>
                  {/* <TableCell ><span>{index + 1}</span></TableCell> */}

                  {Object.keys(row).map((element, index) =>
                    row?.id === editID ? (
                      <TableCell key={index}>
                        {element !== "upload_type" &&
                        element !== "id" &&
                        element !== "owner" ? (
                          <TextField
                            size="small"
                            name={element}
                            value={state?.[element]}
                            sx={{
                              border: "1px solid gray",
                              borderRadius: "5px",
                            }}
                            inputProps={{ style: { fontSize: "small" } }}
                            onChange={handleEditChange}
                          ></TextField>
                        ) : (
                          row[element]
                        )}
                      </TableCell>
                    ) : (
                      <TableCell key={index}>{row[element]}</TableCell>
                    )
                  )}
                  <TableCell>
                    <Box className="tableActions">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="actionButton"
                      >
                        <Tooltip arrow placement="top">
                          <Button
                            onClick={() => {
                              handleEditOpen(row);
                            }}
                            className="edit"
                            variant="text"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.26 3.59997L5.04997 12.29C4.73997 12.62 4.43997 13.27 4.37997 13.72L4.00997 16.96C3.87997 18.13 4.71997 18.93 5.87997 18.73L9.09997 18.18C9.54997 18.1 10.18 17.77 10.49 17.43L18.7 8.73997C20.12 7.23997 20.76 5.52997 18.55 3.43997C16.35 1.36997 14.68 2.09997 13.26 3.59997Z"
                                stroke="CurrentColor"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M11.89 5.05005C12.32 7.81005 14.56 9.92005 17.34 10.2"
                                stroke="CurrentColor"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M3 22H21"
                                stroke="CurrentColor"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </Button>
                        </Tooltip>
                        <Tooltip arrow placement="top">
                          <Button
                            onClick={() => {
                              handleDeleteOpen(row?.id);
                            }}
                            className="del"
                            variant="text"
                          >
                            <DeleteForeverIcon />
                          </Button>
                        </Tooltip>
                        {editID === row?.id && (
                          <Tooltip arrow placement="top">
                            <Button
                              variant="contained"
                              style={{ padding: "0 10px", fontSize: "small" }}
                              onClick={handleEdit}
                            >
                              Save
                            </Button>
                          </Tooltip>
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          className="tablePagination"
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "end" },
            padding: "0 1rem",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[10, 20, 30, { label: "All", value: -1 }]}
            count={adminTutorial?.length}
            rowsPerPage={rowsPerPage}
            SelectProps={{
              fontSize: "1.6rem",
              native: false,
            }}
            page={page}
            className="tablePagination"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>

      <Dialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        className="commonModal squareOff"
        fullWidth
      >
        <Box className="modalHeader">
          <Typography component={"h4"}>Square Off</Typography>
          <Button onClick={handleDeleteClose} className="closeModal">
            <CloseIcon />
          </Button>
        </Box>
        <DialogContent sx={{ padding: "0" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="alertIcons">
              <svg
                width="1052"
                height="1052"
                viewBox="0 0 1052 1052"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M558 334C558 316.3 543.7 302 526 302C508.3 302 494 316.3 494 334V590C494 607.7 508.3 622 526 622C543.7 622 558 607.7 558 590V334ZM526 750C536.609 750 546.783 745.786 554.284 738.284C561.786 730.783 566 720.609 566 710C566 699.391 561.786 689.217 554.284 681.716C546.783 674.214 536.609 670 526 670C515.391 670 505.217 674.214 497.716 681.716C490.214 689.217 486 699.391 486 710C486 720.609 490.214 730.783 497.716 738.284C505.217 745.786 515.391 750 526 750Z"
                  fill="#4987FE"
                />
                <circle
                  cx="526"
                  cy="526"
                  r="507"
                  stroke="#5086EE"
                  stroke-width="38"
                />
              </svg>
            </Box>
            <Typography
              component={"h4"}
              sx={{
                fontSize: "1.8rem",
                textAlign: "center",
                marginTop: "2rem",
              }}
              className="alertText"
            >
              Are You Sure You Want to Close Position !
            </Typography>

            <Box sx={{ display: { xs: "flex" } }}>
              <Button
                onClick={handleDeleteClose}
                className="modal-btn btn-danger"
                sx={{ marginRight: "0.5rem" }}
              >
                Discard
              </Button>
              <Button
                onClick={handleDelete}
                className="modal-btn btn-primary"
                sx={{ marginLeft: "0.5rem" }}
              >
                Confirm
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
