import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunk from "redux-thunk";
import authReducers from "../redux/reducers/authReducer";
import brokerReducers from "../redux/reducers/brokerReducer";
import commonReducers from "../redux/reducers/commonReducer";
import positionReducer from "../redux/reducers/positionReducer";
import plreportReducer from "../redux/reducers/plreportReducer";
import adminReducer from "../redux/reducers/adminReducer";
import marketWatchReducer from "../redux/reducers/marketWatchReducer";
import stratergyReducer from "../redux/reducers/stratergyReducer";
import planvlaueReducer from "../redux/reducers/planvalueReducer";

const rootReducer = combineReducers({
  Auth: authReducers,
  Broker: brokerReducers,
  CommonReducer: commonReducers,
  Position: positionReducer,
  Plreport: plreportReducer,
  Admin: adminReducer,
  Marketwatch: marketWatchReducer,
  Stratergy: stratergyReducer,
  Invocieplanvalue: planvlaueReducer,
});

const logger = (store) => (next) => (action) => {
  // console.group(action.type);
  let result = next(action);
  console.groupEnd();
  return result;
};

const monitorReducerEnhancer =
  (createStore) => (reducer, initialState, enhancer) => {
    const monitoredReducer = (state, action) => {
      const start = performance.now();
      const newState = reducer(state, action);
      const end = performance.now();
      const diff = Math.round(end - start);

      return newState;
    };

    return createStore(monitoredReducer, initialState, enhancer);
  };

const intialState = {};

const middlewares = [logger, thunk];
const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer];
const composedEnhancers = compose(...enhancers);

const store = createStore(rootReducer, intialState, composedEnhancers);

export default store;
