import React from "react";
import "./Orderhistory.scss";
import {
  Box,
  Grid,
  Button,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import Tablesearch from "../Tablesearch/Tablesearch";
import Table from "../Table/Table";
import MessageIcon from "@mui/icons-material/Message";
import Dropdown from "../Dropdown/Dropdown";

export default function Orderhistory() {
  const message = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      Aliquam eget finibus ante, non facilisis lectus. Sed vitae dignissim est,
      vel aliquam tellus. Praesent non nunc mollis, fermentum neque at, semper
      arcu. Nullam eget est sed sem iaculis gravida eget vitae justo.
    </Typography>
  );

  const col = [
    "Symbol Name",
    "Trend",
    "OrderID",
    "QTY",
    "Buy Price",
    "Exit Price",
    "Date/Time",
    "Status",
    "Order Type",
    "RejectionReason",
  ];
  const rows = [
    {
      SymbolName: "BANKNIFTY2310542900PE",
      Trend: <span className="down">Sell</span>,
      OrderID: "230104000209323",
      QTY: 150,
      EntryPrice: 50,
      ExitPrice: 118.8,
      DateTime: "15-05-2023 / 14:21:11:8",
      Status: <span className="close">Complete</span>,
      OrderType: "MIS",
      RejectionReason: (
        <Tooltip title={message} arrow placement="left">
          <Button className="rejectMessage">
            <MessageIcon sx={{ color: "#fff !important" }} />
          </Button>
        </Tooltip>
      ),
    },
    {
      SymbolName: "BANKNIFTY2310542900PE",
      Trend: <span className="down">Sell</span>,
      OrderID: "230104000209323",
      QTY: 150,
      EntryPrice: 50,
      ExitPrice: 118.8,
      DateTime: "15-05-2023 / 14:21:11:8",
      Status: <span className="close">Complete</span>,
      OrderType: "MIS",
      RejectionReason: (
        <Tooltip title={message} arrow placement="left">
          <Button className="rejectMessage">
            <MessageIcon sx={{ color: "#fff !important" }} />
          </Button>
        </Tooltip>
      ),
    },
  ];

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });

  const [alignment, setAlignment] = React.useState("paper");
  const handleChange = (e, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <Box className="tabelBox">
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={12}>
          <Box className="selectiondiv-box m-rb">
            <Box className="selectionDiv searchFlex">
              <Tablesearch />
            </Box>
            <Box className="selectionDiv searchFlex">
              <Typography className="label" component={"label"}>
                Select Trade Type
              </Typography>
              <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleChange}
                className="tradeType-toggle"
              >
                <ToggleButton value="paper">Paper</ToggleButton>
                <ToggleButton value="live">Live</ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Table col={col} rows={rows} />
    </Box>
  );
}
