import React, { useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
// import './Dashboard.scss';

import Dashboardview from "./Dashboardview/Dashboardview";
import Userview from "./Userview/Userview";
import Tradedetails from "./Tradedetails/Tradedetails";
import Liveposition from "./Livepostion/Livepostion";
import Paperposition from "./Paperpostion/Paperpostion";
import Position from "../../Position/Position";
import Closeposition from "./Closeposition/Closeposition";
import Orderbook from "./Orderbook/Orderbook";
import CopyTradeDetails from "./CopytradeDetails/CopyTradeDetails";
import CloseTradeClose from "./CopyTradeClose/CopytradeClose";
import PositionalSignal from "./PositionalSignal/PositionalSignal";
import SubBrokerPosition from "./SubBrokerPosition/SubBrokerPosition";
import { useSelector } from "react-redux";

export default function Dashboard() {
  // admin tab
  const [value, setValue] = useState("admintab1");
  const handleChange = (e, newValue = "string") => {
    setValue(newValue);
  };

  const userData = useSelector((state) => state?.Auth?.authProfile?.[0]);

  return (
    <>
      <Box className="tabs">
        <TabContext value={value}>
          <TabList className="main-tab" onChange={handleChange}>
            <Tab label="Dashboard View" value={"admintab1"} />
            <Tab label="User View" value={"admintab2"} />
            <Tab label="strategy details" value={"admintab3"} />
            <Tab label="CopyTrade Details" value={"admintab7"} />
            <Tab label="Open Position" value={"admintab4"} />
            <Tab label="Close Position" value={"admintab5"} />
            <Tab label="Order Book" value={"admintab6"} />
            <Tab label="CopyTrade Close" value={"admintab8"} />
            {userData?.is_staff && (
              <Tab label="Positional Signal" value={"admintab9"} />
            )}
            {userData?.is_staff && (
              <Tab label="Sub Broker Position" value={"admintab10"} />
            )}
          </TabList>

          <Box className="tabBox" border={"none !important"}>
            <TabPanel value={"admintab1"} sx={{ padding: 0 }}>
              <Dashboardview />
            </TabPanel>
            <TabPanel value={"admintab2"} sx={{ padding: 0 }}>
              <Userview />
            </TabPanel>
            <TabPanel value={"admintab3"} sx={{ padding: 0 }}>
              <Tradedetails />
            </TabPanel>
            <TabPanel value={"admintab7"} sx={{ padding: 0 }}>
              <CopyTradeDetails />
            </TabPanel>
            <TabPanel value={"admintab4"} sx={{ padding: 0 }}>
              <Liveposition />
            </TabPanel>
            <TabPanel value={"admintab5"} sx={{ padding: 0 }}>
              <Closeposition />
            </TabPanel>
            <TabPanel value={"admintab6"} sx={{ padding: 0 }}>
              <Orderbook />
            </TabPanel>
            <TabPanel value={"admintab8"} sx={{ padding: 0 }}>
              <CloseTradeClose />
            </TabPanel>
            <TabPanel value={"admintab9"} sx={{ padding: 0 }}>
              <PositionalSignal />
            </TabPanel>
            {/* <TabPanel value={"admintab5"} sx={{ padding: 0 }}><Paperposition /></TabPanel> */}
            <TabPanel value={"admintab10"} sx={{ padding: 0 }}>
              <SubBrokerPosition />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </>
  );
}
