import React, { useState, useEffect } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Box,
} from '@mui/material';
import Positions from './Positions';
import Position_entry from './Position_entry';
import Entry_exit from './Entry_exit';
import Reviews from './Reviews';
import './Builder.scss';
import { NavLink } from 'react-router-dom';

const steps = ['Positions', 'Entry', 'Exit', 'Review'];
const getStepContent = (step) => {
  switch (step) {
    case 0:
      return <Positions />;
    case 1:
      return <Position_entry />;
    case 2:
      return <Entry_exit />;
    default:
      return <Reviews />;
  }
};

export default function Builder() {
  const [activeStep, setActiveStep] = useState(0);
  const [lineWidth, setLineWidth] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    const progressBar = document.querySelector('.progress-bar');
    const stepWidth = 100 / (steps.length - 1);
    const newLineWidth = activeStep * stepWidth;
    setLineWidth(newLineWidth);

    progressBar.style.width = `${newLineWidth}%`;
    progressBar.style.transition = 'width 0.5s ease-in-out';
  }, [activeStep]);


  return (
    <>
      <Box className='stretagy_builder_box'>
        <Box sx={{ position: 'relative' }}>
          <Stepper activeStep={activeStep} steps={6} variant="progress" alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div className="progress-bar-container">
            <div className="progress-bar"></div>
          </div>
        </Box>
        <Box>
          <Box>{getStepContent(activeStep)}</Box>
          <Box className='Stepper-button'>
            <Button disabled={activeStep === 0} onClick={handleBack} className='back_step'>Back</Button>
            {activeStep === steps.length - 1 ? (
              <NavLink className='next_step' to={'/reviewdeploy'} component={'a'}>
                {activeStep === steps.length - 1 ? 'Save & Backtest' : 'Next'}
              </NavLink>
            ) : (
              <Button onClick={handleNext} className='next_step'>
                {activeStep === steps.length - 1 ? 'Save & Backtest' : 'Next'}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}
