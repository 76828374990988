import * as actionTypes from "../actionTypes";
import axios from "axios";
import { API_URL } from "../../config";
import { generatePopup } from "../../utils/popup";

export const updateStrategy = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.UPDATE_STRATEGY,
      payload: payload,
    });
  });
};

export const getPnlData = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.GET_PNL_POSITIONS_SUCCESS,
      payload: payload,
    });
    resolve();
  });
};

export const userAddStratergy = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.GET_USERADD_STRATERGY_INIT,
    });
    axios
      .get(`${API_URL}/stratergy/useraddstratergy/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_USERADD_STRATERGY_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_USERADD_STRATERGY_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get UserAdd.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_USERADD_STRATERGY_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const addUserStrategy = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.ADD_USERADD_STRATERGY_INIT,
    });
    axios
      .post(`${API_URL}/stratergy/useraddstratergy/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.ADD_USERADD_STRATERGY_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.ADD_USERADD_STRATERGY_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get UserAdd.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.ADD_USERADD_STRATERGY_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const deleteUserStrategy = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.ADD_USERADD_STRATERGY_INIT,
    });
    axios
      .put(`${API_URL}/stratergy/useraddstratergy/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.ADD_USERADD_STRATERGY_SUCCESS,
            payload: res?.data,
          });
          generatePopup("success", res?.data?.message);
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.ADD_USERADD_STRATERGY_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to delete strategy.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.ADD_USERADD_STRATERGY_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const getStratergy = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.GET_STRATERGY_INIT,
    });
    axios
      .get(`${API_URL}/stratergy/getstratergy/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_STRATERGY_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_STRATERGY_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get Stratergy.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_STRATERGY_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const refreshSocket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.TOKEN_REFRESH_INIT,
    });
    axios
      .get(`https://brokerfeed.tradearth.in/connection/startFinvensiaSocket/`)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.TOKEN_REFRESH_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.TOKEN_REFRESH_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get Stratergy.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.TOKEN_REFRESH_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const resetSocket = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.TOKEN_REFRESH_INIT,
    });
    axios
      .post(
        `https://brokerfeed.tradearth.in/connection/subscribesymbolfinvensia/`,
        payload
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.TOKEN_REFRESH_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.TOKEN_REFRESH_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get Stratergy.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.TOKEN_REFRESH_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const adminAddStrategy = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.ADD_ADMIN_STRATERGY_INIT,
    });
    axios
      .post(`${API_URL}/stratergy/getstratergy/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.ADD_ADMIN_STRATERGY_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
          generatePopup(
            "success",
            res?.data?.message || "Strategy Added SuccessFully"
          );
        } else {
          dispatch({
            type: actionTypes.ADD_ADMIN_STRATERGY_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get Stratergy.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.ADD_ADMIN_STRATERGY_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const getStrategyCode = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.GET_STRATEGY_CODE_INIT,
    });
    axios
      .get(`${API_URL}/stratergy/createStratergycode/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_STRATEGY_CODE_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_STRATEGY_CODE_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get Stratergy code.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_STRATEGY_CODE_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const getStrategies = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.GET_STRATEGIES_INIT,
    });
    axios
      .post(`${API_URL}/subbroker/getstratergydata/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_STRATEGIES_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_STRATEGIES_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get Stratergy code.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_STRATEGIES_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const getSegmentData = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.GET_SEGMENTDATA_INIT,
    });
    axios
      .post(`${API_URL}/subbroker/getsegmentdata/ `, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_SEGMENTDATA_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_SEGMENTDATA_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get Stratergy code.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_SEGMENTDATA_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const getSymbolData = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.GET_SYMBOLDATA_INIT,
    });
    axios
      .post(`${API_URL}/subbroker/getsymboldata/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_SYMBOLDATA_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_SYMBOLDATA_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get Stratergy code.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_SYMBOLDATA_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};
export const getSignalAllData = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.GET_SIGNALALLDATA_INIT,
    });
    axios
      .post(`${API_URL}/subbroker/getsignalalldata/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_SIGNALALLDATA_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_SIGNALALLDATA_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get Stratergy code.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_SIGNALALLDATA_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};
export const sendTradeSignal = (url, payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.GET_SENDTRADESIGNAL_INIT,
    });
    axios
      .post(`${url}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_SENDTRADESIGNAL_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_SENDTRADESIGNAL_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to send Signal code.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_SENDTRADESIGNAL_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const activateStrategy = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.STRATEGY_ACTIVATE_INIT,
    });
    axios
      .post(`${API_URL}/stratergy/userdeletestratergy/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.STRATEGY_ACTIVATE_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.STRATEGY_ACTIVATE_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to activate");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.STRATEGY_ACTIVATE_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const deactivateStrategy = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.STRATEGY_DEACTIVATE_INIT,
    });
    axios
      .put(`${API_URL}/stratergy/userdeletestratergy/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.STRATEGY_DEACTIVATE_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.STRATEGY_DEACTIVATE_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to activate");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.STRATEGY_DEACTIVATE_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const unDeployStrategy = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.STRATEGY_UNDEPLOY_INIT,
    });
    axios
      .put(`${API_URL}/stratergy/userundeploy/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.STRATEGY_UNDEPLOY_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.STRATEGY_UNDEPLOY_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to undeploy");
        }
      })
      .catch((error) => {
        resolve(error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.STRATEGY_UNDEPLOY_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};
