import React, { useEffect, useState } from "react";
import {
  Box,
  Tab,
  Button,
  Grid,
  Typography,
  InputAdornment,
  TextField,
  Tooltip,
  RadioGroup,
  Radio,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import "./Profilesetting.scss";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
// import alice from "../../../images/aliceblue.png";
// import zebu from "../../../images/zebull.png";
// import paisa from "../../../images/paisa.png";
// import angleBroking from "../../../images/anglebroking.png";
// import finvasia from "../../../images/finvasia.png";
// import icici from "../../../images/icici.png";
// import kotak from "../../../images/kotak.png";
// import motiwala from "../../../images/motialoswal.png";
// import swastika from "../../../images/swastika.png";
// import zerodha from "../../../images/zerodha.png";
// import fyers from "../../../images/fyers.png";
// import profitMart from "../../../images/profitMart.png";
// import upstox from "../../../images/upstox.png";
import TurnedInRoundedIcon from "@mui/icons-material/TurnedInRounded";
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  contactUs,
  editDemate,
  editSocialLink,
  getLogo,
  getLogoUrls,
  getMobileLogo,
  getPowerBroker,
  getSocialLink,
  openDemate,
  subBrokerLogin,
  updateContact,
  uplaodLogo,
  uploadMobileLogo,
} from "../../../redux/actions/brokerAction";
import { editContactValidation } from "../../../validation/profile";
import { isEditable } from "@testing-library/user-event/dist/utils";
import {
  Input,
  SignalCellularConnectedNoInternet4BarOutlined,
} from "@mui/icons-material";
import { getProfile } from "../../../redux/actions/authActions";
import { toast } from "react-toastify";
import { generatePopup } from "../../../utils/popup";
import DistributedBroker from "./distributedBroker";
export default function Profilesetting() {
  const edit = <Typography sx={{ fontSize: "1.4rem" }}>Edit</Typography>;
  const [value, setValue] = React.useState("admintab1");
  const [save, setsave] = React.useState("none");
  const [brokersave, setbrokersave] = React.useState("none");
  const savebtn = <Typography sx={{ fontSize: "1.4rem" }}>Edit</Typography>;
  const removebtn = <Typography sx={{ fontSize: "1.4rem" }}>Remove</Typography>;
  const [panelSelectedFile, setPanelSelectedFile] = useState();
  const [mobileSelectedFile, setMobileSelectedFile] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const [fieldsError, setFieldsError] = useState(false);
  const [update, setUpdate] = useState(true);
  const [isEditable, setIsEditable] = useState("");
  const [link, setLink] = useState("");
  const [brokerAllow, setAllowBroker] = useState("");
  const [brokerDemate, setBrokerDemate] = useState("");
  const [AppDimensions, setAppDimensions] = useState("");
  const [editSocial, setEditSocial] = useState(null);
  const [socialData, setSocialData] = useState({
    telegram: "",
    facebook: "",
    youtube: "",
    whatsapp: "",
    instagram: "",
    android: "",
    ios: "",
    website: "",
    linkedin: "",
    twitter: "",
  });
  const user = useSelector((state) => state.Auth.authProfile);
  const socialLinks = useSelector((state) => state.Broker.getSocialData);
  const brokerPermission = useSelector((state) => state.Broker.subLogin);
  const dispatch = useDispatch();

  // get default social links and demate data
  useEffect(() => {
    const obj = {
      refrecode: user?.[0]?.username,
    };
    if (user?.[0]?.username) {
      // demate data for default
      // dispatch(openDemate(obj));
      // social links data for default
      dispatch(getSocialLink(obj));
      // get broker allow & open demate weblink default data
      dispatch(subBrokerLogin(obj));
      // get contact us data for default
      dispatch(contactUs(obj));
    }
  }, [user]);

  useEffect(() => {
    if (socialLinks?.length > 0) {
      Object.keys(socialLinks?.[0]).map((key) => {
        setSocialData((prevState) => ({
          ...prevState,
          [key]: socialLinks?.[0]?.[key],
        }));
      });
    }
  }, [socialLinks]);

  const demateData = useSelector((state) => {
    return state?.Broker?.openDemate?.[0];
  });

  // get user data
  // useEffect(() => {
  //   dispatch(getProfile());
  // }, []);

  const handleChange = (e, newValue = "string") => {
    setValue(newValue);
  };

  const data = useSelector((state) => {
    return state?.Broker?.contact?.[0];
  });

  // component for social links action buttons
  const Sociallinkaction = (props) => {
    const { ActionName, disabledButton } = props;

    const handleClear = () => {
      setSocialData((prevState) => ({
        ...prevState,
        [ActionName]: "",
      }));
    };
    return (
      <Box className="social_action">
        <Tooltip placement="top" arrow title={savebtn}>
          <Button
            className="save_link"
            onClick={() => {
              setEditSocial(ActionName);
            }}
          >
            <EditIcon />
          </Button>
        </Tooltip>
        <Tooltip placement="top" arrow title={removebtn}>
          <Button
            disabled={disabledButton}
            onClick={handleClear}
            className="remove_link"
          >
            <DisabledByDefaultRoundedIcon />
          </Button>
        </Tooltip>
      </Box>
    );
  };

  useEffect(() => {
    const obj = {
      refrecode: user?.[0]?.username,
    };

    if (value === "admintab2") {
      dispatch(getPowerBroker());
      if (user?.[0]?.username) {
        // demate data for default
        dispatch(openDemate(obj));
      }
    }
  }, [value]);

  const powerBroker = useSelector((state) => state.Broker?.powerBrokers);
  const brokerList = useSelector((state) => state?.Broker?.brokersList) || [];

  const getUrl = (name) => {
    const broker = brokerList?.find((e) => e?.name === name);
    return broker?.url;
  };

  useEffect(() => {
    dispatch(getLogoUrls());
  }, []);

  // const brokers = [];
  const brokers = [
    {
      name: "Alice Blue",
      brokerImg: getUrl("ALICEBLUE"),
      webLink: demateData?.alice_link,
      demate: demateData?.is_alice_demat,
      brokerAllow: brokerPermission?.[0]?.is_alice ? true : false,
      visible: powerBroker?.[0]?.is_alice,
    },
    {
      name: "Zebull",
      brokerImg: getUrl("ZEBULL"),
      webLink: demateData?.zebull_link,
      demate: demateData?.is_zebull_demat,
      brokerAllow: brokerPermission?.[0]?.is_zebull ? true : false,
      visible: powerBroker?.[0]?.is_zebull,
    },
    {
      name: "5 Paisa",
      brokerImg: getUrl("5 PAISA"),
      webLink: demateData?.paisa_link,
      demate: demateData?.is_paisa_demat,
      brokerAllow: brokerPermission?.[0]?.is_5paisa ? true : false,
      visible: powerBroker?.[0]?.is_5paisa,
    },
    {
      name: "Angel One",
      brokerImg: getUrl("ANGELONE"),
      webLink: demateData?.angelone_link,
      demate: demateData?.is_angelone_demat,
      brokerAllow: brokerPermission?.[0]?.is_angelone ? true : false,
      visible: powerBroker?.[0]?.is_angelone,
    },
    {
      name: "Finvasia",
      brokerImg: getUrl("FINVASIA"),
      webLink: demateData?.finvensia_link,
      demate: demateData?.is_finvensia_demat,
      brokerAllow: brokerPermission?.[0]?.is_finvesia ? true : false,
      visible: powerBroker?.[0]?.is_finvesia,
    },
    {
      name: "ICICI",
      brokerImg: getUrl("ICICI DIRECT"),
      webLink: demateData?.icici_link,
      demate: demateData?.is_icici_demat,
      brokerAllow: brokerPermission?.[0]?.is_icici ? true : false,
      visible: powerBroker?.[0]?.is_icici,
    },
    {
      name: "Kotak",
      brokerImg: getUrl("KOTAK"),
      webLink: demateData?.kotak_link,
      demate: demateData?.is_kotak_demat,
      brokerAllow: brokerPermission?.[0]?.is_kotak ? true : false,
      visible: powerBroker?.[0]?.is_kotak,
    },
    {
      name: "Motilal",
      brokerImg: getUrl("MOTILAL"),
      webLink: demateData?.motilal_link,
      demate: demateData?.is_motilal_demat,
      brokerAllow: brokerPermission?.[0]?.is_motilal ? true : false,
      visible: powerBroker?.[0]?.is_motilal,
    },
    {
      name: "Swastika",
      brokerImg: getUrl("SWASTIKA"),
      webLink: demateData?.swastika_link,
      demate: demateData?.is_swastika_demat,
      brokerAllow: brokerPermission?.[0]?.is_swastika ? true : false,
      visible: powerBroker?.[0]?.is_swastika,
    },
    {
      name: "Zerodha",
      brokerImg: getUrl("ZERODHA"),
      webLink: demateData?.zerodha_link,
      demate: demateData?.is_zerodha_demat,
      brokerAllow: brokerPermission?.[0]?.is_zerodha ? true : false,
      visible: powerBroker?.[0]?.is_zerodha,
    },
    {
      name: "Fyres",
      brokerImg: getUrl("FYERS"),
      webLink: demateData?.fyres_link,
      demate: demateData?.is_fyres_demat,
      brokerAllow: brokerPermission?.[0]?.is_fyres ? true : false,
      visible: powerBroker?.[0]?.is_fyres,
    },
    {
      name: "Dhan",
      brokerImg: getUrl("DHAN"),
      webLink: demateData?.dhan_link,
      demate: demateData?.is_dhan_demat,
      brokerAllow: brokerPermission?.[0]?.is_dhan ? true : false,
      visible: powerBroker?.[0]?.is_dhan,
    },
    {
      name: "Goodwill",
      brokerImg: getUrl("GOODWILL"),
      webLink: demateData?.goodwill_link,
      demate: demateData?.is_goodwill_demat,
      brokerAllow: brokerPermission?.[0]?.is_goodwill ? true : false,
      visible: powerBroker?.[0]?.is_goodwill,
    },
    {
      name: "Sharekhan",
      brokerImg: getUrl("'SHAREKHAN"),
      webLink: demateData?.sharekhan_link,
      demate: demateData?.is_sharekhan_demat,
      brokerAllow: brokerPermission?.[0]?.is_sharekhan ? true : false,
      visible: powerBroker?.[0]?.is_sharekhan,
    },
    {
      name: "Expresso",
      brokerImg: getUrl("ESPRESSO"),
      webLink: demateData?.expresso_link,
      demate: demateData?.is_expresso_demat,
      brokerAllow: brokerPermission?.[0]?.is_expresso ? true : false,
      visible: powerBroker?.[0]?.is_expresso,
    },
    {
      name: "RMoney",
      brokerImg: getUrl("RMONEY"),
      webLink: demateData?.rmoney_link,
      demate: demateData?.is_rmoney_demat,
      brokerAllow: brokerPermission?.[0]?.is_rmoney ? true : false,
      visible: powerBroker?.[0]?.is_rmoney,
    },
    {
      name: "Nuvama",
      brokerImg: getUrl("NUVAMA"),
      webLink: demateData?.nuvama_link,
      demate: demateData?.is_nuvama_demat,
      brokerAllow: brokerPermission?.[0]?.is_nuvama ? true : false,
      visible: powerBroker?.[0]?.is_nuvama ? true : false,
    },
    {
      name: "Flattrade",
      brokerImg: getUrl("FLATTRADE"),
      webLink: demateData?.flattrade_link,
      demate: demateData?.is_flattrade_demat,
      brokerAllow: brokerPermission?.[0]?.is_flattrade ? true : false,
      visible: powerBroker?.[0]?.is_flattrade,
    },
    {
      name: "Profitmart",
      brokerImg: getUrl("PROFITMART"),
      webLink: demateData?.profitmart_link,
      demate: demateData?.is_profitmart_demat,
      brokerAllow: brokerPermission?.[0]?.is_profitmart ? true : false,
      visible: powerBroker?.[0]?.is_profitmart,
    },
    {
      name: "Upstox",
      brokerImg: getUrl("UPSTOX"),
      webLink: demateData?.upstox_link,
      demate: demateData?.is_upstox_demat,
      brokerAllow: brokerPermission?.[0]?.is_upstox ? true : false,
      visible: powerBroker?.[0]?.is_upstox,
    },
    {
      name: "Mandot",
      brokerImg: getUrl("MANDOT"),
      webLink: demateData?.mandot_link,
      demate: demateData?.is_mandot_demat,
      brokerAllow: brokerPermission?.[0]?.is_mandot ? true : false,
      visible: powerBroker?.[0]?.is_mandot,
    },
  ];

  const [inputFields, setInputFields] = useState([
    {
      label: `Phone 1`,
      value: "",
    },
  ]);
  const [fields, setFields] = useState();

  const addInputField = () => {
    if (inputFields.length < 3) {
      setInputFields([
        ...inputFields,
        {
          label: `Phone ${inputFields.length + 1}`,
          value: "",
        },
      ]);
    }
  };

  useEffect(() => {
    if (data) {
      setFields({ email: data?.email, address: data?.address });
      const newData = [
        {
          label: "Phone 1",
          value: data?.phone1 ? data?.phone1 : "",
        },
        {
          label: "Phone 2",
          value: data?.phone2 ? data?.phone2 : "",
        },
        {
          label: "Phone 3",
          value: data?.phone3 ? data?.phone3 : "",
        },
      ];
      setInputFields(newData);
    }
  }, [data]);

  useEffect(() => {
    setFields({
      ...fields,
      phone1: inputFields.find((field) => field.label === "Phone 1")?.value
        ? inputFields.find((field) => field.label === "Phone 1")?.value
        : "",
      phone2: inputFields.find((field) => field.label === "Phone 2")?.value
        ? inputFields.find((field) => field.label === "Phone 2")?.value
        : "",
      phone3: inputFields.find((field) => field.label === "Phone 3")?.value
        ? inputFields.find((field) => field.label === "Phone 3")?.value
        : "",
    });
  }, [inputFields]);

  const removeInputField = (indexToRemove) => {
    setInputFields(inputFields.filter((_, index) => index !== indexToRemove));
  };

  const handleInputChange = (index, value) => {
    if (value?.length <= 10) {
      const newInputFields = [...inputFields];
      newInputFields[index].value = value;
      setInputFields(newInputFields);
    }
  };

  const showSave = () => {
    setUpdate(false);
    setsave("block");
  };

  const showbrokerSave = (items, index) => {
    setLink(items?.webLink);
    setAllowBroker(items?.brokerAllow);
    setBrokerDemate(items?.demate);
    if (isEditable !== index) {
      setIsEditable(index);
    } else {
      setIsEditable("");
    }
    setbrokersave("block");
  };

  const handleInputChanges = (event) => {
    const keyName = event.target.name;
    const keyValue = event.target.value;
    setFields((prevState) => ({
      ...prevState,
      [keyName]: keyValue,
    }));
  };

  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    setAppDimensions({ width: naturalWidth, height: naturalHeight });
  };

  //upload panel file
  const handlePanelFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = (e) => {
          const { naturalWidth, naturalHeight } = e.target;

          if (naturalHeight !== 904 && naturalWidth !== 2641) {
            generatePopup("error :", "image size should be 2641px * 904px");
          } else {
            setPanelSelectedFile(file);
          }
        };
      };
      reader.readAsDataURL(file);
    }
    setPanelSelectedFile(file);
  };

  const handleUploadLogo = () => {
    let formData = new FormData();

    if (panelSelectedFile) {
      formData.append("image", panelSelectedFile);
      dispatch(uplaodLogo(formData));
      setPanelSelectedFile();
    } else {
      generatePopup("error", "Please select image");
    }
  };

  const handleUploadMobileLogo = () => {
    let formData = new FormData();

    if (mobileSelectedFile) {
      formData.append("image", mobileSelectedFile);
      dispatch(uploadMobileLogo(formData));
      setMobileSelectedFile();
    } else {
      generatePopup("error", "Please select image");
    }
  };

  //upload Mobile file
  const handleMobileFileChange = (event) => {
    const file = event.target.files[0];
    // if (file) {
    //   const reader = new FileReader();
    //   reader.onload = (event) => {
    //     const img = new Image();
    //     img.src = event.target.result;
    //     img.onload = (e) => {
    //       const { naturalWidth, naturalHeight } = e.target;

    //       if (naturalHeight !== 32 && naturalWidth !== 32) {
    //         generatePopup("error :", "image size should be 32px * 32px");
    //       } else {
    //         setMobileSelectedFile(file);
    //       }
    //     };
    //   };
    //   reader.readAsDataURL(file);
    // }
    setMobileSelectedFile(file);
  };

  // save edited contact us details
  const handleSave = () => {
    setIsSubmit(true);
    const errors = editContactValidation(fields);

    if (Object.keys(errors) == 0) {
      dispatch(updateContact(fields));
    }
    setFieldsError(editContactValidation(fields));
  };

  const handleDemateSave = (items, index) => {
    const obj = {
      broker: items?.name?.toLowerCase().replace(/ +/g, ""),
      brokerallow: brokerAllow ? "True" : "False",
      brokerlink: link ? link : "",
      brokerdemat: brokerDemate ? "True" : "False",
    };

    dispatch(editDemate(obj)).then((res) => {
      if (res?.status === 200) {
        const obj = {
          refrecode: user?.[0]?.username,
        };
        if (user?.[0]?.username) {
          dispatch(openDemate(obj));
          setIsEditable("");
        }
      }
    });
  };

  // set all social links in state
  const handleSocialChange = (e) => {
    const keyName = e.target?.name;
    const keyValue = e.target?.value;
    setSocialData((prevState) => ({
      ...prevState,
      [keyName]: keyValue,
    }));
  };

  // api calling for social links save
  const handleSocialSave = () => {
    dispatch(editSocialLink(socialData));
  };

  const userData = useSelector((state) => state?.Auth?.authProfile?.[0]);

  useEffect(() => {
    dispatch(getPowerBroker());
    dispatch(getMobileLogo({ refrecode: userData?.refrecode }));
    dispatch(getLogo({ refrecode: userData?.refrecode }));
  }, []);

  const mobileLogo = useSelector((state) => state?.Broker?.mobileLogo);
  const panelLogo = useSelector((state) => state?.Broker?.logo);

  useEffect(() => {
    setMobileSelectedFile({ name: mobileLogo?.url });
    setPanelSelectedFile({ name: panelLogo?.url });
  }, [mobileLogo, panelLogo]);

  return (
    <Box className="tabs">
      <TabContext value={value}>
        <TabList className="main-tab" onChange={handleChange}>
          <Tab label="Contact Us Update" value={"admintab1"} />
          <Tab label="Broker Allow & Open Demat Weblink" value={"admintab2"} />
          <Tab label="Logo Update" value={"admintab3"} />
          <Tab label="Update Social Links" value={"admintab4"} />
          {(user?.[0]?.is_staff || user?.[0]?.is_powerbroker) && (
            <Tab label="Distributed Broker Allow " value={"admintab5"} />
          )}
        </TabList>

        <Box className="tabBox">
          {/* Contact Us Update */}
          <TabPanel value={"admintab1"} sx={{ padding: 2 }}>
            <Grid container spacing={2} sx={{ maxWidth: "550px" }}>
              <Grid item xs={12}>
                <Box className="inputFields space fullWidth space-y">
                  <Typography component={"label"} className="label">
                    Email
                  </Typography>
                  <TextField
                    placeholder="E-mail"
                    type="text"
                    id="Email"
                    name="email"
                    value={fields?.email || ""}
                    onChange={handleInputChanges}
                    disabled={update}
                  />
                  {fieldsError && (
                    <span className="color">{fieldsError.email}</span>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box className="inputFields space fullWidth space-y">
                  <Typography component={"label"} className="label">
                    Phone Number
                  </Typography>
                  {inputFields.map((data, index) => {
                    return (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 1,
                          }}
                          key={index}
                        >
                          <TextField
                            placeholder={"Phone " + (index + 1)}
                            type="text"
                            id={`Phone${index}`}
                            name={`Phone${index}`}
                            value={data.value || ""}
                            onChange={(e) =>
                              handleInputChange(index, e.target.value)
                            }
                            disabled={update}
                            sx={{ marginRight: 1 }}
                          />

                          {inputFields.length > 1 && (
                            <Button
                              className="solidButton squareOff-btn small"
                              onClick={() => removeInputField(index)}
                              disabled={update}
                            >
                              <CancelIcon />
                            </Button>
                          )}
                        </Box>
                        {fieldsError && (
                          <p className="color">
                            {(index + 1 === 1 && fieldsError?.phone1) ||
                              (index + 1 === 2 && fieldsError?.phone2) ||
                              (index + 1 === 3 && fieldsError?.phone3)}
                          </p>
                        )}
                      </>
                    );
                  })}
                  <Button
                    className="solidButton download-btn small"
                    onClick={addInputField}
                  >
                    <AddCircleIcon />
                  </Button>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box className="inputFields space fullWidth space-y">
                  <Typography component={"label"} className="label">
                    Address
                  </Typography>
                  <TextField
                    multiline
                    rows={4}
                    placeholder="Write Your Address..."
                    type="text"
                    id="Phone"
                    name="address"
                    value={fields?.address || ""}
                    onChange={handleInputChanges}
                    disabled={update}
                  />
                  {fieldsError && (
                    <span className="color">{fieldsError.address}</span>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right",
                    width: "100%",
                  }}
                >
                  <Tooltip title={edit} arrow placement="top">
                    <Button
                      className="fetchReport-btn solidButton"
                      onClick={showSave}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                      >
                        {" "}
                        <path
                          d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </Button>
                  </Tooltip>
                  <Button
                    className="formSolid-btn"
                    sx={{ marginLeft: 1, display: save }}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </TabPanel>

          {/* Broker Allow & Open Demat Weblink */}
          <TabPanel value={"admintab2"} sx={{ padding: 0 }}>
            <TableContainer sx={{ MarginTop: 16 }}>
              <Table
                sx={{ minWidth: "100%" }}
                className="table tableData allowTable"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Broker Name</TableCell>
                    <TableCell>Broker Allow</TableCell>
                    <TableCell>Web Link</TableCell>
                    <TableCell>Demat</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {brokers?.map((items, index) => (
                    <>
                      {items?.visible && (
                        <TableRow key={index}>
                          <TableCell>
                            <Box className="brokerName">
                              {items.name} <img src={items.brokerImg} />
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box className="inputFields">
                              <RadioGroup
                                row
                                defaultValue={items?.brokerAllow ? "yes" : "no"}
                                onChange={(e) => {
                                  setAllowBroker(
                                    e.target.value === "yes" ? true : false
                                  );
                                }}
                              >
                                <FormControlLabel
                                  disabled={!(isEditable === index)}
                                  value="yes"
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value="no"
                                  disabled={!(isEditable === index)}
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            </Box>
                          </TableCell>
                          <TableCell>
                            {!(isEditable === index) ? (
                              <Typography
                                href={items?.webLink}
                                className="dematLink"
                                component={"a"}
                              >
                                {items?.webLink === "NA" ? "-" : items?.webLink}
                              </Typography>
                            ) : (
                              <Box className="inputFields space fullWidth space-y">
                                <TextField
                                  defaultValue={items.webLink}
                                  onChange={(e) => {
                                    setLink(e?.target?.value);
                                  }}
                                />
                              </Box>
                            )}
                          </TableCell>
                          <TableCell>
                            <Box className="inputFields">
                              <RadioGroup
                                row
                                defaultValue={items?.demate ? "yes" : "no"}
                                onChange={(e) => {
                                  setBrokerDemate(
                                    e.target.value === "yes" ? true : false
                                  );
                                }}
                              >
                                <FormControlLabel
                                  onClick={() => {
                                    (link === "NA" || link === "") &&
                                      isEditable === index &&
                                      toast.error("please enter web link");
                                  }}
                                  disabled={
                                    !(
                                      isEditable === index &&
                                      !(link === "NA" || link === "")
                                    )
                                  }
                                  value="yes"
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  disabled={!(isEditable === index)}
                                  value="no"
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "left",
                                width: "100%",
                              }}
                            >
                              <Tooltip title={edit} arrow placement="top">
                                <Button
                                  disabled={isEditable === index ? true : false}
                                  className="fetchReport-btn solidButton"
                                  onClick={() => showbrokerSave(items, index)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="1em"
                                    viewBox="0 0 512 512"
                                  >
                                    {" "}
                                    <path
                                      d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </Button>
                              </Tooltip>
                              <Button
                                className="formSolid-btn"
                                sx={{
                                  marginLeft: 1,
                                  display:
                                    isEditable === index ? "block" : "none",
                                }}
                                onClick={() => {
                                  handleDemateSave(items, index);
                                }}
                              >
                                Save
                              </Button>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>

          {/* Logo Update */}
          <TabPanel value={"admintab3"} sx={{ padding: 2 }}>
            <Grid container spacing={2} sx={{ maxWidth: "450px" }}>
              <Grid item xs={12}>
                <Typography component={"label"} className="label">
                  Upload Logo For Panel
                </Typography>
                <Box
                  className="formBox"
                  display={{ xs: "block !important", md: "flex !important" }}
                  marginTop={2}
                >
                  <Box
                    className="formItems"
                    sx={{ marginRight: { xs: 0, md: 1 } }}
                  >
                    <Typography
                      className="fileUpload"
                      component={"label"}
                      sx={{
                        maxWidth: "100% !important",
                        marginTop: "0 !important",
                      }}
                    >
                      <svg
                        width="14"
                        height="15"
                        viewBox="0 0 14 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 8.3335V12.3335C1 13.0699 1.59695 13.6668 2.33333 13.6668H11.6667C12.4031 13.6668 13 13.0699 13 12.3335V8.3335"
                          stroke="#8D8D8D"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.33333 10.3333V1M7.33333 1L4 4.62964M7.33333 1L10.6667 4.62963"
                          stroke="#8D8D8D"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Browse Logo
                      <TextField
                        type="file"
                        name="file"
                        id="file"
                        onChange={handlePanelFileChange}
                        accept="image/*"
                      />
                    </Typography>
                    {panelSelectedFile && (
                      <p style={{ fontWeight: "bold" }}>
                        Selected File: {panelSelectedFile.name}
                      </p>
                    )}
                  </Box>
                  <Button
                    className="formSolid-btn"
                    onClick={() => handleUploadLogo()}
                  >
                    Save
                  </Button>
                </Box>

                <Box className="logoSize-note">
                  <Typography component={"p"}>Notes : </Typography>
                  <Typography component={"span"}>
                    Preffer Logo size 2641px x 904px
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Typography component={"label"} className="label">
                  Upload Logo For Mobile App
                </Typography>
                <Box
                  className="formBox"
                  display={{ xs: "block !important", md: "flex !important" }}
                  marginTop={2}
                >
                  <Box
                    className="formItems"
                    sx={{ marginRight: { xs: 0, md: 1 } }}
                  >
                    <Typography
                      className="fileUpload"
                      component={"label"}
                      sx={{
                        maxWidth: "100% !important",
                        marginTop: "0 !important",
                      }}
                    >
                      <svg
                        width="14"
                        height="15"
                        viewBox="0 0 14 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 8.3335V12.3335C1 13.0699 1.59695 13.6668 2.33333 13.6668H11.6667C12.4031 13.6668 13 13.0699 13 12.3335V8.3335"
                          stroke="#8D8D8D"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.33333 10.3333V1M7.33333 1L4 4.62964M7.33333 1L10.6667 4.62963"
                          stroke="#8D8D8D"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Browse Logo
                      <TextField
                        type="file"
                        name="file"
                        id="file"
                        onChange={handleMobileFileChange}
                        accept="image/*"
                      />
                    </Typography>
                    {mobileSelectedFile && (
                      <p style={{ fontWeight: "bold" }}>
                        Selected File: {mobileSelectedFile.name}
                      </p>
                    )}
                  </Box>
                  <Button
                    className="formSolid-btn"
                    onClick={() => handleUploadMobileLogo()}
                  >
                    Save
                  </Button>
                </Box>

                <Box className="logoSize-note">
                  <Typography component={"p"}>Notes : </Typography>
                  <Typography component={"span"}>
                    Preffer Logo size 32px x 32px
                  </Typography>
                </Box>
              </Grid>

              {/* <Grid item xs={12} sx={{ marginTop: { xs: 0, md: 2 } }}>
                <Typography component={"label"} className="label">
                  Upload Logo For Mobile App
                </Typography>
                <Box
                  className="formBox"
                  display={{ xs: "block !important", md: "flex !important" }}
                  marginTop={2}
                >
                  <Box
                    className="formItems"
                    sx={{ marginRight: { xs: 0, md: 1 } }}
                  >
                    <Typography
                      className="fileUpload"
                      component={"label"}
                      sx={{
                        maxWidth: "100% !important",
                        marginTop: "0 !important",
                      }}
                    >
                      <svg
                        width="14"
                        height="15"
                        viewBox="0 0 14 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 8.3335V12.3335C1 13.0699 1.59695 13.6668 2.33333 13.6668H11.6667C12.4031 13.6668 13 13.0699 13 12.3335V8.3335"
                          stroke="#8D8D8D"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.33333 10.3333V1M7.33333 1L4 4.62964M7.33333 1L10.6667 4.62963"
                          stroke="#8D8D8D"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Browes Logo
                      <TextField
                        type="file"
                        name="file"
                        id="file"
                        onChange={handleMobileFileChange}
                        accept="image/*"
                      />
                    </Typography>
                  </Box>
                  <Button className="formSolid-btn">Save</Button>
                </Box>

                <Box className="logoSize-note">
                  <Typography component={"p"}>Notes : </Typography>
                  <Typography component={"span"}>
                    Preffer Logo size 224px x 88px
                  </Typography>
                </Box>
              </Grid> */}
            </Grid>
          </TabPanel>

          {/* social links update */}
          <TabPanel value={"admintab4"} sx={{ padding: 2 }}>
            <Typography component={"h4"} className="social_tagline">
              Share the links of your social handles (fill all active channel
              links)
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={10} md={6} lg={4}>
                <Box className="social_link_update">
                  <Box className="social_icon youtube">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 576 512"
                    >
                      <path
                        d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                        fill="currentColor"
                      />
                    </svg>
                  </Box>
                  <Box className="social_input">
                    <Typography component={"label"} className="label">
                      Youtube Link
                    </Typography>
                    <Box className="inputFields space fullWidth">
                      <TextField
                        onChange={handleSocialChange}
                        name="youtube"
                        type="text"
                        value={socialData?.youtube}
                        placeholder="Enter Youtube link"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        disabled={!(editSocial === "youtube")}
                      />
                      <Sociallinkaction
                        disabledButton={!(editSocial === "youtube")}
                        ActionName="youtube"
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={10} md={6} lg={4}>
                <Box className="social_link_update">
                  <Box className="social_icon twitter">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                        fill="currentColor"
                      />
                    </svg>
                  </Box>
                  <Box className="social_input">
                    <Typography component={"label"} className="label">
                      Twitter Link
                    </Typography>
                    <Box className="inputFields space fullWidth">
                      <TextField
                        value={socialData?.twitter}
                        onChange={handleSocialChange}
                        name="twitter"
                        type="text"
                        placeholder="Enter Twitter link"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        disabled={!(editSocial === "twitter")}
                      />
                      <Sociallinkaction
                        disabledButton={!(editSocial === "twitter")}
                        ActionName="twitter"
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={10} md={6} lg={4}>
                <Box className="social_link_update">
                  <Box className="social_icon facebook">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
                        fill="currentColor"
                      />
                    </svg>
                  </Box>
                  <Box className="social_input">
                    <Typography component={"label"} className="label">
                      Facebook Link
                    </Typography>
                    <Box className="inputFields space fullWidth">
                      <TextField
                        value={socialData?.facebook}
                        onChange={handleSocialChange}
                        name="facebook"
                        type="text"
                        placeholder="Enter Facebook link"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        disabled={!(editSocial === "facebook")}
                      />
                      <Sociallinkaction
                        disabledButton={!(editSocial === "facebook")}
                        ActionName="facebook"
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={10} md={6} lg={4}>
                <Box className="social_link_update">
                  <Box className="social_icon whatsapp">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 448 512"
                    >
                      <path
                        d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
                        fill="currentColor"
                      />
                    </svg>
                  </Box>
                  <Box className="social_input">
                    <Typography component={"label"} className="label">
                      Whatsapp Link
                    </Typography>
                    <Box className="inputFields space fullWidth">
                      <TextField
                        value={socialData?.whatsapp}
                        onChange={handleSocialChange}
                        name="whatsapp"
                        type="text"
                        placeholder="Enter Whatsapp link"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        disabled={!(editSocial === "whatsapp")}
                      />
                      <Sociallinkaction
                        disabledButton={!(editSocial === "whatsapp")}
                        ActionName="whatsapp"
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={10} md={6} lg={4}>
                <Box className="social_link_update">
                  <Box className="social_icon insta">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 448 512"
                    >
                      <path
                        d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                        fill="currentColor"
                      />
                    </svg>
                  </Box>
                  <Box className="social_input">
                    <Typography component={"label"} className="label">
                      Instagram Link
                    </Typography>
                    <Box className="inputFields space fullWidth">
                      <TextField
                        value={socialData?.instagram}
                        onChange={handleSocialChange}
                        name="instagram"
                        type="text"
                        placeholder="Enter Instagram link"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        disabled={!(editSocial === "instagram")}
                      />
                      <Sociallinkaction
                        disabledButton={!(editSocial === "instagram")}
                        ActionName="instagram"
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={10} md={6} lg={4}>
                <Box className="social_link_update">
                  <Box className="social_icon telegram">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 496 512"
                    >
                      <path
                        d="M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z"
                        fill="currentColor"
                      />
                    </svg>
                  </Box>
                  <Box className="social_input">
                    <Typography component={"label"} className="label">
                      Telegram Link
                    </Typography>
                    <Box className="inputFields space fullWidth">
                      <TextField
                        value={socialData?.telegram}
                        onChange={handleSocialChange}
                        name="telegram"
                        type="text"
                        placeholder="Enter Telegram link"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        disabled={!(editSocial === "telegram")}
                      />
                      <Sociallinkaction
                        disabledButton={!(editSocial === "telegram")}
                        ActionName="telegram"
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={10} md={6} lg={4}>
                <Box className="social_link_update">
                  <Box className="social_icon linkedin">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 448 512"
                    >
                      <path
                        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                        fill="currentColor"
                      />
                    </svg>
                  </Box>
                  <Box className="social_input">
                    <Typography component={"label"} className="label">
                      Linkedin Link
                    </Typography>
                    <Box className="inputFields space fullWidth">
                      <TextField
                        value={socialData?.linkedin}
                        onChange={handleSocialChange}
                        name="linkedin"
                        type="text"
                        placeholder="Enter Linkedin link"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        disabled={!(editSocial === "linkedin")}
                      />
                      <Sociallinkaction
                        disabledButton={!(editSocial === "linkedin")}
                        ActionName="linkedin"
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={10} md={6} lg={4}>
                <Box className="social_link_update">
                  <Box className="social_icon linkedin">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z"
                        fill="currentColor"
                      />
                    </svg>
                  </Box>
                  <Box className="social_input">
                    <Typography component={"label"} className="label">
                      Website Link
                    </Typography>
                    <Box className="inputFields space fullWidth">
                      <TextField
                        value={socialData?.website}
                        onChange={handleSocialChange}
                        type="text"
                        name="website"
                        placeholder="Enter Website link"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        disabled={!(editSocial === "website")}
                      />
                      <Sociallinkaction
                        disabledButton={!(editSocial === "website")}
                        ActionName="website"
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={10} md={6} lg={4}>
                <Box className="social_link_update">
                  <Box className="social_icon android">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 576 512"
                    >
                      {" "}
                      <path
                        d="M420.55,301.93a24,24,0,1,1,24-24,24,24,0,0,1-24,24m-265.1,0a24,24,0,1,1,24-24,24,24,0,0,1-24,24m273.7-144.48,47.94-83a10,10,0,1,0-17.27-10h0l-48.54,84.07a301.25,301.25,0,0,0-246.56,0L116.18,64.45a10,10,0,1,0-17.27,10h0l47.94,83C64.53,202.22,8.24,285.55,0,384H576c-8.24-98.45-64.54-181.78-146.85-226.55"
                        fill="currentColor"
                      />
                    </svg>
                  </Box>
                  <Box className="social_input">
                    <Typography component={"label"} className="label">
                      Android Link
                    </Typography>
                    <Box className="inputFields space fullWidth">
                      <TextField
                        value={socialData?.android}
                        onChange={handleSocialChange}
                        name="android"
                        type="text"
                        placeholder="Enter Android link"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        disabled={!(editSocial === "android")}
                      />
                      <Sociallinkaction
                        disabledButton={!(editSocial === "android")}
                        ActionName="android"
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={10} md={6} lg={4}>
                <Box className="social_link_update">
                  <Box className="social_icon ios">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 384 512"
                    >
                      {" "}
                      <path
                        d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                        fill="currentColor"
                      />
                    </svg>
                  </Box>
                  <Box className="social_input">
                    <Typography component={"label"} className="label">
                      IOS Link
                    </Typography>
                    <Box className="inputFields space fullWidth">
                      <TextField
                        value={socialData?.ios}
                        onChange={handleSocialChange}
                        name="ios"
                        type="text"
                        placeholder="Enter IOS link"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        disabled={!(editSocial === "ios")}
                      />
                      <Sociallinkaction
                        disabledButton={!(editSocial === "ios")}
                        ActionName="ios"
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={10} md={6} lg={4}>
                <Box className="social_link_update">
                  <Box className="social_icon privacy">
                    <svg
                      fill="#ffffff"
                      width="800px"
                      height="800px"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect x="0" fill="none" width="20" height="20" />

                      <g>
                        <path d="M10 9.6c-.6 0-1 .4-1 1 0 .4.3.7.6.8l-.3 1.4h1.3l-.3-1.4c.4-.1.6-.4.6-.8.1-.6-.3-1-.9-1zm.1-4.3c-.7 0-1.4.5-1.4 1.2V8h2.7V6.5c-.1-.7-.6-1.2-1.3-1.2zM10 2L3 5v3c.1 4.4 2.9 8.3 7 9.9 4.1-1.6 6.9-5.5 7-9.9V5l-7-3zm4 11c0 .6-.4 1-1 1H7c-.6 0-1-.4-1-1V9c0-.6.4-1 1-1h.3V6.5C7.4 5.1 8.6 4 10 4c1.4 0 2.6 1.1 2.7 2.5V8h.3c.6 0 1 .4 1 1v4z" />
                      </g>
                    </svg>
                  </Box>
                  <Box className="social_input">
                    <Typography component={"label"} className="label">
                      Privacy & Policy Link
                    </Typography>
                    <Box className="inputFields space fullWidth">
                      <TextField
                        value={socialData?.privacy}
                        onChange={handleSocialChange}
                        name="privacy"
                        type="text"
                        placeholder="Enter privacy link"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        disabled={!(editSocial === "privacy")}
                      />
                      <Sociallinkaction
                        disabledButton={!(editSocial === "privacy")}
                        ActionName="privacy"
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={10} md={6} lg={4}>
                <Box className="social_link_update">
                  <Box className="social_icon faq">
                    <svg
                      fill="#ffffff"
                      height="800px"
                      width="800px"
                      version="1.1"
                      color="white"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M256,0C114.613,0,0,114.617,0,256c0,141.391,114.613,256,256,256s256-114.609,256-256C512,114.617,397.387,0,256,0z
     M256,384c-17.674,0-32-14.32-32-32c0-17.672,14.326-32,32-32s32,14.328,32,32C288,369.68,273.674,384,256,384z M280.438,251.172
    c-5.047,2.078-8.438,7.75-8.438,14.109V272c0,8.844-7.156,16-16,16c-8.844,0-16-7.156-16-16v-6.719
    c0-19.453,11.078-36.609,28.219-43.688c14-5.797,21.922-20.391,19.25-35.516c-2.203-12.594-12.953-23.344-25.531-25.547
    c-9.75-1.719-19.141,0.766-26.5,6.953C228.172,173.594,224,182.531,224,192c0,8.844-7.156,16-16,16c-8.844,0-16-7.156-16-16
    c0-18.953,8.328-36.828,22.859-49.031c14.516-12.188,33.656-17.266,52.609-13.953c25.813,4.531,47,25.719,51.531,51.531
    C324.266,210.547,308.422,239.594,280.438,251.172z"
                      />
                    </svg>
                  </Box>
                  <Box className="social_input">
                    <Typography component={"label"} className="label">
                      FAQ Link
                    </Typography>
                    <Box className="inputFields space fullWidth">
                      <TextField
                        value={socialData?.faq}
                        onChange={handleSocialChange}
                        name="faq"
                        type="text"
                        placeholder="Enter FAQ link"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        disabled={!(editSocial === "faq")}
                      />
                      <Sociallinkaction
                        disabledButton={!(editSocial === "faq")}
                        ActionName="faq"
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <div
              style={{
                marginTop: "15px",
                marginRight: "10px",
                textAlign: "end",
              }}
            >
              <Button className="formSolid-btn" onClick={handleSocialSave}>
                Save
              </Button>
            </div>
          </TabPanel>

          {/* Distributed Broker Allow */}
          <TabPanel value={"admintab5"} sx={{ padding: 0 }}>
            <DistributedBroker />
            {/* <TableContainer sx={{ MarginTop: 16 }}>
              <Table
                sx={{ minWidth: "100%" }}
                className="table tableData allowTable"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Broker Name</TableCell>
                    <TableCell>Broker Allow</TableCell>
                    <TableCell>Web Link</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {brokers.map((items, index) => (
                    <>
                      <TableRow key={index}>
                        <TableCell>
                          <Box className="brokerName">
                            {items.name} <img src={items.brokerImg} />
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box className="inputFields">
                            <RadioGroup
                              row
                              defaultValue={items?.brokerAllow ? "yes" : "no"}
                              onChange={(e) => {
                                setAllowBroker(
                                  e.target.value === "yes" ? true : false
                                );
                              }}
                            >
                              <FormControlLabel
                                disabled={!(isEditable === index)}
                                value="yes"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="no"
                                disabled={!(isEditable === index)}
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </Box>
                        </TableCell>
                        <TableCell>
                          {!(isEditable === index) ? (
                            <Typography
                              href={items?.webLink}
                              className="dematLink"
                              component={"a"}
                            >
                              {items?.webLink === "NA" ? "-" : items?.webLink}
                            </Typography>
                          ) : (
                            <Box className="inputFields space fullWidth space-y">
                              <TextField
                                defaultValue={items.webLink}
                                onChange={(e) => {
                                  setLink(e?.target?.value);
                                }}
                              />
                            </Box>
                          )}
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "left",
                              width: "100%",
                            }}
                          >
                            <Tooltip title={edit} arrow placement="top">
                              <Button
                                disabled={isEditable === index ? true : false}
                                className="fetchReport-btn solidButton"
                                onClick={() => showbrokerSave(items, index)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="1em"
                                  viewBox="0 0 512 512"
                                >
                                  {" "}
                                  <path
                                    d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              </Button>
                            </Tooltip>
                            <Button
                              className="formSolid-btn"
                              sx={{
                                marginLeft: 1,
                                display:
                                  isEditable === index ? "block" : "none",
                              }}
                              onClick={() => {
                                handleDemateSave(items, index);
                              }}
                            >
                              Save
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> */}
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
}
