import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  Tooltip,
  TextField,
} from "@mui/material";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import Table from "../../../Table/Table";
import Dropdown from "../../../Dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  AdminPaperPnlReport,
  AdminPnlReport,
  getBrokers,
  getSegment,
} from "../../../../redux/actions/positionAction";
import { subBrokerLogin } from "../../../../redux/actions/brokerAction";
import dayjs from "dayjs";

export default function BrokerPermission() {
  const drpValue2 = ["True", "False"];

  const fetchreport = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      Fetch Report
    </Typography>
  );

  const [clientId, setClientId] = useState("");
  const [userId, setUserId] = useState("");
  const [loginType, setLogintype] = useState("select");
  const [broker, setBroker] = useState("select");

  const handleClientId = (event) => {
    setClientId(event.target.value);
  };

  const handleUserId = (event) => {
    setUserId(event.target.value);
  };

  const handleLoginType = (event) => {
    setLogintype(event.target.value);
  };

  const handleBroker = (event) => {
    setBroker(event.target.value);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const obj = {
      clientid: "select",
      brokername: "select",
      userid: "select",
      brokerlogin: "select",
    };
    dispatch(getBrokers(obj));
  }, []);

  const userData = useSelector((state) => state?.Auth?.authProfile?.[0]);
  const brokerDetails = useSelector((state) => state?.Position?.brokerDetails);

  useEffect(() => {
    handleSearch();
  }, [clientId, broker, userId, loginType]);

  useEffect(() => {
    dispatch(subBrokerLogin({ refrecode: userData?.refrecode }));
  }, []);
  const brokers = useSelector((state) => state?.Broker?.subLogin);
  const brokerList =
    brokers?.length > 0
      ? Object?.keys(brokers?.[0])?.filter((key) => {
        return brokers[0][key] === true;
      })
      : [];


  const handleSearch = () => {
    const obj = {
      clientid: clientId || "select",
      brokername: broker || "select",
      userid: userId || "select",
      brokerlogin: loginType || "select",
    };
    dispatch(getBrokers(obj));
  };

  const fetchData = () => {
    const obj = {
      clientid: "select",
      brokername: "select",
      userid: "select",
      brokerlogin: "select",
    };
    dispatch(getBrokers(obj));
  };

  const handleRefresh = () => {
    fetchData();
  };

  const data = useSelector((state) => state?.Position?.pnlReport);

  const sortedAsc = [];

  const col = [
    "UID",
    "Brokerlogin",
    "Broekrname",
    "datetime",
  ];

  const rows = brokerDetails?.map((e) => {
    return {
      UID: e?.uid,
      Brokerlogin: e?.brokerlogin ? "True" : "False",
      Broekrname: e?.brokername,
      datetime: dayjs(e?.datetime).format("DD-MM-YYYY hh:mm:ss A"),
    };
  });

  return (
    <>
      <Box
        sx={{
          display: { xs: "flex" },
          alignItems: { xs: "center" },
          justifyContent: { xs: "end" },
          marginBottom: { xs: "1.4rem" },
        }}
      ></Box>
      <Box className="tabelBox">
        <Grid container spacing={2} alignItems={"top"}>
          <Grid item xs={12}>
            <Box className="selectiondiv-box m-rb">
              <Box className="selectionDiv searchFlex">
                <Box className="inputFields" sx={{ marginRight: "10px" }}>
                  <TextField
                    placeholder="User Id"
                    onChange={(event) => handleUserId(event)}
                    value={userId}
                  />
                </Box>
                <Box className="inputFields" sx={{ margin: "0" }}>
                  <TextField
                    placeholder="Client Id"
                    onChange={(event) => handleClientId(event)}
                    value={clientId}
                  />
                </Box>
                <Tooltip title={"search"} arrow>
                  <Button
                    className="download-btn solidButton"
                    sx={{ marginLeft: "1rem" }}
                    onClick={handleSearch}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                        fill="#FFFFFF"
                      />
                    </svg>
                  </Button>
                </Tooltip>
              </Box>

              <Dropdown
                val={["select"].concat(drpValue2)}
                handleSelect={handleLoginType}
                value={loginType}
                title={"Select Broker Login"}
              />
              <Dropdown
                val={["select"].concat(brokerList?.map((e) => e?.slice(3)))}
                handleSelect={handleBroker}
                value={broker}
                title={"All Brokers"}
              />

              <Box className="selectionDiv searchFlex">
                <Tooltip title={fetchreport} arrow>
                  <Button
                    onClick={handleRefresh}
                    className="fetchReport-btn solidButton"
                    sx={{ marginLeft: 1 }}
                  >
                    <CloudSyncIcon />
                  </Button>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Table col={col} rows={rows} />
      </Box>
    </>
  );
}
