import { TextFields } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { upstoxValidation } from "../../../validation/broker";
import { BrokerStatus } from "../../../redux/actions/brokerAction";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const Upstox = () => {
  const inputFields = {
    appkey: "",
    secretkey: "",
    clientId: "",
  };
  const [fields, setFields] = useState(inputFields);
  const [isSubmit, setIsSubmit] = useState(false);
  const [Error, setError] = useState();
  const dispatch = useDispatch();

  const inputBorder = {
    borderRadius: "5px",
    border: "1px solid #bcbcbc",
  };

  const handleSubmit = () => {
    setIsSubmit(true);
    setError(upstoxValidation(fields));
    const error = upstoxValidation(fields);

    if (Object.keys(error) == 0) {
      localStorage.setItem("AppKey", fields?.appkey);
      localStorage.setItem("secretkey", fields?.secretkey);
      localStorage.setItem("clientId", fields?.clientId);
      localStorage.setItem("brokername", "upstox");

      window.location.replace(
        `https://api-v2.upstox.com/login/authorization/dialog?response_type=code&client_id=${fields.appkey}&redirect_uri=https://trade-panel.tradearth.in/broker&state=12345`
      );
    }
  };

  const handleChange = (event) => {
    const keyName = event.target.name;
    const keyValue = event.target.value;
    setFields((prevState) => ({
      ...prevState,
      [keyName]: keyValue,
    }));
    if (isSubmit) {
      setError(upstoxValidation({ ...fields, [keyName]: keyValue }));
    }
  };

  const brokerStatus = useSelector((state) => state.Broker.brokerstatus);
  useEffect(() => {
    dispatch(BrokerStatus());
  }, []);
  useEffect(() => {
    if (brokerStatus?.length > 0) {
      if (brokerStatus[0]?.appkey) {
        setFields({
          ...fields,
          appkey: brokerStatus[0]?.appkey,
          secretkey: brokerStatus[0]?.secretkey,
          clientId: brokerStatus[0]?.clientId,
        });
      }
    }
  }, [brokerStatus]);

  return (
    <>
      <Box className="border">
        <Box>
          <Box className="formItems" sx={{ marginBottom: 1.5 }}>
            <Typography component={"label"} className="label">
              APP KEY :
            </Typography>
            <TextField
              placeholder="Enter User Id.."
              className="inputFiled"
              style={inputBorder}
              name="appkey"
              onChange={handleChange}
              value={fields?.appkey}
            />
            {Error ? <Box className="error">{Error.appkey}</Box> : ""}
          </Box>
          <Box className="formItems" sx={{ marginBottom: 1.5 }}>
            <Typography component={"label"} className="label">
              CLIENT ID :
            </Typography>
            <TextField
              placeholder="Enter Client Id.."
              className="inputFiled"
              style={inputBorder}
              name="clientId"
              onChange={handleChange}
              value={fields?.clientId}
            />
            {Error ? <Box className="error">{Error.secretkey}</Box> : ""}
          </Box>
          <Box className="formItems" sx={{ marginBottom: 1.5 }}>
            <Typography component={"label"} className="label">
              SECRET KEY :
            </Typography>
            <TextField
              placeholder="Enter User Id.."
              className="inputFiled"
              style={inputBorder}
              name="secretkey"
              onChange={handleChange}
              value={fields?.secretkey}
            />
            {Error ? <Box className="error">{Error.secretkey}</Box> : ""}
          </Box>
          <Button
            className="btn solidButton fetchReport-btn"
            sx={{ width: "100% !important" }}
            onClick={handleSubmit}
          >
            Login
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Upstox;
