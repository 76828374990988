import { Box, Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
// import { kotakLogin } from '../../../redux/actions/brokerAction';
import { kotakValidation } from "../../../validation/broker";
import {
  BrokerStatus,
  kotakLogin,
  kotakotp,
} from "../../../redux/actions/brokerAction";
import { generatePopup } from "../../../utils/popup";
import { country_list } from "../../../Outauth/Signform/countryList";
import ReactFlagsSelect from "react-flags-select";

const Kotak = () => {
  const inputFields = {
    appkey: "",
    secretkey: "",
    mobileno: "",
    username: "",
    password: "",
    password1: "",
  };
  const [fields, setFields] = useState(inputFields);
  const [countrycode, setCountryCode] = useState("IN");
  const [isSubmit, setIsSubmit] = useState(false);
  const [Error, setError] = useState();
  const [otpSent, setOtpSent] = useState(false);
  const [response, setResponse] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let country_array = [];
  let country_label = {};
  let country_labelName = {};
  let array = Object.keys(country_list).map((code, index) => {
    const country = country_list[code];
    country_array.push(code);
    country_label[code] = "+" + country.phone;
    country_labelName[code] = " " + country.name;
  });

  const inputBorder = {
    borderRadius: "5px",
    border: "1px solid #bcbcbc",
  };

  //form input
  const handleChange = (event) => {
    const keyName = event.target.name;
    const keyValue = event.target.value;
    setFields((prevState) => ({
      ...prevState,
      [keyName]: keyValue,
    }));
    if (isSubmit) {
      setError(kotakValidation({ ...fields, [keyName]: keyValue }));
    }
  };

  const brokerStatus = useSelector((state) => state.Broker.brokerstatus);

  useEffect(() => {
    dispatch(BrokerStatus());
  }, []);

  console.log("brokerStatus :>> ", brokerStatus);

  const userData = useSelector((state) => state?.Auth?.authProfile?.[0]);

  useEffect(() => {
    if (brokerStatus?.length > 0) {
      if (brokerStatus[0]?.appkey) {
        setFields({
          ...fields,
          appkey: brokerStatus[0]?.appkey,
          secretkey: brokerStatus[0]?.secretkey,
          mobileno: brokerStatus[0]?.phone,
          username: brokerStatus[0]?.uid,
          password1: brokerStatus[0]?.password1,
        });
      }
    }
  }, [brokerStatus]);

  console.log("fields", fields);

  //dispatch data
  const handleSubmit = () => {
    console.log("submit", response);
    // {
    //     "status": "True",
    //     "token": "eyJ4NXQiOiJNbUprWWpVMlpETmpNelpqTURBM05U",
    //     "sid": "87dfc6c3-f13c-480b-912e-7d1ab7eab7e2",
    //     "auth": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzY29w",
    //     "userid": "5e7a86fc-6e4f-44d9-9ace-6f2b32ac0cbf"
    // }
    setIsSubmit(true);
    setError(kotakValidation(fields));
    let error = kotakValidation(fields);
    const obj = {
      brokername: "kotak",
      token: response?.token,
      username: fields?.username,
      otp: fields?.otp,
      appkey: fields?.appkey,
      secretkey: fields?.secretkey,
      sid: response?.sid,
      auth: response?.auth,
      userid: response?.userid,
    };
    if (Object.keys(error) == 0) {
      dispatch(kotakLogin(obj, navigate));
    }
  };

  const sendOtp = () => {
    setError(kotakValidation(fields));
    setIsSubmit(true);
    let error = kotakValidation(fields);
    if (Object.keys(error) == 0) {
      const obj = {
        appkey: fields.appkey,
        secretkey: fields.secretkey,
        username: fields.username,
        password: fields.password,
        password1: fields.password1,
        mobileno:
          (country_list[countrycode]?.phone
            ? "+" + country_list[countrycode]?.phone
            : "+91") + fields?.mobileno,
      };
      console.log("obj :>> ", obj);
      dispatch(kotakotp(obj)).then((res) => {
        setResponse(res?.data);
        if (res?.data?.status === "True") {
          setOtpSent(true);
        } else {
          generatePopup("error", "Details are invalid ");
        }
      });
    }
  };

  return (
    <>
      <Box className="border">
        <Box>
          <Grid>
            <Box className="formBox">
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  APP KEY :
                </Typography>
                <TextField
                  placeholder="Enter App Key..."
                  className="inputFiled"
                  name="appkey"
                  style={inputBorder}
                  onChange={handleChange}
                  value={fields?.appkey}
                />
                {Error ? <Box className="error">{Error.appkey}</Box> : ""}
              </Box>
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  Secret Key :
                </Typography>
                <TextField
                  placeholder="Enter Secret Key..."
                  className="inputFiled"
                  name="secretkey"
                  style={inputBorder}
                  onChange={handleChange}
                  value={fields?.secretkey}
                />
                {Error ? <Box className="error">{Error.secretkey}</Box> : ""}
              </Box>
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  Mobile No. :
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    "& >div.ReactFlagsSelect-module_flagsSelect__2pfa2 > button > span":
                    {
                      padding: 0,
                      height: "1.6rem",
                      fontSize: "1.3rem",
                    },
                    "& >div.ReactFlagsSelect-module_flagsSelect__2pfa2>ul": {
                      maxWidth: "12rem",
                      width: "12rem",
                    },
                  }}
                >
                  <ReactFlagsSelect
                    selected={countrycode}
                    countries={country_array}
                    customLabels={country_label}
                    onSelect={(code) => {
                      setCountryCode(code);
                    }}
                  />
                  <TextField
                    placeholder="Enter Mobile No..."
                    className="inputFiled"
                    name="mobileno"
                    type="number"
                    style={inputBorder}
                    onChange={handleChange}
                    value={fields?.mobileno}
                  />
                </Box>
                {Error ? <Box className="error">{Error.mobileno}</Box> : ""}
              </Box>
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  Username :
                </Typography>
                <TextField
                  placeholder="Enter Username..."
                  className="inputFiled"
                  name="username"
                  style={inputBorder}
                  onChange={handleChange}
                  value={fields?.username}
                />
                {Error ? <Box className="error">{Error.username}</Box> : ""}
              </Box>
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  Trading Password :
                </Typography>
                <TextField
                  placeholder="Enter Trading Password..."
                  className="inputFiled"
                  name="password"
                  style={inputBorder}
                  onChange={handleChange}
                  value={fields?.password}
                />
                {Error ? <Box className="error">{Error.password}</Box> : ""}
              </Box>
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  Api Password :
                </Typography>
                <TextField
                  placeholder="Enter Api Password..."
                  className="inputFiled"
                  name="password1"
                  style={inputBorder}
                  onChange={handleChange}
                  value={fields?.password1}
                />
                {Error ? <Box className="error">{Error.password1}</Box> : ""}
              </Box>

              {otpSent && (
                <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                  <Typography component={"label"} className="label">
                    OTP :
                  </Typography>
                  <TextField
                    placeholder="Enter OTP..."
                    className="inputFiled"
                    name="otp"
                    style={inputBorder}
                    onChange={handleChange}
                    value={fields?.otp}
                  />
                  {!fields?.otp && isSubmit ? (
                    <Box className="error">OTP is Required!</Box>
                  ) : (
                    ""
                  )}
                </Box>
              )}
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  className="btn solidButton fetchReport-btn"
                  sx={{ width: "100% !important" }}
                  onClick={otpSent ? handleSubmit : sendOtp}
                >
                  {otpSent ? "Login" : "Send Otp"}
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Kotak;
