import React from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import Pushalert from "./Pushalert/Pushalert";
import Smadata from "./Smsdata/Smsdata";
import Imagradition from "./Imageadition/Imageadition";
import Holidaylist from "./Holidaylist/Holidaylist";
import { useSelector } from "react-redux";

export default function Marketing() {
  // admin tab
  const [value, setValue] = React.useState("admintab1");
  const handleChange = (e, newValue = "string") => {
    setValue(newValue);
  };

  const userData = useSelector((state) => state?.Auth?.authProfile?.[0]);

  return (
    <Box className="tabs">
      <TabContext value={value}>
        <TabList className="main-tab" onChange={handleChange}>
          <Tab label="App Push Alert" value={"admintab1"} />
          {/* {userData?.is_staff && <Tab label="SMS Data" value={"admintab2"} />} */}
          <Tab label="Image Adition" value={"admintab3"} />
          {/* <Tab label="Holiday List" value={"admintab4"} /> */}
        </TabList>

        <Box className="tabBox" border={"none !important"}>
          <TabPanel value={"admintab1"} sx={{ padding: 0 }}>
            <Pushalert />
          </TabPanel>

          <TabPanel value={"admintab2"} sx={{ padding: 0 }}>
            <Smadata />
          </TabPanel>
          <TabPanel value={"admintab3"} sx={{ padding: 0 }}>
            <Imagradition />
          </TabPanel>
          {/* <TabPanel value={"admintab4"} sx={{ padding: 0 }}><Holidaylist /></TabPanel> */}
        </Box>
      </TabContext>
    </Box>
  );
}
