import { API_URL } from "../../config";
import * as actionTypes from "../actionTypes";
import axios from "axios";
import { generatePopup } from "../../utils/popup";

export const getLogoUrls = (payload, navigate) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.GET_BROKER_URLS_INIT,
    });
    axios
      .get(`${API_URL}/auth/getbrokerlogo/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_BROKER_URLS_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          generatePopup(
            "error",
            res?.data?.message || "Failed to get broker urls!"
          );
          dispatch({
            type: actionTypes.GET_BROKER_URLS_FAIL,
            payload: res?.data?.message || "Failed to get broker urls!",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_BROKER_URLS_FAIL,
            payload:
              typeof error == "string" ? error : "Failed to get broker urls!",
          });
          resolve(error.response);
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const zebullBroker = (payload, navigate) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.ZEBULL_BROKER_INIT,
    });
    axios
      .post(`${API_URL}/auth/brokerdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.ZEBULL_BROKER_SUCCESS,
          });
          generatePopup("success", res?.data?.message);
          // window.location.replace(window.location.origin);
          navigate("/broker");

          dispatch(BrokerStatus());
          resolve(res);
        } else {
          generatePopup(
            "error",
            res?.data?.message || "Failed to add zebull broker details!"
          );
          dispatch({
            type: actionTypes.ZEBULL_BROKER_FAIL,
            payload:
              res?.data?.message || "Failed to add zebull broker details!",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.ZEBULL_BROKER_FAIL,
            payload:
              typeof error == "string"
                ? error
                : "Failed to add zebull broker details!",
          });
          resolve(error.response);
          // error?.response?.data?.message
          generatePopup("error", error?.response?.data?.message);
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const iciciLogin = (payload, navigate) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.ICICI_BROKER_INIT,
    });

    axios
      .post(`${API_URL}/auth/brokerdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.ICICI_BROKER_SUCCESS,
          });
          generatePopup("success", res?.data?.message);
          navigate("/broker");

          dispatch(BrokerStatus());
          // window.location.replace(window.location.origin);

          resolve(res);
        } else {
          generatePopup("error", res?.data?.message);
          dispatch({
            type: actionTypes.ICICI_BROKER_FAIL,
            payload:
              res?.data?.message || "Failed to add icici broker details!",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.ICICI_BROKER_FAIL,
            payload:
              typeof error == "string"
                ? error
                : "Failed to add icici broker details!",
          });
          generatePopup("error", error?.response?.data?.message);
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const upstoxLogin = (payload, navigate) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.UPSTOX_BROKER_INIT,
    });

    axios
      .post(`${API_URL}/auth/brokerdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.UPSTOX_BROKER_SUCCESS,
          });
          generatePopup("success", res?.data?.message);
          navigate("/broker");

          dispatch(BrokerStatus());
          // window.location.replace(window.location.origin);

          resolve(res);
        } else {
          dispatch({
            type: actionTypes.UPSTOX_BROKER_FAIL,
          });
          generatePopup("error", res?.data?.message);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.UPSTOX_BROKER_FAIL,
            payload:
              typeof error == "string"
                ? error
                : "Failed to add upstox broker details!",
          });
          generatePopup("error", error?.response?.data?.message);
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const angelLogin = (payload, navigate) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.ANGEL_BROKER_INIT,
    });

    axios
      .post(`${API_URL}/auth/brokerdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.ANGEL_BROKER_SUCCESS,
          });
          generatePopup("success", res?.data?.message);
          navigate("/broker");
          dispatch(BrokerStatus());
          // window.location.replace(window.location.origin);
          resolve(res);
        } else {
          generatePopup(
            "error",
            res?.data?.message || "Failed to add angel broker details!"
          );
          dispatch({
            type: actionTypes.ANGEL_BROKER_FAIL,
            payload:
              res?.data?.message || "Failed to add angel broker details!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ANGEL_BROKER_FAIL,
          payload:
            typeof error == "string"
              ? error
              : "Failed to add angel broker details!",
        });
        generatePopup(
          "error",
          error?.response?.data?.message || "Failed to login broker."
        );
        if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const zerodhaLogin = (payload, navigate) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.ZERODHA_BROKER_INIT,
    });
    axios
      .post(`${API_URL}/auth/brokerdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.ZERODHA_BROKER_SUCCESS,
          });
          generatePopup("success", res?.data?.message);
          resolve(res);
          navigate("/broker");

          dispatch(BrokerStatus());
          // window.location.replace(window.location.origin);
        } else {
          generatePopup("error", res?.data?.message);
          dispatch({
            type: actionTypes.ZERODHA_BROKER_FAIL,
            payload:
              res?.data?.message || "Failed to add zerodha broker details!",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.ZERODHA_BROKER_FAIL,
            payload:
              typeof error == "string"
                ? error
                : "Failed to add zerodha broker details!",
          });
          generatePopup("error", error?.response?.data?.message);
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const finvasiaLogin = (payload, navigate) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.FINVASIA_BROKER_INIT,
    });
    axios
      .post(`${API_URL}/auth/brokerdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.FINVASIA_BROKER_SUCCESS,
          });
          generatePopup("success", res?.data?.message);
          // window.location.replace(window.location.origin);
          resolve(res);
          navigate("/broker");

          dispatch(BrokerStatus());
        } else {
          generatePopup("error", res?.data?.message);
          dispatch({
            type: actionTypes.FINVASIA_BROKER_FAIL,
            payload:
              res?.data?.message || "Failed to add finvasia broker details!",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.FINVASIA_BROKER_FAIL,
            payload:
              typeof error == "string"
                ? error
                : "Failed to add finvasia broker details!",
          });
          generatePopup("error", error?.response?.data?.message);
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const kotakLogin = (payload, navigate) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.KOTAK_BROKER_INIT,
    });
    axios
      .post(`${API_URL}/auth/brokerdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.KOTAK_BROKER_SUCCESS,
          });
          generatePopup("success", res?.data?.message);
          // window.location.replace(window.location.origin);

          resolve(res);
          navigate("/broker");

          dispatch(BrokerStatus());
        } else {
          generatePopup("error", res?.data?.message);
          dispatch({
            type: actionTypes.KOTAK_BROKER_FAIL,
            payload:
              res?.data?.message || "Failed to add kotak broker details!",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.KOTAK_BROKER_FAIL,
            payload:
              typeof error == "string"
                ? error
                : "Failed to add kotak broker details!",
          });
          generatePopup("error", error?.response?.data?.message);
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const aliceLogin = (payload, navigate) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.ALICE_BROKER_INIT,
    });
    axios
      .post(`${API_URL}/auth/brokerdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.ALICE_BROKER_SUCCESS,
          });
          generatePopup("success", res?.data?.message);
          // window.location.replace(window.location.origin);
          resolve(res);
          navigate("/broker");

          dispatch(BrokerStatus());
        } else {
          generatePopup("error", res?.data?.message);
          dispatch({
            type: actionTypes.ALICE_BROKER_FAIL,
            payload:
              res?.data?.message || "Failed to add alice broker details!",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.ALICE_BROKER_FAIL,
            payload:
              typeof error == "string"
                ? error
                : "Failed to add alice broker details!",
          });
          generatePopup("error", error?.response?.data?.message);
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const fyersBroker = (payload, navigate) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.FYERS_BROKER_INIT,
    });
    axios
      .post(`${API_URL}/auth/brokerdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.FYERS_BROKER_SUCCESS,
          });
          generatePopup("success", res?.data?.message);
          // window.location.replace(window.location.origin);
          resolve(res);
          navigate("/broker");

          dispatch(BrokerStatus());
        } else {
          generatePopup("error", res?.data?.message);
          dispatch({
            type: actionTypes.FYERS_BROKER_FAIL,
            payload:
              res?.data?.message || "Failed to add zebull broker details!",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.FYERS_BROKER_FAIL,
            payload:
              typeof error == "string"
                ? error
                : "Failed to add zebull broker details!",
          });
          generatePopup("error", error?.response?.data?.message);
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const swastikaBroker = (payload, navigate) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.SWASTIKA_BROKER_INIT,
    });
    axios
      .post(`${API_URL}/auth/brokerdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.SWASTIKA_BROKER_SUCCESS,
          });
          generatePopup("success", res?.data?.message);
          // window.location.replace(window.location.origin);
          resolve(res);
          navigate("/broker");

          dispatch(BrokerStatus());
        } else {
          generatePopup("error", res?.data?.message);
          dispatch({
            type: actionTypes.SWASTIKA_BROKER_FAIL,
            payload:
              res?.data?.message || "Failed to add zebull broker details!",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.SWASTIKA_BROKER_FAIL,
            payload:
              typeof error == "string"
                ? error
                : "Failed to add zebull broker details!",
          });
          generatePopup("error", error?.response?.data?.message);
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const dhanBroker = (payload, navigate) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.DHAN_BROKER_INIT,
    });
    axios
      .post(`${API_URL}/auth/brokerdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.DHAN_BROKER_SUCCESS,
          });
          generatePopup("success", res?.data?.message);
          // window.location.replace(window.location.origin);
          resolve(res);
          navigate("/broker");

          dispatch(BrokerStatus());
        } else {
          generatePopup("error", res?.data?.message);
          dispatch({
            type: actionTypes.DHAN_BROKER_FAIL,
            payload:
              res?.data?.message || "Failed to add zebull broker details!",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.DHAN_BROKER_FAIL,
            payload:
              typeof error == "string"
                ? error
                : "Failed to add zebull broker details!",
          });
          generatePopup("error", error?.response?.data?.message);
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const fivePaiseBroker = (payload, navigate) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.FIVEPAISA_BROKER_INIT,
    });
    axios
      .post(`${API_URL}/auth/brokerdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.FIVEPAISA_BROKER_SUCCESS,
          });
          generatePopup("success", res?.data?.message);

          // window.location.replace(window.location.origin);
          resolve(res);
          navigate("/broker");

          dispatch(BrokerStatus());
        } else {
          generatePopup("error", res?.data?.message);
          dispatch({
            type: actionTypes.FIVEPAISA_BROKER_FAIL,
            payload:
              res?.data?.message || "Failed to add zebull broker details!",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.DHAN_BROKER_FAIL,
            payload:
              typeof error == "string"
                ? error
                : "Failed to add zebull broker details!",
          });
          generatePopup("error", error?.response?.data?.message);
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const nuvamaBroker = (payload, navigate) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.NUVAMA_BROKER_INIT,
    });
    axios
      .post(`${API_URL}/auth/brokerdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.NUVAMA_BROKER_SUCCESS,
          });
          generatePopup("success", res?.data?.message);
          // window.location.replace(window.location.origin);
          resolve(res);
          navigate("/broker");

          dispatch(BrokerStatus());
        } else {
          generatePopup("error", res?.data?.message);
          dispatch({
            type: actionTypes.NUVAMA_BROKER_FAIL,
            payload:
              res?.data?.message || "Failed to add nuvama broker details!",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.NUVAMA_BROKER_FAIL,
            payload:
              typeof error == "string"
                ? error
                : "Failed to add nuvama broker details!",
          });
          generatePopup("error", error?.response?.data?.message);
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const goodwillBroker = (payload, navigate) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.GOODWILL_BROKER_INIT,
    });
    axios
      .post(`${API_URL}/auth/brokerdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GOODWILL_BROKER_SUCCESS,
          });
          generatePopup("success", res?.data?.message);
          // window.location.replace(window.location.origin);

          resolve(res);
          navigate("/broker");

          dispatch(BrokerStatus());
        } else {
          generatePopup("error", res?.data?.message);
          dispatch({
            type: actionTypes.GOODWILL_BROKER_FAIL,
            payload:
              res?.data?.message || "Failed to add goodwill broker details!",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GOODWILL_BROKER_FAIL,
            payload:
              typeof error == "string"
                ? error
                : "Failed to add goodwill broker details!",
          });
          generatePopup("error", error?.response?.data?.message);
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const rmoneyBroker = (payload, navigate) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.RMONEY_BROKER_INIT,
    });
    axios
      .post(`${API_URL}/auth/brokerdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.RMONEY_BROKER_SUCCESS,
          });
          generatePopup("success", res?.data?.message);
          // window.location.replace(window.location.origin);
          resolve(res);
          navigate("/broker");

          dispatch(BrokerStatus());
        } else {
          generatePopup("error", res?.data?.message);
          dispatch({
            type: actionTypes.RMONEY_BROKER_FAIL,
            payload:
              res?.data?.message || "Failed to add rmoney broker details!",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.RMONEY_BROKER_FAIL,
            payload:
              typeof error == "string"
                ? error
                : "Failed to add rmoney broker details!",
          });
          generatePopup("error", error?.response?.data?.message);
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const profitMartBroker = (payload, navigate) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.PROFITMART_BROKER_INIT,
    });
    axios
      .post(`${API_URL}/auth/brokerdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.PROFITMART_BROKER_SUCCESS,
          });
          generatePopup("success", res?.data?.message);
          resolve(res);
          navigate("/broker");

          dispatch(BrokerStatus());
        } else {
          generatePopup("error", res?.data?.message);
          dispatch({
            type: actionTypes.PROFITMART_BROKER_FAIL,
            payload:
              res?.data?.message || "Failed to add rmoney broker details!",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.PROFITMART_BROKER_FAIL,
            payload:
              typeof error == "string"
                ? error
                : "Failed to add profitmart broker details!",
          });
          generatePopup("error", error?.response?.data?.message);
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const expressoBroker = (payload, navigate) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.EXPRESSO_BROKER_INIT,
    });
    axios
      .post(`${API_URL}/auth/brokerdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.EXPRESSO_BROKER_SUCCESS,
          });
          generatePopup("success", res?.data?.message);
          // window.location.replace(window.location.origin);
          resolve(res);
          navigate("/broker");

          dispatch(BrokerStatus());
        } else {
          generatePopup("error", res?.data?.message);
          dispatch({
            type: actionTypes.EXPRESSO_BROKER_FAIL,
            payload:
              res?.data?.message || "Failed to add expresso broker details!",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.EXPRESSO_BROKER_FAIL,
            payload:
              typeof error == "string"
                ? error
                : "Failed to add expresso broker details!",
          });
          generatePopup("error", error?.response?.data?.message);
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};
export const mandotBroker = (payload, navigate) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.MANDOT_BROKER_INIT,
    });
    axios
      .post(`${API_URL}/auth/brokerdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.MANDOT_BROKER_SUCCESS,
          });
          generatePopup("success", res?.data?.message);
          // window.location.replace(window.location.origin);
          resolve(res);
          navigate("/broker");

          dispatch(BrokerStatus());
        } else {
          generatePopup("error", res?.data?.message);
          dispatch({
            type: actionTypes.MANDOT_BROKER_FAIL,
            payload:
              res?.data?.message || "Failed to add expresso broker details!",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.MANDOT_BROKER_FAIL,
            payload:
              typeof error == "string"
                ? error
                : "Failed to add expresso broker details!",
          });
          generatePopup("error", error?.response?.data?.message);
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const motilalOswalBroker = (payload, navigate) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.MOTILALOSWAL_BROKER_INIT,
    });
    axios
      .post(`${API_URL}/auth/brokerdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.MOTILALOSWAL_BROKER_SUCCESS,
          });
          generatePopup("success", res?.data?.message);
          // window.location.replace(window.location.origin);
          resolve(res);
          navigate("/broker");

          dispatch(BrokerStatus());
        } else {
          generatePopup("error", res?.data?.message);
          dispatch({
            type: actionTypes.MOTILALOSWAL_BROKER_FAIL,
            payload:
              res?.data?.message ||
              "Failed to add Motilal Oswal broker details!",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.MOTILALOSWAL_BROKER_FAIL,
            payload:
              typeof error == "string"
                ? error
                : "Failed to add Motilal Oswal broker details!",
          });
          generatePopup("error", error?.response?.data?.message);
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const getDhanSession = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    axios
      .get(`${API_URL}/auth/dhansession/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_DHAN_SESSION_SUCCESS,
            payload: res.data,
          });
          generatePopup("success", res?.data?.message);

          resolve(res);
        } else {
          generatePopup("error", "Dhan Session get fail.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          generatePopup("error", error?.response?.data?.message);
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const getAliceSession = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    axios
      .get(`${API_URL}/auth/alicesession/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_ALICE_SESSION_SUCCESS,
            payload: res.data,
          });
          generatePopup("success", res?.data?.message);

          resolve(res);
        } else {
          generatePopup("error", "alice Session get fail.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          generatePopup("error", error?.response?.data?.message);
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const sharekhanBroker = (payload, navigate) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.SHAREKHAN_BROKER_INIT,
    });
    axios
      .post(`${API_URL}/auth/brokerdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.SHAREKHAN_BROKER_SUCCESS,
          });
          generatePopup("success", res?.data?.message);
          // window.location.replace(window.location.origin);
          resolve(res);
          navigate("/broker");

          dispatch(BrokerStatus());
        } else {
          generatePopup("error", res?.data?.message);
          dispatch({
            type: actionTypes.SHAREKHAN_BROKER_FAIL,
            payload:
              res?.data?.message || "Failed to add sharekhan broker details!",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.SHAREKHAN_BROKER_FAIL,
            payload:
              typeof error == "string"
                ? error
                : "Failed to add sharekhan broker details!",
          });
          generatePopup("error", error?.response?.data?.message);
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const flattradeBroker = (payload, navigate) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.FLATTRADE_BROKER_INIT,
    });
    axios
      .post(`${API_URL}/auth/brokerdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.FLATTRADE_BROKER_SUCCESS,
          });
          generatePopup("success", res?.data?.message);
          // window.location.replace(window.location.origin);
          resolve(res);
          navigate("/broker");

          dispatch(BrokerStatus());
        } else {
          generatePopup("error", res?.data?.message);
          dispatch({
            type: actionTypes.FLATTRADE_BROKER_FAIL,
            payload:
              res?.data?.message || "Failed to add flattrade broker details!",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.FLATTRADE_BROKER_FAIL,
            payload:
              typeof error == "string"
                ? error
                : "Failed to add flattrade broker details!",
          });
          generatePopup("error", error?.response?.data?.message);
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const getSwastikaSession = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    axios
      .get(`${API_URL}/auth/swastikasession/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_SWASTIKA_SESSION_SUCCESS,
            payload: res.data,
          });
          generatePopup("success", res?.data?.message);

          resolve(res);
        } else {
          generatePopup("error", "Swastika session get fail.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          generatePopup("error", error?.response?.data?.message);
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const BrokerStatus = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.BROKER_STATUS_INIT,
    });
    axios
      .get(`${API_URL}/auth/brokerdetails/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.BROKER_STATUS_SUCCESS,
            payload: res.data,
          });
          // navigate("/");
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.BROKER_STATUS_FAIL,
            payload: res?.data?.message,
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.BROKER_STATUS_FAIL,
            payload: error?.response?.data?.message,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const openDemate = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.OPEN_DEMATE_INIT,
    });
    axios
      .post(`${API_URL}/subbroker/brokerdemat/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.OPEN_DEMATE_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.OPEN_DEMATE_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.OPEN_DEMATE_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const editDemate = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.EDIT_DEMATE_INIT,
    });
    axios
      .post(`${API_URL}/subbroker/editsubbroker/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.EDIT_DEMATE_SUCCESS,
            payload: res.data,
          });
          resolve(res);
          generatePopup("success", "Data Updated SuccessFully");
        } else {
          dispatch({
            type: actionTypes.EDIT_DEMATE_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.EDIT_DEMATE_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const contactUs = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.CONTACT_US_INIT,
    });
    axios
      .post(`${API_URL}/subbroker/contactusview/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.CONTACT_US_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.CONTACT_US_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.CONTACT_US_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const subBrokerLogin = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.SUB_BROKER_LOGIN_INIT,
    });
    axios
      .post(`${API_URL}/subbroker/subbrokerlogin/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.SUB_BROKER_LOGIN_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.SUB_BROKER_LOGIN_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.SUB_BROKER_LOGIN_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const updateContact = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.UPDATE_CONTACT_US_INIT,
    });
    axios
      .put(`${API_URL}/subbroker/contactusview/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.UPDATE_CONTACT_US_SUCCESS,
            payload: res.data,
          });
          resolve(res);
          generatePopup("success", "Contact Updated Successfully");
        } else {
          dispatch({
            type: actionTypes.UPDATE_CONTACT_US_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.UPDATE_CONTACT_US_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const uplaodLogo = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");

    axios
      .post(`${API_URL}/auth/uploadlogo/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch({
            type: actionTypes.UPLOAD_LOGO_SUCCESS,
            payload: res.data,
          });
          resolve(res);
          generatePopup("success", "Logo uploaded Successfully");
        } else {
          dispatch({
            type: actionTypes.UPLOAD_LOGO_FAIL,
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.UPLOAD_LOGO_FAIL,
          });
          generatePopup("error", "Failed to upload logo");
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const uploadMobileLogo = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");

    axios
      .post(`${API_URL}/auth/uploadlogomobile/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch({
            type: actionTypes.UPLOAD_LOGO_SUCCESS,
            payload: res.data,
          });
          resolve(res);
          generatePopup("success", "Logo uploaded Successfully");
        } else {
          dispatch({
            type: actionTypes.UPLOAD_LOGO_FAIL,
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.UPLOAD_LOGO_FAIL,
          });
          generatePopup("error", "Failed to upload logo");
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const getLogo = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");

    axios
      .post(`${API_URL}/auth/getlogo/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_LOGO_SUCCESS,
            payload: res.data,
          });
          resolve(res);
          // generatePopup("success", "Logo uploaded Successfully");
        } else {
          dispatch({
            type: actionTypes.GET_LOGO_FAIL,
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_LOGO_FAIL,
          });
          generatePopup("error", "Failed to get logo");
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const getMobileLogo = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");

    axios
      .post(`${API_URL}/auth/getlogo1/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_MOBILE_LOGO_SUCCESS,
            payload: res.data,
          });
          resolve(res);
          // generatePopup("success", "Logo uploaded Successfully");
        } else {
          dispatch({
            type: actionTypes.GET_MOBILE_LOGO_FAIL,
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_MOBILE_LOGO_FAIL,
          });
          generatePopup("error", "Failed to get logo");
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const createBroker = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.CREATE_BROKER_INIT,
    });
    axios
      .put(`${API_URL}/subbroker/createbroker/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.CREATE_BROKER_SUCCESS,
            payload: res.data,
          });
          resolve(res);
          generatePopup("success", "Broker Created Successfully");
        } else {
          dispatch({
            type: actionTypes.CREATE_BROKER_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.CREATE_BROKER_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const editSocialLink = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.EDIT_SOCIAL_LINKS_INIT,
    });
    axios
      .put(`${API_URL}/subbroker/sociallink/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.EDIT_SOCIAL_LINKS_SUCCESS,
            payload: res.data,
          });
          resolve(res);
          generatePopup("success", "link edited successfully");
        } else {
          dispatch({
            type: actionTypes.EDIT_SOCIAL_LINKS_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.EDIT_SOCIAL_LINKS_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const getSocialLink = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.GET_SOCIAL_LINKS_INIT,
    });
    axios
      .post(`${API_URL}/subbroker/sociallink/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_SOCIAL_LINKS_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_SOCIAL_LINKS_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_SOCIAL_LINKS_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const getSubBrokerDetails = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.GET_SUB_BROKER_INIT,
    });
    axios
      .get(`${API_URL}/subbroker/getsubbrokerdetails/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_SUB_BROKER_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_SUB_BROKER_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_SUB_BROKER_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const searchSubBrokerDetails = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.SEARCH_SUB_BROKER_INIT,
    });
    axios
      .post(`${API_URL}/subbroker/getsubbrokerdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.SEARCH_SUB_BROKER_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.SEARCH_SUB_BROKER_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.SEARCH_SUB_BROKER_FAIL,
          });
        } else if (error?.response?.status === 401) {
          // generatePopup("error", "Token is invalid or expired.");
          // localStorage.clear();
          // window.location.replace("/");
        }
      });
  });
};

export const searchInvoice = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.SEARCH_INVOICE_INIT,
    });
    axios
      .post(`${API_URL}/subbroker/vendorinvoicemodel/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.SEARCH_INVOICE_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.SEARCH_INVOICE_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.SEARCH_INVOICE_FAIL,
          });
        } else if (error?.response?.status === 401) {
          // generatePopup("error", "Token is invalid or expired.");
          // localStorage.clear();
          // window.location.replace("/");
        }
      });
  });
};

export const getInvoiceModel = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.GET_INVOICE_DATA_INIT,
    });
    axios
      .get(`${API_URL}/subbroker/vendorinvoicemodel/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_INVOICE_DATA_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_INVOICE_DATA_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_INVOICE_DATA_FAIL,
          });
        } else if (error?.response?.status === 401) {
          // generatePopup("error", "Token is invalid or expired.");
          // localStorage.clear();
          // window.location.replace("/");
        }
      });
  });
};

export const getFundsData = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("authToken");

    axios
      .get(`${API_URL}/auth/fundview/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_FUND_BROKER_SUCCESS,
            payload: res.data,
          });

          resolve(res);
        } else {
          generatePopup(
            "error",
            res?.data?.message || "Failed to get fund data!"
          );
          dispatch({
            type: actionTypes.GET_FUND_BROKER_FAIL,
            payload: res?.data?.message || "Failed to get fund data!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_FUND_BROKER_FAIL,
          payload: typeof error == "string" ? error : "Failed to get fund data",
        });

        if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const getVendorReferal = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("authToken");

    axios
      .post(`${API_URL}/subbroker/editmrp/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.EDIT_MRP_SUCCESS,
            payload: res.data,
          });

          resolve(res);
        } else {
          generatePopup(
            "error",
            res?.data?.message || "Failed to Edit Mrp data!"
          );
          dispatch({
            type: actionTypes.EDIT_MRP_FAIL,
            payload: res?.data?.message || "Failed to Edit Mrp data!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.EDIT_MRP_FAIL,
          payload: typeof error == "string" ? error : "Failed to Edit Mrp data",
        });
        generatePopup(
          "error",
          error?.response?.data?.message || "Failed to Edit Mrp data."
        );
        if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const editVendorReferal = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("authToken");

    axios
      .put(`${API_URL}/subbroker/editmrp/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.EDIT_VENDOR_REFERRAL_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          generatePopup(
            "error",
            res?.data?.message || "Failed to Edit Mrp data!"
          );
          dispatch({
            type: actionTypes.EDIT_VENDOR_REFERRAL_FAIL,
            payload: res?.data?.message || "Failed to Edit Mrp data!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.EDIT_VENDOR_REFERRAL_FAIL,
          payload: typeof error == "string" ? error : "Failed to Edit Mrp data",
        });
        generatePopup(
          "error",
          error?.response?.data?.message || "Failed to Edit Mrp data."
        );
        if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const businessReport = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("authToken");

    axios
      .post(`${API_URL}/subbroker/businessreport/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.BUSINESS_REPORT_SUCCESS,
            payload: res.data,
          });

          resolve(res);
        } else {
          generatePopup(
            "error",
            res?.data?.message || "Failed to Get Business report data!"
          );
          dispatch({
            type: actionTypes.BUSINESS_REPORT_FAIL,
            payload:
              res?.data?.message || "Failed to Get Business report data!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.BUSINESS_REPORT_FAIL,
          payload:
            typeof error == "string"
              ? error
              : "Failed to Get Business report data",
        });

        if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const getFaq = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("authToken");

    axios
      .get(`${API_URL}/subbroker/submitfnq/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_FAQ_SUCCESS,
            payload: res.data,
          });

          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_FAQ_FAIL,
            payload:
              res?.data?.message || "Failed to Get Business report data!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_FAQ_FAIL,
          payload:
            typeof error == "string"
              ? error
              : "Failed to Get Business report data",
        });

        if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const editsubbrokerdetails = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.EDIT_SUBBROKERDETAILS_INIT,
    });
    axios
      .post(`${API_URL}/subbroker/editsubbrokerdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.EDIT_SUBBROKERDETAILS_SUCCESS,
            payload: res.data,
          });
          resolve(res);
          generatePopup("success", "subbrokerdetails edited successfully");
        } else {
          dispatch({
            type: actionTypes.EDIT_SUBBROKERDETAILS_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.EDIT_SUBBROKERDETAILS_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const changelimitcopytrade = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.CHANGELIMITCOPYTRADE_INIT,
    });
    axios
      .post(`${API_URL}/position/changelimitcopytrade/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.CHANGELIMITCOPYTRADE_SUCCESS,
            payload: res.data,
          });
          resolve(res);
          generatePopup("success", "Limit Order change Successfully.");
        } else {
          dispatch({
            type: actionTypes.CHANGELIMITCOPYTRADE_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.CHANGELIMITCOPYTRADE_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const changepaperlimitorder = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.CHANGELIMITCOPYTRADE_INIT,
    });
    axios
      .post(`${API_URL}/position/changepaperlimitorder/ `, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.CHANGELIMITCOPYTRADE_SUCCESS,
            payload: res.data,
          });
          resolve(res);
          generatePopup("success", "Limit Order change Successfully.");
        } else {
          dispatch({
            type: actionTypes.CHANGELIMITCOPYTRADE_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.CHANGELIMITCOPYTRADE_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const cancelPaperLimitOrder = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.CANCEL_LIMIT_COPYTRADE_INIT,
    });
    axios
      .post(`${API_URL}/position/cancelpaperlimitorder/ `, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.CANCEL_LIMIT_COPYTRADE_SUCCESS,
            payload: res.data,
          });
          resolve(res);
          generatePopup("success", "Limit Order Cancelled Successfully.");
        } else {
          dispatch({
            type: actionTypes.CANCEL_LIMIT_COPYTRADE_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.CANCEL_LIMIT_COPYTRADE_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const getPowerBroker = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.GET_POWER_BROKER_INIT,
    });
    axios
      .get(`${API_URL}/subbroker/powerbrokerlogin1/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_POWER_BROKER_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_POWER_BROKER_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_POWER_BROKER_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const editPowerBroker = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.EDIT_POWER_BROKER_INIT,
    });
    axios
      .post(`${API_URL}/subbroker/editpowerbroker/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.EDIT_POWER_BROKER_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.EDIT_POWER_BROKER_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.EDIT_POWER_BROKER_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const getUserRefreCode = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.GET_USER_REFRECODE_INIT,
    });
    axios
      .post(`${API_URL}/subbroker/getuserrefercode/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_USER_REFRECODE_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_USER_REFRECODE_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_USER_REFRECODE_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const kotakotp = (payload, navigate) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.KOTAK_BROKER_INIT,
    });
    axios
      .post(`${API_URL}/auth/kotakotp/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.KOTAK_BROKER_SUCCESS,
          });
          generatePopup("success", res?.data?.message);
          // window.location.replace(window.location.origin);

          resolve(res);
          navigate("/broker");

          dispatch(BrokerStatus());
        } else {
          generatePopup("error", res?.data?.message);
          dispatch({
            type: actionTypes.KOTAK_BROKER_FAIL,
            payload:
              res?.data?.message || "Failed to add kotak broker details!",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.KOTAK_BROKER_FAIL,
            payload:
              typeof error == "string"
                ? error
                : "Failed to add kotak broker details!",
          });
          generatePopup("error", error?.response?.data?.message);
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};
