import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  Tooltip,
  TextField,
} from "@mui/material";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import Table from "../../../Table/Table";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Dropdown from "../../../Dropdown/Dropdown";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  AdminPaperPnlReport,
  AdminPnlReport,
  downloadLiveReport,
  downloadPaperReport,
  getSegment,
} from "../../../../redux/actions/positionAction";
import { getStratergy } from "../../../../redux/actions/stratergyAction";

// const rows = [
//   { Customerid: "BANKNIFTY2310542900PE", pl: 100, buyqut: 120, totalsell: 200 },
// ];

export default function Userplrepoert() {
  // drop down
  // const drpValue = ["Select", "FG5", "HGHJG53", "NJKNK2"];
  const drpValue2 = ["Live", "Paper"];

  const download = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      Download
    </Typography>
  );
  const fetchreport = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      Fetch Report
    </Typography>
  );

  const [customerid, setCustomerid] = useState("");
  const [tradetype, setTradetype] = useState("Live");
  // const [pl, setPl] = useState();
  const [strategy, setStrategy] = useState("select");
  // const [strategy, setStrategy] = useState();
  // const [type, setType] = useState();
  const [todate, setTodate] = useState(dayjs());
  const [fromdate, setFromDate] = useState(dayjs());
  // const [search, setSearch] = useState();

  const handleCustomerid = (event) => {
    setCustomerid(event.target.value);
  };

  const handleTradetype = (event) => {
    setTradetype(event.target.value);
  };

  const handleStrategy = (event) => {
    setStrategy(event.target.value);
  };

  const handleTodate = (date) => {
    setTodate(dayjs(date));
  };

  const handleFromDate = (date) => {
    setFromDate(dayjs(date));
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSegment());
    dispatch(getStratergy());
  }, []);

  const strategies = useSelector((state) => state?.Stratergy?.stratergy);

  const strategyList = strategies?.map((e) => e?.name);

  const handleSearch = () => {
    const obj = {
      segment: "select",
      // segment?.toUpperCase() === "SELECT" ? "select" : segment?.toUpperCase(),
      startdate: dayjs(new Date(fromdate)).format("YYYY-MM-DD"),
      enddate: dayjs(new Date(todate)).format("YYYY-MM-DD"),
      clientid: customerid || "select",
    };
    if (tradetype === "Live") {
      dispatch(AdminPnlReport(obj));
    } else {
      dispatch(AdminPaperPnlReport(obj));
    }
  };

  function downloadCSV(data) {
    // Step 2: Convert the JSON data to CSV format
    const csvContent =
      "data:text/csv;charset=utf-8," +
      Object.keys(data[0])
        .map((key) => key)
        .join(",") +
      "\n" +
      data
        .map((row) =>
          Object.values(row)
            .map((value) => `"${value}"`)
            .join(",")
        )
        .join("\n");

    // Step 3: Create a data URI and trigger the download
    const encodedURI = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedURI);
    link.setAttribute("download", "Payment_Report.csv");
    document.body.appendChild(link);
    link.click();
  }

  const fetchData = () => {
    const obj = {
      segment: "select",
      // segment?.toUpperCase() === "SELECT" ? "select" : segment?.toUpperCase(),
      startdate: dayjs(fromdate).format("YYYY-MM-DD"),
      enddate: dayjs(todate).format("YYYY-MM-DD"),
      clientid: customerid || "select",
    };
    if (tradetype?.toLowerCase() === "live") {
      dispatch(AdminPnlReport(obj));
    } else {
      dispatch(AdminPaperPnlReport(obj));
    }
  };

  useEffect(() => {
    fetchData();
  }, [tradetype, fromdate, todate]);

  const handleRefresh = () => {
    fetchData();
  };

  const data = useSelector((state) => state?.Position?.pnlReport);

  const filteredData =
    strategy === "select"
      ? data
      : data?.filter(
          (e) => e?.stratergy?.toLowerCase() === strategy?.toLowerCase()
        );

  const sortedAsc = filteredData.sort(function (a, b) {
    return new Date(b.date) - new Date(a.date);
  });

  const col = [
    "Symbol",
    "Owner",
    "Trend",
    "Stratergy",
    "Exchange",
    "Profit",
    "Buy Quantity",
    "Buy Price",
    "Sell Quantity",
    "Sell Price",
    "Date",
  ];

  const handleDownload = () => {
    const obj = {
      clientid: customerid || "select",
      segment: "select",
      fromdate: dayjs(new Date(fromdate)).format("YYYY-MM-DD"),
      todate: dayjs(new Date(todate)).format("YYYY-MM-DD"),
      stratergy: strategy || "select",
    };
    if (tradetype === "Live") {
      dispatch(downloadLiveReport(obj));
    } else {
      dispatch(downloadPaperReport(obj));
    }
  };

  const rows = sortedAsc?.map((e) => {
    return {
      symbol: e?.symbol,
      owner: e?.owner,
      trend:
        e?.side?.toLowerCase() === "buy" ? (
          <Typography component={"span"} className="up">
            {e?.side}
          </Typography>
        ) : (
          <Typography component={"span"} className="down">
            {e?.side}
          </Typography>
        ),
      stratergy: e?.stratergy,
      exchange: e?.exchange,

      profit:
        Number(e?.profit) > 0 ? (
          <Typography component={"span"} className="up">
            {Number(e?.profit).toFixed(2)}
          </Typography>
        ) : (
          <Typography component={"span"} className="down">
            {Number(e?.profit).toFixed(2)}
          </Typography>
        ),

      bqty: Math.ceil(e?.buyquantity),
      buyprice: Number(e?.buyprice).toFixed(2),
      sqty: Math.ceil(e?.sellquantity),
      sellprice: Number(e?.sellprice).toFixed(2),
      date: e?.date,
    };
  });

  const downloadData = sortedAsc?.map((e) => {
    return {
      symbol: e?.symbol,
      trend: e?.side,
      exchange: e?.exchange,
      profit: e?.profit,
      bqty: e?.buyquantity,
      buyprice: e?.buyprice,
      sqty: e?.sellquantity,
      sellprice: e?.sellprice,
      date: e?.date,
    };
  });
  return (
    <>
      <Box
        sx={{
          display: { xs: "flex" },
          alignItems: { xs: "center" },
          justifyContent: { xs: "end" },
          marginBottom: { xs: "1.4rem" },
        }}
      ></Box>
      <Box className="tabelBox">
        <Grid container spacing={2} alignItems={"top"}>
          <Grid item xs={12}>
            <Box className="selectiondiv-box m-rb">
              <Box className="selectionDiv searchFlex">
                <Box className="inputFields" sx={{ margin: "0" }}>
                  <TextField
                    placeholder="Customer Id"
                    onChange={(event) => handleCustomerid(event)}
                    value={customerid}
                  />
                  {/* <Box className="searchIcon">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.85722 14.7144C11.6444 14.7144 14.7144 11.6444 14.7144 7.85722C14.7144 4.07008 11.6444 1 7.85722 1C4.07008 1 1 4.07008 1 7.85722C1 11.6444 4.07008 14.7144 7.85722 14.7144Z"
                        stroke="#6A6D78"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13 13.0001L17 17.0002"
                        stroke="#6A6D78"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Box> */}
                </Box>
                <Tooltip title={"search"} arrow>
                  <Button
                    className="download-btn solidButton"
                    sx={{ marginLeft: "1rem" }}
                    onClick={handleSearch}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                        fill="#FFFFFF"
                      />
                    </svg>
                  </Button>
                </Tooltip>
              </Box>
              <Box className="selectionDiv">
                <Typography component={"label"} className="label">
                  From Date:{" "}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={fromdate}
                    className="datePicker"
                    onChange={handleFromDate}
                  />
                </LocalizationProvider>
              </Box>
              <Box className="selectionDiv">
                <Typography component={"label"} className="label">
                  To Date:{" "}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={todate}
                    className="datePicker"
                    onChange={handleTodate}
                  />
                </LocalizationProvider>
              </Box>

              <Dropdown
                val={drpValue2}
                handleSelect={handleTradetype}
                value={tradetype}
                title={"Select Trade Type"}
              />
              {/* <Dropdown
                val={drpValue3}
                handleSelect={handlePl}
                value={pl}
                title={"All P&L"}
              /> */}
              <Dropdown
                val={["select"].concat(strategyList)}
                handleSelect={handleStrategy}
                value={strategy}
                title={"All Strategy"}
              />
              {/* <Dropdown
                val={drpValue5}
                handleSelect={handleStrategy}
                value={strategy}
                title={"All Strategy"}
              /> */}
              {/* <Dropdown
                val={drpValue6}
                handleSelect={handleType}
                value={type}
                title={"Select"}
              /> */}
              <Box className="selectionDiv searchFlex">
                {/* <Tablesearch handlesearch={(event) => handleSearch(event)} /> */}
                <Tooltip title={fetchreport} arrow>
                  <Button
                    onClick={handleRefresh}
                    className="fetchReport-btn solidButton"
                    sx={{ marginLeft: 1 }}
                  >
                    <CloudSyncIcon />
                  </Button>
                </Tooltip>

                {rows?.length > 0 && (
                  <Tooltip title={download} arrow>
                    <Button
                      className="download-btn solidButton"
                      sx={{ marginLeft: 1 }}
                      onClick={() => handleDownload()}
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17 11.6669V15.2224C17 15.6939 16.8127 16.1461 16.4793 16.4795C16.1459 16.8129 15.6937 17.0002 15.2222 17.0002H2.77778C2.30628 17.0002 1.8541 16.8129 1.5207 16.4795C1.1873 16.1461 1 15.6939 1 15.2224V11.6669"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4.55542 7.22229L8.99987 11.6667L13.4443 7.22229"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9 11.6667V1"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Button>
                  </Tooltip>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Table col={col} rows={rows} type="plreport" />
      </Box>
    </>
  );
}
