import React from 'react';
import { List, ListItem, Typography, Box, Button } from '@mui/material';

export default function FAQ() {

  return (
    <>
      <List className='profile-content' sx={{ '& > li': { maxWidth: 800, margin: '0 auto', textAlign: 'center ' } }}>
        <ListItem>
          <Typography component='h4' className='underline'>NON-DISCLOSURE AGREEMENT</Typography>
          <Typography component='p'>THIS CONFIDENTIALITY AND NON-DISCLOSURE AGREEMENT (the “Agreement”) made on this day of 25-03-2023 (the “Effective Date”) by and</Typography>
          <Typography component='span'>between</Typography>
        </ListItem>

        <ListItem>
          <Typography component='p'>
            <Typography component={'span'}>DeMatade Algo Technology Pvt Ltd</Typography> ,
            a company incorporated under the Companies Act, 1956 and having its registered office at Office No.604, Shagun Insignia, Sector-19, Plot no.195, Ulwe, Navi Mumbai, India-410206.(hereinafter referred to as the “Disclosing Party” which term shall unless repugnant to the context or meaning thereof be deemed to include its successors and assigns) of the One Part;</Typography>
          <Typography component='span' >And</Typography>
        </ListItem>

        <ListItem>
          <Typography component='p'> SUDHA YADAV, a company incorporated under the Companies Act, 1956/2013 and having its registered office (hereinafter referred to as the “Receiving Party” which term shall unless repugnant to the context or meaning thereof be deemed to include its successors and permitted assigns)the Other Part.</Typography>
          <Typography component='p'>DeMatade Algo Technology pvt ltd and SUDHA YADAV are hereinafter collectively referred to as “the Parties” and individually as “the Party”.</Typography>
          <Typography component='span'>WHEREAS:</Typography>
        </ListItem>

        <ListItem>
          <Typography component='p'>The Parties intend to engage in discussions and negotiations concerning the establishment of a business relationship between themselves. In the course of such discussions and negotiations, it is anticipated that each Party may disclose or deliver to the other certain or some of its trade secrets or confidential or proprietary information, for the purpose of enabling the other party to evaluate the feasibility of such business relationship (hereinafter referred to as “ the Purpose ”).</Typography>
        </ListItem>

        <ListItem>
          <Typography component='p'>AND WHEREAS , each Party wishes to review such Confidential Information of the other for the sole purpose of determining their mutual interest in engaging in the Proposed Transaction;</Typography>
        </ListItem>

        <ListItem>
          <Typography component={'span'} >NOW, THEREFORE, THIS AGREEMENT WITNESSETH AND IT IS HEREBY AGREED BY AND BETWEEN THE PARTIES HERETO AS FOLLOWS :</Typography>
        </ListItem>

        <ListItem>
          <Typography component={'p'}>*DeMatade Algo Technology Pvt Ltd. is a only Technology Provider. It has nothing to do with the Profit or Loss of any users and there is No from DeMatade Algo Technology Pvt Ltd if any dispute happened between the service provider and customers.</Typography>
        </ListItem>


        <ListItem>
          <Typography component={'p'} className='counter'>
            <Typography component={'span'}>“ Confidential and or proprietary Information ” </Typography>
            shall mean and include any information disclosed by one Party (Disclosing Party) to the other (Receiving Party) either directly or indirectly, in writing, orally, by inspection of tangible objects (including, without limitation, documents, prototypes, samples, media, documentation, discs and code). Confidential information shall include, without limitation, any materials, trade secrets, network information, configurations, trademarks, brand name, know-how, business and marketing plans, financial and operational information, and all other non-public information, material or data relating to the current and/ or future business and operations of the Disclosing Party and analysis, compilations, studies, summaries, extracts or other documentation prepared by the Disclosing Party. Confidential Information may also include information disclosed to the Receiving Party by third parties on behalf of the Disclosing Party.
          </Typography>

          <Typography component={'p'} className='counter'> The Receiving Party shall refrain from disclosing, reproducing, summarizing and/or distributing Confidential Information and confidential materials of the Disclosing Party except in connection with the Proposed Transaction.
          </Typography>

          <Typography component={'p'} className='counter'> The Parties shall protect the confidentiality of each other’s Confidential Information in the same manner as they protect the confidentiality of their own proprietary and confidential information of similar nature. Each Party, while acknowledging the confidential and proprietary nature of the Confidential Information agrees to take all reasonable measures at its own expense to restrain its representatives from prohibited or unauthorized disclosure or use of the Confidential Information.

            <Typography sx={{ marginTop: 2 }}> Confidential Information shall at all times remain the property of the Disclosing Party and may not be copied or reproduced by the Receiving Party without the Disclosing Party’s prior written consent.</Typography>
          </Typography>


          <Typography component={'p'} className='counter'>Within seven (7) days of a written request by the Disclosing Party, the Receiving Party shall return/destroy (as may be requested in writing by the Disclosing Party or upon expiry and or earlier termination) all originals, copies, reproductions and summaries of Confidential Information provided to the Receiving Party as Confidential Information. The Receiving Party shall certify to the Disclosing Party in writing that it has satisfied its obligations under this paragraph.</Typography>

          <Typography component={'p'} className='counter'>The Receiving Party may disclose the Confidential Information only to the Receiving Party's employees and consultants on a need-to-know basis. The
            Receiving Party shall have executed or shall execute appropriate written agreements with third parties with prior permission from the Disclosing Party, in a form and manner sufficient to enable the Receiving Party to enforce all the provisions of this Agreement.
          </Typography>

          <Typography component={'p'} className='counter'>Confidential Information, however, shall not include any information which the Receiving Party can show:
            <Typography component={'p'} className='list'> is in or comes into the public domain otherwise than through a breach of this Agreement or the fault of the Receiving Party; or</Typography>
            <Typography component={'p'} className='list'>was already in its possession free of any such restriction prior to receipt from the Disclosing Party; or</Typography>
            <Typography component={'p'} className='list'>was independently developed by the Receiving Party without making use of the Confidential Information; or</Typography>
            <Typography component={'p'} className='list'>has been approved for release or use (in either case without restriction) by written authorization of the Disclosing Party.</Typography>
          </Typography>

          <Typography component={'p'} className='counter'>In the event either Party receives a summons or other validly issued administrative or judicial process requiring the disclosure of Confidential Information of the other Party, the Receiving Party shall promptly notify the Disclosing Party. The Receiving Party may disclose Confidential Information to the extent such disclosure is required by law, rule,  or legal process; provided however, that, to the extent practicable, the Receiving Party shall give prompt written notice of any such request for such information to the Disclosing Party, and agrees to co-operate with the Disclosing Party, at the Disclosing Party’s expense, to the extent permissible and practicable, to challenge the request or limit the scope there of, as the Disclosing Party may reasonably deem appropriate.
          </Typography>

          <Typography component={'p'} className='counter'>Neither Party shall use the other’s name, trademarks, proprietary words or symbols or disclose under this Agreement in any publication, press release,
            material, or otherwise without the prior written approval of the other. 10. Each Party agrees that the conditions in this Agreement and the Confidential Information disclosed pursuant to this Agreement are of a special, unique, and extraordinary character and that an impending or existing violation of any provision of this Agreement would cause the other Party irreparable injury for which it would have no adequate remedy at law and further agrees that the other Party shall be entitled to obtain immediately injunctive relief prohibiting such violation, in addition to any other rights and remedies available to it at law or in equity.
          </Typography>

          <Typography component={'p'} className='counter'>The Receiving Party shall indemnify the Disclosing Party for all costs, expenses or damages that Disclosing Party incurs as a result of any violation of any provisions of this Agreement. This obligation shall include court, litigation expenses, and actual, reasonable attorney’s fees. The Parties acknowledge that as damages may not be a sufficient remedy for any breach under this Agreement, the non-breaching party is entitled to seek specific performance or injunctive relief (as appropriate) as a remedy for any breach or threatened breach, in addition to any other remedies at law or in equity.
          </Typography>

          <Typography component={'p'} className='counter'>Neither Party shall be liable for any special, consequential, incidental or exemplary damages or loss (or any lost profits, savings or business opportunity) regardless of whether a Party was advised of the possibility of the damage or loss asserted.
          </Typography>

          <Typography component={'p'} className='counter'>Both the Parties agree that by virtue of the Parties entering into this Agreement neither Party is obligated to disclose all or any of the Confidential Information to the other as stated in this Agreement. The Parties reserve the right to disclose only such information at its discretion and which it thinks, is necessary to disclose in relation to
            the Proposed Transaction.
          </Typography>

          <Typography component={'p'} className='counter'>Both the Parties agree that this Agreement will be effective from the date of execution of this Agreement by both Parties and shall continue to be effective till the Proposed Transaction is terminated by either Party by giving a thirty (30)days notice, in case either Party foresees that the Proposed Transaction would not be achieved.


            <Typography sx={{ marginTop: 2 }}>Notwithstanding anything contained herein, the provisions of this Agreement shall survive and continue after expiration or termination of this Agreement for a further
              period of five year(s) from the date of expiration.</Typography>
            <Typography sx={{ marginTop: 2 }}>It being further clarified that notwithstanding anything contained herein, in case a binding agreement is executed between the Parties in furtherance of the Proposed Transaction, the terms and conditions of this Agreement shall become effective and form a part of that binding agreement and be co-terminus with such binding agreement and shall be in effect till the term of such binding agreement and shall after its expiry and or early termination shall continue to be in force in the following manner:</Typography>

            <Typography component={'p'} className='list'>… ………….. years after the termination of the binding agreement</Typography>
            <Typography component={'p'} className='list'>……… ……years after the expiry of the binding agreement</Typography>
            <Typography component='span' >(Whichever is earlier)</Typography>
          </Typography>

          <Typography component={'p'} className='counter'>Each Party warrants that it has the authority to enter into this Agreement.</Typography>
          <Typography component={'p'} className='counter'>If any provision of this agreement is held to be invalid or unenforceable to any extent,
            the remainder of this Agreement shall not be affected and each provision hereof shall be valid and enforceable to the fullest extent permitted by law. Any invalid or unenforceable provision of this Agreement shall be replaced with a provision that is valid and enforceable and most nearly reflects the original intent of the unenforceable provision.
          </Typography>

          <Typography component={'p'} className='counter'>This Agreement may be executed in two counterparts, each of which will be deemed to be an original, and all of which, when taken together, shall be deemed to constitute one and the same agreement.
          </Typography>

          <Typography component={'p'} className='counter'>The relationship between both the Parties to this Agreement shall be on a principal-to-principal basis and nothing in this agreement shall be deemed to have
            created a relationship of an agent or partner between the Parties and none of the employees of COMPANY shall be considered as employees of PARTY 1. </Typography>

          <Typography component={'p'} className='counter'>This Agreement shall be governed by the laws of India. Both parties irrevocably submit to the exclusive jurisdiction of the Courts in Mumbai, for any action or proceeding regarding this Agreement. Any dispute or claim arising out of or in connection herewith, or the breach, termination or invalidity thereof, shall be settled by arbitration in accordance with the provisions of Procedure of the Indian Arbitration & Conciliation Act, 1996, including any amendments thereof. The arbitration tribunal shall be composed of a sole arbitrator, and such arbitrator shall be appointed mutually by the Parties. The place of arbitration shall be Mumbai, India and the arbitration proceedings shall take place in the English language.</Typography>

          <Typography component={'p'} className='counter'>Additional oral agreements do not exist. All modifications and amendments to this Agreement must be made in writing.</Typography>
          <Typography component={'p'} className='counter'>The Agreement and/or any rights arising from it cannot be assigned or otherwise   transferred either wholly or in part, without the written consent of the other Party.</Typography>



          <Typography component='span' >For DeMatade Algo Technology pvt ltd</Typography>
          <Typography component='span' >Mr. ‘Pawan Matade’ – Founder</Typography>
          <Typography component='span' >Vendor Details</Typography>
        </ListItem>
        <ListItem className='flexBox'>
          <Typography>First name:</Typography>
          <Typography>Sudha</Typography>
        </ListItem>

        <ListItem className='flexBox'>
          <Typography>Last name:</Typography>
          <Typography>Yadav</Typography>
        </ListItem>

        <ListItem className='flexBox'>
          <Typography>Email:</Typography>
          <Typography>yadavsudha2824@gmail.com</Typography>
        </ListItem>

        <ListItem className='flexBox'>
          <Typography>Bob:</Typography>
          <Typography>1947-08-15</Typography>
        </ListItem>

        <ListItem className='flexBox'>
          <Typography>Bank Name:</Typography>
          <Typography>ICICI BANK</Typography>
        </ListItem>

        <ListItem className='flexBox'>
          <Typography>Ifsc Code:</Typography>
          <Typography>ICIC0000177</Typography>
        </ListItem>

        <ListItem className='flexBox'>
          <Typography>PAN:</Typography>
          <Typography>ATNPM8093R</Typography>
        </ListItem>

        <ListItem className='flexBox'>
          <Typography>GSTIN:</Typography>
          <Typography>NAVI MUMBAI</Typography>
        </ListItem>

        <ListItem className='flexBox'>
          <Typography>City:</Typography>
          <Typography>MUMBAI</Typography>
        </ListItem>

        <ListItem >
          <Typography component={'span'}>It is computer generated NDA agreement and therefore does not require any signature”, at least in important and non-routine commercial communications and follow legal methods.</Typography>
        </ListItem>

        <ListItem >
          <Typography component={'span'} fontStyle={'italic'} sx={{ opacity: 0.6 }}>DeMatade Algo Technology pvt ltd- Non Disclosure Agreement</Typography>
        </ListItem>

        <ListItem >
          <Box className='formItems' sx={{ marginTop: 2 }}>
            <Button className='formSolid-btn' sx={{ direction: 'block' }}>Download Now</Button>
          </Box>
        </ListItem>

      </List >
    </>
  )
}
