export const editContactValidation = (fields) => {
  let isValid = {};

  if (!fields.phone1) {
    isValid.phone1 = "Enter mobile number";
  } else if (fields.phone1.length !== 10) {
    isValid.phone1 = "Mobile number must be 10 characters";
  }
  if (fields?.phone2) {
    if (fields?.phone2?.length !== 10) {
      isValid.phone2 = "Mobile number must be 10 characters";
    }
  }

  if (fields?.phone3) {
    if (fields?.phone3?.length !== 10) {
      isValid.phone3 = "Mobile number must be 10 characters";
    }
  }

  if (!fields.email) {
    isValid.email = "Please enter email";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(fields.email)) {
    isValid.email = "Invalid email address";
  } else if (!(fields.email.length >= 15) || !(fields.email.length <= 40)) {
    isValid.email = "Email length should be 15 to 40";
  } else if (!fields.email.endsWith(".com") && !fields.email.endsWith(".in")) {
    isValid.email = "Enter valid email";
  }

  if (!fields?.address) {
    isValid.address = "Please enter address";
  }

  return isValid;
};
