import React, { useState } from "react";
import {
  Box,
  Tooltip,
  Button,
  TextField,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Tablesearch from "../../../Tablesearch/Tablesearch";
import "../../Dashboard/Tradedetails/Tradedetails.scss";
import "././Vendorrights.scss";
import Dropdown from "../../../Dropdown/Dropdown";

let permissionsList = [
  "Dashboard",
  "Live Position",
  "Strategic",
  "Business Report",
  "Subscription",
  "CopyTradeExpiry",
  "Video Addition",
  "Broker ID",
  "B Tsym Expiry",
  "DB_Live Trade Position",
  "Sms Data",
  "Trade View",
  "Check Balance",
  "User View",
  "Paper Position",
  "User P&L Report",
  "Vendor Report",
  "Push Notification",
  "UserAdmin",
  "CopyStrategyExpiry",
  "Vendor View",
  "Symbol Token",
  "OPT SELL Expiry",
  "DB_Paper Trade Position",
  "Payment Report",
  "Strategy Performance",
  "Trade Details",
  "Order History",
  "AdminPL",
  "Vendor Referal",
  "SMS Notification",
  "Cron View",
  "Image Addition",
  "Service Charge",
  "Holiday List",
  "SymbolMaster",
  "Trade Logs",
  "Auto Login",
  "Capital Amount",
];

export default function Vendorrights() {
  const [save, setsave] = React.useState("none");
  const drpValue = ["Customer ID", "Mobile No."];
  const search = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      search{" "}
    </Typography>
  );
  const edit = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      Edit{" "}
    </Typography>
  );

  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [customervalue, setCustomervalue] = useState();
  const [searchvalue, setSearchvalue] = useState();

  //select Customervalue
  const handleCustomervalue = (event) => {
    setCustomervalue(event.target.value);
  };

  //input Searchvalue
  const handelSearchvalue = (event) => {
    setSearchvalue(event.target.value);
  };

  //checkbox
  const handlePermissionChange = (permission) => {
    if (selectedPermissions.includes(permission)) {
      setSelectedPermissions(
        selectedPermissions.filter((item) => item !== permission)
      );
    } else {
      setSelectedPermissions([...selectedPermissions, permission]);
    }
  };

  const showSave = () => {
    setsave("block");
  };
  return (
    <>
      <Box className="tabelBox">
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12}>
            <Box
              className="selectiondiv-box m-rb"
              sx={{ paddingLeft: { xs: "0", md: "1rem " } }}
              justifyContent={"left"}
            >
              <Dropdown
                val={drpValue}
                handleSelect={handleCustomervalue}
                value={customervalue}
                title={"Customer ID"}
              />
              <Box className="selectionDiv searchFlex">
                <Tablesearch
                  handlesearch={(event) => handelSearchvalue(event)}
                />
                <Tooltip title={search} arrow sx={{ marginLeft: 2 }}>
                  <Button className="download-btn solidButton">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                        fill="#FFFFFF"
                      />
                    </svg>
                  </Button>
                </Tooltip>
              </Box>
              <Box className="selectionDiv">
                <Box
                  className="inputFields space"
                  sx={{ margin: "0 !important" }}
                >
                  <TextField placeholder="User Name" type="text" disabled />
                </Box>
              </Box>
              <Box className="selectionDiv">
                <Box
                  className="inputFields space"
                  sx={{ margin: "0 !important" }}
                >
                  <TextField placeholder="Mobile" type="number" disabled />
                </Box>
              </Box>
              <Box className="selectionDiv searchFlex">
                <Tablesearch />
                <Tooltip
                  title={edit}
                  arrow
                  sx={{ marginLeft: 2 }}
                  placement="top"
                >
                  <Button
                    className="download-btn solidButton"
                    onClick={showSave}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      {" "}
                      <path
                        d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </Button>
                </Tooltip>
                <Box
                  className="formBox"
                  sx={{ marginLeft: 1.5, display: save }}
                >
                  <Button className="formSolid-btn">Save</Button>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box className="permissionsAccess">
              <Typography component={"h4"} className="title">
                Permissions
              </Typography>
            </Box>

            <Box className="permission-box">
              <Grid container spacing={2}>
                {permissionsList.map((permission, index) => (
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={4}
                    lg={3}
                    xl={2}
                    className="permission-item"
                    key={index}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedPermissions.includes(permission)}
                          onChange={() => handlePermissionChange(permission)}
                        />
                      }
                      label={permission}
                      labelPlacement="end"
                    />
                  </Grid>
                ))}
              </Grid>

              {/* <Grid container spacing={2}>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Dashboard" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Live Position" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Strategic" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Business Report" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Vendor Rights" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Subscription" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="CopyTradeExpiry" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Video Addition" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Broker ID" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="B Tsym Expiry" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="DB_Live Trade Position" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Sms Data" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Trade View" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Check Balance" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="User View" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Paper Position" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="User P&L Report" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Vendor Report" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Push Notification" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="UserAdmin" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="CopyStrategyExpiry" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Vendor View" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Symbol Token" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="OPT SELL Expiry" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="DB_Paper Trade Position" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Payment Report" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Strategy Performance" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Trade Details" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Order History" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="AdminPL" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Vendor Referal" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="SMS Notification" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Cron View" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Image Addition" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Service Charge" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Holiday List" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="SymbolMaster" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Trade Logs" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Auto Login" labelPlacement="end" />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} xl={2} className='permission-item'>
                  <FormControlLabel control={<Checkbox />} label="Capital Amount" labelPlacement="end" />
                </Grid>
              </Grid> */}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
