import React, { useState } from "react";
import { Box, Grid, Tooltip, Button, Typography } from "@mui/material";
import Tablesearch from "../../../Tablesearch/Tablesearch";
import Table from "../../../Table/Table";
import Dropdown from "../../../Dropdown/Dropdown";

const col = [
  "Customer ID",
  "Client ID",
  "Message",
  "Action Type",
  "Strategy",
  "Order Type",
  "Completed",
  "Updated At",
];
const rows = [
  {
    CustomerID: "EHYD226042",
    ClientID: "EHYD226042",
    Message: "Success",
    ActionType: 150,
    Strategy: "Trading",
    OrderType: "MIS",
    Completed: <span className="open">Complete</span>,
    UpdatedAt: "15-05-2023 14:21:118",
  },
];

export default function Tradelog() {
  // drop down
  const drpValue = ["Customer ID", "HGHJG53", "NJKNK2"];
  const drpValue2 = ["Both", "Succes", "Failed"];
  const drpValue3 = ["All Strategy", "Dcash", "Trade 1"];

  const search = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      Search
    </Typography>
  );

  const [customerid, setCustomerid] = useState();
  const [type, setType] = useState();
  const [strategy, setStrategy] = useState();
  const [searchvalue, setSearchvalue] = useState();

  //select Customerid
  const handleCustomerid = (event) => {
    setCustomerid(event.target.value);
  };

  //select Type
  const handleType = (event) => {
    setType(event.target.value);
  };

  //select Strategy
  const handleStrategy = (event) => {
    setStrategy(event.target.value);
  };

  //select Searchvalue
  const handleSearchvalue = (event) => {
    setSearchvalue(event.target.value);
  };

  return (
    <>
      <Box className="tabelBox">
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12}>
            <Box className="selectiondiv-box m-rb">
              <Dropdown
                val={drpValue}
                handleSelect={handleCustomerid}
                value={customerid}
                title={"Customer ID"}
              />
              <Box className="selectionDiv searchFlex">
                <Tablesearch
                  handlesearch={(event) => handleSearchvalue(event)}
                />
                <Tooltip title={search} arrow>
                  <Button
                    className="download-btn solidButton"
                    sx={{ marginLeft: 1 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                        fill="#FFFFFF"
                      />
                    </svg>
                  </Button>
                </Tooltip>
              </Box>
              <Dropdown
                val={drpValue2}
                handleSelect={handleType}
                value={type}
                title={"Select"}
              />
              <Dropdown
                val={drpValue3}
                handleSelect={handleStrategy}
                value={strategy}
                title={"All Strategy"}
              />
            </Box>
          </Grid>
        </Grid>

        <Table col={col} rows={rows} />
      </Box>
    </>
  );
}
