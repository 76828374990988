import { Box, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSwastikaSession } from "../../../redux/actions/brokerAction";
import { useEffect } from "react";
const Swastika = () => {
  const [sessionId, setSessionId] = useState();
  const dispatch = useDispatch();

  const swastikaSession = useSelector(
    (state) => state.Broker.swastikaSessionId
  );

  useEffect(() => {
    dispatch(getSwastikaSession());
  }, []);

  useEffect(() => {
    setSessionId(swastikaSession);
  }, [swastikaSession]);

  useEffect(() => {
    if (sessionId?.sessionid) {
      window.location.replace(
        `https://swastikaweb.swastika.co.in/auth/login?AppSessionId=${sessionId?.sessionid}`
      );
    }
  }, [sessionId]);

  // const handleSubmit = () => {};
  return (
    <>
      {/* <Box className="border-ap" sx={{ marginTop: 2 }}> */}
      {/* <Button
          // onClick={handleSubmit}
          className="btn solidButton fetchReport-btn"
          sx={{ width: "100% !important" }}
        >
          Login
        </Button> */}
      {/* </Box> */}
    </>
  );
};

export default Swastika;
