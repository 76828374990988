import React from "react";
import Signform from "./Signform";
import Signup from "./Signup";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";

export default function Signin_up() {
  return (
    <>
      <Routes>
        <Route path="/*" element={<Signform />}></Route>
        <Route path="/signup" element={<Signup />}></Route>
      </Routes>
    </>
  );
}
