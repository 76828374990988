import * as actionTypes from "../actionTypes";
import axios from "axios";
import { API_URL } from "../../config";
import { generatePopup } from "../../utils/popup";

export const invociePlanValue = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.GET_INVOICE_PLANVALUE_INIT,
    });
    axios
      .get(`${API_URL}/invoice/planvalue/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_INVOICE_PLANVALUE_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_INVOICE_PLANVALUE_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get invoice of plan value.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_INVOICE_PLANVALUE_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};