import React, { useEffect } from "react";
import "./Signform.scss";
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import logo from "../../images/logo.png";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import signimg from "../../images/signimg.png";
import { validateRegister, validateValues } from "../../validation/signin";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { country_list } from "./countryList";
import { authSignup, sendOtp } from "../../redux/actions/authActions";
import { generatePopup } from "../../utils/popup";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ReactFlagsSelect from "react-flags-select";

export default function Signup() {
  const [open, setOpen] = useState(false);
  const [terms, setTerms] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [fielderror, setFieldError] = useState(false);
  const [fields, setFields] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const [countrycode, setCountryCode] = useState("IN");
  const [number, setNumber] = useState("");
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");
  const [numberError, setNumberError] = useState(false);
  const countNum = 120;
  const [count, setcount] = useState(countNum);
  const [isSent, setIsSent] = useState(false);
  const [notResend, setNotResend] = useState(true);
  const [readOnly, setReadOnly] = useState(false);
  const [seeOtp, setSeeOtp] = useState(false);

  const navigate = useNavigate();
  const handleCount = () => {
    setcount(countNum);
  };

  const data = useLocation();
  const params = new URLSearchParams(data?.search).get("referal_code");
  useEffect(() => {
    if (params) {
      setFields({ ...fields, refrecode: params });
    }
  }, [params]);

  useEffect(() => {
    if (data?.state) {
      setNumber(data?.state?.phone);
      setFormattedPhoneNumber(data?.state?.formattedPhoneNumber);
    }
  }, [data?.state]);

  useEffect(() => {
    if (isSent) {
      const interval = setInterval(() => {
        setcount((oldCount) => {
          if (oldCount === 1) {
            // setReadOnly(false);
            setNotResend(false);
            setIsSent(false);
          }
          return Math.max(oldCount - 1, 0);
        });
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [isSent]);

  const dispatch = useDispatch();

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = (value) => {
    setOpen(false);
    setTerms(value);
  };

  //input signinform
  const handleInputChange = (event) => {
    const keyName = event.target.name;
    const keyValue = event.target.value;

    // Define a regular expression pattern for alphabetic characters
    const alphabeticPattern = /^[A-Za-z]+$/;

    if (keyName === "firstname" || keyName === "lastname") {
      // Check if keyValue contains only alphabetic characters or is empty
      if (keyValue === "" || keyValue.match(alphabeticPattern)) {
        if (!(keyValue.length > 10)) {
          setFields((prevState) => ({
            ...prevState,
            [keyName]: keyValue,
          }));
        }
      }
    } else if (keyName === "refrecode") {
      const pattern = /^[A-Za-z0-9]+$/;
      if (keyValue === "" || keyValue.match(pattern)) {
        if (!(keyValue.length > 10)) {
          setFields((prevState) => ({
            ...prevState,
            [keyName]: keyValue.toUpperCase(),
          }));
        }
      }
    } else {
      setFields((prevState) => ({
        ...prevState,
        [keyName]: keyValue,
      }));
    }

    if (isSubmit) {
      setFieldError(validateRegister({ ...fields, [keyName]: keyValue }));
    }
  };

  const handleOtp = (event) => {
    const keyName = event.target.name;
    let keyValue = event.target.value;

    keyValue = keyValue.replace(/\D/g, "");

    if (keyValue.length <= 6) {
      setFields((prevState) => ({
        ...prevState,
        [keyName]: keyValue,
      }));

      if (isSubmit) {
        setFieldError(validateRegister({ ...fields, [keyName]: keyValue }));
      }
    }
  };

  // input number
  const handleChange = (event) => {
    const { name, value } = event.target;
    const formattedValue = value.replace(/\D/g, "");
    let formattedNumber = formattedValue;
    // Format the phone number with a hyphen after 5 characters for display

    if (formattedValue.length > 5) {
      formattedNumber =
        formattedValue.slice(0, 5) + "-" + formattedValue.slice(5);
    }
    if (event.key === "Backspace") {
      // Handle backspace key
      if (formattedValue.length === 6 && formattedValue.charAt(5) === "-") {
        // Remove hyphen when deleting the 6th character
        formattedNumber = formattedValue.slice(0, 5);
      }
      if (formattedValue.length === 5) {
        // Remove last character when deleting the 5th character
        formattedNumber = formattedValue.slice(0, 4);
      }
    }
    if (formattedValue.length <= 10) {
      setFormattedPhoneNumber(formattedNumber);
      setNumber(formattedValue);
      if (isSubmit) {
        setNumberError(validateValues(formattedValue));
      }
    }
  };

  //otp send
  const sendotp = (e, flag = true) => {
    e.preventDefault();
    if (validateValues(number) === true) {
      let obj = {
        phone: "91" + number,
        method: "text",
      };
      dispatch(sendOtp(obj)).then((res) => {
        if (res) {
          setIsSent(true);
          setcount(countNum);
          handleCount();
          setNotResend(true);
        }
        handleCount();
        setReadOnly(true);

        generatePopup("success", "OTP sent successfully !");
      });
    } else {
      setNumberError(validateValues(number));
      return generatePopup("error", "OTP not sent !");
    }
  };

  // signinform submit
  const handleSubmit = () => {
    setIsSubmit(true);
    let loginObj = {
      firstname: fields?.firstname,
      lastname: fields?.lastname,
      otp: fields?.otp ? Number(fields?.otp) : fields?.otp,
      email: fields?.email,
      refrecode: fields?.refrecode,
      phone: Number("91" + number),
    };
    setFields(loginObj);
    setFieldError(validateRegister(loginObj));
    setNumberError(validateValues(number));
    const error = validateRegister(loginObj);

    if (Object.keys(error) == 0) {
      dispatch(authSignup(loginObj)).then((res) => {
        setReadOnly(false);
        setFields({
          phone: "",
          email: "",
          firstname: "",
          lastname: "",
          DOB: "",
        });
        if (res?.response?.data?.phone?.length > 0) {
          navigate("signin", {
            state: {
              phone: number,
              formattedPhoneNumber: formattedPhoneNumber,
            },
          });
        }
      });
    }
  };

  let country_array = [];
  let country_label = {};
  let country_labelName = {};
  let array = Object.keys(country_list).map((code, index) => {
    const country = country_list[code];
    country_array.push(code);
    country_label[code] = "+" + country.phone;
    country_labelName[code] = " " + country.name;
  });

  return (
    <>
      <Box className="login_layer"></Box>
      <Box className="signForm-bg">
        <Box className="signForm-menu">
          <NavLink to={"/"} className="logoLight">
            {/* <img src={logo} height={"100%"} width={"100%"} /> */}
          </NavLink>
          <Box>
            <NavLink component={"a"} to={"/"} className="signForm-btn">
              Sign In{" "}
            </NavLink>
            <NavLink component={"a"} to={"/signup"} className="signForm-btn">
              Sign Up{" "}
            </NavLink>
          </Box>
        </Box>

        <Typography component={"p"} className="welcome-text">
          Welcome to the world of algorithmic trading – where innovation meets
          financial markets.
        </Typography>

        <Box className="signForm-box">
          <Box className="sign_form_left">
            <img src={signimg} height={"100%"} width={"100%"} />
          </Box>

          <Box className="sign_form_right">
            <Box className="from-bg">
              <Typography component={"p"} className="form-text">
                Create an Account
              </Typography>
              <Typography component={"p"} className="form-welcome filled">
                Start algo trading with free life time paper trading .
              </Typography>
              <Typography component={"h4"} className="form-text">
                Signup
              </Typography>

              {/* Sign Up Form */}
              <Box
                className="contactForm formBox"
                sx={{ border: "none !important" }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box className="inputFields space fullWidth filled">
                      <Typography component={"label"} className="label">
                        First name
                      </Typography>
                      <TextField
                        type="text"
                        placeholder="First Name"
                        className="inputFiled"
                        id="FirstName"
                        name="firstname"
                        value={fields?.firstname ? fields.firstname : ""}
                        onChange={handleInputChange}
                      />
                      {fielderror && (
                        <span className="color">{fielderror.firstname}</span>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box className="inputFields space fullWidth filled">
                      <Typography component={"label"} className="label">
                        Last name
                      </Typography>
                      <TextField
                        type="text"
                        placeholder="Last Name"
                        className="inputFiled"
                        id="LastName"
                        name="lastname"
                        value={fields?.lastname ? fields.lastname : ""}
                        onChange={handleInputChange}
                      />
                      {fielderror && (
                        <span className="color">{fielderror.lastname}</span>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="inputFields space fullWidth filled">
                      <Typography component={"label"} className="label">
                        E Mail
                      </Typography>
                      <TextField
                        type="email"
                        placeholder="Enter Email"
                        className="inputFiled"
                        id="Email"
                        name="email"
                        value={fields?.email ? fields.email : ""}
                        onChange={handleInputChange}
                      />
                      {fielderror && (
                        <span className="color">{fielderror.email}</span>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="inputFields space fullWidth filled">
                      <Typography component={"label"} className="label">
                        Mobile Number
                      </Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box
                          className="dropdown-ap"
                          sx={{
                            width: "10rem !important",
                            marginRight: 1,
                            "& >div.ReactFlagsSelect-module_flagsSelect__2pfa2 > button > span":
                              {
                                padding: 0,
                                height: "1.6rem",
                                fontSize: "1.3rem",
                              },
                            "& >div.ReactFlagsSelect-module_flagsSelect__2pfa2>ul":
                              { maxWidth: "12rem", width: "12rem" },
                          }}
                        >
                          <ReactFlagsSelect
                            selected={countrycode}
                            countries={country_array}
                            customLabels={country_label}
                            onSelect={(code) => {
                              setCountryCode(code);
                            }}
                            placeholder=" "
                          />
                        </Box>
                        <Box
                          className="inputFields space fullWidth filled"
                          sx={{ marginLeft: 0 }}
                        >
                          <TextField
                            type="text"
                            placeholder="Enter Mobile Number"
                            value={formattedPhoneNumber}
                            className="inputFiled"
                            id="MobileNumber"
                            name="number"
                            onChange={handleChange}
                          />
                        </Box>

                        <Button
                          type="submit"
                          className={
                            readOnly || number.length < 10
                              ? "disabled-btn"
                              : "signForm-btn"
                          }
                          sx={{
                            width: "max-content !important",
                            borderRadius: "0.5rem !important",
                          }}
                          onClick={sendotp}
                          disabled={readOnly || number.length < 10}
                        >
                          Get OTP
                        </Button>
                      </Box>
                      {numberError && <p className="color">{numberError}</p>}
                      {readOnly && count > 0 && (
                        <Typography component={"p"} className="valide_otp">
                          You can resend the OTP in
                          <Typography component={"span"}>{count}</Typography>
                          second
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="inputFields space fullWidth filled">
                      <Typography component={"label"} className="label">
                        OTP
                      </Typography>

                      <Box className="resend_otp">
                        <TextField
                          type={seeOtp ? "number" : "password"}
                          placeholder="Enter OTP"
                          className="inputFiled"
                          id="otp"
                          name="otp"
                          value={fields?.otp ? fields.otp : ""}
                          onChange={handleOtp}
                        />
                        <Box
                          sx={{ display: "flex", alignItems: "center" }}
                          className="actions_otp"
                        >
                          <Button
                            onClick={() => {
                              setSeeOtp(!seeOtp);
                            }}
                            className="otp_viewHide"
                          >
                            {seeOtp ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityRoundedIcon />
                            )}
                          </Button>
                          <Button
                            className="resend_otp_btn"
                            onClick={(e) => sendotp(e, false)}
                            disabled={notResend}
                            style={{
                              color: notResend === true ? "gray" : "#0a53b5",
                            }}
                          >
                            RESEND OTP
                          </Button>
                        </Box>
                      </Box>
                      {fielderror && (
                        <span className="color">{fielderror.otp}</span>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="inputFields space fullWidth filled">
                      <Typography component={"label"} className="label">
                        Promo Code
                      </Typography>
                      <TextField
                        type="text"
                        placeholder="Enter promo code"
                        className="inputFiled"
                        id="refrecode"
                        name="refrecode"
                        disabled={params ? true : false}
                        value={fields?.refrecode ? fields.refrecode : ""}
                        onChange={handleInputChange}
                      />
                      {fielderror && (
                        <span className="color">{fielderror.refrecode}</span>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="term_checkbox">
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={terms} />}
                          onClick={handleClickOpen("paper")}
                          label="I Agree To Terms & Conditions"
                        />
                      </FormGroup>
                    </Box>
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  className="signForm-btn"
                  sx={{ marginTop: "2rem !important" }}
                  onClick={handleSubmit}
                  disabled={!terms}
                >
                  Sign Up
                </Button>

                <Box className="accoutnHere">
                  <Typography component={"p"}>
                    Already have an Account ?
                  </Typography>
                  <NavLink component={"a"} className="signIn_go" to={"/signin"}>
                    Sign in
                  </NavLink>
                </Box>
              </Box>
              <Typography
                my={3}
                className="form-welcome"
                sx={{ fontSize: "22px !important" }}
              >
                Download Now!
              </Typography>
              <Box
                sx={{
                  display: "flex !important",
                  justifyContent: "center",
                  gap: 2,
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
                my={2}
              >
                <Box md={5}>
                  <Link
                    style={{
                      display: "flex",
                    }}
                    target="_blank"
                    to="https://play.google.com/store/apps/details?id=dotin.tradearth"
                  >
                    <Box
                      sx={{
                        height: "50px",
                        width: "160px",
                      }}
                    >
                      <img
                        style={{ objectFit: "fill" }}
                        src={require("../../images/image 30.png")}
                      />
                    </Box>
                  </Link>
                </Box>
                <Box md={5}>
                  <Link
                    style={{
                      display: "flex",
                    }}
                    target="_blank"
                    to="https://apps.apple.com/in/app/trade-arth/id6470361389"
                  >
                    <Box
                      sx={{
                        height: "50px",
                        width: "160px",
                      }}
                    >
                      <img
                        style={{ objectFit: "fill" }}
                        src={require("../../images/apple-app-store-logo.jpg")}
                      />
                    </Box>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        className="term_condition_modal"
      >
        <DialogTitle>
          <Typography component={"h2"} className="modal_header">
            Terms & Condition
          </Typography>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText tabIndex={-1}>
            <Typography component={"p"}>
              {" "}
              Welcome to DeMatade Algo Technology Pvt Ltd. The domain name
              tradearth.in (hereinafter referred to as "Website") is owned by
              DeMatade Algo Technology Pvt Ltd., a company incorporated under
              the Companies Act, 1956 having its registered office at Office
              No.604 Shagun Insignia Sector-19 Plot no.195 Ulwe Navi Mumbai
              India 410206 (hereinafter to be referred as “DEMATADE ALGO
              TECHNOLOGY PRIVATE LIMITED”).
            </Typography>
            <Typography component={"p"}>
              {" "}
              We continuously & consistently endeavor to provide you with the
              best possible service and in line with that, advise you to read
              carefully and understand the “Terms & Conditions” as also the
              limitations of this service (website) prior to making use of any
              service offered herein
            </Typography>
            <Typography component={"p"}>
              {" "}
              You can use this website “only & only if” you agree with the
              conditions mentioned here in totality, therefore any usage of our
              website, if made for the purpose of any transactions and/or to
              obtain any information, would signify your acceptance of all terms
              & conditions mentioned here in entirety, without any variation
              whatsoever.
            </Typography>
            <Typography component={"p"}>
              {" "}
              Please note that by accessing this website you acknowledge that
              you have read, understood and agreed to be bound by the terms and
              conditions mentioned below.
            </Typography>

            <Typography component={"h4"}>
              TERMS AND CONDITIONS OF USE:
            </Typography>
            <Typography component={"p"}>
              You acknowledge, agree & confirm that:
            </Typography>
            <Typography component="p" className="list">
              1. You have all attributes as required under the applicable Laws
              of India for you to enter into a valid & legally binding contract,
              including but not limited to your attainment of the age of 18
              years, being of a sound mind and not suffering from any legal
              limitations/debarment.
            </Typography>
            <Typography component="p" className="list">
              2. You are “the person” registered as our client, and thus the
              person entitled to access the information and effect transactions
              through this website.
            </Typography>
            <Typography component="p">
              The security & developmental concerns necessitate, allow & empower
              us to alter and/or amend the rights, limitations and obligations
              contained herein without any specific notice and/or advice to you.
            </Typography>
            <Typography component="p">
              TradeArth reserves the right to change the terms of service or
              policies regarding the use of the Website at any time and posting
              an updated version of the “SERVICE” on this Website shall be
              construed as sufficient notice to you in this regard. You
              understand and are responsible for regularly reviewing the
              “SERVICE”. Continued use of the Website after any such changes
              shall constitute your consent to such changes.
            </Typography>
            <Typography component="p">
              The trading system as available through the website is meant for &
              restricted to authorized user only. Any actual or attempted
              unauthorized access to this trading system is subject to civil
              and/or criminal proceeding/penalties & consequences.
            </Typography>
            <Typography component="p">
              Unless specifically mentioned otherwise the “Terms & Conditions”
              herein supersede the previous terms & conditions, if any, on the
              matters specified herein.
            </Typography>
            <Typography component="p">
              TradeArth has exclusive and sole discretion to
              accept/reject/deactivate the account of any customers, without
              assigning any reason what so ever.
            </Typography>
            <Typography component="p">
              TradeArth also reserves the absolute right and discretion to
              decide on the criteria for selecting/allowing the customers to use
              the services offered through its website.
            </Typography>
            <Typography component="p">
              TradeArth would be entitled to assume that all the information
              provided by you is true & correct and that you have not concealed
              anything material in any respect, your indulgence in providing
              incorrect or incomplete information would be at your own risk,
              responsibility & legal (civil and criminal) consequences.
              TradeArth reserves the right to suspend/deactivate your account &
              transactions in case you provide any incorrect information or fail
              to comply with any statutory requirement, whether current or
              future.
            </Typography>
            <Typography component="p">
              Disclosure of information, made in accordance with the
              requirements of law, by TradeArth wouldn’t amount to breach of
              trust and it will be TradeArth’s right to do so.
            </Typography>
            <Typography component="p">
              TradeArth may at times need to edit, alter and or remove any
              information or transaction mechanism in whole or in part that may
              be available on its web site however, TradeArth shall not be held
              responsible for all or any consequences that may subsequently
              culminate into any loss, damage and or liability.
            </Typography>
            <Typography component="p" className="list">
              1. You confirm that your registration with us doesn’t violate any
              law or its procedures and adherence to all such procedures shall
              be your sole responsibility.
            </Typography>
            <Typography component="p" className="list">
              2. You agree to protect your Web Access Password from becoming
              known to any other person, including any representative of
              TradeArth, as you understand that the same may provide access to
              your account and enable them to transact through the account and
              this may result in financial loss to you, for which TradeArth
              doesn’t accept any responsibility & liability.
            </Typography>
            <Typography component="p" className="list">
              3. You will not allow or give access to any person to your mobile
              number and email account registered with us, as TradeArth would be
              delivering information as required and meant to be kept
              confidential, to the said mobile number and email account and you
              would be solely responsible for any consequence resulting from
              your failure to protect the confidentiality of information
              delivered to your mobile number and email account.
            </Typography>
            <Typography component="p" className="list">
              4. “YOU & ONLY YOU” You are authorized to use & access the
              services offered to you by the website using your User Name and
              Password and shall not share this information with any other
              person. It shall be your sole responsibility to ensure that no
              other person is able to use your website account for any purpose
              whatsoever.
            </Typography>
            <Typography component="p" className="list">
              5. You are advised to exercise full caution while entering any
              transaction or information through the website as you would be
              completely, solely & invariably responsible for all resultant
              implications & consequence, financial or otherwise.
            </Typography>
            <Typography component="p">
              Before using the website for any purpose, you agree to understand
              the services & information provided by/through the website
              completely, and shall not plead ignorance of any facility in any
              matter against TradeArth.
            </Typography>
            <Typography component="p">
              While efforts have been made to make this Website helpful and
              accurate, due to the open nature of this Web site, and the
              potential for errors in the storage and transmission of digital
              information, TradeArth does not represent or warrant the
              completeness, authenticity or accuracy of information obtained
              from this Website, and TradeArth shall not be responsible or
              liable for any loss consequent to such errors, Further TradeArth
              doesn’t provide any assurance that the information provided will
              not violate or infringe any right of third parties.
            </Typography>
            <Typography component="p">
              TradeArth conducts research at its own cost, with an intention to
              provide benefit of knowledge gained from such research to its
              Clients, the information is imparted through various mediums,
              including but not limited to SMS’s, reports published on the
              website and emails etc, to name a few, but TradeArth doesn’t
              guarantee, in any manner that the same will result in beneficial
              consequences, you are therefore advised to exercise discretion &
              trust your own instincts as regards the use of information so
              provided.
            </Typography>
            <Typography component="p">
              The services offered by the website may vary from time to time and
              are at the sole discretion of TradeArth, further TradeArth
              reserves the right to amend/withdraw or add any service at its
              discretion and you shall have no objection to such change.
            </Typography>
            <Typography component="p">
              The content of the website shouldn’t be copied, reproduced,
              republished, uploaded, posted, transmitted or distributed for any
              non – personal use without obtaining prior permission from
              TradeArth. In addition to other legal recourses TradeArth reserves
              the right to terminate the account of subscribers/customers, who
              violate such proprietary rights.
            </Typography>
            <Typography component="p" className="list">
              You warrant that you shall not use the Information from any of our
              sites for any illegal purpose. Further you are aware that it is
              forbidden to link to any of the Pages/content of TradeArth’s
              website to your own or another website in any way without
              TradeArth express written consent. If you do this we put you on
              notice that in doing so you are breaching our and our licensors
              intellectual property rights in the Information and we reserve the
              right to take action against you to uphold our rights, which may
              involve pursuing injunctive proceedings.
            </Typography>
            <Typography component="p">
              TradeArth exercises’ due care to ensure provisioning of a secure
              environment and smooth functioning, however due to the volatility
              & vulnerability of internet,TradeArth provides no warranty that
              the contents of the website and its components are free from
              viruses or other damaging elements, which have contaminating or
              destructive properties and therefore doesn’t accept any liability
              in respect thereof.
            </Typography>
            <Typography component="p">
              TradeArth does not guarantee continuous, uninterrupted or secure
              access to this website or to any other linked website. You
              understand that the website may be unavailable for such period of
              time as required due to technical and/or maintenance reasons or
              otherwise as decided by TradeArth including during market hours
              for modification, maintenance activity or any other reason as
              deemed fit by TradeArth and you shall not hold TradeArth or its
              Vendors liable in any manner whatsoever for such
              interruption/unavailability of the website/service.
            </Typography>
            <Typography component="p">
              TradeArth neither makes any representation or warranty regarding
              the standing, credit of any person, firm or company mentioned in
              the information contained in the Web site, nor does it warrant the
              suitability of the information contained on the Web site for any
              purpose
            </Typography>
            <Typography component="p">
              Use of the services offered by the website is your own risk &
              responsibility. The data and information provided on the website
              is not advice, professional or otherwise, and should not be
              construed upon as such. Neither the information nor any opinion
              contained in this website constitutes a solicitation or offer by
              TradeArth to buy or sell any securities, future, options or other
              financial instruments or provide any investment advice or service.
              Also, Orders, if accepted during off market hours will be sent to
              the exchange after the exchange opens on the next trading day.
              TradeArth does not take any responsibility for any delay in
              acceptance / execution or non-execution of the order by the
              Exchange.
            </Typography>
            <Typography component="p">
              TradeArth shall not be liable or responsible for any resultant
              loss consequent to unauthorized or illegal, additions, deletions
              or alterations made by any third party to the Website.
            </Typography>
            <Typography component="p" className="list">
              You shall remain solely liable for the content of any messages or
              other information you upload or transmit to TradeArth, including
              the discussion forums or interactive areas of this Web site. You
              agree to indemnify and hold harmless TradeArth from any claim,
              action, demand, loss, or damages (including attorney’s fees) made
              or incurred by any third party arising out of or relating to your
              conduct, your violation of these terms and conditions, or any
              rights of the third party.
            </Typography>
            <Typography component="p">
              TradeArth will immediately terminate any account which it
              believes, in its sole discretion, is transmitting or is otherwise
              connected with any spam or other unsolicited bulk email.
            </Typography>
            <Typography component="p">
              The investments discussed or recommended in the market analysis
              research report etc may not be suitable for all investors.
              Investors must make their own investment decision based on their
              specific investment objectives, financial position, risk taking
              capacity and using such independent advisors as they believe
              necessary. Information herein is believed to have been obtained
              from reliable sources but TradeArth does not warrant its
              completeness, reliability or accuracy. You are advised to pursue
              the articles and other data in the website only as information and
              to rely on your own judgment when making investment decisions.
            </Typography>
            <Typography component="p">
              The services are provided on an “AS IS” basis. TradeArth and its
              Vendors/ Directors/ Promoters disclaim any warranty of any kind,
              imputed by the laws of any jurisdiction, whether express or
              implied, as to any matter whatsoever relating to the service,
              including without limitation the implied warranties of
              merchantability, fitness for a particular purpose and non –
              infringement. TradeArth is not responsible for the content of any
              of the linked sites. By providing access to other websites,
              TradeArth is neither recommending nor endorsing the content
              available in the linked websites.
            </Typography>
            <Typography component="p">
              The services on the website do not constitute an offer to sell or
              intended for solicitation of any person in any jurisdiction where
              it is unlawful to make such an offer or solicitation. This service
              is not intended to be any form of an investment advertisement,
              investment advice or investment information and has not been
              registered under any securities law of any foreign jurisdiction
              and is only for the information for personal use of client. The
              distribution of this service or content in other jurisdictions may
              be restricted by law and the person who access the service should
              informed themselves about and observe compliance of the same. By
              accessing and surfing this website the customers agrees to be
              bound by the foregoing limitation.
            </Typography>
            <Typography component="p">
              TradeArth shall not be liable for any consequences if the customer
              downloads any information or software from this website. Further
              TradeArth shall not be liable if the customers make a copy,
              modifies, uploads, downloads, transmits, (re)publishes it or
              removes or obscures any intellectual property, including
              trademark, copyright, or other notices or legends contained in any
              such information or otherwise distributes any service or content
              from this website. TradeArth shall not bear the responsibility if
              the customer modifies or uses the content or services he/she gets
              from this website for any purpose other than those permitted and
              in doing so violates the intellectual property rights including
              trademarks, copyrights and other proprietary rights of any person.
              The trademarks, logos and service marks available on our web site
              is an invaluable asset of TradeArth and/or its agents, associates,
              subsidiaries and other parties, who have been authorized by
              TradeArth to display the same on its web site. In our endeavor to
              serve and protect your interests and rights, we would appreciate
              if you could inform us in writing to contact@tradearth.in giving
              all necessary details of the violation or infringement of our
              trademarks, logos and service marks which are either protected by
              us and or available on our web site. The information thus provided
              by you would be forwarded to our legal advisors for opinion on all
              or any legal recourse that can or should be adopted by us in order
              to protect our intellectual Property rights
            </Typography>
            <Typography component="p">
              TradeArth or any of its Directors, employees, associates or other
              representatives and its Vendors shall not be liable for damages or
              injury arising out of or in connection with the use of the website
              or its non-use including non-availability, compensatory, direct,
              indirect or consequential damages, loss of data, income or profit,
              loss or damage to property ( including loss or corruption of data,
              loss of goodwill, work stoppage, computer failure or malfunction
              or interruption of business ; under any contract, negligence,
              strict liability or other theory arising out of or relating in any
              products or services and claims of third parties damages or
              injuries caused by any performance, failure of performance, error,
              omission, interruption, deletion, defect, delay in operation or
              transmission, computer virus, communication line failure, theft or
              destruction or unauthorized access to, alteration of, or use of
              information, whether resulting in whole or in part, from/or
              relating to any of the services offered or displayed by TradeArth
              on this website).
            </Typography>
            <Typography component="p">
              TradeArth makes no guarantees or representation as to, and shall
              have no liability for, any electronic content delivered by any
              third party, including the accuracy, subject matter, quality or
              timeliness of any electronic content on the website.
            </Typography>
            <Typography component="p">
              TradeArth shall not be responsible for the contents of any linked
              site or any content contained in a linked site, or any changes or
              updates to such sites. The links being provided to the customers
              are only for information and convenience and the inclusion of any
              link does not imply any endorsement, recommendation or
              certification of correctness of the content of linked site by
              TradeArth. By providing access to other website, TradeArth does
              not recommend the purchase or sale of the securities issued by it.
            </Typography>
            <Typography component="p">
              Save as otherwise provided herein, to the maximum extent
              permissible by law all warranties, conditions or terms other than
              those expressly set out are excluded, including but not limited to
              implied and statutory conditions and warranties
            </Typography>
            <Typography component="p">
              Offers shall remain valid only for such period as may be
              determined by TradeArth. TradeArth also reserves the absolute
              right to decide on the duration of the period during which the
              Offer shall be available. TradeArth need not and does not and will
              not undertake to notify the user about the Offer.
            </Typography>
            <Typography component="p">
              TradeArth has been incorporated under the laws of India, you will
              appreciate that we can only abide and follow the laws of this land
              for entering and/or fulfilling the terms & conditions set out
              herein. For the sake of jurisdiction, all or any disputes arising
              out of/or relating to the use of this website, the courts in the
              city of Mumbai in the state of Maharashtra, shall ONLY have
              jurisdiction.
            </Typography>
            <Typography component="p" className="list">
              The access and or use by you of our website shall be deemed to be
              UNAUTHORIZED if the terms and conditions contained herein, in part
              or in full including this paragraph are not recognized and or not
              capable of being enforced in the country and or jurisdiction from
              where you have voluntarily or otherwise accessed and/or used our
              web site in any manner.
            </Typography>
            <Typography component="p" className="list">
              You understand and agree that no joint venture, partnership,
              employment, or agency relationship exists between us as a result
              of this association or on account of use of our web site.
            </Typography>
            <Typography component="p">
              TradeArth reserves the right to provide and disclose any
              information as desired by any statutory, law enforcing or
              regulatory authority, including the information about the
              transactions effected by you through this website in regular
              course or otherwise and such an act by us shall not cause the
              derogation of our right with respect to these terms nor shall it
              cause other terms to be null and void or be considered violation
              of any terms and conditions contained herein.
            </Typography>
            <Typography component="p">
              During the existence and subsistence of these terms, if any part
              hereof is concluded to be invalid or unenforceable in terms of any
              applicable Indian law, then the invalid or unenforceable provision
              will be deemed superseded by a valid, enforceable provision that
              most closely matches the intent of the original provision and the
              other terms shall continue in effect.
            </Typography>

            <Typography component="p" style={{ color: "#006093" }}>
              <Typography
                component="span"
                style={{ color: "red", fontSize: "14px", fontWeight: "bold" }}
              >
                Disclaimer :{" "}
              </Typography>
              Please note that the information provided by Trade Arth is for
              educational purposes only. Trade Arth staff does not provide
              specific investment advice, advocate for any particular investment
              methodology, endorse specific financial tools or trading
              platforms, or recommend any particular financial planner, advisor,
              or broker-dealer. Trading and investing always involve high levels
              of risk, and any decisions you make regarding your trading or
              investment activities are your sole responsibility. Trade Arth
              does not take any responsibility for any trading or investing
              activity you undertake. Subscription fees paid to Trade Arth are
              non- refundable, and their service is limited to automated trading
              application development, deployment, and maintenance. All
              algorithms provided by Trade Arth are based on backtested data,
              but there is no guarantee of their future performance. Past
              performance is not indicative of future results. Any algorithm
              running in an automated system is agreed upon with the user before
              deployment, and Trade Arth does not accept liability for any
              losses generated by the use of these algorithms. It is essential
              to understand that trading and investing in financial markets
              carry inherent risks, and you should carefully consider your
              financial situation, risk tolerance, and investment goals before
              making any decisions. If you require personalized financial
              advice, it is recommended to consult a qualified financial advisor
              or professional.
            </Typography>
            <Typography component="p">
              <Typography
                component="span"
                style={{ fontSize: "14px", fontWeight: "bold" }}
              >
                This disclaimer shall be applicable to any person
                visiting/accessing the Web site and/or a user entering into an
                agreement related to availing of the services offered by
                TradeArth.
              </Typography>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClose(false)}
            className="signForm-btn secound"
          >
            Cancel
          </Button>
          <Button onClick={() => handleClose(true)} className="signForm-btn">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
