import React, { useState } from 'react';
import {
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Box,
  TextField
} from '@mui/material';

export default function Entry_exit() {
  const [order, setorder] = React.useState(' ');
  const ordertype = ['Percentage %', 'Absolute (abs)', 'Points (pts)'];
  const [value, setValue] = React.useState('admintab1');

  const ordertypedrp = (event) => {
    setorder(event.target.value);
  };


  const handleChange = (e, newValue = 'string') => {
    setValue(newValue);
  };
  const GetTPSLType = (ordertype) => {
    switch (ordertype) {
      case ' ':
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box className='inputFields space fullWidth'>
                <Typography component={'label'} className='label' color='#ff0000 !important'>Stop Loss %</Typography>
                <TextField value={0} placeholder="Script" className='inputFiled' />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className='inputFields space fullWidth'>
                <Typography component={'label'} className='label' color='#24a959 !important'>Target profit %</Typography>
                <TextField value={0} placeholder="Script" className='inputFiled' />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className='inputFields space fullWidth'>
                <Typography component={'label'} className='label'>Trailing SL % (optional)</Typography>
                <TextField value={0} placeholder="Script" className='inputFiled' />
              </Box>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box className='inputFields space fullWidth'>
                <Typography component={'label'} className='label' color='#ff0000 !important'>Total Stop loss</Typography>
                <TextField value={0} placeholder="Script" className='inputFiled' />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className='inputFields space fullWidth'>
                <Typography component={'label'} className='label' color='#24a959 !important'>Total Target profit</Typography>
                <TextField value={0} placeholder="Script" className='inputFiled' />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className='inputFields space fullWidth'>
                <Typography component={'label'} className='label'>Total Trailing SL % (optional)</Typography>
                <TextField value={0} placeholder="Script" className='inputFiled' />
              </Box>
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box className='inputFields space fullWidth'>
                <Typography component={'label'} className='label' color='#ff0000 !important'>Stop loss</Typography>
                <TextField value={0} placeholder="Script" className='inputFiled' />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className='inputFields space fullWidth'>
                <Typography component={'label'} className='label' color='#24a959 !important'>Total profit</Typography>
                <TextField value={0} placeholder="Script" className='inputFiled' />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className='inputFields space fullWidth'>
                <Typography component={'label'} className='label'>Trailing SL(optional)</Typography>
                <TextField value={0} placeholder="Script" className='inputFiled' />
              </Box>
            </Grid>
          </Grid>
        );
    }
  };

  return (
    <Box className='strategy_box'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ display: { xs: 'block', md: 'flex' }, alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography className='title_positions' component={'h4'}>Entry Time</Typography>
            <Box className='formBox'>
              <Box className='formItems' sx={{ display: 'flex !important', alignItems: 'center' }}>
                <Typography component={'label'} className='label'>TPSL type</Typography>
                <Box className='selectionDiv' >
                  <FormControl className='dropdown-ap' sx={{ width: '100%' }}>
                    <Select value={order} onChange={ordertypedrp} className='dropdown'>
                      {ordertype.map((vals, index) => (
                        <MenuItem value={index === 0 ? ' ' : index} key={index}>{vals}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box className='order_box'>
        {GetTPSLType(order)}
      </Box>
    </Box >
  )
}
