import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  Tooltip,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./Userview.scss";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Tablesearch from "../../../Tablesearch/Tablesearch";
import Dropdown from "../../../Dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  createUserUpdateAdmin,
  emptyUserView,
  userUpdateAdmin,
} from "../../../../redux/actions/adminActions";
import dayjs from "dayjs";

export default function Userview() {
  const edit = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      Edit{" "}
    </Typography>
  );
  const search = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      search{" "}
    </Typography>
  );
  const dispatch = useDispatch();
  // const drpValue = ["Select Type", "Client ID", "Mobile", "E-mail"];
  // const [hidden, sethidden] = React.useState("none");
  // const [clientvalue, setClientvalue] = useState();
  // const [searchvalue, setSearchvalue] = useState();
  // const [fields, setFields] = useState();

  const data = useSelector((state) => state?.Admin?.userViewData);
  const userData = useSelector((state) => state?.Auth?.authProfile?.[0]);

  const drpValue = ["ClientID", "Phone"];
  // const drpValue1 = ["Client ID", "JHJF", "FFD55"];
  const [type, setType] = useState("clientid");
  const [searchvalue, setSearchvalue] = useState("");
  const [searchData, setSearchData] = useState("");
  const [editable, setEditable] = useState(false);
  const [fields, setFields] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const submitForm = () => {
    console.log("SUBMIT");
    dispatch(
      userUpdateAdmin({
        username: fields?.customerid,
        owner: userData?.username,
        brokerid: fields?.brokerid,
        clientid: fields?.clientid,
        firstname: fields?.firstname,
        lastname: fields?.lastname,
        email: fields?.email,
        mobile: fields?.mobile,
        refercode: fields?.["refer code"],
        token: fields?.token,
        start_date: startDate,
        end_date: endDate,
        planstatus:
          fields?.["plan status"] === true || fields?.["plan status"] === "1"
            ? "True"
            : "False",
        otp: fields?.otp,
      })
    ).then((res) => {});
  };

  const handleStart = (e) => {
    setStartDate(dayjs(e).format("YYYY-MM-DD"));
  };
  const handleEnd = (e) => {
    setEndDate(dayjs(e).format("YYYY-MM-DD"));
  };

  //select client id
  const handleType = (event) => {
    setType(event.target.value);
  };

  //input search
  const handlesearch = (event) => {
    setSearchvalue(event.target.value);
  };
  const handleSearchData = (event) => {
    const { value } = event.target;
    if (type === "phone") {
      if (/^(?:\d{0,10})?$/.test(value)) {
        setSearchData(event.target.value);
      }
    } else {
      setSearchData(event.target.value);
    }
  };

  //from all input
  const handleInputChange = (event) => {
    const keyName = event.target.name;
    const keyValue = event.target.value;
    if (keyName === "mobile") {
      if (keyValue?.length <= 10) {
        setFields((prevState) => ({
          ...prevState,
          [keyName]: keyValue,
        }));
      }
    } else {
      setFields((prevState) => ({
        ...prevState,
        [keyName]: keyValue,
      }));
    }
  };

  const handleSearchDetail = () => {
    const obj =
      type === "clientid"
        ? {
            search: type,
            phone: searchData,
          }
        : {
            search: type,
            phone: "91" + searchData,
          };
    dispatch(createUserUpdateAdmin(obj));
  };

  useEffect(() => {
    setFields(data);
    setStartDate(data?.plan_start_date);
    setEndDate(data?.plan_end_date);
  }, [data]);

  useEffect(() => {
    dispatch(emptyUserView());
  }, []);

  return (
    <>
      <Box className="userview">
        <Box className="userviewActions">
          <Box
            sx={{ display: "flex", alignItems: "center" }}
            className="selectiondiv-box m-rb"
          >
            <Dropdown
              val={drpValue}
              handleSelect={handleType}
              value={type}
              title={"Search Type"}
            />
            <FormControl
              sx={{
                display: "flex",
                alignItems: "start",
                justifyContent: "start",
                marginBottom: "1rem",
              }}
              variant="outlined"
            >
              <OutlinedInput
                id="outlined-adornment-password"
                sx={{ border: "1px solid rgba(117, 134, 150, 0.3)" }}
                inputProps={{
                  style: { fontSize: "14px", padding: "10px" },
                  placeholder: `enter ${type}`,
                }}
                onChange={handleSearchData}
                value={searchData}
                label="Password"
              />
            </FormControl>

            <Box className="selectionDiv searchFlex">
              <Tablesearch handlesearch={(event) => handlesearch(event)} />
              <Tooltip title={search} arrow>
                <Button
                  onClick={handleSearchDetail}
                  className="download-btn solidButton"
                  sx={{ marginLeft: 1 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                      fill="#FFFFFF"
                    />
                  </svg>
                </Button>
              </Tooltip>
              {userData?.is_staff && (
                <Tooltip title={edit} arrow sx={{ marginLeft: 1 }}>
                  <Button
                    onClick={() => {
                      setEditable(true);
                    }}
                    className="download-btn solidButton"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      {" "}
                      <path
                        d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                        fill="white"
                      />
                    </svg>
                  </Button>
                </Tooltip>
              )}
            </Box>
          </Box>
        </Box>

        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Customer ID
              </Typography>
              <TextField
                className={!editable && "disabled-inputField"}
                disabled={true}
                type="text"
                placeholder="Enter Customer ID"
                name="customerid"
                value={fields?.customerid}
                onChange={handleInputChange}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Broker ID
              </Typography>
              <TextField
                className={!editable && "disabled-inputField"}
                disabled={!editable}
                type="text"
                placeholder="Enter Broker ID"
                name="brokerid"
                value={fields?.brokerid}
                onChange={handleInputChange}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Client ID
              </Typography>
              <TextField
                className={!editable && "disabled-inputField"}
                disabled={!editable}
                type="text"
                placeholder="Enter Client ID"
                name="clientid"
                value={fields?.clientid}
                onChange={handleInputChange}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                First Name
              </Typography>
              <TextField
                className={!editable && "disabled-inputField"}
                disabled={!editable}
                type="text"
                placeholder="Enter Name"
                name="firstname"
                value={fields?.firstname}
                onChange={handleInputChange}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Last Name
              </Typography>
              <TextField
                className={!editable && "disabled-inputField"}
                disabled={!editable}
                type="text"
                placeholder="Enter Name"
                name="lastname"
                value={fields?.lastname}
                onChange={handleInputChange}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Email
              </Typography>
              <TextField
                className={!editable && "disabled-inputField"}
                disabled={!editable}
                type="text"
                placeholder="Enter Email"
                name="email"
                value={fields?.email}
                onChange={handleInputChange}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Mobile
              </Typography>
              <TextField
                className={!editable && "disabled-inputField"}
                disabled={!editable}
                type="number"
                placeholder="Enter Mobile Number"
                name="mobile"
                value={fields?.mobile}
                onChange={handleInputChange}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Plan Status
              </Typography>
              <TextField
                className={!editable && "disabled-inputField"}
                disabled={!editable}
                type="text"
                placeholder="Enter Plan Status"
                name="plan status"
                value={
                  fields?.["plan status"]
                    ? Number(fields?.["plan status"])
                    : fields?.["plan status"] === false
                    ? Number(fields?.["plan status"])
                    : ""
                }
                onChange={handleInputChange}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Plan Start Date
              </Typography>
              {/* <TextField
                className={!editable && "disabled-inputField"}
                disabled={!editable}
                type="text"
                placeholder="Start Date"
                name="plan_start_date"
                value={fields?.plan_start_date}
                onChange={handleInputChange}
              /> */}
              <Box
                sx={{
                  margin: "0 !important",
                }}
                className="selectiondiv-box"
              >
                <Box
                  sx={{
                    padding: "0 !important",
                    marginRight: 0,
                  }}
                  className="fullWidth selectionDiv"
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      onChange={handleStart}
                      disabled={!editable}
                      value={startDate && dayjs(startDate)}
                      className="datePicker"
                      sx={{ maxWidth: "100% !important" }}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Plan End Date
              </Typography>
              {/* <TextField
                className={!editable && "disabled-inputField"}
                disabled={!editable}
                type="text"
                placeholder="Select Date"
                name="plan_end_date"
                value={fields?.plan_end_date}
                onChange={handleInputChange}
              /> */}
              <Box
                sx={{
                  margin: "0 !important",
                }}
                className="selectiondiv-box"
              >
                <Box
                  sx={{
                    padding: "0 !important",
                    marginRight: 0,
                  }}
                  className="fullWidth selectionDiv"
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      onChange={handleEnd}
                      disabled={!editable}
                      value={endDate && dayjs(endDate)}
                      className="datePicker"
                      sx={{ maxWidth: "100% !important" }}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Referral Code
              </Typography>
              <TextField
                className={!editable && "disabled-inputField"}
                disabled={!editable}
                type="text"
                placeholder="Enter Referral Code"
                name="refer code"
                value={fields?.["refer code"]}
                onChange={handleInputChange}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                OTP
              </Typography>
              <TextField
                className={!editable && "disabled-inputField"}
                disabled={!editable}
                type="text"
                placeholder="Enter otp"
                name="otp"
                value={fields?.otp}
                onChange={handleInputChange}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Is Deleted
              </Typography>
              <TextField
                className={!editable && "disabled-inputField"}
                disabled={!editable}
                type="text"
                placeholder="Is Deleted"
                name="is_active"
                value={
                  fields?.is_active === true
                    ? "0"
                    : !fields?.is_active === false
                    ? "1"
                    : ""
                }
                onChange={handleInputChange}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Is Loggedin
              </Typography>
              <TextField
                className={!editable && "disabled-inputField"}
                disabled={!editable}
                type="text"
                placeholder="Is Loggedin"
                name="is_loggedin"
                value={fields?.is_loggedin}
                onChange={handleInputChange}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Broker Login
              </Typography>
              <TextField
                className={!editable && "disabled-inputField"}
                disabled={!editable}
                type="text"
                placeholder="Broker Portal Login"
                name="brokerlogin"
                value={fields?.brokerlogin}
                onChange={handleInputChange}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Token
              </Typography>
              <TextField
                className={!editable && "disabled-inputField"}
                disabled={!editable}
                type="text"
                placeholder="Enter Token"
                name="token"
                value={fields?.token}
                onChange={handleInputChange}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Register at
              </Typography>
              <TextField
                className={"disabled-inputField"}
                disabled
                type="text"
                placeholder="Enter register date"
                name="register_at"
                value={fields?.register_at}
                onChange={handleInputChange}
              />
            </Box>
          </Grid>
        </Grid>
        {editable && (
          <Box
            className="formBox"
            sx={{ marginTop: "2rem", border: "none !important" }}
          >
            <Button className="formSolid-btn" onClick={submitForm}>
              Submit{" "}
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
}
