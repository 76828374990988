import React, { useState } from "react";
import {
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export default function Autoselectsrp(props) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box className="formBox" sx={{ border: "none !important" }}>
        <Box className="formItems">
          <Box className="selectionDiv brokerDrp">
            <Box
              className="dropdown-ap"
              sx={{
                "& > div.MuiAutocomplete-root > div.MuiFormControl-root > div.MuiInputBase-root":
                  { padding: "0 !important", border: "none !important" },
              }}
            >
              <Autocomplete
                freeSolo
                open={open}
                className="dropdown"
                onOpen={() => {
                  if (props.selectedValue) {
                    setOpen(true);
                  }
                }}
                onClose={handleClose}
                inputValue={props?.selectedValue}
                onChange={(e, newValue) => {
                  props.handleSelectAlert(newValue);
                }}
                onInputChange={(e, value, reason) => {
                  if (e) props.handleAlertSearch(value);
                  if (!value) {
                    setOpen(false);
                  }
                }}
                options={props?.exchanges}
                getOptionLabel={(option) =>
                  props?.segment == "nse" ? option.symbol : option
                }
                renderInput={(params, option) => {
                  return (
                    <>
                      <Box className="MultieValue">
                        <TextField
                          placeholder="Scrip"
                          value={props?.selectedValue}
                          {...params}
                          sx={{
                            "&.MuiFormControl-root > .MuiInputBase-root > input.MuiInputBase-input":
                              {
                                border: "none !important",
                              },
                          }}
                        />
                        {/* <Box className="autoComplete-box">
                          {props?.selectedAlert}
                        </Box> */}
                      </Box>
                    </>
                  );
                }}
                renderOption={(propsData, option) => {
                  return (
                    <Box {...propsData} className="searchSelect">
                      <Box className="exchangeName">
                        <Typography component="h4">
                          {props?.segment == "nse" ? option.symbol : option}
                        </Typography>
                        <Typography component="p">
                          {props?.segment == "nse" ? option.symbol : option}
                        </Typography>
                      </Box>
                    </Box>
                  );
                }}
                sx={{
                  border: "none !important ",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
