import * as actionTypes from "../actionTypes";

const initState = {
  loading: false,
  authProfile: [],
  error: "",
  userTutorial: [],
  adminTutorial: [],
  refreCodes: [],
  symbolToken: [],
  notifications: [],
  copytrades: [],
  symbolList: [],
  serviceCharges: {},
  tradingKeyData: {},
  cronData: [],
  smsTextAlerts: [],
  userBrokerDetails: {},
};

const store = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.OTP_SENT_INIT:
      return {
        ...state,
      };
    case actionTypes.OTP_SENT_SUCCESS:
      return {
        ...state,
        error: "",
      };
    case actionTypes.OTP_SENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.OTP_VERIFY_INIT:
      return {
        ...state,
      };
    case actionTypes.OTP_VERIFY_SUCCESS:
      return {
        ...state,
        error: "",
      };
    case actionTypes.OTP_VERIFY_FAIL:
      return {
        ...state,
        error: action?.payload,
      };
    case actionTypes.GET_SYMBOL_LIST_SUCCESS:
      return {
        ...state,
        symbolList: action?.payload,
      };
    case actionTypes.AUTH_SIGNUP_INIT:
      return {
        ...state,
      };
    case actionTypes.AUTH_SIGNUP_SUCCESS:
      return {
        ...state,
        error: "",
      };
    case actionTypes.AUTH_SIGNUP_FAIL:
      return {
        ...state,
        error: action?.payload,
      };
    case actionTypes.AUTH_PROFILE_INIT:
      return {
        ...state,
      };
    case actionTypes.AUTH_PROFILE_SUCCESS:
      return {
        ...state,
        authProfile: action.payload,
      };
    case actionTypes.AUTH_PROFILE_FAIL:
      return {
        ...state,
        error: action?.payload,
      };
    case actionTypes.EDIT_PROFILE_INIT:
      return {
        ...state,
      };
    case actionTypes.EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        editProfile: action.payload,
      };
    case actionTypes.EDIT_PROFILE_FAIL:
      return {
        ...state,
        error: action?.payload,
      };
    case actionTypes.GET_ADMIN_TUTORIAL_INIT:
      return {
        ...state,
      };
    case actionTypes.GET_ADMIN_TUTORIAL_SUCCESS:
      return {
        ...state,
        adminTutorial: action.payload,
      };
    case actionTypes.GET_ADMIN_TUTORIAL_FAIL:
      return {
        ...state,
        error: action?.payload,
      };
    case actionTypes.GET_USER_TUTORIAL_INIT:
      return {
        ...state,
      };
    case actionTypes.GET_USER_TUTORIAL_SUCCESS:
      return {
        ...state,
        userTutorial: action.payload,
      };
    case actionTypes.GET_USER_TUTORIAL_FAIL:
      return {
        ...state,
        error: action?.payload,
      };
    case actionTypes.GET_REFRECODE_INIT:
      return {
        ...state,
      };
    case actionTypes.GET_REFRECODE_SUCCESS:
      return {
        ...state,
        refreCodes: action.payload,
      };
    case actionTypes.GET_USER_VENDOR_SUCCESS:
      return {
        ...state,
        userVendors: action.payload,
      };
    case actionTypes.GET_REFRECODE_FAIL:
      return {
        ...state,
        error: action?.payload,
      };
    case actionTypes.GET_SYMBOL_TOKEN_SUCCESS:
      return {
        ...state,
        symbolToken: action.payload,
      };
    case actionTypes.GET_SYMBOL_TOKEN_FAIL:
      return {
        ...state,
        error: action?.payload,
      };
    case actionTypes.GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: action?.payload,
      };
    case actionTypes.GET_SEGMENT_COPY_TRADE_SUCCESS:
      return {
        ...state,
        copytrades: action?.payload,
      };
    case actionTypes.GET_SERVICE_CHARGES_SUCCESS:
      return {
        ...state,
        serviceCharges: action?.payload,
      };
    case actionTypes.GENERATE_TRADING_KEY_SUCCESS:
      return {
        ...state,
        tradingKeyData: action?.payload,
      };
    case actionTypes.GET_CRON_SUCCESS:
      return {
        ...state,
        cronData: action?.payload,
      };
    case actionTypes.GET_SMS_TEXT_ALERT_SUCCESS:
      return {
        ...state,
        smsTextAlerts: action?.payload,
      };
    case actionTypes.GET_BROKER_SUCCESS:
      return {
        ...state,
        userBrokerDetails: action?.payload,
      };
    case actionTypes.BROKER_NOT_EXIST:
      return {
        ...state,
        userBrokerDetails: action?.error,
      };
    default:
      return state;
  }
};

export default store;
