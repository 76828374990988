import * as actionTypes from "../actionTypes";

const initState = {
  userstratergy: [],
  stratergy: [],
  error: "",
  strategyCode: {},
  pnl: {},
  strategies: [],
  segmentdata: [],
  symboldata: [],
  singlealldata: [],
  sendtradesignal: [],
};

const store = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.GET_USERADD_STRATERGY_INIT:
      return {
        ...state,
        error: "",
      };

    case actionTypes.GET_USERADD_STRATERGY_SUCCESS:
      return {
        ...state,
        userstratergy: action.payload,
      };

    case actionTypes.GET_STRATEGIES_SUCCESS:
      return {
        ...state,
        strategies: action.payload,
      };

    case actionTypes.GET_SEGMENTDATA_SUCCESS:
      return {
        ...state,
        segmentdata: action.payload,
      };

    case actionTypes.GET_SYMBOLDATA_SUCCESS:
      return {
        ...state,
        symboldata: action.payload,
      };

    case actionTypes.GET_SIGNALALLDATA_SUCCESS:
      return {
        ...state,
        singlealldata: action.payload,
      };

    case actionTypes.GET_SENDTRADESIGNAL_SUCCESS:
      return {
        ...state,
        sendtradesignal: action.payload,
      };

    case actionTypes.GET_USERADD_STRATERGY_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.GET_STRATERGY_INIT:
      return {
        ...state,
        error: "",
      };
    case actionTypes.GET_STRATERGY_SUCCESS:
      return {
        ...state,
        stratergy: action.payload,
      };
    case actionTypes.GET_STRATERGY_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.GET_STRATEGY_CODE_SUCCESS:
      return {
        ...state,
        strategyCode: action.payload,
      };
    case actionTypes.GET_PNL_POSITIONS_SUCCESS:
      return {
        ...state,
        pnl: action.payload,
      };
    case actionTypes.UPDATE_STRATEGY:
      const userstratergy = state?.userstratergy;
      const payload = action?.payload;

      const index = userstratergy.findIndex((x) => x.id === payload?.id);

      // let data = userstratergy?.filter((e) => e?.id === payload?.id)?.[0];
      // data?.[payload?.name] === payload?.value;

      let data = userstratergy?.filter((e) => e?.id === payload?.id)?.[0];
      if (data) {
        data[payload.name] = payload.value;
      }

      userstratergy.splice(index, 1, data);

      return {
        ...state,
        userstratergy: userstratergy,
      };

    default:
      return state;
  }
};

export default store;
