import React from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Bussinesreport from "./Bussinesreport/Bussinesreport";
import Vendorrefrel from "./Vendorreferal/Vendorreferal";
import Vendorreport from "./Vendorreport/Vendorreport";
import Vendorright from "./Vendorrights/Vendorrights";
import Vendorbroker from "./Vendorbroker/Vendorbroker";
import Vendorviewform from "./Vendorview/Vendorviewform";
import Paymentreport from "./Paymentreport/Paymentreport";
import Userplreport from "../Others/Userplrepoert/Userplrepoert";
import { useSelector } from "react-redux";

export default function Partnerreport() {
  // admin tab
  const [value, setValue] = React.useState("admintab1");
  const handleChange = (e, newValue = "string") => {
    setValue(newValue);
  };

  const user = useSelector((state) => state?.Auth?.authProfile);

  return (
    <Box className="tabs">
      <TabContext value={value}>
        <TabList className="main-tab" onChange={handleChange}>
          <Tab label="Business Report" value={"admintab1"} />
          <Tab label="Vendor Refrel" value={"admintab2"} />
          {/* <Tab label="Vendor Report" value={"admintab3"} /> */}
          {/* {user?.[0]?.is_staff && (
            <Tab label="Vendor Right" value={"admintab4"} />
          )} */}
          {/* <Tab label="Vendor Broker" value={"admintab5"} /> */}
          <Tab label="Vendor View" value={"admintab6"} />
          <Tab label="Payment Report" value={"admintab7"} />
          <Tab label="User p&l report" value={"admintab8"} />
        </TabList>

        <Box className="tabBox" border={"none !important"}>
          <TabPanel value={"admintab1"} sx={{ padding: 0 }}>
            <Bussinesreport />
          </TabPanel>
          <TabPanel value={"admintab2"} sx={{ padding: 0 }}>
            <Vendorrefrel />
          </TabPanel>
          {/* <TabPanel value={"admintab3"} sx={{ padding: 0 }}><Vendorreport /></TabPanel> */}
          <TabPanel value={"admintab4"} sx={{ padding: 0 }}>
            <Vendorright />
          </TabPanel>
          {/* <TabPanel value={"admintab5"} sx={{ padding: 0 }}><Vendorbroker /></TabPanel> */}
          <TabPanel value={"admintab6"} sx={{ padding: 0 }}>
            <Vendorviewform />
          </TabPanel>
          <TabPanel value={"admintab7"} sx={{ padding: 0 }}>
            <Paymentreport />
          </TabPanel>
          <TabPanel value={"admintab8"} sx={{ padding: 0 }}>
            <Userplreport />
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
}
