import { Box, Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import "./Broker.scss";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import EventIcon from "@mui/icons-material/Event";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import dayjs from "dayjs";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import { zebullValidation } from "../../../validation/broker";
import {
  BrokerStatus,
  zebullBroker,
} from "../../../redux/actions/brokerAction";

const Zebull = (props) => {
  const inputValue = { uid: "", password: "", dob: "", appkey: "" };
  const [fields, setFields] = useState(inputValue);
  const [isSubmit, setIsSubmit] = useState(false);
  const [seeOtp, setSeeOtp] = useState(false);
  const [Error, setError] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const brokerStatus = useSelector((state) => state.Broker.brokerstatus);

  const dateField = {
    width: {
      xs: "100% !important",
      md: "100% !important",
    },
    border: "1px solid #bcbcbc",
    borderRadius: {
      xs: "3px",
    },
  };

  //form input
  const handleChange = (event) => {
    let keyName = event.target.name;
    let keyValue = event.target.value;
    if (keyName == "uid") {
      keyValue = keyValue.toUpperCase();
    }
    setFields((prevState) => ({
      ...prevState,
      [keyName]: keyValue,
    }));
    if (isSubmit) {
      setError(zebullValidation({ ...fields, [keyName]: keyValue }));
    }
  };

  const brokerStatus = useSelector((state) => state.Broker.brokerstatus);

  useEffect(() => {
    dispatch(BrokerStatus());
  }, []);

  useEffect(() => {
    if (brokerStatus?.length > 0) {
      if (brokerStatus[0]?.appkey) {
        setFields({
          ...fields,
          uid: brokerStatus[0].uid,
          appkey: brokerStatus[0]?.appkey,
          dob: brokerStatus[0]?.dob,
        });
      }
    }
  }, [brokerStatus]);

  //form input date
  const handleDateChange = (dateString) => {
    setFields({
      ...fields,
      dob: dayjs(dateString, "YYYY-MM-DD").format("YYYY-MM-DD"),
    });
    if (isSubmit) {
      setError(
        zebullValidation({
          ...fields,
          dob: dayjs(dateString, "YYYY-MM-DD").format("YYYY-MM-DD"),
        })
      );
    }
  };

  // dispatch data
  const handleSubmit = () => {
    setIsSubmit(true);
    setError(zebullValidation(fields));
    let error = zebullValidation(fields);
    if (Object.keys(error) == 0) {
      dispatch(zebullBroker({ ...fields, brokername: "zebull" }, navigate));
    }
  };

  const inputBorder = {
    borderRadius: "5px",
    border: "1px solid #bcbcbc",
  };

  //   useEffect(() => {
  //     dispatch(BrokerStatus());
  //   }, []);

  // useEffect(() => {
  //     if (brokerStatus?.length > 0) {
  //         if (brokerStatus[0]?.appkey) {
  //             setFields({
  //                 ...fields,
  //                 uid: brokerStatus[0]?.uid,
  //                 appkey: brokerStatus[0]?.appkey,
  //                 dob: brokerStatus[0]?.dob,
  //             });
  //         }
  //     }
  // }, [brokerStatus]);

  return (
    <>
      <Box className="border">
        <Box>
          <Grid item xs={12} lg={12}>
            <Box className="formBox">
              <Box
                className="formItems"
                /* sx={inputFiled} */ sx={{ marginBottom: 1.5 }}
              >
                <Typography component={"label"} className="label">
                  UID :
                </Typography>
                <TextField
                  placeholder="Enter Uid..."
                  className="inputFiled"
                  value={fields?.uid}
                  style={inputBorder}
                  name="uid"
                  id="uid"
                  onChange={handleChange}
                />
                {Error && <Box className="error">{Error.uid}</Box>}
              </Box>
              <Box
                className="formItems"
                /* sx={inputFiled} */ sx={{ marginBottom: 1.5 }}
              >
                <Typography component={"label"} className="label">
                  Password :
                </Typography>
                <Box className="resend_otp">
                  <TextField
                    placeholder="Enter Password..."
                    className="inputFiled"
                    style={inputBorder}
                    value={fields?.password}
                    name="password"
                    id="password"
                    type={seeOtp ? "text" : "password"}
                    onChange={handleChange}
                  />

                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    className="actions_otp"
                  >
                    <Button
                      onClick={() => {
                        setSeeOtp(!seeOtp);
                      }}
                      className="otp_viewHide"
                    >
                      {seeOtp ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityRoundedIcon />
                      )}
                    </Button>
                  </Box>
                </Box>
                {Error && <Box className="error">{Error.password}</Box>}
              </Box>
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Typography component={"label"} className="label">
                    DOB :
                  </Typography>
                  <MobileDatePicker
                    sx={dateField}
                    format="YYYY-MM-DD"
                    value={dayjs(fields.dob)}
                    onChange={handleDateChange}
                    slotProps={{
                      textField: {
                        error: false,
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <EventIcon />
                            </InputAdornment>
                          ),
                        },
                      },
                    }}
                  />
                  {Error && <Box className="error">{Error.dob}</Box>}
                </LocalizationProvider>
              </Box>
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  App Key :
                </Typography>
                <TextField
                  placeholder="Enter App Key..."
                  style={dateField}
                  value={fields?.appkey}
                  className="inputFiled"
                  name="appkey"
                  id="appkey"
                  onChange={handleChange}
                />
                {Error && <Box className="error">{Error.appkey}</Box>}
              </Box>

              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  className="btn solidButton fetchReport-btn"
                  sx={{ width: "100% !important" }}
                  onClick={handleSubmit}
                >
                  Login
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default Zebull;
