import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Dialog,
  DialogContent,
  Tooltip,
  Checkbox,
  DialogContentText,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  Tab,
  InputBase,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import close from "../../images/close.png";
import Table from "../Table/Table";
import "./Position.scss";
import RefreshIcon from "@mui/icons-material/Refresh";
import MessageIcon from "@mui/icons-material/Message";
import {
  cancelOrder,
  closeAllLiveOrder,
  closeLimit,
  closeLimitPaperTrade,
  closeOrder,
  closePaperOrder,
  copyPostionDetails,
  liveOrderDetails,
  liveTradeDetails,
  modifyOrder,
  paperOrderDetails,
  paperTradeDetails,
} from "../../redux/actions/positionAction";
import { useDispatch, useSelector } from "react-redux";
import { messageEvent, socket } from "../../hooks/useSocket";
import moment from "moment";
import { generatePopup } from "../../utils/popup";
import {
  cancelPaperLimitOrder,
  changepaperlimitorder,
} from "../../redux/actions/brokerAction";
import { socketOpen } from "../../redux/actions/commonActions";

// const down = (
//   <svg
//     width="10"
//     height="5"
//     viewBox="0 0 10 5"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       d="M8.93119 1.20379L6.83498 3.30389L5.55506 4.59274C5.01304 5.13575 4.13146 5.13575 3.58945 4.59274L0.206783 1.20379C-0.237274 0.758914 0.0827077 0 0.703081 0H4.36655H8.43489C9.0618 0 9.37525 0.758914 8.93119 1.20379Z"
//       fill="#FF231F"
//     />
//   </svg>
// );

// const up = (
//   <svg
//     width="10"
//     height="5"
//     viewBox="0 0 10 5"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       d="M8.93119 3.79621L6.83498 1.69611L5.55506 0.407262C5.01304 -0.135754 4.13146 -0.135754 3.58945 0.407262L0.206783 3.79621C-0.237274 4.24109 0.0827077 5 0.703081 5H4.36655H8.43489C9.0618 5 9.37525 4.24109 8.93119 3.79621Z"
//       fill="#008F75"
//     />
//   </svg>
// );

export default function Position(props) {
  const [open, setOpen] = React.useState(false);
  const [alignment, setAlignment] = React.useState("live");
  const [value, setValue] = React.useState("maintab1");
  const [open3, setOpen3] = React.useState(false);
  const [id, setId] = React.useState("");
  const [liveWs, setLiveWs] = React.useState(false);
  const [paperWs, setPaperWs] = React.useState(false);
  const [bsopen, setBsopen] = useState(false);
  const [pageno, setPageno] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [prdType, setPrdType] = useState("LIMIT");
  const [orderType, setOrderType] = useState("");
  const [prc, setPrc] = useState("");
  const [qty, setQty] = useState("");
  const [load, setLoad] = useState(false);
  const [load1, setLoad1] = useState(false);
  const [load2, setLoad2] = useState(false);
  const [load3, setLoad3] = useState(false);
  const [bsopen2, setBsopen2] = useState(false);
  const [prc2, setPrc2] = useState("");
  const [copytrdeId, setCopytredId] = useState("");
  const [copypostionId, setCopyPostionId] = useState("");
  const [position, setPosition] = useState({});
  const [trade, setTrade] = useState({});
  const edit = <Typography sx={{ fontSize: "1.4rem" }}>Edit</Typography>;
  const cancel = <Typography sx={{ fontSize: "1.4rem" }}>Cancel</Typography>;
  const squareOff = (
    <Typography sx={{ fontSize: "1.4rem" }}>square Off</Typography>
  );

  const dispatch = useDispatch();

  const handlePageChange = (e) => {
    setPageno(e);
  };
  // useEffect(() => {
  //   dispatch(copyPostionDetails());
  // }, []);

  const livePositions = useSelector((state) => {
    return state?.Position?.livePositions;
  });

  const paperPositions = useSelector((state) => {
    return state?.Position?.paperPositions;
  });

  useEffect(() => {
    if (alignment === "live") {
      if (livePositions.count) {
        setTotalCount(livePositions.count);
      }
    } else if (alignment === "paper") {
      if (paperPositions.count) {
        setTotalCount(paperPositions.count);
      }
    }
  }, [livePositions, paperPositions, alignment]);

  useEffect(() => {
    console.log("liveWs", liveWs);
    if (alignment === "live" && paperWs) {
      messageEvent(paperWs, "liveposition", dispatch, {
        remark: "disconnect",
      });
    } else if (alignment === "paper" && liveWs) {
      messageEvent(liveWs, "paperposition", dispatch, {
        remark: "disconnect",
      });
    }
  }, [alignment]);

  // useEffect(() => {
  //   setInterval(() => {
  //     console.log("alignment,liveWs, apperW :>> ", alignment, liveWs, paperWs);
  //     if (alignment === "paper" && paperWs) {
  //       messageEvent(paperWs, "paperposition", dispatch, {
  //         remark: "heartbeat",
  //         pageno: 1,
  //       });
  //     } else if (alignment === "live" && liveWs) {
  //       console.log("entered in live");
  //       messageEvent(liveWs, "liveposition", dispatch, {
  //         remark: "heartbeat",
  //         pageno: 1,
  //       });
  //     }
  //   }, 30000);
  // }, [alignment, liveWs, paperWs]);

  const handleClickOpen3 = () => {
    setOpen3(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const handleClickOpen = (type, Id) => {
    setId(Id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseOrder = () => {
    setLoad1(true);

    if (value === "maintab3") {
      if (alignment === "live") {
        dispatch(cancelOrder({ id: Number(trade?.id) })).then((res) => {
          setLoad1(false);
          if (res?.status === 200) {
            setOpen(false);
            dispatch(liveTradeDetails());
            generatePopup("success", res?.data?.message);
          } else {
            generatePopup("error", res?.data?.message);
          }
        });
      } else {
        const obj = {
          id: trade?.id,
        };
        dispatch(cancelPaperLimitOrder(obj)).then((res) => {
          setLoad1(false);
          if (res?.status === 200) {
            setOpen(false);
            dispatch(paperTradeDetails());
          } else {
            generatePopup("error", res?.data?.message);
          }
        });
      }
    } else {
      console.log("enter :>> ");
      if (alignment === "live") {
        dispatch(closeOrder({ id: Number(id) })).then((res) => {
          setLoad1(false);
          if (res?.status === 200) {
            setOpen(false);
          } else {
            generatePopup("error", res?.data?.message);
          }
        });
      } else {
        dispatch(closePaperOrder({ id: Number(id) })).then((res) => {
          setLoad1(false);
          if (res?.status === 200) {
            setOpen(false);
          } else {
            generatePopup("error", res?.data?.message);
          }
        });
      }
    }
    // }
  };

  const handleChangetab = (e, newValue = "string") => {
    setValue(newValue);
    setAlignment("live");
  };

  const handleChange = (e, newAlignment) => {
    if (newAlignment) {
      setAlignment(newAlignment);
    }
  };

  useEffect(() => {
    if (alignment === "paper") {
      let ws;
      const setupWebSocket = async () => {
        ws = await socket(
          "paperposition",
          process.env.REACT_APP_PAPER_CUSTOMER_WEBSOCKET_URL
        );
        setPaperWs(ws);
        if (ws) {
          dispatch(socketOpen({ name: "paperposition", socket: ws }));
          messageEvent(ws, "paperposition", dispatch, {
            remark: "heartbeat",
            pageno: 1,
          });
        }
      };
      setupWebSocket();
      window.onbeforeunload = async function (event) {
        return await ws?.send({ remark: "disconnect" });
      };

      return () => {
        if (ws && ws.readyState === WebSocket.OPEN) {
          ws?.send("disconnect");
        }
      };
    }
  }, [alignment]);

  useEffect(() => {
    if (alignment === "live") {
      let ws;
      const setupWebSocket = async () => {
        ws = await socket(
          "liveposition",
          process.env.REACT_APP_LIVE_CUSTOMER_WEBSOCKET_URL
        );
        setLiveWs(ws);
        if (ws) {
          dispatch(socketOpen({ name: "liveposition", socket: ws }));
          messageEvent(ws, "liveposition", dispatch, {
            remark: "heartbeat",
            pageno: 1,
          });
        }
      };

      setupWebSocket();
      window.onbeforeunload = async function (event) {
        return await ws?.send("disconnect");
      };

      return () => {
        if (ws && ws.readyState === WebSocket.OPEN) {
          ws?.send("disconnect");

          // closeSocket(ws, "liveposition");
        }
      };
    }
  }, [alignment]);

  useEffect(() => {
    if (alignment === "live") {
      if (liveWs) {
        messageEvent(liveWs, "liveposition", dispatch, {
          remark: "pageno",
          pageno: pageno,
        });
      }
    }
    if (alignment === "paper") {
      if (paperWs) {
        messageEvent(paperWs, "paperposition", dispatch, {
          remark: "pageno",
          pageno: pageno,
        });
      }
    }
  }, [pageno, liveWs, paperWs]);

  useEffect(() => {
    if (value === "maintab2" && alignment === "live") {
      dispatch(liveOrderDetails());
    } else if (value === "maintab2" && alignment === "paper") {
      dispatch(paperOrderDetails());
    } else if (value === "maintab3" && alignment === "live") {
      dispatch(liveTradeDetails());
    } else if (value === "maintab3" && alignment === "paper") {
      dispatch(paperTradeDetails());
    }
  }, [value, alignment]);

  const orderBookData = useSelector((state) => {
    return state.Position?.closePosition;
  });

  const orderBook = orderBookData
    ?.map((e) => {
      return { ...e, date: new Date(e?.date) };
    })
    .sort((a, b) => b.date - a.date);

  const closePositionData = useSelector((state) => {
    return state?.Position?.orderBookData;
  });

  const changelimitsOpen = (value) => {
    setTrade(value);
    setBsopen2(true);
    setPrc2(value?.price);
    setCopytredId(value?.id);
  };

  const buysellOpen = (value, e) => {
    setBsopen(true);
    setCopyPostionId(e?.id);
    setOrderType(e?.prctyp);
    setPosition(e);
    setPrc(e?.price);
    setQty(e?.quantity);
  };

  const modifyTrade = () => {
    setLoad2(true);
    const obj = {
      id: Number(copypostionId),
      price: Number(prc),
      prctyp: "LIMIT",
      side: position?.side === "BUY" ? "SELL" : "BUY",
    };

    if (alignment === "live") {
      dispatch(closeLimit(obj)).then((res) => {
        setLoad2(false);
        if (res) {
          setBsopen(false);
          setCopyPostionId("");
          setOrderType("");
          setPosition({});
          setPrc("");
          setQty("");
        }
      });
    } else {
      dispatch(closeLimitPaperTrade(obj)).then((res) => {
        if (res) {
          setBsopen(false);
          setCopyPostionId("");
          setOrderType("");
          setPosition({});
          setPrc("");
          setQty("");
        }
      });
    }
  };

  const SqaureOffAll = () => {
    setLoad3(true);
    dispatch(closeAllLiveOrder()).then((res) => {
      setLoad3(false);
      // console.log("res :>> ", res);
      if (res) {
        setOpen3(false);
      }
    });
  };

  // const handleCancelcopytrade = () => {
  //   dispatch(cancelcopyTrade({ id: Number(postionid) })).then((res) => {
  //     if (res?.status === 200) {
  //       // setOpen2(false);
  //       generatePopup("success", res?.data?.message);
  //       // dispatch(copyPostionDetails());
  //     } else {
  //       generatePopup("error", res?.data?.message);
  //     }
  //   });
  // };

  const handleSubmit = () => {
    setLoad(true);
    setBsopen2(false);
    if (alignment === "paper") {
      const obj = {
        id: copytrdeId,
        price: prc2,
      };
      dispatch(changepaperlimitorder(obj)).then((res) => {
        setLoad(false);
        if (res?.status === 200) {
          dispatch(paperTradeDetails());
        }
      });
    } else {
      const obj = {
        id: copytrdeId,
        prctyp: "LIMIT",
        prc: prc2,
        qty: Number(trade?.quantity),
        trp: 0,
      };
      dispatch(modifyOrder(obj)).then((res) => {
        setLoad(false);
        if (res?.status === 200) {
          generatePopup("success", res?.data?.message);
          dispatch(liveTradeDetails());
        }
      });
    }
  };

  // Open Position
  const col = [
    <Checkbox sx={{ "& .MuiSvgIcon-root": { fontSize: 24 }, padding: 0 }} />,
    "Symbol Name",
    "Strategy Name",
    "Exchange",
    "Trend",
    "PrdType",
    "OrderType",
    "Quantity",
    "Buy Price",
    "LTP",
    "P&L",
    "Date",
    "Action",
  ];

  let rows = [];
  if (value === "maintab1" && alignment === "live") {
    rows =
      livePositions?.length > 0
        ? livePositions?.map((e) => {
            return {
              check: (
                <Checkbox
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 24 }, padding: 0 }}
                />
              ),
              name: e?.symbol,
              strategy: e?.stratergy,
              exchange: e?.exchange,
              type: (
                <Typography
                  sx={{
                    color: e?.side === "BUY" ? "#24a959" : "red",
                    fontWeight: "bold",
                  }}
                >
                  {e?.side}
                </Typography>
              ),
              prdType: e?.prctyp,
              orderType: e?.product,
              quantity: e?.quantity,
              price: e?.price,
              ltp: e?.liveprice,
              profit: (
                <Typography
                  sx={{
                    color: Number(e?.profit) >= 0 ? "#24a959" : "red",
                    fontWeight: "bold",
                  }}
                >
                  {Number(e?.profit)?.toFixed(2)}
                </Typography>
              ),
              date: e?.date,

              action: (
                <Box className="tableActions">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="actionButton"
                  >
                    <Tooltip title={edit} arrow placement="top">
                      <Button
                        onClick={() => buysellOpen("buy", e)}
                        className="edit"
                        variant="text"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.26 3.59997L5.04997 12.29C4.73997 12.62 4.43997 13.27 4.37997 13.72L4.00997 16.96C3.87997 18.13 4.71997 18.93 5.87997 18.73L9.09997 18.18C9.54997 18.1 10.18 17.77 10.49 17.43L18.7 8.73997C20.12 7.23997 20.76 5.52997 18.55 3.43997C16.35 1.36997 14.68 2.09997 13.26 3.59997Z"
                            stroke="CurrentColor"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.89 5.05005C12.32 7.81005 14.56 9.92005 17.34 10.2"
                            stroke="CurrentColor"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M3 22H21"
                            stroke="CurrentColor"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Button>
                    </Tooltip>
                    <Tooltip title={squareOff} arrow placement="top">
                      <Button
                        className="del"
                        variant="text"
                        onClick={() => {
                          handleClickOpen("close", e?.id);
                        }}
                      >
                        <CloseIcon />
                      </Button>
                    </Tooltip>
                  </Box>
                </Box>
              ),
            };
          })
        : [];
  } else if (value === "maintab1" && alignment === "paper") {
    rows =
      paperPositions?.length > 0
        ? paperPositions?.map((e) => {
            return {
              check: (
                <Checkbox
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 24 }, padding: 0 }}
                />
              ),
              name: e?.symbol,
              strategy: e?.stratergy,
              exchange: e?.exchange,
              type: (
                <Typography
                  sx={{
                    color: e?.side === "BUY" ? "#24a959" : "red",
                    fontWeight: "bold",
                  }}
                >
                  {e?.side}
                </Typography>
              ),
              prdType: e?.prctyp,
              orderType: e?.product,
              quantity: e?.quantity,
              price: e?.price,
              ltp: e?.liveprice,
              profit: (
                <Typography
                  sx={{
                    color: Number(e?.profit) >= 0 ? "#24a959" : "red",
                    fontWeight: "bold",
                  }}
                >
                  {Number(e?.profit)?.toFixed(2)}
                </Typography>
              ),
              date: e?.date,
              action: (
                <Box className="tableActions">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="actionButton"
                  >
                    <Tooltip title={edit} arrow placement="top">
                      <Button
                        onClick={() => buysellOpen("buy", e)}
                        className="edit"
                        variant="text"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.26 3.59997L5.04997 12.29C4.73997 12.62 4.43997 13.27 4.37997 13.72L4.00997 16.96C3.87997 18.13 4.71997 18.93 5.87997 18.73L9.09997 18.18C9.54997 18.1 10.18 17.77 10.49 17.43L18.7 8.73997C20.12 7.23997 20.76 5.52997 18.55 3.43997C16.35 1.36997 14.68 2.09997 13.26 3.59997Z"
                            stroke="CurrentColor"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.89 5.05005C12.32 7.81005 14.56 9.92005 17.34 10.2"
                            stroke="CurrentColor"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M3 22H21"
                            stroke="CurrentColor"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Button>
                    </Tooltip>
                    <Tooltip title={squareOff} arrow placement="top">
                      <Button
                        className="del"
                        variant="text"
                        onClick={() => {
                          handleClickOpen("close", e?.id);
                        }}
                      >
                        <CloseIcon />
                      </Button>
                    </Tooltip>
                    {/* <Tooltip title={cancel} arrow placement="top">
            <Button
              className="del"
              variant="text"
              onClick={() => {
                handleClickOpen("cancel");
              }}
            >
              <DoDisturbIcon />
            </Button>
          </Tooltip> */}
                  </Box>
                </Box>
              ),
            };
          })
        : [];
  }

  const col2 = [
    "Symbol Name",
    "Strategy",
    "Exchange",
    "Buy Price",
    "Sell Price",
    "Buy Qty",
    "Sell QTY",
    "Trend",
    // "Owner",
    "OrderId",
    "Order Type",
    "PrdType",
    "P&L",
    "Date & Time",
    "Status",
    // "Action",
  ];

  const rows2 =
    closePositionData?.length > 0
      ? closePositionData?.map((e) => {
          return {
            name: e?.symbol,
            stratergy: e?.stratergy,
            exchange: e?.exchange,
            buyprice: Number(e?.buyprice)?.toFixed(2),
            sellprice: Number(e?.sellprice)?.toFixed(2),
            buyquantity: Math.trunc(e?.buyquantity),
            sellquantity: Math.trunc(e?.sellquantity),
            trend: (
              <Typography
                sx={{
                  color: e?.side === "BUY" ? "#24a959" : "red",
                  fontWeight: "bold",
                }}
              >
                {e?.side}
              </Typography>
            ),
            orderId: e?.orderid,
            // owner: e?.owner,
            product: e?.product,
            prdType: e?.prctyp,
            profit: (
              <Typography
                sx={{
                  color: Number(e?.profit) >= 0 ? "#24a959" : "red",
                  fontWeight: "bold",
                }}
              >
                {Number(e?.profit)?.toFixed(2)}
              </Typography>
            ),
            date: moment.parseZone(e?.date)?.format("DD-MM-YYYY HH:mm:ss"),
            status: e?.status,
          };
        })
      : [];

  const col3 = [
    // <Checkbox sx={{ "& .MuiSvgIcon-root": { fontSize: 24 }, padding: 0 }} />,
    "Symbol Name",
    "Exchange",
    "Trend",
    "Strategy",
    "Quantity",
    "Order Id",
    "Order Type",
    "Price",
    "PrdType",
    "Date & Time",
    "Status",
    "Reason",
    "Action",
  ];

  const rows3 =
    orderBook?.length > 0
      ? orderBook?.map((e) => {
          return {
            // check: (
            //   <Checkbox
            //     sx={{ "& .MuiSvgIcon-root": { fontSize: 24 }, padding: 0 }}
            //   />
            // ),
            name: e?.symbol,
            exchange: e?.exchange,
            side: (
              <Typography
                sx={{
                  color:
                    e?.side?.toUpperCase() === "B" ||
                    e?.side?.toUpperCase() === "BUY"
                      ? "#24a959"
                      : "red",
                  fontWeight: "bold",
                }}
              >
                {e?.side}
              </Typography>
            ),
            strategy: e?.stratergy,
            quantity: Math.trunc(e?.quantity),
            token: e?.orderid,
            product: e?.product,
            price: e?.price,
            priceType: e?.prctyp,
            date: moment(e?.date).parseZone()?.format("DD-MM-YYYY hh:mm:ss a"),
            status: (
              <Typography
                sx={{
                  color:
                    e?.status?.toUpperCase() === "COMPLETE" ? "#24a959" : "red",
                  // fontWeight: "bold",
                }}
              >
                {e?.status}
              </Typography>
            ),
            remark: (
              <Tooltip
                title={
                  <Typography sx={{ fontSize: "1.3rem" }}>
                    {e?.remark}
                  </Typography>
                }
                arrow
                placement="left"
              >
                <Button className="rejectMessage">
                  <MessageIcon sx={{ color: "#fff !important" }} />
                </Button>
              </Tooltip>
            ),
            action:
              e?.status?.toUpperCase() === "PENDING" ? (
                <Box className="tableActions">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="actionButton"
                  >
                    <Tooltip
                      onClick={() => {
                        changelimitsOpen(e);
                      }}
                      title={edit}
                      arrow
                      placement="top"
                    >
                      <Button className="edit" variant="text">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.26 3.59997L5.04997 12.29C4.73997 12.62 4.43997 13.27 4.37997 13.72L4.00997 16.96C3.87997 18.13 4.71997 18.93 5.87997 18.73L9.09997 18.18C9.54997 18.1 10.18 17.77 10.49 17.43L18.7 8.73997C20.12 7.23997 20.76 5.52997 18.55 3.43997C16.35 1.36997 14.68 2.09997 13.26 3.59997Z"
                            stroke="CurrentColor"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.89 5.05005C12.32 7.81005 14.56 9.92005 17.34 10.2"
                            stroke="CurrentColor"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M3 22H21"
                            stroke="CurrentColor"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Button>
                    </Tooltip>

                    <Tooltip
                      onClick={() => {
                        setOpen(true);
                        setTrade(e);
                      }}
                      title={cancel}
                      arrow
                      placement="top"
                    >
                      <Button className="del" variant="text">
                        <CloseIcon />
                      </Button>
                    </Tooltip>
                  </Box>
                </Box>
              ) : (
                <>-</>
              ),
          };
        })
      : [];

  const handleRefresh = () => {
    if (value === "maintab2" && alignment === "live") {
      dispatch(liveOrderDetails());
    } else if (value === "maintab2" && alignment === "paper") {
      dispatch(paperOrderDetails());
    } else if (value === "maintab3" && alignment === "live") {
      dispatch(liveTradeDetails());
    } else if (value === "maintab3" && alignment === "paper") {
      dispatch(paperTradeDetails());
    }
  };

  return (
    <>
      <Box className="tabs">
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12}>
            <TabContext value={value}>
              <Box
                sx={{
                  display: { xs: "block", lg: "flex" },
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <TabList className="main-tab" onChange={handleChangetab}>
                  <Tab label="Open Position" value="maintab1" />
                  <Tab label="Close Position" value="maintab2" />
                  <Tab label="Order Book" value="maintab3" />
                </TabList>
                {value !== "maintab1" && (
                  <Tooltip
                    arrow
                    placement="top"
                    title={
                      <Typography sx={{ fontSize: "1.4rem" }}>
                        Refresh{" "}
                      </Typography>
                    }
                  >
                    <Button
                      onClick={() => handleRefresh()}
                      className="collapse-btn"
                    >
                      <RefreshIcon />
                    </Button>
                  </Tooltip>
                )}
              </Box>

              <Box className={`tabelBox ${props.btnone}`}>
                {/* open position */}
                <TabPanel value="maintab1" sx={{ padding: 0 }}>
                  <Box
                    className="selectiondiv-box m-rb"
                    sx={{ display: props.opHeaderNone }}
                  >
                    {/* <Box className="selectionDiv searchFlex">
                      <Tablesearch
                        handlesearch={(event) => handlesearch(event)}
                      />
                    </Box> */}
                    <Button
                      className="squareOff-btn solidButton"
                      sx={{
                        width: "auto !important",
                        fontSize: "1.2rem",
                        marginRight: 1,
                        marginBottom: "1rem",
                      }}
                      onClick={handleClickOpen3}
                    >
                      Square Off All
                    </Button>
                    <Box
                      className="selectionDiv searchFlex"
                      sx={{
                        maxWidth: "auto !important",
                        width: "auto !important",
                      }}
                    >
                      {/* <Typography className="label" component={"label"}>
                        Select Trade Type
                      </Typography> */}
                      <ToggleButtonGroup
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        className="tradeType-toggle"
                      >
                        <ToggleButton value="paper">Paper</ToggleButton>
                        <ToggleButton value="live">Live</ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  </Box>
                  <Table
                    col={col}
                    rows={rows}
                    handlePageChange={handlePageChange}
                    pageno={pageno}
                    totalCount={totalCount}
                    type="openPosition"
                  />
                </TabPanel>
                {/* close position */}
                <TabPanel value="maintab2" sx={{ padding: 0 }}>
                  <Box
                    className="selectiondiv-box m-rb"
                    sx={{ display: props.opHeaderNone }}
                  >
                    {/* <Box className="selectionDiv searchFlex">
                      <Tablesearch
                        handlesearch={(event) => handlesearch(event)}
                      />
                    </Box> */}
                    <Box
                      className="selectionDiv searchFlex"
                      sx={{
                        maxWidth: "auto !important",
                        width: "auto !important",
                      }}
                    >
                      {/* <Typography className="label" component={"label"}>
                        Select Trade Type
                      </Typography> */}
                      <ToggleButtonGroup
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        className="tradeType-toggle"
                      >
                        <ToggleButton value="paper">Paper</ToggleButton>
                        <ToggleButton value="live">Live</ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  </Box>
                  <Table col={col2} rows={rows2} />
                </TabPanel>
                {/* order book */}
                <TabPanel value="maintab3" sx={{ padding: 0 }}>
                  <Box
                    className="selectiondiv-box m-rb"
                    sx={{ display: props.opHeaderNone }}
                  >
                    <Button
                      className="squareOff-btn solidButton"
                      sx={{
                        width: "auto !important",
                        fontSize: "1.2rem",
                        marginRight: 1,
                        marginBottom: "1rem",
                      }}
                    >
                      Cancel All
                    </Button>
                    {/* <Box className="selectionDiv searchFlex">
                      <Tablesearch />
                    </Box> */}
                    <Box
                      className="selectionDiv searchFlex"
                      sx={{
                        maxWidth: "auto !important",
                        width: "auto !important",
                      }}
                    >
                      {/* <Typography className="label" component={"label"}>
                        Select Trade Type
                      </Typography> */}
                      <ToggleButtonGroup
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        className="tradeType-toggle"
                      >
                        <ToggleButton value="paper">Paper</ToggleButton>
                        <ToggleButton value="live">Live</ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  </Box>
                  <Table col={col3} rows={rows3} />
                </TabPanel>
              </Box>
            </TabContext>
          </Grid>
        </Grid>
      </Box>

      {/* Sqaure off */}
      <Dialog
        open={open}
        onClose={handleClose}
        className="commonModal squareOff"
      >
        <Box className="modalHeader" sx={{ justifyContent: "end" }}>
          <Typography component={"h4"}>Cancel Order</Typography>
          <Button onClick={handleClose} className="closeModal">
            <img alt="close" src={close} />
          </Button>
        </Box>
        <DialogContent sx={{ padding: "0" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="alertIcons">
              <svg
                width="1052"
                height="1052"
                viewBox="0 0 1052 1052"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M558 334C558 316.3 543.7 302 526 302C508.3 302 494 316.3 494 334V590C494 607.7 508.3 622 526 622C543.7 622 558 607.7 558 590V334ZM526 750C536.609 750 546.783 745.786 554.284 738.284C561.786 730.783 566 720.609 566 710C566 699.391 561.786 689.217 554.284 681.716C546.783 674.214 536.609 670 526 670C515.391 670 505.217 674.214 497.716 681.716C490.214 689.217 486 699.391 486 710C486 720.609 490.214 730.783 497.716 738.284C505.217 745.786 515.391 750 526 750Z"
                  fill="#4987FE"
                />
                <circle
                  cx="526"
                  cy="526"
                  r="507"
                  stroke="#5086EE"
                  stroke-width="38"
                />
              </svg>
            </Box>
            <Typography
              component={"h4"}
              sx={{
                fontSize: "1.8rem",
                textAlign: "center",
                marginTop: "2rem",
              }}
              className="alertText"
            >
              Are You Sure You Want to Close Position !
            </Typography>

            <Box sx={{ display: { xs: "flex" }, marginTop: 2 }}>
              <Button
                onClick={handleClose}
                className="modal-btn btn-danger"
                sx={{ marginRight: "0.5rem" }}
              >
                Discard
              </Button>
              <Button
                disabled={load1}
                onClick={handleCloseOrder}
                className="modal-btn btn-primary"
                sx={{ marginLeft: "0.5rem" }}
              >
                Confirm
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* Cancel All */}
      {/* <Dialog
        open={open2}
        onClose={handleClose2}
        className="commonModal squareOff"
      >
        <Box className="modalHeader" sx={{ justifyContent: "end" }}>
          <Typography component={"h4"}>Cancel All Order</Typography>
          <Button onClick={handleClose2} className="closeModal">
            <img src={close} />
          </Button>
        </Box>
        <DialogContent sx={{ padding: "0" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="alertIcons">
              <svg
                width="1052"
                height="1052"
                viewBox="0 0 1052 1052"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M558 334C558 316.3 543.7 302 526 302C508.3 302 494 316.3 494 334V590C494 607.7 508.3 622 526 622C543.7 622 558 607.7 558 590V334ZM526 750C536.609 750 546.783 745.786 554.284 738.284C561.786 730.783 566 720.609 566 710C566 699.391 561.786 689.217 554.284 681.716C546.783 674.214 536.609 670 526 670C515.391 670 505.217 674.214 497.716 681.716C490.214 689.217 486 699.391 486 710C486 720.609 490.214 730.783 497.716 738.284C505.217 745.786 515.391 750 526 750Z"
                  fill="#4987FE"
                />
                <circle
                  cx="526"
                  cy="526"
                  r="507"
                  stroke="#5086EE"
                  stroke-width="38"
                />
              </svg>
            </Box>
            <Typography
              component={"h4"}
              sx={{
                fontSize: "1.8rem",
                textAlign: "center",
                marginTop: "2rem",
              }}
              className="alertText"
            >
              Are You Sure You Want to Cancel All order !
            </Typography>

            <Box sx={{ display: { xs: "flex" }, marginTop: 2 }}>
              <Button
                onClick={handleClose2}
                className="modal-btn btn-danger"
                sx={{ marginRight: "0.5rem" }}
              >
                Discard
              </Button>
              <Button
                onClick={handleCancelcopytrade}
                className="modal-btn btn-primary"
                sx={{ marginLeft: "0.5rem" }}
              >
                Confirm
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog> */}

      {/* Sqaure Off with Dropdown */}
      <Dialog
        open={open3}
        onClose={handleClose3}
        className="commonModal squareOff"
        fullWidth
      >
        <Box className="modalHeader">
          <Typography component={"h4"}>Square Off</Typography>
          <Button onClick={handleClose3} className="closeModal">
            <img alt="close" src={close} />
          </Button>
        </Box>
        <DialogContent sx={{ padding: "0" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="alertIcons">
              <svg
                width="1052"
                height="1052"
                viewBox="0 0 1052 1052"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M558 334C558 316.3 543.7 302 526 302C508.3 302 494 316.3 494 334V590C494 607.7 508.3 622 526 622C543.7 622 558 607.7 558 590V334ZM526 750C536.609 750 546.783 745.786 554.284 738.284C561.786 730.783 566 720.609 566 710C566 699.391 561.786 689.217 554.284 681.716C546.783 674.214 536.609 670 526 670C515.391 670 505.217 674.214 497.716 681.716C490.214 689.217 486 699.391 486 710C486 720.609 490.214 730.783 497.716 738.284C505.217 745.786 515.391 750 526 750Z"
                  fill="#4987FE"
                />
                <circle
                  cx="526"
                  cy="526"
                  r="507"
                  stroke="#5086EE"
                  stroke-width="38"
                />
              </svg>
            </Box>
            <Typography
              component={"h4"}
              sx={{
                fontSize: "1.8rem",
                textAlign: "center",
                marginTop: "2rem",
              }}
              className="alertText"
            >
              Are You Sure You Want to Close Position !
            </Typography>
            <Box className="formBox">
              <Box className="formItems" sx={{ marginTop: 2 }}>
                {/* <Typography component={'label'} className='label important'>Select Trade Type:</Typography> */}
                <Box className="selectionDiv">
                  {/* <FormControl className="dropdown-ap" sx={{ width: "100%" }}>
                    <Select
                      value={tradetypeshand}
                      onChange={tradetypesdrp}
                      className="dropdown"
                    >
                      {tradetypes.map((vals, index) => (
                        <MenuItem value={index === 0 ? " " : index} key={index}>
                          {vals}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: { xs: "flex" }, marginTop: 2 }}>
              <Button
                onClick={handleClose3}
                className="modal-btn btn-danger"
                sx={{ marginRight: "0.5rem" }}
              >
                Discard
              </Button>
              <Button
                disabled={load3}
                onClick={SqaureOffAll}
                className="modal-btn btn-primary"
                sx={{ marginLeft: "0.5rem" }}
              >
                Confirm
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={bsopen}
        onClose={() => {
          setBsopen(false);
        }}
        className={`commonModal buysellModal `}
      >
        <DialogContent sx={{ padding: "0 !important" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="tabs buysellModal-tabs">
              <Box
                className="tabBox"
                sx={{
                  border: "none !important",
                  padding: 1,
                  width: "auto !important",
                }}
              >
                <Box
                  className="formBox"
                  sx={{
                    border: "none",
                    display: "flex",
                    alignItems: "flex-start",
                    flexWrap: { xs: "wrap", md: "nowrap" },
                    marginTop: 1,
                  }}
                >
                  <Box className="formItems">
                    <Typography
                      component={"label"}
                      className="label"
                      sx={{ fontSize: "1.4rem !important" }}
                    >
                      Quantity
                    </Typography>
                    <Box className="inputFields space">
                      <InputBase
                        value={qty}
                        onChange={(e) => setQty(Number(e.target.value))}
                        placeholder="Quantity"
                      />
                    </Box>
                  </Box>
                  <Box className="formItems" sx={{ padding: "0 0.8rem" }}>
                    <Typography
                      component={"label"}
                      className="label"
                      sx={{ fontSize: "1.4rem !important" }}
                    >
                      Price
                    </Typography>
                    <Box className="inputFields space">
                      <InputBase
                        value={prc}
                        onChange={(e) => setPrc(Number(e.target.value))}
                        placeholder="Price"
                      />
                    </Box>

                    <Box className="formItems buysell-radio">
                      <Box className="inputFields space fullWidth">
                        <RadioGroup
                          row
                          value={prdType}
                          // onChange={(e) => set(e.target.value)}
                        >
                          <FormControlLabel
                            value="LIMIT"
                            sx={{ marginRight: "1rem" }}
                            control={<Radio />}
                            label="Limit"
                          />
                        </RadioGroup>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="formItems">
                    <Typography
                      component={"label"}
                      className="label"
                      sx={{ fontSize: "1.4rem !important" }}
                    >
                      Order Type
                    </Typography>
                    <Box
                      className="inputFields space fullWidth"
                      sx={{
                        "& > .selectionDiv": {
                          padding: "0 !important",
                          marginTop: "0 !important",
                          border: "none !important",
                        },
                      }}
                    >
                      <InputBase
                        value={orderType}
                        className="disabled-inputField"
                        // onChange={(e) => setQty(Number(e.target.value))}
                        placeholder=""
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className="buysell-footer">
              <Box className="margin">
                {/* <Typography component={'p'}>Margin ₹ 493.70 Charges ₹ 2.17</Typography> */}
                {/* <Button><RefreshIcon /></Button> */}
              </Box>

              <Box className="footer-btn">
                <Button
                  disabled={load2}
                  style={{ backgroundColor: "#2f80ed" }}
                  onClick={() => modifyTrade()}
                >
                  Save
                </Button>
                <Button
                  onClick={() => {
                    setBsopen(false);
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* change copytrade price */}
      <Dialog
        open={bsopen2}
        onClose={() => {
          setBsopen2(false);
        }}
        className={`commonModal buysellModal`}
        sx={{
          ".MuiPaper-elevation": {
            width: "25rem !important",
          },
        }}
      >
        <DialogContent sx={{ padding: "0 !important" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="tabs buysellModal-tabs">
              <Box
                className="tabBox"
                sx={{
                  border: "none !important",
                  padding: 1,
                  width: "auto !important",
                }}
              >
                <Box
                  className="formBox"
                  sx={{
                    border: "none",
                    display: "flex",
                    alignItems: "flex-start",
                    flexWrap: { xs: "wrap", md: "nowrap" },
                    marginTop: 1,
                  }}
                >
                  <Box className="formItems" sx={{ padding: "0 0.8rem" }}>
                    <Typography
                      component={"label"}
                      className="label"
                      sx={{ fontSize: "1.4rem !important" }}
                    >
                      Price
                    </Typography>
                    <Box className="inputFields space">
                      <InputBase
                        type="number"
                        value={prc2}
                        onChange={(e) => setPrc2(e.target.value)}
                        placeholder="Price"
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className="buysell-footer">
              <Box className="footer-btn">
                <Button
                  style={{ backgroundColor: "#2f80ed" }}
                  onClick={() => handleSubmit()}
                  disabled={load}
                >
                  Save
                </Button>
                <Button
                  onClick={() => {
                    setBsopen2(false);
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
