import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import "./Tutorial.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserTutorial } from "../../redux/actions/authActions";
import ErrorIcon from "@mui/icons-material/Error";

const tutorial = [
  { title: "How to do Sign up & Sign in DeMatade mobile apps." },
  {
    title: "How to do broker login in Alice blue Through DeMatade mobile apps.",
  },
  {
    title: "How to do broker login in Angel One  Through DeMatade mobile apps.",
  },
  { title: "How to do broker login in Swastika Through DeMatade mobile apps." },
  { title: "How to do broker login in Fyers Through DeMatade mobile apps." },
  {
    title:
      "How to do broker login in Motilal Oswal Through DeMatade mobile apps.",
  },
  {
    title:
      "How to do broker login in Finvasia ( Shoonya ) Through DeMatade mobile apps.",
  },
  { title: "How to do broker login in 5 Paisa Through DeMatade mobile apps." },
  { title: "How to do broker login in ZEBULL Through DeMatade mobile apps." },
  {
    title:
      "How to do broker login in ICICI DIRECT Through DeMatade mobile apps.",
  },
  { title: "How to do broker login in Zerodha Through DeMatade mobile apps." },
  {
    title:
      "How to do broker login in KOTAK SECURITIES through Dematade Algo Technology Solutions Private Limited.",
  },
];

export default function Tutorial() {
  const user = useSelector((state) => {
    return state?.Auth?.authProfile;
  });

  const dispatch = useDispatch();
  useEffect(() => {
    if (user?.length > 0) {
      const obj = {
        refrecode: user?.[0]?.refrecode,
      };
      dispatch(getUserTutorial(obj));
    }
  }, [user]);

  const userTutorial = useSelector((state) => {
    return state?.Auth?.userTutorial;
  });

  const data = userTutorial?.filter((item) => {
    return item?.upload_type === "video";
  });

  // "https://www.youtube.com/embed/UyW_j6G7BlE"

  const isUrlValid = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component={"h2"} className="title">
            Tutorial
          </Typography>
        </Grid>
      </Grid>

      {data.length > 0 ? (
        <Box className="tutorial-flex">
          {data?.map((tutorials, index) => (
            <Box className="tutorial-item" key={index}>
              {/* <a target="_blank" href={tutorials?.url}> */}
              {isUrlValid(tutorials?.url) ? (
                <iframe
                  src={tutorials?.url}
                  title={"none"}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen={true}
                ></iframe>
              ) : (
                <Box>
                  <ErrorIcon />
                  Video Not Found
                </Box>
              )}
              <Box className="tutorialBy">
                <Typography component={"p"} className="tutorial-title">
                  {tutorials.title}
                </Typography>
              </Box>
              {/* </a> */}
            </Box>
          ))}
        </Box>
      ) : (
        <Box className="tutorial-flex">
          <Typography component={"h2"} className="title">
            {" "}
            No Tutorial Found
          </Typography>
        </Box>
      )}
    </>
  );
}
