import React from "react";
import "./Broker.scss";
import { Box, Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BrokerStatus } from "../../../redux/actions/brokerAction";
import { FlattradeValidation } from "../../../validation/broker";

const Flattrade = () => {
  const inputFields = { appkey: "", secretkey: "", uid: "" };
  const [fields, setFields] = useState(inputFields);
  const [isSubmit, setIsSubmit] = useState(false);
  const [Error, setError] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputBorder = {
    borderRadius: "5px",
    border: "1px solid #bcbcbc",
  };

  const brokerStatus = useSelector((state) => state.Broker.brokerstatus);
  useEffect(() => {
    dispatch(BrokerStatus());
  }, []);
  useEffect(() => {
    if (brokerStatus?.length > 0) {
      if (brokerStatus[0]?.appkey) {
        setFields({
          ...fields,
          appKey: brokerStatus[0]?.appkey,
          secretkey: brokerStatus[0]?.secretkey,
          clientId: brokerStatus[0]?.clientId,
        });
      }
    }
  }, [brokerStatus]);

  const handleChange = (event) => {
    const keyName = event.target.name;
    const keyValue = event.target.value;
    setFields((prevState) => ({
      ...prevState,
      [keyName]: keyValue,
    }));
    if (isSubmit) {
      setError(FlattradeValidation({ ...fields, [keyName]: keyValue }));
    }
  };

  const handleSubmit = () => {
    setIsSubmit(true);
    setError(FlattradeValidation(fields));
    const error = FlattradeValidation(fields);
    if (Object.keys(error) == 0) {
      localStorage.setItem("AppKey", fields.appkey);
      localStorage.setItem("secretkey", fields.secretkey);
      localStorage.setItem("clientId", fields.clientId);
      localStorage.setItem("brokername", "flattrade");
      window.location.replace(
        `https://auth.flattrade.in/?app_key=${fields.appkey}`
      );
    }
  };

  //   useEffect(() => {
  //     if (brokerStatus?.length > 0) {
  //       if (brokerStatus[0]?.appkey) {
  //         setFields({
  //           ...fields,
  //           appkey: brokerStatus[0]?.appkey,
  //           secretkey: brokerStatus[0]?.secretkey,
  //           uid: brokerStatus[0]?.password,
  //         });
  //       }
  //     }
  //   }, [brokerStatus]);

  return (
    <>
      <div className="border">
        <Box>
          <Grid xs={12} lg={12}>
            <Box style={{ border: "none" }} className="formBox">
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  APP KEY :
                </Typography>
                <TextField
                  placeholder="Enter Access Key"
                  name="appkey"
                  className="inputFiled"
                  style={inputBorder}
                  onChange={handleChange}
                  value={fields?.appkey}
                />
                {Error ? <div className="error">{Error.appkey}</div> : ""}
              </Box>
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  SECRET KEY :
                </Typography>
                <TextField
                  placeholder="Enter Secret Key"
                  name="secretkey"
                  className="inputFiled"
                  style={inputBorder}
                  onChange={handleChange}
                  value={fields?.secretkey}
                />
                {Error ? <div className="error">{Error.secretkey}</div> : ""}
              </Box>
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  CLIENT ID :
                </Typography>
                <TextField
                  placeholder="Enter Client ID"
                  name="clientId"
                  className="inputFiled"
                  style={inputBorder}
                  onChange={handleChange}
                  value={fields?.clientId}
                />
                {Error ? <div className="error">{Error.clientId}</div> : ""}
              </Box>

              <Button
                className="btn solidButton fetchReport-btn"
                sx={{ width: "100% !important" }}
                onClick={handleSubmit}
              >
                Login
              </Button>
            </Box>
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default Flattrade;
