import { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState } from "react";
import { motilalValidation } from "../../../validation/broker";
import { useDispatch, useSelector } from "react-redux";
import { BrokerStatus } from "../../../redux/actions/brokerAction";

const MotilalOswal = () => {
  let inputFields = { uid: "" };
  const [fields, setFields] = useState(inputFields);
  const [isSubmit, setIsSubmit] = useState(false);
  const [Error, setError] = useState();
  const dispatch = useDispatch();
  const inputBorder = {
    borderRadius: "5px",
    border: "1px solid #bcbcbc",
  };
  const brokerStatus = useSelector((state) => state.Broker.brokerstatus);

  console.log("fields :>> ", fields);

  useEffect(() => {
    dispatch(BrokerStatus());
  }, []);

  useEffect(() => {
    if (brokerStatus?.length > 0) {
      if (brokerStatus[0]?.appkey) {
        setFields({
          ...fields,
          uid: brokerStatus[0]?.uid,
          appkey: brokerStatus[0]?.appkey,
        });
      }
    }
  }, [brokerStatus]);

  //form input
  const handleChange = (event) => {
    let keyName = event.target.name;
    let keyValue = event.target.value;
    setFields((prevState) => ({
      ...prevState,
      [keyName]: keyValue,
    }));
    if (isSubmit) {
      setError(motilalValidation({ ...fields, [keyName]: keyValue }));
    }
  };

  //dispatch data
  const handleSubmit = () => {
    console.log("fields :>> ", fields);
    setIsSubmit(true);
    setError(motilalValidation(fields));
    let error = motilalValidation(fields);
    if (Object.keys(error) == 0) {
      // localStorage.setItem("secretkey", fields.secretkey);
      localStorage.setItem("AppKey", fields.appkey);
      localStorage.setItem("uid", fields.uid);
      // localStorage.setItem("brokername", "motilalOswal");
      window.location.replace(
        `https://invest.motilaloswal.com/OpenAPI/Login.aspx?apikey=${fields?.appkey}`
      );
    }
  };
  return (
    <>
      <Box className="border">
        <Box>
          <Grid xs={12} lg={12}>
            <Box className="formBox">
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  USER ID :
                </Typography>
                <TextField
                  placeholder="Enter User Id.."
                  className="inputFiled"
                  style={inputBorder}
                  name="uid"
                  onChange={handleChange}
                  value={fields?.uid?.toUpperCase()}
                />
                {Error ? <Box className="error">{Error.uid}</Box> : ""}
              </Box>
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  API KEY :
                </Typography>
                <TextField
                  placeholder="Enter Api Key.."
                  className="inputFiled"
                  style={inputBorder}
                  name="appkey"
                  onChange={handleChange}
                  value={fields?.appkey?.toUpperCase()}
                />
                {Error ? <Box className="error">{Error.appkey}</Box> : ""}
              </Box>

              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  className="btn solidButton fetchReport-btn"
                  sx={{ width: "100% !important" }}
                  onClick={handleSubmit}
                >
                  Login
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default MotilalOswal;
