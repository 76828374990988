import React, { useState } from 'react';
import {
  Typography,
  ToggleButton,
  TextField,
  ToggleButtonGroup,
  Grid,
  Box,
  Tab,
  Button,
  Autocomplete,
  FormControl,
  Select,
  MenuItem
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

export default function Position_entry() {
  const [holding, setholding] = React.useState('Buy');
  const [value, setValue] = React.useState('admintab1');
  const [order, setorder] = React.useState(' ');

  const ordertype = [
    '00:00',
    '00: 15',
    '00: 30',
    '00: 35',
    '00: 40',
    '00: 45',
    '00: 50',
    '00: 55',
    '01: 55',
    '02: 55',
    '03: 55',
    '04: 55',
    '05: 55',
    '06: 55',
    '07: 55',
    '08: 55',
    '09: 55',
    '10: 55',
    '11: 55',
    '12: 55',
    '13: 55',
    '14: 55',
    '15: 55',
    '16: 55',
    '17: 55',
    '18: 55',
    '19: 55',
    '20: 55',
    '21: 55',
    '22: 55',
    '23: 55',
  ];
  const mathFunction = ['Period Min', 'Multitime frame completed', 'Min', 'Max', 'Symbol', 'Floor', 'Ceil', 'Abs', 'Multitime frame', 'Period Max']
  const indicator = ['Phase Power Mobving Average', 'Money Flow Index', 'Vwap Moving Index', 'UIcer', 'Know Sure Think', 'DI, Minus Directional Index', 'True Strength Indictor', 'Normalised average true range']

  const handleChange = (e, newValue = 'string') => {
    setValue(newValue);
  };

  const holdingchange = (e, newAlignment) => {
    setholding(newAlignment);
  };


  const ordertypedrp = (event) => {
    setorder(event.target.value);
  };
  return (
    <Box className='strategy_box'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className='selectionDiv order_type_switch'>
            <Typography className='label' component={'label'}>Position I would take</Typography>
            <ToggleButtonGroup
              value={holding}
              exclusive
              onChange={holdingchange}
              className='tradeType-toggle'
            >
              <ToggleButton className='buy' value="Buy">Buy</ToggleButton>
              <ToggleButton className='sell' value="Sell">Sell</ToggleButton>
              <ToggleButton className='both' value="Both">Both</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography className='label' component={'label'}>Position I would take</Typography>
          <Box className='tabs' sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
            <TabContext value={value}>
              <Box className='main-tab'>
                <TabList className='sub-tabs' onChange={handleChange}>
                  <Tab label="All" value={"admintab1"} />
                  <Tab label="Math Functions" value={"admintab2"} />
                  <Tab label="Indicators" value={"admintab3"} />
                </TabList>
              </Box>

              <Box className='tabBox' sx={{ width: 'auto !important', border: 'none !important' }} >
                <TabPanel value={"admintab1"} sx={{ padding: 0 }}>
                  <Box className='formBox' sx={{ border: 'none !important', width: { xs: '100%', md: '30rem' } }}>
                    <Box className='formItems'>
                      <Box className='selectionDiv brokerDrp'>
                        <Box className='dropdown-ap'>
                          <Autocomplete
                            defaultValue={''}
                            className='dropdown'
                            renderInput={(params) => <TextField placeholder='What would you like to add?' {...params} />}
                            options={mathFunction}
                            selectOnFocus={false}
                            sx={{ '& > .MuiFormControl-root > .MuiTextField -root': { padding: '0.5rem !important' } }} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </TabPanel>
                <TabPanel value={"admintab2"} sx={{ padding: 0 }}>
                  <Box className='formBox' sx={{ border: 'none !important', width: { xs: '100%', md: '30rem' } }}>
                    <Box className='formItems'>
                      <Box className='selectionDiv brokerDrp'>
                        <Box className='dropdown-ap'>
                          <Autocomplete
                            defaultValue={''}
                            className='dropdown'
                            renderInput={(params) => <TextField placeholder='What would you like to add?' {...params} />}
                            options={mathFunction}
                            selectOnFocus={false}
                            sx={{ '& > .MuiFormControl-root > .MuiTextField -root': { padding: '0.5rem !important' } }} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </TabPanel>
                <TabPanel value={"admintab3"} sx={{ padding: 0 }}>
                  <Box className='formBox' sx={{ border: 'none !important', width: { xs: '100%', md: '30rem' } }}>
                    <Box className='formItems'>
                      <Box className='selectionDiv brokerDrp'>
                        <Box className='dropdown-ap'>
                          <Autocomplete
                            defaultValue={''}
                            className='dropdown'
                            renderInput={(params) => <TextField placeholder='What would you like to add?' {...params} />}
                            options={indicator}
                            selectOnFocus={false}
                            sx={{ '& > .MuiFormControl-root > .MuiTextField -root': { padding: '0.5rem !important' } }} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </TabPanel>
              </Box>
            </TabContext>
          </Box>
        </Grid>


        <Grid item xs={6}>
          <Box className='entry_condition_box bgsb'>
            <Box className='entry_condition_item'>
              <Box className='entrys_condition'>Multitime frame completed()</Box>
            </Box>

            <Box className='entry_condition_actions'>
              <Button className='btn_default'><VisibilityOffIcon />Disable</Button>
              <Button className='btn_default'><FileCopyIcon />Copy</Button>
              <Button className='btn_delete'><DeleteForeverIcon />Remove Condition</Button>
            </Box>
          </Box>
        </Grid>


        <Grid item xs={6}>
          <Box className='bgsb'>
            <Typography className='title_positions' component={'h4'}>Entry Time</Typography>
            <Box className='selectiondiv-box' sx={{ justifyContent: 'left' }}>
              <Box className='selectionDiv searchFlex'>
                <Typography component={'label'} className='label'>Start Time: </Typography>
                {/* <Box className='formBox'>
                  <Box className='formItems'>
                    <Box className='selectionDiv' >
                      <FormControl className='dropdown-ap' sx={{ maxWidth: '16rem !important', width: '16rem !important' }}>
                        <Select value={order} onChange={ordertypedrp} className='dropdown icon_set' sx={{ height: '100% !important' }}>
                          {ordertype.map((vals, index) => (
                            <MenuItem value={index === 0 ? ' ' : index} key={index}>{vals}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </Box> */}

                <Box className='datePicker'>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={['MobileTimePicker', 'MobileTimePicker', 'MobileTimePicker']}
                    >
                      <DemoItem>
                        <TimePicker views={['hours', 'minutes']} />
                      </DemoItem>

                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
              </Box>

              <Box className='selectionDiv' searchFlex>
                <Typography component={'label'} className='label'>End Time: </Typography>
                <Box className='datePicker'>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={['MobileTimePicker', 'MobileTimePicker', 'MobileTimePicker']}
                    >
                      <DemoItem>
                        <TimePicker views={['hours', 'minutes']} />
                      </DemoItem>

                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box >
  )
}
