import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Box,
  Select,
  FormControl,
  MenuItem,
  Checkbox,
  ListItemText,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "./Createstrategy.scss";
import dayjs from "dayjs";
import { useEffect } from "react";
import { TimePicker } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import {
  adminAddStrategy,
  getStrategyCode,
} from "../../../../redux/actions/stratergyAction";
import close from "../../../../images/close.png";
import { getSegment } from "../../../../redux/actions/positionAction";
import { getReferCode } from "../../../../redux/actions/authActions";
import { generatePopup } from "../../../../utils/popup";

const tagList = [
  "NFO",
  "Intraday",
  "EarnTheta",
  "Directional",
  "Bullish",
  "Bearish",
  "Trendfollowing",
  "Momentum",
  "EventBased",
];
// const segementList = [
//   "NSECASH",
//   "BANKNIFTY OPTION",
//   "NIFTY OPTION",
//   "FINNIFTY OPTION",
//   "CURRENCY OPTION",
//   "STOCK OPTION",
//   "COMMODITY OPTION",
//   "MIDNIFTY OPTION",
//   "BSEINDEX OPTION",
//   "STOCK FUT",
//   "COMMODITY FUT",
//   "CURRENCY FUT",
//   "BANKNIFTY FUT",
//   "NIFTY FUT",
//   "FINNIFTY FUT",
//   "BANKNIFTY OPTION SELLING",
//   "NIFTY OPTION SELLING",
// ];

export default function Creatastrategy() {
  const [tags, setTags] = useState([]);
  const [refreCodes, setRefreCodes] = useState([]);
  const [segment, setSegment] = useState([]);
  const [state, setState] = useState({});
  const [entryTime, setEntryTime] = useState(dayjs(new Date()).format("hh:mm"));
  const [endTime, setEndTime] = useState(dayjs(new Date()).format("hh:mm"));
  const [open, setOpen] = useState();
  const [display, setDisplay] = useState("Refercode");

  const handleClose = () => {
    setOpen(false);
    setDisplay("Everyone");
  };

  const dispatch = useDispatch();
  const handleCreate = () => {
    if (display === "Refercode" && refreCodes?.length === 0) {
      return generatePopup("error", "Please Select RefreCodes");
    }
    const obj = {
      stratergy_code: state?.stratergy_code,
      name: state?.name,
      full_name: state?.full_name,
      stratergy_description: state?.stratergy_description,
      tag: tags,
      segment: segment,
      captial_requirement: state?.captial_requirement,
      entry_time: entryTime,
      exit_time: endTime,
      target: state?.target,
      sl: state?.sl,
      display: display,
      referlist: refreCodes?.filter((e) => e !== "All"),
    };

    dispatch(adminAddStrategy(obj)).then((res) => {
      if (res) {
        setRefreCodes([]);
        setSegment([]);
        setTags([]);
        setState({});
        setEntryTime(dayjs(new Date())?.format("hh:mm"));
        setEndTime(dayjs(new Date())?.format("hh:mm"));
        setOpen(false);
        setDisplay("Refercode");
      }
    });
  };

  useEffect(() => {
    dispatch(getSegment());
  }, []);

  const segementList = useSelector((state) => state?.Position?.segmentList);

  const handleChangetag = (event) => {
    const {
      target: { value },
    } = event;
    setTags(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleRefreCodes = (event) => {
    const {
      target: { value },
    } = event;

    console.log("value", value);
    if (value?.includes("All")) {
      console.log("refreCodeList", refreCodeList);
      setRefreCodes(["All"].concat(refreCodeList));
    } else if (refreCodes?.includes("All") && !value?.includes("All")) {
      setRefreCodes([]);
    } else {
      setRefreCodes(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    }
  };

  const handleChangeSegement = (event) => {
    const {
      target: { value },
    } = event;
    setSegment(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    dispatch(getStrategyCode());
  }, []);

  const userData = useSelector((state) => state?.Auth?.authProfile?.[0]);
  const strategyCode = useSelector((state) => state?.Stratergy?.strategyCode);

  useEffect(() => {
    if (strategyCode?.shortcode) {
      setState({ ...state, ["stratergy_code"]: strategyCode?.shortcode });
    }
  }, [strategyCode]);

  useEffect(() => {
    dispatch(getReferCode());
  }, []);

  const refreCodeList = useSelector(
    (state) => state?.Auth?.refreCodes?.refrecode
  );

  return (
    <>
      <Box className="border-ap" sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className="save_deploy_box">
              <Typography component={"h4"}>Disclaimer</Typography>
              <Typography component={"p"}>
                Algo Edge Capital or I am not a SEBI registered investment or
                financial advisor. Don't deploy our strategies purely based on
                past performance only. We are not responsible for your profit or
                loss. Although this strategy is fully automated, you are advised
                to keep a track on your account to monitor any deviations or
                errors. As option selling involves market risks, Please consult
                your financial advisor before investing.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Strategy code
              </Typography>
              <TextField
                name="stratergy_code"
                value={state?.stratergy_code || ""}
                onChange={handleChange}
                placeholder="Strategy code"
                className="inputFiled"
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Strategy Display Name
              </Typography>
              <TextField
                name="name"
                value={state?.name || ""}
                onChange={handleChange}
                placeholder="Strategy Display Name"
                className="inputFiled"
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Strategy Display Full Name
              </Typography>
              <TextField
                name="full_name"
                value={state?.full_name || ""}
                onChange={handleChange}
                placeholder="Strategy Display Full Name"
                className="inputFiled"
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box
              className="selectionDiv  select_check"
              sx={{ marginLeft: 0, display: "block !important" }}
            >
              <Typography
                component={"label"}
                className="label"
                sx={{ marginBottom: "0.6rem !important", display: "block" }}
              >
                Add Tag
              </Typography>
              <FormControl className="dropdown-ap" fullWidth>
                <Select
                  multiple
                  value={tags}
                  onChange={handleChangetag}
                  renderValue={(selected) => selected.join(", ")}
                  defaultValue={"Select tags"}
                >
                  {tagList.map((name, index) => (
                    <MenuItem key={index} value={name}>
                      <Checkbox checked={tags.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box
              className="selectionDiv  select_check"
              sx={{ marginLeft: 0, display: "block !important" }}
            >
              <Typography
                component={"label"}
                className="label"
                sx={{ marginBottom: "0.6rem !important", display: "block" }}
              >
                Segment
              </Typography>
              <FormControl className="dropdown-ap" fullWidth>
                <Select
                  multiple
                  value={segment}
                  onChange={handleChangeSegement}
                  renderValue={(selected) => selected.join(", ")}
                  defaultValue={"Select Segment"}
                >
                  {segementList.map((name, index) => (
                    <MenuItem key={index} value={name}>
                      <Checkbox checked={segment.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Capital Requirement
              </Typography>
              <TextField
                name="captial_requirement"
                value={state?.captial_requirement || ""}
                onChange={handleChange}
                placeholder="Capital Requirement"
                className="inputFiled"
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Typography
              component={"label"}
              className="label"
              sx={{ marginBottom: "0.6rem !important", display: "block" }}
            >
              Entry Time
            </Typography>
            <Box className="selectionDiv">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  ampm={false}
                  onChange={(e) => {
                    setEntryTime(dayjs(e).format("hh:mm"));
                  }}
                  value={
                    entryTime ? dayjs(new Date(entryTime)) : dayjs(new Date())
                  }
                  className="datePicker"
                  sx={{ maxWidth: "100%", width: "100%" }}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Typography
              component={"label"}
              className="label"
              sx={{ marginBottom: "0.6rem !important", display: "block" }}
            >
              Exit Time
            </Typography>
            <Box className="selectionDiv">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  ampm={false}
                  onChange={(e) => {
                    setEndTime(dayjs(e).format("hh:mm"));
                  }}
                  value={endTime ? dayjs(new Date(endTime)) : dayjs(new Date())}
                  className="datePicker"
                  sx={{ maxWidth: "100%", width: "100%" }}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Strategy Target
              </Typography>
              <TextField
                name="target"
                value={state?.target || ""}
                onChange={handleChange}
                placeholder="Strategy Target"
                className="inputFiled"
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Strategy Stop loss
              </Typography>
              <TextField
                name="sl"
                value={state?.sl || ""}
                onChange={handleChange}
                placeholder="Strategy Stop loss"
                className="inputFiled"
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Discription
              </Typography>
              <TextField
                name="stratergy_description"
                value={state?.stratergy_description || ""}
                onChange={handleChange}
                placeholder="Discription"
                multiline
                rows={3}
                className="inputFiled"
              />
            </Box>
          </Grid>
          <Box className="commonModal">
            <Box
              className="modalBody"
              sx={{ display: { xs: "flex" }, padding: "2rem !important" }}
            >
              <Button
                className="modal-btn btn-danger"
                sx={{ marginRight: "0.5rem" }}
              >
                Discard
              </Button>
              <Button
                onClick={handleOpen}
                className="modal-btn btn-primary"
                sx={{ marginLeft: "0.5rem" }}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Grid>
      </Box>

      {/* confirm modal */}
      <Dialog
        open={open}
        onClose={handleClose}
        className="commonModal squareOff"
        fullWidth
      >
        <Box className="modalHeader">
          <Typography component={"h4"}>Create Strategy</Typography>
          <Button onClick={handleClose} className="closeModal">
            <img src={close} />
          </Button>
        </Box>

        <DialogContent sx={{ padding: "0" }} className="modalBody">
          <DialogContentText sx={{ padding: "15px 0 0 0" }}>
            <Box className="formBox boxBorder">
              <Box className="formItems" sx={{ display: "block" }}>
                <Typography component={"label"} className="label">
                  Select Display Type
                </Typography>
                <FormControl className="dropdown-ap">
                  <Select
                    value={display}
                    onChange={(e) => {
                      setDisplay(e?.target?.value);
                    }}
                    displayEmpty
                    className="dropdown"
                  >
                    {/* <MenuItem value="Everyone">Everyone</MenuItem> */}
                    <MenuItem value={"Refercode"}>Refercode</MenuItem>
                    <MenuItem value={"Own"}>Own</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {display === "Refercode" && (
                <Box
                  className="selectionDiv   formItems"
                  sx={{ marginLeft: 0, display: "block !important" }}
                >
                  <Typography
                    component={"label"}
                    className="label"
                    sx={{ margin: "0.6rem 0 !important", display: "block" }}
                  >
                    Select RefreCode
                  </Typography>
                  <FormControl className="dropdown-ap" fullWidth>
                    <Select
                      multiple
                      value={refreCodes}
                      onChange={handleRefreCodes}
                      renderValue={(selected) => selected.join(", ")}
                      defaultValue={"Select refreCodes"}
                    >
                      <MenuItem key={"All"} value={"All"}>
                        <Checkbox checked={refreCodes.indexOf("All") > -1} />
                        <ListItemText primary={"All"} />
                      </MenuItem>
                      {refreCodeList?.map((name, index) => (
                        <MenuItem key={index} value={name}>
                          <Checkbox checked={refreCodes.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}
              <Box sx={{ display: { xs: "flex", marginTop: "10px" } }}>
                <Button
                  onClick={handleClose}
                  className="modal-btn btn-danger"
                  sx={{ marginRight: "0.5rem" }}
                >
                  Discard
                </Button>
                <Button
                  onClick={handleCreate}
                  className="modal-btn btn-primary"
                  sx={{ marginLeft: "0.5rem" }}
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
