import { API_URL } from "../../config";
import * as actionTypes from "../actionTypes";
import axios from "axios";
import { generatePopup } from "../../utils/popup";

export const socketOpen = (payload) => (dispatch) => {
  // console.log("payload :>> ", payload);
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.SOCKET_OPEN_SUCCESS,
      payload: payload,
    });
    resolve();
  });
};
export const socketClose = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.SOCKET_CLOSE_SUCCESS,
      payload: payload,
    });
    resolve();
  });
};
export const getLiveData = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.LIVE_FEED_DATA_SUCCESS,
      payload: payload,
    });
    resolve();
  });
};

export const sendContactMsg = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");

    axios
      .post(`${API_URL}/auth/sendcontactmessage/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201) {
          dispatch({
            type: actionTypes.SEND_CONTACT_MSG_SUCCESS,
          });
          generatePopup(
            "success",
            res.data.message || "Message sent successfully."
          );
          resolve(res.data);
        } else {
          dispatch({
            type: actionTypes.SEND_CONTACT_MSG_FAIL,
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.SEND_CONTACT_MSG_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const getLiveNotifications = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.GET_LIVE_NOTIFICATIONS_SUCCESS,
      payload: payload,
    });
    resolve();
  });
};

export const getDayGainLoss = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.GET_DAY_GAIN_LOSS_SUCCESS,
      payload: payload,
    });
    resolve();
  });
};

export const getPriceToken = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.GET_TOKEN_PRICE_SUCCESS,
      payload: payload,
    });
    resolve();
  });
};
