import React from "react";
import {
  List,
  ListItem,
  Typography,
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useState } from "react";
import close from "../../../images/close.png";
import { useDispatch } from "react-redux";
import { deleteAccount } from "../../../redux/actions/authActions";

export default function Delete_account() {
  const [deletePop, setDeletePop] = useState(false);
  const dispatch = useDispatch();

  const onDeleteAccount = () => {
    setDeletePop(false);
    dispatch(deleteAccount());
  };

  return (
    <>
      <List className="profile-content">
        <ListItem>
          <Typography component="h4">
            Permanent Account Deletion Terms and Conditions:
          </Typography>
          <Typography component="p">
            These terms and conditions ("Agreement") govern the process and
            effects of permanently deleting your account ("Account") with
            [Dematade Algo Technology Solutions Private Limited]. By deleting
            your Account, you acknowledge and agree to the following terms
          </Typography>
          <Typography component="h4">Account Deletion Process:</Typography>
          <Typography component="p" className="list">
            Once your Account is deleted, all associated services, privileges,
            and benefits will be terminated and no longer accessible
          </Typography>
          <Typography component="p" className="list">
            To reactivate services, you must complete the full sign-up process
            anew, including providing all required information and agreeing to
            any updated terms.
          </Typography>
          <Typography component="h4">Accidental Deletion:</Typography>
          <Typography component="p">
            If your Account is mistakenly deleted, please promptly contact our
            customer support through the designated customer support page. We
            will make reasonable efforts to address and rectify the situation.
          </Typography>
          <Typography component="h4">Promotional Content:</Typography>
          <Typography component="p">
            Following the complete deletion of your Account from our system, you
            will cease to receive promotional content, emails, or messages from
            us
          </Typography>
          <Typography component="h4">Data Removal:</Typography>
          <Typography component="p">
            You retain the authority and full access to request the deletion of
            your personal data from our database. Please note that certain data
            may be retained for legal or legitimate business purposes, as
            detailed in our Privacy Policy.
          </Typography>
          <Typography component="h4">Liability and Indemnification:</Typography>
          <Typography component="p">
            You acknowledge and agree that the Company shall not be liable for
            any losses, damages, or claims arising from the deletion of your
            Account or associated data. You shall indemnify and hold the Company
            harmless against any claims or liabilities arising from your Account
            deletion.
          </Typography>
          <Typography component="h4">
            Governing Law and Dispute Resolution
          </Typography>
          <Typography component="p">
            This Agreement shall be governed by and construed in accordance with
            the laws of India. Any disputes arising from or relating to this
            Agreement shall be subject to the exclusive jurisdiction of the
            courts located in Mumbai
          </Typography>
          <Typography component="h4">Modification of Terms:</Typography>
          <Typography component="p">
            The Company reserves the right to modify, amend, or update these
            terms at any time. Notice of such changes will be communicated
            through our website or via email
          </Typography>
          <Typography component="p">
            By clicking the "Delete Account" button or taking any actions that
            lead to the deletion of your Account, you acknowledge that you have
            read, understood, and agreed to the terms outlined in this
            Agreement.
          </Typography>
          <Typography component="p">
            For any inquiries or concerns about Account deletion, please contact
            us through our customer support page.
          </Typography>
          <Box className="formItems" sx={{ marginTop: 2 }}>
            <Button
              className="formSolid-btn"
              sx={{ direction: "block" }}
              onClick={() => setDeletePop(true)}
            >
              Click Here To Delete
            </Button>
          </Box>
        </ListItem>
      </List>
      <Dialog
        open={deletePop}
        onClose={() => setDeletePop(false)}
        className="commonModal squareOff"
        fullWidth
      >
        <Box className="modalHeader">
          <Typography component={"h4"}>Delete Account</Typography>
          <Button onClick={() => setDeletePop(false)} className="closeModal">
            <img src={close} />
          </Button>
        </Box>

        <DialogContent sx={{ padding: "0" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="alertIcons">
              <svg
                width="1052"
                height="1052"
                viewBox="0 0 1052 1052"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M558 334C558 316.3 543.7 302 526 302C508.3 302 494 316.3 494 334V590C494 607.7 508.3 622 526 622C543.7 622 558 607.7 558 590V334ZM526 750C536.609 750 546.783 745.786 554.284 738.284C561.786 730.783 566 720.609 566 710C566 699.391 561.786 689.217 554.284 681.716C546.783 674.214 536.609 670 526 670C515.391 670 505.217 674.214 497.716 681.716C490.214 689.217 486 699.391 486 710C486 720.609 490.214 730.783 497.716 738.284C505.217 745.786 515.391 750 526 750Z"
                  fill="#4987FE"
                />
                <circle
                  cx="526"
                  cy="526"
                  r="507"
                  stroke="#5086EE"
                  stroke-width="38"
                />
              </svg>
            </Box>
            <Typography
              component={"h4"}
              sx={{
                fontSize: "1.8rem",
                textAlign: "center",
                marginTop: "2rem",
                marginBottom: "2rem",
              }}
              className="alertText"
            >
              Are You Sure You Want to Delete Your Account !
            </Typography>

            <Box sx={{ display: { xs: "flex" } }}>
              <Button
                onClick={() => setDeletePop(false)}
                className="modal-btn btn-danger"
                sx={{ marginRight: "0.5rem" }}
              >
                Discard
              </Button>
              <Button
                onClick={() => onDeleteAccount()}
                className="modal-btn btn-primary"
                sx={{ marginLeft: "0.5rem" }}
              >
                Confirm
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
