import React from "react";
import { List, ListItem, Typography } from "@mui/material";

export default function Privacypolicy() {
  return (
    <>
      <List className="profile-content">
        <ListItem>
          <Typography component="h4">Privacy Policy.</Typography>
          <Typography component="p">
            We at Dematade Algo Technology Solutions Private Limited value your
            privacy. Regarding any information we might gather about you on our
            website, we respect your right to privacy.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography component="h4">Refund Policy.</Typography>
          <Typography component="p">
            All services listed in the service column are covered by this
            policy. After a member has paid their membership fee, Stock Market
            robo trading will not consider any requests for cancellation or
            postponement. Even if a member is unable to use our services for
            whatever reason, a refund will not be initiated. After reading the
            Terms & Conditions, we retain a no return policy for all consumers
            who voluntarily used the services and signed up for membership.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography component="h4">Liability.</Typography>
          <Typography component="p">
            You acknowledge and agree that Dematade Algo Technology Solutions
            Private Limited, its affiliates, group companies, officers,
            directors, employees, agents, representatives, and subcontractors
            shall not, under any circumstances, be liable for any direct,
            indirect, special, consequential, or incidental damages or other
            damages of any kind arising out of the use or inability to use{" "}
            <Typography href="#" component={"a"}>
              https://dematadesolution.com/
            </Typography>{" "}
            for any purpose, even if DeMatade is a party to the claim.
          </Typography>
          <Typography component="p">
            There is no contractual or other obligation that Dematade Algo
            Technology Solutions Private Limited, its affiliates, officers,
            directors, employees, or agents have to the user or any other party.
            This exclusion of liability covers, but is not limited to, the
            transmission of any viruses that could harm a user's computer
            equipment, the breakdown of mechanical or electronic devices or
            communication lines, telephone or other interconnect issues (such as
            when you can't reach your internet service provider), unauthorized
            access, theft, operator mistakes, strikes or other labor disputes,
            or any other unavoidable force majeure. Access to this website
            cannot and is not guaranteed to be continuous, uninterrupted, or
            secure by Dematade Algo Technology Solutions Private Limited.
          </Typography>
        </ListItem>

        <ListItem>
          <Typography component="h4">Indemnification</Typography>
          <Typography component="p">
            You agree to defend, hold harmless, and indemnify Dematade Algo
            Technology Solutions Private Limited (along with its and their
            officers, directors, employees, affiliates, group companies, agents,
            representatives, and subcontractors) from any and all liabilities
            arising out of or connected with:
          </Typography>
          <Typography component="p" className="list">
            Your access to and use of{" "}
            <Typography href="#" component={"a"}>
              https://dematadesolution.com/
            </Typography>
          </Typography>
          <Typography component="p" className="list">
            any violation of the terms and conditions by the user
          </Typography>
          <Typography component="p" className="list">
            any illegal or authorised acts by third parties in connection with
            the user's reception and use of the information.
          </Typography>
        </ListItem>
      </List>
    </>
  );
}
