import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Autocomplete,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import close from "../../../../images/close.png";
import "../../../Scanner/Scanner.scss";
import "../../../Tablesearch/Tablesearch.scss";
import "../../../Tablesearch/Tablesearch.scss";
import { getSegment } from "../../../../redux/actions/positionAction";
import { useDispatch, useSelector } from "react-redux";
import CopyTradeView from "./CopyTradeView";
import { addWatchListData } from "../../../../redux/actions/marketWatchAction";
import { messageEvent, socket } from "../../../../hooks/useSocket";

export default function Copytrade() {
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = useState("");
  const [symbolSocket, setSymbolSocket] = useState("");
  const [payload, setPayload] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSegment());
  }, []);

  const watchlistData = useSelector((state) => state?.Position?.watchlist);
  console.log("watchlistData", watchlistData);

  useEffect(() => {
    if (symbolSocket && symbolSocket.readyState === WebSocket.OPEN) {
      messageEvent(symbolSocket, "watchlist", dispatch, {
        value: search?.toUpperCase() || "",
      });
    }
  }, [search, symbolSocket]);

  const handleAddSymbol = () => {
    const obj = {
      exchange: payload?.exchange,
      expiry: payload?.expiry,
      option: payload?.option,
      strikeprice: payload?.strikeprice,
      symbol: payload?.symbol,
      token: payload?.token,
      lot: 0,
      tradingsymbol: payload?.tradingsymbol,
    };
    dispatch(addWatchListData(obj));
  };

  useEffect(() => {
    let ws = null;
    const getWatchListLiveSocket = async () => {
      ws = await socket("watchlistupdate");

      messageEvent(ws, "watchlistupdate", dispatch);
    };
    getWatchListLiveSocket();

    window.onbeforeunload = async function (event) {
      return await ws?.send("disconnect");
      // closeSocket(ws, "liveposition");
    };
    return () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws?.send("disconnect");
      }
    };
  }, []);

  useEffect(() => {
    let ws;
    const setupWebSocket = async () => {
      ws = await socket(
        "watchlist",
        process.env.REACT_APP_PAPER_CUSTOMER_WEBSOCKET_URL
      );
      setSymbolSocket(ws);
      if (ws) {
        messageEvent(ws, "watchlist", dispatch, { value: "" });
      }
    };
    setupWebSocket();
    window.onbeforeunload = async function (event) {
      console.log("closed");
      return await ws?.send({ remark: "disconnect" });
    };
    return () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws?.send("disconnect");
      }
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ marginTop: 3, padding: "0 1rem" }}>
        <CopyTradeView opHeaderNone="none" btnone="bt-none" tabType={""} />
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        className="commonModal addSymbol"
        fullWidth
      >
        <Box className="modalHeader" sx={{ justifyContent: "space-between" }}>
          <Typography component={"h4"}>Add Symbol </Typography>
          <Button onClick={handleClose} className="closeModal">
            <img src={close} />
          </Button>
        </Box>
        <DialogContent sx={{ padding: "0" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="dropdown-ap inputFields fullWidth">
              <Autocomplete
                freeSolo
                className="dropdown"
                inputValue={search || ""}
                onChange={(e, newValue) => {
                  setSearch(newValue?.tradingsymbol);
                  setPayload(newValue);
                }}
                onInputChange={(e, value, reason) => {
                  setSearch(value);
                }}
                options={watchlistData}
                getOptionLabel={(option) => option?.tradingsymbol}
                renderInput={(params, option) => {
                  return (
                    <>
                      <Box className="MultieValue">
                        <TextField
                          placeholder="Scrip"
                          value={search}
                          {...params}
                          sx={{
                            "&.MuiFormControl-root > .MuiInputBase-root > input.MuiInputBase-input":
                              {
                                border: "",
                              },
                          }}
                        />
                      </Box>
                      <Button
                        className="refresh-btn solidButton"
                        sx={{ width: "auto !important", marginTop: 2 }}
                        onClick={() => handleAddSymbol()}
                      >
                        Add Symbol
                      </Button>
                    </>
                  );
                }}
                renderOption={(propsData, option) => {
                  return (
                    <Box {...propsData} className="searchSelect">
                      <Box className="exchangeName">
                        <Typography component="h4">
                          {option?.tradingsymbol}
                        </Typography>
                        <Typography component="p">{option?.symbol}</Typography>
                      </Box>
                    </Box>
                  );
                }}
                sx={{
                  border: "none !important ",
                }}
              />
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
