import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  Tooltip,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import close from "../../../../images/close.png";
import Dropdown from "../../../Dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  closeAllCopyOrder,
  getSegment,
} from "../../../../redux/actions/positionAction";
import { getStratergy } from "../../../../redux/actions/stratergyAction";
import { generatePopup } from "../../../../utils/popup";
import { getUserRefreCode } from "../../../../redux/actions/brokerAction";
import {
  getReferCode,
  getUserVendor,
} from "../../../../redux/actions/authActions";

export default function CloseTradeClose() {
  const closeAll = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      Close All
    </Typography>
  );

  const [open, setOpen] = useState(false);
  const [exchange, setExchange] = useState("select");
  const [referalcode, setReferalcode] = useState("");

  const dispatch = useDispatch();

  //select exchange
  const handleExchange = (event) => {
    setExchange(event.target.value);
  };

  //modal open close
  const handleClickOpen = () => {
    if (referalcode?.toLowerCase() === "select") {
      return generatePopup("error", "Please select refferalCode");
    }
    if (exchange?.toLowerCase() === "select") {
      return generatePopup("error", "Please select exchange");
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const userData = useSelector((state) => state?.Auth?.authProfile?.[0]);

  useEffect(() => {
    setReferalcode(userData?.refrecode);
  }, [userData?.refrecode]);

  useEffect(() => {
    if (userData) {
      const obj = {
        refrecode: userData?.username,
      };
      dispatch(getUserRefreCode(obj));
    }
  }, [userData]);

  // const refrecodes = useSelector((state) => state?.Broker?.userRefreCodes);

  // const handleSearchData = () => {
  //   const obj = {
  //     exchange:
  //       exchange?.toLowerCase() !== "select"
  //         ? exchange?.toUpperCase()
  //         : "select",
  //     stratergy:
  //       strategy?.toLowerCase() !== "select"
  //         ? strategy?.toUpperCase()
  //         : "select",
  //     clientid: refreCode ? refreCode?.toUpperCase() : "select",
  //   };
  //   dispatch(adminOpenPosition(obj));
  // };

  // useEffect(() => {
  //   handleSearchData();
  // }, [exchange, strategy]);

  useEffect(() => {
    dispatch(getStratergy());
    dispatch(getSegment());
  }, []);

  //   const segments = useSelector((state) => state?.Position?.segmentList);

  const exchanges = ["NSE", "NFO", "CDS", "MCX", "BFO"];
  const refrecodeOptions = [userData?.refrecode];

  useEffect(() => {
    if (userData?.is_powerbroker) {
      const obj = {
        refrecode: userData?.username,
      };
      dispatch(getUserVendor(obj));
    } else {
      dispatch(getReferCode());
    }
  }, [userData]);

  const userVendors = useSelector((state) => state?.Auth?.userVendors);

  const handleSquareOffAll = () => {
    const obj = {
      exchange: exchange?.toUpperCase(),
      username: referalcode?.toUpperCase(),
    };
    dispatch(closeAllCopyOrder(obj)).then((res) => {
      console.log("res.data", res.data);
    });
  };

  const handleReferalcode = (event) => {
    setReferalcode(event.target.value);
  };

  return (
    <>
      <Box className="tabelBox">
        <Grid container spacing={2} alignItems={"center"} padding={"1rem 0"}>
          <Grid item xs={12}>
            <Box
              className="selectiondiv-box m-rb"
              sx={{
                paddingRight: { xs: "0", md: "1rem" },
                justifyContent: "center",
              }}
            >
              {/* <Grid container md={3} sx={{ paddingLeft: "1rem" }}>
                <Grid item md={12}>
                  <Box className="dropdown-ap inputFields fullWidth">
                    <Autocomplete
                      onInputChange={handleRefreSearch}
                      placeholder="Select Broker"
                      value={refreCode || ""}
                      inputValue={refreCode || ""}
                      className="dropdown"
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select Broker" />
                      )}
                      options={refrecodes?.map((e) => e)}
                      onChange={handleRefreSelect}
                      getOptionLabel={(option) => option}
                      sx={{
                        "& > .MuiFormControl-root > .MuiInputBase-root": {
                          padding: "0.5rem !important",
                        },
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Tooltip title={search} arrow>
                <Button
                  onClick={handleSearchPosition}
                  className="download-btn solidButton"
                  sx={{ margin: "0 1rem" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                      fill="#FFFFFF"
                    />
                  </svg>
                </Button>
              </Tooltip> */}
              <Dropdown
                val={["select"].concat(exchanges)}
                handleSelect={handleExchange}
                value={exchange}
                title={"Exchange"}
              />
              <Dropdown
                val={userData?.is_staff ? userVendors : refrecodeOptions}
                handleSelect={handleReferalcode}
                value={referalcode}
                title={"Referal Code"}
              />
              <Box className="selectionDiv searchFlex">
                <Tooltip title={closeAll} arrow>
                  <Button
                    className="squareOff-btn solidButton"
                    sx={{
                      width: "auto !important",
                      fontSize: "1.2rem",
                      marginRight: 1,
                    }}
                    onClick={() => handleClickOpen()}
                  >
                    Close All
                  </Button>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Sqaure Off Modal */}
      <Dialog
        open={open}
        onClose={handleClose}
        className="commonModal squareOff"
        fullWidth
      >
        <Box className="modalHeader">
          <Typography component={"h4"}>Square Off</Typography>
          <Button onClick={handleClose} className="closeModal">
            <img alt="close" src={close} />
          </Button>
        </Box>

        <DialogContent sx={{ padding: "0" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="alertIcons">
              <svg
                width="1052"
                height="1052"
                viewBox="0 0 1052 1052"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M558 334C558 316.3 543.7 302 526 302C508.3 302 494 316.3 494 334V590C494 607.7 508.3 622 526 622C543.7 622 558 607.7 558 590V334ZM526 750C536.609 750 546.783 745.786 554.284 738.284C561.786 730.783 566 720.609 566 710C566 699.391 561.786 689.217 554.284 681.716C546.783 674.214 536.609 670 526 670C515.391 670 505.217 674.214 497.716 681.716C490.214 689.217 486 699.391 486 710C486 720.609 490.214 730.783 497.716 738.284C505.217 745.786 515.391 750 526 750Z"
                  fill="#4987FE"
                />
                <circle
                  cx="526"
                  cy="526"
                  r="507"
                  stroke="#5086EE"
                  stroke-width="38"
                />
              </svg>
            </Box>
            <Typography
              component={"h4"}
              sx={{
                fontSize: "1.8rem",
                textAlign: "center",
                margin: "2rem 0",
              }}
              className="alertText"
            >
              Are You Sure You Want to Close All Copy Order !
            </Typography>

            <Box sx={{ display: { xs: "flex" } }}>
              <Button
                onClick={handleClose}
                className="modal-btn btn-danger"
                sx={{ marginRight: "0.5rem" }}
              >
                Discard
              </Button>
              <Button
                onClick={handleSquareOffAll}
                className="modal-btn btn-primary"
                sx={{ marginLeft: "0.5rem" }}
              >
                Confirm
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
