import * as actionTypes from "../actionTypes";

const initState = {
  brokerstatus: {},
  loading: false,
  error: "",
  openDemate: [],
  brokersList: [],
  swastikaSessionId: {},
  dhanSessionId: {},
  aliceSessionId: {},
  contact: [],
  socialData: [],
  subLogin: [],
  getSocialData: [],
  getSubBrokerData: [],
  fundData: {},
  businessReport: [],
  logo: "",
  faqs: [],
  vendorReferalList: [],
  paymentReport: [],
  powerBrokers: [],
  userRefreCodes: [],
};

const store = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.ZEBULL_BROKER_INIT:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case actionTypes.ZEBULL_BROKER_SUCCESS:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case actionTypes.ZEBULL_BROKER_FAIL:
      return {
        ...state,
        loading: true,
        error: action?.payload,
      };
    case actionTypes.ICICI_BROKER_INIT:
      return {
        ...state,
        error: "",
      };
    case actionTypes.ICICI_BROKER_SUCCESS:
      return {
        ...state,
        error: "",
      };
    case actionTypes.ICICI_BROKER_FAIL:
      return {
        ...state,
        error: action?.payload,
      };
    case actionTypes.ANGEL_BROKER_INIT:
      return {
        ...state,
        error: "",
      };
    case actionTypes.ANGEL_BROKER_SUCCESS:
      return {
        ...state,
        error: "",
      };
    case actionTypes.ANGEL_BROKER_FAIL:
      return {
        ...state,
        error: action?.payload,
      };
    case actionTypes.ZERODHA_BROKER_INIT:
      return {
        ...state,
        error: "",
      };
    case actionTypes.ZERODHA_BROKER_SUCCESS:
      return {
        ...state,
        error: "",
      };
    case actionTypes.ZERODHA_BROKER_FAIL:
      return {
        ...state,
        error: action?.payload,
      };
    case actionTypes.FINVASIA_BROKER_INIT:
      return {
        ...state,
        error: "",
      };
    case actionTypes.FINVASIA_BROKER_SUCCESS:
      return {
        ...state,
        error: "",
      };
    case actionTypes.FINVASIA_BROKER_FAIL:
      return {
        ...state,
        error: action?.payload,
      };
    case actionTypes.KOTAK_BROKER_INIT:
      return {
        ...state,
        error: "",
      };
    case actionTypes.KOTAK_BROKER_SUCCESS:
      return {
        ...state,
        error: "",
      };
    case actionTypes.KOTAK_BROKER_FAIL:
      return {
        ...state,
        error: action?.payload,
      };
    case actionTypes.ALICE_BROKER_INIT:
      return {
        ...state,
        error: "",
      };
    case actionTypes.ALICE_BROKER_SUCCESS:
      return {
        ...state,
        error: "",
      };
    case actionTypes.ALICE_BROKER_FAIL:
      return {
        ...state,
        error: action?.payload,
      };
    case actionTypes.FYERS_BROKER_INIT:
      return {
        ...state,
        error: "",
      };
    case actionTypes.FYERS_BROKER_SUCCESS:
      return {
        ...state,
        error: "",
      };
    case actionTypes.FYERS_BROKER_FAIL:
      return {
        ...state,
        error: action?.payload,
      };

    case actionTypes.GET_SWASTIKA_SESSION_SUCCESS:
      return {
        ...state,
        swastikaSessionId: action?.payload,
      };

    case actionTypes.GET_DHAN_SESSION_SUCCESS:
      return {
        ...state,
        dhanSessionId: action?.payload,
      };

    case actionTypes.GET_ALICE_SESSION_SUCCESS:
      return {
        ...state,
        aliceSessionId: action?.payload,
      };

    case actionTypes.BROKER_STATUS_INIT:
      return {
        ...state,
        error: "",
      };
    case actionTypes.BROKER_STATUS_SUCCESS:
      return {
        ...state,
        brokerstatus: action.payload,
        error: "",
      };
    case actionTypes.BROKER_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.OPEN_DEMATE_INIT:
      return {
        ...state,
        error: "",
      };
    case actionTypes.OPEN_DEMATE_SUCCESS:
      return {
        ...state,
        openDemate: action.payload,
        error: "",
      };
    case actionTypes.OPEN_DEMATE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.CONTACT_US_INIT:
      return {
        ...state,
        error: "",
      };
    case actionTypes.CONTACT_US_SUCCESS:
      return {
        ...state,
        contact: action.payload,
        error: "",
      };
    case actionTypes.CONTACT_US_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.SUB_BROKER_LOGIN_INIT:
      return {
        ...state,
        error: "",
      };
    case actionTypes.SUB_BROKER_LOGIN_SUCCESS:
      return {
        ...state,
        subLogin: action.payload,
        error: "",
      };
    case actionTypes.SUB_BROKER_LOGIN_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.EDIT_SOCIAL_LINKS_INIT:
      return {
        ...state,
        error: "",
      };
    case actionTypes.EDIT_SOCIAL_LINKS_SUCCESS:
      return {
        ...state,
        socialData: action.payload,
        error: "",
      };
    case actionTypes.EDIT_SOCIAL_LINKS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.GET_SOCIAL_LINKS_INIT:
      return {
        ...state,
        error: "",
      };
    case actionTypes.GET_SOCIAL_LINKS_SUCCESS:
      return {
        ...state,
        getSocialData: action.payload,
        error: "",
      };
    case actionTypes.GET_SOCIAL_LINKS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.GET_SUB_BROKER_INIT:
      return {
        ...state,
        error: "",
      };
    case actionTypes.GET_SUB_BROKER_SUCCESS:
      return {
        ...state,
        getSubBrokerData: action?.payload,
        error: "",
      };
    case actionTypes.GET_SUB_BROKER_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.SEARCH_SUB_BROKER_INIT:
      return {
        ...state,
        error: "",
      };
    case actionTypes.SEARCH_SUB_BROKER_SUCCESS:
      return {
        ...state,
        searchSubBrokerData: action?.payload,
        error: "",
      };
    case actionTypes.SEARCH_SUB_BROKER_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.GET_FUND_BROKER_SUCCESS:
      return {
        ...state,
        fundData: action.payload,
      };
    case actionTypes.BUSINESS_REPORT_SUCCESS:
      return {
        ...state,
        businessReport: action?.payload,
      };
    case actionTypes.BUSINESS_REPORT_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.GET_LOGO_SUCCESS:
      return {
        ...state,
        logo: action.payload,
      };
    case actionTypes.GET_MOBILE_LOGO_SUCCESS:
      return {
        ...state,
        mobileLogo: action.payload,
      };
    case actionTypes.GET_FAQ_SUCCESS:
      return {
        ...state,
        faqs: action.payload,
      };
    case actionTypes.EDIT_MRP_SUCCESS:
      return {
        ...state,
        vendorReferalList: action.payload,
      };
    case actionTypes.SEARCH_INVOICE_SUCCESS:
      return {
        ...state,
        paymentReport: action.payload,
      };
    case actionTypes.GET_POWER_BROKER_SUCCESS:
      return {
        ...state,
        powerBrokers: action.payload,
      };
    case actionTypes.GET_USER_REFRECODE_SUCCESS:
      return {
        ...state,
        userRefreCodes: action.payload,
      };
    case actionTypes.GET_BROKER_URLS_SUCCESS:
      return {
        ...state,
        brokersList: action.payload,
      };
    default:
      return state;
  }
};

export default store;
