import React, { useEffect } from "react";
import { Box, List, ListItem, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getFaq } from "../../../redux/actions/brokerAction";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FAQ() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFaq());
  }, []);

  const faqs = useSelector((state) => state?.Broker?.faqs);

  return (
    <>
      {/* <Box className="content">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Accordion 1</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box> */}
      <List className="profile-content">
        {faqs?.map((e) => (
          <ListItem>
            <Typography component="h4">{e?.question}</Typography>
            <Typography component="p">{e?.answer}</Typography>
          </ListItem>
        ))}
        {/* <ListItem>
          <Typography component="h4">What is Algo Trading?</Typography>
          <Typography component="p">
            Algorithmic trading, often known as black box trading, follows a
            predefined set of trading instructions from a computer programme. It
            makes earnings at a rate and frequency that a human trader cannot
            match. The rules are established in accordance with the amount,
            time, pricing, or mathematical model. Algorithmic trading gives
            liquidity to markets and profits to traders, as well as a methodical
            approach to trading free of human emotions. Simply said, algorithmic
            trading eliminates any manual intervention. After coding the logic
            rules in the algorithm, it will do point-to-point automation. It
            follows the market, makes orders when an opportunity arises, and
            monitors risk and stop loss. When necessary, it also squares off the
            trade. Algorithms can assist in trading F&O, equities, FOREX,
            cryptocurrencies such as BITFINEX and BITMEX, and commodities on
            Indian exchanges such as the BSE, NSE, NCDEX, MCX, and others.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography component="h4"> How Algo Trading Works?</Typography>
          <Typography component="p">
            Algorithmic trading makes trade entries and exits based on the set
            parameters, such as volatility levels or price changes, utilizing
            chart analysis and algorithms. Once they locate favorable market
            circumstances in accordance with the specified criteria, trading
            algorithms are able to submit orders for buying or selling on behalf
            of the user.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography component="h4">
            {" "}
            How is algo trading better than manual trading?.
          </Typography>
          <Typography component="p">
            Two of the key benefits of algo trading over human trading are speed
            and accuracy. Pre-defined and well executed algorithms. It completes
            transactions in a split second. Human traders are unable to deal at
            this speed. It runs and scans on a number of factors that are nearly
            hard for a person to handle. Therefore, there are more solutions
            available at lower costs. On the other hand, there is no chance of
            making mistakes by selecting the incorrect deal. If you choose the
            manual option, there is a potential that you may invest in the
            incorrect currency pair or trade for the incorrect amount. A cross
            check of an algorithm has already been performed to confirm the
            correct sequence. Additionally, algorithmic trading eliminates human
            emotions that produce irrational conclusions. The most frequent
            offenders that influence the human mind are greed and fear
          </Typography>
        </ListItem>

        <ListItem>
          <Typography component="h4">
            What are the pros and cons of Algo Trading?
          </Typography>
          <Typography component="p">
            Compared to the conventional method, algorithmic trading has many
            advantages. But there are also drawbacks. Here are a few examples:
            Pros a backtesting capability unparalleled speed accuracy that is
            beyond human capabilities No chance of making poor choices because
            of human emotions like fear and wrath Automated asset selection
            Drawbacks A small learning curve exists.
          </Typography>
        </ListItem>

        <ListItem>
          <Typography component="h4"> Is Free API Key available?</Typography>
          <Typography component="p">
            Dematade currently has partnerships with brokers, therefore there is
            no requirement for an API key; clients simply need to sign up using
            their credentials for a broker account.
          </Typography>
        </ListItem>

        <ListItem>
          <Typography component="h4">
            {" "}
            Does algo trading need constant monitoring over laptop or PC?
          </Typography>
          <Typography component="p">
            Yes, the trader must keep an eye on the trading session to prevent
            any technical problems like connection and power outages. The
            execution of the strategy has to be constantly monitored.
            Additionally, make sure that algorithms don't include any incorrect,
            redundant, or missing orders.
          </Typography>
        </ListItem>

        <ListItem>
          <Typography component="h4">
            What are the system requirements?
          </Typography>
          <Typography component="p">
            All you need is 4GB of RAM and reliable internet access.
          </Typography>
        </ListItem>

        <ListItem>
          <Typography component="h4">
            What about the accuracy of automatic signals?
          </Typography>
          <Typography component="p">
            All of the algorithms used to generate the buy/sell signals have
            undergone extensive back testing as well as several iterations in
            the live market, leading to a high success rate.
          </Typography>
        </ListItem>

        <ListItem>
          <Typography component="h4">
            Is Algo Trading legal in India?
          </Typography>
          <Typography component="p">
            In order to ensure that automated trading was allowed in India, the
            Securities and Exchange Board of India (SEBI) established a
            regulation in 2008. Therefore, a succinct response is "Yes," it is
            legal in India. In actuality, algorithms are used in more than 43%
            of today's NSE deals.
          </Typography>
        </ListItem>

        <ListItem>
          <Typography component="h4">
            Do you guarantee profits with it?
          </Typography>
          <Typography component="p">
            No, you can only predict profits based on the algorithm that has
            been configured. We make no promises regarding a company's financial
            success.
          </Typography>
        </ListItem> */}
      </List>
    </>
  );
}
