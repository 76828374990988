import * as actionTypes from "../actionTypes";

const initState = {
  loading: false,
  error: "",
  liveFeedData: [],
  liveNotification: [],
  gainLoss: {},
  tokenPrice: "",
  socket: {},
};

const store = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.LIVE_FEED_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        liveFeedData: action?.payload,
      };
    case actionTypes.SOCKET_OPEN_SUCCESS:
      return {
        ...state,
        socket: {
          ...state.socket,
          [action?.payload?.name]: action?.payload?.socket,
        },
      };
    case actionTypes.SOCKET_CLOSE_SUCCESS:
      return {
        ...state,
        socket: {
          ...state.socket,
          [action?.payload?.name]: null,
        },
      };
    case actionTypes.GET_LIVE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        liveNotification: action.payload,
      };
    case actionTypes.GET_DAY_GAIN_LOSS_SUCCESS:
      return {
        ...state,
        gainLoss: action.payload,
      };
    case actionTypes.GET_TOKEN_PRICE_SUCCESS:
      return {
        ...state,
        tokenPrice: action.payload,
      };
    default:
      return state;
  }
};

export default store;
