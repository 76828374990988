import React, { useEffect } from "react";
import {
  Box,
  Button,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  TextField,
} from "@mui/material";
import Table from "../../../Table/Table";
import CloseIcon from "@mui/icons-material/Close";
import MessageIcon from "@mui/icons-material/Message";
import { useDispatch, useSelector } from "react-redux";
import {
  adminPaperTradeDetails,
  adminTradeDetails,
} from "../../../../redux/actions/adminActions";
import moment from "moment";

const edit = <Typography sx={{ fontSize: "1.4rem" }}>Edit</Typography>;
const cancle = <Typography sx={{ fontSize: "1.4rem" }}>Cancel</Typography>;
//Order Book
const col3 = [
  // <Checkbox sx={{ "& .MuiSvgIcon-root": { fontSize: 24 }, padding: 0 }} />,
  "Symbol Name",
  "Exchange",
  "Trend",
  "Price",
  "Qty",
  "Owner",
  "OrderId",
  "PrdType",
  "Strategy",
  "Date & Time",
  "Status",
  "Reason",
  "Actions",
];
// const rows3 = [
//   {
//     check: (
//       <Checkbox sx={{ "& .MuiSvgIcon-root": { fontSize: 24 }, padding: 0 }} />
//     ),
//     date: "BANKNIFTY2310542900PE",
//     strategyname: "Demo",
//     Exchange: "NFO",
//     OrderType: "MIS",
//     BuyQTY: 150,
//     SellQTY: 150,
//     EntryPrice: 92.45,
//     ExitPrice: 118.8,
//     LTP: "05-15-2023, 11:20:05PM",
//     PL: "Sell",
//     reason: (
//       <Tooltip
//         title={
//           <Typography sx={{ fontSize: "1.3rem" }}>
//             We at DeMatade Algo Technology Solutions value your privacy.
//             Regarding any information we might gather about you on our website,
//             we respect your right to privacy.
//           </Typography>
//         }
//         arrow
//         placement="left"
//       >
//         <Button className="rejectMessage">
//           <MessageIcon sx={{ color: "#fff !important" }} />
//         </Button>
//       </Tooltip>
//     ),
//     action: (
//       <Box className="tableActions">
//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//           className="actionButton"
//         >
//           <Tooltip title={edit} arrow placement="top">
//             <Button className="edit" variant="text">
//               <svg
//                 width="24"
//                 height="24"
//                 viewBox="0 0 24 24"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M13.26 3.59997L5.04997 12.29C4.73997 12.62 4.43997 13.27 4.37997 13.72L4.00997 16.96C3.87997 18.13 4.71997 18.93 5.87997 18.73L9.09997 18.18C9.54997 18.1 10.18 17.77 10.49 17.43L18.7 8.73997C20.12 7.23997 20.76 5.52997 18.55 3.43997C16.35 1.36997 14.68 2.09997 13.26 3.59997Z"
//                   stroke="CurrentColor"
//                   stroke-width="1.5"
//                   stroke-miterlimit="10"
//                   stroke-linecap="round"
//                   stroke-linejoin="round"
//                 />
//                 <path
//                   d="M11.89 5.05005C12.32 7.81005 14.56 9.92005 17.34 10.2"
//                   stroke="CurrentColor"
//                   stroke-width="1.5"
//                   stroke-miterlimit="10"
//                   stroke-linecap="round"
//                   stroke-linejoin="round"
//                 />
//                 <path
//                   d="M3 22H21"
//                   stroke="CurrentColor"
//                   stroke-width="1.5"
//                   stroke-miterlimit="10"
//                   stroke-linecap="round"
//                   stroke-linejoin="round"
//                 />
//               </svg>
//             </Button>
//           </Tooltip>

//           <Tooltip title={cancle} arrow placement="top">
//             <Button className="del" variant="text">
//               <CloseIcon />
//             </Button>
//           </Tooltip>
//         </Box>
//       </Box>
//     ),
//   },
// ];

export default function Orderbook() {
  const [alignment, setAlignment] = React.useState("live");
  const [clientId, setClientId] = React.useState("");

  const handleChange = (e, newAlignment) => {
    if (newAlignment) {
      setAlignment(newAlignment);
    }
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (alignment === "live") {
      console.log("live");
      dispatch(
        adminTradeDetails({
          clientid: clientId || "select",
        })
      );
    } else {
      console.log("live");
      dispatch(
        adminPaperTradeDetails({
          clientid: clientId || "select",
        })
      );
    }
  }, [alignment]);

  const data = useSelector((state) => state?.Admin?.adminTradeDetailsData);
  const paperData = useSelector((state) => state?.Admin?.adminPaperOrderData);

  console.log("data :>> ", data);

  const sortedAsc = data?.sort(function (a, b) {
    return new Date(b.date) - new Date(a.date);
  });
  console.log("sortedAsc :>> ", sortedAsc);
  const paperSortedAsc = paperData?.sort(function (a, b) {
    return new Date(b.date) - new Date(a.date);
  });

  const search = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      search{" "}
    </Typography>
  );

  const handleSearch = () => {
    if (alignment === "live") {
      dispatch(
        adminTradeDetails({
          clientid: clientId ? clientId : "select",
        })
      );
    } else {
      dispatch(
        adminPaperTradeDetails({
          clientid: clientId ? clientId : "select",
        })
      );
    }
  };

  let rows3 = [];
  if (alignment === "live") {
    rows3 = sortedAsc?.map((e) => {
      return {
        // check: (
        //   <Checkbox
        //     sx={{ "& .MuiSvgIcon-root": { fontSize: 24 }, padding: 0 }}
        //   />
        // ),
        name: e?.symbol,
        exchange: e?.exchange,
        trend:
          e?.side?.toLowerCase() === "sell" ||
          e?.side?.toLowerCase() === "s" ? (
            <Typography component={"span"} className="down">
              {e?.side}
            </Typography>
          ) : (
            <Typography component={"span"} className="up">
              {e?.side}
            </Typography>
          ),
        price: Number(e?.price)?.toFixed(2),
        quantity: Math.trunc(e?.quantity),
        owner: e?.owner,
        orderId: e?.orderid,
        prdtyp: e?.prctyp,
        stratergy: e?.stratergy,
        date: moment.parseZone(e?.date)?.format("YYYY-MM-DD HH:mm"),
        status:
          e?.status?.toUpperCase() === "REJECTED" ? (
            <Typography component={"span"} className="down">
              {e?.status}
            </Typography>
          ) : (
            <Typography component={"span"} className="up">
              {e?.status}
            </Typography>
          ),
        remark: (
          <Tooltip
            title={
              <Typography sx={{ fontSize: "1.3rem" }}>{e?.remark}</Typography>
            }
            arrow
            placement="left"
          >
            <Button className="rejectMessage">
              <MessageIcon sx={{ color: "#fff !important" }} />
            </Button>
          </Tooltip>
        ),
        Action:
          e?.status?.toUpperCase() === "PENDING" ? (
            <>
              {" "}
              <Box className="tableActions">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="actionButton"
                >
                  <Tooltip title={edit} arrow placement="top">
                    <Button className="edit" variant="text">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.26 3.59997L5.04997 12.29C4.73997 12.62 4.43997 13.27 4.37997 13.72L4.00997 16.96C3.87997 18.13 4.71997 18.93 5.87997 18.73L9.09997 18.18C9.54997 18.1 10.18 17.77 10.49 17.43L18.7 8.73997C20.12 7.23997 20.76 5.52997 18.55 3.43997C16.35 1.36997 14.68 2.09997 13.26 3.59997Z"
                          stroke="CurrentColor"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.89 5.05005C12.32 7.81005 14.56 9.92005 17.34 10.2"
                          stroke="CurrentColor"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3 22H21"
                          stroke="CurrentColor"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Button>
                  </Tooltip>

                  <Tooltip title={cancle} arrow placement="top">
                    <Button className="del" variant="text">
                      <CloseIcon />
                    </Button>
                  </Tooltip>
                </Box>
              </Box>
            </>
          ) : (
            <></>
          ),
      };
    });
  } else if (alignment === "paper") {
    rows3 = paperSortedAsc?.map((e) => {
      return {
        // check: (
        //   <Checkbox
        //     sx={{ "& .MuiSvgIcon-root": { fontSize: 24 }, padding: 0 }}
        //   />
        // ),
        name: e?.symbol,
        exchange: e?.exchange,
        trend:
          e?.side?.toLowerCase() === "sell" ||
          e?.side?.toLowerCase() === "s" ? (
            <Typography component={"span"} className="down">
              {e?.side}
            </Typography>
          ) : (
            <Typography component={"span"} className="up">
              {e?.side}
            </Typography>
          ),
        price: Number(e?.price)?.toFixed(2),
        quantity: e?.quantity,
        owner: e?.owner,
        orderId: e?.orderid,
        prdtyp: e?.prctyp,
        stratergy: e?.stratergy,
        date: moment.parseZone(e?.date)?.format("YYYY-MM-DD HH:mm"),
        status:
          e?.status === "REJECTED" ? (
            <Typography component={"span"} className="down">
              {e?.status}
            </Typography>
          ) : (
            <Typography component={"span"} className="up">
              {e?.status}
            </Typography>
          ),
        remark: (
          <Tooltip
            title={
              <Typography sx={{ fontSize: "1.3rem" }}>{e?.remark}</Typography>
            }
            arrow
            placement="left"
          >
            <Button className="rejectMessage">
              <MessageIcon sx={{ color: "#fff !important" }} />
            </Button>
          </Tooltip>
        ),
        Action:
          e?.status?.toUpperCase() === "PENDING" ? (
            <>
              {" "}
              <Box className="tableActions">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="actionButton"
                >
                  <Tooltip title={edit} arrow placement="top">
                    <Button className="edit" variant="text">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.26 3.59997L5.04997 12.29C4.73997 12.62 4.43997 13.27 4.37997 13.72L4.00997 16.96C3.87997 18.13 4.71997 18.93 5.87997 18.73L9.09997 18.18C9.54997 18.1 10.18 17.77 10.49 17.43L18.7 8.73997C20.12 7.23997 20.76 5.52997 18.55 3.43997C16.35 1.36997 14.68 2.09997 13.26 3.59997Z"
                          stroke="CurrentColor"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.89 5.05005C12.32 7.81005 14.56 9.92005 17.34 10.2"
                          stroke="CurrentColor"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3 22H21"
                          stroke="CurrentColor"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Button>
                  </Tooltip>

                  <Tooltip title={cancle} arrow placement="top">
                    <Button className="del" variant="text">
                      <CloseIcon />
                    </Button>
                  </Tooltip>
                </Box>
              </Box>
            </>
          ) : (
            <></>
          ),
      };
    });
  }

  return (
    <Box className="border-ap">
      <Box className="selectiondiv-box m-rb">
        {/* <Button
          className="squareOff-btn solidButton"
          sx={{
            width: "auto !important",
            fontSize: "1.2rem",
            marginRight: 1,
            marginBottom: "1rem",
          }}
        >
          Cancel All
        </Button> */}
        <Box className="selectionDiv searchFlex">
          <Box className="inputFields">
            <TextField
              placeholder="Broker Client id"
              onChange={(e) => {
                setClientId(e?.target?.value);
              }}
            />

            <Box className="searchIcon">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.85722 14.7144C11.6444 14.7144 14.7144 11.6444 14.7144 7.85722C14.7144 4.07008 11.6444 1 7.85722 1C4.07008 1 1 4.07008 1 7.85722C1 11.6444 4.07008 14.7144 7.85722 14.7144Z"
                  stroke="#6A6D78"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13 13.0001L17 17.0002"
                  stroke="#6A6D78"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Box>
          </Box>
          <Tooltip title={search} arrow>
            <Button
              onClick={handleSearch}
              className="download-btn solidButton"
              sx={{ marginLeft: 1 }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path
                  d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                  fill="#FFFFFF"
                />
              </svg>
            </Button>
          </Tooltip>
        </Box>
        {/* <Box className="selectionDiv searchFlex">
          <Tablesearch />
        </Box> */}
        <Box className="selectionDiv searchFlex">
          <Typography className="label" component={"label"}>
            Select Trade Type
          </Typography>
          <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleChange}
            className="tradeType-toggle"
          >
            <ToggleButton value="paper">Paper</ToggleButton>
            <ToggleButton value="live">Live</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Table col={col3} rows={rows3} />
      </Box>
    </Box>
  );
}
