import React from 'react';
import { Box } from '@mui/material';
import Builder from './Builder';

export default function Strategybuilder() {

  return (
    <>
      <Box className='strategyBox_builder'>
        <Builder />
      </Box>
    </>
  )
}
