import * as React from "react";
import { FormControl, Box, Select, MenuItem, Typography } from "@mui/material";
import "./Dropdown.scss";

export default function Tableaction(props) {
  const { handleSelect, value, val, title } = props;

  // Dropdown tab

  const [report, setreport] = React.useState(" ");
  const drpchnage = (event) => {
    setreport(event.target.value);
  };
  return (
    <>
      <Box
        className="selectionDiv border scroll display-none"
        sx={{
          display: { xs: "flex" },
          marginLeft: { xs: "0 !important" },
          padding: "1rem",
        }}
      >
        {/* <FormControl className='dropdown-ap'>
          <Autocomplete
            onChange={drpchnage}
            defaultValue={report}
            options={props.val}
            renderInput={(params) => <TextField {...params} />}
            selectOnFocus={false}
            className='dropdown'
          />
        </FormControl> */}
        <Typography component={"p"}>{title} &nbsp;</Typography>
        <FormControl className="dropdown-ap">
          <Select
            className="dropdown"
            value={value?.toLowerCase()}
            onChange={handleSelect}
            renderValue={
              value !== ""
                ? undefined
                : () => <Typography sx={{ opacity: 0.5 }}>Select</Typography>
            }
          >
            {val?.map((item, index) => (
              <MenuItem value={item?.toLowerCase()} key={index}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
}
