import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  ToggleButton,
  ToggleButtonGroup,
  TablePagination,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Tooltip,
  DialogActions,
  TableContainer,
  RadioGroup,
  Radio,
} from "@mui/material";
import Dropdown from "../../Dropdown/Dropdown";
import "../../Scanner/Scanner.scss";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  activateCopyTrade,
  addSegmentCopytrade,
  deleteSegmentCopytrade,
  getProfile,
  getSegmentCopytrade,
  stopCopyTrade,
} from "../../../redux/actions/authActions";
import { generatePopup } from "../../../utils/popup";
import { getSegment } from "../../../redux/actions/positionAction";
import noData from "../../../images/page_456.jpg";
import close from "../../../images/close.png";
import DeleteIcon from "@mui/icons-material/Delete";

// Dropdown
const drpValue = [
  "All",
  "Banknifty Fut",
  "BANKNIFTY",
  "Nifty Fut",
  "Banknify Option",
  "Nifty Option",
  "MCX Fut",
  "Currency Fut",
  "NSE Cash",
  "Stock Fut",
  "Banknifty Option Sell",
  "MCX Otion",
  "Finnifty Fut",
  "Finnifty Option",
];

const drpValue2 = ["Select TradingType", "Intraday", "Positional"];

const drpValue3 = ["Select TradeType", "Live", "Paper"];

const col = [
  "Sr No.",
  "Segments",
  "Lot Size",
  "Trading Type",
  "Trade Type",
  "Actions",
];

const rows = [
  {
    id: 1,
    segment: "Bank nifty",
    qty: 5,
    trade: "Intraday",
    trading: "Live",
  },
  {
    id: 1,
    segment: "Bank nifty",
    qty: 5,
    trade: "Intraday",
    trading: "Live",
  },
  {
    id: 1,
    segment: "Bank nifty",
    qty: 5,
    trade: "Intraday",
    trading: "Live",
  },
  {
    id: 1,
    segment: "Bank nifty",
    qty: 5,
    trade: "Intraday",
    trading: "Live",
  },
  {
    id: 1,
    segment: "Bank nifty",
    qty: 5,
    trade: "Intraday",
    trading: "Live",
  },
  {
    id: 1,
    segment: "Bank nifty",
    qty: 5,
    trade: "Intraday",
    trading: "Live",
  },
];
export default function Copytrade() {
  const segmentList = useSelector((state) => state?.Position?.segmentList);

  const [segment, setSegment] = useState("Select Segment");
  const [alignment, setAlignment] = React.useState("live");
  const [Trade, setTrade] = React.useState("Select TradingType");
  const [Trading, setTrading] = React.useState("Select TradeType");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [consent, setConsent] = React.useState(false);
  const [copyTrade, setCopyTrade] = React.useState(true);
  const [open, setOpen] = useState(false);
  const [fields, setFields] = useState({});
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [openChange, setOpenChange] = useState(false);
  const [id, setId] = useState(false);

  const data = useSelector((state) => state?.Auth?.copytrades);
  const [qty, setQty] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSegmentCopytrade());
  }, []);

  const segmentData = ["Select Segment"]?.concat(segmentList);

  //select segment
  const handlesegment = (event) => {
    setSegment(event.target.value);
  };

  //select Trade
  const handleTrade = (event) => {
    setTrade(event.target.value);
  };

  //select Trading
  const handleTrading = (event) => {
    setTrading(event.target.value);
  };

  const handleChange = (e, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const userData = useSelector((state) => state?.Auth?.authProfile?.[0]);

  const handleChangeConsent = (e) => {
    setConsent(e?.target?.checked ? true : false);
    setOpen(true);
  };

  const handleActivate = () => {
    dispatch(
      activateCopyTrade({
        is_copytrade:
          copyTrade === true ? "True" : copyTrade === false ? "False" : "",
        is_copy_consent:
          consent === true ? "True" : consent === false ? "False" : "",
      })
    ).then((res) => {
      if (res) {
        setOpen(false);
        if (res?.status === 200) {
          generatePopup("success", res?.data?.message);
          dispatch(getProfile());
          dispatch(getSegmentCopytrade());
        }
      }
    });
  };

  const handleCreate = () => {
    const obj = {
      segment: segment.toUpperCase(),
      lot: qty,
      tradetype: Trade === "intraday" ? "Intraday" : "Positional",
      tradingtype: Trading === "live" ? "Live" : "Paper",
    };
    dispatch(addSegmentCopytrade(obj)).then((res) => {
      if (res) {
        if (res?.status === 200) {
          generatePopup("success", res?.data?.message);
          dispatch(getSegmentCopytrade());
        }
      }
    });
  };

  const handleStop = () => {
    dispatch(
      stopCopyTrade({
        is_copytrade: copyTrade ? "True" : "False",
      })
    ).then((res) => {
      generatePopup("success", res?.data?.message);
      dispatch(getSegmentCopytrade());
    });
  };

  useEffect(() => {
    dispatch(getSegment());
  }, []);

  const handleDeleteOpen = (e) => {
    setDeleteOpen(true);
    setId(e?.id);
  };

  const handleCloseDelete = () => {
    setDeleteOpen(false);
  };

  const handleDelete = () => {
    dispatch(
      deleteSegmentCopytrade({
        id: id,
      })
    ).then((res) => {
      if (res) {
        dispatch(getSegmentCopytrade());
        setDeleteOpen(false);
        if (res?.status === 200) {
          generatePopup("success", res?.data?.message);
        }
      }
    });
  };

  const handleOpenChange = () => {
    setOpenChange(false);
    setCopyTrade(!copyTrade);
  };

  useEffect(() => {
    setConsent(userData?.is_copy_trade_consent);
    setCopyTrade(userData?.is_copy_trade);
  }, [userData]);

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid item md={4}>
          <Box className="term_checkbox" sx={{ paddingLeft: 1.5 }}>
            <FormGroup>
              <FormControlLabel
                style={{ marginLeft: "10px" }}
                control={
                  !userData?.is_copy_trade_consent ? (
                    <Checkbox onChange={handleChangeConsent} />
                  ) : (
                    <></>
                  )
                }
                label={
                  <Typography
                    onClick={handleClickOpen}
                    sx={{ fontSize: 14, textDecoration: "underline" }}
                  >
                    I have read the CopyTrade Consent and Terms & Conditions
                  </Typography>
                }
              />
            </FormGroup>
          </Box>
        </Grid>
        <Grid item md={8}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              flexWrap: "wrap",
              paddingRight: 2,
              marginBottom: 2,
            }}
          >
            {/* <Typography className="label" component={"label"}>
              Copy Trade
            </Typography> */}
            <Box className="onoff_toggle">
              <RadioGroup
                onChange={(e) => {
                  if (!userData?.is_copy_trade_consent) {
                    return generatePopup(
                      "error",
                      "Please check terms & condition"
                    );
                  }
                  setOpenChange(true);
                  setCopyTrade(e?.target?.value === "true" ? true : false);
                }}
                value={copyTrade}
                row
                defaultValue={true}
              >
                <FormControlLabel value={true} control={<Radio />} label="ON" />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="OFF"
                />
              </RadioGroup>
            </Box>

            {/* <Box className="formItems">
              <Button
                onClick={handleActivate}
                className="formSolid-btn"
                sx={{ direction: "block", marginLeft: "10px" }}
              >
                Activate
              </Button>
            </Box> */}

            <Box
              className="selectiondiv-box"
              sx={{ marginTop: "0 !important", marginLeft: 2 }}
            >
              <Box className="selectionDiv searchFlex">
                <Dropdown
                  val={segmentData}
                  handleSelect={handlesegment}
                  value={segment}
                />
                <Dropdown
                  val={drpValue2}
                  handleSelect={handleTrade}
                  value={Trade}
                />
                <Dropdown
                  val={drpValue3}
                  handleSelect={handleTrading}
                  value={Trading}
                />
              </Box>
            </Box>

            <Box
              className="inputFields space"
              sx={{ width: "auto !important" }}
            >
              <TextField
                onChange={(e) => setQty(e.target.value)}
                placeholder="Enter Lot"
              />
            </Box>

            <Tooltip
              placement="top"
              title={<Typography sx={{ fontSize: 14 }}>Add Data</Typography>}
              arrow
            >
              <Button
                onClick={handleCreate}
                className="download-btn solidButton"
                sx={{ marginLeft: 1.4 }}
              >
                <AddCircleIcon />
              </Button>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>

      <TableContainer sx={{ MarginTop: 16, maxHeight: 440 }}>
        <Table
          stickyHeader
          sx={{ minWidth: "100%" }}
          className={`table tableData`}
        >
          <TableHead>
            <TableRow>
              {/* <TableCell>Sr No.</TableCell> */}
              {col.map((colum, index) => (
                <TableCell key={index}>{colum}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length > 0 ? (
              <>
                {data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((items, i) => (
                    <TableRow key={i}>
                      <TableCell>{i + 1}</TableCell>
                      <TableCell> {items?.segment}</TableCell>
                      <TableCell>{items.lot}</TableCell>
                      <TableCell>{items.tradetype}</TableCell>
                      <TableCell>{items.tradingtype}</TableCell>
                      <TableCell>
                        <Box className="tableActions">
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            className="actionButton"
                          >
                            <Tooltip
                              title={
                                <Typography sx={{ fontSize: "1.4rem" }}>
                                  Delete
                                </Typography>
                              }
                              arrow
                              placement="top"
                            >
                              <Button
                                onClick={() => handleDeleteOpen(items)}
                                className="del"
                                variant="text"
                              >
                                <DeleteIcon />
                                {/* <svg
                                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                  focusable="false"
                                  aria-hidden="true"
                                  viewBox="0 0 24 24"
                                  data-testid="DeleteIcon"
                                >
                                  <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path>
                                </svg> */}
                              </Button>
                            </Tooltip>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
              </>
            ) : (
              <TableRow className="noHover">
                <TableCell colSpan={12}>
                  <Box className="no-data">
                    <img src={noData} />
                  </Box>
                  <Typography component={"p"} className="no-dataText">
                    You have no position, place an order to open a new position
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        className="tablePagination"
        sx={{
          display: "flex",
          justifyContent: { xs: "center", md: "end" },
          padding: "0 1rem",
        }}
      >
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, { label: "All", value: -1 }]}
          count={data?.length}
          rowsPerPage={rowsPerPage}
          SelectProps={{
            fontSize: "1.6rem",
            native: false,
          }}
          page={page}
          className="tablePagination"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        className="term_condition_modal"
      >
        <DialogTitle>
          <Typography component={"h2"} className="modal_header">
            COPY TRADING CONSENT T&C FORM
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText tabIndex={-1}>
            {/* <Typography component={"h3"} sx={{ fontWeight: "600" }}>NON-DISCLOSURE AGREEMENT WITH TERMS & CONDITION.</Typography> */}

            <Typography component={"p"}>
              This Copy Trading Consent Form ("Consent Form") is entered into
              between "{userData?.firstname + " " + userData?.lastname}
              "(referred as Customer) and "{userData?.refrecode}"(referred as
              Provider) on the date of {dayjs().format("DD-MMM-YYYY")}.
            </Typography>
            <Typography component={"p"}>
              Dematade Algo Technology Solutions Private Limited is a technology
              provider and offers the use of its online platform. We do not
              provide financial or investment advice to any user or vendors, and
              we are not responsible for any profit or loss incurred in users'
              accounts resulting from their use of our Platform.
            </Typography>

            <Typography component={"h3"} sx={{ fontWeight: "600" }}>
              1. INTRODUCTION:
            </Typography>
            <Typography component={"p"}>
              Customer acknowledges and agrees that Provider offers copy trading
              services where Customer's account can be automatically
              synchronized with the trading strategies of professional traders
              or other clients of Provider ("Signal Providers"). By signing this
              Consent Form, Customer hereby consents to participate in copy
              trading services as per the terms and conditions outlined herein.
            </Typography>

            <Typography component={"h3"} sx={{ fontWeight: "600" }}>
              2. ACKNOWLGMENT OF RISKS:
            </Typography>
            <Typography component={"p"}>
              Customer acknowledges that trading in financial markets involves
              significant risks, including the risk of losing the entire
              investment. Copy trading carries additional risks due to the
              possibility of replicating the trading strategies of Signal
              Providers. Customer is responsible for fully understanding these
              risks before participating in copy trading.
            </Typography>

            <Typography component={"h3"} sx={{ fontWeight: "600" }}>
              3. SELECTION OF SIGNAL PROVIDERS:
            </Typography>
            <Typography component={"p"}>
              Provider may offer a selection of Signal Providers for Customer to
              choose from. Customer has the sole discretion to select Signal
              Providers to follow. Dematade Algo Technology Solutions Private
              Limited does not provide financial advice or recommendations on
              Signal Providers. Customer is responsible for conducting their own
              due diligence before selecting Signal Providers.
            </Typography>

            <Typography component={"h3"} sx={{ fontWeight: "600" }}>
              4. ACCOUNT ACCESS:
            </Typography>
            <Typography component={"p"}>
              Customer hereby authorizes Dematade Algo Technology Solutions
              Private Limited to access and execute trading orders in Customer's
              account in accordance with the trading signals generated by the
              selected Signal Providers. Customer acknowledges that Provider
              will not have access to Customer's withdrawal capabilities or
              personal information beyond what is necessary for copy trading.
            </Typography>

            <Typography component={"h3"} sx={{ fontWeight: "600" }}>
              5. PERFORMANCE NOT GUARANTEED:
            </Typography>
            <Typography component={"p"}>
              Customer understands that past performance of Signal Providers is
              not indicative of future results. Provider makes no guarantees or
              promises regarding the profitability or success of copy trading.
              Customer is solely responsible for monitoring their account and
              managing their risk.
            </Typography>
            <Typography component={"h3"} sx={{ fontWeight: "600" }}>
              6. TERMINATION AND MODIFICATIONS:
            </Typography>
            <Typography component={"p"}>
              Customer may terminate their participation in copy trading at any
              time by providing written notice to Provider. Provider reserves
              the right to modify or terminate the copy trading service at its
              discretion without prior notice.
            </Typography>
            <Typography component={"h3"} sx={{ fontWeight: "600" }}>
              7. FEES AND COMPENSATION:
            </Typography>
            <Typography component={"p"}>
              Customer acknowledges that Provider may charge fees for copy
              trading services, which will be outlined in a separate agreement
              or fee schedule.
            </Typography>
            <Typography component={"h3"} sx={{ fontWeight: "600" }}>
              8. INDEMNIFICATION:
            </Typography>
            <Typography component={"p"}>
              Customer agrees to indemnify and hold harmless Provider, its
              employees, and Signal Providers from any losses, claims, or
              liabilities arising from Customer's participation in copy trading.
            </Typography>
            <Typography component={"h3"} sx={{ fontWeight: "600" }}>
              9. GOVERNING LAW:
            </Typography>
            <Typography component={"p"}>
              This Consent Form Agreement shall be governed in accordance with
              the laws of India and the courts of Maharashtra will have the
              exclusive jurisdiction.
            </Typography>
            <Typography component={"h3"} sx={{ fontWeight: "600" }}>
              10. AGREEMENT ACKNOWLEDGMENT:
            </Typography>
            <Typography component={"p"}>
              By Agreeing below, Customer acknowledges that they have read,
              understood, and agree to the terms and conditions outlined in this
              Consent Form.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            padding: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {userData?.is_copy_trade_consent && (
            <Typography component={"h3"} sx={{ fontWeight: "600" }}>
              {" "}
              You Already Agreed The CopyTrade Consent...
            </Typography>
          )}
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button onClick={handleClose} className="formOutline-btn">
              Cancel
            </Button>
            {!userData?.is_copy_trade_consent && (
              <Button
                onClick={() => {
                  handleActivate();
                }}
                className="formSolid-btn fetchReport-btn"
              >
                Agree
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>

      {/* delete copytrade */}
      <Dialog
        open={deleteOpen}
        onClose={handleCloseDelete}
        className="commonModal squareOff"
      >
        <Box className="modalHeader" sx={{ justifyContent: "end" }}>
          <Typography component={"h4"}>Delete Copy Trade</Typography>
          <Button onClick={handleCloseDelete} className="closeModal">
            <img src={close} />
          </Button>
        </Box>
        <DialogContent sx={{ padding: "0" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="alertIcons">
              <svg
                width="1052"
                height="1052"
                viewBox="0 0 1052 1052"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M558 334C558 316.3 543.7 302 526 302C508.3 302 494 316.3 494 334V590C494 607.7 508.3 622 526 622C543.7 622 558 607.7 558 590V334ZM526 750C536.609 750 546.783 745.786 554.284 738.284C561.786 730.783 566 720.609 566 710C566 699.391 561.786 689.217 554.284 681.716C546.783 674.214 536.609 670 526 670C515.391 670 505.217 674.214 497.716 681.716C490.214 689.217 486 699.391 486 710C486 720.609 490.214 730.783 497.716 738.284C505.217 745.786 515.391 750 526 750Z"
                  fill="#4987FE"
                />
                <circle
                  cx="526"
                  cy="526"
                  r="507"
                  stroke="#5086EE"
                  stroke-width="38"
                />
              </svg>
            </Box>
            <Typography
              component={"h4"}
              sx={{
                fontSize: "1.8rem",
                textAlign: "center",
                marginTop: "2rem",
              }}
              className="alertText"
            >
              Are You Sure You Want to Delete Copy Trade !
            </Typography>

            <Box sx={{ display: { xs: "flex" }, marginTop: 2 }}>
              <Button
                onClick={handleCloseDelete}
                className="modal-btn btn-danger"
                sx={{ marginRight: "0.5rem" }}
              >
                Discard
              </Button>
              <Button
                onClick={handleDelete}
                className="modal-btn btn-primary"
                sx={{ marginLeft: "0.5rem" }}
              >
                Confirm
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openChange}
        onClose={handleOpenChange}
        className="commonModal squareOff"
      >
        <Box className="modalHeader" sx={{ justifyContent: "end" }}>
          <Typography component={"h4"}>Copy Trade</Typography>
          <Button onClick={handleOpenChange} className="closeModal">
            <img src={close} />
          </Button>
        </Box>
        <DialogContent sx={{ padding: "0" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="alertIcons">
              <svg
                width="1052"
                height="1052"
                viewBox="0 0 1052 1052"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M558 334C558 316.3 543.7 302 526 302C508.3 302 494 316.3 494 334V590C494 607.7 508.3 622 526 622C543.7 622 558 607.7 558 590V334ZM526 750C536.609 750 546.783 745.786 554.284 738.284C561.786 730.783 566 720.609 566 710C566 699.391 561.786 689.217 554.284 681.716C546.783 674.214 536.609 670 526 670C515.391 670 505.217 674.214 497.716 681.716C490.214 689.217 486 699.391 486 710C486 720.609 490.214 730.783 497.716 738.284C505.217 745.786 515.391 750 526 750Z"
                  fill="#4987FE"
                />
                <circle
                  cx="526"
                  cy="526"
                  r="507"
                  stroke="#5086EE"
                  stroke-width="38"
                />
              </svg>
            </Box>
            <Typography
              component={"h4"}
              sx={{
                fontSize: "1.8rem",
                textAlign: "center",
                marginTop: "2rem",
              }}
              className="alertText"
            >
              Are You Sure You Want to {copyTrade === true ? "On" : "Off"} Copy
              Trade !
            </Typography>

            <Box sx={{ display: { xs: "flex" }, marginTop: 2 }}>
              <Button
                onClick={handleOpenChange}
                className="modal-btn btn-danger"
                sx={{ marginRight: "0.5rem" }}
              >
                Discard
              </Button>
              <Button
                onClick={() => {
                  handleStop();
                  setOpenChange(false);
                }}
                className="modal-btn btn-primary"
                sx={{ marginLeft: "0.5rem" }}
              >
                Confirm
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
