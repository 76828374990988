import React, { useEffect, useState } from "react";
import { Box, Grid, Button, Tooltip, Typography } from "@mui/material";
import Table from "../../../Table/Table";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Dropdown from "../../../Dropdown/Dropdown";
import moment from "moment";
import { businessReport } from "../../../../redux/actions/brokerAction";
import { useDispatch, useSelector } from "react-redux";
import {
  getReferCode,
  getUserVendor,
} from "../../../../redux/actions/authActions";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";

const col = [
  "Name",
  "Mobile",
  "Email",
  "Referal Code",
  "Username",
  "Plan Start Date",
  "Plan End Date",
  "Plan Status",
];
// const rows = [
//   {
//     name: "John ",
//     Mobile: "1324657988",
//     Email: "dummy@demo.com",
//     refercode: 120,
//     cID: 1235,
//     psd: "15-05-2023",
//     ped: "31-05-2023",
//     planstatus: "status",
//   },
// ];

export default function Bussinesreport() {
  const [todate, setTodate] = useState(dayjs().format("YYYY-MM-DD"));
  const [fromdate, setFromdate] = useState(dayjs().format("YYYY-MM-DD"));
  const [subscriptionValue, setSubscriptionValue] = useState("All");
  const [referalcode, setReferalcode] = useState("");

  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.Auth?.authProfile?.[0]);

  useEffect(() => {
    if (userData) {
      setReferalcode(userData?.username?.toLowerCase());
    }
  }, [userData]);

  useEffect(() => {
    if (userData?.is_powerbroker) {
      const obj = {
        refrecode: userData?.username,
      };
      dispatch(getUserVendor(obj));
    } else {
      dispatch(getReferCode());
    }
  }, [userData]);

  const refrecodes = useSelector((state) => state?.Auth?.refreCodes?.refrecode);
  const userVendors = useSelector((state) => state?.Auth?.userVendors);

  const capitalize = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  const reportData = useSelector((state) => state?.Broker?.businessReport);

  function downloadCSV(data) {
    // Step 2: Convert the JSON data to CSV format
    const csvContent =
      "data:text/csv;charset=utf-8," +
      Object.keys(data[0])
        .map((key) => key)
        .join(",") +
      "\n" +
      data
        .map((row) =>
          Object.values(row)
            .map((value) => `"${value}"`)
            .join(",")
        )
        .join("\n");

    // Step 3: Create a data URI and trigger the download
    const encodedURI = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedURI);
    link.setAttribute("download", "Business_Report.csv");
    document.body.appendChild(link);
    link.click();
  }

  useEffect(() => {
    if (referalcode) {
      const obj = {
        refercode: capitalize(referalcode),
        startdate: fromdate,
        enddate: todate,
        subscription: capitalize(subscriptionValue),
      };
      dispatch(businessReport(obj)).then((res) => {
        // console.log("res", res);
      });
    }
  }, [referalcode, fromdate, todate, subscriptionValue]);

  const handleRefresh = () => {
    if (referalcode) {
      const obj = {
        refercode: capitalize(referalcode),
        startdate: fromdate,
        enddate: todate,
        subscription: capitalize(subscriptionValue),
      };
      dispatch(businessReport(obj)).then((res) => {
        console.log("res", res);
      });
    }
  };
  // drop down
  const drpValue = ["All", "Active", "Inactive"];
  const drpValue2 = ["Referal Code", "All Refer Code", "SDTrade", "Trade 1"];

  const download = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      Download
    </Typography>
  );
  const fetchreport = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      Featch Report
    </Typography>
  );

  const rows = reportData?.map((e) => {
    return {
      name: e?.firstname,
      phone: e?.phone,
      email: e?.email,
      refrecode: e?.refrecode,
      username: e?.username,
      startDate: e?.activate_at,
      endDate: e?.expire_at,
      status: e?.is_subscribe ? "Active" : "Inactive",
    };
  });

  //select to date
  const handleTodate = (date) => {
    setTodate(dayjs(date).format("YYYY-MM-DD"));
  };

  //select fromdate
  const handleFromdate = (date) => {
    setFromdate(dayjs(date).format("YYYY-MM-DD"));
  };

  //select Customervalue
  const handleCustomervalue = (event) => {
    setSubscriptionValue(event.target.value);
  };

  //select Referalcode
  const handleReferalcode = (event) => {
    setReferalcode(event.target.value);
  };

  return (
    <>
      <Box className="tabelBox">
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12}>
            <Box className="selectiondiv-box m-rb">
              <Box className="selectionDiv">
                <Typography component={"label"} className="label">
                  From Date:{" "}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="datePicker"
                    value={dayjs(fromdate)}
                    onChange={handleFromdate}
                  />
                </LocalizationProvider>
              </Box>
              <Box className="selectionDiv">
                <Typography component={"label"} className="label">
                  To Date:{" "}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="datePicker"
                    onChange={handleTodate}
                    value={dayjs(todate)}
                  />
                </LocalizationProvider>
              </Box>

              <Dropdown
                val={drpValue}
                handleSelect={handleCustomervalue}
                value={subscriptionValue}
                title={"Subscription"}
              />
              <Dropdown
                val={
                  userData?.is_powerbroker
                    ? [userData?.username]?.concat(userVendors)
                    : refrecodes
                }
                handleSelect={handleReferalcode}
                value={referalcode}
                title={"Referal Code"}
              />

              <Box className="selectionDiv searchFlex">
                <Tooltip title={fetchreport} arrow>
                  <Button
                    onClick={handleRefresh}
                    className="fetchReport-btn solidButton"
                  >
                    <CloudSyncIcon />
                  </Button>
                </Tooltip>

                {rows?.length > 0 && (
                  <Tooltip title={download} arrow>
                    <Button
                      onClick={() => downloadCSV(rows)}
                      className="download-btn solidButton"
                      sx={{ marginLeft: 1 }}
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17 11.6669V15.2224C17 15.6939 16.8127 16.1461 16.4793 16.4795C16.1459 16.8129 15.6937 17.0002 15.2222 17.0002H2.77778C2.30628 17.0002 1.8541 16.8129 1.5207 16.4795C1.1873 16.1461 1 15.6939 1 15.2224V11.6669"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4.55542 7.22229L8.99987 11.6667L13.4443 7.22229"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9 11.6667V1"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Button>
                  </Tooltip>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Table col={col} rows={rows} />
      </Box>
    </>
  );
}
