import React, { useEffect, useState } from "react";
import Signform from "./Outauth/Signform/Signform";
import "@fontsource/poppins";
import "./App.scss";
import { Typography } from "@mui/material";
import wp from "../src/images/wp.png";
import Mainlayout from "./Inauth/Mainlayout/Mainlayout";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Signin_up from "./Outauth/Signform/Signin_up";
import Signup from "./Outauth/Signform/Signup";
import { PANEL_API_URL } from "./config";
import { useDispatch, useSelector } from "react-redux";
import { getSocialLink } from "./redux/actions/brokerAction";
import { getProfile } from "./redux/actions/authActions";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState();

  let token = localStorage.getItem("authToken");
  const dispatch = useDispatch();

  const socialLinks = useSelector((state) => {
    return state?.Broker?.getSocialData?.[0];
  });

  const userData = useSelector((state) => {
    return state.Auth.authProfile;
  });
  useEffect(() => {
    if (userData?.length > 0) {
      dispatch(
        getSocialLink({
          refrecode: userData?.[0]?.refrecode,
        })
      );
    }
  }, [userData]);

  // useEffect(() => {
  //   dispatch(getProfile());
  // }, []);

  // #03a84e

  useEffect(() => {
    if (token && token !== null && token !== "undefined") {
      // var Tawk_API = Tawk_API || {},
      //   Tawk_LoadStart = new Date();
      // (function () {
      //   var s1 = document.createElement("script"),
      //     s0 = document.getElementsByTagName("script")[0];
      //   s1.async = true;
      //   s1.src = "https://embed.tawk.to/65d880fe8d261e1b5f646696/1hnarfp6v";
      //   s1.charset = "UTF-8";
      //   s1.setAttribute("crossorigin", "*");
      //   s0.parentNode.insertBefore(s1, s0);
      // })();

      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [token]);

  useEffect(() => {
    let search = {};

    if (window.location.search.length) {
      window.location.search
        .substring(1)
        .split("&")
        .map((a) => {
          let b = a.split("=");
          search[b[0]] = b[1];
        });
      if ("token" in search && "phone" in search && "mode" in search) {
        let { token, mode, phone } = search;
        setIsLoggedIn(true);
        localStorage.setItem("authToken", token);
        localStorage.setItem("phone", phone);
        // localStorage.setItem("mode", mode);
        if (!token) {
          window.location.replace(`${PANEL_API_URL}/signin`);
        }
        window.location.replace(`${PANEL_API_URL}`);
      }
    }
  }, []);

  if (isLoggedIn == undefined) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress disableShrink />
      </div>
    );
  }

  return isLoggedIn ? (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/*" element={<Mainlayout />}></Route>
        </Routes>
      </Router>

      <Typography
        component={"a"}
        href={`${socialLinks?.whatsapp}`}
        target={"blank"}
        className="wp_button"
      >
        <img src={wp} />
      </Typography>
    </>
  ) : (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/*" element={<Signin_up />}></Route>
        </Routes>
      </Router>

      {/* <Typography
        component={"a"}
        href={`${socialLinks?.whatsapp}`}
        target={"blank"}
        className="wp_button"
      >
        <img src={wp} />
      </Typography> */}
    </>
  );
}

export default App;
