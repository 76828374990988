import * as actionTypes from "../actionTypes";

const initState = {
  loading: false,
  error: "",
  exchangeList: [],
  watchListLive: [],
  expiryList: [],
  optionExpiry: [],
  optionStrikeData: [],
  watchListData: []
};

const store = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.NSE_EXCHANGE_SUCCESS:
      return {
        ...state,
        exchangeList: action?.payload,
      };
    case actionTypes.NSE_EXCHANGE_FAIL:
      return {
        ...state,
        error: action?.payload,
      };
    case actionTypes.FUTURE_SYMBOL_SUCCESS:
      return {
        ...state,
        exchangeList: action?.payload,
      };
    case actionTypes.FUTURE_SYMBOL_FAIL:
      return {
        ...state,
        error: action?.payload,
      };
    case actionTypes.EXPIRY_EXCHANGE_SUCCESS:
      return {
        ...state,
        expiryList: action?.payload,
      };
    case actionTypes.OPTION_EXPIRY_DATA:
      return {
        ...state,
        optionExpiry: action?.payload,
      };
    case actionTypes.OPTION_STRIKE_DATA:
      return {
        ...state,
        optionStrikeData: action?.payload,
      };
    case actionTypes.GET_WATCH_LIST_SUCCESS:
      return {
        ...state,
        watchListData: action?.payload,
      };
    case actionTypes.EXPIRY_EXCHANGE_FAIL:
      return {
        ...state,
        error: action?.payload,
      };
    case actionTypes.STRIKEPRICE_EXCHANGE_SUCCESS:
      return {
        ...state,
        expiryList: action?.payload,
      };
    case actionTypes.STRIKEPRICE_EXCHANGE_FAIL:
      return {
        ...state,
        error: action?.payload,
      };

    case actionTypes.GET_WATCH_LIST_LIVE_DATA_SUCCESS:
      return {
        ...state,
        watchListLive: action?.payload,
      };
    default:
      return state;
  }
};

export default store;
