import React from "react";
import { Box, Tab, List, ListItem, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Privacypolicy from "./Profilepages/Privacypolicy";
import FAQ from "./Profilepages/FAQ";
import Termuse from "./Profilepages/Termuse";
import How_it_work from "./Profilepages/How_it_work";
import Delete_account from "./Profilepages/Delete_account";
import Vendor_agrements from "./Profilepages/Vendor_agrement";
import Settings from "./Profilepages/Settings";
import Desclaimer from "./Profilepages/Desclaimer";
import Tradingstring from "../Trandingstring/Trandingstring";
import Funview from "./Profilepages/Fundview";
import Copytrade from "./Profilepages/Copytrade";

import "./Profile.scss";

export default function Profile() {
  // Main tab
  const [value, setValue] = React.useState("maintab1");
  const handleChange = (e, newValue = "string") => {
    setValue(newValue);
  };

  return (
    <>
      <Box className="tabs">
        <TabContext value={value}>
          <TabList className="main-tab" onChange={handleChange}>
            <Tab label="Privacy Policy" value={"maintab1"} />
            {/* <Tab label="Terms of use" value={"maintab2"} /> */}
            <Tab label="FAQ" value={"maintab3"} />
            {/* <Tab label="How It Works" value={"maintab4"} /> */}
            <Tab label="Delete Account" value={"maintab5"} />
            {/* <Tab label="Vendor Agrements" value={"maintab6"} /> */}
            {/* <Tab label="Desclaimer" value={"maintab8"} /> */}
            <Tab label="Trading Bridge" value={"maintab9"} />
            <Tab label="Fund View" value={"maintab10"} />
            {/* <Tab label="Settings" value={"maintab7"} /> */}
            <Tab label="Copy Trade" value={"maintab11"} />
          </TabList>

          <Box className="tabBox" sx={{ padding: { xs: "0 ", ms: "3rem" } }}>
            <TabPanel
              value={"maintab1"}
              className="sub-tabs"
              sx={{ padding: 0, height: "70vh", overflowY: "auto" }}
            >
              <Privacypolicy />
            </TabPanel>
            <TabPanel
              value={"maintab2"}
              className="sub-tabs"
              sx={{ padding: 0, height: "70vh", overflowY: "auto" }}
            >
              <Termuse />
            </TabPanel>
            <TabPanel
              value={"maintab3"}
              className="sub-tabs"
              sx={{ padding: 0, height: "70vh", overflowY: "auto" }}
            >
              <FAQ />
            </TabPanel>
            <TabPanel
              value={"maintab4"}
              className="sub-tabs"
              sx={{ padding: 0, height: "70vh", overflowY: "auto" }}
            >
              <How_it_work />
            </TabPanel>
            <TabPanel
              value={"maintab5"}
              className="sub-tabs"
              sx={{ padding: 0, height: "70vh", overflowY: "auto" }}
            >
              <Delete_account />
            </TabPanel>
            <TabPanel
              value={"maintab6"}
              className="sub-tabs"
              sx={{ padding: 0, height: "70vh", overflowY: "auto" }}
            >
              <Vendor_agrements />
            </TabPanel>
            {/* <TabPanel
              value={"maintab7"}
              className="sub-tabs"
              sx={{ padding: 0, height: "70vh", overflowY: "auto" }}
            >
              <Settings />
            </TabPanel> */}
            <TabPanel
              value={"maintab8"}
              className="sub-tabs"
              sx={{ padding: 0, height: "70vh", overflowY: "auto" }}
            >
              <Desclaimer />
            </TabPanel>
            <TabPanel
              value={"maintab9"}
              className="sub-tabs"
              sx={{ padding: 0, height: "70vh", overflowY: "auto" }}
            >
              <Tradingstring />
            </TabPanel>
            <TabPanel
              value={"maintab10"}
              className="sub-tabs"
              sx={{ padding: 0, height: "70vh", overflowY: "auto" }}
            >
              <Funview />
            </TabPanel>
            <TabPanel
              value={"maintab11"}
              className="sub-tabs"
              sx={{ padding: 0, height: "70vh", overflowY: "auto" }}
            >
              <Copytrade />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </>
  );
}
