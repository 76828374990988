export const API_URL = process.env.REACT_APP_LIVE_API_URL;
export const PAPER_API_URL = process.env.REACT_APP_PAPER_API_URL;

export const POSITION_SOCKET_URL =
  process.env.REACT_APP_TRADE_CUSTOMER_WEBSOCKET_URL;
//
export const SITE_URL = process.env.REACT_APP_WEBSITE_URL;

// export const PANEL_API_URL = "http://localhost:3001/";
export const PANEL_API_URL = process.env.REACT_APP_TRADEPANEL_URL;
///
