import { POSITION_SOCKET_URL } from "../config";
import {
  getDayGainLoss,
  getLiveData,
  getLiveNotifications,
  getPriceToken,
} from "../redux/actions/commonActions";
import { getWatchListUpdateData } from "../redux/actions/marketWatchAction";
import {
  getAdminOpenLive,
  getLivePositions,
  getPaperPositions,
  getSymbolData,
} from "../redux/actions/positionAction";
import { getPnlData } from "../redux/actions/stratergyAction";

export async function socket(endPoint, url) {
  return await new Promise((resolve, reject) => {
    const authToken = localStorage.getItem("authToken");

    var ws = new WebSocket(
      `${url ? url : POSITION_SOCKET_URL
      }/${endPoint}/?authorization=${authToken}`
    );

    ws.onopen = () => {
      // ws.send({});

      console.log("connected", endPoint);

      if (ws && ws.readyState === WebSocket.OPEN) {
        resolve(ws);
        // return ws;
      }
    };
  });
}

export async function paperSocket(endPoint) {
  return await new Promise((resolve, reject) => {
    const authToken = localStorage.getItem("authToken");

    var ws = new WebSocket(
      `${process.env.REACT_APP_PAPER_CUSTOMER_WEBSOCKET_URL}/${endPoint}/?authorization=${authToken}`
    );

    ws.onopen = () => {
      // ws.send({});

      console.log("connected", endPoint);

      if (ws && ws.readyState === WebSocket.OPEN) {
        resolve(ws);
        // return ws;
      }
    };
  });
}

export async function closeSocket(ws, endpoint) {
  return await new Promise((resolve, reject) => {
    // const authToken = localStorage.getItem("authToken");

    if (ws) {
      ws.onclose = () => {
        resolve();
      };
      ws.close();
      resolve();
    }
  });
}

export function messageEvent(ws, endPoint, dispatch, data = {}) {
  if (
    endPoint === "watchlist" ||
    endPoint === "tokenprice" ||
    endPoint === "liveposition" ||
    endPoint === "paperposition" ||
    endPoint === "stratergypnl" ||
    endPoint === "profit" ||
    endPoint === "subbrokerpaperposition" ||
    endPoint === "subbrokerposition"
  ) {
    ws.send(JSON.stringify(data));
  }

  // Handle incoming messages
  ws.onmessage = (event) => {
    const message = JSON.parse(event.data);
    // console.log("message,endpoint :>> ", endPoint, message);
    if (endPoint === "liveprice") {
      dispatch(getLiveData(message));
    } else if (endPoint === "watchlistupdate") {
      dispatch(getWatchListUpdateData(message));
    } else if (endPoint === "liveposition") {
      dispatch(getLivePositions(message));
    } else if (endPoint === "notification") {
      dispatch(getLiveNotifications(message));
    } else if (endPoint === "profit") {
      dispatch(getDayGainLoss(message));
    } else if (endPoint === "tokenprice") {
      dispatch(getPriceToken(message));
    } else if (endPoint === "paperposition") {
      dispatch(getPaperPositions(message));
    } else if (endPoint === "subbrokerposition") {
      dispatch(getAdminOpenLive(message));
    } else if (endPoint === "stratergypnl") {
      dispatch(getPnlData(message));
    } else if (endPoint === "watchlist") {
      dispatch(getSymbolData(message));
    }
  };
}
