import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Box,
  Select,
  FormControl,
  MenuItem,
  Checkbox,
  ListItemText,
  Button,
} from "@mui/material";
import Dropdown from "../../Dropdown/Dropdown";
import { subBrokerLogin } from "../../../redux/actions/brokerAction";
import { useDispatch, useSelector } from "react-redux";
import {
  addSmsTextAlert,
  getReferCode,
  getSmsTextAlert,
} from "../../../redux/actions/authActions";
import Table from "../../Table/Table";
import { generatePopup } from "../../../utils/popup";

export default function Smstextalert() {
  const [allcustomer, setallcustomer] = React.useState("All");
  const [allbroker, setallbroker] = React.useState("All");
  const [allrefercode, setallrefercode] = React.useState("All");
  const [alltemplatename, settemplatename] = React.useState("");
  const [load, setLoad] = React.useState(false);

  const dispatch = useDispatch();

  const allcustomerdrp = (event) => {
    setallcustomer(event.target.value);
  };

  const allbrokerdrp = (event) => {
    setallbroker(event.target.value);
  };

  const allrefercodedrp = (event) => {
    setallrefercode(event.target.value);
  };

  const templatenamedrp = (event) => {
    settemplatename(event.target.value);
  };

  const userData = useSelector((state) => state?.Auth?.authProfile?.[0]);

  useEffect(() => {
    dispatch(subBrokerLogin({ refrecode: userData?.refrecode }));
  }, []);

  const brokers = useSelector((state) => state?.Broker?.subLogin);

  const brokerList =
    brokers?.length > 0
      ? Object?.keys(brokers?.[0])?.filter((key) => {
          return brokers[0][key] === true;
        })
      : [];

  useEffect(() => {
    dispatch(getReferCode());
    dispatch(getSmsTextAlert());
  }, []);

  const smsTextAlerts = useSelector((state) => state?.Auth?.smsTextAlerts);

  const refreCodeList = useSelector(
    (state) => state?.Auth?.refreCodes?.refrecode
  );

  const handleSubmit = () => {
    setLoad(true);
    const obj = {
      userset: allcustomer,
      refrecode: allrefercode,
      broker: allbroker,
      templatename: alltemplatename,
    };
    dispatch(addSmsTextAlert(obj)).then((res) => {
      setLoad(false);
      if (res) {
        generatePopup("success", res?.data?.message);
      }
      setallcustomer("All");
      setallbroker("All");
      setallrefercode("All");
      settemplatename("");
    });
  };

  // const col = ["Owner", "Template Name", "Flow Id", "Date", "Time", "Month"];

  // const rows = smsTextAlerts?.map((e) => {
  //   return {
  //     owner: e?.owner,
  //     templatename: e?.templatename,
  //     flowid: e?.flowid,
  //     date: e?.date,
  //     time: e?.time,
  //     month: e?.month,
  //   };
  // });

  return (
    <>
      <Box className="border formBox">
        <Grid container spacing={2} alignItems="end">
          <Grid item xs={12} md={6} lg={4}>
            <Box className="formItems">
              <Typography component={"label"} className="label">
                Customer
              </Typography>
              <FormControl className="dropdown-ap">
                <Select
                  value={allcustomer}
                  onChange={allcustomerdrp}
                  displayEmpty
                  className="dropdown"
                >
                  <MenuItem value="All">Customer All</MenuItem>
                  <MenuItem value="Active">Subscription Active</MenuItem>
                  <MenuItem value="Inactive">Subscription Inactive</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Box className="formItems">
              <Typography component={"label"} className="label">
                Broker
              </Typography>
              <FormControl className="dropdown-ap">
                <Select
                  value={allbroker}
                  onChange={allbrokerdrp}
                  displayEmpty
                  className="dropdown"
                >
                  <MenuItem value="All">Broker All</MenuItem>
                  {brokerList?.map((e) => (
                    <MenuItem value={e?.slice(3)}>
                      {e?.slice(3)?.toUpperCase()}
                    </MenuItem>
                  ))}
                  {/* <MenuItem value={2}>Broker 2</MenuItem> */}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Box className="formItems">
              <Typography component={"label"} className="label">
                Referal Code
              </Typography>
              <FormControl className="dropdown-ap">
                <Select
                  value={allrefercode}
                  onChange={allrefercodedrp}
                  displayEmpty
                  className="dropdown"
                >
                  <MenuItem value="All">All</MenuItem>
                  {refreCodeList?.map((e) => (
                    <MenuItem value={e?.toUpperCase()}>
                      {e?.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Box className="formItems">
              <Typography component={"label"} className="label">
                Template Name
              </Typography>
              <FormControl className="dropdown-ap">
                <Select
                  value={alltemplatename}
                  onChange={templatenamedrp}
                  displayEmpty
                  className="dropdown"
                >
                  {smsTextAlerts?.map((e) => (
                    <MenuItem value={e?.templatename}>
                      {e?.templatename}
                    </MenuItem>
                  ))}
                  {/* <MenuItem value={1}>Template 1</MenuItem> */}
                  {/* <MenuItem value={2}>Template 2</MenuItem> */}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Button onClick={handleSubmit} className="formSolid-btn">
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* <Table col={col} rows={rows} /> */}
    </>
  );
}
