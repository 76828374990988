import React, { useState } from "react";
import {
  Box,
  Grid,
  Button,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import Tablesearch from "../../../Tablesearch/Tablesearch";
import Table from "../../../Table/Table";
import MessageIcon from "@mui/icons-material/Message";
import Dropdown from "../../../Dropdown/Dropdown";

const message = (
  <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
    Aliquam eget finibus ante, non facilisis lectus. Sed vitae dignissim est,
    vel aliquam tellus. Praesent non nunc mollis, fermentum neque at, semper
    arcu. Nullam eget est sed sem iaculis gravida eget vitae justo.
  </Typography>
);

const col = [
  "Cutomer ID",
  "Client ID",
  "Symbol",
  "Trade Type",
  "Qty",
  "Buy Price",
  "Order ID",
  "Date/Time",
  "Status",
  "Rejection Reason",
];
const rows = [
  {
    cui: "DEMO132",
    cli: "TEST132",
    SymbolName: "230104000209323",
    TradeType: <span className="down">Sell</span>,
    Qty: 150,
    EnterPrice: "15-05-2023 / 14:21:11:8",
    ExitPrice: <span className="open">Complete</span>,
    orderid: "2123",
    dateTime: "01/07/23 04:00",
    SellID: (
      <Tooltip title={message} arrow placement="left">
        <Button className="rejectMessage">
          {" "}
          <MessageIcon sx={{ color: "#fff !important" }} />
        </Button>
      </Tooltip>
    ),
  },
];

export default function Orderhistory() {
  // drop down
  const drpValue = ["Customer ID", "CSKI25", "CSVFR23"];
  const search = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      search{" "}
    </Typography>
  );

  const [customerid, setCustomerid] = useState();
  const [searchvalue, setSearchvalue] = useState();

  //select Customerid
  const handleCustomerid = (event) => {
    setCustomerid(event.target.value);
  };

  //select Searchvalue
  const handleSearchvalue = (event) => {
    setSearchvalue(event.target.value);
  };

  return (
    <>
      <Box className="tabelBox">
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12}>
            <Box className="selectiondiv-box m-rb ">
              <Dropdown
                val={drpValue}
                handleSelect={handleCustomerid}
                value={customerid}
                title={"Customer ID"}
              />
              <Box className="selectionDiv searchFlex">
                <Tablesearch
                  handlesearch={(event) => handleSearchvalue(event)}
                />
                <Tooltip title={search} arrow>
                  <Button
                    className="download-btn solidButton"
                    sx={{ margin: "0 0 0 1rem !important" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                        fill="#FFFFFF"
                      />
                    </svg>
                  </Button>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Table col={col} rows={rows} />
      </Box>
    </>
  );
}
