import * as actionTypes from "../actionTypes";
import axios from "axios";
import { API_URL, PAPER_API_URL } from "../../config";
import { generatePopup } from "../../utils/popup";
import download from "downloadjs";

export const getLivePositions = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.GET_LIVE_POSITIONS_SUCCESS,
      payload: payload,
    });
    resolve();
  });
};

export const getSymbolData = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.GET_SYMBOL_DATA_SUCCESS,
      payload: payload,
    });
    resolve();
  });
};

export const getPaperPositions = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.GET_PAPER_POSITIONS_SUCCESS,
      payload: payload,
    });
    resolve();
  });
};

export const getAdminOpenPaper = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.GET_ADMIN_PAPER_POSITIONS_SUCCESS,
      payload: payload,
    });
    resolve();
  });
};

export const getAdminOpenLive = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.GET_ADMIN_LIVE_POSITIONS_SUCCESS,
      payload: payload,
    });
    resolve();
  });
};

export const signalDetails = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.SIGNAL_DETAILS_INIT,
    });
    axios
      .post(`${API_URL}/position/signaldetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.SIGNAL_DETAILS_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.SIGNAL_DETAILS_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.SIGNAL_DETAILS_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const liveTradeDetails = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.GET_LIVE_TRADE_DETAILS_INIT,
    });
    axios
      .get(`${API_URL}/position/tradedetails1/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_LIVE_TRADE_DETAILS_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_LIVE_TRADE_DETAILS_FAIL,
          });
          generatePopup("error", "Failed to get live trade details.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_LIVE_TRADE_DETAILS_FAIL,
          });
          generatePopup("error", "Failed to get live trade details.");
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const liveOrderDetails = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.GET_LIVE_ORDER_DETAILS_INIT,
    });
    axios
      .get(`${API_URL}/position/liveorderdetails/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_LIVE_ORDER_DETAILS_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_LIVE_ORDER_DETAILS_FAIL,
          });
          generatePopup("error", "Failed to get live trade details.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_LIVE_ORDER_DETAILS_FAIL,
          });
          generatePopup("error", "Failed to get live trade details.");
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const paperOrderDetails = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.PAPER_ORDER_DETAILS_INIT,
    });
    axios
      .get(`${PAPER_API_URL}/position/paperorderdetails/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.PAPER_ORDER_DETAILS_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.PAPER_ORDER_DETAILS_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get paper profit details.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.PAPER_ORDER_DETAILS_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const paperTradeDetails = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.PAPER_TRADE_DETAILS_INIT,
    });
    axios
      .get(`${PAPER_API_URL}/position/papertradedetails/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.PAPER_TRADE_DETAILS_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.PAPER_TRADE_DETAILS_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get paper profit details.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.PAPER_TRADE_DETAILS_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const createPosition = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.CREATE_POSITION_INIT,
    });
    axios
      .post(`${API_URL}/position/placeorder/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("res :>> ", res);
        if (res?.status === 200) {
          dispatch({
            type: actionTypes.CREATE_POSITION_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.CREATE_POSITION_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get paper profit details.");
        }
      })
      .catch((error) => {
        generatePopup("error", "Can Not Create Trade.");
        dispatch({
          type: actionTypes.CREATE_POSITION_FAIL,
        });
      });
  });
};

export const createPaperPosition = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.CREATE_POSITION_INIT,
    });
    axios
      .post(`${API_URL}/position/placepaperorder/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("res :>> ", res);
        if (res?.status === 200) {
          dispatch({
            type: actionTypes.CREATE_POSITION_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.CREATE_POSITION_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get paper profit details.");
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        if (error?.response?.status === 400) {
          generatePopup("error", error?.response?.data?.message);
        }
        generatePopup("error", "Can Not Create Trade.");
        dispatch({
          type: actionTypes.CREATE_POSITION_FAIL,
        });
      });
  });
};

export const closeOrder = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.CLOSE_ORDER_INIT,
    });
    axios
      .post(`${API_URL}/position/closeorder/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.CLOSE_ORDER_SUCCESS,
            payload: res?.data,
          });
          generatePopup("success", res?.data?.message);
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.CLOSE_ORDER_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get paper profit details.");
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.CLOSE_ORDER_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const adminCloseOrder = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.ADMIN_CLOSE_ORDER_INIT,
    });
    axios
      .post(`${API_URL}/position/closeorder5/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.ADMIN_CLOSE_ORDER_SUCCESS,
            payload: res?.data,
          });
          generatePopup("success", res?.data?.message);
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.ADMIN_CLOSE_ORDER_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get paper profit details.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.ADMIN_CLOSE_ORDER_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const closeStrategy = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.CLOSE_STRATEGY_INIT,
    });
    axios
      .post(`${API_URL}/position/stratergycloseorders/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.CLOSE_STRATEGY_SUCCESS,
            payload: res?.data,
          });
          generatePopup("success", res?.data?.message);
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.CLOSE_STRATEGY_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get paper profit details.");
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.CLOSE_STRATEGY_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const closePaperOrder = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.CLOSE_ORDER_INIT,
    });
    axios
      .post(`${API_URL}/position/closepapertrade/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.CLOSE_ORDER_SUCCESS,
            payload: res?.data,
          });
          generatePopup("success", res?.data?.message);
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.CLOSE_ORDER_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get paper profit details.");
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.CLOSE_ORDER_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const cancelOrder = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.CANCEL_ORDER_INIT,
    });
    axios
      .post(`${API_URL}/position/cancelorder/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.CANCEL_ORDER_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.CANCEL_ORDER_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get paper profit details.");
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.CANCEL_ORDER_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const modifyOrder = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.MODIFY_ORDER_INIT,
    });
    axios
      .post(`${API_URL}/position/modifyorder/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("res :>> ", res);
        if (res.status === 200) {
          dispatch({
            type: actionTypes.MODIFY_ORDER_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.MODIFY_ORDER_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get paper profit details.");
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.MODIFY_ORDER_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const closeCopyLimitOrder = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.CLOSE_COPY_LIMIT_ORDER_INIT,
    });
    axios
      .post(`${API_URL}/position/closecopylimitorder/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.CLOSE_COPY_LIMIT_ORDER_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
          generatePopup("success", res?.data?.message);
        } else {
          dispatch({
            type: actionTypes.CLOSE_COPY_LIMIT_ORDER_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to close copyTrade");
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.CLOSE_COPY_LIMIT_ORDER_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const closeLimitPaperTrade = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.CLOSE_LIMIT_PAPER_TRADE_INIT,
    });
    axios
      .post(`${API_URL}/position/closelimitpapertrade/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("res :>> ", res);
        if (res.status === 200) {
          dispatch({
            type: actionTypes.CLOSE_LIMIT_PAPER_TRADE_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.CLOSE_LIMIT_PAPER_TRADE_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get paper profit details.");
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.CLOSE_LIMIT_PAPER_TRADE_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const closeLimit = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.CLOSE_LIMIT_INIT,
    });
    axios
      .post(`${API_URL}/position/closelimit/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("res :>> ", res);
        if (res.status === 200) {
          dispatch({
            type: actionTypes.CLOSE_LIMIT_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.CLOSE_LIMIT_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get paper profit details.");
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.CLOSE_LIMIT_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const closeAllLiveOrder = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.CLOSE_ALL_LIVE_ORDER_INIT,
    });
    axios
      .get(`${API_URL}/position/closeallliveorder/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.CLOSE_ALL_LIVE_ORDER_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.CLOSE_ALL_LIVE_ORDER_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get paper profit details.");
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.CLOSE_ALL_LIVE_ORDER_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const plReportPdf = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.PL_REPORT_PDF_INIT,
    });
    axios
      .post(`${API_URL}/position/profitpdf/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const content = res.headers["content-type"];
          download(res?.data, "Profiit-Loss Report.pdf", content);
          dispatch({
            type: actionTypes.PL_REPORT_PDF_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.PL_REPORT_PDF_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get paper profit details.");
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.PL_REPORT_PDF_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const paperPlReportPdf = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.PAPER_PL_REPORT_PDF_INIT,
    });
    axios
      .post(`${PAPER_API_URL}/position/paperprofitdetailspdf/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          console.log("res : ", res);
          const content = res.headers["content-type"];
          download(res?.data, "Profiit-Loss Report.pdf", content);
          dispatch({
            type: actionTypes.PAPER_PL_REPORT_PDF_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.PAPER_PL_REPORT_PDF_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get paper profit details.");
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.PAPER_PL_REPORT_PDF_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const getSegment = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.GET_SEGMENT_INIT,
    });
    axios
      .get(`${API_URL}/position/getsegment/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_SEGMENT_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_SEGMENT_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get segments.");
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_SEGMENT_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const getBrokers = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.GET_BROKERS_INIT,
    });
    axios
      .post(`${API_URL}/position/getbrokerdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_BROKERS_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_BROKERS_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get segments.");
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_BROKERS_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const copyTradeDetails = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.GET_COPYTRADEDETAILS_INIT,
    });
    axios
      .get(`${API_URL}/position/getCopyTradeDetails/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_COPYTRADEDETATIS_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_COPYTRADEDETATIS_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get copytradedetails.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_COPYTRADEDETATIS_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const copytradeClose = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.CLOSECOPYTRADE_INIT,
    });
    axios
      .post(`${API_URL}/position/closecopyTrade/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.CLOSECOPYTRADE_SUCCESS,
            payload: res?.data,
          });
          generatePopup("success", res?.data?.message);
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.CLOSECOPYTRADE_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to copytrade close.");
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.CLOSECOPYTRADE_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const copyPostionDetails = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.GET_COPYPOSITIONDETAILS_INIT,
    });
    axios
      .get(`${API_URL}/position/getCopyPositionDetails/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_COPYPOSITIONDETAILS_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_COPYPOSITIONDETAILS_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get copyPostionDetails.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_COPYPOSITIONDETAILS_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const cancelcopyTrade = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.CLOSEPOSTIONDETAILS_INIT,
    });
    axios
      .post(`${API_URL}/position/cancelcopytrade/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.CLOSEPOSTIONDETAILS_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.CLOSEPOSTIONDETAILS_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to copytrade close.");
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.CLOSEPOSTIONDETAILS_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const getStrategyFlag = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.GET_STRATEGY_FLAG_INIT,
    });
    axios
      .get(`${API_URL}/position/stratergyflag/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_STRATEGY_FLAG_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_STRATEGY_FLAG_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_STRATEGY_FLAG_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const getStrategyDetail = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.GET_STRATEGY_DETAILS_INIT,
    });
    axios
      .post(`${API_URL}/position/customerstratergy/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_STRATEGY_DETAILS_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_STRATEGY_DETAILS_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_STRATEGY_DETAILS_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const editStrategyDetail = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.EDIT_STRATEGY_DETAILS_INIT,
    });
    axios
      .put(`${API_URL}/position/customerstratergy/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.EDIT_STRATEGY_DETAILS_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.EDIT_STRATEGY_DETAILS_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.EDIT_STRATEGY_DETAILS_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const getCopyTradeDetails = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.GET_COPYTRADE_DETAILS_INIT,
    });
    axios
      .post(`${API_URL}/position/customercopytrade/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_COPYTRADE_DETAILS_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_COPYTRADE_DETAILS_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_COPYTRADE_DETAILS_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const editCopyTradeDetails = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.EDIT_COPYTRADE_DETAILS_INIT,
    });
    axios
      .put(`${API_URL}/position/customercopytrade/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.EDIT_COPYTRADE_DETAILS_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.EDIT_COPYTRADE_DETAILS_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.EDIT_COPYTRADE_DETAILS_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const AdminPnlReport = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.ADMIN_PNL_INIT,
    });
    axios
      .post(`${API_URL}/position/adminprofitdetails1/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.ADMIN_PNL_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.ADMIN_PNL_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.ADMIN_PNL_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const AdminPaperPnlReport = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.ADMIN_PAPER_PNL_INIT,
    });
    axios
      .post(`${PAPER_API_URL}/position/adminpaperprofitdetails1/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.ADMIN_PAPER_PNL_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.ADMIN_PAPER_PNL_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.ADMIN_PAPER_PNL_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const adminOpenPosition = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.OPEN_POSITION_DATA_INIT,
    });
    axios
      .post(`${API_URL}/position/brokeropenposition/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.OPEN_POSITION_DATA_SUCCESS,
            payload: Array.isArray(res?.data) ? res?.data : [],
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.OPEN_POSITION_DATA_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.OPEN_POSITION_DATA_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const adminPaperOpenPosition = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.OPEN_PAPER_POSITION_DATA_INIT,
    });
    axios
      .post(`${API_URL}/position/brokeropenposition/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.OPEN_PAPER_POSITION_DATA_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.OPEN_PAPER_POSITION_DATA_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.OPEN_PAPER_POSITION_DATA_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const closeAllAdminOrder = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.CLOSE_ALL_ADMIN_POSITION_INIT,
    });
    axios
      .post(`${API_URL}/position/closealladminorders/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.CLOSE_ALL_ADMIN_POSITION_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.CLOSE_ALL_ADMIN_POSITION_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.CLOSE_ALL_ADMIN_POSITION_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const closeAllAdminPaperOrder = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.CLOSE_ALL_ADMIN_PAPER_POSITION_INIT,
    });
    console.log("payload :>> ", payload);
    axios
      .post(`${API_URL}/position/closeallpaperadminorders/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.CLOSE_ALL_ADMIN_PAPER_POSITION_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.CLOSE_ALL_ADMIN_PAPER_POSITION_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.CLOSE_ALL_ADMIN_PAPER_POSITION_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const closeMisPosition = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.CLOSE_MIS_POSITION_INIT,
    });
    axios
      .get(`${API_URL}/position/closemisposition/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.CLOSE_MIS_POSITION_SUCCESS,
            payload: res?.data,
          });
          generatePopup("success", res?.data?.message);
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.CLOSE_MIS_POSITION_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.CLOSE_MIS_POSITION_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const closeAllCopyOrder = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.CLOSE_ALL_COPY_ORDER_INIT,
    });
    axios
      .post(`${API_URL}/position/closeallcopyorder/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.CLOSE_ALL_COPY_ORDER_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.CLOSE_ALL_COPY_ORDER_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.CLOSE_ALL_COPY_ORDER_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const PositionSignalDetails = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.GET_POSITIONAL_SIGNAL_INIT,
    });
    axios
      .post(`${API_URL}/position/positionsignalDetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_POSITIONAL_SIGNAL_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_POSITIONAL_SIGNAL_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_POSITIONAL_SIGNAL_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const downloadLiveReport = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.LIVE_REPORT_DOWNLOAD_INIT,
    });
    axios
      .post(`${API_URL}/position/adminprofitdetailspdf/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const content = res.headers["content-type"];
          download(res?.data, "User-profiit-Loss Report.pdf", content);
          dispatch({
            type: actionTypes.LIVE_REPORT_DOWNLOAD_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.LIVE_REPORT_DOWNLOAD_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.LIVE_REPORT_DOWNLOAD_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const downloadPaperReport = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.PAPER_REPORT_DOWNLOAD_INIT,
    });
    axios
      .post(`${API_URL}/position/adminpaperprofitdetailspdf/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const content = res.headers["content-type"];
          download(res?.data, "User-profiit-Loss Report.pdf", content);
          dispatch({
            type: actionTypes.PAPER_REPORT_DOWNLOAD_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.PAPER_REPORT_DOWNLOAD_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        console.log("error :>> ", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.PAPER_REPORT_DOWNLOAD_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};
