import React, { useEffect } from "react";
import { Box, Grid, Typography, ListItem, List, Button } from "@mui/material";
import "./Planpricing.scss";
import {
  getInvoice,
  getProfile,
  payment,
  paymentSucess,
} from "../../redux/actions/authActions";
import { generatePopup } from "../../utils/popup";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { invociePlanValue } from "../../redux/actions/planvalueActions";
import { useNavigate } from "react-router-dom";

export default function Planpricing() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.Auth?.authProfile?.[0]);
  const navigate = useNavigate();
  // ========== function for payment ==========

  // var Tawk_API = Tawk_API || {},
  //   Tawk_LoadStart = new Date();
  // (function () {
  //   var s1 = document.createElement("script"),
  //     s0 = document.getElementsByTagName("script")[0];
  //   s1.async = true;
  //   s1.src = "https://embed.tawk.to/65d880fe8d261e1b5f646696/1hnb0t2ml";
  //   s1.charset = "UTF-8";
  //   s1.setAttribute("crossorigin", "*");
  //   s0.parentNode.insertBefore(s1, s0);
  // })();

  useEffect(() => {
    dispatch(invociePlanValue());
  }, []);

  const invocieplanvalue = useSelector(
    (state) => state?.Invocieplanvalue?.invociePlanValue
  );

  const handleSuccess = (data) => {
    console.log("success");
    const token = localStorage.getItem("authToken");
    dispatch(paymentSucess(data, token)).then((res) => {
      console.log("res :>>", res);
      dispatch(getProfile());
    });
  };
  const handleError = () => {
    console.log("error----------------");
  };

  const handleSubscribe = async (plan) => {
    dispatch(payment(plan, handleSuccess, handleError, userData)).then(
      (res) => {
        console.log("res ✅✅✅✅", res);
      }
    );
  };

  const difference = dayjs().diff(userData?.expire_at, "days");
  const demoDifference = dayjs().diff(userData?.demo_expire_at, "days");

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src =
  //     "https://stage-router.paytm.in/merchantpgpui/checkoutjs/aoa/merchants/216820000000000014719.js";
  //   script.async = true;
  //   script.crossOrigin = "anonymous";
  //   // script.onload = () => {
  //   //   onScriptLoad("1234", "SHUBHAM_123", "1");
  //   // };
  //   document.head.appendChild(script);

  //   return () => {
  //     document.head.removeChild(script);
  //   };
  // }, []);

  // const onScriptLoad = (txnToken, orderId, amount) => {
  //   const config = {
  //     root: "",
  //     flow: "DEFAULT",
  //     data: {
  //       orderId: orderId,
  //       token: txnToken,
  //       tokenType: "TXN_TOKEN",
  //       amount: amount,
  //     },
  //     handler: {
  //       notifyMerchant: (eventName, data) => {
  //         console.log("Handler log", eventName, data);
  //         if (eventName === "SESSION_EXPIRED") {
  //           alert("Your session has expired!!");
  //           // window.location.reload();
  //           console.log("Session Expired");
  //         }
  //       },
  //     },
  //   };

  //   if (window.Paytm && window.Paytm.CheckoutJS) {
  //     // initialize configuration using init method
  //     window.Paytm.CheckoutJS.init(config)
  //       .then(() => {
  //         console.log("Before JS Checkout invoke");
  //         // after successfully update configuration invoke checkoutjs
  //         window.Paytm.CheckoutJS.invoke();
  //       })
  //       .catch((error) => {
  //         console.log("Error section");
  //         console.log("Error => ", error);
  //       });
  //   } else {
  //     console.log("something went wrong");
  //   }
  // };

  return (
    <>
      <Grid
        container
        gap={"10px"}
        spacing={1}
        justifyContent={"center"}
        sx={{
          maxWidth: { xs: "1600px" },
          margin: "auto",
        }}
      >
        <Grid item xs={12}>
          <Typography component={"h2"} className="title">
            Recharge Plans
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Box
            className={
              userData?.is_subscribe ? "plan-card active" : "plan-card expiry"
            }
          >
            <Typography className="plan-text"> Plan Status :</Typography>
            <Box className="plan-content">
              <Typography
                className={
                  userData?.is_subscribe
                    ? "plan-value-primary"
                    : "plan-value-danger"
                }
              >
                {userData?.is_subscribe
                  ? "Subscription Active"
                  : "Subscription Inactive"}
              </Typography>
              {/* <Typography className="plan_type">(Basic)</Typography> */}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={3}>
          <Box className="plan-card expiry">
            <Typography className="plan-text">Plan Expiry :</Typography>
            <Box className="plan-content">
              <Typography className="plan-value-danger">
                {userData?.expire_at}
              </Typography>
              <Typography className="plan_type">
                (
                {-difference > 0
                  ? `${-difference} Days Left`
                  : `${difference} Days ago`}
                )
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box className="plan-card expiry-demo">
            <Typography className="plan-text">Demo Expiry :</Typography>
            <Box className="plan-content">
              <Typography className="plan-value-danger-demo">
                {userData?.demo_expire_at}
              </Typography>
              <Typography className="plan_type">
                (
                {-demoDifference > 0
                  ? `${-demoDifference} Days Left`
                  : `${demoDifference} Days ago`}
                )
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        justifyContent={"center"}
        sx={{
          maxWidth: { xs: "1600px" },
          margin: "auto",
        }}
      >
        <Grid item xs={12}>
          <Box className="planCard-flex">
            <Box className="planCard">
              <Box className="planCard-type">
                <Typography component={"p"} className="planType">
                  Basic
                </Typography>
                <Box className="planPrice">
                  <Typography component={"span"}>₹</Typography>
                  <Typography component={"span"}>
                    {invocieplanvalue?.basic_final_value}
                  </Typography>
                  <Typography component={"span"}>/ Month</Typography>
                </Box>

                <Box className="save">
                  <Typography component={"span"}>
                    Save {invocieplanvalue?.basic_disc}%
                  </Typography>
                  vs our monthly plan
                  <Box component={"del"}>₹{invocieplanvalue?.basic_total}</Box>
                </Box>
              </Box>
              <Box className="planCard-content">
                <List>
                  <ListItem>Validity 30 Days </ListItem>
                  <ListItem> All Segment Trading</ListItem>
                  <ListItem> Engineer Support</ListItem>
                  <ListItem>Learning Videos </ListItem>
                </List>
                <Button
                  className="planCard-btn"
                  onClick={() => handleSubscribe("Basic")}
                >
                  Basic Buy Now
                </Button>
              </Box>
            </Box>

            <Box className="planCard">
              <Box className="planCard-type">
                <Typography component={"p"} className="planType">
                  Pro
                </Typography>
                <Box className="planPrice">
                  <Typography component={"span"}>₹</Typography>
                  <Typography component={"span"}>
                    {invocieplanvalue?.pro_final_value}
                  </Typography>
                  <Typography component={"span"}>/ Quartely</Typography>
                </Box>

                <Box className="save">
                  <Typography component={"span"}>
                    Save {invocieplanvalue?.pro_disc}%
                  </Typography>
                  vs our monthly plan
                  <Box component={"del"}>₹{invocieplanvalue?.pro_total}</Box>
                </Box>
              </Box>
              <Box className="planCard-content">
                <List>
                  <ListItem>Validity 90 Days </ListItem>
                  <ListItem> All Segment Trading</ListItem>
                  <ListItem>Engineer Support</ListItem>
                  <ListItem>Learning Videos</ListItem>
                </List>
                <Button
                  onClick={() => handleSubscribe("Pro")}
                  className="planCard-btn"
                >
                  Buy Now
                </Button>
              </Box>
            </Box>

            <Box className="planCard premium">
              <Box className="planCard-type">
                <Typography
                  component={"p"}
                  className="planType"
                  sx={{ color: "#FFFFFF !important" }}
                >
                  Premium
                </Typography>
                <Box className="planPrice">
                  <Typography component={"span"}>₹</Typography>
                  <Typography
                    component={"span"}
                    sx={{ color: "#FFFFFF !important" }}
                  >
                    {invocieplanvalue?.premium_final_value}
                  </Typography>
                  <Typography component={"span"}>/ Half Yearly</Typography>
                </Box>

                <Box className="save">
                  <Typography component={"span"}>
                    Save {invocieplanvalue?.premium_disc}%
                  </Typography>
                  vs our monthly plan
                  <Box component={"del"}>
                    ₹{invocieplanvalue?.premium_total}
                  </Box>
                </Box>
              </Box>
              <Box className="planCard-content">
                <List>
                  <ListItem>Validity 180 Days </ListItem>
                  <ListItem> All Segment Trading</ListItem>
                  <ListItem> Engineer Support</ListItem>
                  <ListItem>Learning Videos </ListItem>
                  <ListItem>Make Your Own Strategy </ListItem>
                  <ListItem> Expert Weekend Trading Training</ListItem>
                </List>
                <Button
                  onClick={() => handleSubscribe("Premium")}
                  className="planCard-btn"
                >
                  Buy Now
                </Button>
              </Box>
            </Box>

            <Box className="planCard">
              <Box className="planCard-type">
                <Typography component={"p"} className="planType">
                  Extra Plan
                </Typography>
                <Box className="planPrice">
                  <Typography component={"span"}>₹</Typography>
                  <Typography component={"span"}>
                    {invocieplanvalue?.extra_final_value}
                  </Typography>
                  <Typography component={"span"}>/ Annually</Typography>
                </Box>

                <Box className="save">Cost-Effective, Full Service</Box>
              </Box>
              <Box className="planCard-content">
                <List>
                  <ListItem>Validity 365 Days </ListItem>
                  <ListItem> All Segment Trading</ListItem>
                  <ListItem> Engineer Support</ListItem>
                  <ListItem>Learning Videos </ListItem>
                  <ListItem>Make Your Own Strategy </ListItem>
                  <ListItem> Expert Weekend Trading Training</ListItem>
                </List>
                <Button
                  onClick={() => handleSubscribe("Extra")}
                  className="planCard-btn"
                >
                  Buy Now
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box className="notes">
            <Typography component={"p"}>Terms & Conditions : </Typography>
            <Typography component={"span"}>
              Exclusive GST & Payment Gateway Charges Applicable.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
