import * as actionTypes from "../actionTypes";

const initState = {
  userstratergy: [],
  stratergy: [],
  error: "",
};

const store = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.GET_INVOICE_PLANVALUE_INIT:
      return {
        ...state,
        error: "",
      };

    case actionTypes.GET_INVOICE_PLANVALUE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.GET_INVOICE_PLANVALUE_SUCCESS:
      return {
        ...state,
        invociePlanValue: action.payload,
      };
    default:
      return state;
  }
};

export default store;
