import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    Grid,
    Tooltip,
    Typography,
} from "@mui/material";
import Table from "../../../Table/Table";
import Dropdown from "../../../Dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { getStratergy } from "../../../../redux/actions/stratergyAction";
import { getUserRefreCode } from "../../../../redux/actions/brokerAction";
import { messageEvent, socket } from "../../../../hooks/useSocket";
import { socketOpen } from "../../../../redux/actions/commonActions";
import MessageIcon from "@mui/icons-material/Message";
import CloseIcon from "@mui/icons-material/Close";
import { generatePopup } from "../../../../utils/popup";
import close from "../../../../images/close.png";

export default function SubBrokerPosition() {

    const sqyuareoff = (
        <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
            Square Off
        </Typography>
    );

    const search = (
        <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
            Search
        </Typography>
    );

    const [open, setOpen] = useState(false);
    const [id, setId] = useState("");
    const [type, setType] = useState("");
    const [segment, setSegment] = useState("select");
    const [strategy, setStrategy] = useState("select");
    const [totalCount, setTotalCount] = useState(0);
    const [paperWs, setPaperWs] = React.useState(false);
    const [pageno, setPageno] = useState(0);

    const dispatch = useDispatch();
    const positions = useSelector((state) => state?.Position?.liveAdminPositions);
    const data = Array.isArray(positions) ? positions : []
    console.log('data', data)
    useEffect(() => {
        if (positions?.count) {
            setTotalCount(positions?.count)
        }
    }, [positions])

    console.log('totalCount', totalCount)

    //select strategy
    const handleStrategy = (event) => {
        setStrategy(event.target.value);
    };

    const handlePageChange = (e) => {
        setPageno(e);
    };

    const handleClickOpen = (ID, type) => {
        if (strategy?.toLowerCase() === "select") {
            return generatePopup("error", "Please select strategy");
        }

        setOpen(true);
        setId(ID);
        setType(type);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSquareOffAll = () => {
    };

    const userData = useSelector((state) => state?.Auth?.authProfile?.[0]);

    useEffect(() => {
        if (userData) {
            const obj = {
                refrecode: userData?.username,
            };
            dispatch(getUserRefreCode(obj));
        }
    }, [userData]);

    useEffect(() => {
        if (paperWs) {
            messageEvent(paperWs, "subbrokerposition", dispatch, {
                "remark": "strategy",
                "value": strategy?.toUpperCase()
            });
        }
    }, [strategy]);

    const col = [
        "Symbol",
        "Trade Type",
        "Exchange",
        "Lot",
        "Order Type",
        "Trend",
        "Price",
        "Live Price",
        "P&L",
        "Status",
        "Remark",
        "Date",
        "Action",
    ];
    const rows = data?.map((e) => {
        return {
            symbol: e.symbol,
            tradeType: e.positiontype,
            exchange: e.exchange,
            lot: e?.quantity,
            orderType: e?.prctyp,
            trend: (
                <Typography
                    component={"span"}
                    className={e?.side?.toUpperCase() === "BUY" || e?.side?.toUpperCase() === "B" ? "up" : "down"}
                >
                    {e?.side?.toUpperCase() === "BUY" || e?.side?.toUpperCase() === "B" ? "BUY" : "SELL"}
                </Typography>
            ),

            price: e?.price,
            liveprice: e?.liveprice,
            profit: Number(e?.profit)?.toFixed(2),
            status: (
                <Typography
                    component={"span"}
                    className={e?.status?.toUpperCase() === "COMPLETE" ? "up" : "down"}
                >
                    {e?.status}
                </Typography>
            ),
            remark: (
                <Tooltip
                    title={
                        <Typography sx={{ fontSize: "1.3rem" }}>{e?.remark}</Typography>
                    }
                    arrow
                    placement="left"
                >
                    <Button className="rejectMessage">
                        <MessageIcon sx={{ color: "#fff !important" }} />
                    </Button>
                </Tooltip>
            ),
            date: e?.date,
            squer: (
                <Button
                    onClick={() => handleClickOpen(e?.id, "single")}
                    className="squerOff"
                >
                    <CloseIcon />
                </Button>
            ),
        };
    });

    useEffect(() => {
        dispatch(getStratergy());
    }, []);

    useEffect(() => {
        let ws;
        const setupWebSocket = async () => {
            ws = await socket(
                "subbrokerposition",
                process.env.REACT_APP_PAPER_CUSTOMER_WEBSOCKET_URL
            );
            setPaperWs(ws);
            if (ws) {
                dispatch(socketOpen({ name: "subbrokerposition", socket: ws }));
                messageEvent(ws, "subbrokerposition", dispatch, {
                    "remark": "strategy",
                    "value": "PYRAMID TRADE"
                });
            }
        };
        setupWebSocket();
        window.onbeforeunload = async function (event) {
            console.log("closed");
            return await ws?.send({ remark: "disconnect" });
        };
        return () => {
            if (ws && ws.readyState === WebSocket.OPEN) {
                ws?.send("disconnect");
            }
        };
    }, []);



    useEffect(() => {
        if (paperWs) {
            messageEvent(paperWs, "subbrokerposition", dispatch, {
                remark: "pageno",
                pageno: pageno,
            });
        }
    }, [pageno, paperWs]);

    const strategies = useSelector((state) => state?.Stratergy?.stratergy);

    const strategyList = strategies?.map((e) => e?.name);
    return (
        <>
            <Box className="tabelBox">
                <Grid container spacing={2} alignItems={"center"} padding={"1rem 0"}>
                    <Grid item xs={12}>
                        <Box
                            className="selectiondiv-box m-rb"
                            sx={{ paddingRight: { xs: "0", md: "1rem" }, display: "flex", alignItems: "center" }}
                        >
                            <Dropdown
                                val={["select"].concat(strategyList)}
                                handleSelect={handleStrategy}
                                value={strategy}
                                title={"Strategy"}
                            />
                            <Tooltip title={sqyuareoff} arrow>
                                <Button
                                    className="squareOff-btn solidButton"
                                    onClick={() => handleClickOpen("", "all")}
                                >
                                    <CloseIcon />
                                </Button>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>

                <Table
                    col={col}
                    rows={rows}
                    handlePageChange={handlePageChange}
                    pageno={pageno}
                    totalCount={totalCount}
                    type="openPosition"
                />
            </Box>

            <Dialog
                open={open}
                onClose={handleClose}
                className="commonModal squareOff"
                fullWidth
            >
                <Box className="modalHeader">
                    <Typography component={"h4"}>Square Off</Typography>
                    <Button onClick={handleClose} className="closeModal">
                        <img alt="close" src={close} />
                    </Button>
                </Box>

                <DialogContent sx={{ padding: "0" }} className="modalBody">
                    <DialogContentText sx={{ padding: "0" }}>
                        <Box className="alertIcons">
                            <svg
                                width="1052"
                                height="1052"
                                viewBox="0 0 1052 1052"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M558 334C558 316.3 543.7 302 526 302C508.3 302 494 316.3 494 334V590C494 607.7 508.3 622 526 622C543.7 622 558 607.7 558 590V334ZM526 750C536.609 750 546.783 745.786 554.284 738.284C561.786 730.783 566 720.609 566 710C566 699.391 561.786 689.217 554.284 681.716C546.783 674.214 536.609 670 526 670C515.391 670 505.217 674.214 497.716 681.716C490.214 689.217 486 699.391 486 710C486 720.609 490.214 730.783 497.716 738.284C505.217 745.786 515.391 750 526 750Z"
                                    fill="#4987FE"
                                />
                                <circle
                                    cx="526"
                                    cy="526"
                                    r="507"
                                    stroke="#5086EE"
                                    stroke-width="38"
                                />
                            </svg>
                        </Box>
                        <Typography
                            component={"h4"}
                            sx={{
                                fontSize: "1.8rem",
                                textAlign: "center",
                                marginTop: "2rem",
                            }}
                            className="alertText"
                        >
                            Are You Sure You Want to Close Position !
                        </Typography>

                        <Box sx={{ display: { xs: "flex" } }}>
                            <Button
                                onClick={handleClose}
                                className="modal-btn btn-danger"
                                sx={{ marginRight: "0.5rem" }}
                            >
                                Discard
                            </Button>
                            <Button
                                onClick={handleSquareOffAll}
                                className="modal-btn btn-primary"
                                sx={{ marginLeft: "0.5rem" }}
                            >
                                Confirm
                            </Button>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>


        </>
    );
}
