import * as actionTypes from "../actionTypes";

const initState = {
  adminTradeDetailsData: [],
  adminOrderDetailsData: [],
  adminPaperTradeData: [],
  adminPaperOrderData: [],
  error: "",
  userViewData: {},
  dashboardCount: {},
  todayCount: {},
  copytrades: [],
  adminPaperDetailsData: [],
};

const store = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.ADMIN_TRADE_DETAILS_INIT:
      return {
        ...state,
        error: "",
      };
    case actionTypes.ADMIN_TRADE_DETAILS_SUCCESS:
      console.log("action.payload :>> ", action.payload);
      return {
        ...state,
        adminTradeDetailsData: action.payload,
      };
    case actionTypes.ADMIN_PAPER_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        adminPaperDetailsData: action.payload,
      };
    case actionTypes.ADMIN_PAPER_TRADE_DETAILS_SUCCESS:
      return {
        ...state,
        adminPaperOrderData: action.payload,
      };

    case actionTypes.ADMIN_TRADE_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.ADMIN_ORDER_DETAILS_INIT:
      return {
        ...state,
        error: "",
      };
    case actionTypes.ADMIN_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        adminOrderDetailsData: action.payload,
      };
    case actionTypes.ADMIN_ORDER_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.CREATE_ADMIN_UPDATE_USER_DETAILS_INIT:
      return {
        ...state,
        error: "",
      };
    case actionTypes.CREATE_ADMIN_UPDATE_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userViewData: action.payload,
      };
    case actionTypes.EMPTY_USER_VIEW:
      return {
        ...state,
        userViewData: {},
      };
    case actionTypes.CREATE_ADMIN_UPDATE_USER_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.GET_ADMIN_VENDOR_REFFERAL_SUCCESS:
      return {
        ...state,
        vendorRefferal: action.payload,
      };
    case actionTypes.GET_ADMIN_VENDOR_REFFERAL_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.GET_DASHBOARD_COUNT_SUCCESS:
      return {
        ...state,
        dashboardCount: action.payload,
      };
    case actionTypes.GET_DASHBOARD_COUNT_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.GET_TODAY_COUNT_SUCCESS:
      return {
        ...state,
        todayCount: action.payload,
      };
    case actionTypes.GET_TODAY_COUNT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default store;
