import { API_URL, PANEL_API_URL } from "../../config";
import * as actionTypes from "../actionTypes";
import axios from "axios";
import { generatePopup } from "../../utils/popup";
import dayjs from "dayjs";

export const sendOtp = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.OTP_SENT_INIT,
    });
    axios
      .post(`${API_URL}/auth/otp/`, payload)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.OTP_SENT_SUCCESS,
            payload: res.data,
          });
          resolve(res?.data);
        } else {
          dispatch({
            type: actionTypes.OTP_SENT_FAIL,
            payload: res.data?.message || "Failed to sent otp",
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.OTP_SENT_FAIL,
          payload: typeof e == "string" ? e : "Failed to otp",
        });
      });
  });
};

export const phonecheck = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.PHONE_CHECK_INIT,
    });
    axios
      .post(`${API_URL}/auth/phonecheck/`, payload)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.PHONE_CHECK_SUCCESS,
            payload: res.data,
          });
          resolve(res?.data);
        } else {
          dispatch({
            type: actionTypes.PHONE_CHECK_FAIL,
            payload: res.data?.message || "Failed to sent otp",
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.PHONE_CHECK_FAIL,
          payload: typeof e == "string" ? e : "Failed to otp",
        });
      });
  });
};

export const verifyOtp = (payload, navigate) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const mode = localStorage.getItem("mode");
    dispatch({
      type: actionTypes.OTP_VERIFY_INIT,
    });
    axios
      .post(`${API_URL}/auth/login/`, payload, {})
      .then((res) => {
        console.log("====res", res);
        if (res.status === 200) {
          dispatch({
            type: actionTypes.OTP_VERIFY_SUCCESS,
            payload: res.data,
          });
          window.location.replace(
            `${PANEL_API_URL}?token=${res?.data?.access}&mode=${mode}&phone=${res?.data?.phone}`
          );
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.OTP_VERIFY_FAIL,
            payload: res.data?.message || "Failed to verify otp",
          });
        }
      })
      .catch((error) => {
        console.log("======res", error);
        if (error.response.data.otp === "False") {
          dispatch({
            type: actionTypes.OTP_VERIFY_FAIL,
            payload: typeof error == "string" ? error : "Failed to verify otp",
          });
          resolve(error);
          generatePopup("error", error?.response?.data?.message);
        } else {
          dispatch({
            type: actionTypes.OTP_VERIFY_FAIL,
            payload: typeof error == "string" ? error : "User not registered",
          });
          resolve(error);
          generatePopup("error", error?.response?.data?.message);
        }
      });
  });
};

export const authSignup = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.AUTH_SIGNUP_INIT,
    });
    const mode = localStorage.getItem("mode");
    axios
      .post(`${API_URL}/auth/register/`, payload)
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.AUTH_SIGNUP_SUCCESS,
            payload: res.data,
          });
          resolve(res);
          if (res?.data?.status === false) {
            generatePopup("error", res?.data?.message);
          } else {
            generatePopup("success", "User register succesfully.");
            localStorage.setItem("authToken", res?.data?.access);
            localStorage.setItem("phone", res?.data?.phone);
            window.location.replace(
              `${PANEL_API_URL}?token=${res?.data?.access}&mode=${mode}&phone=${res?.data?.phone}`
            );
          }
        } else if (res?.data?.status === false) {
          generatePopup(
            "error",
            res?.data?.message || "Invalid OTP Credential !"
          );
        } else {
          dispatch({
            type: actionTypes.AUTH_SIGNUP_FAIL,
            payload: res.data?.message || "Failed to signup user",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.AUTH_SIGNUP_FAIL,
            payload:
              typeof error == "string" ? error : "User already registered!",
          });
          resolve(error);
          if (error?.response?.data?.email) {
            generatePopup("error", error?.response?.data?.email[0]);
          } else if (error?.response?.data?.phone) {
            generatePopup("error", error?.response?.data?.phone[0]);
          }
        }
      });
  });
};

export const getProfile = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.AUTH_PROFILE_INIT,
    });
    axios
      .get(`${API_URL}/auth/user/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.AUTH_PROFILE_SUCCESS,
            payload: res?.data,
          });
          resolve(res?.data);
        } else {
          dispatch({
            type: actionTypes.AUTH_PROFILE_FAIL,
            payload: res?.data?.message || "Failed to get profile!",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.AUTH_PROFILE_FAIL,
            payload: error?.response?.data?.message,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const editProfile = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.EDIT_PROFILE_INIT,
    });
    axios
      .put(`${API_URL}/auth/user/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.EDIT_PROFILE_SUCCESS,
            payload: res?.data,
          });
          resolve(res?.data);
          generatePopup(
            "success",
            res.data.message || "Profile Edited succesfully."
          );
          dispatch(getProfile());
        } else {
          generatePopup(
            "error",
            res.data.message || "Failed to update profile"
          );
          dispatch({
            type: actionTypes.EDIT_PROFILE_FAIL,
            payload: res?.data?.message || "Failed to get profile!",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          generatePopup("error", error?.response?.data?.message);
          dispatch({
            type: actionTypes.EDIT_PROFILE_FAIL,
            payload: error?.response?.data?.message,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const deleteAccount = (payload, navigate) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    axios
      .get(`${API_URL}/auth/deleteaccount/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.DELETE_ACCOUNT_SUCCESS,
          });

          generatePopup(
            "success",
            res.data.message || "Account deleted succesfully"
          );
          resolve(res);

          window.location.replace("/");
        } else {
          dispatch({
            type: actionTypes.DELETE_ACCOUNT_FAIL,
            payload: res.data?.message || "Failed to delete account",
          });
        }
      })
      .catch((error) => {
        if (error.response.data.otp === "False") {
          dispatch({
            type: actionTypes.DELETE_ACCOUNT_FAIL,
            payload:
              typeof error == "string" ? error : "Failed to delete account",
          });
          resolve(error);
          generatePopup("error", error?.response?.data?.message);
        } else {
          dispatch({
            type: actionTypes.DELETE_ACCOUNT_FAIL,
          });
          resolve(error);
          generatePopup("error", error?.response?.data?.message);
        }
      });
  });
};

// upload tutorial api
export const uploadTutorial = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.UPLOAD_TUTORIAL_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .post(`${API_URL}/auth/uploadtutorial/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.UPLOAD_TUTORIAL_SUCCESS,
            payload: res.data,
          });
          generatePopup("success", "Tutorial Uploaded succesfully.");
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.UPLOAD_TUTORIAL_FAIL,
            payload: res.data?.message || "Failed to upload tutorial",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.UPLOAD_TUTORIAL_FAIL,
            error: error,
          });
          resolve(error);
        }
      });
  });
};

// get tutorials for admin table
export const getAdminTutorial = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.GET_ADMIN_TUTORIAL_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .get(`${API_URL}/auth/uploadtutorial/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.GET_ADMIN_TUTORIAL_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_ADMIN_TUTORIAL_FAIL,
            payload: res.data?.message || "Failed to get tutorial",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_ADMIN_TUTORIAL_FAIL,
            error: error,
          });
          resolve(error);
        }
      });
  });
};

export const getNotification = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.GET_NOTIFICATION_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .get(`${API_URL}/auth/getNotification/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.GET_NOTIFICATION_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_NOTIFICATION_FAIL,
            payload: res.data?.message || "Failed to get tutorial",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_NOTIFICATION_FAIL,
            error: error,
          });
          resolve(error);
        }
      });
  });
};

export const editAdminTutorial = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.EDIT_ADMIN_TUTORIAL_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .put(`${API_URL}/auth/uploadtutorial/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.EDIT_ADMIN_TUTORIAL_SUCCESS,
            payload: res.data,
          });
          generatePopup("success", "Tutorial Edited succesfully.");
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.EDIT_ADMIN_TUTORIAL_FAIL,
            payload: res.data?.message || "Failed to edit tutorial",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.EDIT_ADMIN_TUTORIAL_FAIL,
            error: error,
          });
          resolve(error);
        }
      });
  });
};

export const deleteAdminTutorial = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.DELETE_ADMIN_TUTORIAL_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .post(`${API_URL}/auth/deletetutorial/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.DELETE_ADMIN_TUTORIAL_SUCCESS,
            payload: res.data,
          });
          generatePopup("error", "Tutorial Deleted succesfully.");
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.DELETE_ADMIN_TUTORIAL_FAIL,
            payload: res.data?.message || "Failed to delete tutorial",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.DELETE_ADMIN_TUTORIAL_FAIL,
            error: error,
          });
          resolve(error);
        }
      });
  });
};

// get tutorials for user tutorial page
export const getUserTutorial = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.GET_USER_TUTORIAL_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .post(`${API_URL}/auth/customertutorial/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.GET_USER_TUTORIAL_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_USER_TUTORIAL_FAIL,
            payload: res.data?.message || "Failed to signup user",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_USER_TUTORIAL_FAIL,
            error: error,
          });
          resolve(error);
        }
      });
  });
};

// export const payment = (payload) => (dispatch) => {
//   return new Promise((resolve, reject) => {
//     dispatch({
//       type: actionTypes.GET_USER_TUTORIAL_INIT,
//     });

//     const token = localStorage.getItem("authToken");

//     axios
//       .post(
//         `${API_URL}/invoice/PlanValuePaytm/`,
//         { plan: payload?.plan },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       )
//       .then((res) => {
//         console.log("res", res);
//         if (res.status === 201 || res.status === 200) {
//           const config = {
//             root: "",
//             flow: "DEFAULT",
//             data: {
//               orderId: res?.data?.order_id,
//               token: res?.data?.txn,
//               tokenType: "TXN_TOKEN",
//               amount: res?.data?.total_amount,
//             },
//             merchant: {
//               redirect: false,
//             },
//             handler: {
//               transactionStatus: (paymentStatus) => {
//                 console.log("paymentStatus : ", paymentStatus);
//                 const obj = {
//                   status: paymentStatus?.STATUS,
//                   method: paymentStatus?.PAYMENTMODE,
//                   amount: paymentStatus?.TXNAMOUNT,
//                   gst: res?.data?.gst || "",
//                   gateway_fee: res?.data?.gateway_fee,
//                   total_amount: paymentStatus?.TXNAMOUNT,
//                   order_id: paymentStatus?.ORDERID,
//                   payment_id: paymentStatus?.TXNID,
//                   payment_date: dayjs(paymentStatus?.TXNDATE).format(
//                     "YYYY-MM-DD"
//                   ),
//                   plan: payload?.plan,
//                   phone_type: "iphone",
//                   merchantcode: "435",
//                   txid: paymentStatus?.TXNID,
//                   token: "rrtr",
//                 };
//                 dispatch(getPaytmInvoice(obj)).then((res) => {
//                   console.log("res == == == ", res);
//                   if (res?.data?.status === true) {
//                     console.log("entered");
//                     window.location.href = `${PANEL_API_URL}status?status=true&orderid=${
//                       paymentStatus?.ORDERID
//                     }&txnid=${paymentStatus?.TXNID}&plan=${
//                       payload?.plan
//                     }&amount=${paymentStatus?.TXNAMOUNT}&date=${dayjs(
//                       paymentStatus?.TXNDATE
//                     ).format("YYYY-MM-DD HH:mm:ss")}`;
//                   } else {
//                     window.location.href = `${PANEL_API_URL}status?status=false`;
//                   }
//                 });
//               },
//               notifyMerchant: (eventName, data) => {
//                 console.log("Handler", eventName, data);
//                 if (eventName === "SESSION_EXPIRED") {
//                   alert("Your session has expired!!");
//                   window.location.reload();
//                 }
//               },
//             },
//           };

//           if (window.Paytm && window.Paytm.CheckoutJS) {
//             window.Paytm.CheckoutJS.init(config)
//               .then(() => {
//                 window.Paytm.CheckoutJS.invoke();
//               })
//               .catch((error) => {
//                 console.log("Error => ", error);
//               });
//           } else {
//             console.log("something went wrong");
//           }
//         } else {
//           dispatch({
//             type: actionTypes.GET_USER_TUTORIAL_FAIL,
//             payload: res.data?.message || "Failed to signup user",
//           });
//         }
//       })
//       .catch((error) => {
//         console.log("error :>> ", error);
//         if (error?.response?.status === 400) {
//           dispatch({
//             type: actionTypes.GET_USER_TUTORIAL_FAIL,
//             error: error,
//           });
//           resolve(error);
//         }
//       });
//   });
// };

// response handle and send response to backend

const responseHandler = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.SEND_PAYMENT_DATA_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .post(`${API_URL}/invoice/getInvoiceTest/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("new reponse", res);
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.SEND_PAYMENT_DATA_SUCCESS,
            payload: res.data,
          });
          if (res?.data?.status === true) {
            window.location.reload();
            generatePopup("success", res?.data?.message);
          } else {
            generatePopup("error", res?.data?.message);
          }
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.SEND_PAYMENT_DATA_FAIL,
            payload: res.data?.message || "Failed to signup user",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.SEND_PAYMENT_DATA_FAIL,
            error: error,
          });
          resolve(error);
        }
      });
  });
};

// handle response for payment
const handleResponse = (res, data) => (dispatch) => {
  console.log("res --- ", res, data);
  if (
    typeof res !== "undefined" &&
    typeof res.paymentMethod !== "undefined" &&
    typeof res.paymentMethod.paymentTransaction !== "undefined" &&
    typeof res.paymentMethod.paymentTransaction.statusCode !== "undefined" &&
    res.paymentMethod.paymentTransaction.statusCode === "0300"
  ) {
    console.log("response --- ", res);
    const apiData = {
      datetime: res?.paymentMethod?.paymentTransaction?.dateTime,
      token: res?.paymentMethod?.paymentTransaction?.identifier,
      txid: res?.merchantTransactionIdentifier,
      amount: data?.amount,
      merchantcode: res?.merchantCode,
      status: res?.paymentMethod?.paymentTransaction?.statusMessage,
      method: "test",
      gst: data?.gst,
      gateway_fee: data?.gateway_fee,
      total_amount: data?.total_amount,
      order_id: data?.order_id,
      payment_id: "df3443",
      plan: data?.plan,
      phone_type: "NA",
    };
    dispatch(responseHandler(apiData));
  } else if (
    typeof res !== "undefined" &&
    typeof res.paymentMethod !== "undefined" &&
    typeof res.paymentMethod.paymentTransaction !== "undefined" &&
    typeof res.paymentMethod.paymentTransaction.statusCode !== "undefined" &&
    res.paymentMethod.paymentTransaction.statusCode === "0398"
  ) {
    // initiated block
  } else {
    // error block
  }
};

export const paymentSucess = (data, token) => {
  return async (dispatch) => {
    const payload = data;
    const response = await axios.post(
      `${API_URL}/invoice/getinvoice/`,
      payload,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response;
  };
};

// payment paynimo api
export const payment = (plan, onSuccess, onError, user) => {
  return async (dispatch) => {
    const token = localStorage.getItem("authToken");

    const payload = {
      plan: plan,
    };

    const response = await axios.post(
      `${API_URL}/invoice/planvalue/`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const data = response?.data;

    // live : L998500
    // test : T955031
    // if (data) {
    //   let reqJson = {
    //     features: {
    //       enableAbortResponse: true,
    //       enableExpressPay: true,
    //       enableInstrumentDeRegistration: true,
    //       enableMerTxnDetails: true,
    //       enableNewWindowFlow: true,
    //     },
    //     consumerData: {
    //       deviceId: "WEBSH1", //possible values "WEBSH1" or "WEBSH2"
    //       token: data?.token,
    //       responseHandler: (res) => {
    //         dispatch(handleResponse(res, data));
    //       },
    //       paymentMode: "all",
    //       merchantLogoUrl:
    //         "https://emailwala.s3.ap-south-1.amazonaws.com/TradeArthLogofor+payment.png",
    //       merchantId: "L998500",
    //       currency: "INR",
    //       consumerId: data?.consumerid,
    //       consumerMobileNo: user?.phone,
    //       consumerEmailId: user?.email,
    //       txnId: data?.order_id,
    //       // returnUrl: `${PANEL_API_URL}/planpricing`,
    //       items: [
    //         {
    //           itemId: "first",
    //           amount: data?.total_amount,
    //           comAmt: "0",
    //         },
    //       ],
    //       customStyle: {
    //         PRIMARY_COLOR_CODE: "#0a53b5", //merchant primary color code
    //         SECONDARY_COLOR_CODE: "#FFFFFF", //provide merchant's suitable color code
    //         BUTTON_COLOR_CODE_1: "#166bdd", //merchant's button background color code
    //         BUTTON_COLOR_CODE_2: "#FFFFFF", //provide merchant's suitable color code for button text
    //       },
    //     },
    //   };

    //   if (window.$.pnCheckout) {
    //     window.$.pnCheckout(reqJson);
    //     if (reqJson.features.enableNewWindowFlow) {
    //       window.pnCheckoutShared.openNewWindow();
    //     }
    //   }
    // }

    // const reqJson = {
    //   features: {
    //     enableAbortResponse: true,
    //     enableExpressPay: true,
    //     enableInstrumentDeRegistration: true,
    //     enableMerTxnDetails: true,
    //     enableNewWindowFlow: true,
    //   },
    //   consumerData: {
    //     deviceId: "WEBSH1",
    //     // returnUrl: `${PANEL_API_URL}planpricing`,
    //     txnId: response?.data?.order_id,
    //     token: response?.data?.token,
    //     items: [
    //       {
    //         itemId: "first",
    //         amount: response?.data?.total_amount,
    //         comAmt: "0",
    //       },
    //     ],
    //     consumerid: response?.data?.consumerid,
    //     merchantId: "L998500",
    //     // Other consumer data
    //   },
    // };

    const res = await loadScript(
      // "https://paynimo.com/paynimokit/"
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      onError();
      return;
    }

    console.log("data :>> ", data);

    // if (window.$.pnCheckout) {
    // console.log("checkout");
    //   window.$.pnCheckout(reqJson);
    //   if (reqJson.features.enableNewWindowFlow) {
    //     window.pnCheckoutShared.openNewWindow();
    //   }
    // } else {
    //   console.error("$.pnCheckout is not available");
    // }

    // Dispatch the payment initiated action directly

    // const response = await axios.post(
    //   `${API_URL}/invoice/planvalue/`,
    //   payload,
    //   {
    //     headers: { Authorization: `Bearer ${token}` },
    //   }
    // );
    // const data = response?.data;
    let randomNumber = Math.floor(Math.random() * 90000000) + 10000000;
    const options = {
      key: process.env.REACT_APP_RAZOR_PAY_KEY,
      // key: "rzp_test_1Tj4XwlZ1EPkau",
      currency: "INR",
      amount: (data?.total_amount * 100).toString(),
      // order_id: data?.order_id,
      name: "Trade Arth",
      prefill: {
        name: user?.name || "",
        email: user?.email || "",
        contact: user?.phone || "",
      },
      handler: async (response) => {
        console.log("response", response);
        const obj = {
          amount: data?.amount,
          gateway_fee: data?.gateway_fee,
          gst: data?.gst?.toFixed(2),
          total_amount: data?.total_amount,
          payment_id: response?.razorpay_payment_id,
          order_id: data?.order_id,
          status: "",
          payment_date: dayjs().format("YYYY-MM-DD"),
          status: "complete",
          plan: "Basic",
        };
        onSuccess(obj, plan);
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };
};

// load script for payment api
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

// get payment invoice data after payment successful
export const getInvoice = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.PAYMENT_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .post(`${API_URL}/invoice/getinvoice/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.PAYMENT_SUCCESS,
            payload: res.data,
          });
          generatePopup("success", "Paymnet succesfully.");
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.PAYMENT_FAIL,
            payload: res.data?.message || "Failed to Payment",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.PAYMENT_FAIL,
            error: error,
          });
          resolve(error);
        }
      });
  });
};

export const getPaytmInvoice = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.GET_PAYMENT_INVOICE_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .post(`${API_URL}/invoice/getInvoicedetailspaytm/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.GET_PAYMENT_INVOICE_SUCCESS,
            payload: res.data,
          });
          // generatePopup("success", "Paymnet succesfully.");
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_PAYMENT_INVOICE_FAIL,
            payload: res.data?.message || "Failed to Payment",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_PAYMENT_INVOICE_FAIL,
            error: error,
          });
          resolve(error);
        }
      });
  });
};

export const getReferCode = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.GET_REFRECODE_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .get(`${API_URL}/auth/getrefrecode/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.GET_REFRECODE_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_REFRECODE_FAIL,
            payload: res.data?.message || "Failed to Get Refrecode",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_REFRECODE_FAIL,
            error: error,
          });
          resolve(error);
        }
      });
  });
};

export const getUserVendor = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.GET_USER_VENDOR_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .post(`${API_URL}/subbroker/getuservendor/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.GET_USER_VENDOR_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_USER_VENDOR_FAIL,
            payload: res.data?.message || "Failed to Get Refrecode",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_USER_VENDOR_FAIL,
            error: error,
          });
          resolve(error);
        }
      });
  });
};

export const sendPushNotifications = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.SEND_PUSH_NOTIFICATION_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .post(`${API_URL}/auth/pushnotification/`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.SEND_PUSH_NOTIFICATION_SUCCESS,
            payload: res.data,
          });
          generatePopup("success", res?.data?.message);
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.SEND_PUSH_NOTIFICATION_FAIL,
            payload: res.data?.message || "Failed to Payment",
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.SEND_PUSH_NOTIFICATION_FAIL,
            error: error,
          });
        }
      });
  });
};

export const getSymbolToken = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.GET_SYMBOL_TOKEN_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .get(`${API_URL}/auth/symbolupdate/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.GET_SYMBOL_TOKEN_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_SYMBOL_TOKEN_FAIL,
            payload: res.data?.message || "Failed to get symbol token",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_SYMBOL_TOKEN_FAIL,
            error: error,
          });
          generatePopup("error", error?.response?.data?.message);
          resolve(error);
        }
      });
  });
};

export const deleteSymbolToken = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.DELETE_SYMBOL_TOKEN_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .post(`${API_URL}/auth/symboldelete/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.DELETE_SYMBOL_TOKEN_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.DELETE_SYMBOL_TOKEN_FAIL,
            payload: res.data?.message || "Failed to get symbol token",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.DELETE_SYMBOL_TOKEN_FAIL,
            error: error,
          });
          generatePopup("error", error?.response?.data?.message);
          resolve(error);
        }
      });
  });
};

export const editSymbolToken = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.EDIT_SYMBOL_TOKEN_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .put(`${API_URL}/auth/symbolupdate/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.EDIT_SYMBOL_TOKEN_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.EDIT_SYMBOL_TOKEN_FAIL,
            payload: res.data?.message || "Failed to get symbol token",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.EDIT_SYMBOL_TOKEN_FAIL,
            error: error,
          });
          generatePopup("error", error?.response?.data?.message);
          resolve(error);
        }
      });
  });
};

export const createSymbolToken = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.CREATE_SYMBOL_TOKEN_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .post(`${API_URL}/auth/symbolupdate/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.CREATE_SYMBOL_TOKEN_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.CREATE_SYMBOL_TOKEN_FAIL,
            payload: res.data?.message || "Failed to create symbol token",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.CREATE_SYMBOL_TOKEN_FAIL,
            error: error,
          });
          generatePopup("error", error?.response?.data?.message);
          resolve(error);
        }
      });
  });
};

export const activateCopyTrade = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.ACTIVATE_COPY_TRADE_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .post(`${API_URL}/auth/startCopyTrade/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.ACTIVATE_COPY_TRADE_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.ACTIVATE_COPY_TRADE_FAIL,
            payload: res.data?.message || "Failed to activate copytrade",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.ACTIVATE_COPY_TRADE_FAIL,
            error: error,
          });
          generatePopup("error", error?.response?.data?.message);
          resolve(error);
        }
      });
  });
};

export const stopCopyTrade = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.STOP_COPY_TRADE_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .put(`${API_URL}/auth/startCopyTrade/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.STOP_COPY_TRADE_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.STOP_COPY_TRADE_FAIL,
            payload: res.data?.message || "Failed to activate copytrade",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.STOP_COPY_TRADE_FAIL,
            error: error,
          });
          generatePopup("error", error?.response?.data?.message);
          resolve(error);
        }
      });
  });
};

export const addSegmentCopytrade = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.ADD_SEGMENT_COPY_TRADE_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .post(`${API_URL}/auth/addSegmentCopyTrade/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.ADD_SEGMENT_COPY_TRADE_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.ADD_SEGMENT_COPY_TRADE_FAIL,
            payload: res.data?.message || "Failed to add segment copytrade",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.ADD_SEGMENT_COPY_TRADE_FAIL,
            error: error,
          });
          generatePopup("error", error?.response?.data?.message);
          resolve(error);
        }
      });
  });
};

export const editSegmentCopytrade = (payload) => (dispatch) => {
  console.log("payload", payload);
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.EDIT_SEGMENT_COPY_TRADE_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .put(`${API_URL}/auth/addSegmentCopyTrade/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.EDIT_SEGMENT_COPY_TRADE_SUCCESS,
            payload: res.data,
          });
          generatePopup("success", res?.data?.message);
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.EDIT_SEGMENT_COPY_TRADE_FAIL,
            payload: res.data?.message || "Failed to add segment copytrade",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.EDIT_SEGMENT_COPY_TRADE_FAIL,
            error: error,
          });
          generatePopup("error", error?.response?.data?.message);
          resolve(error);
        }
      });
  });
};

export const deleteSegmentCopytrade = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.DELETE_SEGMENT_COPY_TRADE_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .put(`${API_URL}/auth/deleteSegmentCopyTrade/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.DELETE_SEGMENT_COPY_TRADE_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.DELETE_SEGMENT_COPY_TRADE_FAIL,
            payload: res.data?.message || "Failed to add segment copytrade",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.DELETE_SEGMENT_COPY_TRADE_FAIL,
            error: error,
          });
          generatePopup("error", error?.response?.data?.message);
          resolve(error);
        }
      });
  });
};

export const getSegmentCopytrade = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.GET_SEGMENT_COPY_TRADE_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .get(`${API_URL}/auth/addSegmentCopyTrade/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.GET_SEGMENT_COPY_TRADE_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_SEGMENT_COPY_TRADE_FAIL,
            payload: res.data?.message || "Failed to add segment copytrade",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_SEGMENT_COPY_TRADE_FAIL,
            error: error,
          });
          generatePopup("error", error?.response?.data?.message);
          resolve(error);
        }
      });
  });
};

export const getSymbolList = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.GET_SYMBOL_LIST_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .post(`${API_URL}/auth/getlist/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.GET_SYMBOL_LIST_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_SYMBOL_LIST_FAIL,
            payload: res.data?.message || "Failed to get symbol list",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_SYMBOL_LIST_FAIL,
            error: error,
          });
          generatePopup("error", error?.response?.data?.message);
          resolve(error);
        }
      });
  });
};

export const updateUserStrategy = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.UPDATE_USER_STRATEGY_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .put(`${API_URL}/stratergy/updateUserStratergy/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.UPDATE_USER_STRATEGY_SUCCESS,
            payload: res.data,
          });
          resolve(res);
          generatePopup("success", res?.data?.message);
        } else {
          dispatch({
            type: actionTypes.UPDATE_USER_STRATEGY_FAIL,
            payload: res.data?.message || "Failed to get symbol list",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.UPDATE_USER_STRATEGY_FAIL,
            error: error,
          });
          generatePopup("error", error?.response?.data?.message);
          resolve(error);
        }
      });
  });
};

export const getServiceCharges = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.GET_SERVICE_CHARGES_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .get(`${API_URL}/invoice/servicecharges/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.GET_SERVICE_CHARGES_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_SERVICE_CHARGES_FAIL,
            payload: res.data?.message || "Failed to get service charges",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_SERVICE_CHARGES_FAIL,
            error: error,
          });
          generatePopup("error", error?.response?.data?.message);
          resolve(error);
        }
      });
  });
};

export const updateServiceCharges = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.EDIT_SERVICE_CHARGES_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .put(`${API_URL}/invoice/servicecharges/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.EDIT_SERVICE_CHARGES_SUCCESS,
            payload: res.data,
          });
          resolve(res);
          generatePopup("success", res?.data?.message);
        } else {
          dispatch({
            type: actionTypes.EDIT_SERVICE_CHARGES_FAIL,
            payload: res.data?.message || "Failed to edit service charges",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.EDIT_SERVICE_CHARGES_FAIL,
            error: error,
          });
          generatePopup("error", error?.response?.data?.message);
          resolve(error);
        }
      });
  });
};

// Generate Trading Key
export const generateTradingKey = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.GENERATE_TRADING_KEY_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .get(`${API_URL}/auth/generateTradingkey/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.GENERATE_TRADING_KEY_SUCCESS,
            payload: res.data,
          });
          resolve(res);
          generatePopup("success", res?.data?.message);
        } else {
          dispatch({
            type: actionTypes.GENERATE_TRADING_KEY_FAIL,
            payload: res.data?.message || "Failed to edit generate trading key",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GENERATE_TRADING_KEY_FAIL,
            error: error,
          });
          generatePopup("error", error?.response?.data?.message);
          resolve(error);
        }
      });
  });
};

// cron run
export const cronRun = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.CRON_RUN_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .post(`${API_URL}/auth/cronrun/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.CRON_RUN_SUCCESS,
            payload: res.data,
          });
          resolve(res);
          generatePopup("success", res?.data?.message);
        } else {
          dispatch({
            type: actionTypes.CRON_RUN_FAIL,
            payload: res.data?.message || "Failed to edit Run cron ",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.CRON_RUN_FAIL,
            error: error,
          });
          generatePopup("error", error?.response?.data?.message);
          resolve(error);
        }
      });
  });
};

// get crons
export const getCrons = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.GET_CRON_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .get(`${API_URL}/auth/cronrun/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.GET_CRON_SUCCESS,
            payload: res.data,
          });
          resolve(res);
          generatePopup("success", res?.data?.message);
        } else {
          dispatch({
            type: actionTypes.GET_CRON_FAIL,
            payload: res.data?.message || "Failed to edit get crons",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_CRON_FAIL,
            error: error,
          });
          generatePopup("error", error?.response?.data?.message);
          resolve(error);
        }
      });
  });
};

// get sms text alert
export const getSmsTextAlert = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.GET_SMS_TEXT_ALERT_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .get(`${API_URL}/auth/sendsmsview/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.GET_SMS_TEXT_ALERT_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_SMS_TEXT_ALERT_FAIL,
            payload: res.data?.message || "Failed to edit get cron text alert",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_SMS_TEXT_ALERT_FAIL,
            error: error,
          });
          generatePopup("error", error?.response?.data?.message);
          resolve(error);
        }
      });
  });
};

// add sms text alert
export const addSmsTextAlert = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.ADD_SMS_TEXT_ALERT_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .post(`${API_URL}/auth/sendsmsview/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.ADD_SMS_TEXT_ALERT_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.ADD_SMS_TEXT_ALERT_FAIL,
            payload: res.data?.message || "Failed to edit add sms text",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.ADD_SMS_TEXT_ALERT_FAIL,
            error: error,
          });
          generatePopup("error", error?.response?.data?.message);
          resolve(error);
        }
      });
  });
};

// delete borker in admin
export const brokerDelete = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.DELETE_BROKER_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .put(`${API_URL}/auth/brokerdelete/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.DELETE_BROKER_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.DELETE_BROKER_FAIL,
            payload: res.data?.message || "Failed to edit add sms text",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.DELETE_BROKER_FAIL,
            error: error,
          });
          generatePopup("error", error?.response?.data?.message);
          resolve(error);
        }
      });
  });
};

export const showBroker = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.GET_BROKER_INIT,
    });
    const token = localStorage.getItem("authToken");
    axios
      .post(`${API_URL}/auth/brokerdelete/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          dispatch({
            type: actionTypes.GET_BROKER_SUCCESS,
            payload: res.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_BROKER_FAIL,
            payload: res.data?.message || "Failed to edit add sms text",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 500) {
          dispatch({
            type: actionTypes.BROKER_NOT_EXIST,
            error: [],
          });
        }
        if (error?.response?.status === 400) {
          generatePopup("error", error?.response?.data?.message);
          dispatch({
            type: actionTypes.GET_BROKER_FAIL,
            error: error,
          });
          resolve(error);
        }
      });
  });
};
