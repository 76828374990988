import React, { useState } from "react";
import {
  Box,
  Grid,
  Button,
  Tooltip,
  Typography,
  TextField,
} from "@mui/material";
import Table from "../../../Table/Table";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Tableaction from "../../../Tableaction/Tableaction";

export default function Ctradestrategy() {
  const add = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      Add Data
    </Typography>
  );

  const col = ["id", "CustomerId", "Strategy Name", "Actions"];
  const rows = [
    {
      id: 3,
      customerid: 10,
      strategyname: "MYTRADE",
      action: <Tableaction />,
    },
  ];

  const [fields, setFields] = useState();

  const handleInputChange = (event) => {
    const keyName = event.target.name;
    const keyValue = event.target.value;
    setFields((prevState) => ({
      ...prevState,
      [keyName]: keyValue,
    }));
  };

  return (
    <>
      <Box className="tabelBox">
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12}>
            <Box className="selectiondiv-box m-rb">
              <Box className="selectionDiv">
                <Box
                  className="inputFields space"
                  sx={{ margin: "0 !important" }}
                >
                  <TextField
                    placeholder="Customer ID"
                    name="customerid"
                    value={fields?.customerid}
                    onChange={handleInputChange}
                  />
                </Box>
              </Box>
              <Box
                className="selectionDiv flexBox"
                sx={{ display: "flex !important", alignItems: "center" }}
              >
                <Box
                  className="inputFields space"
                  sx={{ margin: "0 !important" }}
                >
                  <TextField
                    placeholder="Strategy"
                    name="strategy"
                    value={fields?.strategy}
                    onChange={handleInputChange}
                  />
                </Box>
                <Tooltip title={add} arrow>
                  <Button
                    className="download-btn solidButton"
                    sx={{ marginLeft: "1rem" }}
                  >
                    <AddCircleIcon />
                  </Button>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Table col={col} rows={rows} />
      </Box>
    </>
  );
}
