// ResizeObserver loop completed with undelivered notifications.

import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Typography,
    FormControlLabel,
    Radio,
    FormControl,
    Select,
    MenuItem,
    Checkbox,
    InputBase,
    Tooltip,
    RadioGroup,
    Switch,
    Dialog,
    DialogContent,
    DialogContentText,
    Grid,
    Table,
    TableBody,
    Tab,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Autocomplete,
    TextField,
} from "@mui/material";
import Dropdown from "../../../Dropdown/Dropdown";
import { TabContext, TabPanel } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import close from "../../../../images/close.png";
import noData from "../../../../images/page_456.jpg";
import "../../../Home/Home.scss";
import "../../../Table/Table.scss";
import "../../../Marketwatch/Marketwatch.scss";
import { messageEvent, socket } from "../../../../hooks/useSocket";
import { useDispatch, useSelector } from "react-redux";
import {
    addWatchListData,
    deleteWatchList,
    futureExchange,
    getWatchListData,
    nseExchange,
} from "../../../../redux/actions/marketWatchAction";
import { generatePopup } from "../../../../utils/popup";
import {
    createPaperPosition,
    createPosition,
} from "../../../../redux/actions/positionAction";
import { addcopytrade } from "../../../../redux/actions/adminActions";
import dayjs from "dayjs";
import { getProfile } from "../../../../redux/actions/authActions";
import { BrokerStatus } from "../../../../redux/actions/brokerAction";

const sharekhanDrpValue = ["INVESTMENT", "BIGTRADE", "BIGTRADE+"];
const drpValue = ["MIS", "NRML"];
const instrumentTypes = {
    FS: "Future Stocks(FS)",
    FI: "Future Index(FI)",
    OI: " Option Index(OI)",
    OS: "Option Stocks(OS)",
    FUTCUR: "Future Currency(FUTCUR)",
    OPTCUR: "Option Currency(OPTCUR)",
};

const up = (
    <svg
        width="8"
        height="9"
        viewBox="0 0 8 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3.51526 1.03329V8.06192M0.58667 3.96189L3.51526 1.03329L0.58667 3.96189ZM3.51526 1.03329L6.44386 3.96189L3.51526 1.03329Z"
            stroke="#24a959"
            strokeWidth="1.17144"
            strokeLinecap="round"
            strokeLinejoin="round"
        ></path>
    </svg>
);
const down = (
    <svg
        width="8"
        height="9"
        viewBox="0 0 8 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3.60241 8.06195L3.60241 1.03332M6.53101 5.13336L3.60241 8.06195L6.53101 5.13336ZM3.60241 8.06195L0.673816 5.13336L3.60241 8.06195Z"
            stroke="#FF231F"
            stroke-width="1.17144"
            stroke-linecap="round"
            stroke-linejoin="round"
        ></path>
    </svg>
);

const arrowUp = (
    <svg
        width="8"
        height="9"
        viewBox="0 0 8 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3.51526 1.03329V8.06192M0.58667 3.96189L3.51526 1.03329L0.58667 3.96189ZM3.51526 1.03329L6.44386 3.96189L3.51526 1.03329Z"
            stroke="white"
            strokeWidth="1.17144"
            strokeLinecap="round"
            strokeLinejoin="round"
        ></path>
    </svg>
);
const arrowDown = (
    <svg
        width="8"
        height="9"
        viewBox="0 0 8 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3.60241 8.06195L3.60241 1.03332M6.53101 5.13336L3.60241 8.06195L6.53101 5.13336ZM3.60241 8.06195L0.673816 5.13336L3.60241 8.06195Z"
            stroke="white"
            stroke-width="1.17144"
            stroke-linecap="round"
            stroke-linejoin="round"
        ></path>
    </svg>
);

const drpValue3 = ["Intraday", "Positional"];

export default function Copytrade() {
    const [open, setOpen] = React.useState(false);
    const [bsopen, setbsopen] = React.useState(false);
    const [sell, setbuy] = React.useState("buy");
    const [buysell, setbuysell] = React.useState("Buy");
    const [prdType, setPrdType] = useState("MIS");
    const [broker, setBroker] = useState("");
    const [trp, settTrp] = useState(0);
    const [selectedStock, setSelectedStock] = useState();
    const [selectedIndex, setSelectedIndex] = useState();
    const [search, setSearch] = useState("");
    const [payload, setPayload] = useState({});
    const [exchange, setExchange] = useState("nse");
    const [value, setValue] = useState("mw1");
    const [deleteData, setDeleteData] = useState([]);
    const [deletePop, setDeletePop] = useState(false);
    const [instType, setInstType] = useState("");
    const [qty, setQty] = useState("");
    const dispatch = useDispatch();
    const [orderType, setOrderType] = useState("Market");
    const [tradeType, setTradeType] = useState("Live");
    const [load, setLoad] = useState(false);
    const [trading, setTrading] = useState("Intraday");
    const [segment, setSegment] = useState("select segment");

    const watchListLiveData = useSelector((state) => {
        return state?.Marketwatch?.watchListData
    });

    useEffect(() => {
        dispatch(getWatchListData())
    }, [])

    const segmentList = useSelector((state) => state?.Position?.segmentList);

    const [symbolSocket, setSymbolSocket] = useState("");

    useEffect(() => {
        let ws;
        const setupWebSocket = async () => {
            ws = await socket(
                "watchlist",
                process.env.REACT_APP_PAPER_CUSTOMER_WEBSOCKET_URL
            );
            setSymbolSocket(ws);
            if (ws) {
                messageEvent(ws, "watchlist", dispatch, { value: "" });
            }
        };
        setupWebSocket();
        window.onbeforeunload = async function (event) {
            console.log("closed");
            return await ws?.send({ remark: "disconnect" });
        };
        return () => {
            if (ws && ws.readyState === WebSocket.OPEN) {
                ws?.send("disconnect");
            }
        };
    }, []);

    useEffect(() => {
        if (symbolSocket && symbolSocket.readyState === WebSocket.OPEN) {
            messageEvent(symbolSocket, "watchlist", dispatch, { value: search?.toUpperCase() });
        }
    }, [search, symbolSocket])

    // ============
    const handletrading = (event) => {
        setTrading(event.target.value);
    };

    const handlesegment = (event) => {
        setSegment(event.target.value);
    };

    const watchlistData = useSelector((state) => state?.Position?.watchlist)

    // Add Symbol Modal
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (broker === "sharekhan") {
            setPrdType("INVESTMENT");
        }
    }, [broker]);

    useEffect(() => {
        dispatch(getProfile());
        dispatch(BrokerStatus());
    }, []);

    const brokerDetails = useSelector(
        (state) => state?.Broker?.brokerstatus?.[0]
    );
    useEffect(() => {
        if (brokerDetails?.brokername) {
            setBroker(brokerDetails?.brokername);
        }
    }, [brokerDetails?.brokername]);

    // console.log('selectedStock', selectedStock)

    const createTrade = () => {
        setLoad(true);
        if (qty === 0) {
            return generatePopup("error", "Please enter quantity.");
        }

        if (
            selectedStock?.lot &&
            selectedStock?.lot !== "0" &&
            selectedStock?.lot !== "undefined"
        ) {
            if (qty % Number(selectedStock?.lot) !== 0) {
                return generatePopup("error", "Please Select Valid Quantity.");
            }
        }

        let obj = {
            segment: segment?.toUpperCase(),
            symbol: selectedStock?.symbol?.toUpperCase(),
            tradetype: trading,
            side: buysell?.toUpperCase(),
            expiry: selectedStock?.expiry,
            strikeprice: String(parseInt(selectedStock?.strikeprice)),
            tag: selectedStock?.option,
            prctyp: orderType?.toUpperCase(),
            order_type: orderType?.toUpperCase(),
            prc: trp
        };

        dispatch(addcopytrade(obj)).then((res) => {
            if (res) {
                setLoad(false);
                setSelectedIndex();
                setSelectedStock();
                settTrp(0);
                buysellClose();
            }
        })
        setLoad(false)
    };

    // Buy/Sell Modal
    const buysellOpen = (value, stock, index) => {
        setQty(stock?.lot === "undefined" || stock?.lot === "0" ? 1 : stock?.lot);
        setSelectedIndex(index);
        setbuy(value);
        setbuysell(value);
        setbsopen(true);
    };

    useEffect(() => {
        setSelectedStock(watchListLiveData?.[selectedIndex]);
    }, [selectedIndex]);

    const buysellClose = () => {
        setbsopen(false);
    };
    const buysellpopup = () => {
        if (sell === "buy") {
            setbuy("sell");
            setbuysell("sell");
        } else {
            setbuy("buy");
            setbuysell("buy");
        }
    };

    const handleInstrument = (e) => {
        const { value } = e?.target;
        setInstType(value);
    };

    let ws = null;
    useEffect(() => {
        getWatchListLiveSocket();

        window.onbeforeunload = async function (event) {
            console.log("closed");
            return await ws?.send("disconnect");
        };
        return () => {
            if (ws && ws.readyState === WebSocket.OPEN) {
                ws?.send("disconnect");
            }
        };
    }, []);

    const getWatchListLiveSocket = async () => {
        ws = await socket("watchlistupdate");

        messageEvent(ws, "watchlistupdate", dispatch);
    };

    const handleRowSelection = (d, allRow = false, e = null) => {
        if (allRow) {
            if (e.target.checked) {
                setDeleteData(watchListLiveData);
            } else {
                setDeleteData([]);
            }
        } else {
            if (deleteData?.find((val) => val.token === d.token)) {
                setDeleteData(deleteData?.filter((rowId) => rowId.token !== d.token));
            } else {
                setDeleteData([...deleteData, d]);
            }
        }
    };
    const onDeleteWatchList = () => {
        setDeletePop(true);
    };

    const handleAddSymbol = () => {
        const obj = {
            exchange: payload?.exchange,
            expiry: payload?.expiry,
            option: payload?.option,
            strikeprice: payload?.strikeprice,
            symbol: payload?.symbol,
            token: payload?.token,
            lot: 0,
            tradingsymbol: payload?.tradingsymbol
        }
        dispatch(addWatchListData(obj))
        setOpen(false)
        dispatch(getWatchListData())
    }

    const deleteWatchListData = () => {
        const obj = deleteData?.map((e) => {
            return {
                symbol: e?.symbol,
                token: e?.token,
                exchange: e?.exchange,
                tradingsymbol: e?.tradingsymbol,
                option: e?.option,
                expiry: e?.expiry,
                strikeprice: e?.strikeprice,
                lot: e?.lot,
            };
        });

        dispatch(deleteWatchList(obj));
        setDeleteData([]);
        setDeletePop(false);
    };

    return (
        <>
            <Box className="tabs">
                <TabContext value={value}>
                    <Box className="tabBox" border={"none !important"}>
                        <TabPanel value={"mw1"} sx={{ padding: 0 }}>
                            <Box className="border-ap">
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Tooltip
                                                arrow
                                                placement="top"
                                                title={
                                                    <Typography sx={{ fontSize: "1.4rem" }}>
                                                        Add Symbol
                                                    </Typography>
                                                }
                                            >
                                                <Button
                                                    className="collapse-btn"
                                                    onClick={handleClickOpen}
                                                    sx={{ margin: "1rem 0 0 1rem" }}
                                                >
                                                    <AddIcon />
                                                </Button>
                                            </Tooltip>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginTop: 1,
                                                }}
                                            >
                                                <Button
                                                    disabled={deleteData.length === 0}
                                                    onClick={() => onDeleteWatchList()}
                                                    className="delete_mw"
                                                >
                                                    <DeleteIcon />
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TableContainer sx={{ MarginTop: 16, maxHeight: 440 }}>
                                            <Table
                                                stickyHeader
                                                sx={{ minWidth: "100%" }}
                                                className={`table tableData`}
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Checkbox
                                                                sx={{
                                                                    "& .MuiSvgIcon-root": { fontSize: 24 },
                                                                    padding: 0,
                                                                }} k={(e) =>
                                                                    handleRowSelection(undefined, true, e)
                                                                }
                                                                checked={
                                                                    watchListLiveData?.length ==
                                                                    deleteData?.length
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell>Symbol Name</TableCell>
                                                        <TableCell>Token</TableCell>
                                                        <TableCell>Expiry</TableCell>
                                                        <TableCell>Strikeprice</TableCell>

                                                        <TableCell>Actions</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {watchListLiveData?.length > 0 ? (
                                                        <>
                                                            {watchListLiveData?.map((d, index) => {
                                                                return (
                                                                    <>
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                <Checkbox
                                                                                    checked={
                                                                                        deleteData?.find(
                                                                                            (e) => e.token === d.token
                                                                                        )
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    onChange={() => handleRowSelection(d)}
                                                                                    sx={{
                                                                                        "& .MuiSvgIcon-root": {
                                                                                            fontSize: 24,
                                                                                        },
                                                                                        padding: 0,
                                                                                    }}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell>{d?.tradingsymbol}</TableCell>
                                                                            <TableCell>{d?.token}</TableCell>
                                                                            <TableCell>{d?.expiry}</TableCell>
                                                                            <TableCell>{d?.strikeprice}</TableCell>
                                                                            <TableCell>
                                                                                <Box className="tableActions">
                                                                                    <Box
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            justifyContent: "center",
                                                                                        }}
                                                                                        className="actionButton"
                                                                                    >
                                                                                        <Button
                                                                                            className="buy"
                                                                                            variant="text"
                                                                                            onClick={() =>
                                                                                                buysellOpen("buy", d, index)
                                                                                            }
                                                                                        >
                                                                                            <svg
                                                                                                width="15"
                                                                                                height="19"
                                                                                                viewBox="0 0 15 19"
                                                                                                fill="none"
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                            >
                                                                                                <path
                                                                                                    d="M10.622 9.718C11.5927 9.89133 12.416 10.4027 13.092 11.252C13.768 12.1013 14.106 13.0633 14.106 14.138C14.106 15.0567 13.8633 15.8887 13.378 16.634C12.91 17.362 12.2253 17.9427 11.324 18.376C10.4227 18.792 9.374 19 8.178 19H0.95V0.929999H7.84C9.07067 0.929999 10.128 1.138 11.012 1.554C11.896 1.97 12.5633 2.53333 13.014 3.244C13.4647 3.93733 13.69 4.71733 13.69 5.584C13.69 6.624 13.4127 7.49067 12.858 8.184C12.3033 8.87733 11.558 9.38867 10.622 9.718ZM3.914 8.522H7.58C8.55067 8.522 9.30467 8.30533 9.842 7.872C10.3967 7.42133 10.674 6.78 10.674 5.948C10.674 5.13333 10.3967 4.50067 9.842 4.05C9.30467 3.582 8.55067 3.348 7.58 3.348H3.914V8.522ZM7.918 16.582C8.92333 16.582 9.712 16.3393 10.284 15.854C10.856 15.3687 11.142 14.6927 11.142 13.826C11.142 12.942 10.8387 12.24 10.232 11.72C9.62533 11.2 8.81933 10.94 7.814 10.94H3.914V16.582H7.918Z"
                                                                                                    fill="white"
                                                                                                />
                                                                                            </svg>
                                                                                        </Button>
                                                                                        <Button
                                                                                            className="sell"
                                                                                            variant="text"
                                                                                            onClick={() =>
                                                                                                buysellOpen("sell", d, index)
                                                                                            }
                                                                                        >
                                                                                            <svg
                                                                                                width="14"
                                                                                                height="20"
                                                                                                viewBox="0 0 14 20"
                                                                                                fill="none"
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                            >
                                                                                                <path
                                                                                                    d="M6.904 19.182C5.69067 19.182 4.59867 18.974 3.628 18.558C2.65733 18.1247 1.89467 17.518 1.34 16.738C0.785333 15.958 0.508 15.048 0.508 14.008H3.68C3.74933 14.788 4.05267 15.4293 4.59 15.932C5.14467 16.4347 5.916 16.686 6.904 16.686C7.92667 16.686 8.724 16.4433 9.296 15.958C9.868 15.4553 10.154 14.814 10.154 14.034C10.154 13.4273 9.972 12.9333 9.608 12.552C9.26133 12.1707 8.81933 11.876 8.282 11.668C7.762 11.46 7.034 11.2347 6.098 10.992C4.91933 10.68 3.95733 10.368 3.212 10.056C2.484 9.72667 1.86 9.224 1.34 8.548C0.82 7.872 0.56 6.97067 0.56 5.844C0.56 4.804 0.82 3.894 1.34 3.114C1.86 2.334 2.588 1.736 3.524 1.32C4.46 0.903999 5.54333 0.695999 6.774 0.695999C8.52467 0.695999 9.95467 1.138 11.064 2.022C12.1907 2.88867 12.8147 4.08467 12.936 5.61H9.66C9.608 4.95133 9.296 4.388 8.724 3.92C8.152 3.452 7.398 3.218 6.462 3.218C5.61267 3.218 4.91933 3.43467 4.382 3.868C3.84467 4.30133 3.576 4.92533 3.576 5.74C3.576 6.29467 3.74067 6.754 4.07 7.118C4.41667 7.46467 4.85 7.742 5.37 7.95C5.89 8.158 6.60067 8.38333 7.502 8.626C8.698 8.95533 9.66867 9.28467 10.414 9.614C11.1767 9.94333 11.818 10.4547 12.338 11.148C12.8753 11.824 13.144 12.734 13.144 13.878C13.144 14.7967 12.8927 15.6633 12.39 16.478C11.9047 17.2927 11.1853 17.9513 10.232 18.454C9.296 18.9393 8.18667 19.182 6.904 19.182Z"
                                                                                                    fill="white"
                                                                                                />
                                                                                            </svg>
                                                                                        </Button>
                                                                                    </Box>
                                                                                </Box>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </>
                                                                );
                                                            })}
                                                        </>
                                                    ) : (
                                                        <TableRow className="noHover">
                                                            <TableCell colSpan={12}>
                                                                <Box className="no-data">
                                                                    <img src={noData} />
                                                                </Box>
                                                                <Typography
                                                                    component={"p"}
                                                                    className="no-dataText"
                                                                >
                                                                    You have no position, place an order to open a
                                                                    new position
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Box>
                        </TabPanel>

                    </Box>
                </TabContext>
            </Box>

            {/* Add Watchlist */}
            <Dialog
                open={open}
                onClose={handleClose}
                className="commonModal addSymbol"
                fullWidth
            >
                <Box className="modalHeader" sx={{ justifyContent: "space-between" }}>
                    <Typography component={"h4"}>Add Symbol </Typography>
                    <Button onClick={handleClose} className="closeModal">
                        <img src={close} />
                    </Button>
                </Box>
                <DialogContent sx={{ padding: "0" }} className="modalBody">
                    <DialogContentText sx={{ padding: "0" }}>
                        <Box className="tabs">
                            <Box className="formBox" sx={{ border: "none !important" }}>
                                <Box className="formItems">
                                    <Box className="selectionDiv brokerDrp">

                                        <Box
                                            className="dropdown-ap"
                                            sx={{
                                                "& > div.MuiAutocomplete-root > div.MuiFormControl-root > div.MuiInputBase-root":
                                                    { padding: "0 !important", border: "none !important" },
                                            }}
                                        >
                                            <Autocomplete
                                                freeSolo
                                                className="dropdown"
                                                inputValue={search}
                                                onChange={(e, newValue) => {
                                                    setSearch(newValue?.tradingsymbol)
                                                    setPayload(newValue)
                                                }}
                                                onInputChange={(e, value, reason) => {

                                                    setSearch(value)
                                                }}
                                                options={watchlistData}
                                                getOptionLabel={(option) =>
                                                    option.tradingsymbol
                                                }
                                                renderInput={(params, option) => {
                                                    return (
                                                        <>
                                                            <Box className="MultieValue">
                                                                <TextField
                                                                    placeholder="Scrip"
                                                                    value={search}
                                                                    {...params}
                                                                    sx={{
                                                                        "&.MuiFormControl-root > .MuiInputBase-root > input.MuiInputBase-input":
                                                                        {
                                                                            border: "none !important",
                                                                        },
                                                                    }}
                                                                />
                                                            </Box>
                                                        </>
                                                    );
                                                }}
                                                filterOptions={(propsData, option) => {
                                                    return watchlistData
                                                }}
                                                renderOption={(propsData, option) => {
                                                    return (
                                                        <Box {...propsData} className="searchSelect">
                                                            <Box className="exchangeName">
                                                                <Typography component="h4">
                                                                    {option.tradingsymbol}
                                                                </Typography>
                                                                <Typography component="p">
                                                                    {option.symbol}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    );
                                                }}
                                                sx={{
                                                    border: "none !important ",
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Button
                            className="refresh-btn solidButton"
                            sx={{ width: "auto !important", marginTop: 2 }}
                            onClick={() => handleAddSymbol()}
                        >
                            Add Symbol
                        </Button>
                    </DialogContentText>
                </DialogContent>
            </Dialog>

            {/* Delete Pop up */}
            <Dialog
                open={deletePop}
                onClose={() => setDeletePop(false)}
                className="commonModal squareOff"
                fullWidth
            >
                <Box className="modalHeader">
                    <Typography component={"h4"}>Delete Watchlist</Typography>
                    <Button onClick={() => setDeletePop(false)} className="closeModal">
                        <img src={close} />
                    </Button>
                </Box>

                <DialogContent sx={{ padding: "0" }} className="modalBody">
                    <DialogContentText sx={{ padding: "0" }}>
                        <Box className="alertIcons">
                            <svg
                                width="1052"
                                height="1052"
                                viewBox="0 0 1052 1052"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M558 334C558 316.3 543.7 302 526 302C508.3 302 494 316.3 494 334V590C494 607.7 508.3 622 526 622C543.7 622 558 607.7 558 590V334ZM526 750C536.609 750 546.783 745.786 554.284 738.284C561.786 730.783 566 720.609 566 710C566 699.391 561.786 689.217 554.284 681.716C546.783 674.214 536.609 670 526 670C515.391 670 505.217 674.214 497.716 681.716C490.214 689.217 486 699.391 486 710C486 720.609 490.214 730.783 497.716 738.284C505.217 745.786 515.391 750 526 750Z"
                                    fill="#4987FE"
                                />
                                <circle
                                    cx="526"
                                    cy="526"
                                    r="507"
                                    stroke="#5086EE"
                                    stroke-width="38"
                                />
                            </svg>
                        </Box>
                        <Typography
                            component={"h4"}
                            sx={{
                                fontSize: "1.8rem",
                                textAlign: "center",
                                marginTop: "2rem",
                                marginBottom: "2rem",
                            }}
                            className="alertText"
                        >
                            Are You Sure You Want to Delete Selected Symbols !
                        </Typography>

                        <Box sx={{ display: { xs: "flex" } }}>
                            <Button
                                onClick={() => setDeletePop(false)}
                                className="modal-btn btn-danger"
                                sx={{ marginRight: "0.5rem" }}
                            >
                                Discard
                            </Button>
                            <Button
                                onClick={() => deleteWatchListData()}
                                className="modal-btn btn-primary"
                                sx={{ marginLeft: "0.5rem" }}
                            >
                                Confirm
                            </Button>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>

            {/* buy/sell popup */}
            <Dialog
                open={bsopen}
                onClose={buysellClose}
                className={`commonModal buysellModal ${sell}`}
            >
                <DialogContent sx={{ padding: "0 !important" }} className="modalBody">
                    <DialogContentText sx={{ padding: "0" }}>
                        <Box className="buysell-box" sx={{ backgroundColor: sell }}>
                            <Box className="buysell_header">
                                <Typography component={"h4"} className="symbol">
                                    {buysell?.toUpperCase() + " " + selectedStock?.tradingsymbol}
                                    <Typography component={"small"} className="symbol_type">
                                        {selectedStock?.exchange}
                                    </Typography>
                                </Typography>
                                <Switch onChange={buysellpopup} />
                            </Box>
                            <Box display={"flex"} gap={1}>
                                <Typography
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                    className={
                                        Math.sign(selectedStock?.PC) === 1 ? "sym " : "sym "
                                    }
                                >
                                    {selectedStock?.liveprice}{" "}
                                    {Math.sign(selectedStock?.PC) === 1 ? arrowUp : arrowDown}
                                </Typography>
                                <Typography className="sym ">
                                    {Number(selectedStock?.liveprice - selectedStock?.C).toFixed(
                                        2
                                    )}
                                </Typography>
                                <Typography className="static">
                                    ({selectedStock?.PC} %)
                                </Typography>
                            </Box>
                        </Box>
                        <Box className="tabs buysellModal-tabs">
                            <Box
                                className="tabBox"
                                sx={{
                                    border: "none !important",
                                    padding: 1,
                                    width: "auto !important",
                                }}
                            >
                                <Box
                                    className="formBox"
                                    sx={{
                                        border: "none",
                                        display: "flex",
                                        alignItems: "flex-start",
                                        flexWrap: { xs: "wrap", md: "nowrap" },
                                        marginTop: 1,
                                    }}
                                >

                                    <Box className="formItems" sx={{ padding: "0 0 0 0.8rem" }}>
                                        <Typography
                                            component={"label"}
                                            className="label"
                                            sx={{ fontSize: "1.4rem !important" }}
                                        >
                                            Segment
                                        </Typography>
                                        <Box
                                            className="inputFields space fullWidth"
                                            sx={{
                                                "& > .selectionDiv": {
                                                    padding: "0 !important",
                                                    marginTop: "0 !important",
                                                    border: "none !important",
                                                },
                                            }}
                                        >
                                            <Dropdown
                                                val={segmentList}
                                                handleSelect={handlesegment}
                                                value={segment}
                                            />
                                        </Box>
                                    </Box>
                                    <Box className="formItems" sx={{ padding: "0 0 0 0.8rem" }}>
                                        <Typography
                                            component={"label"}
                                            className="label"
                                            sx={{ fontSize: "1.4rem !important" }}
                                        >
                                            TradeType
                                        </Typography>
                                        <Box
                                            className="inputFields space fullWidth"
                                            sx={{
                                                "& > .selectionDiv": {
                                                    padding: "0 !important",
                                                    marginTop: "0 !important",
                                                    border: "none !important",
                                                },
                                            }}
                                        >
                                            <Dropdown
                                                // val={
                                                //     broker === "sharekhan" ? sharekhanDrpValue : drpValue
                                                // }
                                                val={drpValue3}
                                                handleSelect={handletrading}
                                                value={trading}
                                            />
                                        </Box>
                                    </Box>

                                    {broker === "sharekhan" && (
                                        <Box className="formItems" sx={{ padding: "0 0 0 0.8rem" }}>
                                            <Typography
                                                component={"label"}
                                                className="label"
                                                sx={{ fontSize: "1.4rem !important" }}
                                            >
                                                Instrument
                                            </Typography>
                                            <Box
                                                className="inputFields space fullWidth"
                                                sx={{
                                                    "& > .selectionDiv": {
                                                        padding: "0 !important",
                                                        marginTop: "0 !important",
                                                        border: "none !important",
                                                    },
                                                }}
                                            >
                                                <Box
                                                    className="selectionDiv border scroll display-none"
                                                    sx={{
                                                        display: { xs: "flex" },
                                                        marginLeft: { xs: "0 !important" },
                                                        padding: "1rem",
                                                    }}
                                                >
                                                    <Typography component={"p"}>
                                                        Instrument &nbsp;
                                                    </Typography>
                                                    <FormControl className="dropdown-ap">
                                                        <Select
                                                            className="dropdown"
                                                            value={instType}
                                                            onChange={handleInstrument}
                                                            renderValue={
                                                                instType !== ""
                                                                    ? undefined
                                                                    : () => (
                                                                        <Typography sx={{ opacity: 0.5 }}>
                                                                            Select
                                                                        </Typography>
                                                                    )
                                                            }
                                                        >
                                                            {Object.keys(instrumentTypes).map(
                                                                (vals, index) => (
                                                                    <MenuItem value={vals} key={index}>
                                                                        {instrumentTypes[vals]}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}

                                    {orderType !== "Market" && (
                                        <Box className="formItems" sx={{ padding: "0 0.8rem" }}>
                                            <Typography
                                                component={"label"}
                                                className="label"
                                                sx={{ fontSize: "1.4rem !important" }}
                                            >
                                                Trigger Price
                                            </Typography>
                                            <Box className="inputFields space">
                                                <InputBase
                                                    placeholder="Price"
                                                    type="number"
                                                    onChange={(e) => settTrp(Number(e.target.value))}
                                                />
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                                <Box
                                    className="formItems buysell-radio"
                                    sx={{ padding: "1rem 0 0 0.8rem" }}
                                >
                                    <Box
                                        display={"flex"}
                                        justifyContent={"space-between"}
                                        className="inputFields space fullWidth"
                                    >
                                        <RadioGroup
                                            sx={{ width: "50%" }}
                                            row
                                            value={orderType}
                                            type="number"
                                            onChange={(e) => setOrderType(e.target.value)}
                                        >
                                            <FormControlLabel
                                                value="Market"
                                                sx={{ marginRight: "1rem" }}
                                                control={<Radio />}
                                                label="Market"
                                            />
                                            <FormControlLabel
                                                value="Limit"
                                                sx={{ marginRight: "1rem" }}
                                                control={<Radio />}
                                                label="Limit"
                                            />
                                            <FormControlLabel
                                                value="SL-M"
                                                sx={{ marginRight: "1rem" }}
                                                control={<Radio />}
                                                label="SL-M"
                                            />
                                        </RadioGroup>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="buysell-footer">
                            <Box className="margin">
                                {/* <Typography component={'p'}>Margin ₹ 493.70 Charges ₹ 2.17</Typography> */}
                                {/* <Button><RefreshIcon /></Button> */}
                            </Box>

                            <Box className="footer-btn">
                                <Button disabled={load} onClick={() => createTrade()}>
                                    {buysell}
                                </Button>
                                <Button onClick={buysellClose}>Cancel</Button>
                            </Box>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
}
