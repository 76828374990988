import { Box, Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { expressoValidation } from "../../../validation/broker";
import { BrokerStatus } from "../../../redux/actions/brokerAction";

const Expresso = () => {
  const inputFields = { uid: "", appkey: "", secretkey: "" };
  const [fields, setFields] = useState(inputFields);
  const [Error, setError] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const dispatch = useDispatch();
  const inputBorder = {
    borderRadius: "5px",
    border: "1px solid #bcbcbc",
  };

  const brokerStatus = useSelector((state) => state.Broker.brokerstatus);

  useEffect(() => {
    dispatch(BrokerStatus());
  }, []);

  useEffect(() => {
    if (brokerStatus?.length > 0) {
      if (brokerStatus[0]?.appkey) {
        setFields({
          ...fields,
          appkey: brokerStatus[0]?.appkey,
          secretkey: brokerStatus[0]?.secretkey,
          uid: brokerStatus[0]?.uid,
        });
      }
    }
  }, [brokerStatus]);

  //form input
  const handleChange = (event) => {
    const keyName = event.target.name;
    const keyValue = event.target.value;
    setFields((prevState) => ({
      ...prevState,
      [keyName]: keyValue,
    }));
    if (isSubmit) {
      setError(expressoValidation({ ...fields, [keyName]: keyValue }));
    }
  };

  //dispatch data
  const handleSubmit = () => {
    setIsSubmit(true);
    setError(expressoValidation(fields));
    const error = expressoValidation(fields);
    if (Object.keys(error) == 0) {
      localStorage.setItem("AppKey", fields.appkey);
      localStorage.setItem("secretkey", fields.secretkey);
      localStorage.setItem("uid", fields.uid);
      localStorage.setItem("brokername", "expresso");

      window.location.replace(
        `https://api.myespresso.com/espressoapi/auth/login.html?api_key=${fields.appkey}`
      );
    }
  };

  return (
    <>
      <Box className="border">
        <Box>
          <Grid xs={12} lg={12}>
            <Box style={{ border: "none" }} className="formBox">
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  USER ID :
                </Typography>
                <TextField
                  placeholder="Enter User Id..."
                  name="uid"
                  className="inputFiled"
                  style={inputBorder}
                  onChange={handleChange}
                  value={fields?.uid?.toUpperCase()}
                />
                {Error ? <Box className="error">{Error.uid}</Box> : ""}
              </Box>
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  APP KEY :
                </Typography>
                <TextField
                  placeholder="Enter App Key..."
                  className="inputFiled"
                  style={inputBorder}
                  name="appkey"
                  onChange={handleChange}
                  value={fields?.appkey}
                />
                {Error ? <Box className="error">{Error.appkey}</Box> : ""}
              </Box>
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  SECRET KEY :
                </Typography>
                <TextField
                  placeholder="Enter Secret Key..."
                  className="inputFiled"
                  style={inputBorder}
                  name="secretkey"
                  onChange={handleChange}
                  value={fields?.secretkey}
                />
                {Error ? <Box className="error">{Error.secretkey}</Box> : ""}
              </Box>

              <Button
                className="btn solidButton fetchReport-btn"
                sx={{ width: "100% !important" }}
                onClick={handleSubmit}
              >
                Login
              </Button>
            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Expresso;
