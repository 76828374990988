import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Tooltip,
  Typography,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import Table from "../../../Table/Table";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Tableaction from "../../../Tableaction/Tableaction";
import { useDispatch, useSelector } from "react-redux";
import {
  createSymbolToken,
  deleteSymbolToken,
  editSymbolToken,
  getSymbolToken,
} from "../../../../redux/actions/authActions";
import close from "../../../../images/close.png";
import { generatePath } from "react-router-dom";
import { generatePopup } from "../../../../utils/popup";

export default function Symboltoken() {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [fields, setFields] = useState();
  const [id, setId] = useState("");
  const [editable, setEditable] = useState();
  const [state, setState] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSymbolToken());
  }, []);

  const data = useSelector((state) => state?.Auth?.symbolToken);

  const add = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      Add Data
    </Typography>
  );

  const edit = <Typography sx={{ fontSize: "1.4rem" }}>Edit</Typography>;
  const save = <Typography sx={{ fontSize: "1.4rem" }}>Save</Typography>;
  const del = <Typography sx={{ fontSize: "1.4rem" }}>Delete</Typography>;

  const col = ["id", "Symbol Name", "Token", "Actions"];
  // const rows = [
  //   {
  //     id: 1,
  //     name: "NIFTY50",
  //     deleted: 26000,
  //     action: <Tableaction />,
  //   },
  // ];
  const handleEditChange = (e) => {
    const { name, value } = e?.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEdit = () => {
    dispatch(
      editSymbolToken({
        id: state?.id,
        symbol: state.symbol,
        token: state.token,
      })
    ).then((res) => {
      if (res) {
        setState({});
        setEditable(false);
        dispatch(getSymbolToken());
      }
    });
  };

  const rows = data?.map((e) => {
    return {
      id: e?.id,
      symbol:
        editable === e?.id ? (
          <TextField
            size="small"
            name="symbol"
            value={state?.symbol}
            sx={{
              border: "1px solid gray",
              borderRadius: "5px",
            }}
            inputProps={{ style: { fontSize: "small" } }}
            onChange={handleEditChange}
          ></TextField>
        ) : (
          e?.symbol
        ),
      token:
        editable === e?.id ? (
          <TextField
            size="small"
            name="token"
            value={state?.token}
            sx={{
              border: "1px solid gray",
              borderRadius: "5px",
            }}
            inputProps={{ style: { fontSize: "small" } }}
            onChange={handleEditChange}
          ></TextField>
        ) : (
          e?.token
        ),
      action: (
        <Box className="tableActions">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="actionButton"
          >
            {editable !== e?.id && (
              <Tooltip
                title={edit}
                onClick={() => {
                  setEditable(e?.id);
                  setState(e);
                }}
                arrow
                placement="top"
              >
                <Button className="edit" variant="text">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.26 3.59997L5.04997 12.29C4.73997 12.62 4.43997 13.27 4.37997 13.72L4.00997 16.96C3.87997 18.13 4.71997 18.93 5.87997 18.73L9.09997 18.18C9.54997 18.1 10.18 17.77 10.49 17.43L18.7 8.73997C20.12 7.23997 20.76 5.52997 18.55 3.43997C16.35 1.36997 14.68 2.09997 13.26 3.59997Z"
                      stroke="CurrentColor"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.89 5.05005C12.32 7.81005 14.56 9.92005 17.34 10.2"
                      stroke="CurrentColor"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3 22H21"
                      stroke="CurrentColor"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Button>
              </Tooltip>
            )}
            {editable === e?.id && (
              <Tooltip onClick={handleEdit} title={save} arrow placement="top">
                <Button className="save" variant="text">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.8199 2H7.17995C5.04995 2 3.31995 3.74 3.31995 5.86V19.95C3.31995 21.75 4.60995 22.51 6.18995 21.64L11.0699 18.93C11.5899 18.64 12.4299 18.64 12.9399 18.93L17.8199 21.64C19.3999 22.52 20.6899 21.76 20.6899 19.95V5.86C20.6799 3.74 18.9499 2 16.8199 2Z"
                      stroke="CurrentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Button>
              </Tooltip>
            )}
            <Tooltip title={del} arrow placement="top">
              <Button
                onClick={() => {
                  setDeleteOpen(true);
                  setId(e?.id);
                }}
                className="del"
                variant="text"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"
                    stroke="CurrentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                    stroke="CurrentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.85 9.14001L18.2 19.21C18.09 20.78 18 22 15.21 22H8.79002C6.00002 22 5.91002 20.78 5.80002 19.21L5.15002 9.14001"
                    stroke="CurrentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.33 16.5H13.66"
                    stroke="CurrentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.5 12.5H14.5"
                    stroke="CurrentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Button>
            </Tooltip>
          </Box>
        </Box>
      ),
    };
  });

  const handleInputChange = (event) => {
    const keyName = event.target.name;
    const keyValue = event.target.value;
    setFields((prevState) => ({
      ...prevState,
      [keyName]: keyValue,
    }));
  };

  const handleCloseDelete = () => {
    setDeleteOpen(false);
  };

  const handleDelete = () => {
    dispatch(deleteSymbolToken({ id })).then((res) => {
      if (res) {
        setDeleteOpen(false);
        dispatch(getSymbolToken());
      }
    });
  };

  const handleCreate = () => {
    const obj = {
      symbol: fields?.symbol,
      token: fields?.token,
    };
    dispatch(createSymbolToken(obj)).then((res) => {
      setFields({ symbol: "", token: "" });
      if (res?.status === 200) {
        generatePopup("success", res?.data?.message);
        dispatch(getSymbolToken());
      }
    });
  };

  return (
    <>
      <Box className="tabelBox">
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12}>
            <Box className="selectiondiv-box m-rb">
              <Box className="selectionDiv searchFlex">
                <Box className="inputFields space" sx={{ margin: "0" }}>
                  <TextField
                    placeholder="Enter symbol name "
                    name="symbol"
                    value={fields?.symbol}
                    onChange={handleInputChange}
                  />
                </Box>
              </Box>
              <Box className="selectionDiv searchFlex">
                <Box className="inputFields space" sx={{ margin: "0" }}>
                  <TextField
                    placeholder="Enter Token"
                    name="token"
                    value={fields?.token}
                    onChange={handleInputChange}
                  />
                </Box>
                <Tooltip title={add} arrow>
                  <Button
                    onClick={handleCreate}
                    className="download-btn solidButton"
                    sx={{ marginLeft: "1rem" }}
                  >
                    <AddCircleIcon />
                  </Button>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Table col={col} rows={rows} />
      </Box>

      {/* delete symbol model */}
      <Dialog
        open={deleteOpen}
        onClose={handleCloseDelete}
        className="commonModal squareOff"
      >
        <Box className="modalHeader" sx={{ justifyContent: "end" }}>
          <Typography component={"h4"}>Delete Symbol Token</Typography>
          <Button onClick={handleCloseDelete} className="closeModal">
            <img src={close} />
          </Button>
        </Box>
        <DialogContent sx={{ padding: "0" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="alertIcons">
              <svg
                width="1052"
                height="1052"
                viewBox="0 0 1052 1052"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M558 334C558 316.3 543.7 302 526 302C508.3 302 494 316.3 494 334V590C494 607.7 508.3 622 526 622C543.7 622 558 607.7 558 590V334ZM526 750C536.609 750 546.783 745.786 554.284 738.284C561.786 730.783 566 720.609 566 710C566 699.391 561.786 689.217 554.284 681.716C546.783 674.214 536.609 670 526 670C515.391 670 505.217 674.214 497.716 681.716C490.214 689.217 486 699.391 486 710C486 720.609 490.214 730.783 497.716 738.284C505.217 745.786 515.391 750 526 750Z"
                  fill="#4987FE"
                />
                <circle
                  cx="526"
                  cy="526"
                  r="507"
                  stroke="#5086EE"
                  stroke-width="38"
                />
              </svg>
            </Box>
            <Typography
              component={"h4"}
              sx={{
                fontSize: "1.8rem",
                textAlign: "center",
                marginTop: "2rem",
              }}
              className="alertText"
            >
              Are You Sure You Want to Delete Symbol Token !
            </Typography>

            <Box sx={{ display: { xs: "flex" }, marginTop: 2 }}>
              <Button
                onClick={handleCloseDelete}
                className="modal-btn btn-danger"
                sx={{ marginRight: "0.5rem" }}
              >
                Discard
              </Button>
              <Button
                onClick={handleDelete}
                className="modal-btn btn-primary"
                sx={{ marginLeft: "0.5rem" }}
              >
                Confirm
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
