import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import "../../Dashboard/Userview/Userview.scss";
import Tablesearch from "../../../Tablesearch/Tablesearch";
import Dropdown from "../../../Dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  editsubbrokerdetails,
  getSubBrokerDetails,
  searchSubBrokerDetails,
} from "../../../../redux/actions/brokerAction";
import {
  getReferCode,
  getUserVendor,
} from "../../../../redux/actions/authActions";
import { getSubBrokerdetails } from "../../../../redux/actions/adminActions";

export default function Vendorviewform() {
  const [hidden, sethidden] = React.useState("none");
  const [brokerValue, setBrokerValue] = React.useState("Select Type");
  const [refrecodeValue, setRefrecodeValue] = React.useState("");
  const [refrecode, setRefrecode] = useState([]);
  const [subBrokerList, setSubBrokerList] = React.useState("none");
  const [data, setData] = React.useState("none");
  const [owners, setOwners] = React.useState([]);
  const [editable, setEditable] = React.useState(false);
  const searchBroker = useSelector((state) => {
    return state?.Broker?.searchSubBrokerData?.[0];
  });
  // console.log("searchBroker :>> ", searchBroker);
  const [formData, setFormData] = useState({
    Firstname: searchBroker?.firstname,
    lastName: searchBroker?.lastname,
    Email: searchBroker?.email,
    Pan: searchBroker?.pan_number,
    GST: searchBroker?.gstin,
    IFSC: searchBroker?.ifsccode,
    actype: searchBroker?.accounttype,
    acno: searchBroker?.banknumber,
    Bank: searchBroker?.bankname,
    broker: searchBroker?.broker,
    refercode: searchBroker?.refercode,
    referBy: searchBroker?.referby,
    days: searchBroker?.paper_days,

    // ... and so on for other fields
  });

  useEffect(() => {
    if (searchBroker) {
      setFormData({
        Firstname: searchBroker?.firstname,
        lastName: searchBroker?.lastname,
        Email: searchBroker?.email,
        Pan: searchBroker?.pan_number,
        GST: searchBroker?.gstin,
        IFSC: searchBroker?.ifsccode,
        actype: searchBroker?.accounttype,
        acno: searchBroker?.banknumber,
        Bank: searchBroker?.bankname,
        refercode: searchBroker?.refercode,
        days: searchBroker?.paper_days,
      });
    }
  }, [searchBroker]);

  // console.log("formData :>> ", formData);
  const drpValue = ["Select Type", "Reffer code", "Mobile"];
  const dispatch = useDispatch();
  const edit = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      Edit{" "}
    </Typography>
  );
  const search = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      search{" "}
    </Typography>
  );

  useEffect(() => {
    dispatch(getSubBrokerDetails());
  }, []);

  const subBrokers = useSelector((state) => {
    return state.Broker.getSubBrokerData;
  });
  const userData = useSelector((state) => {
    return state.Auth.authProfile;
  });

  useEffect(() => {
    setData(searchBroker);
  }, [searchBroker]);

  useEffect(() => {
    if (userData?.[0]?.is_powerbroker) {
      const obj = {
        refrecode: userData?.[0]?.username,
      };
      dispatch(getUserVendor(obj));
    } else {
      dispatch(getReferCode());
    }
    setRefrecodeValue(userData?.[0]?.username?.toLowerCase() || "");
  }, [userData]);

  const refrecodes = useSelector((state) => state?.Auth?.refreCodes?.refrecode);
  const userVendors = useSelector((state) => state?.Auth?.userVendors);

  useEffect(() => {
    if (userData?.[0]?.is_powerbroker) {
      setRefrecode(userVendors);
    } else if (userData[0]?.is_staff === true) {
      setRefrecode(refrecodes);
    } else {
      setRefrecode([userData[0]?.refrecode]);
    }
  }, [refrecodes, userData]);

  useEffect(() => {
    setSubBrokerList(subBrokers);
  }, [subBrokers]);

  // console.log("subBrokers", subBrokers);

  const submitForm = () => {
    // sethidden("flex");
    const obj = {
      refercode: formData?.refercode,
      firstname: formData?.Firstname,
      lastname: formData?.lastName,
      pan_number: formData?.Pan,
      gstin: formData?.GST,
      ifsccode: formData?.IFSC,
      accounttype: formData?.actype,
      banknumber: formData?.acno,
      bankname: formData?.Bank,
      broker: formData?.broker,
      referBy: formData?.referBy,
      days: formData?.days,
    };
    dispatch(editsubbrokerdetails(obj));
  };

  const handleSelectBroker = (e, value) => {
    setBrokerValue(value);
    dispatch(
      searchSubBrokerDetails({
        vendorcode: value,
      })
    ).then((res) => {
      // console.log("res", res);
    });
  };

  const handleSearch = (e) => {
    setBrokerValue(e?.target?.value);
  };

  const handleSearchvendor = () => {
    dispatch(searchSubBrokerDetails({ vendorcode: refrecodeValue }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (refrecodes && refrecodes !== "none") {
      setOwners(refrecodes?.refrecode);
    }
  }, [refrecodes]);

  return (
    <>
      <Box className="userview">
        <Box className="userviewActions">
          <Box className="selectiondiv-box" sx={{ marginTop: "0 !important" }}>
            {/* <Dropdown
              val={drpValue}
              handleSelect={(e) => setBrokerValue(e?.target?.value)}
              value={brokerValue}
            /> */}

            {/* <Box className="formBox">
              <Box className="formItems">
                <Box className="selectionBox searchFlex">
                  <Box className="dropdown-ap">
                    <Autocomplete
                      value={brokerValue}
                      onInputChange={handleSearch}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ width: "20rem" }}
                          placeholder="Select Broker"
                        />
                      )}
                      onChange={handleSelectBroker}
                      options={owners ? owners : []}
                      getOptionLabel={(option) => option}
                      sx={{
                        "& > .MuiFormControl-root > .MuiInputBase-root": {
                          padding: "0 !important",
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box> */}

            <Box
              className="selectiondiv-box"
              sx={{
                flexWrap: "nowrap !important",
                marginTop: "0 !important",
                marginLeft: 1,
              }}
            >
              <Dropdown
                title={"Select Refrecodes"}
                val={refrecode}
                handleSelect={(e) => setRefrecodeValue(e?.target?.value)}
                value={refrecodeValue}
              />
              {/* <Box className="inputFields">
                <TextField
                  placeholder="Search ..."
                  fullWidth
                  onChange={() => {}}
                  // sx={{
                  //   "& > .MuiInputBase-root > .MuiInputBase-input": {
                  //     maxWidth: "100% !important",
                  //   },
                  // }}
                />
                <Box className="searchIcon">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.85722 14.7144C11.6444 14.7144 14.7144 11.6444 14.7144 7.85722C14.7144 4.07008 11.6444 1 7.85722 1C4.07008 1 1 4.07008 1 7.85722C1 11.6444 4.07008 14.7144 7.85722 14.7144Z"
                      stroke="#6A6D78"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M13 13.0001L17 17.0002"
                      stroke="#6A6D78"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Box>
              </Box> */}
              <Tooltip title={search} arrow>
                <Button
                  className="download-btn solidButton"
                  sx={{ marginLeft: 1 }}
                  onClick={handleSearchvendor}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                      fill="#FFFFFF"
                    />
                  </svg>
                </Button>
              </Tooltip>

              {/* <Tooltip title={edit} arrow sx={{ display: hidden, marginLeft: 1 }}>
                <Button className='download-btn solidButton'>
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"> <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" fill='white' /></svg>
                </Button>
              </Tooltip> */}
              {/* <Tooltip title={search} arrow>
                <Button
                  className="download-btn solidButton"
                  sx={{ marginLeft: 1 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                      fill="#FFFFFF"
                    />
                  </svg>
                </Button>
              </Tooltip> */}
            </Box>
          </Box>
        </Box>

        <Grid container spacing={2} sx={{ marginTop: 1.2 }}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Firstname
              </Typography>
              <TextField
                name="Firstname"
                className={!editable && "disabled-inputField"}
                disabled={!editable}
                value={formData.Firstname}
                // defaultValue={data?.firstname}
                onChange={handleInputChange}
                type="text"
                placeholder="Enter Name"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                lastName
              </Typography>
              <TextField
                name="lastName"
                className={!editable && "disabled-inputField"}
                disabled={!editable}
                value={formData?.lastName}
                onChange={handleInputChange}
                type="text"
                placeholder="Enter Name"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Mobile
              </Typography>
              <TextField
                disabled
                className="disabled-inputField"
                value={data?.phone}
                type="text"
                placeholder="Enter Mobile"
              />
            </Box>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Customer ID{" "}
              </Typography>
              <TextField type="text" placeholder="Enter Customer ID " />
            </Box>
          </Grid> */}
          {/* <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Type
              </Typography>
              <TextField type="text" placeholder="Enter Type" />
            </Box>
          </Grid> */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Email
              </Typography>
              <TextField
                name="Email"
                className={!editable && "disabled-inputField"}
                disabled={!editable}
                value={formData?.Email}
                onChange={handleInputChange}
                type="text"
                placeholder="Enter Email"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Pan
              </Typography>
              <TextField
                name="Pan"
                className={!editable && "disabled-inputField"}
                disabled={!editable}
                value={formData?.Pan}
                onChange={handleInputChange}
                type="text"
                placeholder="Enter Pan"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Broker
              </Typography>
              <TextField
                name="broker"
                className={!editable && "disabled-inputField"}
                disabled={!editable}
                value={formData?.broker}
                onChange={handleInputChange}
                type="text"
                placeholder="Enter broker name"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                GST
              </Typography>
              <TextField
                name="GST"
                className={!editable && "disabled-inputField"}
                disabled={!editable}
                value={formData?.GST}
                onChange={handleInputChange}
                type="text"
                placeholder="Enter GST"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                IFSC
              </Typography>
              <TextField
                name="IFSC"
                className={!editable && "disabled-inputField"}
                disabled={!editable}
                value={formData?.IFSC}
                onChange={handleInputChange}
                type="text"
                placeholder="Enter IFSC"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                A/C Type
              </Typography>
              <TextField
                name="actype"
                className={!editable && "disabled-inputField"}
                disabled={!editable}
                value={formData?.actype}
                onChange={handleInputChange}
                type="text"
                placeholder="A/C Type"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                A/C No.
              </Typography>
              <TextField
                name="acno"
                className={!editable && "disabled-inputField"}
                disabled={!editable}
                value={formData?.acno}
                onChange={handleInputChange}
                type="text"
                placeholder="Enter A/C No"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Bank
              </Typography>
              <TextField
                name="Bank"
                className={!editable && "disabled-inputField"}
                disabled={!editable}
                value={formData?.Bank}
                onChange={handleInputChange}
                type="text"
                placeholder="Enter Bank"
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Refferal Code
              </Typography>
              <TextField
                className={"disabled-inputField"}
                value={data?.refercode}
                type="text"
                disabled={true}
                placeholder=""
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Refer By
              </Typography>
              <TextField
                className={"disabled-inputField"}
                value={formData?.referBy}
                type="text"
                disabled={true}
                placeholder="referBy"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography component={"label"} className="label">
                Paper Days
              </Typography>
              <TextField
                name="days"
                className={!editable && "disabled-inputField"}
                disabled={!editable}
                value={formData?.days}
                onChange={handleInputChange}
                type="text"
                placeholder="Enter Paper Days"
              />
            </Box>
          </Grid>

          {/* <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box className="inputFields space fullWidth">
              <Typography
                value={data?.bankname}
                component={"label"}
                className="label"
              >
                Created By
              </Typography>
              <TextField type="text" placeholder="Created By" />
            </Box>
          </Grid> */}
        </Grid>
        <Box
          className="formBox"
          sx={{ marginTop: "2rem", border: "none !important" }}
        >
          <Button
            onClick={() => {
              setEditable(true);
            }}
            className="solidButton download-btn"
            sx={{ width: "auto !important", marginRight: 1 }}
          >
            Edit
          </Button>
          <Button className="formSolid-btn" onClick={submitForm}>
            Submit
          </Button>
          {/* <Button
            className="solidButton squareOff-btn"
            sx={{ width: "auto !important" }}
          >
            Delete
          </Button> */}
        </Box>
      </Box>
    </>
  );
}
