import * as actionTypes from "../actionTypes";
import axios from "axios";
import { API_URL, PAPER_API_URL } from "../../config";
import { generatePopup } from "../../utils/popup";

export const userUpdateAdmin = (payload) => (dispatch) => {
  return new Promise((reslove, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.ADMIN_UPDATE_USER_DETAILS_INIT,
    });
    axios
      .put(`${API_URL}/subbroker/userupdateview/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.ADMIN_UPDATE_USER_DETAILS_SUCCESS,
            payload: res?.data,
          });
          generatePopup("success", "Profile update succesfully.");
          reslove(res);
        } else {
          dispatch({
            type: actionTypes.ADMIN_UPDATE_USER_DETAILS_FAIL,
            payload: res?.data?.message || "Failed to update profile!",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.ADMIN_UPDATE_USER_DETAILS_FAIL,
            payload: error?.response?.data?.message,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const createUserUpdateAdmin = (payload) => (dispatch) => {
  return new Promise((reslove, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.CREATE_ADMIN_UPDATE_USER_DETAILS_INIT,
    });
    axios
      .post(`${API_URL}/subbroker/userupdateview/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res?.data?.message) {
            generatePopup("error", res?.data?.message);
          }
          dispatch({
            type: actionTypes.CREATE_ADMIN_UPDATE_USER_DETAILS_SUCCESS,
            payload: res?.data,
          });
          reslove(res);
        } else {
          dispatch({
            type: actionTypes.CREATE_ADMIN_UPDATE_USER_DETAILS_FAIL,
            payload: res?.data?.message || "Failed to get user data!",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.CREATE_ADMIN_UPDATE_USER_DETAILS_FAIL,
            payload: error?.response?.data?.message,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const adminTradeDetails = (payload) => (dispatch) => {
  return new Promise((reslove, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.ADMIN_TRADE_DETAILS_INIT,
    });
    axios
      .post(`${API_URL}/position/admintradedetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("res :>> ", res);
        if (res.status === 200) {
          dispatch({
            type: actionTypes.ADMIN_TRADE_DETAILS_SUCCESS,
            payload: res?.data,
          });
          reslove(res);
        } else {
          dispatch({
            type: actionTypes.ADMIN_TRADE_DETAILS_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.ADMIN_TRADE_DETAILS_FAIL,
          });
        }
      });
  });
};

export const adminOrderDetails = (payload) => (dispatch) => {
  return new Promise((reslove, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.ADMIN_ORDER_DETAILS_INIT,
    });
    axios
      .post(`${API_URL}/position/adminorderdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.ADMIN_ORDER_DETAILS_SUCCESS,
            payload: res?.data,
          });
          reslove(res);
        } else {
          dispatch({
            type: actionTypes.ADMIN_ORDER_DETAILS_FAIL,
            payload: "Data Not Found",
          });
          generatePopup("error", "Failed to get order details.");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.ADMIN_ORDER_DETAILS_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const getVendorRefferal = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("authToken");

    axios
      .get(`${API_URL}/subbroker/editmrp/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_ADMIN_VENDOR_REFFERAL_SUCCESS,
            payload: res.data,
          });

          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_ADMIN_VENDOR_REFFERAL_FAIL,
            payload: res?.data?.message || "Failed to get vendor refferaldata!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_ADMIN_VENDOR_REFFERAL_FAIL,
          payload:
            typeof error == "string"
              ? error
              : "Failed to get vendor refferaldata",
        });

        if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const getDashboardCount = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("authToken");

    axios
      .post(`${API_URL}/subbroker/dashboardcount/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_DASHBOARD_COUNT_SUCCESS,
            payload: res.data,
          });

          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_DASHBOARD_COUNT_FAIL,
            payload: res?.data?.message || "Failed to get vendor refferaldata!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_DASHBOARD_COUNT_FAIL,
          payload:
            typeof error == "string"
              ? error
              : "Failed to get vendor refferaldata",
        });

        if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const getTodayCount = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("authToken");

    axios
      .get(`${API_URL}/subbroker/todaydashboardcount/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_TODAY_COUNT_SUCCESS,
            payload: res.data,
          });

          resolve(res);
        } else {
          dispatch({
            type: actionTypes.GET_TODAY_COUNT_FAIL,
            payload: res?.data?.message || "Failed to get Today's count!",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_TODAY_COUNT_FAIL,
          payload:
            typeof error == "string"
              ? error
              : "Failed to get vendor refferaldata",
        });

        if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const emptyUserView = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.EMPTY_USER_VIEW,
    });
  });
};

export const adminPaperOrderDetails = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.ADMIN_PAPER_ORDER_DETAILS_INIT,
    });
    axios
      .post(`${PAPER_API_URL}/position/adminpaperorderdetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.ADMIN_PAPER_ORDER_DETAILS_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.ADMIN_PAPER_ORDER_DETAILS_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.ADMIN_PAPER_ORDER_DETAILS_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const adminPaperTradeDetails = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.ADMIN_PAPER_TRADE_DETAILS_INIT,
    });
    axios
      .post(`${API_URL}/position/adminpapertradedetails/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.ADMIN_PAPER_TRADE_DETAILS_SUCCESS,
            payload: res?.data,
          });
          resolve(res);
        } else {
          dispatch({
            type: actionTypes.ADMIN_PAPER_TRADE_DETAILS_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.ADMIN_PAPER_TRADE_DETAILS_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
        }
      });
  });
};

export const addcopytrade = (payload) => (dispatch) => {
  return new Promise((reslove, reject) => {
    let token = localStorage.getItem("authToken");
    dispatch({
      type: actionTypes.ADD_COPYTRADE_INIT,
    });
    axios
      .post(`${API_URL}/position/copytradeplace/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.ADD_COPYTRADE_SUCCESS,
            payload: res?.data,
          });
          generatePopup("success", res?.data?.message);
          reslove(res);
        } else {
          dispatch({
            type: actionTypes.ADD_COPYTRADE_FAIL,
            payload: "Data Not Found",
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.ADD_COPYTRADE_FAIL,
          });
        }
      });
  });
};
