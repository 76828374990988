import { Box, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useEffect } from "react";

const Alice = () => {
  // const handleSubmit = () => {
  //   window.location.replace(
  //     "https://ant.aliceblueonline.com/?appcode=MtxTBoRCAyxTjPg"
  //   );
  // };

  useEffect(() => {
    window.location.replace(
      "https://ant.aliceblueonline.com/?appcode=MtxTBoRCAyxTjPg"
    );
  }, []);
  return (
    <>
      <Box className="border-ap" sx={{ marginTop: 2 }}>
        {/* <Button
          className="btn solidButton fetchReport-btn"
          sx={{ width: "100% !important" }}
          // onClick={handleSubmit}
        >
          Login
        </Button> */}
      </Box>
    </>
  );
};

export default Alice;
