import { Box, Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  expressoValidation,
  rmoneyValidation,
} from "../../../validation/broker";
import {
  BrokerStatus,
  expressoBroker,
  rmoneyBroker,
} from "../../../redux/actions/brokerAction";

const RmoneyBroker = () => {
  const inputFields = { appkey: "", secretkey: "" };
  const [fields, setFields] = useState(inputFields);
  const [Error, setError] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputBorder = {
    borderRadius: "5px",
    border: "1px solid #bcbcbc",
  };

  const brokerStatus = useSelector((state) => state.Broker.brokerstatus);
  useEffect(() => {
    dispatch(BrokerStatus());
  }, []);
  useEffect(() => {
    if (brokerStatus?.length > 0) {
      if (brokerStatus[0]?.appkey) {
        setFields({
          ...fields,
          appkey: brokerStatus[0]?.appkey,
          secretkey: brokerStatus[0]?.secretkey,
          clientId: brokerStatus[0]?.clientId,
        });
      }
    }
  }, [brokerStatus]);

  //form input
  const handleChange = (event) => {
    const keyName = event.target.name;
    const keyValue = event.target.value;
    setFields((prevState) => ({
      ...prevState,
      [keyName]: keyValue,
    }));
    if (isSubmit) {
      setError(rmoneyValidation({ ...fields, [keyName]: keyValue }));
    }
  };

  //dispatch data
  const handleSubmit = () => {
    setIsSubmit(true);
    setError(rmoneyValidation(fields));
    const error = rmoneyValidation(fields);
    if (Object.keys(error) == 0) {
      if (Object.keys(error) == 0) {
        dispatch(rmoneyBroker({ ...fields, brokername: "rmoney" }, navigate));
      }
    }
  };

  return (
    <>
      <Box className="border">
        <Box>
          <Grid xs={12} lg={12}>
            <Box style={{ border: "none" }} className="formBox">
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  APP KEY :
                </Typography>
                <TextField
                  placeholder="Enter App Key..."
                  className="inputFiled"
                  style={inputBorder}
                  name="appkey"
                  onChange={handleChange}
                  value={fields?.appkey}
                />
                {Error ? <Box className="error">{Error.appkey}</Box> : ""}
              </Box>
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  SECRET KEY :
                </Typography>
                <TextField
                  placeholder="Enter Secret Key..."
                  className="inputFiled"
                  style={inputBorder}
                  name="secretkey"
                  onChange={handleChange}
                  value={fields?.secretkey}
                />
                {Error ? <Box className="error">{Error.secretkey}</Box> : ""}
              </Box>
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  CLIENT ID :
                </Typography>
                <TextField
                  placeholder="Enter Client id..."
                  className="inputFiled"
                  style={inputBorder}
                  name="secretkey"
                  onChange={handleChange}
                  value={fields?.clientId}
                />
                {Error ? <Box className="error">{Error.clientId}</Box> : ""}
              </Box>

              <Button
                className="btn solidButton fetchReport-btn"
                sx={{ width: "100% !important" }}
                onClick={handleSubmit}
              >
                Login
              </Button>
            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default RmoneyBroker;
