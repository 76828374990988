import React from 'react';
import { Box } from '@mui/material';
import termsuse from '../../../images/termuse.png'

export default function Termuse() {

  return (
    <>
      <Box sx={{ padding: 2 }}>
        <img src={termsuse} />
      </Box>
    </>
  )
}
