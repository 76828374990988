import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Autocomplete,
  Button,
  Typography,
} from "@mui/material";
import "./Brokerlogin.scss";
import Alice from "./Broker/Alice";
import Zebull from "./Broker/Zebull";
import Icici from "./Broker/Icici";
import Zerodha from "./Broker/Zerodha";
import Angel from "./Broker/Angel";
import Finvasia from "./Broker/Finvasia";
import Kotak from "./Broker/Kotak";
import Paisa from "./Broker/Paisa";
import MotilalOswal from "./Broker/MotilalOswal";
import Swastika from "./Broker/Swastika";
import Fyers from "./Broker/Fyers";
import Nuvama from "./Broker/nuvama";
import Dhan from "./Broker/dhan";
import Goodwill from "./Broker/goodwill";
import Expresso from "./Broker/expresso";
import Mandot from "./Broker/mandot";
import ProfitMart from "./Broker/profitMart";
import Upstox from "./Broker/upstox";
import {
  BrokerStatus,
  aliceLogin,
  dhanBroker,
  expressoBroker,
  fivePaiseBroker,
  flattradeBroker,
  fyersBroker,
  getLogoUrls,
  iciciLogin,
  motilalOswalBroker,
  nuvamaBroker,
  sharekhanBroker,
  subBrokerLogin,
  swastikaBroker,
  upstoxLogin,
  zerodhaLogin,
} from "../../redux/actions/brokerAction";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Sharekhan from "./Broker/Sharekhan";
import Flattrade from "./Broker/Flattrade";
import RmoneyBroker from "./Broker/RmoneyBroker";

export default function Brokerlogin(props) {
  const [brokerValue, setBrokerValue] = useState("");
  const [optionsKey, setOptionsKey] = useState(0);
  let [searchParams, setSearchParams] = useSearchParams();
  let [permissions, setPermissions] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const brokerStatus = useSelector((state) => state?.Broker?.brokerstatus);
  const brokerList = useSelector((state) => state?.Broker?.brokersList) || [];

  useEffect(() => {
    dispatch(BrokerStatus());
    dispatch(getLogoUrls());
  }, []);

  const getUrl = (name) => {
    const broker = brokerList?.find((e) => e?.name === name);
    return broker?.url;
  };

  let broker = [
    {
      allow: permissions?.is_swastika,
      img: getUrl("SWASTIKA"),
      name: "Swastika",
      id: 1,
      capName: "SWASTIKA",
    },
    {
      allow: permissions?.is_angelone,
      img: getUrl("ANGELONE"),
      name: "Angel One",
      id: 2,
      capName: "ANGEL ONE",
    },
    {
      allow: permissions?.is_zerodha,
      img: getUrl("ZERODHA"),
      name: "Zerodha",
      id: 3,
      capName: "ZERODHA",
    },
    {
      allow: permissions?.is_nuvama,
      img: getUrl("NUVAMA"),
      name: "nuvama",
      id: 4,
      capName: "NUVAMA",
    },
    {
      allow: permissions?.is_dhan,
      img: getUrl("DHAN"),
      name: "dhan",
      id: 5,
      capName: "DHAN",
    },
    {
      allow: permissions?.is_icici,
      img: getUrl("ICICI DIRECT"),
      name: "Icici",
      id: 6,
      capName: "ICICI",
    },
    {
      allow: permissions?.is_finvesia,
      img: getUrl("FINVASIA"),
      name: "Finvasia",
      id: 7,
      capName: "FINVASIA",
    },
    {
      allow: permissions?.is_goodwill,
      img: getUrl("GOODWILL"),
      name: "goodwill",
      id: 8,
      capName: "GOODWILL",
    },
    {
      allow: permissions?.is_alice,
      img: getUrl("ALICEBLUE"),
      name: "Alice Blue",
      id: 9,
      capName: "ALICE BLUE",
    },
    {
      allow: permissions?.is_zebull,
      img: getUrl("ZEBULL"),
      name: "Zebull",
      id: 10,
      capName: "ZEBULL",
    },
    {
      allow: permissions?.is_expresso,
      img: getUrl("ESPRESSO"),
      name: "expresso",
      id: 11,
      capName: "EXPRESSO",
    },
    {
      allow: permissions?.is_5paisa,
      img: getUrl("5 PAISA"),
      name: "5Paisa",
      id: 12,
      capName: "5PAISA",
    },
    {
      allow: permissions?.is_fyres,
      img: getUrl("FYERS"),
      name: "fyres",
      id: 13,
      capName: "FYERS",
    },
    {
      allow: permissions?.is_sharekhan,
      img: getUrl("'SHAREKHAN"),
      name: "Sharekhan",
      id: 14,
      capName: "SHAREKHAN",
    },
    {
      allow: permissions?.is_flattrade,
      img: getUrl("FLATTRADE"),
      name: "Flattrade",
      id: 15,
      capName: "FLATTRADE",
    },
    {
      allow: permissions?.is_motilal,
      img: getUrl("MOTILAL"),
      name: "Motilal",
      id: 16,
      capName: "MOTILAL",
    },
    {
      allow: permissions?.is_rmoney,
      img: getUrl("RMONEY"),
      name: "r money",
      id: 17,
      capName: "R MONEY",
    },
    {
      allow: permissions?.is_kotak,
      img: getUrl("KOTAK"),
      name: "Kotak",
      id: 18,
      capName: "KOTAK",
    },
    {
      allow: permissions?.is_profitmart,
      img: getUrl("PROFITMART"),
      name: "profitMart",
      id: 19,
      capName: "PROFITMART",
    },
    {
      allow: permissions?.is_upstox,
      img: getUrl("UPSTOX"),
      name: "upstox",
      id: 20,
      capName: "UPSTOX",
    },
    {
      allow: permissions?.is_mandot,
      img: getUrl("MANDOT"),
      name: "mandot",
      id: 21,
      capName: "MANDOT",
    },
    // {
    //   img: broker13,
    //   name: "Nuvama",
    //   id: 12,
    // },
    // {
    //   img: broker14,
    //   name: "Raghunandan Money",
    //   id: 14,
    // },
    // {
    //   img: broker15,
    //   name: "Shoonay",
    //   id: 15,
    // },
    // {
    //   img: broker16,
    //   name: "Swastika",
    //   id: 16,
    // },
    // {
    //   img: broker17,
    //   name: "zebull",
    //   id: 17,
    // },
    // {
    //   img: broker18,
    //   name: "Zerodha",
    //   id: 18,
    // },
  ];

  let options = broker
    .map((d) => {
      if (d?.allow === true && d?.capName) {
        return d?.capName;
      }
    })
    .filter((value) => value && value);

  //broker value
  const handleClick = (value) => {
    if (value) {
      setBrokerValue(value);
      setOptionsKey((prevKey) => prevKey + 1);
    }
  };

  const handleSelectbroker = (event, value) => {
    setBrokerValue(value);
  };

  const handleSearch = (event, value) => {
    setBrokerValue(event?.target?.value);
  };

  const userData = useSelector((state) => state?.Auth?.authProfile?.[0]);

  useEffect(() => {
    let apisession = searchParams.get("apisession");
    let RequestToken = searchParams.get("RequestToken");
    const appkey = localStorage.getItem("AppKey");
    const authCode = searchParams.get("authCode");
    const userId = searchParams.get("userId");
    let session = searchParams.get("request_token");
    const secretkey = localStorage.getItem("secretkey");
    const brokername = localStorage.getItem("brokername");
    const uid = localStorage.getItem("uid");
    const request_id = searchParams.get("request_id");
    const broker = localStorage.getItem("broker");
    const swastikaSess = searchParams.get("requestParam");
    const dhanId = searchParams.get("tokenId");
    const motilalToken = searchParams.get("authtoken");
    const flattradetkn = searchParams.get("code");
    const fyersSession = searchParams.get("auth_code");
    if (apisession && brokername === "icici") {
      let obj = {
        appkey: appkey,
        session: apisession,
        secretkey: secretkey,
        brokername: "icici",
      };
      dispatch(iciciLogin(obj, navigate));
    }

    if (swastikaSess) {
      let obj = {
        session: swastikaSess.replace(/ /g, "+"),
        brokername: "swastika",
      };
      dispatch(swastikaBroker(obj, navigate));
    }

    if (dhanId) {
      let obj = {
        token: dhanId,
        brokername: "dhan",
      };
      dispatch(dhanBroker(obj, navigate));
    }

    if (fyersSession && uid) {
      let obj = {
        brokername: "fyres",
        uid: uid,
        session: fyersSession,
      };
      dispatch(fyersBroker(obj, navigate));
    }

    if (session && appkey && secretkey && brokername === "sharekhan") {
      let obj = {
        appkey: appkey,
        secretkey: secretkey,
        uid: uid,
        session: session.replace(/ /g, "+"),
        brokername: "sharekhan",
      };
      dispatch(sharekhanBroker(obj, navigate));
    }

    if (flattradetkn && appkey && secretkey && brokername === "flattrade") {
      let obj = {
        appkey: appkey,
        secretkey: secretkey,
        token: flattradetkn,
        brokername: "flattrade",
      };
      dispatch(flattradeBroker(obj, navigate));
    }

    if (flattradetkn && appkey && secretkey && brokername === "upstox") {
      let obj = {
        appkey: appkey,
        secretkey: secretkey,
        session: flattradetkn,
        brokername: "upstox",
      };
      dispatch(upstoxLogin(obj, navigate));
    }

    if (RequestToken && uid) {
      let obj = {
        uid: uid,
        session: RequestToken,
        brokername: "5paisa",
      };
      dispatch(fivePaiseBroker(obj, navigate));
    }

    if (motilalToken && uid && appkey) {
      let obj = {
        uid: uid,
        token: motilalToken,
        brokername: "motilal",
        appkey: appkey,
      };
      dispatch(motilalOswalBroker(obj, navigate));
    }

    if (request_id && appkey && secretkey) {
      let obj = {
        appkey: appkey,
        secretkey: secretkey,
        token: request_id,
        brokername: "nuvama",
      };
      dispatch(nuvamaBroker(obj, navigate));
    }

    if (authCode && userId) {
      let obj = {
        authcode: authCode,
        uid: userId,
        brokername: "aliceblue",
      };
      dispatch(aliceLogin(obj, navigate));
    }
    if (session && appkey && secretkey && brokername === "zerodha") {
      let obj = {
        appkey: appkey,
        secretkey: secretkey,
        session: session,
        uid: uid,
        brokername: "zerodha",
      };
      dispatch(zerodhaLogin(obj, navigate));
    }

    if (session && appkey && secretkey && brokername === "expresso") {
      let obj = {
        appkey: appkey,
        secretkey: secretkey,
        session: session,
        uid: uid,
        brokername: "expresso",
      };
      dispatch(expressoBroker(obj, navigate));
    }
    if (session && appkey && secretkey && brokername === "mandot") {
      let obj = {
        appkey: appkey,
        secretkey: secretkey,
        session: session,
        uid: uid,
        brokername: "mandot",
      };
      dispatch(expressoBroker(obj, navigate));
    }
  }, [window, searchParams]);

  useEffect(() => {
    if (userData?.refrecode) {
      const obj = {
        refrecode: userData?.refrecode,
      };
      dispatch(subBrokerLogin(obj)).then((res) => {
        if (res?.data) {
          setPermissions(res?.data?.[0]);
        }
      });
    }
  }, [userData]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={5} xl={3}>
          <Box className="formBox" sx={{ border: "none !important" }}>
            <Box className="formItems">
              <Box className="selectionBox brokerDrp">
                <Typography component={"label"} className="label">
                  Associate Brokers
                </Typography>
                <Box className="dropdown-ap">
                  <Autocomplete
                    onInputChange={handleSearch}
                    placeholder="Select Broker"
                    key={optionsKey}
                    value={brokerValue ? brokerValue?.toUpperCase() : ""}
                    inputValue={brokerValue ? brokerValue?.toUpperCase() : ""}
                    className="dropdown"
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Broker" />
                    )}
                    options={
                      brokerStatus[0]?.status === true
                        ? [brokerStatus[0]?.brokername.toUpperCase()]
                        : options
                    }
                    onChange={handleSelectbroker}
                    getOptionLabel={(option) => option}
                    sx={{
                      "& > .MuiFormControl-root > .MuiInputBase-root": {
                        padding: "0.5rem !important",
                      },
                    }}
                  />
                </Box>
              </Box>

              <Box xs={10}>
                <Box>
                  {brokerValue === "Alice Blue" && (
                    <Box>
                      <Alice broker={brokerValue}></Alice>
                    </Box>
                  )}
                  {brokerValue?.toLowerCase() === "zebull" && (
                    <Box>
                      <Zebull broker={brokerValue} type="new"></Zebull>
                    </Box>
                  )}
                  {brokerValue === "Icici" && (
                    <Box>
                      <Icici broker={brokerValue} type="new"></Icici>
                    </Box>
                  )}
                  {brokerValue?.toLowerCase() === "zerodha" && (
                    <Box>
                      <Zerodha broker={brokerValue}></Zerodha>
                    </Box>
                  )}
                  {brokerValue === "Angel One" && (
                    <Box>
                      <Angel broker={brokerValue}></Angel>
                    </Box>
                  )}

                  {brokerValue === "Finvasia" && (
                    <Box>
                      <Finvasia broker={brokerValue}></Finvasia>
                    </Box>
                  )}
                  {brokerValue === "Kotak" && (
                    <Box>
                      <Kotak broker={brokerValue}></Kotak>
                    </Box>
                  )}

                  {brokerValue === "5Paisa" && (
                    <Box>
                      <Paisa broker={brokerValue}></Paisa>
                    </Box>
                  )}
                  {brokerValue === "Motilal" && (
                    <Box>
                      <MotilalOswal broker={brokerValue}></MotilalOswal>
                    </Box>
                  )}
                  {brokerValue === "Swastika" && (
                    <Box>
                      <Swastika broker={brokerValue}></Swastika>
                    </Box>
                  )}
                  {brokerValue === "fyres" && (
                    <Box>
                      <Fyers broker={brokerValue}></Fyers>
                    </Box>
                  )}
                  {brokerValue === "nuvama" && (
                    <Box>
                      <Nuvama broker={brokerValue}></Nuvama>
                    </Box>
                  )}
                  {brokerValue === "dhan" && (
                    <Box>
                      <Dhan broker={brokerValue}></Dhan>
                    </Box>
                  )}
                  {brokerValue === "Sharekhan" && (
                    <Box>
                      <Sharekhan broker={brokerValue}></Sharekhan>
                    </Box>
                  )}
                  {brokerValue === "Flattrade" && (
                    <Box>
                      <Flattrade broker={brokerValue}></Flattrade>
                    </Box>
                  )}
                  {brokerValue === "goodwill" && (
                    <Box>
                      <Goodwill broker={brokerValue}></Goodwill>
                    </Box>
                  )}
                  {brokerValue === "expresso" && (
                    <Box>
                      <Expresso broker={brokerValue}></Expresso>
                    </Box>
                  )}
                  {brokerValue === "mandot" && (
                    <Box>
                      <Mandot broker={brokerValue}></Mandot>
                    </Box>
                  )}
                  {brokerValue === "r money" && (
                    <Box>
                      <RmoneyBroker broker={brokerValue}></RmoneyBroker>
                    </Box>
                  )}
                  {brokerValue === "profitMart" && (
                    <Box>
                      <ProfitMart broker={brokerValue}></ProfitMart>
                    </Box>
                  )}
                  {brokerValue === "upstox" && (
                    <Box>
                      <Upstox broker={brokerValue}></Upstox>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ marginTop: "1.5rem" }}>
          <Box className="selectBroker">
            {/* <Typography className="allTitles">Associate Brokers</Typography> */}
            <Box
              sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
            >
              {brokerStatus[0]?.status === true ? (
                <>
                  {broker.map((brokers, index) => {
                    if (
                      brokerStatus[0]?.brokername ===
                      brokers.name.toLowerCase().replace(/ +/g, "")
                    ) {
                      return (
                        <Button
                          className="brokerList"
                          key={index}
                          onClick={() => handleClick(brokers.name)}
                        >
                          <img src={brokers.img} alt="broker" />
                          <Typography
                            component={"p"}
                            className="brokerDestials"
                          >
                            {brokers.name}
                          </Typography>
                        </Button>
                      );
                    }
                  })}{" "}
                </>
              ) : (
                <>
                  {brokerValue !== null && brokerValue !== ""
                    ? broker.map((brokers, index) => {
                        if (
                          brokerValue?.toLowerCase() ==
                          brokers.name.toLowerCase()
                        ) {
                          return (
                            <Button
                              className="brokerList"
                              key={index}
                              onClick={() => handleClick(brokers.name)}
                            >
                              <img src={brokers.img} />
                              <Typography
                                component={"p"}
                                className="brokerDestials"
                              >
                                {brokers.name}
                              </Typography>
                            </Button>
                          );
                        }
                      })
                    : broker.map((brokers, index) => {
                        return (
                          <>
                            {brokers?.allow && (
                              <Button
                                className="brokerList"
                                key={index}
                                onClick={() => handleClick(brokers.name)}
                              >
                                <img src={brokers.img} />
                                <Typography
                                  component={"p"}
                                  className="brokerDestials"
                                >
                                  {brokers.name}
                                </Typography>
                              </Button>
                            )}
                          </>
                        );
                      })}
                </>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
