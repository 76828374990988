import React, { useState } from 'react';
import { Box, Tab, Button, Grid, Dialog, DialogContent, FormControlLabel, Radio, RadioGroup, TextField, FormControl, Select, MenuItem, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import './Strategybuilder.scss';
import close from '../../images/close.png';
import Autoselect from './Autoselect';

const drpVal = [
  { name: 'jkhkjhjkk52', price: 2000.03 },
  { name: 'jhjdfskdjk56', price: 1458 },
  { name: 'pokjs453', price: 500.03 },
  { name: 'uhadf8745', price: 2000.03 },
  { name: 'cdrsrtdf33', price: 486.23 },
]

export default function CreateStretagy() {
  const [open, setOpen] = React.useState(false);
  const [order, setorder] = React.useState(' ');
  const ordertype = ['exchange', 'NSE', 'NFO', 'MCX', 'CDS'];
  const [selectedOption, setSelectedOption] = useState(' ');
  const [mcxradio, setmcxradio] = useState(' ');
  const [cdsradio, setcdsradio] = useState(' ');


  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handlemcxradioChange = (event) => {
    setmcxradio(event.target.value);
  };
  const handlecdsradioChange = (event) => {
    setcdsradio(event.target.value);
  };

  // admin tab  
  const [nfocallput, setnfocallput] = React.useState('nse');

  const handleChangenfocallput = (e, newValue = 'string') => {
    setnfocallput(newValue);
  };

  const ordertypedrp = (event) => {
    setorder(event.target.value);
  };

  // Add Symbol Modal
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const GetSegement = (ordertype) => {
    switch (ordertype) {
      case ' ':
        return (
          <>
            <Box className='bgsb'>
              <Typography component={'label'} className='label'>Select Exchange</Typography>
              <Autoselect drpVal={drpVal} />
            </Box>
          </>
        )
      case 1:
        return (
          <Box className='bgsb'>
            <Typography component={'label'} className='label'>Select NSE</Typography>
            <Autoselect drpVal={drpVal} />
          </Box>
        )
      case 2:
        return (
          <Box className='inputFields space fullWidth bgsb'>
            <FormControl sx={{ display: 'block', marginLeft: 1 }}>
              <RadioGroup row defaultValue="IndexFuture" value={selectedOption} onChange={handleRadioChange} >
                <FormControlLabel value="IndexFuture" sx={{ marginRight: '1.5rem' }} control={<Radio />} label="Index Future" />
                <FormControlLabel value="IndexOption" sx={{ marginRight: '1.5rem' }} control={<Radio />} label="Index Option" />
                <FormControlLabel value="StockFuture" sx={{ marginRight: '1.5rem' }} control={<Radio />} label="Stock Future" />
                <FormControlLabel value="StockOption" sx={{ marginRight: '1.5rem' }} control={<Radio />} label="Stock Option" />
              </RadioGroup>
            </FormControl>

            {selectedOption === 'IndexFuture' &&
              <>
                <Box sx={{ marginTop: 2 }}>
                  <Autoselect drpVal={drpVal} />
                </Box>
              </>
            }
            {selectedOption === 'IndexOption' &&
              <>
                <Box className='tabs' sx={{ marginTop: 1 }}>
                  <TabContext value={nfocallput}>
                    <TabList className='main-tab' onChange={handleChangenfocallput}>
                      <Tab label="Call" value={"admintab1"} />
                      <Tab label="Put" value={"admintab2"} />
                    </TabList>
                    <Box className='tabBox' border={'none !important'} sx={{ marginTop: '0 !important' }}>
                      <TabPanel value={"admintab1"} sx={{ padding: 0 }}>
                        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                          <Box sx={{ display: 'block', maxWidth: '26rem', width: '26rem' }}>
                            <Typography component={'label'} className='label'>Expiry</Typography>
                            <Autoselect drpVal={drpVal} />
                          </Box>
                          <Box sx={{ display: 'block', maxWidth: '26rem', width: '26rem' }}>
                            <Typography component={'label'} className='label'>Strike</Typography>
                            <Autoselect drpVal={drpVal} />
                          </Box>
                        </Box>
                      </TabPanel>
                      <TabPanel value={"admintab2"} sx={{ padding: 0 }}>
                        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                          <Box sx={{ display: 'block', maxWidth: '26rem', width: '26rem' }}>
                            <Typography component={'label'} className='label'>Expiry</Typography>
                            <Autoselect drpVal={drpVal} />
                          </Box>
                          <Box sx={{ display: 'block', maxWidth: '26rem', width: '26rem' }}>
                            <Typography component={'label'} className='label'>Strike</Typography>
                            <Autoselect drpVal={drpVal} />
                          </Box>
                        </Box>
                      </TabPanel>
                    </Box>
                  </TabContext>
                </Box>
              </>
            }
            {selectedOption === 'StockFuture' &&
              <>
                <Box sx={{ marginTop: 2 }}>
                  <Autoselect drpVal={drpVal} />
                </Box>
              </>
            }
            {selectedOption === 'StockOption' &&
              <>
                <Box sx={{ marginTop: 2 }}>
                  <Autoselect drpVal={drpVal} />
                </Box>
              </>
            }
          </Box >
        )
      case 3:
        return (
          <Box className='inputFields space fullWidth bgsb'>
            <FormControl sx={{ display: 'block', marginLeft: 1 }}>
              <RadioGroup row defaultValue="Commodityfuture" value={mcxradio} onChange={handlemcxradioChange}>
                <FormControlLabel value="Commodityfuture" sx={{ marginRight: '1.5rem' }} control={<Radio />} label="Commodity future" />
                <FormControlLabel value="CommodityOption" sx={{ marginRight: '1.5rem' }} control={<Radio />} label="Commodity Option" />
              </RadioGroup>
            </FormControl>
            {mcxradio === 'Commodityfuture' &&
              <>
                <Box sx={{ marginTop: 2 }}>
                  <Autoselect drpVal={drpVal} />
                </Box>
              </>
            }
            {mcxradio === 'CommodityOption' &&
              <>
                <Box className='tabs' sx={{ marginTop: 1 }}>
                  <TabContext value={nfocallput}>
                    <TabList className='main-tab' onChange={handleChangenfocallput}>
                      <Tab label="Call" value={"admintab1"} />
                      <Tab label="Put" value={"admintab2"} />
                    </TabList>
                    <Box className='tabBox' border={'none !important'} sx={{ marginTop: '0 !important' }}>
                      <TabPanel value={"admintab1"} sx={{ padding: 0 }}>
                        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                          <Box sx={{ display: 'block', maxWidth: '26rem', width: '26rem' }}>
                            <Typography component={'label'} className='label'>Expiry</Typography>
                            <Autoselect drpVal={drpVal} />
                          </Box>
                          <Box sx={{ display: 'block', maxWidth: '26rem', width: '26rem' }}>
                            <Typography component={'label'} className='label'>Strike</Typography>
                            <Autoselect drpVal={drpVal} />
                          </Box>
                        </Box>
                      </TabPanel>
                      <TabPanel value={"admintab2"} sx={{ padding: 0 }}>
                        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                          <Box sx={{ display: 'block', maxWidth: '26rem', width: '26rem' }}>
                            <Typography component={'label'} className='label'>Expiry</Typography>
                            <Autoselect drpVal={drpVal} />
                          </Box>
                          <Box sx={{ display: 'block', maxWidth: '26rem', width: '26rem' }}>
                            <Typography component={'label'} className='label'>Strike</Typography>
                            <Autoselect drpVal={drpVal} />
                          </Box>
                        </Box>
                      </TabPanel>
                    </Box>
                  </TabContext>
                </Box>
              </>
            }
          </Box>
        )
      case 4:
        return (
          <Box className='inputFields space fullWidth bgsb'>
            <FormControl sx={{ display: 'block', marginLeft: 2 }}>
              <RadioGroup row defaultValue="CurrencyFuture" value={cdsradio} onChange={handlecdsradioChange} >
                <FormControlLabel value="CurrencyFuture" sx={{ marginRight: '1.5rem' }} control={<Radio />} label="Currency Future" />
                <FormControlLabel value="Currencyoption" sx={{ marginRight: '1.5rem' }} control={<Radio />} label="Currency option" />
              </RadioGroup>
            </FormControl>

            {cdsradio === 'CurrencyFuture' &&
              <>
                <Box sx={{ marginTop: 2 }}>
                  <Autoselect drpVal={drpVal} />
                </Box>
              </>
            }
            {cdsradio === 'Currencyoption' &&
              <>
                <Box className='tabs' sx={{ marginTop: 1 }}>
                  <TabContext value={nfocallput}>
                    <TabList className='main-tab' onChange={handleChangenfocallput}>
                      <Tab label="Call" value={"admintab1"} />
                      <Tab label="Put" value={"admintab2"} />
                    </TabList>
                    <Box className='tabBox' border={'none !important'} sx={{ marginTop: '0 !important' }}>
                      <TabPanel value={"admintab1"} sx={{ padding: 0 }}>
                        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                          <Box sx={{ display: 'block', maxWidth: '26rem', width: '26rem' }}>
                            <Typography component={'label'} className='label'>Expiry</Typography>
                            <Autoselect drpVal={drpVal} />
                          </Box>
                          <Box sx={{ display: 'block', maxWidth: '26rem', width: '26rem' }}>
                            <Typography component={'label'} className='label'>Strike</Typography>
                            <Autoselect drpVal={drpVal} />
                          </Box>
                        </Box>
                      </TabPanel>
                      <TabPanel value={"admintab2"} sx={{ padding: 0 }}>
                        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                          <Box sx={{ display: 'block', maxWidth: '26rem', width: '26rem' }}>
                            <Typography component={'label'} className='label'>Expiry</Typography>
                            <Autoselect drpVal={drpVal} />
                          </Box>
                          <Box sx={{ display: 'block', maxWidth: '26rem', width: '26rem' }}>
                            <Typography component={'label'} className='label'>Strike</Typography>
                            <Autoselect drpVal={drpVal} />
                          </Box>
                        </Box>
                      </TabPanel>
                    </Box>
                  </TabContext>
                </Box>
              </>
            }
          </Box>
        )
    }
  };

  return (
    <>
      <Box className='strategyBox_builder'>
        {/* Create A New Srategy Data */}
        <Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
              <Box className='createStrategy'>
                <Button className='createStrategy-btn' onClick={handleClickOpen}>
                  <AddIcon />
                  <Typography component={'p'}>Create</Typography>
                  <Typography component={'span'}>A New Srategy</Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4} xl={3}>
            <Box className='bgsb'>
              <Box className='formBox'>
                <Box className='formItems'>
                  <Typography component={'label'} className='label'>Select Exchange</Typography>
                  <Box className='selectionDiv' >
                    <FormControl className='dropdown-ap' sx={{ width: '100%' }}>
                      <Select value={order} onChange={ordertypedrp} className='dropdown'>
                        {ordertype.map((vals, index) => (
                          <MenuItem value={index === 0 ? ' ' : index} key={index}>{vals}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4} xl={4}>
            {GetSegement(order)}
          </Grid>
          <Grid item xs={12}>
            {/* <Box className='created_strategy'>
              <Box className='created_strategy_card'>
                <Button className='cancel_strategy'><CloseIcon /></Button>
                <Typography component={'h6'}>Banknifty02132</Typography>
                <Typography component={'h4'}>BN</Typography>
                <Typography component={'h5'}>32546.05</Typography>
                <Button className='formSolid-btn'>Edit Strategy</Button>
              </Box>
            </Box> */}
          </Grid>
        </Grid>
      </Box>

      {/* Create A New Srategy */}
      < Dialog open={open} onClose={handleClose} className='commonModal createStrategy' >
        <Box className='modalHeader' sx={{ justifyContent: 'space-between' }}>
          <Typography component={'h5'}>Create New Strategy</Typography>
          <Button onClick={handleClose} className='closeModal'><img src={close} /></Button>
        </Box>
        <DialogContent className='modalBody'>
          <Box className='inputFields space space-y fullWidth' >
            <Typography component={'label'} className='label'>Strategy code</Typography>
            <TextField type='number' placeholder="Trigger Price" />
          </Box>
          <Box className='inputFields space space-y fullWidth' >
            <Typography component={'label'} className='label'>Strategy Display Name</Typography>
            <TextField type='text' placeholder="Strategy Display Name" />
          </Box>
          <Box className='inputFields space space-y fullWidth' >
            <Typography component={'label'} className='label'>Strategy Display Full Name</Typography>
            <TextField type='text' placeholder="Strategy Display Full Name" />
          </Box>
          <Button className='formSolid-btn' sx={{ marginTop: '2rem !important' }}>Save & Submit</Button>
        </DialogContent>
      </Dialog >
    </>
  )
}
