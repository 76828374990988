import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import Tablesearch from "../Tablesearch/Tablesearch";
import Dropdown from "../Dropdown/Dropdown";
import Table from "../Table/Table";
import "./Scanner.scss";
import { useDispatch, useSelector } from "react-redux";
import { getSegment, signalDetails } from "../../redux/actions/positionAction";
import dayjs from "dayjs";
import moment from "moment";
import { getStratergy } from "../../redux/actions/stratergyAction";

const col = [
  "Symbol Name",
  "Trend",
  "Entry Price",
  "Stop Loss",
  "Target",
  "Signal Type",
  "Order Type",
  // "Prc Type",
  "Strategy",
  "Segment",
  "Strike Price",
  "Date & Time",
];
// const rows = [
//   {
//     date: "CRUDEOILM23519FUT",
//     day: <span className="down"> Sell</span>,
//     open: 5866,
//     high: 5732,
//     low: 5902,
//     close: <span className="down"> Square Off</span>,
//     pl: "05-15-2023, 11:20:05 PM",
//   },
//   {
//     date: "CRUDEOILM23519FUT",
//     day: <span className="up"> Buy</span>,
//     open: 5866,
//     high: 5732,
//     low: 5902,
//     close: <span className="up"> Call Active</span>,
//     pl: "05-15-2023, 11:20:05 PM",
//   },
// ];

// Dropdown
const drpValue = [
  "All",
  "Banknifty Fut",
  "Nifty Fut",
  "Banknify Option",
  "Nifty Option",
  "MCX Fut",
  "Currency Fut",
  "NSE Cash",
  "Stock Fut",
  "Banknifty Option Sell",
  "MCX Otion",
  "Finnifty Fut",
  "Finnifty Option",
];
const drpValue2 = [
  "All",
  "Orbatm",
  "D cash",
  "Demsmic",
  "Dematade",
  "Rsi option",
  "Trade",
];
const drpValue3 = ["BOTH", "BUY", "SELL"];

export default function Scanner() {
  const [segment, setSegment] = useState("All");
  const [strategy, setStrategy] = useState("All");
  const [type, setType] = useState("BOTH");
  const [search, setSearch] = useState();
  const [signaldetails, setSignaldetails] = useState([]);
  const dispatch = useDispatch();

  const signals = useSelector((state) => state.Position.signaldetails);

  // useEffect(() => {
  //   const obj = {
  //     segment: segment === "All" ? "select" : segment,
  //     strategy: strategy === "All" ? "select" : strategy,
  //     type: type,
  //   };
  //   dispatch(signalDetails(obj));
  // }, []);

  const sockets = useSelector((state) => state?.CommonReducer?.socket);

  const commonArrayFun = (signals = signals) => {
    var sort_signals_array = signals.sort(function (a, b) {
      return new Date(b?.timestamp) - new Date(a?.timestamp);
    });
    let newArr = [];
    for (let index = 0; index < sort_signals_array.length; index++) {
      const element = sort_signals_array[index];
      newArr.push({
        symbol:
          element.symbol +
          dayjs(element?.expiry)?.format("YY-MM-DD")?.split("-").join("") +
          Math.trunc(element?.strikeprice) +
          (element?.tag !== "XX" ? element?.tag : ""),
        side: (
          <span className={element.side == "BUY" ? "up" : "down"}>
            {element.side}
          </span>
        ),
        price: element.entry,
        stoploss: element.stoploss || 0,
        target: element.target || 0,
        signalType: (
          <span className={element?.type == "Entry" ? "up" : "down"}>
            {element.type}
          </span>
        ),
        product: element.product,
        // prctyp: element.prctyp,
        stratergy: element.stratergy,
        segmemt: element?.segment,
        strikeprice: Math.floor(Number(element.strikeprice)),
        date: element.timestamp
          ? moment.parseZone(element.timestamp)?.format("DD-MM-YYYY HH:mm:ss")
          : "-",
      });
    }
    return newArr;
  };

  const handleSearch = (event) => {
    if (event.target.value.length > 0) {
      let filterData = signals.filter((item) =>
        item.symbol.toLowerCase().includes(event.target.value.toLowerCase())
      );

      let newArr = commonArrayFun(filterData);

      setSignaldetails(newArr);
    } else {
      let newArr = commonArrayFun(signals);

      setSignaldetails(newArr);
    }
  };

  useEffect(() => {
    let newArr = commonArrayFun(signals);

    setSignaldetails(newArr);
  }, [signals]);

  const handleSubmit = () => {
    dispatch(
      signalDetails({
        segment:
          segment?.toLowerCase() == "all" ? "select" : segment?.toUpperCase(),
        strategy: strategy?.toLowerCase() == "all" ? "select" : strategy,
        type,
      })
    );
  };

  useEffect(() => {
    handleSubmit();
  }, [type, strategy, segment]);

  //select segment
  const handlesegment = (event) => {
    setSegment(event.target.value);
  };
  //select strategy
  const handlestrategy = (event) => {
    setStrategy(event.target.value);
  };
  //select type
  const handleType = (event) => {
    setType(event.target.value);
  };

  //input search
  const handlesearch = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    dispatch(getSegment());
  }, []);

  useEffect(() => {
    dispatch(getStratergy());
  }, []);

  const strategies = useSelector((state) => state?.Stratergy?.stratergy);
  const strategyList = strategies?.map((e) => e?.name);

  const segmentList = useSelector((state) => state?.Position?.segmentList);

  return (
    <Box className="tabelBox">
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={12}>
          <Box className="selectiondiv-box m-rb">
            <Box className="selectionDiv searchFlex">
              {/* <Tablesearch handlesearch={handleSearch} /> */}
            </Box>
            <Dropdown
              val={["All"].concat(segmentList)}
              handleSelect={handlesegment}
              value={segment}
              title={"Select Segment"}
            />
            <Dropdown
              val={["All"]?.concat(strategyList)}
              handleSelect={handlestrategy}
              value={strategy}
              title={"Select Strategy"}
            />
            <Dropdown
              val={drpValue3}
              handleSelect={handleType}
              value={type}
              title={"Select type"}
            />
          </Box>
        </Grid>
      </Grid>

      <Table col={col} rows={signaldetails} />
    </Box>
  );
}
