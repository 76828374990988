import React from 'react';
import { Box, Grid } from '@mui/material';
import Strategy from '../Strategy/Strategy';
import './Home.scss';

export default function Home() {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box className='border-ap'>
            <Box className='strategyBox'>
              <Strategy />
            </Box>
          </Box>
        </Grid >
      </Grid >
    </>
  )
}
