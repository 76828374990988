import { Box, Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  BrokerStatus,
  finvasiaLogin,
} from "../../../redux/actions/brokerAction";
import { finvasiaValidation } from "../../../validation/broker";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const Finvasia = () => {
  const inputFields = { uid: "", password: "", totp: "" };
  const [fields, setFields] = useState(inputFields);
  const [Error, setError] = useState();
  const [seeOtp, setSeeOtp] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputBorder = {
    borderRadius: "5px",
    border: "1px solid #bcbcbc",
  };

  const brokerStatus = useSelector((state) => state.Broker.brokerstatus);
  useEffect(() => {
    dispatch(BrokerStatus());
  }, []);

  useEffect(() => {
    if (brokerStatus?.length > 0) {
      if (brokerStatus[0]?.uid) {
        setFields({
          ...fields,
          uid: brokerStatus[0].uid,
        });
      }
    }
  }, [brokerStatus]);

  //form input
  const handleChange = (event) => {
    const keyName = event.target.name;
    const keyValue = event.target.value;
    setFields((prevState) => ({
      ...prevState,
      [keyName]: keyValue,
    }));
    if (isSubmit) {
      setError(finvasiaValidation({ ...fields, [keyName]: keyValue }));
    }
  };

  //dispatch data
  const handleSubmit = () => {
    setIsSubmit(true);
    setError(finvasiaValidation(fields));
    const error = finvasiaValidation(fields);
    if (Object.keys(error) == 0) {
      dispatch(finvasiaLogin({ ...fields, brokername: "finvasia" }, navigate));
    }
  };

  return (
    <>
      <Box className="border">
        <Box>
          <Grid xs={12} lg={12}>
            <Box style={{ border: "none" }} className="formBox">
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  USER ID :
                </Typography>
                <TextField
                  placeholder="Enter User Id..."
                  name="uid"
                  className="inputFiled"
                  style={inputBorder}
                  onChange={handleChange}
                  value={fields?.uid?.toUpperCase()}
                />
                {Error ? <Box className="error">{Error.uid}</Box> : ""}
              </Box>
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  PASSWORD :
                </Typography>
                <Box className="resend_otp">
                  <TextField
                    type={seeOtp ? "text" : "password"}
                    placeholder="Enter Password.."
                    className="inputFiled"
                    style={inputBorder}
                    name="password"
                    value={fields?.password}
                    onChange={handleChange}
                  />

                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    className="actions_otp"
                  >
                    <Button
                      onClick={() => {
                        setSeeOtp(!seeOtp);
                      }}
                      className="otp_viewHide"
                    >
                      {seeOtp ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityRoundedIcon />
                      )}
                    </Button>
                  </Box>
                </Box>
                {Error ? <Box className="error">{Error.password}</Box> : ""}
              </Box>

              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  TOTP :
                </Typography>
                <TextField
                  placeholder="Enter Totp..."
                  name="totp"
                  className="inputFiled"
                  style={inputBorder}
                  onChange={handleChange}
                />
                {Error ? <Box className="error">{Error.totp}</Box> : ""}
              </Box>

              <Button
                className="btn solidButton fetchReport-btn"
                sx={{ width: "100% !important" }}
                onClick={handleSubmit}
              >
                Login
              </Button>
            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Finvasia;
