import React from 'react';
import {
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Box,
  Tooltip,
  Button
} from '@mui/material';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import CreateStretagy from './Createstretagy';

export default function Positions() {
  const [ordertype, setordertype] = React.useState('ordertype1');
  const [orderinterval, setorderinterval] = React.useState('1min');
  const [holding, setholding] = React.useState('MIS');
  const [order, setorder] = React.useState(' ');
  const sizingtype = ['Capital Based', 'Risk Based'];
  const ot1 = <Typography sx={{ fontSize: '1.4rem' }}>Candlestick</Typography>
  const ot2 = <Typography sx={{ fontSize: '1.4rem' }}>Heiki-Ashi</Typography>

  const ordertypedrp = (event) => {
    setorder(event.target.value);
  };
  const ordertypechange = (e, newAlignment) => {
    setordertype(newAlignment);
  };

  const orderintervalchange = (e, newAlignment) => {
    setorderinterval(newAlignment);
  };

  const holdingchange = (e, newAlignment) => {
    setholding(newAlignment);
  };

  // Create Stretagy
  return (
    <>
      <CreateStretagy />
      <Box className='strategy_box'>
        <Box className='order_box'>
          <Grid container spacing={2}>
            <Grid item xl={2}>
              <Box className='selectionDiv order_type_switch'>
                <Typography className='label' component={'label'}>Chart Type</Typography>
                <ToggleButtonGroup
                  value={ordertype}
                  exclusive
                  onChange={ordertypechange}
                  className='tradeType-toggle'
                >
                  <ToggleButton value="ordertype1">
                    <Tooltip title={ot1} arrow placement='top'>
                      <CandlestickChartIcon />
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton value="ordertype2">
                    <Tooltip title={ot2} arrow placement='top'>
                      <CandlestickChartIcon />
                    </Tooltip>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Grid>

            <Grid item xl={7}>
              <Box className='selectionDiv order_type_switch'>
                <Typography className='label' component={'label'}>Time Frame</Typography>
                <ToggleButtonGroup
                  value={orderinterval}
                  exclusive
                  onChange={orderintervalchange}
                  className='tradeType-toggle'
                >
                  <ToggleButton value="1min">1min</ToggleButton>
                  <ToggleButton value="2min">2min</ToggleButton>
                  <ToggleButton value="3min">3min</ToggleButton>
                  <ToggleButton value="5min">5min</ToggleButton>
                  <ToggleButton value="10min">10min</ToggleButton>
                  <ToggleButton value="15min">15min</ToggleButton>
                  <ToggleButton value="30min">30min</ToggleButton>
                  <ToggleButton value="1hour">1hour</ToggleButton>
                  <ToggleButton value="4hour">4hour</ToggleButton>
                  <ToggleButton value="day">day</ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Grid>


            <Grid item xl={3}>
              <Box className='selectionDiv order_type_switch'>
                <Typography className='label' component={'label'}>Order type</Typography>
                <ToggleButtonGroup
                  value={holding}
                  exclusive
                  onChange={holdingchange}
                  className='tradeType-toggle'
                >
                  <ToggleButton value="MIS">MIS</ToggleButton>
                  <ToggleButton value="CNC">CNC</ToggleButton>
                  <ToggleButton value="NRML">NRML</ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Grid>
          </Grid>
        </Box>



        <Typography className='title_positions' sx={{ marginTop: 4 }} component={'h4'}>Quantity</Typography>
        <Box className='order_box'>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <Box className='inputFields space fullWidth'>
                <Typography component={'label'} className='label'>Quantity ( in lots )</Typography>
                <TextField value={0} placeholder="Script" className='inputFiled' disabled fullWidth />
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box className='inputFields space fullWidth'>
                <Typography component={'label'} className='label'>Max Allocation</Typography>
                <TextField value={0} placeholder="Script" className='inputFiled' fullWidth />
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box className='inputFields space fullWidth'>
                <Typography component={'label'} className='label'>Max Quantity ( in lots )</Typography>
                <TextField value={0} placeholder="Script" className='inputFiled' fullWidth />
              </Box>
            </Grid>
            <Grid item md={3}>
              <Box className='formBox'>
                <Box className='formItems'>
                  <Typography component={'label'} className='label'>Position sizing type</Typography>
                  <Box className='selectionDiv' >
                    <FormControl className='dropdown-ap' sx={{ width: '100%' }}>
                      <Select value={order} onChange={ordertypedrp} className='dropdown'>
                        {sizingtype.map((vals, index) => (
                          <MenuItem value={index === 0 ? ' ' : index} key={index}>{vals}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box >
    </>
  )
}
