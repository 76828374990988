import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { profitMartValidation } from "../../../validation/broker";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  BrokerStatus,
  profitMartBroker,
} from "../../../redux/actions/brokerAction";

const ProfitMart = () => {
  const inputFields = { uid: "" };
  const [fields, setFields] = useState(inputFields);
  const [isSubmit, setIsSubmit] = useState(false);
  const [Error, setError] = useState();
  const [seeOtp, setSeeOtp] = useState(false);

  const inputBorder = {
    borderRadius: "5px",
    border: "1px solid #bcbcbc",
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = () => {
    setIsSubmit(true);
    setError(profitMartValidation(fields));
    const error = profitMartValidation(fields);
    if (Object.keys(error) == 0) {
      dispatch(
        profitMartBroker({ ...fields, brokername: "profitmart" }, navigate)
      );
    }
  };

  const brokerStatus = useSelector((state) => state.Broker.brokerstatus);

  useEffect(() => {
    dispatch(BrokerStatus());
  }, []);

  useEffect(() => {
    if (brokerStatus?.length > 0) {
      if (brokerStatus[0]?.appkey) {
        setFields({
          ...fields,
          appKey: brokerStatus[0]?.appkey,
          uid: brokerStatus[0]?.uid,
        });
      }
    }
  }, [brokerStatus]);

  const handleChange = (event) => {
    const keyName = event.target.name;
    const keyValue = event.target.value;
    setFields((prevState) => ({
      ...prevState,
      [keyName]: keyValue,
    }));
    if (isSubmit) {
      setError(profitMartValidation({ ...fields, [keyName]: keyValue }));
    }
  };

  return (
    <>
      <Box className="border">
        <Box style={{ border: "none" }} className="formBox">
          <Box className="formItems" sx={{ marginBottom: 1.5 }}>
            <Typography component={"label"} className="label">
              USER ID :
            </Typography>
            <TextField
              placeholder="Enter User Id..."
              name="uid"
              className="inputFiled"
              style={inputBorder}
              onChange={handleChange}
              value={fields?.uid?.toUpperCase()}
            />
            {Error ? <Box className="error">{Error.uid}</Box> : ""}
          </Box>
          <Box className="formItems" sx={{ marginBottom: 1.5 }}>
            <Typography component={"label"} className="label">
              APP KEY :
            </Typography>
            <TextField
              placeholder="Enter App Key..."
              className="inputFiled"
              style={inputBorder}
              name="appkey"
              onChange={handleChange}
              value={fields?.appkey}
            />
            {Error ? <Box className="error">{Error.appkey}</Box> : ""}
          </Box>
          <Box className="formItems" sx={{ marginBottom: 1.5 }}>
            <Typography component={"label"} className="label">
              PASSWORD :
            </Typography>
            <Box className="resend_otp">
              <TextField
                type={seeOtp ? "text" : "password"}
                placeholder="Enter Password.."
                className="inputFiled"
                style={inputBorder}
                value={fields.password}
                name="password"
                onChange={handleChange}
              />

              <Box
                sx={{ display: "flex", alignItems: "center" }}
                className="actions_otp"
              >
                <Button
                  onClick={() => {
                    setSeeOtp(!seeOtp);
                  }}
                  className="otp_viewHide"
                >
                  {seeOtp ? <VisibilityOffIcon /> : <VisibilityRoundedIcon />}
                </Button>
              </Box>
            </Box>
            {Error ? <Box className="error">{Error.password}</Box> : ""}
          </Box>
          <Box className="formItems" sx={{ marginBottom: 1.5 }}>
            <Typography component={"label"} className="label">
              TOTP :
            </Typography>
            <TextField
              placeholder="Enter Totp..."
              name="totp"
              className="inputFiled"
              style={inputBorder}
              onChange={handleChange}
              value={fields?.totp}
            />
            {Error ? <Box className="error">{Error.totp}</Box> : ""}
          </Box>

          <Button
            className="btn solidButton fetchReport-btn"
            sx={{ width: "100% !important" }}
            onClick={handleSubmit}
          >
            Login
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ProfitMart;
