import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  Typography,
  TextField,
  Button,
  Box,
} from "@mui/material";
import copy from "copy-text-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import {
  generateTradingKey,
  getProfile,
} from "../../redux/actions/authActions";

export default function Settings() {
  const [generatedKey, setGeneratedKey] = useState("");
  const dispatch = useDispatch();

  const handleGenerate = () => {
    dispatch(generateTradingKey()).then((res) => {
      if (res?.status === 200) {
        dispatch(getProfile());
      }
    });
  };

  const copyLink = () => {
    if (generatedKey) {
      copy(`${generatedKey}`);
    }
  };

  const userData = useSelector((state) => state?.Auth?.authProfile?.[0]);

  useEffect(() => {
    setGeneratedKey(userData?.tradingbridge);
  }, [userData]);

  return (
    <>
      <List className="profile-content">
        <ListItem sx={{ display: "flex !important", alignItems: "center" }}>
          <Typography component="p" sx={{ margin: "0 2rem 0 0 !important" }}>
            Trading Key :-
          </Typography>
          <Box className="formItems">
            <Button
              disabled={generatedKey ? true : false}
              onClick={handleGenerate}
              className="formSolid-btn"
              sx={{ direction: "block" }}
            >
              Generate Key
            </Button>
          </Box>
        </ListItem>
        <ListItem sx={{ display: "flex !important", alignItems: "center" }}>
          <Box className="inputFields space" sx={{ maxWidth: "20rem" }}>
            <TextField
              placeholder="Generate Key"
              value={generatedKey}
              disabled
            />
          </Box>
          <Box className="formItems">
            <Button
              onClick={copyLink}
              className="formSolid-btn"
              sx={{ direction: "block" }}
            >
              Copy Key
            </Button>
          </Box>
        </ListItem>
        <ListItem sx={{ display: "flex !important", alignItems: "center" }}>
          <Box className="formItems">
            <Button className="formSolid-btn" sx={{ direction: "block" }}>
              Click Here to copy webbook url.
            </Button>
          </Box>
        </ListItem>
      </List>
    </>
  );
}
