import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Grid, TextField } from "@mui/material";
import Dropdown from "../../../Dropdown/Dropdown";
import "../../../Scanner/Scanner.scss";
import "../../../Tablesearch/Tablesearch.scss";
import "../../../Tablesearch/Tablesearch.scss";
import { useDispatch, useSelector } from "react-redux";
import CryptoJS from "crypto-js";
import { getSegment } from "../../../../redux/actions/positionAction";
import {
  getSegmentData,
  getSignalAllData,
  getStrategies,
  getSymbolData,
} from "../../../../redux/actions/stratergyAction";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { getSymbolList } from "../../../../redux/actions/authActions";

const drpValue1 = ["Buy", "Sell"];
const drpValue2 = ["CE", "PE", "XX", "AA"];
const drpValue3 = ["Entry", "Exit"];

export default function GenerateKey() {
  const [stratergies, setStratergies] = useState("");
  const [segment, setSegment] = useState("");
  const [key, setKey] = useState("");
  const [tradeType, setTradeType] = useState("");
  const [symbol, setSymbol] = useState("");
  const [signalType, setSignalType] = useState("");
  const [trend, setTrend] = useState("");
  const [optionType, setOptionType] = useState("");
  const [makeBuysell, setMakeBuysell] = useState("#24a959");
  const [load, setLoad] = useState(false);
  const [fields, setFields] = useState("");

  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.Auth?.authProfile?.[0]);
  const cashSymbols = useSelector((state) => state?.Auth?.symbolList?.symbol);

  useEffect(() => {
    dispatch(getSegment());
    dispatch(getSymbolList({ segment: segment?.toUpperCase() }));
  }, []);

  const handleStratergy = (event) => {
    setStratergies(event.target.value);
  };

  const handleSegment = (event) => {
    setSegment(event.target.value);
  };
  const handleSymbol = (event) => {
    setSymbol(event.target.value);
  };

  const handleSignalType = (event) => {
    setSignalType(event.target.value);
  };
  const handleTrend = (event) => {
    setTrend(event.target.value);
  };
  const handleOptionType = (event) => {
    setOptionType(event.target.value);
  };
  const handleTradeType = (event) => {
    setTradeType(event.target.value);
  };

  const handleChange = (e) => {
    console.log("e", e.target.value);
    const { name, value } = e.target;
    setFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleReset = () => {
    setSignalType();
    setTrend();
    setOptionType();
    setSegment("");
    setFields({
      strikeprice: "",
    });
    setSymbol("");
    setStratergies("");
    setTradeType("");
  };

  useEffect(() => {
    dispatch(getStrategies({ owner: userData?.username }));
  }, [userData]);

  useEffect(() => {
    dispatch(
      getSegmentData({
        owner: userData?.username,
        stratergy: stratergies?.toUpperCase(),
      })
    );
  }, [stratergies]);

  useEffect(() => {
    dispatch(
      getSymbolData({
        owner: userData?.username,
        stratergy: stratergies?.toUpperCase(),
        segment: segment?.toUpperCase(),
      })
    );
  }, [stratergies, segment]);

  useEffect(() => {
    dispatch(
      getSignalAllData({
        owner: userData?.username,
        stratergy: stratergies?.toUpperCase(),
        segment: segment?.toUpperCase(),
        symbol: symbol?.toUpperCase(),
      })
    );
  }, [stratergies, segment, symbol]);

  const stratergy = useSelector(
    (state) => state?.Stratergy?.strategies?.dataset
  );
  const getsegmentdata = useSelector(
    (state) => state?.Stratergy?.segmentdata?.dataset
  );
  const getsymboldata = useSelector(
    (state) => state?.Stratergy?.symboldata?.dataset
  );
  const getsignalalldata = useSelector(
    (state) => state?.Stratergy?.singlealldata
  );

  const dateTime = dayjs().format("MM/DD/YYYY hh:mm:ss A");
  const date = dayjs().format("YYYY-MM-DD");

  const handleSend = () => {
    if (
      segment === "" ||
      symbol === "" ||
      stratergies === "" ||
      fields?.strikeprice === "" ||
      signalType === "" ||
      trend === "" ||
      optionType === "" ||
      tradeType === ""
    ) {
      toast.info("All Fields are require");
    } else {
      const payload = `${segment.toUpperCase()}|${symbol?.toUpperCase()}|0|0|0|${stratergies?.toUpperCase()}|${
        getsignalalldata?.[0]?.tardingbridge
      }|${getsignalalldata?.[0]?.stratergyode}|${
        (tradeType === "intraday" && "Intraday") ||
        (tradeType === "normal" && "Normal")
      }|${
        (signalType === "entry" && "Entry") || (signalType === "exit" && "Exit")
      }|${dateTime}|${trend?.toUpperCase()}|${
        segment === "nsecash" ? date : getsignalalldata?.[0]?.expiry
      }|${fields?.strikeprice}|${optionType?.toUpperCase()}|1|`;

      const encryptPayload = (payload) => {
        return CryptoJS.AES.encrypt(
          payload,
          process.env.REACT_APP_GENERATE_JWT_KEY
        ).toString();
      };

      const encryptedPayload = encryptPayload(payload);
      setKey(encryptedPayload);
    }
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(key)
      .then(() => {
        toast.success("Your Key Copied to Clipboard Successfully!");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <>
      <Box
        className="border-ap"
        sx={{ margin: 1, maxWidth: { xs: "100%", lg: 900 } }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            margin: 0,
            width: "100%",
            backgroundColor: makeBuysell,
            paddingBottom: 2,
          }}
          className="border-apb"
        >
          <Grid item xs={6} md={6}>
            <Typography
              sx={{ fontSize: 20, color: "#FFFFFF", fontWeight: 600 }}
            >
              Generate Key
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ marginTop: 1, paddingRight: 2 }}>
          <Grid item xs={12}>
            <Box
              className="selectiondiv-box"
              sx={{ marginTop: "0 !important" }}
            >
              <Box className="adminCtradeBox">
                <Box className="adminCtradeBoxItem">
                  <Dropdown
                    title={"Stratergy"}
                    val={stratergy}
                    handleSelect={handleStratergy}
                    value={stratergies}
                  />
                </Box>
                <Box className="adminCtradeBoxItem">
                  <Dropdown
                    title={"Segment"}
                    val={getsegmentdata}
                    handleSelect={handleSegment}
                    value={segment}
                  />
                </Box>
                <Box className="adminCtradeBoxItem">
                  <Dropdown
                    disabled={true}
                    title={"Symbol"}
                    val={segment === "nsecash" ? cashSymbols : getsymboldata}
                    handleSelect={handleSymbol}
                    value={symbol}
                  />
                </Box>
                <Box className="adminCtradeBoxItem">
                  <Box
                    className="selectiondiv-box"
                    sx={{
                      marginTop: "0 !important",
                      justifyContent: "left !important",
                    }}
                  >
                    <Box className="inputFields space fullWidth">
                      <TextField
                        name="strikeprice"
                        placeholder="strike price"
                        sx={{
                          border: "1px solid #0a53b5",
                          borderRadius: "5px",
                        }}
                        type="text"
                        value={fields?.strikeprice}
                        onChange={handleChange}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className="adminCtradeBoxItem">
                  <Dropdown
                    title={"Signal Type"}
                    val={drpValue3}
                    handleSelect={handleSignalType}
                    value={signalType}
                  />
                </Box>
                <Box className="adminCtradeBoxItem">
                  <Dropdown
                    title={"Trend"}
                    val={drpValue1}
                    handleSelect={handleTrend}
                    value={trend}
                  />
                </Box>
                <Box className="adminCtradeBoxItem">
                  <Dropdown
                    title={"Select Option type"}
                    val={drpValue2}
                    handleSelect={handleOptionType}
                    value={optionType}
                  />
                </Box>
                <Box className="adminCtradeBoxItem">
                  <Dropdown
                    title={"Trade Type"}
                    val={["Intraday", "Normal"]}
                    handleSelect={handleTradeType}
                    value={tradeType}
                  />
                </Box>
              </Box>
            </Box>

            <Grid
              container
              spacing={2}
              sx={{ margin: 0, marginBottom: 2, width: "100%" }}
            >
              {key && (
                <Grid item>
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <TextField
                      size="small"
                      sx={{
                        border: "1px solid #0a53b5",
                        borderRadius: "5px",
                        paddingY: "0 !important",
                      }}
                      type="text"
                      value={key}
                    />
                    <Button
                      onClick={handleCopy}
                      className="formSolid-btn green"
                    >
                      Copy
                    </Button>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} md={12} sx={{ marginTop: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Button
                    disabled={load}
                    onClick={handleSend}
                    className="formSolid-btn green"
                  >
                    Generate Key
                  </Button>
                  <Button
                    onClick={handleReset}
                    className="solidButton fetchReport-btn"
                    sx={{
                      width: "auto !important",
                      maxWidth: "auto !important",
                    }}
                  >
                    Reset
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
