import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Tooltip,
  Typography,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import Table from "../../../Table/Table";
import close from "../../../../images/close.png";
import { useDispatch, useSelector } from "react-redux";
import {
  brokerDelete,
  getServiceCharges,
  showBroker,
} from "../../../../redux/actions/authActions";
import LogoutIcon from "@mui/icons-material/Logout";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { generatePopup } from "../../../../utils/popup";

export default function Strategycharges() {
  const edit = <Typography sx={{ fontSize: "1.4rem" }}>Edit</Typography>;
  const save = <Typography sx={{ fontSize: "1.4rem" }}>Save</Typography>;
  const logout = <Typography sx={{ fontSize: "1.4rem" }}>Logout</Typography>;
  const del = <Typography sx={{ fontSize: "1.4rem" }}>Delete</Typography>;
  const searchTip = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      Search
    </Typography>
  );
  const [open, setOpen] = React.useState(false);
  const [report, setreport] = React.useState(" ");
  const [strategy, setstrategy] = React.useState(" ");
  const [state, setState] = React.useState({});
  const segment = [
    "Select Segment",
    "Select All",
    "Banknifty Fut",
    "Nifty Fut",
    "BANKNIFTY OPTION",
    "NIFTY OPTION",
    "MCX FUT",
    "CURRENCY FUT",
    "NSE CASH",
    "STOCK FUT",
    "BANKNIFTY OPTION SELLI...",
    "NIFTY OPTION SELLING",
    "MCX OPTION",
    "FINNIFTY FUT",
    "FINNIFTY OPTION",
  ];
  const strategyName = [
    "Select Strategy",
    "ORBATM",
    "DCASH",
    "DEMSMIC",
    "DEMATADE",
    "RSIOPTION",
    "TRADE",
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const drpchnage = (event) => {
    setreport(event.target.value);
  };

  const drpstrategy = (event) => {
    setstrategy(event.target.value);
  };

  const handleOpen = (event) => {
    setOpen(true);
  };

  const brokerDetails = useSelector((state) => state?.Auth?.userBrokerDetails);

  const col = ["broker name", "Username", "Broker ClientId", "Action"];
  const rows =
    Object.keys(brokerDetails).length > 0
      ? [
          {
            brokerName: brokerDetails?.broker,
            Username: brokerDetails?.username,
            brokerClientId: brokerDetails?.clientid,
            action: (
              <Box className="tableActions">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="actionButton"
                >
                  <Tooltip title={edit} arrow placement="top">
                    <Button className="edit" variant="text">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.26 3.59997L5.04997 12.29C4.73997 12.62 4.43997 13.27 4.37997 13.72L4.00997 16.96C3.87997 18.13 4.71997 18.93 5.87997 18.73L9.09997 18.18C9.54997 18.1 10.18 17.77 10.49 17.43L18.7 8.73997C20.12 7.23997 20.76 5.52997 18.55 3.43997C16.35 1.36997 14.68 2.09997 13.26 3.59997Z"
                          stroke="CurrentColor"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.89 5.05005C12.32 7.81005 14.56 9.92005 17.34 10.2"
                          stroke="CurrentColor"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3 22H21"
                          stroke="CurrentColor"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Button>
                  </Tooltip>

                  <Tooltip title={del} arrow placement="top">
                    <Button onClick={handleOpen} className="del" variant="text">
                      <DeleteForeverIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip title={logout} arrow placement="top">
                    <Button className="del" variant="text">
                      <LogoutIcon />
                    </Button>
                  </Tooltip>
                </Box>
              </Box>
            ),
          },
        ]
      : [];

  const [search, setSearch] = useState("");

  //input Search
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleSearchData = () => {
    const obj = {
      username: search,
    };
    dispatch(showBroker(obj));
  };

  useEffect(() => {
    const obj = {
      username: search,
    };
    dispatch(showBroker(obj));
  }, []);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.Auth?.authProfile?.[0]);

  const handleDelete = () => {
    console.log("brokerDetails", brokerDetails);
    const obj = {
      username: brokerDetails?.username,
    };
    dispatch(brokerDelete(obj)).then((res) => {
      const obj = {
        username: user?.username,
      };
      setOpen(false);
      dispatch(showBroker(obj));
      if (res?.status === 200) {
        generatePopup("success", "Broker Delete Successfully");
      }
    });
  };

  const serviceCharges = useSelector((state) => state?.Auth?.serviceCharges);

  useEffect(() => {
    setState(serviceCharges);
  }, [serviceCharges]);

  return (
    <>
      <Box className="tabelBox">
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12}>
            <Box className="selectiondiv-box m-rb">
              <Box className="selectionDiv searchFlex">
                <Box className="inputFields" sx={{ marginBottom: "0" }}>
                  <TextField
                    value={search}
                    placeholder="Search Here..."
                    onChange={(event) => handleSearch(event)}
                  />
                  <Box className="searchIcon">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.85722 14.7144C11.6444 14.7144 14.7144 11.6444 14.7144 7.85722C14.7144 4.07008 11.6444 1 7.85722 1C4.07008 1 1 4.07008 1 7.85722C1 11.6444 4.07008 14.7144 7.85722 14.7144Z"
                        stroke="#6A6D78"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13 13.0001L17 17.0002"
                        stroke="#6A6D78"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Box>
                </Box>
                <Tooltip title={searchTip} arrow>
                  <Button
                    onClick={handleSearchData}
                    className="download-btn solidButton "
                    sx={{ marginLeft: 1 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                        fill="#FFFFFF"
                      />
                    </svg>
                  </Button>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Table col={col} rows={rows} />
      </Box>

      {/* Deploy Modal */}
      <Dialog
        open={open}
        onClose={handleClose}
        className="commonModal squareOff"
        fullWidth
      >
        <Box className="modalHeader">
          <Typography component={"h4"}>Delete Broker</Typography>
          <Button onClick={handleClose} className="closeModal">
            <img alt="close" src={close} />
          </Button>
        </Box>

        <DialogContent sx={{ padding: "0" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="alertIcons">
              <svg
                width="1052"
                height="1052"
                viewBox="0 0 1052 1052"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M558 334C558 316.3 543.7 302 526 302C508.3 302 494 316.3 494 334V590C494 607.7 508.3 622 526 622C543.7 622 558 607.7 558 590V334ZM526 750C536.609 750 546.783 745.786 554.284 738.284C561.786 730.783 566 720.609 566 710C566 699.391 561.786 689.217 554.284 681.716C546.783 674.214 536.609 670 526 670C515.391 670 505.217 674.214 497.716 681.716C490.214 689.217 486 699.391 486 710C486 720.609 490.214 730.783 497.716 738.284C505.217 745.786 515.391 750 526 750Z"
                  fill="#4987FE"
                />
                <circle
                  cx="526"
                  cy="526"
                  r="507"
                  stroke="#5086EE"
                  stroke-width="38"
                />
              </svg>
            </Box>
            <Typography
              component={"h4"}
              sx={{
                fontSize: "1.8rem",
                textAlign: "center",
                marginTop: "2rem",
              }}
              className="alertText"
            >
              Are You Sure You Want to Remove Broker!
            </Typography>
            <Box sx={{ display: { xs: "flex" } }}>
              <Button
                onClick={handleClose}
                className="modal-btn btn-danger"
                sx={{ marginRight: "0.5rem" }}
              >
                Discard
              </Button>
              <Button
                onClick={handleDelete}
                className="modal-btn btn-primary"
                sx={{ marginLeft: "0.5rem" }}
              >
                Confirm
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
