import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import {
  Tooltip,
  Button,
  Typography,
  TextField,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import Table from "../../../Table/Table";
import "./CopyTradeDetails.scss";
import Dropdown from "../../../Dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  editCopyTradeDetails,
  getCopyTradeDetails,
  getSegment,
} from "../../../../redux/actions/positionAction";
import { generatePopup } from "../../../../utils/popup";
import dayjs from "dayjs";

// const rows = [
//   {
//     brokerid: "MOTILAL OSWAL",
//     clinetid: "EHYD226042",
//     customerid: 132,
//     tradetype: "Paper Trade",
//     tradetype2: "Bankniftyoption",
//     staus: 1,
//     qty: 1,
//     ll: 200,
//     pl: 1000,
//     st: 100,
//     stock: 1,
//     strategyname: "HLNATGASMINI",
//     update: "13-05-2023 16:09:48",
//   },
//   {
//     brokerid: "MOTILAL OSWAL",
//     clinetid: "EHYD226042",
//     customerid: 132,
//     tradetype: "Paper Trade",
//     tradetype2: "Bankniftyoption",
//     staus: 1,
//     qty: 1,
//     ll: 200,
//     pl: 1000,
//     st: 100,
//     stock: 1,
//     strategyname: "HLNATGASMINI",
//     update: "13-05-2023 16:09:48",
//   },
//   {
//     brokerid: "MOTILAL OSWAL",
//     clinetid: "EHYD226042",
//     customerid: 132,
//     tradetype: "Paper Trade",
//     tradetype2: "Bankniftyoption",
//     staus: 1,
//     qty: 1,
//     ll: 200,
//     pl: 1000,
//     st: 100,
//     stock: 1,
//     strategyname: "HLNATGASMINI",
//     update: "13-05-2023 16:09:48",
//   },
// ];

export default function CopyTradeDetails() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSegment());
  }, []);

  const segmentList = useSelector((state) => state?.Position?.segmentList);

  const edit = <Typography sx={{ fontSize: "1.4rem" }}>Edit</Typography>;
  const save = <Typography sx={{ fontSize: "1.4rem" }}>Save</Typography>;

  // Dropdown

  const drpValue4 = ["select", "Live", "Paper"];

  const [searchvalue, setSearchvalue] = useState("");
  const [status, setStatus] = useState("select");
  const [strategy, setStrategy] = useState("select");
  const [tradeType, setTradeType] = useState("select");
  const [segment, setSegment] = useState("select");
  const [editStatus, setEditStatus] = useState("select");
  const [editable, setEditable] = useState(false);
  const [state, setState] = useState({});
  const [qty, setQty] = useState("");
  const [searchData, setSearchData] = useState("");

  //Select status
  // const handleStatus = (event) => {
  //   setStatus(event.target.value);
  // };
  const handleSegment = (event) => {
    setSegment(event.target.value);
  };

  //Select TradeType
  const handleTradeType = (event) => {
    setTradeType(event.target.value);
  };

  useEffect(() => {
    const obj = {
      segment: segment,
      trading_type: tradeType,
      // is_active: status === "off" ? "False" : "True",
      // stratergy: strategy,
      clientid: searchvalue || "select",
    };
    dispatch(getCopyTradeDetails(obj));
  }, [segment, tradeType, status, strategy]);

  const data = useSelector((state) => state?.Position?.copyTradeDetails);

  const handleEdit = () => {
    const obj = {
      id: state?.id,
      lot: qty,
    };
    dispatch(editCopyTradeDetails(obj)).then((res) => {
      generatePopup("success", res?.data?.message);
      const obj1 = {
        segment: segment,
        trading_type: tradeType,
        // is_active: status === "off" ? "False" : "True",
        // stratergy: strategy,
        clientid: searchvalue || "select",
      };
      setEditable("");
      dispatch(getCopyTradeDetails(obj1));
    });
  };
  const col = [
    "Segment",
    "Qty",
    "Owner",
    "Trade Type",
    "Trading Type",
    "Date&Time",
    "Action",
  ];

  // const col = [
  //   "Broker Id",
  //   "Client Id",
  //   "Customer Id",
  //   "Trade Type",
  //   "Trade Type",
  //   "Trade Status",
  //   "Qty",
  //   "Loss Limit",
  //   "Profit Limit",
  //   "Set Trail",
  //   "Count Of Stock",
  //   "Strategy Name",
  //   "UpdatedAt",
  // ];

  const rows = data?.map((e) => {
    return {
      segment: e?.segment,
      lot:
        editable !== e?.id ? (
          e?.lot
        ) : (
          <Box className="inputFields space fullWidth">
            <TextField
              type="text"
              placeholder="Enter Broker ID"
              value={qty}
              onChange={(e) => {
                setQty(e?.target.value);
              }}
            />
          </Box>
        ),
      owner: e?.owner,
      tradetype: e?.tradetype,
      tradingtype: e?.tradingtype,
      date: dayjs(e?.date).format("DD-MM-YYYY hh:mm:ss A"),
      action: (
        <Box className="tableActions">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="actionButton"
          >
            {editable !== e?.id && (
              <Tooltip
                title={edit}
                onClick={() => {
                  setEditable(e?.id);
                  setState(e);
                  setEditStatus(e?.is_active ? "on" : "off");
                  setQty(e?.lot);
                }}
                arrow
                placement="top"
              >
                <Button className="edit" variant="text">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.26 3.59997L5.04997 12.29C4.73997 12.62 4.43997 13.27 4.37997 13.72L4.00997 16.96C3.87997 18.13 4.71997 18.93 5.87997 18.73L9.09997 18.18C9.54997 18.1 10.18 17.77 10.49 17.43L18.7 8.73997C20.12 7.23997 20.76 5.52997 18.55 3.43997C16.35 1.36997 14.68 2.09997 13.26 3.59997Z"
                      stroke="CurrentColor"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.89 5.05005C12.32 7.81005 14.56 9.92005 17.34 10.2"
                      stroke="CurrentColor"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3 22H21"
                      stroke="CurrentColor"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Button>
              </Tooltip>
            )}
            {editable === e?.id && (
              <Tooltip onClick={handleEdit} title={save} arrow placement="top">
                <Button className="save" variant="text">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.8199 2H7.17995C5.04995 2 3.31995 3.74 3.31995 5.86V19.95C3.31995 21.75 4.60995 22.51 6.18995 21.64L11.0699 18.93C11.5899 18.64 12.4299 18.64 12.9399 18.93L17.8199 21.64C19.3999 22.52 20.6899 21.76 20.6899 19.95V5.86C20.6799 3.74 18.9499 2 16.8199 2Z"
                      stroke="CurrentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Button>
              </Tooltip>
            )}
            {/* <Tooltip title={del} arrow placement="top">
              <Button
                onClick={() => {
                  // setDeleteOpen(true);
                  // setId(e?.id);
                }}
                className="del"
                variant="text"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"
                    stroke="CurrentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                    stroke="CurrentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.85 9.14001L18.2 19.21C18.09 20.78 18 22 15.21 22H8.79002C6.00002 22 5.91002 20.78 5.80002 19.21L5.15002 9.14001"
                    stroke="CurrentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.33 16.5H13.66"
                    stroke="CurrentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.5 12.5H14.5"
                    stroke="CurrentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Button>
            </Tooltip> */}
          </Box>
        </Box>
      ),

      // customerid: 132,
      // tradetype: "Paper Trade",
      // tradetype2: "Bankniftyoption",
      // staus: 1,
      // qty: 1,
      // ll: 200,
      // pl: 1000,
      // st: 100,
      // stock: 1,
      // strategyname: "HLNATGASMINI",
      // update: "13-05-2023 16:09:48",
    };
  });

  const handleSearch = (e) => {
    console.log("e.target :>> ", e.target);
  };

  const [filteredData, setFilteredData] = useState([]);

  const handleSearchDetail = (e) => {
    const { value } = e.target;

    setSearchData(value);
  };

  useEffect(() => {
    if (searchData.length > 0) {
      const filtered = rows.filter((item) =>
        item.owner?.toLowerCase().includes(searchData?.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(rows);
    }
  }, [searchData, rows]);

  // useEffect(() => {
  //   console.log("searchData :>> ", searchData);

  // }, [searchData]);
  // console.log("filteredData------", filteredData);

  const search = (
    <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
      search{" "}
    </Typography>
  );

  return (
    <>
      <Box className="tabelBox">
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12} lg={4}>
            {/* <Box
              className="selectiondiv-box m-rb"
              sx={{ justifyContent: "left", paddingLeft: 1 }}
            >
              <Dropdown
                val={drpValue}
                handleSelect={handleClientId}
                value={clientid}
                title={"Client ID"}
              />
              <Box className="selectionDiv searchFlex">
                <Tablesearch handlesearch={(event) => handlesearch(event)} />
                <Tooltip title={search} arrow>
                  <Button
                    onClick={handleGetData}
                    className="download-btn solidButton"
                    sx={{ margin: "0 0 0 1rem !important" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                        fill="#FFFFFF"
                      />
                    </svg>
                  </Button>
                </Tooltip>
              </Box>
            </Box> */}
          </Grid>
          <Grid item xs={12} lg={8}>
            <Box className="selectiondiv-box m-rb">
              <Box
                // sx={{ alignItems: "center" }}
                className="selectionDiv searchFlex"
              >
                <FormControl
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    // marginBottom: "1rem",
                  }}
                  variant="outlined"
                >
                  <OutlinedInput
                    id="outlined-adornment-password"
                    sx={{ border: "1px solid rgba(117, 134, 150, 0.3)" }}
                    inputProps={{
                      style: { fontSize: "14px", padding: "10px" },
                      placeholder: `enter username`,
                    }}
                    onChange={handleSearchDetail}
                    value={searchData}
                    label="Password"
                  />
                </FormControl>
                {/* <Tooltip title={search} arrow>
                  <Button
                    onClick={handleSearchDetail}
                    className="download-btn solidButton"
                    sx={{ marginLeft: 1 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                        fill="#FFFFFF"
                      />
                    </svg>
                  </Button>
                </Tooltip> */}
              </Box>
              <Dropdown
                val={["select"].concat(segmentList)}
                handleSelect={handleSegment}
                value={segment}
                title={"Select Segment"}
              />
              <Dropdown
                val={drpValue4}
                handleSelect={handleTradeType}
                value={tradeType}
                title={"Select Trade Type"}
              />
              {/* <Box className="selectionDiv">
                <Tooltip title={tradeon} arrow placement="top">
                  <Button className="autoTrade-btn on">Auto Trade ON</Button>
                </Tooltip>
                <Tooltip title={tradeoff} arrow placement="top">
                  <Button className="autoTrade-btn off">Auto Trade Off</Button>
                </Tooltip>
              </Box> */}
            </Box>
          </Grid>
        </Grid>

        <Table col={col} rows={filteredData} />
      </Box>
    </>
  );
}
