import React from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import "./Strategybuilder.scss";
import Strategybuilder_tab from "./Strategybuilder_tab";
import Createdstretagy from "./Createdstretagy";
import { useSelector } from "react-redux";

export default function Strategybuilder() {
  // admin tab
  const [value, setValue] = React.useState("admintab1");
  const handleChange = (e, newValue = "string") => {
    setValue(newValue);
  };
  const user = useSelector((state) => state?.Auth?.authProfile?.[0]);

  return (
    <Box className="tabs strategybuilder_tabs">
      <TabContext value={value}>
        <TabList className="main-tab" onChange={handleChange}>
          <Tab label={"Create A New Srategy"} value={"admintab1"} />
          {user?.is_staff && (
            <Tab label={"Discover Strategy"} value={"admintab2"} />
          )}
        </TabList>

        <Box className="tabBox" border={"none !important"}>
          <TabPanel value={"admintab1"} sx={{ padding: 0 }}>
            <Strategybuilder_tab />
          </TabPanel>
          <TabPanel value={"admintab2"} sx={{ padding: 0 }}>
            <Createdstretagy />
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
}
