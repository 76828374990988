import { Box, Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { fyersValidation } from "../../../validation/broker";
import { useDispatch, useSelector } from "react-redux";
import { BrokerStatus } from "../../../redux/actions/brokerAction";

const Fyers = () => {
  let inputFields = { uid: "" };
  const [fields, setFields] = useState(inputFields);
  const [isSubmit, setIsSubmit] = useState(false);
  const [Error, setError] = useState();
  const dispatch = useDispatch();
  const inputBorder = {
    borderRadius: "5px",
    border: "1px solid #bcbcbc",
  };

  const brokerStatus = useSelector((state) => state.Broker.brokerstatus);
  useEffect(() => {
    dispatch(BrokerStatus());
  }, []);

  useEffect(() => {
    if (brokerStatus?.length > 0) {
      if (brokerStatus[0]?.appkey) {
        setFields({
          ...fields,
          uid: brokerStatus[0]?.uid,
        });
      }
    }
  }, [brokerStatus]);

  //form input
  const handleChange = (event) => {
    let keyName = event.target.name;
    let keyValue = event.target.value;
    setFields((prevState) => ({
      ...prevState,
      [keyName]: keyValue,
    }));
    if (isSubmit) {
      setError(fyersValidation({ ...fields, [keyName]: keyValue }));
    }
  };

  //dispatch data
  const handleSubmit = () => {
    setIsSubmit(true);
    setError(fyersValidation(fields));
    let error = fyersValidation(fields);
    if (Object.keys(error) == 0) {
      localStorage.setItem("uid", fields.uid);
      let redirect = `${window.location.origin}/broker`;
      let appCode = "K0SJAH8YY8-102";
      window.location.replace(
        `https://api-t1.fyers.in/api/v3/generate-authcode?client_id=${appCode}&redirect_uri=https://trade-panel.tradearth.in/broker&response_type=code&state=sample_state`
      );

      // dispatch(fyersBroker())
      //   console.log("fields :>> ", fields);
    }
  };
  return (
    <>
      <Box className="border">
        <Box>
          <Grid xs={12} lg={12}>
            <Box className=" formBox">
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  USER ID :
                </Typography>
                <TextField
                  value={fields?.uid?.toUpperCase()}
                  placeholder="Enter User Id.."
                  className="inputFiled"
                  style={inputBorder}
                  name="uid"
                  onChange={handleChange}
                />
                {Error ? <Box className="error">{Error.uid}</Box> : ""}
              </Box>

              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  className="btn solidButton fetchReport-btn"
                  sx={{ width: "100% !important" }}
                  onClick={handleSubmit}
                >
                  Login
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Fyers;
