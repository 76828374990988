import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import "../Strategy/Strategy.scss";
import "./Strategybuilder.scss";
import { useDispatch, useSelector } from "react-redux";
import close from "../../images/close.png";
import {
  deleteUserStrategy,
  getStratergy,
} from "../../redux/actions/stratergyAction";
import dayjs from "dayjs";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export default function Createdstretagy(props) {
  const [id, setId] = useState("");
  const [open, setOpen] = useState(false);

  const displayName = {
    fontSize: "1.6rem !important",
  };
  const myStrategy = [];
  for (let i = 0; i < 10; i++) {
    myStrategy.push(
      <Grid item xs={12} sm={10} lg={6} xl={2.4}>
        <Box className="created_strategy_card">
          <Typography component={"h4"}>BNF Money Plant</Typography>
          <Typography component={"h5"}>BNF Money Plant</Typography>
          <Typography component={"h6"}>Created 11 days ago</Typography>

          <Box className="strategy_action">
            <Button className="stretagy_btn edit_strategy">
              Strategy Edit
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path
                  d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                  fill="white"
                />
              </svg>
            </Button>
          </Box>
        </Box>
      </Grid>
    );
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStratergy());
  }, []);

  const strategyList = useSelector((state) => state?.Stratergy?.stratergy);

  const handleDelete = () => {
    dispatch(deleteUserStrategy({ id: id })).then((res) => {
      if (res) {
        setOpen(false);
        if (res?.status === 200) {
          dispatch(getStratergy());
        }
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
    setId("");
  };

  return (
    <>
      <Box className="tabs">
        <Grid
          container
          spacing={2}
          sx={{ justifyContent: { xs: "center", lg: "left" } }}
        >
          {strategyList?.map((e) => (
            <Grid item xs={12} sm={10} lg={6} xl={2.4}>
              <Box className="created_strategy_card">
                <Typography component={"h4"}>{e?.full_name}</Typography>
                <Typography component={"h5"}>
                  {e?.stratergy_description}
                </Typography>

                <Box
                  sx={{ justifyContent: "center !important" }}
                  className="strategy_badges"
                >
                  {e?.tag?.map((d) => (
                    <Typography component={"p"} className="badges">
                      {d}
                    </Typography>
                  ))}
                </Box>

                <Typography component={"h6"}>
                  Created {dayjs().diff(e?.created_date, "days")} days ago by{" "}
                  {e?.owner}
                </Typography>

                <Box className="strategy_action">
                  <Button
                    onClick={() => {
                      setId(e?.id);
                      setOpen(true);
                    }}
                    className="stretagy_btn delete_strategy"
                  >
                    Strategy Delete
                    <DeleteForeverIcon />
                  </Button>
                </Box>
              </Box>
            </Grid>
          ))}

          {/* <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Button className='refresh-btn solidButton' sx={{ width: 'auto !important', fontSize: '1.2rem' }}>View More</Button>
          </Grid> */}
        </Grid>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        className="commonModal squareOff"
        fullWidth
      >
        <Box className="modalHeader">
          <Typography component={"h4"}>Delete Strategy</Typography>
          <Button onClick={handleClose} className="closeModal">
            <img src={close} />
          </Button>
        </Box>

        <DialogContent sx={{ padding: "0" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="alertIcons">
              <svg
                width="1052"
                height="1052"
                viewBox="0 0 1052 1052"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M558 334C558 316.3 543.7 302 526 302C508.3 302 494 316.3 494 334V590C494 607.7 508.3 622 526 622C543.7 622 558 607.7 558 590V334ZM526 750C536.609 750 546.783 745.786 554.284 738.284C561.786 730.783 566 720.609 566 710C566 699.391 561.786 689.217 554.284 681.716C546.783 674.214 536.609 670 526 670C515.391 670 505.217 674.214 497.716 681.716C490.214 689.217 486 699.391 486 710C486 720.609 490.214 730.783 497.716 738.284C505.217 745.786 515.391 750 526 750Z"
                  fill="#4987FE"
                />
                <circle
                  cx="526"
                  cy="526"
                  r="507"
                  stroke="#5086EE"
                  stroke-width="38"
                />
              </svg>
            </Box>
            <Typography
              component={"h4"}
              sx={{
                fontSize: "1.8rem",
                textAlign: "center",
                marginTop: "2rem",
              }}
              className="alertText"
            >
              Are You Sure You Want to Delete Strategy !
            </Typography>

            <Box sx={{ display: { xs: "flex" } }}>
              <Button
                onClick={handleClose}
                className="modal-btn btn-danger"
                sx={{ marginRight: "0.5rem" }}
              >
                Discard
              </Button>
              <Button
                onClick={handleDelete}
                className="modal-btn btn-primary"
                sx={{ marginLeft: "0.5rem" }}
              >
                Confirm
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
