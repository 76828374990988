import React from 'react';
import { List, ListItem, Typography } from '@mui/material';

export default function Desclaimer() {

  return (
    <>
      <List className='profile-content'>
        <ListItem>
          <Typography component='h4'>Desclaimer</Typography>
          <Typography component='p'>All subscription fees paid to India Algo is Non refundable. We do not provide trading tips nor we are investment adviser. Our service is solely restricted to automated trading application development, deployment and maintenance. All algorithms are based on backtested data but we do not provide any guarantee for their performance in future. The algorithm running in an automated system is agreed with the user prior deployment and we do not take any liability for any loss generated by the same. Past performance of advise/strategy/model does not indicate the future performance of any current or future strategy/model or advise by India Algo Trades and actual returns may differ significantly from that depicted herein due to various factors including but not limited to impact costs, expense charged, timing of entry/exit, timing of additional flows/redemptions, individual client mandates, specific portfolio construction characteristics etc. There is no assurance or guarantee that the objectives of any strategy/model or advice provided by India Algo Trades will be achieved. India Algo Trades or any of its partner/s or principal officer/employees do not assure/give guarantee for any return on the investment in strategies/models/advice given to the Investor. The value of investment can go up/down depending on factors & forces affecting securities markets. India Algo Trades or its associates are not liable or responsible for any loss or shortfall arising from operations and affected by the market condition.</Typography>
        </ListItem>
      </List>
    </>
  )
}
