export const OTP_SENT_INIT = "OTP_SENT_INIT";
export const OTP_SENT_SUCCESS = "OTP_SENT_SUCCESS";
export const OTP_SENT_FAIL = "OTP_SENT_FAIL";

export const TOKEN_REFRESH_INIT = "TOKEN_REFRESH_INIT";
export const TOKEN_REFRESH_SUCCESS = "TOKEN_REFRESH_SUCCESS";
export const TOKEN_REFRESH_FAIL = "TOKEN_REFRESH_FAIL";

export const GET_SYMBOL_DATA_INIT = "GET_SYMBOL_DATA_INIT";
export const GET_SYMBOL_DATA_SUCCESS = "GET_SYMBOL_DATA_SCCESS";
export const GET_SYMBOL_DATA_FAIL = "GET_SYMBOL_DATA_FAIL";

export const GET_SEGMENT_SYMBOL_ALERT_SUCCESS =
  "GET_SEGMENT_SYMBOL_ALERT_SUCCESS";
export const GET_LIVE_POSITIONS_SUCCESS = "GET_LIVE_POSITIONS_SUCCESS";
export const GET_PAPER_POSITIONS_SUCCESS = "GET_PAPER_POSITIONS_SUCCESS";
export const GET_ADMIN_PAPER_POSITIONS_SUCCESS =
  "GET_ADMIN_PAPER_POSITIONS_SUCCESS";
export const GET_ADMIN_LIVE_POSITIONS_SUCCESS =
  "GET_ADMIN_LIVE_POSITIONS_SUCCESS";

export const GET_LIVE_NOTIFICATIONS_SUCCESS = "GET_LIVE_NOTIFICATIONS_SUCCESS";
export const GET_DAY_GAIN_LOSS_SUCCESS = "GET_DAY_GAIN_LOSS_SUCCESS";
export const GET_TOKEN_PRICE_SUCCESS = "GET_TOKEN_PRICE_SUCCESS";

export const PHONE_CHECK_INIT = "PHONE_CHECK_INIT";
export const PHONE_CHECK_SUCCESS = "PHONE_CHECK_SUCCESS";
export const PHONE_CHECK_FAIL = "PHONE_CHECK_FAIL";

export const OTP_VERIFY_INIT = "OTP_VERIFY_INIT";
export const OTP_VERIFY_SUCCESS = "OTP_VERIFY_SUCCESS";
export const OTP_VERIFY_FAIL = "OTP_VERIFY_FAIL";

export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAIL = "DELETE_ACCOUNT_FAIL";

export const AUTH_SIGNUP_INIT = "AUTH_SIGNUP_INIT";
export const AUTH_SIGNUP_SUCCESS = "AUTH_SIGNUP_SUCCESS";
export const AUTH_SIGNUP_FAIL = "AUTH_SIGNUP_FAIL";

export const UPLOAD_TUTORIAL_INIT = "UPLOAD_TUTORIAL_INIT";
export const UPLOAD_TUTORIAL_SUCCESS = "UPLOAD_TUTORIAL_SUCCESS";
export const UPLOAD_TUTORIAL_FAIL = "UPLOAD_TUTORIAL_FAIL";

export const PAYMENT_INIT = "PAYMENT_INIT";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_FAIL = "PAYMENT_FAIL";

export const GET_PAYMENT_INVOICE_INIT = "GET_PAYMENT_INVOICE_INIT";
export const GET_PAYMENT_INVOICE_SUCCESS = "GET_PAYMENT_INVOICE_SUCCESS";
export const GET_PAYMENT_INVOICE_FAIL = "GET_PAYMENT_INVOICE_FAIL";

export const GET_REFRECODE_INIT = "GET_REFRECODE_INIT";
export const GET_REFRECODE_SUCCESS = "GET_REFRECODE_SUCCESS";
export const GET_REFRECODE_FAIL = "GET_REFRECODE_FAIL";

export const GET_USER_VENDOR_INIT = "GET_USER_VENDOR_INIT";
export const GET_USER_VENDOR_SUCCESS = "GET_USER_VENDOR_SUCCESS";
export const GET_USER_VENDOR_FAIL = "GET_USER_VENDOR_FAIL";

export const GET_SYMBOL_TOKEN_INIT = "GET_SYMBOL_TOKEN_INIT";
export const GET_SYMBOL_TOKEN_SUCCESS = "GET_SYMBOL_TOKEN_SUCCESS";
export const GET_SYMBOL_TOKEN_FAIL = "GET_SYMBOL_TOKEN_FAIL";

export const DELETE_SYMBOL_TOKEN_INIT = "DELETE_SYMBOL_TOKEN_INIT";
export const DELETE_SYMBOL_TOKEN_SUCCESS = "DELETE_SYMBOL_TOKEN_SUCCESS";
export const DELETE_SYMBOL_TOKEN_FAIL = "DELETE_SYMBOL_TOKEN_FAIL";

export const EDIT_SYMBOL_TOKEN_INIT = "EDIT_SYMBOL_TOKEN_INIT";
export const EDIT_SYMBOL_TOKEN_SUCCESS = "EDIT_SYMBOL_TOKEN_SUCCESS";
export const EDIT_SYMBOL_TOKEN_FAIL = "EDIT_SYMBOL_TOKEN_FAIL";

export const CREATE_SYMBOL_TOKEN_INIT = "CREATE_SYMBOL_TOKEN_INIT";
export const CREATE_SYMBOL_TOKEN_SUCCESS = "CREATE_SYMBOL_TOKEN_SUCCESS";
export const CREATE_SYMBOL_TOKEN_FAIL = "CREATE_SYMBOL_TOKEN_FAIL";

export const ACTIVATE_COPY_TRADE_INIT = "ACTIVATE_COPY_TRADE_INIT";
export const ACTIVATE_COPY_TRADE_SUCCESS = "ACTIVATE_COPY_TRADE_SUCCESS";
export const ACTIVATE_COPY_TRADE_FAIL = "ACTIVATE_COPY_TRADE_FAIL";

export const STOP_COPY_TRADE_INIT = "STOP_COPY_TRADE_INIT";
export const STOP_COPY_TRADE_SUCCESS = "STOP_COPY_TRADE_SUCCESS";
export const STOP_COPY_TRADE_FAIL = "STOP_COPY_TRADE_FAIL";

export const ADD_SEGMENT_COPY_TRADE_INIT = "ADD_SEGMENT_COPY_TRADE_INIT";
export const ADD_SEGMENT_COPY_TRADE_SUCCESS = "ADD_SEGMENT_COPY_TRADE_SUCCESS";
export const ADD_SEGMENT_COPY_TRADE_FAIL = "ADD_SEGMENT_COPY_TRADE_FAIL";

export const EDIT_SEGMENT_COPY_TRADE_INIT = "EDIT_SEGMENT_COPY_TRADE_INIT";
export const EDIT_SEGMENT_COPY_TRADE_SUCCESS =
  "EDIT_SEGMENT_COPY_TRADE_SUCCESS";
export const EDIT_SEGMENT_COPY_TRADE_FAIL = "EDIT_SEGMENT_COPY_TRADE_FAIL";

export const DELETE_SEGMENT_COPY_TRADE_INIT = "DELETE_SEGMENT_COPY_TRADE_INIT";
export const DELETE_SEGMENT_COPY_TRADE_SUCCESS =
  "DELETE_SEGMENT_COPY_TRADE_SUCCESS";
export const DELETE_SEGMENT_COPY_TRADE_FAIL = "DELETE_SEGMENT_COPY_TRADE_FAIL";

export const GET_SEGMENT_COPY_TRADE_INIT = "GET_SEGMENT_COPY_TRADE_INIT";
export const GET_SEGMENT_COPY_TRADE_SUCCESS = "GET_SEGMENT_COPY_TRADE_SUCCESS";
export const GET_SEGMENT_COPY_TRADE_FAIL = "GET_SEGMENT_COPY_TRADE_FAIL";

export const GET_SYMBOL_LIST_INIT = "GET_SYMBOL_LIST_INIT";
export const GET_SYMBOL_LIST_SUCCESS = "GET_SYMBOL_LIST_SUCCESS";
export const GET_SYMBOL_LIST_FAIL = "GET_SYMBOL_LIST_FAIL";

export const UPDATE_USER_STRATEGY_INIT = "UPDATE_USER_STRATEGY_INIT";
export const UPDATE_USER_STRATEGY_SUCCESS = "UPDATE_USER_STRATEGY_SUCCESS";
export const UPDATE_USER_STRATEGY_FAIL = "UPDATE_USER_STRATEGY_FAIL";

export const GET_SERVICE_CHARGES_INIT = "GET_SERVICE_CHARGES_INIT";
export const GET_SERVICE_CHARGES_SUCCESS = "GET_SERVICE_CHARGES_SUCCESS";
export const GET_SERVICE_CHARGES_FAIL = "GET_SERVICE_CHARGES_FAIL";

export const EDIT_SERVICE_CHARGES_INIT = "EDIT_SERVICE_CHARGES_INIT";
export const EDIT_SERVICE_CHARGES_SUCCESS = "EDIT_SERVICE_CHARGES_SUCCESS";
export const EDIT_SERVICE_CHARGES_FAIL = "EDIT_SERVICE_CHARGES_FAIL";

export const GENERATE_TRADING_KEY_INIT = "GENERATE_TRADING_KEY_INIT";
export const GENERATE_TRADING_KEY_SUCCESS = "GENERATE_TRADING_KEY_SUCCESS";
export const GENERATE_TRADING_KEY_FAIL = "GENERATE_TRADING_KEY_FAIL";

export const CRON_RUN_INIT = "CRON_RUN_INIT";
export const CRON_RUN_SUCCESS = "CRON_RUN_SUCCESS";
export const CRON_RUN_FAIL = "CRON_RUN_FAIL";

export const GET_CRON_INIT = "GET_CRON_INIT";
export const GET_CRON_SUCCESS = "GET_CRON_SUCCESS";
export const GET_CRON_FAIL = "GET_CRON_FAIL";

export const GET_SMS_TEXT_ALERT_INIT = "GET_SMS_TEXT_ALERT_INIT";
export const GET_SMS_TEXT_ALERT_SUCCESS = "GET_SMS_TEXT_ALERT_SUCCESS";
export const GET_SMS_TEXT_ALERT_FAIL = "GET_SMS_TEXT_ALERT_FAIL";

export const ADD_SMS_TEXT_ALERT_INIT = "ADD_SMS_TEXT_ALERT_INIT";
export const ADD_SMS_TEXT_ALERT_SUCCESS = "ADD_SMS_TEXT_ALERT_SUCCESS";
export const ADD_SMS_TEXT_ALERT_FAIL = "ADD_SMS_TEXT_ALERT_FAIL";

export const DELETE_BROKER_INIT = "DELETE_BROKER_INIT";
export const DELETE_BROKER_SUCCESS = "DELETE_BROKER_SUCCESS";
export const DELETE_BROKER_FAIL = "DELETE_BROKER_FAIL";

export const GET_BROKER_INIT = "GET_BROKER_INIT";
export const GET_BROKER_SUCCESS = "GET_BROKER_SUCCESS";
export const GET_BROKER_FAIL = "GET_BROKER_FAIL";

export const SEND_PUSH_NOTIFICATION_INIT = "SEND_PUSH_NOTIFICATION_INIT";
export const SEND_PUSH_NOTIFICATION_SUCCESS = "SEND_PUSH_NOTIFICATION_SUCCESS";
export const SEND_PUSH_NOTIFICATION_FAIL = "SEND_PUSH_NOTIFICATION_FAIL";

export const GET_ADMIN_TUTORIAL_INIT = "GET_ADMIN_TUTORIAL_INIT";
export const GET_ADMIN_TUTORIAL_SUCCESS = "GET_ADMIN_TUTORIAL_SUCCESS";
export const GET_ADMIN_TUTORIAL_FAIL = "GET_ADMIN_TUTORIAL_FAIL";

export const GET_NOTIFICATION_INIT = "GET_NOTIFICATION_INIT";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAIL = "GET_NOTIFICATION_FAIL";

export const EDIT_ADMIN_TUTORIAL_INIT = "EDIT_ADMIN_TUTORIAL_INIT";
export const EDIT_ADMIN_TUTORIAL_SUCCESS = "EDIT_ADMIN_TUTORIAL_SUCCESS";
export const EDIT_ADMIN_TUTORIAL_FAIL = "EDIT_ADMIN_TUTORIAL_FAIL";

export const DELETE_ADMIN_TUTORIAL_INIT = "DELETE_ADMIN_TUTORIAL_INIT";
export const DELETE_ADMIN_TUTORIAL_SUCCESS = "DELETE_ADMIN_TUTORIAL_SUCCESS";
export const DELETE_ADMIN_TUTORIAL_FAIL = "DELETE_ADMIN_TUTORIAL_FAIL";

export const GET_USER_TUTORIAL_INIT = "GET_USER_TUTORIAL_INIT";
export const GET_USER_TUTORIAL_SUCCESS = "GET_USER_TUTORIAL_SUCCESS";
export const GET_USER_TUTORIAL_FAIL = "GET_USER_TUTORIAL_FAIL";

export const SEND_PAYMENT_DATA_INIT = "SEND_PAYMENT_DATA_INIT";
export const SEND_PAYMENT_DATA_SUCCESS = "SEND_PAYMENT_DATA_SUCCESS";
export const SEND_PAYMENT_DATA_FAIL = "SEND_PAYMENT_DATA_FAIL";

export const AUTH_PROFILE_INIT = "AUTH_PROFILE_INIT";
export const AUTH_PROFILE_SUCCESS = "AUTH_PROFILE_SUCCESS";
export const AUTH_PROFILE_FAIL = "AUTH_PROFILE_FAIL";

export const EDIT_PROFILE_INIT = "EDIT_PROFILE_INIT";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_FAIL = "EDIT_PROFILE_FAIL";

export const GET_BROKER_DETAILS_INIT = "GET_BROKER_DETAILS_INIT";
export const GET_BROKER_DETAILS_SUCCESS = " GET_BROKER_DETAILS_SUCCESS";
export const GET_BROKER_DETAILS_FAIL = " GET_BROKER_DETAILS_FAIL";

export const ZEBULL_BROKER_INIT = "ZEBULL_BROKER_INIT";
export const ZEBULL_BROKER_SUCCESS = " ZEBULL_BROKER_SUCCESS";
export const ZEBULL_BROKER_FAIL = " ZEBULL_BROKER_FAIL";

export const GET_BROKER_URLS_INIT = "GET_BROKER_URLS_INIT";
export const GET_BROKER_URLS_SUCCESS = " GET_BROKER_URLS_SUCCESS";
export const GET_BROKER_URLS_FAIL = " GET_BROKER_URLS_FAIL";

export const ICICI_BROKER_INIT = "ICICI_BROKER_INIT";
export const ICICI_BROKER_SUCCESS = " ICICI_BROKER_SUCCESS";
export const ICICI_BROKER_FAIL = " ICICI_BROKER_FAIL";

export const UPSTOX_BROKER_INIT = "UPSTOX_BROKER_INIT";
export const UPSTOX_BROKER_SUCCESS = " UPSTOX_BROKER_SUCCESS";
export const UPSTOX_BROKER_FAIL = " UPSTOX_BROKER_FAIL";

export const ANGEL_BROKER_INIT = "ANGEL_BROKER_INIT";
export const ANGEL_BROKER_SUCCESS = " ANGEL_BROKER_SUCCESS";
export const ANGEL_BROKER_FAIL = " ANGEL_BROKER_FAIL";

export const ZERODHA_BROKER_INIT = "ZERODHA_BROKER_INIT";
export const ZERODHA_BROKER_SUCCESS = " ZERODHA_BROKER_SUCCESS";
export const ZERODHA_BROKER_FAIL = " ZERODHA_BROKER_FAIL";

export const FINVASIA_BROKER_INIT = "FINVASIA_BROKER_INIT";
export const FINVASIA_BROKER_SUCCESS = " FINVASIA_BROKER_SUCCESS";
export const FINVASIA_BROKER_FAIL = " FINVASIA_BROKER_FAIL";

export const KOTAK_BROKER_INIT = "KOTAK_BROKER_INIT";
export const KOTAK_BROKER_SUCCESS = " KOTAK_BROKER_SUCCESS";
export const KOTAK_BROKER_FAIL = " KOTAK_BROKER_FAIL";

export const ALICE_BROKER_INIT = "ALICE_BROKER_INIT";
export const ALICE_BROKER_SUCCESS = " ALICE_BROKER_SUCCESS";
export const ALICE_BROKER_FAIL = " ALICE_BROKER_FAIL";
export const GET_ALICE_SESSION_SUCCESS = "GET_ALICE_SESSION_SUCCESS";

export const FYERS_BROKER_INIT = "FYERS_BROKER_INIT";
export const FYERS_BROKER_SUCCESS = " FYERS_BROKER_SUCCESS";
export const FYERS_BROKER_FAIL = " FYERS_BROKER_FAIL";

export const SWASTIKA_BROKER_INIT = "SWASTIKA_BROKER_INIT";
export const SWASTIKA_BROKER_SUCCESS = "SWASTIKA_BROKER_SUCCESS";
export const SWASTIKA_BROKER_FAIL = "SWASTIKA_BROKER_FAIL";
export const GET_SWASTIKA_SESSION_SUCCESS = "GET_SWASTIKA_SESSION_SUCCESS";

export const DHAN_BROKER_INIT = "DHAN_BROKER_INIT";
export const DHAN_BROKER_SUCCESS = "DHAN_BROKER_SUCCESS";
export const DHAN_BROKER_FAIL = "DHAN_BROKER_FAIL";
export const GET_DHAN_SESSION_SUCCESS = "GET_DHAN_SESSION_SUCCESS";

export const NUVAMA_BROKER_INIT = "NUVAMA_BROKER_INIT";
export const NUVAMA_BROKER_SUCCESS = "NUVAMA_BROKER_SUCCESS";
export const NUVAMA_BROKER_FAIL = "NUVAMA_BROKER_FAIL";

export const SHAREKHAN_BROKER_INIT = "SHAREKHAN_BROKER_INIT";
export const SHAREKHAN_BROKER_SUCCESS = "SHAREKHAN_BROKER_SUCCESS";
export const SHAREKHAN_BROKER_FAIL = "SHAREKHAN_BROKER_FAIL";

export const FLATTRADE_BROKER_INIT = "FLATTRADE_BROKER_INIT";
export const FLATTRADE_BROKER_SUCCESS = "FLATTRADE_BROKER_SUCCESS";
export const FLATTRADE_BROKER_FAIL = "FLATTRADE_BROKER_FAIL";

export const GOODWILL_BROKER_INIT = "GOODWILL_BROKER_INIT";
export const GOODWILL_BROKER_SUCCESS = "GOODWILL_BROKER_SUCCESS";
export const GOODWILL_BROKER_FAIL = "GOODWILL_BROKER_FAIL";

export const RMONEY_BROKER_INIT = "RMONEY_BROKER_INIT";
export const RMONEY_BROKER_SUCCESS = "RMONEY_BROKER_SUCCESS";
export const RMONEY_BROKER_FAIL = "RMONEY_BROKER_FAIL";

export const PROFITMART_BROKER_INIT = "PROFITMART_BROKER_INIT";
export const PROFITMART_BROKER_SUCCESS = "PROFITMART_BROKER_SUCCESS";
export const PROFITMART_BROKER_FAIL = "PROFITMART_BROKER_FAIL";

export const EXPRESSO_BROKER_INIT = "EXPRESSO_BROKER_INIT";
export const EXPRESSO_BROKER_SUCCESS = "EXPRESSO_BROKER_SUCCESS";
export const EXPRESSO_BROKER_FAIL = "EXPRESSO_BROKER_FAIL";

export const MANDOT_BROKER_INIT = "MANDOT_BROKER_INIT";
export const MANDOT_BROKER_SUCCESS = "MANDOT_BROKER_SUCCESS";
export const MANDOT_BROKER_FAIL = "MANDOT_BROKER_FAIL";

export const FIVEPAISA_BROKER_INIT = "FIVEPAISA_BROKER_INIT";
export const FIVEPAISA_BROKER_SUCCESS = "FIVEPAISA_BROKER_SUCCESS";
export const FIVEPAISA_BROKER_FAIL = "FIVEPAISA_BROKER_FAIL";

export const MOTILALOSWAL_BROKER_INIT = "MOTILALOSWAL_BROKER_INIT";
export const MOTILALOSWAL_BROKER_SUCCESS = "MOTILALOSWAL_BROKER_SUCCESS";
export const MOTILALOSWAL_BROKER_FAIL = "MOTILALOSWAL_BROKER_FAIL";

export const BROKER_STATUS_INIT = "BROKER_STATUS_INIT";
export const BROKER_STATUS_SUCCESS = "BROKER_STATUS_SUCCESS";
export const BROKER_STATUS_FAIL = "BROKER_STATUS_FAIL";

export const ADD_WATCH_LIST_SUCCESS = "ADD_WATCH_LIST_SUCCESS";
export const ADD_WATCH_LIST_FAIL = "ADD_WATCH_LIST_FAIL";

export const GET_WATCH_LIST_SUCCESS = "GET_WATCH_LIST_SUCCESS";
export const GET_WATCH_LIST_FAIL = "GET_WATCH_LIST_FAIL";

export const DELETE_WATCH_LIST_SUCCESS = "DELETE_WATCH_LIST_SUCCESS";
export const DELETE_WATCH_LIST_FAIL = "DELETE_WATCH_LIST_FAIL";

export const NSE_EXCHANGE_SUCCESS = "NSE_EXCHANGE_SUCCESS";
export const NSE_EXCHANGE_FAIL = "NSE_EXCHANGE_FAIL";

export const FUTURE_SYMBOL_SUCCESS = "FUTURE_SYMBOL_SUCCESS";
export const FUTURE_SYMBOL_FAIL = "FUTURE_SYMBOL_FAIL";

export const EXPIRY_EXCHANGE_SUCCESS = "EXPIRY_EXCHANGE_SUCCESS";
export const EXPIRY_EXCHANGE_FAIL = "EXPIRY_EXCHANGE_FAIL";

export const OPTION_EXPIRY_DATA = "OPTION_EXPIRY_DATA";
export const OPTION_STRIKE_DATA = "OPTION_STRIKE_DATA";

export const STRIKEPRICE_EXCHANGE_SUCCESS = "STRIKEPRICE_EXCHANGE_SUCCESS";
export const STRIKEPRICE_EXCHANGE_FAIL = "STRIKEPRICE_EXCHANGE_FAIL";

export const DELETE_WATCH_LIST_DATA_SUCCESS = "DELETE_WATCH_LIST_DATA_SUCCESS";
export const DELETE_WATCH_LIST_DATA_FAIL = "DELETE_WATCH_LIST_DATA_FAIL";

export const GET_WATCH_LIST_LIVE_DATA_SUCCESS =
  "GET_WATCH_LIST_LIVE_DATA_SUCCESS";

export const SIGNAL_DETAILS_INIT = "SIGNAL_DETAILS_INIT";
export const SIGNAL_DETAILS_SUCCESS = "SIGNAL_DETAILS_SUCCESS";
export const SIGNAL_DETAILS_FAIL = "SIGNAL_DETAILS_FAIL";

export const OPEN_DEMATE_INIT = "OPEN_DEMATE_INIT";
export const OPEN_DEMATE_SUCCESS = "OPEN_DEMATE_SUCCESS";
export const OPEN_DEMATE_FAIL = "OPEN_DEMATE_FAIL";

export const EDIT_DEMATE_INIT = "EDIT_DEMATE_INIT";
export const EDIT_DEMATE_SUCCESS = "EDIT_DEMATE_SUCCESS";
export const EDIT_DEMATE_FAIL = "EDIT_DEMATE_FAIL";

export const LIVE_PROFIT_DETAILS_INIT = "LIVE_PROFIT_DETAILS_INIT";
export const LIVE_PROFIT_DETAILS_SUCCESS = "LIVE_PROFIT_DETAILS_SUCCESS";
export const LIVE_PROFIT_DETAILS_FAIL = "LIVE_PROFIT_DETAILS_FAIL";

export const PAPER_PROFIT_DETAILS_INIT = "PAPER_PROFIT_DETAILS_INIT";
export const PAPER_PROFIT_DETAILS_SUCCESS = "PAPER_PROFIT_DETAILS_SUCCESS";
export const PAPER_PROFIT_DETAILS_FAIL = "PAPER_PROFIT_DETAILS_FAIL";

export const PAPER_ORDER_DETAILS_INIT = "PAPER_ORDER_DETAILS_INIT";
export const PAPER_ORDER_DETAILS_SUCCESS = "PAPER_ORDER_DETAILS_SUCCESS";
export const PAPER_ORDER_DETAILS_FAIL = "PAPER_ORDER_DETAILS_FAIL";

export const PAPER_TRADE_DETAILS_INIT = "PAPER_TRADE_DETAILS_INIT";
export const PAPER_TRADE_DETAILS_SUCCESS = "PAPER_TRADE_DETAILS_SUCCESS";
export const PAPER_TRADE_DETAILS_FAIL = "PAPER_TRADE_DETAILS_FAIL";

export const ADMIN_PAPER_ORDER_DETAILS_INIT = "ADMIN_PAPER_ORDER_DETAILS_INIT";
export const ADMIN_PAPER_ORDER_DETAILS_SUCCESS =
  "ADMIN_PAPER_ORDER_DETAILS_SUCCESS";
export const ADMIN_PAPER_ORDER_DETAILS_FAIL = "ADMIN_PAPER_ORDER_DETAILS_FAIL";

export const ADMIN_PAPER_TRADE_DETAILS_INIT = "ADMIN_PAPER_TRADE_DETAILS_INIT";
export const ADMIN_PAPER_TRADE_DETAILS_SUCCESS =
  "ADMIN_PAPER_TRADE_DETAILS_SUCCESS";
export const ADMIN_PAPER_TRADE_DETAILS_FAIL = "ADMIN_PAPER_TRADE_DETAILS_FAIL";

export const CREATE_POSITION_INIT = "CREATE_POSITION_INIT";
export const CREATE_POSITION_SUCCESS = "CREATE_POSITION_SUCCESS";
export const CREATE_POSITION_FAIL = "CREATE_POSITION_FAIL";

export const CLOSE_ORDER_INIT = "CLOSE_ORDER_INIT";
export const CLOSE_ORDER_SUCCESS = "CLOSE_ORDER_SUCCESS";
export const CLOSE_ORDER_FAIL = "CLOSE_ORDER_FAIL";

export const ADMIN_CLOSE_ORDER_INIT = "ADMIN_CLOSE_ORDER_INIT";
export const ADMIN_CLOSE_ORDER_SUCCESS = "ADMIN_CLOSE_ORDER_SUCCESS";
export const ADMIN_CLOSE_ORDER_FAIL = "ADMIN_CLOSE_ORDER_FAIL";

export const CLOSE_STRATEGY_INIT = "CLOSE_STRATEGY_INIT";
export const CLOSE_STRATEGY_SUCCESS = "CLOSE_STRATEGY_SUCCESS";
export const CLOSE_STRATEGY_FAIL = "CLOSE_STRATEGY_FAIL";

export const CANCEL_ORDER_INIT = "CANCEL_ORDER_INIT";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_FAIL = "CANCEL_ORDER_FAIL";

export const CLOSE_COPY_LIMIT_ORDER_INIT = "CLOSE_COPY_LIMIT_ORDER_INIT";
export const CLOSE_COPY_LIMIT_ORDER_SUCCESS = "CLOSE_COPY_LIMIT_ORDER_SUCCESS";
export const CLOSE_COPY_LIMIT_ORDER_FAIL = "CLOSE_COPY_LIMIT_ORDER_FAIL";

export const CLOSE_LIMIT_PAPER_TRADE_INIT = "CLOSE_LIMIT_PAPER_TRADE_INIT";
export const CLOSE_LIMIT_PAPER_TRADE_SUCCESS =
  "CLOSE_LIMIT_PAPER_TRADE_SUCCESS";
export const CLOSE_LIMIT_PAPER_TRADE_FAIL = "CLOSE_LIMIT_PAPER_TRADE_FAIL";

export const CLOSE_LIMIT_INIT = "CLOSE_LIMIT_INIT";
export const CLOSE_LIMIT_SUCCESS = "CLOSE_LIMIT_SUCCESS";
export const CLOSE_LIMIT_FAIL = "CLOSE_LIMIT_FAIL";

export const MODIFY_ORDER_INIT = "MODIFY_ORDER_INIT";
export const MODIFY_ORDER_SUCCESS = "MODIFY_ORDER_SUCCESS";
export const MODIFY_ORDER_FAIL = "MODIFY_ORDER_FAIL";

export const PL_REPORT_PDF_INIT = "PL_REPORT_PDF_INIT";
export const PL_REPORT_PDF_SUCCESS = "PL_REPORT_PDF_SUCCESS";
export const PL_REPORT_PDF_FAIL = "PL_REPORT_PDF_FAIL";

export const PAPER_PL_REPORT_PDF_INIT = "PAPER_PL_REPORT_PDF_INIT";
export const PAPER_PL_REPORT_PDF_SUCCESS = "PAPER_PL_REPORT_PDF_SUCCESS";
export const PAPER_PL_REPORT_PDF_FAIL = "PAPER_PL_REPORT_PDF_FAIL";

export const GET_SEGMENT_INIT = "GET_SEGMENT_INIT";
export const GET_SEGMENT_SUCCESS = "GET_SEGMENT_SUCCESS";
export const GET_SEGMENT_FAIL = "GET_SEGMENT_FAIL";

export const GET_BROKERS_INIT = "GET_BROKERS_INIT";
export const GET_BROKERS_SUCCESS = "GET_BROKERS_SUCCESS";
export const GET_BROKERS_FAIL = "GET_BROKERS_FAIL";

export const CLOSE_ALL_LIVE_ORDER_INIT = "CLOSE_ALL_LIVE_ORDER_INIT";
export const CLOSE_ALL_LIVE_ORDER_SUCCESS = "CLOSE_ALL_LIVE_ORDER_SUCCESS";
export const CLOSE_ALL_LIVE_ORDER_FAIL = "CLOSE_ALL_LIVE_ORDER_FAIL";

export const GET_LIVE_TRADE_DETAILS_INIT = "GET_LIVE_TRADE_DETAILS_INIT";
export const GET_LIVE_TRADE_DETAILS_SUCCESS = "GET_LIVE_TRADE_DETAILS_SUCCESS";
export const GET_LIVE_TRADE_DETAILS_FAIL = "GET_LIVE_TRADE_DETAILS_FAIL";

export const GET_LIVE_ORDER_DETAILS_INIT = "GET_LIVE_ORDER_DETAILS_INIT";
export const GET_LIVE_ORDER_DETAILS_SUCCESS = "GET_LIVE_ORDER_DETAILS_SUCCESS";
export const GET_LIVE_ORDER_DETAILS_FAIL = "GET_LIVE_ORDER_DETAILS_FAIL";

export const ADMIN_UPDATE_USER_DETAILS_INIT = "ADMIN_UPDATE_USER_DETAILS_INIT";
export const ADMIN_UPDATE_USER_DETAILS_SUCCESS =
  "ADMIN_UPDATE_USER_DETAILS_SUCCESS";
export const ADMIN_UPDATE_USER_DETAILS_FAIL = "ADMIN_UPDATE_USER_DETAILS_FAIL";

export const CREATE_ADMIN_UPDATE_USER_DETAILS_INIT =
  "CREATE_ADMIN_UPDATE_USER_DETAILS_INIT";
export const CREATE_ADMIN_UPDATE_USER_DETAILS_SUCCESS =
  "CREATE_ADMIN_UPDATE_USER_DETAILS_SUCCESS";
export const CREATE_ADMIN_UPDATE_USER_DETAILS_FAIL =
  "CREATE_ADMIN_UPDATE_USER_DETAILS_FAIL";

export const ADMIN_TRADE_DETAILS_INIT = "ADMIN_TRADE_DETAILS_INIT";
export const ADMIN_TRADE_DETAILS_SUCCESS = "ADMIN_TRADE_DETAILS_SUCCESS";
export const ADMIN_TRADE_DETAILS_FAIL = "ADMIN_TRADE_DETAILS_FAIL";

export const ADMIN_ORDER_DETAILS_INIT = "ADMIN_ORDER_DETAILS_INIT";
export const ADMIN_ORDER_DETAILS_SUCCESS = "ADMIN_ORDER_DETAILS_SUCCESS";
export const ADMIN_ORDER_DETAILS_FAIL = "ADMIN_ORDER_DETAILS_FAIL";

export const LIVE_FEED_DATA_SUCCESS = "LIVE_FEED_DAT_SUCCESS";
export const SOCKET_OPEN_SUCCESS = "SOCKET_OPEN_SUCCESS";
export const SOCKET_CLOSE_SUCCESS = "SOCKET_CLOSE_SUCCESS";
export const GET_OVERALL_PROFIT_LOSS_SUCCESS =
  "GET_OVERALL_PROFIT_LOSS_SUCCESS";

export const CONTACT_US_INIT = "CONTACT_US_INIT";
export const CONTACT_US_SUCCESS = "CONTACT_US_SUCCESS";
export const CONTACT_US_FAIL = "CONTACT_US_FAIL";

export const SUB_BROKER_LOGIN_INIT = "SUB_BROKER_LOGIN_INIT";
export const SUB_BROKER_LOGIN_SUCCESS = "SUB_BROKER_LOGIN_SUCCESS";
export const SUB_BROKER_LOGIN_FAIL = "SUB_BROKER_LOGIN_FAIL";

export const UPDATE_CONTACT_US_INIT = "UPDATE_CONTACT_US_INIT";
export const UPDATE_CONTACT_US_SUCCESS = "UPDATE_CONTACT_US_SUCCESS";
export const UPDATE_CONTACT_US_FAIL = "UPDATE_CONTACT_US_FAIL";

export const CREATE_BROKER_INIT = "CREATE_BROKER_INIT";
export const CREATE_BROKER_SUCCESS = "CREATE_BROKER_SUCCESS";
export const CREATE_BROKER_FAIL = "CREATE_BROKER_FAIL";

export const EDIT_SOCIAL_LINKS_INIT = "EDIT_SOCIAL_LINKS_INIT";
export const EDIT_SOCIAL_LINKS_SUCCESS = "EDIT_SOCIAL_LINKS_SUCCESS";
export const EDIT_SOCIAL_LINKS_FAIL = "EDIT_SOCIAL_LINKS_FAIL";

export const GET_SOCIAL_LINKS_INIT = "GET_SOCIAL_LINKS_INIT";
export const GET_SOCIAL_LINKS_SUCCESS = "GET_SOCIAL_LINKS_SUCCESS";
export const GET_SOCIAL_LINKS_FAIL = "GET_SOCIAL_LINKS_FAIL";

export const GET_SUB_BROKER_INIT = "GET_SUB_BROKER_INIT";
export const GET_SUB_BROKER_SUCCESS = "GET_SUB_BROKER_SUCCESS";
export const GET_SUB_BROKER_FAIL = "GET_SUB_BROKER_FAIL";

export const SEARCH_SUB_BROKER_INIT = "SEARCH_SUB_BROKER_INIT";
export const SEARCH_SUB_BROKER_SUCCESS = "SEARCH_SUB_BROKER_SUCCESS";
export const SEARCH_SUB_BROKER_FAIL = "SEARCH_SUB_BROKER_FAIL";

export const SEARCH_INVOICE_INIT = "SEARCH_INVOICE_INIT";
export const SEARCH_INVOICE_SUCCESS = "SEARCH_INVOICE_SUCCESS";
export const SEARCH_INVOICE_FAIL = "SEARCH_INVOICE_FAIL";

export const GET_INVOICE_DATA_INIT = "GET_INVOICE_DATA_INIT";
export const GET_INVOICE_DATA_SUCCESS = "GET_INVOICE_DATA_SUCCESS";
export const GET_INVOICE_DATA_FAIL = "GET_INVOICE_DATA_FAIL";

export const GET_WATCH_LIST_DATA_SUCCESS = "GET_WATCH_LIST_DATA_SUCCESS";

export const SEND_CONTACT_MSG_SUCCESS = "SEND_CONTACT_MSG_SUCCESS";
export const SEND_CONTACT_MSG_FAIL = "SEND_CONTACT_MSG_FAIL";

export const DELETE_WATCHLIST_SUCCESS = "DELETE_WATCHLIST_SUCCESS";
export const DELETE_WATCHLIST_FAIL = "DELETE_WATCHLIST_FAIL";

export const GET_FUND_BROKER_SUCCESS = "GET_FUND_BROKER_SUCCESS";
export const GET_FUND_BROKER_FAIL = "GET_FUND_BROKER_FAIL";

export const EDIT_MRP_SUCCESS = "EDIT_MRP_SUCCESS";
export const EDIT_MRP_FAIL = "EDIT_MRP_FAIL";

export const EDIT_VENDOR_REFERRAL_SUCCESS = "EDIT_VENDOR_REFERRAL_SUCCESS";
export const EDIT_VENDOR_REFERRAL_FAIL = "EDIT_VENDOR_REFERRAL_FAIL";

export const GET_ADMIN_VENDOR_REFFERAL_SUCCESS =
  "GET_ADMIN_VENDOR_REFFERAL_SUCCESS";
export const GET_ADMIN_VENDOR_REFFERAL_FAIL = "GET_ADMIN_VENDOR_REFFERAL_FAIL";

export const BUSINESS_REPORT_SUCCESS = "BUSINESS_REPORT_SUCCESS";
export const BUSINESS_REPORT_FAIL = "BUSINESS_REPORT_FAIL";
export const UPLOAD_LOGO_SUCCESS = "UPLOAD_LOGO_SUCCESS";
export const UPLOAD_LOGO_FAIL = "UPLOAD_LOGO_FAIL";

export const GET_LOGO_SUCCESS = "GET_LOGO_SUCCESS";
export const GET_LOGO_FAIL = "GET_LOGO_FAIL";

export const GET_MOBILE_LOGO_SUCCESS = "GET_MOBILE_LOGO_SUCCESS";
export const GET_MOBILE_LOGO_FAIL = "GET_MOBILE_LOGO_FAIL";

export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
export const GET_FAQ_FAIL = "GET_FAQ_FAIL";

export const GET_DASHBOARD_COUNT_SUCCESS = "GET_DASHBOARD_COUNT_SUCCESS";
export const GET_DASHBOARD_COUNT_FAIL = "GET_DASHBOARD_COUNT_FAIL";

export const GET_TODAY_COUNT_SUCCESS = "GET_TODAY_COUNT_SUCCESS";
export const GET_TODAY_COUNT_FAIL = "GET_TODAY_COUNT_FAIL";

export const EMPTY_USER_VIEW = "EMPTY_USER_VIEW";

export const GET_USERADD_STRATERGY_INIT = "GET_USERADD_STRATERGY_INIT";
export const GET_USERADD_STRATERGY_SUCCESS = "GET_USERADD_STRATERGY_SUCCESS";
export const GET_USERADD_STRATERGY_FAIL = "GET_USERADD_STRATERGY_FAIL";

export const UPDATE_STRATEGY = "UPDATE_STRATEGY";
export const GET_PNL_POSITIONS_SUCCESS = "GET_PNL_POSITIONS_SUCCESS";

export const ADD_USERADD_STRATERGY_INIT = "ADD_USERADD_STRATERGY_INIT";
export const ADD_USERADD_STRATERGY_SUCCESS = "ADD_USERADD_STRATERGY_SUCCESS";
export const ADD_USERADD_STRATERGY_FAIL = "ADD_USERADD_STRATERGY_FAIL";

export const GET_STRATERGY_INIT = "GET_STRATERGY_INIT";
export const GET_STRATERGY_SUCCESS = "GET_STRATERGY_SUCCESS";
export const GET_STRATERGY_FAIL = "GET_STRATERGY_FAIL";

export const ADD_ADMIN_STRATERGY_INIT = "ADD_ADMIN_STRATERGY_INIT";
export const ADD_ADMIN_STRATERGY_SUCCESS = "ADD_ADMIN_STRATERGY_SUCCESS";
export const ADD_ADMIN_STRATERGY_FAIL = "ADD_ADMIN_STRATERGY_FAIL";

export const GET_STRATEGY_CODE_INIT = "GET_STRATEGY_CODE_INIT";
export const GET_STRATEGY_CODE_SUCCESS = "GET_STRATEGY_CODE_SUCCESS";
export const GET_STRATEGY_CODE_FAIL = "GET_STRATEGY_CODE_FAIL";

export const GET_STRATEGIES_INIT = "GET_STRATEGIES_INIT";
export const GET_STRATEGIES_SUCCESS = "GET_STRATEGIES_SUCCESS";
export const GET_STRATEGIES_FAIL = "GET_STRATEGIES_FAIL";

export const GET_SEGMENTDATA_INIT = "GET_SEGMENTDATA_INIT";
export const GET_SEGMENTDATA_SUCCESS = "GET_SEGMENTDATA_SUCCESS";
export const GET_SEGMENTDATA_FAIL = "GET_SEGMENTDATA_FAIL";

export const GET_SYMBOLDATA_INIT = "GET_SYMBOLDATA_INIT";
export const GET_SYMBOLDATA_SUCCESS = "GET_SYMBOLDATA_SUCCESS";
export const GET_SYMBOLDATA_FAIL = "GET_SYMBOLDATA_FAIL";

export const GET_SIGNALALLDATA_INIT = "GET_SIGNALALLDATA_INIT";
export const GET_SIGNALALLDATA_SUCCESS = "GET_SIGNALALLDATA_SUCCESS";
export const GET_SIGNALALLDATA_FAIL = "GET_SIGNALALLDATA_FAIL";

export const GET_SENDTRADESIGNAL_INIT = "GET_SENDTRADESIGNAL_INIT";
export const GET_SENDTRADESIGNAL_SUCCESS = "GET_SENDTRADESIGNAL_SUCCESS";
export const GET_SENDTRADESIGNAL_FAIL = "GET_SENDTRADESIGNAL_FAIL";

export const STRATEGY_ACTIVATE_INIT = "STRATEGY_ACTIVATE_INIT";
export const STRATEGY_ACTIVATE_SUCCESS = "STRATEGY_ACTIVATE_SUCCESS";
export const STRATEGY_ACTIVATE_FAIL = "STRATEGY_ACTIVATE_FAIL";

export const STRATEGY_DEACTIVATE_INIT = "STRATEGY_DEACTIVATE_INIT";
export const STRATEGY_DEACTIVATE_SUCCESS = "STRATEGY_DEACTIVATE_SUCCESS";
export const STRATEGY_DEACTIVATE_FAIL = "STRATEGY_DEACTIVATE_FAIL";

export const STRATEGY_UNDEPLOY_INIT = "STRATEGY_UNDEPLOY_INIT";
export const STRATEGY_UNDEPLOY_SUCCESS = "STRATEGY_UNDEPLOY_SUCCESS";
export const STRATEGY_UNDEPLOY_FAIL = "STRATEGY_UNDEPLOY_FAIL";

export const GET_INVOICE_PLANVALUE_INIT = "GET_INVOICE_PLANVALUE_INIT";
export const GET_INVOICE_PLANVALUE_SUCCESS = "GET_INVOICE_PLANVALUE_SUCCESS";
export const GET_INVOICE_PLANVALUE_FAIL = "GET_INVOICE_PLANVALUE_FAIL";

export const ADD_COPYTRADE_INIT = "ADD_COPYTRADE_INIT";
export const ADD_COPYTRADE_SUCCESS = "ADD_COPYTRADE_SUCCESS";
export const ADD_COPYTRADE_FAIL = "ADD_COPYTRADE_FAIL";

export const GET_COPYTRADEDETAILS_INIT = "GET_COPYTRADEDETAILS_INIT";
export const GET_COPYTRADEDETATIS_SUCCESS = "GET_COPYTRADEDETATIS_SUCCESS";
export const GET_COPYTRADEDETATIS_FAIL = "GET_COPYTRADEDETATIS_FAIL";

export const CLOSECOPYTRADE_INIT = "CLOSECOPYTRADE_INIT";
export const CLOSECOPYTRADE_SUCCESS = "CLOSECOPYTRADE_SUCCESS";
export const CLOSECOPYTRADE_FAIL = "CLOSECOPYTRADE_FAIL";

export const GET_COPYPOSITIONDETAILS_INIT = "GET_COPYPOSITIONDETAILS_INIT";
export const GET_COPYPOSITIONDETAILS_SUCCESS =
  "GET_COPYPOSITIONDETAILS_SUCCESS";
export const GET_COPYPOSITIONDETAILS_FAIL = "GET_COPYPOSITIONDETAILS_FAIL";

export const CLOSEPOSTIONDETAILS_INIT = "CLOSEPOSTIONDETAILS_INIT";
export const CLOSEPOSTIONDETAILS_SUCCESS = "CLOSEPOSTIONDETAILS_SUCCESS";
export const CLOSEPOSTIONDETAILS_FAIL = "CLOSEPOSTIONDETAILS_FAIL";

export const GET_STRATEGY_FLAG_INIT = "GET_STRATEGY_FLAG_INIT";
export const GET_STRATEGY_FLAG_SUCCESS = "GET_STRATEGY_FLAG_SUCCESS";
export const GET_STRATEGY_FLAG_FAIL = "GET_STRATEGY_FLAG_FAIL";

export const GET_STRATEGY_DETAILS_INIT = "GET_STRATEGY_DETAILS_INIT";
export const GET_STRATEGY_DETAILS_SUCCESS = "GET_STRATEGY_DETAILS_SUCCESS";
export const GET_STRATEGY_DETAILS_FAIL = "GET_STRATEGY_DETAILS_FAIL";

export const EDIT_STRATEGY_DETAILS_INIT = "EDIT_STRATEGY_DETAILS_INIT";
export const EDIT_STRATEGY_DETAILS_SUCCESS = "EDIT_STRATEGY_DETAILS_SUCCESS";
export const EDIT_STRATEGY_DETAILS_FAIL = "EDIT_STRATEGY_DETAILS_FAIL";

export const GET_COPYTRADE_DETAILS_INIT = "GET_COPYTRADE_DETAILS_INIT";
export const GET_COPYTRADE_DETAILS_SUCCESS = "GET_COPYTRADE_DETAILS_SUCCESS";
export const GET_COPYTRADE_DETAILS_FAIL = "GET_COPYTRADE_DETAILS_FAIL";

export const EDIT_COPYTRADE_DETAILS_INIT = "EDIT_COPYTRADE_DETAILS_INIT";
export const EDIT_COPYTRADE_DETAILS_SUCCESS = "EDIT_COPYTRADE_DETAILS_SUCCESS";
export const EDIT_COPYTRADE_DETAILS_FAIL = "EDIT_COPYTRADE_DETAILS_FAIL";

export const ADMIN_PNL_INIT = "ADMIN_PNL_INIT";
export const ADMIN_PNL_SUCCESS = "ADMIN_PNL_SUCCESS";
export const ADMIN_PNL_FAIL = "ADMIN_PNL_FAIL";

export const LIVE_REPORT_DOWNLOAD_INIT = "LIVE_REPORT_DOWNLOAD_INIT";
export const LIVE_REPORT_DOWNLOAD_SUCCESS = "LIVE_REPORT_DOWNLOAD_SUCCESS";
export const LIVE_REPORT_DOWNLOAD_FAIL = "LIVE_REPORT_DOWNLOAD_FAIL";

export const PAPER_REPORT_DOWNLOAD_INIT = "PAPER_REPORT_DOWNLOAD_INIT";
export const PAPER_REPORT_DOWNLOAD_SUCCESS = "PAPER_REPORT_DOWNLOAD_SUCCESS";
export const PAPER_REPORT_DOWNLOAD_FAIL = "PAPER_REPORT_DOWNLOAD_FAIL";

export const ADMIN_PAPER_PNL_INIT = "ADMIN_PAPER_PNL_INIT";
export const ADMIN_PAPER_PNL_SUCCESS = "ADMIN_PAPER_PNL_SUCCESS";
export const ADMIN_PAPER_PNL_FAIL = "ADMIN_PAPER_PNL_FAIL";

export const OPEN_POSITION_DATA_INIT = "OPEN_POSITION_DATA_INIT";
export const OPEN_POSITION_DATA_SUCCESS = "OPEN_POSITION_DATA_SUCCESS";
export const OPEN_POSITION_DATA_FAIL = "OPEN_POSITION_DATA_FAIL";

export const OPEN_PAPER_POSITION_DATA_INIT = "OPEN_PAPER_POSITION_DATA_INIT";
export const OPEN_PAPER_POSITION_DATA_SUCCESS =
  "OPEN_PAPER_POSITION_DATA_SUCCESS";
export const OPEN_PAPER_POSITION_DATA_FAIL = "OPEN_PAPER_POSITION_DATA_FAIL";

export const CLOSE_ALL_ADMIN_POSITION_INIT = "CLOSE_ALL_ADMIN_POSITION_INIT";
export const CLOSE_ALL_ADMIN_POSITION_SUCCESS =
  "CLOSE_ALL_ADMIN_POSITION_SUCCESS";
export const CLOSE_ALL_ADMIN_POSITION_FAIL = "CLOSE_ALL_ADMIN_POSITION_FAIL";

export const CLOSE_ALL_ADMIN_PAPER_POSITION_INIT =
  "CLOSE_ALL_ADMIN_PAPER_POSITION_INIT";
export const CLOSE_ALL_ADMIN_PAPER_POSITION_SUCCESS =
  "CLOSE_ALL_ADMIN_PAPER_POSITION_SUCCESS";
export const CLOSE_ALL_ADMIN_PAPER_POSITION_FAIL =
  "CLOSE_ALL_ADMIN_PAPER_POSITION_FAIL";

export const CLOSE_MIS_POSITION_INIT = "CLOSE_MIS_POSITION_INIT";
export const CLOSE_MIS_POSITION_SUCCESS = "CLOSE_MIS_POSITION_SUCCESS";
export const CLOSE_MIS_POSITION_FAIL = "CLOSE_MIS_POSITION_FAIL";

export const CLOSE_ALL_COPY_ORDER_INIT = "CLOSE_ALL_COPY_ORDER_INIT";
export const CLOSE_ALL_COPY_ORDER_SUCCESS = "CLOSE_ALL_COPY_ORDER_SUCCESS";
export const CLOSE_ALL_COPY_ORDER_FAIL = "CLOSE_ALL_COPY_ORDER_FAIL";

export const GET_POSITIONAL_SIGNAL_INIT = "GET_POSITIONAL_SIGNAL_INIT";
export const GET_POSITIONAL_SIGNAL_SUCCESS = "GET_POSITIONAL_SIGNAL_SUCCESS";
export const GET_POSITIONAL_SIGNAL_FAIL = "GET_POSITIONAL_SIGNAL_FAIL";

export const CLOSE_ALL_POSITION_INIT = "CLOSE_ALL_POSITION_INIT";
export const CLOSE_ALL_POSITION_SUCCESS = "CLOSE_ALL_POSITION_SUCCESS";
export const CLOSE_ALL_POSITION_FAIL = "CLOSE_ALL_POSITION_FAIL";

export const EDIT_SUBBROKERDETAILS_INIT = "EDIT_SUBBROKERDETAILS_INIT";
export const EDIT_SUBBROKERDETAILS_SUCCESS = "EDIT_SUBBROKERDETAILS_SUCCESS";
export const EDIT_SUBBROKERDETAILS_FAIL = "EDIT_SUBBROKERDETAILS_FAIL";

export const CHANGELIMITCOPYTRADE_INIT = "CHANGELIMITCOPYTRADE_INIT";
export const CHANGELIMITCOPYTRADE_SUCCESS = "CHANGELIMITCOPYTRADE_SUCCESS";
export const CHANGELIMITCOPYTRADE_FAIL = "CHANGELIMITCOPYTRADE_FAIL";

export const CANCEL_LIMIT_COPYTRADE_INIT = "CANCEL_LIMIT_COPYTRADE_INIT";
export const CANCEL_LIMIT_COPYTRADE_SUCCESS = "CANCEL_LIMIT_COPYTRADE_SUCCESS";
export const CANCEL_LIMIT_COPYTRADE_FAIL = "CANCEL_LIMIT_COPYTRADE_FAIL";

export const GET_POWER_BROKER_INIT = "GET_POWER_BROKER_INIT";
export const GET_POWER_BROKER_SUCCESS = "GET_POWER_BROKER_SUCCESS";
export const GET_POWER_BROKER_FAIL = "GET_POWER_BROKER_FAIL";

export const EDIT_POWER_BROKER_INIT = "EDIT_POWER_BROKER_INIT";
export const EDIT_POWER_BROKER_SUCCESS = "EDIT_POWER_BROKER_SUCCESS";
export const EDIT_POWER_BROKER_FAIL = "EDIT_POWER_BROKER_FAIL";

export const GET_USER_REFRECODE_INIT = "GET_USER_REFRECODE_INIT";
export const GET_USER_REFRECODE_SUCCESS = "GET_USER_REFRECODE_SUCCESS";
export const GET_USER_REFRECODE_FAIL = "GET_USER_REFRECODE_FAIL";

export const BROKER_NOT_EXIST = "BROKER_NOT_EXIST";
