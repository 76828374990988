import React, { useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";

import Header from "../../Inauth/Header/Header";
import Sidebar from "../../Inauth/Sidebar/Sidebar";
import Liveprice from "../../Inauth/Liveprice/Liveprice";
import Home from "../../Inauth//Home/Home";
import Marketwatch from "../Marketwatch/Marketwatch";
import Brokerlogin from "../../Inauth/Brokerlogin/Brokerlogin";
import Strategy from "../../Inauth/Strategy/Strategy";
import Strategybuilder from "../Strategybuilder/Strategybuilder";
import Reviewdeploy from "../Strategybuilder/Reviewdeploy";
import Trandingstring from "../Trandingstring/Trandingstring";
import Scanner from "../../Inauth/Scanner/Scanner";
import Position from "../../Inauth/Position/Position";
import Orderhistory from "../../Inauth/Orderhistory/Orderhistory";
import Opendemat from "../../Inauth/Opendemat/Opendemat";
import Plreport from "../../Inauth/Plreport/Plreport";
import Planpricing from "../../Inauth/Planpricing/Planpricing";
import Tutorial from "../../Inauth/Tutorial/Tutorial";
import Admin from "../../Inauth/Admin/Admin";
import Contact from "../../Inauth/Contact/Contact";
import Profile from "../../Inauth/Profile/Profile";
import Profilesetting from "../../Inauth/Profilesetting/Profilesetting";
import Settings from "../Settings/Settings";
import Passiveincome from "../Strategy/Passiveincome";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { messageEvent, socket } from "../../hooks/useSocket";
import NotificationSound from ".././../audio/notification_alert.mp3";
import dayjs from "dayjs";
import Status from "../Status/status";

export default function Mainlayout() {
  const [opensidebar, setopensidebar] = React.useState(" ");
  const [layer, setlayer] = React.useState("-100%");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const audioPlayer = useRef(null);

  const liveFeedData = useSelector(
    (State) => State?.CommonReducer?.liveFeedData
  );

  // Sidebar open/close
  const openMenu = () => {
    if (opensidebar === " ") {
      setopensidebar("open");
      setlayer("0");
    } else {
      setopensidebar(" ");
      setlayer("-100%");
    }
  };
  const location = useLocation();

  const socketCallFun = async () => {
    const ws = await socket(
      "liveprice",
      process.env.REACT_APP_LIVE_CUSTOMER_WEBSOCKET_URL
    );
    messageEvent(ws, "liveprice", dispatch);
  };

  useEffect(() => {
    socketCallFun();
    // dispatch(BrokerStatus({}, navigate));
  }, []);

  // audio play function
  // useEffect(() => {
  //   audioPlayer?.current?.play();
  // },[]);

  return (
    <>
      <Header openMenu={openMenu} layer={layer} />
      <audio ref={audioPlayer} src={NotificationSound} />
      <Box sx={{ display: { xs: "block", md: "flex" } }}>
        <Box component={"nav"}>
          <Sidebar openbar={opensidebar} />
        </Box>
        <Box
          component={"section"}
          sx={{
            padding: { xs: "1.5rem", md: "2rem" },
            overflowY: "auto",
            width: { xs: "auto", lg: "100%" },
          }}
        >
          <Liveprice liveFeedData={liveFeedData} />
          <Box sx={{ margin: "0rem 0 4rem" }}>
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="/broker" element={<Brokerlogin />}></Route>
              <Route path="/marketwatch" element={<Marketwatch />}></Route>
              <Route path="/strategy" element={<Strategy />}></Route>
              <Route
                path="/strategybuilder"
                element={<Strategybuilder />}
              ></Route>
              <Route
                path="/trandingstring"
                element={<Trandingstring />}
              ></Route>
              <Route path="/scanner" element={<Scanner />}></Route>
              <Route path="/position" element={<Position />}></Route>
              <Route path="/orderhistory" element={<Orderhistory />}></Route>
              <Route path="/opendemat" element={<Opendemat />}></Route>
              <Route path="/plreport" element={<Plreport />}></Route>
              <Route path="/planpricing" element={<Planpricing />}></Route>
              <Route path="/status" element={<Status />}></Route>
              <Route path="/tutorial" element={<Tutorial />}></Route>
              <Route path="/contact" element={<Contact />}></Route>
              <Route path="/admin" element={<Admin />}></Route>
              <Route path="/profile" element={<Profile />}></Route>
              <Route
                path="/profilesetting"
                element={<Profilesetting />}
              ></Route>
              <Route path="/settings" element={<Settings />}></Route>
              <Route path="/passiveincome" element={<Passiveincome />}></Route>
              <Route path="/reviewdeploy" element={<Reviewdeploy />}></Route>
            </Routes>
          </Box>
          <Box className="footer">
            <Typography component={"p"}>
              {" "}
              Copyright ©{dayjs().year()} All rights reserved by @ DEMATADE ALGO
              TECHNOLOGY SOLUTIONS PVT LTD
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
