import React, { useState } from 'react';
import { Table, TableBody, Box, TableCell, TableContainer, TablePagination, TableHead, TableRow, TableSortLabel } from '@mui/material';
import '../Table/Table.scss';

export default function Dashboard(props) {
  // Table pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [orderBy, setOrderBy] = useState(0);
  const [order, setOrder] = useState('asc');

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedRows = props.rows.sort((a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];
    if (order === 'asc') {
      return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
    } else {
      return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
    }
  });

  return (
    <>
      <TableContainer sx={{ MarginTop: 16, maxHeight: 440 }}>
        <Table stickyHeader sx={{ minWidth: '100%' }} className={`table tableData ${props.small}`}>
          <TableHead>
            <TableRow>
              {(props.col).map((colum, index) => (
                <TableCell key={index}>
                  <TableSortLabel
                    active={orderBy === colum}
                    direction={orderBy === colum ? order : 'asc'}
                    onClick={() => handleSort(colum)}
                  >
                    {colum}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0 ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : sortedRows).map((row, index) => (
              <TableRow key={index} >
                {Object.keys(row).map((element, index) =>
                  <TableCell key={index} >
                    {row[element]}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>


      </TableContainer >
      <Box className='tablePagination' sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'end' }, padding: '0 1rem' }}>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, { label: 'All', value: -1 }]}
          count={props.rows.length}
          rowsPerPage={rowsPerPage}
          SelectProps={{
            fontSize: '1.6rem',
            native: false,
          }}
          page={page}
          className='tablePagination'
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  )
}
