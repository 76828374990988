import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  FormControlLabel,
  Radio,
  FormControl,
  Autocomplete,
  RadioGroup,
} from "@mui/material";
import Dropdown from "../../../Dropdown/Dropdown";
import "../../../Scanner/Scanner.scss";
import "../../../Tablesearch/Tablesearch.scss";
import "../../../Tablesearch/Tablesearch.scss";
import { useDispatch, useSelector } from "react-redux";
import SignalScannerView from "./SignalScannerView";
import {
  copyPostionDetails,
  copyTradeDetails,
  getSegment,
} from "../../../../redux/actions/positionAction";
import { generatePopup } from "../../../../utils/popup";
import { messageEvent, socket } from "../../../../hooks/useSocket";
import { addcopytrade } from "../../../../redux/actions/adminActions";
import {
  getSegmentData,
  getSignalAllData,
  getStrategies,
  getStratergy,
  getSymbolData,
  sendTradeSignal,
} from "../../../../redux/actions/stratergyAction";
import dayjs from "dayjs";
import { toast } from "react-toastify";

const drpValue1 = ["Buy", "Sell"];
const drpValue2 = ["CE", "PE", "XX", "AA"];
const drpValue3 = ["Entry", "Exit"];

export default function SignalScanner() {
  const [stratergies, setStratergies] = useState("");
  const [segment, setSegment] = useState("");
  const [tradeType, setTradeType] = useState("");
  const [symbol, setSymbol] = useState("");
  const [signalType, setSignalType] = useState("");
  const [trend, setTrend] = useState("");
  const [optionType, setOptionType] = useState("");
  const [makeBuysell, setMakeBuysell] = useState("#24a959");
  const [Bs, setBs] = React.useState(true);
  const [strikeSearch, setStrikeSearch] = useState("");
  const [radio, setRadio] = useState("Market");
  const [strategy, setStrategy] = useState("select");
  const [load, setLoad] = useState(false);
  const [price, setPrice] = useState("");
  const [fields, setFields] = useState("");

  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.Auth?.authProfile?.[0]);

  useEffect(() => {
    dispatch(getSegment());
  }, []);

  // const segmentList = useSelector((state) => state?.Position?.segmentList) || [];

  // useEffect(() => {
  //   let ws = null;
  //   const getWatchListLiveSocket = async () => {
  //     ws = await socket("watchlistupdate");

  //     messageEvent(ws, "watchlistupdate", dispatch);
  //   };
  //   getWatchListLiveSocket();

  //   window.onbeforeunload = async function (event) {
  //     return await ws?.send("disconnect");
  //   };
  //   return () => {
  //     if (ws && ws.readyState === WebSocket.OPEN) {
  //       ws?.send("disconnect");
  //     }
  //   };
  // }, []);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFields((prevState) => ({ ...prevState, [name]: value }));
  // };

  // useEffect(() => {
  //   let ws1 = null;
  //   const getTokenPriceSocket = async () => {
  //     ws1 = await socket("tokenprice");

  //     messageEvent(ws1, "tokenprice", dispatch, { token: strikeSearch?.token });
  //   };
  //   getTokenPriceSocket();

  //   window.onbeforeunload = async function (event) {
  //     if (ws1) {
  //       return await ws1?.send("disconnect");
  //     }
  //   };

  //   return () => {
  //     ws1?.send("disconnect");
  //   };
  // }, [strikeSearch]);

  // const tokenPrice = useSelector((state) => state?.CommonReducer?.tokenPrice);

  // useEffect(() => {
  //   if (radio?.toLowerCase() === "market") {
  //     setPrice(tokenPrice?.liveprice);
  //   }
  // }, [tokenPrice?.liveprice, radio]);

  const handleStratergy = (event) => {
    setStratergies(event.target.value);
  };

  const handleSegment = (event) => {
    setSegment(event.target.value);
  };
  const handleSymbol = (event) => {
    setSymbol(event.target.value);
  };

  const handleSignalType = (event) => {
    setSignalType(event.target.value);
  };
  const handleTrend = (event) => {
    setTrend(event.target.value);
  };
  const handleOptionType = (event) => {
    setOptionType(event.target.value);
  };
  const handleTradeType = (event) => {
    setTradeType(event.target.value);
  };

  const handleChange = (e) => {
    console.log("e", e.target.value);
    const { name, value } = e.target;
    setFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleReset = () => {
    // setStrikeSearch({
    //   symbol: "",
    //   expiry: "",
    //   strikeprice: "",
    //   option: "",
    // });
    setSignalType();
    setTrend();
    setOptionType();
    setSegment("");
    // setBs(true);
    // setRadio("Market");
    // setPrice("");
    setFields({
      strikeprice: "",
    });
    setSymbol("");
    setStratergies("");
    setTradeType("");
  };

  // const handleBuy = () => {
  //   if (!strikeSearch?.symbol) {
  //     return generatePopup("error", "Symbol is Required");
  //   } else if (!strikeSearch?.expiry) {
  //     return generatePopup("error", "Expiry is Required");
  //   } else if (!radio) {
  //     return generatePopup("error", "Please Select Order Type");
  //   }
  //   const obj = {
  //     segment: segment,
  //     symbol: fields?.symbol,
  //     entry: fields?.entry,
  //     exit: fields?.exit,
  //     target: fields?.target,
  //     strategy: "",
  //     tradingBridge: "",
  //     strategyCode: "",
  //     tradeType: "",
  //     signalType: signalType,
  //     signalDateTime: dayjs().format("DD/MM/YYYY"),
  //     trend: trend,
  //     expiry: fields?.expiry,
  //     strikePrice: "",
  //     optionType: optionType,
  //   };

  //   console.log("obj", obj);
  //   const obj = {
  //     segment: segment.toUpperCase(),
  //     symbol: strikeSearch?.symbol,
  //     side: Bs ? "BUY" : "SELL",
  //     expiry: strikeSearch?.expiry,
  //     strikeprice: String(parseInt(strikeSearch?.strikeprice)),
  //     tag: strikeSearch?.option,
  //     prctyp: radio?.toUpperCase(),
  //     order_type: radio?.toUpperCase(),
  //     prc: price ? price : "0",
  //   };
  // };

  const AutoCompleteBox = (props) => {
    const { children } = props;
    return (
      <>
        <Box
          className="formBox"
          sx={{
            width: { xs: "100% !important", md: "auto !important" },
          }}
        >
          <Box className="formItems">
            <Box className="selectionDiv " sx={{ padding: "0 !important" }}>
              <Box
                className="dropdown-ap "
                sx={{
                  height: "auto !important",
                  paddingLeft: "10px",
                  marginBottom: "1rem",
                }}
              >
                {children}
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  useEffect(() => {
    dispatch(getStrategies({ owner: userData?.username }));
  }, [userData]);

  useEffect(() => {
    dispatch(
      getSegmentData({
        owner: userData?.username,
        stratergy: stratergies?.toUpperCase(),
      })
    );
  }, [stratergies]);

  useEffect(() => {
    dispatch(
      getSymbolData({
        owner: userData?.username,
        stratergy: stratergies?.toUpperCase(),
        segment: segment?.toUpperCase(),
      })
    );
  }, [stratergies, segment]);

  useEffect(() => {
    dispatch(
      getSignalAllData({
        owner: userData?.username,
        stratergy: stratergies?.toUpperCase(),
        segment: segment?.toUpperCase(),
        symbol: symbol?.toUpperCase(),
      })
    );
  }, [stratergies, segment, symbol]);

  // const stratergy = useSelector((state) => state?.Stratergy?.stratergy);
  const stratergy = useSelector(
    (state) => state?.Stratergy?.strategies?.dataset
  );
  const getsegmentdata = useSelector(
    (state) => state?.Stratergy?.segmentdata?.dataset
  );
  const getsymboldata = useSelector(
    (state) => state?.Stratergy?.symboldata?.dataset
  );
  const getsignalalldata = useSelector(
    (state) => state?.Stratergy?.singlealldata
  );

  const URL = getsignalalldata?.[0]?.url;
  const dateTime = dayjs().format("MM/DD/YYYY hh:mm:ss A");
  const date = dayjs().format("YYYY-MM-DD");

  const handleSend = () => {
    if (
      segment === "" ||
      symbol === "" ||
      stratergies === "" ||
      fields?.strikeprice === "" ||
      signalType === "" ||
      trend === "" ||
      optionType === "" ||
      tradeType === ""
    ) {
      toast.info("All Fields are require");
    } else {
      const payload = `${segment.toUpperCase()}|${symbol?.toUpperCase()}|0|0|0|${stratergies?.toUpperCase()}|${
        getsignalalldata?.[0]?.tardingbridge
      }|${getsignalalldata?.[0]?.stratergyode}|${
        (tradeType === "intraday" && "Intraday") ||
        (tradeType === "normal" && "Normal")
      }|${
        (signalType === "entry" && "Entry") || (signalType === "exit" && "Exit")
      }|${dateTime}|${trend?.toUpperCase()}|${
        segment === "nsecash" ? date : getsignalalldata?.[0]?.expiry
      }|${fields?.strikeprice}|${optionType?.toUpperCase()}|1|`;

      dispatch(sendTradeSignal(URL, payload)).then((res) => {
        if (res?.status === 200) {
          toast.success("Signal Send Successfully");
          handleReset();
        } else {
          toast.error("Signal not Send");
        }
      });
    }
  };

  return (
    <>
      <Box
        className="border-ap"
        sx={{ margin: 1, maxWidth: { xs: "100%", lg: 900 } }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            margin: 0,
            width: "100%",
            backgroundColor: makeBuysell,
            paddingBottom: 2,
          }}
          className="border-apb"
        >
          <Grid item xs={6} md={6}>
            <Typography
              sx={{ fontSize: 20, color: "#FFFFFF", fontWeight: 600 }}
            >
              Trade Signal Scanner
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ marginTop: 1, paddingRight: 2 }}>
          <Grid item xs={12}>
            <Box
              className="selectiondiv-box"
              sx={{ marginTop: "0 !important" }}
            >
              <Box className="adminCtradeBox">
                <Box className="adminCtradeBoxItem">
                  <Dropdown
                    title={"Stratergy"}
                    val={stratergy}
                    handleSelect={handleStratergy}
                    value={stratergies}
                  />
                </Box>
                <Box className="adminCtradeBoxItem">
                  <Dropdown
                    title={"Segment"}
                    val={getsegmentdata}
                    handleSelect={handleSegment}
                    value={segment}
                  />
                </Box>
                <Box className="adminCtradeBoxItem">
                  <Dropdown
                    disabled={true}
                    title={"Symbol"}
                    val={getsymboldata}
                    handleSelect={handleSymbol}
                    value={symbol}
                  />
                </Box>
                <Box className="adminCtradeBoxItem">
                  <Box
                    className="selectiondiv-box"
                    sx={{
                      marginTop: "0 !important",
                      justifyContent: "left !important",
                    }}
                  >
                    <Box className="inputFields space fullWidth">
                      <TextField
                        // disabled
                        name="strikeprice"
                        placeholder="strike price"
                        sx={{
                          border: "1px solid #0a53b5",
                          borderRadius: "5px",
                        }}
                        type="text"
                        value={fields?.strikeprice}
                        onChange={handleChange}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className="adminCtradeBoxItem">
                  <Dropdown
                    title={"Signal Type"}
                    val={drpValue3}
                    handleSelect={handleSignalType}
                    value={signalType}
                  />
                </Box>
                <Box className="adminCtradeBoxItem">
                  <Dropdown
                    title={"Trend"}
                    val={drpValue1}
                    handleSelect={handleTrend}
                    value={trend}
                  />
                </Box>
                <Box className="adminCtradeBoxItem">
                  <Dropdown
                    title={"Select Option type"}
                    val={drpValue2}
                    handleSelect={handleOptionType}
                    value={optionType}
                  />
                </Box>
                <Box className="adminCtradeBoxItem">
                  <Dropdown
                    title={"Trade Type"}
                    val={["Intraday", "Normal"]}
                    handleSelect={handleTradeType}
                    value={tradeType}
                  />
                </Box>
                {/* <Box
                  className="adminCtradeBoxItem"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    className="selectiondiv-box"
                    sx={{
                      marginTop: "0 !important",
                      justifyContent: "left !important",
                    }}
                  >
                    <Box className="inputFields space fullWidth">
                      <TextField
                        // disabled
                        placeholder="Enter Symbol Name"
                        sx={{
                          border: "1px solid #0a53b5",
                          borderRadius: "5px",
                        }}
                        onChange={handleChange}
                        type="text"
                        name="symbol"
                        value={fields?.symbol}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  className="adminCtradeBoxItem"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    className="selectiondiv-box"
                    sx={{
                      marginTop: "0 !important",
                      justifyContent: "left !important",
                    }}
                  >
                    <Box className="inputFields space fullWidth">
                      <TextField
                        placeholder="Entry Stop loss"
                        onChange={handleChange}
                        sx={{
                          border: "1px solid #0a53b5",
                          borderRadius: "5px",
                        }}
                        type="text"
                        name="entry"
                        value={fields?.entry}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  className="adminCtradeBoxItem"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    className="selectiondiv-box"
                    sx={{
                      marginTop: "0 !important",
                      justifyContent: "left !important",
                    }}
                  >
                    <Box className="inputFields space fullWidth">
                      <TextField
                        placeholder="Exit Stop loss"
                        onChange={handleChange}
                        sx={{
                          border: "1px solid #0a53b5",
                          borderRadius: "5px",
                        }}
                        type="text"
                        name="exit"
                        value={fields?.exit}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  className="adminCtradeBoxItem"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    className="selectiondiv-box"
                    sx={{
                      marginTop: "0 !important",
                      justifyContent: "left !important",
                    }}
                  >
                    <Box className="inputFields space fullWidth">
                      <TextField
                        placeholder="Entry Target"
                        onChange={handleChange}
                        sx={{
                          border: "1px solid #0a53b5",
                          borderRadius: "5px",
                        }}
                        type="text"
                        name="target"
                        value={fields?.target}
                      />
                    </Box>
                  </Box>
                </Box> */}

                {/* <AutoCompleteBox>
                  <Autocomplete
                    options={stratergyList}
                    value={fields?.strategy}
                    onChange={(event, newValue) => {
                      handleAutocomplete("strategy", newValue);
                    }}
                    className="dropdown segmentSearch"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Enter Strategy Name"
                      />
                    )}
                  />
                </AutoCompleteBox> */}

                {/* {!["NSECASH"].includes(segment.toUpperCase()) && (
                  <Box className="adminCtradeBoxItem">
                    <Box
                      className="selectiondiv-box"
                      sx={{
                        marginTop: "0 !important",
                        justifyContent: "left !important",
                      }}
                    >
                      <Box className="inputFields space fullWidth">
                        <TextField
                          // disabled
                          placeholder="expiry"
                          sx={{
                            border: "1px solid #0a53b5",
                            borderRadius: "5px",
                          }}
                          name="expiry"
                          onChange={handleChange}
                          type="text"
                          value={fields?.expiry}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
                {![
                  "NSECASH",
                  "SENSEXFUT",
                  "FINNIFTYFUT",
                  "MIDCPNIFTYFUT",
                  "BANKNIFTYFUT",
                  "STOCKFUT",
                  "CURFUT",
                  "MCXFUT",
                ].includes(segment.toUpperCase()) && (
                    <Box className="adminCtradeBoxItem">
                      <Box
                        className="selectiondiv-box"
                        sx={{
                          marginTop: "0 !important",
                          justifyContent: "left !important",
                        }}
                      >
                        <Box className="inputFields space fullWidth">
                          <TextField
                            // disabled
                            placeholder="strike price"
                            sx={{
                              border: "1px solid #0a53b5",
                              borderRadius: "5px",
                            }}
                            type="text"
                            value={strikeSearch?.strikeprice}
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}

                <Box className="adminCtradeBoxItem">
                  <Dropdown
                    title={"Signal Type"}
                    val={drpValue3}
                    handleSelect={handleSignalType}
                    value={signalType}
                  />
                </Box>
                <Box className="adminCtradeBoxItem">
                  <Dropdown
                    title={"Trend"}
                    val={drpValue1}
                    handleSelect={handleTrend}
                    value={trend}
                  />
                </Box>
                <Box className="adminCtradeBoxItem">
                  <Dropdown
                    title={"Select Option type"}
                    val={drpValue2}
                    handleSelect={handleOptionType}
                    value={optionType}
                  />
                </Box> */}
              </Box>
            </Box>

            <Grid
              container
              spacing={2}
              sx={{ margin: 0, marginBottom: 2, width: "100%" }}
            >
              {/* <Grid item xs={12} lg={6} md={5}>
                <Box
                  className="selectiondiv-box"
                  sx={{
                    marginTop: "0 !important",
                    justifyContent: "left !important",
                  }}
                >
                  <Box className="inputFields space fullWidth">
                    <Typography component={"label"} className="label">
                      Trigger Price
                    </Typography>
                    <TextField
                      type="number"
                      placeholder="Trigger price"
                      name="priceID"
                    />
                  </Box>
                </Box>
              </Grid> */}
              <Grid item xs={12} md={12} sx={{ marginTop: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Button
                    disabled={load}
                    onClick={handleSend}
                    className="formSolid-btn green"
                  >
                    Send
                  </Button>
                  <Button
                    onClick={handleReset}
                    className="solidButton fetchReport-btn"
                    sx={{
                      width: "auto !important",
                      maxWidth: "auto !important",
                    }}
                  >
                    Reset
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {/* <Box sx={{ marginTop: 3, padding: "0 1rem" }}>
        <SignalScannerView opHeaderNone="none" btnone="bt-none" tabType={""} />
      </Box> */}
    </>
  );
}
