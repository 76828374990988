import React, { useState } from "react";
import {
  Box,
  Tab,
  Grid,
  Typography,
  MenuItem,
  Tooltip,
  Select,
  RadioGroup,
  Radio,
  Pagination,
  FormControlLabel,
  FormControl,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  List,
  ListItem,
  TableCell,
  TableRow,
  ListItemText,
  Checkbox,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import close from "../../images/close.png";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";
import noData from "../../images/page_456.jpg";
import "./Strategy.scss";
import { NavLink, Navigate, generatePath, useNavigate } from "react-router-dom";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  activateStrategy,
  addUserStrategy,
  deactivateStrategy,
  getStratergy,
  updateStrategy,
  userAddStratergy,
} from "../../redux/actions/stratergyAction";
import {
  closeOrder,
  closeStrategy,
  copytradeClose,
  getSegment,
  getStrategyFlag,
} from "../../redux/actions/positionAction";
import dayjs from "dayjs";
import { generatePopup } from "../../utils/popup";
import {
  editSegmentCopytrade,
  getSymbolList,
  updateUserStrategy,
} from "../../redux/actions/authActions";
import { messageEvent, socket } from "../../hooks/useSocket";
import { toast } from "react-toastify";

export default function Strategy(props) {
  const [value, setValue] = useState("maintab1");
  const [open, setOpen] = useState(false);
  const [selectedStrategy, setSelectedStrategy] = useState({});
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [tradetypeshand, settradetypeshand] = useState("Select");
  const [exchanges, setexchanges] = useState("Select");
  const [segments, setsegments] = useState("Select");
  const [order, setorder] = useState("Select");
  const [tardingtype, settardingtype] = useState("Select");
  const [filertSidebar, setfilertSidebar] = useState("-100%");
  const [strategySelected, setStrategySelected] = useState("All");
  const [tagSelected, setTagSelected] = useState("All");
  const [lotSize, setLotSize] = useState("");
  const [state, setState] = useState("");
  const [page, setPage] = useState(1);
  const [symbol, setSymbol] = useState([]);
  const [marketPage, setMarketPage] = useState(1);
  const [status, setStatus] = useState("");
  const [data, setData] = useState("");
  const [statusOpen, setStatusOpen] = useState(false);
  const [segmentData, setSegmentData] = useState([]);
  const [id, setId] = useState("");

  // Dropdown
  const tradetypes = ["Select", "Paper", "Live"];
  const segment = ["Select", "Select All", "NSE", "NFO", "MCX ", "CDS"];
  const ordertype = ["Select", "MIS", "NRML", "LIMIT"];
  const tradingype = ["Select", "Intraday", "Positional"];
  const exchange = ["Select", "NFO", "NSE"];
  const tradetype = ["Trade Type", "Indtraday"];

  const navigate = useNavigate()

  const setFilterHnadler = () => {
    if (filertSidebar === "-100%") {
      setfilertSidebar(0);
    } else {
      setfilertSidebar("-100%");
    }
  };
  const handleChange = (e, newValue = "string") => {
    setValue(newValue);
    setStrategySelected("All");
  };

  useEffect(() => {
    if (value === "maintab2") {
      dispatch(userAddStratergy());
    }
  }, [value]);

  const handleChangeValue = (name, value, id) => {
    dispatch(
      updateStrategy({
        name,
        value,
        id,
      })
    );
  };

  const handleClickOpen = (e) => {
    setSelectedStrategy(e);
    setOpen(true);
  };

  const handleEditOpen = (e) => {
    setSegmentData(e?.symbol);
    setState(e);
    dispatch(
      getSymbolList({
        segment: e?.segment,
      })
    );
  };

  useEffect(() => {
    dispatch(getStrategyFlag());
  }, []);

  const strategyFlags = useSelector((state) => state?.Position?.strategyFlag);

  const handleEdit = (e) => {
    const data = strategyData?.filter((e) => e?.id === state?.id)?.[0];

    dispatch(
      updateUserStrategy({
        id: data?.id,
        segment: data?.segment,
        lot: data?.lot,
        tradetype: data?.trade_type,
        tradingtype: data?.trading_type,
        symbol: data?.symbol,
        exchange: data?.exchange,
        order_type: data?.order_type,
      })
    ).then((res) => {
      if (res) {
        setState({});
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedStrategy({});
    settradetypeshand("Select");
    setsegments("Select");
  };
  const handleDeploy = () => {
    if (tradetypeshand === "Live") {
      if (!userData?.is_subscribe) {
        toast.error("Your subscription is not active. Please subscribe to continue.")
        return navigate("/planpricing")
      }
    }
    if (tradetypeshand === "Paper") {
      if (!userData?.is_subscribe && !userData?.is_demo) {
        toast.error("Your subscription is not active. Please subscribe to continue.")
        return navigate("/planpricing")
      }
    }
    const obj = {
      id: selectedStrategy?.id,
      trade_type: tradetypeshand,
      segment: segments,
      lot: lotSize,
      order_type: order,
      exchange: exchanges,
      trading_type: tardingtype,
      symbol: symbol || [],
    };

    dispatch(addUserStrategy(obj)).then((res) => {
      if (res?.data?.status) {
        generatePopup("success", res?.data?.message);
        setOpen(false);
        setSelectedStrategy({});
        settradetypeshand("Select");
        setsegments("Select");
        settardingtype("Select");
        setLotSize("");
        setSymbol([]);
        setorder("Select");
      } else {
        generatePopup("error", res?.data?.message);
        setLotSize("");
        setOpen(false);
        setSelectedStrategy({});
        settradetypeshand("Select");
        setsegments("Select");
        settardingtype("Select");
        setSymbol([]);
        setorder("Select");
      }
    });
  };

  const handleClickOpen3 = () => {
    setOpen3(true);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };

  const handleClickOpen4 = () => {
    setOpen4(true);
  };
  const handleClose4 = () => {
    setOpen4(false);
  };

  // Dropdown tab
  const tradetypesdrp = (event) => {
    settradetypeshand(event.target.value);
  };
  const segmentdrp = (event) => {
    setsegments(event.target.value);
  };
  const exchangedrp = (event) => {
    setexchanges(event.target.value);
  };
  const ordertypedrp = (event) => {
    setorder(event.target.value);
  };
  const tradingtypedrp = (event) => {
    settardingtype(event.target.value);
  };

  const handleClickOpen2 = (e) => {
    setId(e?.id);
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClose5 = () => {
    setStatusOpen(false);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSegment());
  }, []);

  const handleChangeSegement = (event) => {
    const {
      target: { value },
    } = event;
    setSegmentData(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const strategyData = useSelector((state) => state?.Stratergy?.userstratergy);

  const MyStrategies =
    strategySelected === "All"
      ? strategyData
      : strategyData?.filter(
        (e) => e?.segment?.includes(strategySelected) && e
      );

  useEffect(() => {
    dispatch(getStratergy());
  }, []);

  const segmentList = useSelector((state) => state?.Position?.segmentList);

  const strategiesList = useSelector((state) => state?.Stratergy?.stratergy);

  const strategies =
    strategySelected === "All" && tagSelected === "All"
      ? strategiesList
      : strategySelected === "All" && tagSelected !== "All"
        ? strategiesList?.filter((e) => e?.tag?.includes(tagSelected) && e)
        : tagSelected === "All" && strategySelected !== "All"
          ? strategiesList?.filter(
            (e) => e?.segment?.includes(strategySelected) && e
          )
          : strategiesList?.filter(
            (e) =>
              e?.segment?.includes(strategySelected) &&
              e?.tag?.includes(tagSelected) &&
              e
          );

  const drpValue3 = ["Live", "Paper"];

  let list = [
    "NFO",
    "Intraday",
    "EarnTheta",
    "Directional",
    "Bullish",
    "Bearish",
    "Trendfollowing",
    "Momentum",
    "EventBased",
  ];

  useEffect(() => {
    setSymbol([]);
    if (segments || segments !== "Select") {
      dispatch(getSymbolList({ segment: segments }));
    }
  }, [segments]);

  const symbolList = useSelector((state) => state.Auth?.symbolList?.symbol);
  const userData = useSelector((state) => state.Auth?.authProfile?.[0]);

  const handleChangeSymbol = (event) => {
    const {
      target: { value },
    } = event;
    setSymbol(typeof value === "string" ? value.split(",") : value);
  };

  const handleActivate = (event, e) => {
    const obj = {
      id: e?.id,
    };
    if (event?.target?.value === "false") {
      dispatch(deactivateStrategy(obj)).then((res) => {
        if (res) {
          setStatusOpen(false);
          dispatch(userAddStratergy());
        }
      });
    } else {
      dispatch(activateStrategy(obj)).then((res) => {
        if (res) {
          setStatusOpen(false);
          dispatch(userAddStratergy());
        }
      });
    }
  };

  const squareOff = () => {
    const obj = {
      id: Number(id),
    };
    dispatch(closeStrategy(obj)).then((res) => {
      if (res) {
        setOpen2(false);
        // dispatch(userAddStratergy());
      }
    });
  };

  let ws = null;
  const socketCallFun = async (channel) => {
    ws = await socket(
      channel,
      process.env.REACT_APP_LIVE_CUSTOMER_WEBSOCKET_URL
    );
    messageEvent(ws, channel, dispatch, { remark: "heartbeat" });
  };

  useEffect(() => {
    socketCallFun("stratergypnl");
    window.onbeforeunload = async function (event) {
      return await ws?.send({ remark: "disconnect" });
    };
    return () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws?.send({ remark: "disconnect" });
      }
    };
  }, []);

  const pnlList = useSelector((state) => state?.Stratergy?.pnl);

  return (
    <>
      <Box
        className="tabs"
        sx={{
          maxWidth: { xs: "auto", lg: "1200px" },
          margin: "0 auto",
        }}
      >
        <TabContext value={value}>
          <Box
            sx={{
              display: { xs: "block", lg: "flex" },
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TabList className="main-tab" onChange={handleChange}>
              <Tab label="Marketplace" value="maintab1" />
              <Tab label="My Strategies" value="maintab2" />
            </TabList>
            {value == "maintab2" && (
              <Box
                sx={{
                  display: {
                    xs: "inline-flex",
                    md: "inline-flex",
                    flexWrap: "wrap",
                  },
                  alignItems: "center",
                }}
              >
                <Box className="inputFields" sx={{ width: "auto !important" }}>
                  <TextField placeholder="Search Strategy" />
                  <Box className="searchIcon">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.85722 14.7144C11.6444 14.7144 14.7144 11.6444 14.7144 7.85722C14.7144 4.07008 11.6444 1 7.85722 1C4.07008 1 1 4.07008 1 7.85722C1 11.6444 4.07008 14.7144 7.85722 14.7144Z"
                        stroke="#6A6D78"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13 13.0001L17 17.0002"
                        stroke="#6A6D78"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Box>
                </Box>
                <Button
                  className="solidButton refresh-btn filter_strategy"
                  sx={{ margin: 0.5 }}
                  onClick={setFilterHnadler}
                >
                  <FilterListRoundedIcon />
                </Button>
                <Button
                  className="squareOff-btn solidButton"
                  sx={{
                    width: { xs: "100% !important", lg: "auto !important" },
                    fontSize: "1.2rem",
                    margin: { xs: 1, md: 1 },
                  }}
                  onClick={handleClickOpen3}
                >
                  SquareOff All
                </Button>
              </Box>
            )}

            {value == "maintab1" && (
              <>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Button
                    className="solidButton refresh-btn filter_strategy"
                    onClick={setFilterHnadler}
                  >
                    <FilterListRoundedIcon />
                  </Button>
                  <Box
                    className="inputFields"
                    sx={{ width: "auto !important", marginLeft: 2 }}
                  >
                    <TextField placeholder="Search Marketplace" />
                    <Box className="searchIcon">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.85722 14.7144C11.6444 14.7144 14.7144 11.6444 14.7144 7.85722C14.7144 4.07008 11.6444 1 7.85722 1C4.07008 1 1 4.07008 1 7.85722C1 11.6444 4.07008 14.7144 7.85722 14.7144Z"
                          stroke="#6A6D78"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M13 13.0001L17 17.0002"
                          stroke="#6A6D78"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Box>

          <TabPanel value="maintab1" sx={{ padding: 0, marginTop: "0.5rem" }}>
            {strategiesList?.length > 0 ? (
              <Box sx={{ display: "flex", alignItems: "start" }}>
                <Box className="strategy_list_box" sx={{ left: filertSidebar }}>
                  {/* <Box className="inputFields">
                      <TextField
                        placeholder="Search Startegy..."
                        fullWidth
                        sx={{
                          "& > .MuiInputBase-root > .MuiInputBase-input": {
                            maxWidth: "100% !important",
                          },
                        }}
                      />
                      <Box className="searchIcon">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.85722 14.7144C11.6444 14.7144 14.7144 11.6444 14.7144 7.85722C14.7144 4.07008 11.6444 1 7.85722 1C4.07008 1 1 4.07008 1 7.85722C1 11.6444 4.07008 14.7144 7.85722 14.7144Z"
                            stroke="#6A6D78"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13 13.0001L17 17.0002"
                            stroke="#6A6D78"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Box>
                    </Box> */}

                  <Box
                    className="strategy_list"
                    sx={{ overflowY: "auto", maxHeight: "60vh" }}
                  >
                    <Typography component={"h4"}>Segment Wise</Typography>
                    <>
                      <List className="list">
                        <ListItem
                          className={"All" == strategySelected ? "active" : ""}
                          onClick={() => {
                            setMarketPage(1);
                            setStrategySelected("All");
                          }}
                        >
                          All
                        </ListItem>
                        {segmentList?.map((e) => (
                          <ListItem
                            className={e == strategySelected ? "active" : ""}
                            onClick={() => {
                              setMarketPage(1);
                              setStrategySelected(e);
                            }}
                          >
                            {e}
                          </ListItem>
                        ))}
                      </List>
                    </>

                    <>
                      <Typography component={"h4"}>Tags List</Typography>
                      <List className="list">
                        <ListItem
                          className={"All" === tagSelected ? "active" : ""}
                          onClick={() => setTagSelected("All")}
                        >
                          All
                        </ListItem>
                        {list.map((d) => (
                          <ListItem
                            className={d === tagSelected ? "active" : ""}
                            onClick={() => setTagSelected(d)}
                          >
                            {d}
                          </ListItem>
                        ))}
                      </List>
                    </>
                  </Box>

                  <Box
                    sx={{
                      padding: "1.5rem 1rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      className="solidButton fetchReport-btn"
                      sx={{ width: "100% !important" }}
                    >
                      Reset
                    </Button>
                    <Button
                      className="solidButton download-btn filter_strategy"
                      onClick={setFilterHnadler}
                      sx={{ marginLeft: 1 }}
                    >
                      <KeyboardArrowLeftRoundedIcon />
                    </Button>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: { xs: "100%", lg: "calc(100% - 33rem)" },
                    margin: "0 auto",
                    height: "68vh",
                    overflowY: "auto",
                    paddingRight: 1.5,
                  }}
                  className="withOutScrollbar"
                >
                  <Grid
                    container
                    spacing={2}
                    sx={{ justifyContent: { xs: "center", lg: "center" } }}
                  >
                    {strategies?.length > 0 ? (
                      <>
                        {strategies
                          ?.slice(
                            (marketPage - 1) * 3,
                            (marketPage - 1) * 3 + 3
                          )
                          ?.map((e) => (
                            <Grid item xs={12}>
                              <Box className="strategyCard">
                                <Box className="strategyCard-header">
                                  <Box className="strategyCard-title">
                                    <Typography
                                      component={"p"}
                                      className="title"
                                    >
                                      {e?.name}
                                    </Typography>
                                    <Typography
                                      component={"p"}
                                      className="days"
                                    >
                                      Created{" "}
                                      {dayjs().diff(e?.created_date, "days") ===
                                        0
                                        ? "Today"
                                        : dayjs().diff(
                                          e?.created_date,
                                          "days"
                                        ) + " days ago"}
                                      <Typography component={"span"}>
                                        by {e?.owner}
                                      </Typography>
                                    </Typography>
                                  </Box>
                                  {/* <Button className='copyShare-link'><ShareIcon /></Button> */}
                                </Box>
                                <Box className="strategyCard-body">
                                  <NavLink
                                    to={"/passiveincome"}
                                    state={{ data: e, name: "marketplace" }}
                                    className="read_more"
                                  >
                                    Read More...
                                  </NavLink>
                                  <Box className="strategy_badges">
                                    {e?.tag?.map((d) => (
                                      <Typography
                                        component={"p"}
                                        className="badges"
                                      >
                                        {d}
                                      </Typography>
                                    ))}
                                    {/* <Typography component={"p"} className="badges">
                              Intraday
                            </Typography>
                            <Typography component={"p"} className="badges">
                              EarnTheta
                            </Typography>
                            <Typography component={"p"} className="badges">
                              Directional
                            </Typography>
                            <Typography component={"p"} className="badges">
                              Bullish
                            </Typography>
                            <Typography component={"p"} className="badges">
                              Bearish
                            </Typography>
                            <Typography component={"p"} className="badges">
                              Trendfollowing
                            </Typography>
                            <Typography component={"p"} className="badges">
                              Momentum
                            </Typography>
                            <Typography component={"p"} className="badges">
                              EventBased
                            </Typography> */}
                                  </Box>
                                  {/* <Box className="trade_progress">
                          <Box className="trade_progress_item">
                            <Typography component={"p"}>
                              Tades/~Costs
                              <Tooltip
                                placement="top"
                                title={
                                  <Typography sx={{ fontSize: "1.3rem" }}>
                                    Test
                                  </Typography>
                                }
                              >
                                <ErrorIcon sx={{ marginLeft: 0.5 }} />
                              </Tooltip>
                            </Typography>
                            <Typography component={"h4"}>
                              ₹ 244(4.9 K)
                            </Typography>
                          </Box>
                          <Box className="trade_progress_item">
                            <Typography component={"p"}>
                              Drowdown
                              <Tooltip
                                placement="top"
                                title={
                                  <Typography sx={{ fontSize: "1.3rem" }}>
                                    Test
                                  </Typography>
                                }
                              >
                                <ErrorIcon sx={{ marginLeft: 0.5 }} />
                              </Tooltip>
                            </Typography>
                            <Typography component={"h4"}>
                              ₹ 19.2 K(8%)
                            </Typography>
                          </Box>
                          <Box className="trade_progress_item">
                            <Typography component={"p"}>Min Capital</Typography>
                            <Typography component={"h4"}>₹ 250 K</Typography>
                          </Box>
                        </Box> */}
                                </Box>
                                <Box className="strategyCard-footer">
                                  <Button
                                    className="strategyCard-btn bg-solid"
                                    onClick={() => handleClickOpen(e)}
                                  >
                                    Deploy
                                  </Button>
                                </Box>
                                {/* <Box className="strategyCard-footer sc_fb">
                        <Typography component={"p"}>
                          <Typography component={"span"}>Note :-</Typography>{" "}
                          Before deploy strategy for live trade do first paper
                          trade and build your trading confidance. There is no
                          responsibility profit and loss our trading product.
                        </Typography>
                      </Box> */}
                              </Box>
                            </Grid>
                          ))}
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          justifyContent="center"
                        >
                          {/* <Button className='refresh-btn solidButton' sx={{ width: 'auto !important', fontSize: '1.2rem' }}>View More</Button> */}
                          {strategies?.length > 0 && (
                            <Pagination
                              count={Math.ceil(strategies?.length / 3)}
                              onChange={(e, i) => {
                                setMarketPage(i);
                              }}
                            />
                          )}
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <img
                            style={{ width: "50%", height: "80%" }}
                            src={noData}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
              </Box>
            ) : (
              <>
                <Box className="no-data">
                  <img src={noData} />
                </Box>
              </>
            )}
          </TabPanel>

          <TabPanel value="maintab2" sx={{ padding: 0, marginTop: "0.5rem" }}>
            <Box sx={{ display: "flex", alignItems: "start" }}>
              <Box className="strategy_list_box" sx={{ left: filertSidebar }}>
                <Box
                  className="strategy_list"
                  sx={{ overflowY: "auto", maxHeight: "56vh" }}
                >
                  <Typography component={"h4"}>Segment Wise</Typography>
                  <>
                    <List className="list">
                      <ListItem
                        className={"All" == strategySelected ? "active" : ""}
                        onClick={() => {
                          setMarketPage(1);
                          setStrategySelected("All");
                        }}
                      >
                        All
                      </ListItem>
                      {segmentList?.map((e) => (
                        <ListItem
                          className={e == strategySelected ? "active" : ""}
                          onClick={() => {
                            setStrategySelected(e);
                            setMarketPage(1);
                          }}
                        >
                          {e}
                        </ListItem>
                      ))}
                    </List>
                  </>

                  {/* <Typography component={"h4"}>Tags List</Typography>
                  <List className="list">
                    {list.map((d) => (
                      <ListItem
                        className={d === tagSelected ? "active" : ""}
                        onClick={() => setTagSelected(d)}
                      >
                        {d}
                      </ListItem>
                    ))}
                  </List> */}
                </Box>

                <Box
                  sx={{
                    padding: "1.5rem 1rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className="solidButton fetchReport-btn"
                    sx={{ width: "100% !important" }}
                  >
                    Reset
                  </Button>
                  <Button
                    className="solidButton download-btn filter_strategy"
                    onClick={setFilterHnadler}
                    sx={{ marginLeft: 1 }}
                  >
                    <KeyboardArrowLeftRoundedIcon />
                  </Button>
                </Box>
              </Box>

              <Box
                sx={{
                  width: { xs: "100%", lg: "calc(100% - 33rem)" },
                  margin: "0 auto",
                  height: "68vh",
                  overflowY: "auto",
                  paddingRight: 1.5,
                }}
                className="withOutScrollbar"
              >
                <Grid
                  container
                  spacing={3}
                  sx={{
                    justifyContent: { xs: "center", lg: "center" },
                  }}
                >
                  <Grid item xs={12}>
                    <></>
                    {MyStrategies?.length > 0 ? (
                      <>
                        {MyStrategies?.slice(
                          (page - 1) * 2,
                          (page - 1) * 2 + 2
                        )?.map((e, index) => {
                          return (
                            <Box className="strategyCard">
                              <Box className="strategyCard-header">
                                <Box className="strategyCard-title">
                                  <Typography component={"p"} className="title">
                                    {e?.full_name}
                                  </Typography>
                                  <Typography component={"p"} className="days">
                                    Created 11 days ago
                                    {/* <Typography component={"span"}>
                                      by {e?.owner}
                                    </Typography> */}
                                  </Typography>
                                  <Box className="strategyCard-body">
                                    <NavLink
                                      to={"/passiveincome"}
                                      state={{ data: e, name: "myStrategy" }}
                                      className="read_more"
                                    >
                                      Read More...
                                    </NavLink>
                                  </Box>
                                </Box>

                                <Box
                                  className="dayGainLoss"
                                  sx={{
                                    textAlign: "center",
                                    alignItems: "flex-end !important",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "block",
                                      textAlign: "left",
                                      marginRight: 1,
                                    }}
                                  >
                                    <Typography component={"span"}>
                                      P&L
                                    </Typography>
                                    <Box
                                      className={
                                        !pnlList?.[e?.id] ||
                                          pnlList?.[e?.id]?.toFixed(2) >= 0
                                          ? "status up"
                                          : "status down-danger"
                                      }
                                    >
                                      {" "}
                                      {pnlList?.[e?.id]
                                        ? pnlList?.[e?.id]?.toFixed(2)
                                        : "00.00"}{" "}
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                              <Box className="strategyCard-body">
                                <Box
                                  className="selectiondiv-box m-rb fullWidth justify"
                                  sx={{
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box className="strategyCard-item">
                                    <Box className="inputFields space fullWidth ">
                                      <Typography
                                        component={"label"}
                                        className="label"
                                      >
                                        Trade Type
                                      </Typography>
                                      {state?.id === e?.id ? (
                                        <Box
                                          className="border"
                                          sx={{
                                            padding: "0px",
                                            marginTop: "0",
                                          }}
                                        >
                                          <FormControl>
                                            <Select
                                              value={e?.trade_type}
                                              inputProps={{
                                                sx: {
                                                  padding: "1rem !important",
                                                  fontSize: "14px !important",
                                                  display: "flex !important",
                                                  alignItems:
                                                    "center !important",
                                                },
                                              }}
                                              onChange={(event) => {
                                                if (userData?.is_subscribe) {
                                                  handleChangeValue(
                                                    "trade_type",
                                                    event?.target?.value,
                                                    e?.id
                                                  );
                                                } else {
                                                  generatePopup(
                                                    "error",
                                                    "please subscribe for live"
                                                  );
                                                }
                                              }}
                                              className="dropdown"
                                            >
                                              {drpValue3?.map((menu) => (
                                                <MenuItem value={menu}>
                                                  {menu}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        </Box>
                                      ) : (
                                        <TextField
                                          disabled
                                          defaultValue={e?.trade_type}
                                          value={e?.trade_type}
                                          className={
                                            state?.id !== e?.id &&
                                            "disabled-inputField"
                                          }
                                        />
                                      )}
                                      {/* <TextField
                                          defaultValue={e?.trade_type}
                                          value={e?.trade_type}
                                          className={
                                            state?.id !== e?.id && "disabled-inputField"
                                          }
                                        /> */}
                                      {/* ) : (
                                          e?.trade_type
                                        )} */}
                                    </Box>
                                  </Box>

                                  <Box className="strategyCard-item">
                                    <Box className="inputFields space fullWidth ">
                                      <Typography
                                        component={"label"}
                                        className="label"
                                      >
                                        Segment
                                      </Typography>
                                      <TextField
                                        disabled
                                        defaultValue={e?.segment}
                                        value={e?.segment}
                                        className="disabled-inputField"
                                      />
                                    </Box>
                                  </Box>

                                  <Box className="strategyCard-item">
                                    <Box className="inputFields space fullWidth ">
                                      <Typography
                                        component={"label"}
                                        className="label"
                                      >
                                        Lot Size
                                      </Typography>
                                      <TextField
                                        disabled={
                                          state?.id === e?.id ? false : true
                                        }
                                        onChange={(event) => {
                                          handleChangeValue(
                                            "lot",
                                            event?.target?.value,
                                            e?.id
                                          );
                                        }}
                                        defaultValue={e?.lot}
                                        className={
                                          state?.id !== e?.id &&
                                          "disabled-inputField"
                                        }
                                      />
                                    </Box>
                                  </Box>
                                  <Box className="strategyCard-item">
                                    <Box className="inputFields space fullWidth ">
                                      <Typography
                                        component={"label"}
                                        className="label"
                                      >
                                        Order Type
                                      </Typography>
                                      {/* {state?.id === e?.id ? ( */}
                                      <TextField
                                        defaultValue={e?.order_type}
                                        value={e?.order_type}
                                        className="disabled-inputField"
                                      />
                                      {/* ) : (
                                          e?.order_type
                                        )} */}
                                    </Box>
                                  </Box>
                                  <Box className="strategyCard-item">
                                    <Box className="inputFields space fullWidth ">
                                      <Typography
                                        component={"label"}
                                        className="label"
                                      >
                                        Trading Type
                                      </Typography>
                                      {/* {state?.id === e?.id ? ( */}
                                      <TextField
                                        defaultValue={e?.trading_type}
                                        value={e?.trading_type}
                                        className="disabled-inputField"
                                      />
                                      {/* ) : (
                                          e?.trading_type
                                        )} */}
                                    </Box>
                                  </Box>
                                  {state?.id === e?.id ? (
                                    <>
                                      {" "}
                                      <Box className="strategyCard-item">
                                        <Box className="inputFields space fullWidth ">
                                          <Typography
                                            component={"label"}
                                            className="label"
                                          >
                                            Symbol
                                          </Typography>
                                          <Box
                                            sx={{ padding: "0px !important" }}
                                            className="selectionDiv select_check"
                                          >
                                            <FormControl
                                              className="dropdown-ap"
                                              fullWidth
                                            >
                                              <Select
                                                multiple
                                                value={e?.symbol}
                                                onChange={(event) => {
                                                  handleChangeValue(
                                                    "symbol",
                                                    typeof event?.target
                                                      ?.value === "string"
                                                      ? event?.target?.value.split(
                                                        ","
                                                      )
                                                      : event?.target?.value,
                                                    e?.id
                                                  );
                                                }}
                                                renderValue={(selected) =>
                                                  selected.join(", ")
                                                }
                                              >
                                                {symbolList?.map(
                                                  (name, index) => (
                                                    <MenuItem
                                                      key={index}
                                                      value={name}
                                                    >
                                                      <Checkbox
                                                        checked={
                                                          e?.symbol?.indexOf(
                                                            name
                                                          ) > -1
                                                        }
                                                      />
                                                      <ListItemText
                                                        primary={name}
                                                      />
                                                    </MenuItem>
                                                  )
                                                )}
                                              </Select>
                                            </FormControl>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </>
                                  ) : (
                                    <Box className="strategyCard-item">
                                      <Box className="inputFields space fullWidth ">
                                        <Typography
                                          component={"label"}
                                          className="label"
                                        >
                                          symbol
                                        </Typography>
                                        <TextField
                                          disabled
                                          defaultValue={e?.symbol?.join(",")}
                                          value={e?.symbol?.join(",") || "-"}
                                          className="disabled-inputField"
                                        />
                                      </Box>
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                              <Box className="strategyCard-footer">
                                <Box>
                                  <Typography
                                    component={"label"}
                                    className="label"
                                    sx={{ fontSize: "1.2rem !important" }}
                                  >
                                    Strategy Status{" "}
                                  </Typography>
                                  <Box className="onoff_toggle">
                                    <RadioGroup
                                      row
                                      onChange={(data) => {
                                        if (strategyFlags?.[e?.id]) {
                                          generatePopup(
                                            "error",
                                            "strategy is running now"
                                          );
                                        } else {
                                          setStatus(data);
                                          setData(e);
                                          setStatusOpen(true);
                                        }
                                      }}
                                      value={e?.is_active ? true : false}
                                    >
                                      <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label="ON"
                                      />
                                      <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label="OFF"
                                      />
                                    </RadioGroup>
                                  </Box>
                                </Box>

                                <Box className="strategy_action">
                                  <Button
                                    onClick={handleClickOpen4}
                                    className="stretagy_btn trailing"
                                  >
                                    Set Trailing
                                    <svg
                                      width="12"
                                      height="12"
                                      viewBox="0 0 12 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <circle
                                        cx="2.5"
                                        cy="2.5"
                                        r="1.5"
                                        fill="white"
                                      />
                                      <circle
                                        cx="9.5"
                                        cy="9.5"
                                        r="1.5"
                                        fill="white"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M5.12505 2.5C5.12505 2.29289 5.29294 2.125 5.50005 2.125H8.06599C9.44161 2.125 9.96475 3.9215 8.80419 4.66003L3.59856 7.97271C3.07104 8.30841 3.30883 9.125 3.93411 9.125H5.59472L5.48488 9.01516C5.33844 8.86872 5.33844 8.63128 5.48488 8.48484C5.63133 8.33839 5.86877 8.33839 6.01521 8.48484L6.76521 9.23484C6.91166 9.38128 6.91166 9.61872 6.76521 9.76516L6.01521 10.5152C5.86877 10.6616 5.63133 10.6616 5.48488 10.5152C5.33844 10.3687 5.33844 10.1313 5.48488 9.98484L5.59472 9.875H3.93411C2.55849 9.875 2.03536 8.0785 3.19591 7.33996L8.40154 4.02729C8.92906 3.69159 8.69127 2.875 8.06599 2.875H5.50005C5.29294 2.875 5.12505 2.70711 5.12505 2.5Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </Button>

                                  <Button
                                    onClick={() => {
                                      strategyFlags?.[e?.id]
                                        ? handleClickOpen2(e)
                                        : generatePopup(
                                          "error",
                                          "This Position Not Running !"
                                        );
                                    }}
                                    className="stretagy_btn square_off"
                                  >
                                    Square Off
                                    <svg
                                      width="12"
                                      height="12"
                                      viewBox="0 0 12 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M6 0C2.694 0 0 2.694 0 6C0 9.306 2.694 12 6 12C9.306 12 12 9.306 12 6C12 2.694 9.306 0 6 0ZM8.016 7.38C8.19 7.554 8.19 7.842 8.016 8.016C7.926 8.106 7.812 8.148 7.698 8.148C7.584 8.148 7.47 8.106 7.38 8.016L6 6.636L4.62 8.016C4.53 8.106 4.416 8.148 4.302 8.148C4.188 8.148 4.074 8.106 3.984 8.016C3.81 7.842 3.81 7.554 3.984 7.38L5.364 6L3.984 4.62C3.81 4.446 3.81 4.158 3.984 3.984C4.158 3.81 4.446 3.81 4.62 3.984L6 5.364L7.38 3.984C7.554 3.81 7.842 3.81 8.016 3.984C8.19 4.158 8.19 4.446 8.016 4.62L6.636 6L8.016 7.38Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </Button>
                                  {state?.id && e?.id === state?.id ? (
                                    <Button
                                      onClick={() => {
                                        e?.id === state?.id
                                          ? handleEdit()
                                          : handleEditOpen(e);
                                      }}
                                      className="stretagy_btn edit_strategy"
                                    >
                                      Save
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        x="0px"
                                        y="0px"
                                        width="100"
                                        height="100"
                                        viewBox="0 0 24 24"
                                        fill="white"
                                      >
                                        <path d="M 4 2 L 4 22 L 12 19 L 20 22 L 20 2 L 6 2 L 4 2 z"></path>
                                      </svg>
                                    </Button>
                                  ) : (
                                    <Button
                                      onClick={() => {
                                        e?.id === state?.id
                                          ? handleEdit()
                                          : strategyFlags?.[e?.id]
                                            ? generatePopup(
                                              "error",
                                              "strategy is running now"
                                            )
                                            : handleEditOpen(e);
                                      }}
                                      className="stretagy_btn edit_strategy"
                                    >
                                      Strategy Edit
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="1em"
                                        viewBox="0 0 512 512"
                                      >
                                        <path
                                          d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                                          fill="white"
                                        />
                                      </svg>
                                    </Button>
                                  )}
                                </Box>
                              </Box>
                              {/* <Box className='strategyCard-footer sc_fb'>
            <Typography component={'p'}><Typography component={'span'}>Note :-</Typography>Start Trading in Rs <Typography component={'span'}>400/-</Typography> One Segment with One Strategy.</Typography>
          </Box> */}
                            </Box>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <img
                            style={{ width: "50%", height: "80%" }}
                            src={noData}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <Grid item xs={12} display="flex" justifyContent="center">
                    {/* <Button className='refresh-btn solidButton' sx={{ width: 'auto !important', fontSize: '1.2rem' }}>View More</Button> */}
                    <Pagination
                      count={Math.round(MyStrategies?.length / 2)}
                      onChange={(e, i) => {
                        setPage(i);
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </TabPanel>
        </TabContext>
      </Box>

      {/* Deploy Modal */}
      <Dialog
        open={open}
        onClose={handleClose}
        className="commonModal deployModal"
        fullWidth
      >
        <Box className="modalHeader">
          <Typography component={"h4"}>{selectedStrategy?.name}</Typography>
          <Button onClick={handleClose} className="closeModal">
            <img alt="close icon" src={close} />
          </Button>
        </Box>
        <DialogContent
          className="modalBody"
          sx={{ paddingTop: "6rem !important" }}
        >
          <DialogContentText>
            <Box className="formBox">
              <Grid container spacing={1} justifyContent="center">
                <Grid item xs={12} md={6}>
                  <Box className="formItems">
                    <Typography component={"label"} className="label important">
                      Select Trade Type:
                    </Typography>
                    <Box className="selectionDiv">
                      <FormControl
                        className="dropdown-ap"
                        sx={{ width: "100%" }}
                      >
                        <Select
                          value={tradetypeshand}
                          onChange={tradetypesdrp}
                          className="dropdown"
                        >
                          {tradetypes.map((vals, index) => (
                            <MenuItem value={vals} key={index}>
                              {vals}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </Grid>

                {/* <Grid item xs={12} md={6}>
                  <Box className="formItems">
                    <Typography component={"label"} className="label important">
                      Exchange:
                    </Typography>
                    <Box className="selectionDiv">
                      <FormControl
                        className="dropdown-ap"
                        sx={{ width: "100%" }}
                      >
                        <Select
                          value={exchanges}
                          onChange={exchangedrp}
                          className="dropdown"
                        >
                          {exchange.map((vals, index) => (
                            <MenuItem value={vals} key={index}>
                              {vals}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </Grid> */}

                <Grid item xs={12} md={6}>
                  <Box className="formItems">
                    <Typography component={"label"} className="label important">
                      Select Segment:
                    </Typography>
                    <Box className="selectionDiv">
                      <FormControl
                        className="dropdown-ap"
                        sx={{ width: "100%" }}
                      >
                        <Select
                          value={segments}
                          onChange={segmentdrp}
                          className="dropdown"
                        >
                          <MenuItem value="Select" key="Select">
                            Select
                          </MenuItem>
                          {selectedStrategy?.segment?.map((vals, index) => (
                            <MenuItem value={vals} key={index}>
                              {vals}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="formItems">
                    <Typography component={"label"} className="label">
                      Select Symbol:{" "}
                    </Typography>
                    <Box
                      sx={{ marginLeft: "0px !important" }}
                      className="selectionDiv select_check"
                    >
                      <FormControl className="dropdown-ap">
                        <Select
                          multiple
                          value={symbol}
                          onChange={handleChangeSymbol}
                          renderValue={(value) => value?.join(",")}
                          placeholder="select symbol"
                        >
                          {symbolList?.map((name, index) => (
                            <MenuItem key={index} value={name}>
                              <Checkbox checked={symbol?.includes(name)} />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box className="inputFields space fullWidth">
                    <Typography component={"label"} className="label important">
                      Add Lot Size :
                    </Typography>
                    <TextField
                      value={lotSize}
                      type="number"
                      onChange={(e) => {
                        setLotSize(e?.target?.value);
                      }}
                      placeholder="Script"
                      className="inputFiled"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box className="formItems">
                    <Typography component={"label"} className="label important">
                      Select Order Type:
                    </Typography>
                    <Box className="selectionDiv">
                      <FormControl
                        className="dropdown-ap"
                        sx={{ width: "100%" }}
                      >
                        <Select
                          value={order}
                          onChange={ordertypedrp}
                          className="dropdown"
                        >
                          {ordertype.map((vals, index) => (
                            <MenuItem value={vals} key={index}>
                              {vals}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box className="formItems">
                    <Typography component={"label"} className="label important">
                      Select Trading Type:
                    </Typography>
                    <Box className="selectionDiv">
                      <FormControl
                        className="dropdown-ap"
                        sx={{ width: "100%" }}
                      >
                        <Select
                          value={tardingtype}
                          onChange={tradingtypedrp}
                          className="dropdown"
                        >
                          {tradingype.map((vals, index) => (
                            <MenuItem value={vals} key={index}>
                              {vals}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}></Grid>

                <Grid item xs={12} md={6}>
                  <Button
                    onClick={handleDeploy}
                    className="modal-btn btn-primary"
                    sx={{ marginTop: "5%" }}
                  >
                    Deploy
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* Sqaure Off */}
      <Dialog
        open={open2}
        onClose={handleClose2}
        className="commonModal squareOff"
        fullWidth
      >
        <Box className="modalHeader">
          <Typography component={"h4"}>Square Off</Typography>
          <Button onClick={handleClose2} className="closeModal">
            <img src={close} />
          </Button>
        </Box>
        <DialogContent sx={{ padding: "0" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="alertIcons">
              <svg
                width="1052"
                height="1052"
                viewBox="0 0 1052 1052"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M558 334C558 316.3 543.7 302 526 302C508.3 302 494 316.3 494 334V590C494 607.7 508.3 622 526 622C543.7 622 558 607.7 558 590V334ZM526 750C536.609 750 546.783 745.786 554.284 738.284C561.786 730.783 566 720.609 566 710C566 699.391 561.786 689.217 554.284 681.716C546.783 674.214 536.609 670 526 670C515.391 670 505.217 674.214 497.716 681.716C490.214 689.217 486 699.391 486 710C486 720.609 490.214 730.783 497.716 738.284C505.217 745.786 515.391 750 526 750Z"
                  fill="#4987FE"
                />
                <circle
                  cx="526"
                  cy="526"
                  r="507"
                  stroke="#5086EE"
                  stroke-width="38"
                />
              </svg>
            </Box>
            <Typography
              component={"h4"}
              sx={{
                fontSize: "1.8rem",
                textAlign: "center",
                marginTop: "2rem",
              }}
              className="alertText"
            >
              Are You Sure You Want to Close Position !
            </Typography>

            <Box sx={{ display: { xs: "flex" }, marginTop: 2 }}>
              <Button
                onClick={handleClose2}
                className="modal-btn btn-danger"
                sx={{ marginRight: "0.5rem" }}
              >
                Discard
              </Button>
              <Button
                onClick={squareOff}
                className="modal-btn btn-primary"
                sx={{ marginLeft: "0.5rem" }}
              >
                Confirm
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* status Modal */}
      <Dialog
        open={statusOpen}
        onClose={handleClose5}
        className="commonModal squareOff"
        fullWidth
      >
        <Box className="modalHeader">
          <Typography component={"h4"}>Strategy Status</Typography>
          <Button onClick={handleClose5} className="closeModal">
            <img src={close} />
          </Button>
        </Box>
        <DialogContent sx={{ padding: "0" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="alertIcons">
              <svg
                width="1052"
                height="1052"
                viewBox="0 0 1052 1052"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M558 334C558 316.3 543.7 302 526 302C508.3 302 494 316.3 494 334V590C494 607.7 508.3 622 526 622C543.7 622 558 607.7 558 590V334ZM526 750C536.609 750 546.783 745.786 554.284 738.284C561.786 730.783 566 720.609 566 710C566 699.391 561.786 689.217 554.284 681.716C546.783 674.214 536.609 670 526 670C515.391 670 505.217 674.214 497.716 681.716C490.214 689.217 486 699.391 486 710C486 720.609 490.214 730.783 497.716 738.284C505.217 745.786 515.391 750 526 750Z"
                  fill="#4987FE"
                />
                <circle
                  cx="526"
                  cy="526"
                  r="507"
                  stroke="#5086EE"
                  stroke-width="38"
                />
              </svg>
            </Box>
            <Typography
              component={"h4"}
              sx={{
                fontSize: "1.8rem",
                textAlign: "center",
                marginTop: "2rem",
              }}
              className="alertText"
            >
              Are You Sure You Want to{" "}
              {status?.target?.value === "false" ? "Off" : "On"} strategy !
            </Typography>

            <Box sx={{ display: { xs: "flex" }, marginTop: 2 }}>
              <Button
                onClick={handleClose5}
                className="modal-btn btn-danger"
                sx={{ marginRight: "0.5rem" }}
              >
                Discard
              </Button>
              <Button
                onClick={() => handleActivate(status, data)}
                className="modal-btn btn-primary"
                sx={{ marginLeft: "0.5rem" }}
              >
                Confirm
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* Sqaure Off with Dropdown */}
      <Dialog
        open={open3}
        onClose={handleClose3}
        className="commonModal squareOff"
        fullWidth
      >
        <Box className="modalHeader">
          <Typography component={"h4"}>Square Off</Typography>
          <Button onClick={handleClose3} className="closeModal">
            <img src={close} />
          </Button>
        </Box>
        <DialogContent sx={{ padding: "0" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="alertIcons">
              <svg
                width="1052"
                height="1052"
                viewBox="0 0 1052 1052"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M558 334C558 316.3 543.7 302 526 302C508.3 302 494 316.3 494 334V590C494 607.7 508.3 622 526 622C543.7 622 558 607.7 558 590V334ZM526 750C536.609 750 546.783 745.786 554.284 738.284C561.786 730.783 566 720.609 566 710C566 699.391 561.786 689.217 554.284 681.716C546.783 674.214 536.609 670 526 670C515.391 670 505.217 674.214 497.716 681.716C490.214 689.217 486 699.391 486 710C486 720.609 490.214 730.783 497.716 738.284C505.217 745.786 515.391 750 526 750Z"
                  fill="#4987FE"
                />
                <circle
                  cx="526"
                  cy="526"
                  r="507"
                  stroke="#5086EE"
                  stroke-width="38"
                />
              </svg>
            </Box>
            <Typography
              component={"h4"}
              sx={{
                fontSize: "1.8rem",
                textAlign: "center",
                marginTop: "2rem",
              }}
              className="alertText"
            >
              Are You Sure You Want to Close Position !
            </Typography>
            <Box className="formBox">
              <Box className="formItems" sx={{ marginTop: 2 }}>
                <Box className="selectionDiv">
                  <FormControl className="dropdown-ap" sx={{ width: "100%" }}>
                    <Select
                      value={tradetypeshand}
                      onChange={tradetypesdrp}
                      className="dropdown"
                    >
                      {tradetypes.map((vals, index) => (
                        <MenuItem value={index === 0 ? " " : index} key={index}>
                          {vals}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: { xs: "flex" }, marginTop: 2 }}>
              <Button
                onClick={handleClose3}
                className="modal-btn btn-danger"
                sx={{ marginRight: "0.5rem" }}
              >
                Discard
              </Button>
              <Button
                onClick={handleClose3}
                className="modal-btn btn-primary"
                sx={{ marginLeft: "0.5rem" }}
              >
                Confirm
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* Tailing Stop / Loss */}
      <Dialog
        open={open4}
        onClose={handleClose4}
        className="commonModal Trailing_modal"
        fullWidth
      >
        <Box className="modalHeader">
          <Typography component={"h4"}>Trailing Stop/Loss</Typography>
          <Button onClick={handleClose4} className="closeModal">
            <img src={close} />
          </Button>
        </Box>
        <DialogContent sx={{ padding: "0" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="alertIcons">
              <svg
                width="1052"
                height="1052"
                viewBox="0 0 1052 1052"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M558 334C558 316.3 543.7 302 526 302C508.3 302 494 316.3 494 334V590C494 607.7 508.3 622 526 622C543.7 622 558 607.7 558 590V334ZM526 750C536.609 750 546.783 745.786 554.284 738.284C561.786 730.783 566 720.609 566 710C566 699.391 561.786 689.217 554.284 681.716C546.783 674.214 536.609 670 526 670C515.391 670 505.217 674.214 497.716 681.716C490.214 689.217 486 699.391 486 710C486 720.609 490.214 730.783 497.716 738.284C505.217 745.786 515.391 750 526 750Z"
                  fill="#4987FE"
                />
                <circle
                  cx="526"
                  cy="526"
                  r="507"
                  stroke="#5086EE"
                  stroke-width="38"
                />
              </svg>
            </Box>
            <Typography
              component={"h4"}
              sx={{
                fontSize: "1.8rem",
                textAlign: "center",
                marginTop: "2rem",
              }}
              className="alertText"
            >
              Are You Sure You Want to Close Position !
            </Typography>
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12}>
                <Box className="inputFields space fullWidth">
                  <Typography component={"label"} className="label">
                    Set Target in Rs:
                  </Typography>
                  <TextField
                    value={30000}
                    placeholder="Script"
                    className="inputFiled"
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box className="inputFields space fullWidth">
                  <Typography component={"label"} className="label">
                    Set Loss Limit is Rs:
                  </Typography>
                  <TextField
                    value={2000}
                    placeholder="Script"
                    className="inputFiled"
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box className="inputFields space fullWidth">
                  <Typography component={"label"} className="label">
                    Set Trailing Stop Loss in Rs:
                  </Typography>
                  <TextField
                    value={600}
                    placeholder="Script"
                    className="inputFiled"
                  />
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ display: { xs: "flex" }, marginTop: 2 }}>
              <Button
                onClick={handleClose4}
                className="modal-btn btn-danger"
                sx={{ marginRight: "0.5rem" }}
              >
                Discard
              </Button>
              <Button
                onClick={handleClose4}
                className="modal-btn btn-primary"
                sx={{ marginLeft: "0.5rem" }}
              >
                Confirm
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
