import "./Broker.scss";
import { Box, Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { zerodhaValidation } from "../../../validation/broker";
import { useDispatch, useSelector } from "react-redux";
import { BrokerStatus } from "../../../redux/actions/brokerAction";

const Zerodha = () => {
  const inputFields = { Appkey: "", secretkey: "", uid: "" };
  const [fields, setFields] = useState(inputFields);
  const [isSubmit, setIsSubmit] = useState(false);
  const [Error, setError] = useState();
  // const brokerStatus = useSelector((state) => state.Broker.brokerstatus);
  const dispatch = useDispatch();
  const inputBorder = {
    borderRadius: "5px",
    border: "1px solid #bcbcbc",
  };

  const brokerStatus = useSelector((state) => state.Broker.brokerstatus);
  useEffect(() => {
    dispatch(BrokerStatus());
  }, []);
  useEffect(() => {
    if (brokerStatus?.length > 0) {
      if (brokerStatus[0]?.appkey) {
        setFields({
          ...fields,
          Appkey: brokerStatus[0]?.appkey,
          secretkey: brokerStatus[0]?.secretkey,
          uid: brokerStatus[0]?.uid,
        });
      }
    }
  }, [brokerStatus]);

  //form input
  const handleChange = (event) => {
    const keyName = event.target.name;
    const keyValue = event.target.value;
    setFields((prevState) => ({
      ...prevState,
      [keyName]: keyValue,
    }));
    if (isSubmit) {
      setError(zerodhaValidation({ ...fields, [keyName]: keyValue }));
    }
  };

  const handleSubmit = () => {
    setIsSubmit(true);
    setError(zerodhaValidation(fields));
    const error = zerodhaValidation(fields);
    if (Object.keys(error) == 0) {
      localStorage.setItem("AppKey", fields.Appkey);
      localStorage.setItem("secretkey", fields.secretkey);
      localStorage.setItem("uid", fields.uid);
      localStorage.setItem("brokername", "zerodha");

      window.location.replace(
        `https://kite.zerodha.com/connect/login?v=3&api_key=${fields.Appkey}`
      );
    }
  };

  // useEffect(() => {
  //     dispatch(BrokerStatus());
  // }, []);

  // useEffect(() => {
  //     if (brokerStatus?.length > 0) {
  //         if (brokerStatus[0]?.appkey) {
  //             setFields({
  //                 ...fields,
  //                 appkey: brokerStatus[0]?.appkey,
  //                 secretkey: brokerStatus[0]?.secretkey,
  //             });
  //         }
  //     }
  // }, [brokerStatus]);

  return (
    <>
      <Box className="border">
        <Box>
          <Grid xs={12} lg={12}>
            <Box style={{ border: "none" }} className="formBox">
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  APP KEY :
                </Typography>
                <TextField
                  placeholder="Enter Access Key"
                  name="Appkey"
                  className="inputFiled"
                  style={inputBorder}
                  onChange={handleChange}
                  value={fields?.Appkey}
                />
                {Error ? <Box className="error">{Error.Appkey}</Box> : ""}
              </Box>
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  SECRET KEY :
                </Typography>
                <TextField
                  placeholder="Enter Secret Key"
                  name="secretkey"
                  className="inputFiled"
                  style={inputBorder}
                  onChange={handleChange}
                  value={fields?.secretkey}
                />
                {Error ? <Box className="error">{Error.secretkey}</Box> : ""}
              </Box>
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  UID :
                </Typography>
                <TextField
                  placeholder="Enter Secret Key"
                  name="uid"
                  className="inputFiled"
                  style={inputBorder}
                  onChange={handleChange}
                  value={fields?.uid}
                />
                {Error ? <Box className="error">{Error.uid}</Box> : ""}
              </Box>

              <Button
                className="btn solidButton fetchReport-btn"
                sx={{ width: "100% !important" }}
                onClick={handleSubmit}
              >
                Login
              </Button>
            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Zerodha;
