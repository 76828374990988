import { TextFields } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { paisaValidation } from "../../../validation/broker";

const Paisa = () => {
  const inputFields = { uid: "" };
  const [fields, setFields] = useState(inputFields);
  const [isSubmit, setIsSubmit] = useState(false);
  const [Error, setError] = useState();

  const inputBorder = {
    borderRadius: "5px",
    border: "1px solid #bcbcbc",
  };

  const handleSubmit = () => {
    setIsSubmit(true);
    setError(paisaValidation(fields));
    const error = paisaValidation(fields);

    if (Object.keys(error) == 0) {
      localStorage.setItem("uid", fields.uid);

      window.location.replace(
        "https://dev-openapi.5paisa.com/WebVendorLogin/VLogin/Index?VendorKey=fc6HaBEQ0vCAzYv6sgJVRuVEKDwuJsEs&ResponseURL=https://trade-panel.tradearth.in/broker&State="
      );
    }
  };

  const handleChange = (event) => {
    const keyName = event.target.name;
    const keyValue = event.target.value;
    setFields((prevState) => ({
      ...prevState,
      [keyName]: keyValue,
    }));
    if (isSubmit) {
      setError(paisaValidation({ ...fields, [keyName]: keyValue }));
    }
  };

  return (
    <>
      <Box className="border">
        <Box>
          <Box className="formItems" sx={{ marginBottom: 1.5 }}>
            <Typography component={"label"} className="label">
              USER ID :
            </Typography>
            <TextField
              placeholder="Enter User Id.."
              className="inputFiled"
              style={inputBorder}
              name="uid"
              value={fields?.uid?.toUpperCase()}
              onChange={handleChange}
            />
            {Error ? <Box className="error">{Error.uid}</Box> : ""}
          </Box>

          <Button
            className="btn solidButton fetchReport-btn"
            sx={{ width: "100% !important" }}
            onClick={handleSubmit}
          >
            Login
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Paisa;
