import React, { useState } from "react";
import { Box, Typography, Button, Grid, TextField } from "@mui/material";
import Dropdown from "../../../Dropdown/Dropdown";
import "../../../Scanner/Scanner.scss";
import "../../../Tablesearch/Tablesearch.scss";
import "../../../Tablesearch/Tablesearch.scss";
import { useDispatch } from "react-redux";
import {
  refreshSocket,
  resetSocket,
} from "../../../../redux/actions/stratergyAction";
import { toast } from "react-toastify";

export default function SocketService() {
  const [symbol, setSymbol] = useState("select symbol");
  const [load, setLoad] = useState(false);
  const [fields, setFields] = useState("");

  const dispatch = useDispatch();

  const symbolList = ["BSE", "NFO", "NSE", "MCX"];
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleSymbol = (event) => {
    setSymbol(event.target.value);
  };

  const handleRefresh = (event) => {
    dispatch(refreshSocket()).then((res) => {
      if (res.status === 200) {
        toast.success("Refresh successfully");
      }
    });
  };

  const handleReset = () => {
    dispatch(
      resetSocket({
        symbol: symbol.toUpperCase(),
        token: fields?.token,
      })
    ).then((res) => {
      if (res.status === 200) {
        toast.success("subscribed successfully");
      }
    });
  };

  return (
    <>
      <Box
        className="border-ap"
        sx={{ margin: 1, maxWidth: { xs: "100%", lg: 900 } }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            margin: 0,
            width: "100%",
            backgroundColor: "#0096fb",
            paddingBottom: 2,
          }}
          className="border-apb"
        >
          <Grid item xs={6} md={6}>
            <Typography
              sx={{ fontSize: 20, color: "#FFFFFF", fontWeight: 600 }}
            >
              Socket Services
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <Typography
              sx={{
                fontSize: 24,
                color: "#FFFFFF",
                fontWeight: 700,
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                onClick={handleRefresh}
                disabled={load}
                className="formSolid-btn"
              >
                Refresh
              </Button>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ marginTop: 1, paddingRight: 2 }}>
          <Grid item xs={12}>
            <Box
              className="selectiondiv-box"
              sx={{ marginTop: "0 !important" }}
            >
              <Box className="adminCtradeBox">
                <Box className="adminCtradeBoxItem">
                  <Dropdown
                    title={"symbol"}
                    val={symbolList}
                    handleSelect={handleSymbol}
                    value={symbol}
                  />
                </Box>
                <Box
                  className="adminCtradeBoxItem"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    className="selectiondiv-box"
                    sx={{
                      marginTop: "0 !important",
                      justifyContent: "left !important",
                    }}
                  >
                    <Box className="inputFields space fullWidth">
                      <TextField
                        placeholder="Enter Token"
                        sx={{
                          border: "1px solid #0a53b5",
                          borderRadius: "5px",
                        }}
                        onChange={handleChange}
                        type="text"
                        name="token"
                        value={fields?.token}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Grid
              container
              spacing={2}
              sx={{ margin: 0, marginBottom: 2, width: "100%" }}
            >
              <Grid item xs={12} md={12} sx={{ marginTop: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Button
                    onClick={handleReset}
                    className="solidButton fetchReport-btn"
                    sx={{
                      width: "auto !important",
                      maxWidth: "auto !important",
                    }}
                  >
                    Reset Socket
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
