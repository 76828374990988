import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Dialog,
  DialogContent,
  Tooltip,
  Checkbox,
  DialogContentText,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  Tab,
  InputBase,
} from "@mui/material";
import MarketWatch from "./CopyMarketWatch"
import Dropdown from "../../../Dropdown/Dropdown";
import { TabContext, TabList, TabPanel } from "@mui/lab";
// import Tablesearch from "../Tablesearch/Tablesearch";
import CloseIcon from "@mui/icons-material/Close";
import close from "../../../../images/close.png";
import Table from "../../../Table/Table";
import "../../../Position/Position.scss";
import RefreshIcon from "@mui/icons-material/Refresh";
import MessageIcon from "@mui/icons-material/Message";
import {
  cancelOrder,
  cancelcopyTrade,
  closeAllLiveOrder,
  copyPostionDetails,
  copyTradeDetails,
  copytradeClose,
  closeCopyLimitOrder,
} from "../../../../redux/actions/positionAction";
import { useDispatch, useSelector } from "react-redux";
import { messageEvent, socket } from "../../../../hooks/useSocket";
import moment from "moment";
import { generatePopup } from "../../../../utils/popup";
import { changelimitcopytrade } from "../../../../redux/actions/brokerAction";

// const down = (
//   <svg
//     width="10"
//     height="5"
//     viewBox="0 0 10 5"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       d="M8.93119 1.20379L6.83498 3.30389L5.55506 4.59274C5.01304 5.13575 4.13146 5.13575 3.58945 4.59274L0.206783 1.20379C-0.237274 0.758914 0.0827077 0 0.703081 0H4.36655H8.43489C9.0618 0 9.37525 0.758914 8.93119 1.20379Z"
//       fill="#FF231F"
//     />
//   </svg>
// );

// const up = (
//   <svg
//     width="10"
//     height="5"
//     viewBox="0 0 10 5"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       d="M8.93119 3.79621L6.83498 1.69611L5.55506 0.407262C5.01304 -0.135754 4.13146 -0.135754 3.58945 0.407262L0.206783 3.79621C-0.237274 4.24109 0.0827077 5 0.703081 5H4.36655H8.43489C9.0618 5 9.37525 4.24109 8.93119 3.79621Z"
//       fill="#008F75"
//     />
//   </svg>
// );

export default function CopyTradeView(props) {
  const [open, setOpen] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  const [alignment, setAlignment] = React.useState("live");
  const [value, setValue] = React.useState("maintab1");
  const [open3, setOpen3] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [tradetypeshand, settradetypeshand] = React.useState("");
  const [modelType, setModelType] = React.useState("");
  const [id, setId] = React.useState("");
  const [postionid, setPostionid] = useState("");
  const [search, setSearch] = useState();
  const [bsopen, setBsopen] = useState(false);
  const [prdType, setPrdType] = useState("LIMIT");
  const drpValue = ["LIMIT", "SL-M", "SL-LIMIT"];
  const [prc, setPrc] = useState("Market");
  const [bsopen2, setBsopen2] = useState(false);
  const [prc2, setPrc2] = useState("");
  const [copytrdeId, setCopytredId] = useState("");
  const [copypostionId, setCopyPostionId] = useState("");
  const edit = <Typography sx={{ fontSize: "1.4rem" }}>Edit</Typography>;
  const cancle = <Typography sx={{ fontSize: "1.4rem" }}>Cancel</Typography>;
  const squareOff = (
    <Typography sx={{ fontSize: "1.4rem" }}>square Off</Typography>
  );

  const dispatch = useDispatch();

  const socketCallFun = async () => {
    const ws = await socket("liveposition");
    messageEvent(ws, "liveposition", dispatch);
  };

  useEffect(() => {
    socketCallFun();
    // dispatch(BrokerStatus({}, navigate));
  }, []);

  useEffect(() => {
    if (value === "maintab1") {
      dispatch(copyTradeDetails());
    } else if (value === "maintab2") {
      dispatch(copyPostionDetails());
    }
  }, [value]);

  const copytradedetails = useSelector((state) => {
    return state?.Position?.copytrade;
  });

  const handleClickOpen3 = () => {
    setOpen3(true);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };

  const handleClickOpen2 = (type, id) => {
    setOpen2(true);
    setPostionid(id);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClickOpen = (type, Id) => {
    setId(Id);
    setModelType(type);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseOrder = () => {
    setLoad(true);
    if (modelType === "close") {
      dispatch(copytradeClose({ id: Number(id) })).then((res) => {
        setLoad(false);
        if (res?.status === 200) {
          setOpen(false);
          // generatePopup("success", res?.data?.message);
          dispatch(copyTradeDetails());
          dispatch(copyPostionDetails());
        } else {
          generatePopup("error", res?.data?.message);
        }
      });
    } else {
      dispatch(cancelOrder({ id: Number(id) })).then((res) => {
        if (res === 200) {
          setOpen(false);
        }
      });
    }
  };

  const handleChangetab = (e, newValue = "string") => {
    setValue(newValue);
    setAlignment("live");
  };

  const handleChange = (e, newAlignment) => {
    setAlignment(newAlignment);
  };

  const orderBookData = useSelector((state) => {
    return state.Position?.liveTradeDetails;
  });

  const copyPostionDetail = useSelector((state) => {
    return state?.Position?.copyPostion;
  });

  const buysellOpen = (value) => {
    setBsopen(true);
    setCopyPostionId(value);
  };

  const changelimitsOpen = (value) => {
    setBsopen2(true);
    setCopytredId(value);
  };

  const handlePrdChange = (e) => {
    setPrdType(e.target.value);
  };

  const modifyTrade = () => {
    const obj = {
      id: copypostionId,
      prctyp: prdType.toUpperCase(),
      prc: prc.toString(),
    };
    dispatch(closeCopyLimitOrder(obj)).then((res) => {
      setBsopen(false);
    });
  };

  const SqaureOffAll = () => {
    dispatch(closeAllLiveOrder()).then((res) => {
      if (res) {
        setOpen3(false);
        generatePopup("success", res?.data?.message);
        dispatch(copyTradeDetails());
        dispatch(copyPostionDetails());
      }
    });
  };

  const handleCancelcopytrade = () => {
    dispatch(cancelcopyTrade({ id: Number(postionid) })).then((res) => {
      if (res?.status === 200) {
        setOpen2(false);
        generatePopup("success", res?.data?.message);
        dispatch(copyTradeDetails());
        dispatch(copyPostionDetails());
      } else {
        generatePopup("error", res?.data?.message);
      }
    });
  };

  const handleSubmit = () => {
    const obj = {
      id: copytrdeId,
      prc: prc2,
    };
    dispatch(changelimitcopytrade(obj));
    setBsopen2(false);
  };

  // Open Position
  const col = [
    <Checkbox sx={{ "& .MuiSvgIcon-root": { fontSize: 24 }, padding: 0 }} />,
    "Symbol Name",
    "Exchange",
    "Trend",
    "PrdType",
    "OrderType",
    "Reason",
    "Action",
  ];

  const rows =
    copyPostionDetail?.length > 0
      ? copyPostionDetail?.map((e) => {
        return {
          check: (
            <Checkbox
              sx={{ "& .MuiSvgIcon-root": { fontSize: 24 }, padding: 0 }}
            />
          ),
          name: e?.symbol,
          // strategy: e?.stratergy,
          exchange: e?.exchange,
          type: (
            <Typography
              sx={{
                color: e?.side === "BUY" ? "#24a959" : "red",
                fontWeight: "bold",
              }}
            >
              {e?.side}
            </Typography>
          ),
          prdType: e?.prctyp,
          orderType: e?.product,
          // quantity: e?.quantity,
          // price: e?.price,
          // ltp: e?.liveprice,
          // profit: (
          //   <Typography
          //     sx={{
          //       color: Number(e?.profit) >= 0 ? "#24a959" : "red",
          //       fontWeight: "bold",
          //     }}
          //   >
          //     {Number(e?.profit)?.toFixed(2)}
          //   </Typography>
          // ),
          reason: (
            <Tooltip
              title={
                <Typography sx={{ fontSize: "1.3rem" }}>
                  {e?.remark}
                </Typography>
              }
              arrow
              placement="left"
            >
              <Button className="rejectMessage">
                <MessageIcon sx={{ color: "#fff !important" }} />
              </Button>
            </Tooltip>
          ),
          // positiontype: e?.positiontype,
          // status: (
          //   <Typography
          //     sx={{
          //       color: e?.status === "COMPLETE" ? "#24a959" : "red",
          //       fontWeight: "bold",
          //     }}
          //   >
          //     {e?.status}
          //   </Typography>
          // ),
          action: (
            <Box className="tableActions">
              {e.status === "pending" && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="actionButton"
                >
                  <Tooltip title={edit} arrow placement="top">
                    <Button
                      onClick={() => buysellOpen(e.id)}
                      className="edit"
                      variant="text"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.26 3.59997L5.04997 12.29C4.73997 12.62 4.43997 13.27 4.37997 13.72L4.00997 16.96C3.87997 18.13 4.71997 18.93 5.87997 18.73L9.09997 18.18C9.54997 18.1 10.18 17.77 10.49 17.43L18.7 8.73997C20.12 7.23997 20.76 5.52997 18.55 3.43997C16.35 1.36997 14.68 2.09997 13.26 3.59997Z"
                          stroke="CurrentColor"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.89 5.05005C12.32 7.81005 14.56 9.92005 17.34 10.2"
                          stroke="CurrentColor"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3 22H21"
                          stroke="CurrentColor"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Button>
                  </Tooltip>
                  <Tooltip title={squareOff} arrow placement="top">
                    <Button
                      className="del"
                      variant="text"
                      onClick={() => {
                        handleClickOpen("close", e?.id);
                      }}
                    >
                      <CloseIcon />
                    </Button>
                  </Tooltip>
                  {/* <Tooltip title={cancel} arrow placement="top">
              <Button
                className="del"
                variant="text"
                onClick={() => {
                  handleClickOpen("cancel");
                }}
              >
                <DoDisturbIcon />
              </Button>
            </Tooltip> */}
                </Box>
              )}
            </Box>
          ),
        };
      })
      : [];

  const col2 = [
    "Symbol Name",
    "Exchange",
    "Trend",
    "Owner",
    "Price",
    "Type",
    "Order Type",
    "PrdType",
    "Date & Time",
    "Status",
    "Action",
  ];

  const rows2 =
    copytradedetails?.length > 0
      ? copytradedetails?.map((e) => {
        return {
          name: e?.symbol,
          exchange: e?.exchange,
          trend: (
            <Typography
              sx={{
                color: e?.side === "BUY" ? "#24a959" : "red",
                fontWeight: "bold",
              }}
            >
              {e?.side}
            </Typography>
          ),
          owner: e?.owner,
          price: (
            <Typography
              sx={{
                color: Number(e?.price >= 0) ? "#24a959" : "red",
                fontWeight: "bold",
              }}
            >
              {e?.price ? Number(e?.price).toFixed(2) : "0.00"}
            </Typography>
          ),
          type: e?.type1,
          product: e?.product,
          prdType: e?.prctyp,
          date: moment.parseZone(e?.date)?.format("DD-MM-YYYY HH:mm"),
          status: e?.status,
          action: (
            <Box className="tableActions">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="actionButton"
              >
                {e?.status?.toLowerCase() === "pending" ? (
                  <>
                    <Tooltip title={edit} arrow placement="top">
                      <Button
                        className="edit"
                        variant="text"
                        onClick={() => changelimitsOpen(e.id)}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.26 3.59997L5.04997 12.29C4.73997 12.62 4.43997 13.27 4.37997 13.72L4.00997 16.96C3.87997 18.13 4.71997 18.93 5.87997 18.73L9.09997 18.18C9.54997 18.1 10.18 17.77 10.49 17.43L18.7 8.73997C20.12 7.23997 20.76 5.52997 18.55 3.43997C16.35 1.36997 14.68 2.09997 13.26 3.59997Z"
                            stroke="CurrentColor"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.89 5.05005C12.32 7.81005 14.56 9.92005 17.34 10.2"
                            stroke="CurrentColor"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M3 22H21"
                            stroke="CurrentColor"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Button>
                    </Tooltip>
                    <Tooltip title={cancle} arrow placement="top">
                      <Button
                        className="del"
                        variant="text"
                        onClick={() => {
                          handleClickOpen2("close", e?.id);
                        }}
                      >
                        <CloseIcon />
                      </Button>
                    </Tooltip>
                  </>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          ),
        };
      })
      : [];

  const col3 = [
    <Checkbox sx={{ "& .MuiSvgIcon-root": { fontSize: 24 }, padding: 0 }} />,
    "Symbol Name",
    "Exchange",
    "Trend",
    "Strategy",
    "Quantity",
    "Order Id",
    "Order Type",
    "Price",
    "PrdType",
    "Date & Time",
    "Status",
    "Reason",
    "Action",
  ];

  const rows3 =
    orderBookData?.length > 0
      ? orderBookData?.map((e) => {
        return {
          check: (
            <Checkbox
              sx={{ "& .MuiSvgIcon-root": { fontSize: 24 }, padding: 0 }}
            />
          ),
          name: e?.symbol,
          exchange: e?.exchange,
          side: (
            <Typography
              sx={{
                color: e?.side === "BUY" ? "#24a959" : "red",
                fontWeight: "bold",
              }}
            >
              {e?.side}
            </Typography>
          ),
          strategy: e?.stratergy,
          quantity: Math.trunc(e?.quantity),
          token: e?.orderid,
          product: e?.product,
          price: e?.price,
          priceType: e?.prctyp,
          date: moment.parseZone(e?.date)?.format("DD-MM-YYYY hh:mm"),

          status: (
            <Typography
              sx={{
                color: e?.status === "COMPLETE" ? "#24a959" : "red",
                // fontWeight: "bold",
              }}
            >
              {e?.status}
            </Typography>
          ),
          remark: (
            <Tooltip
              title={
                <Typography sx={{ fontSize: "1.3rem" }}>
                  {e?.remark}
                </Typography>
              }
              arrow
              placement="left"
            >
              <Button className="rejectMessage">
                <MessageIcon sx={{ color: "#fff !important" }} />
              </Button>
            </Tooltip>
          ),
          action:
            e?.status === "PENDING" ? (
              <Box className="tableActions">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="actionButton"
                >
                  <Tooltip title={edit} arrow placement="top">
                    <Button className="edit" variant="text">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.26 3.59997L5.04997 12.29C4.73997 12.62 4.43997 13.27 4.37997 13.72L4.00997 16.96C3.87997 18.13 4.71997 18.93 5.87997 18.73L9.09997 18.18C9.54997 18.1 10.18 17.77 10.49 17.43L18.7 8.73997C20.12 7.23997 20.76 5.52997 18.55 3.43997C16.35 1.36997 14.68 2.09997 13.26 3.59997Z"
                          stroke="CurrentColor"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.89 5.05005C12.32 7.81005 14.56 9.92005 17.34 10.2"
                          stroke="CurrentColor"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3 22H21"
                          stroke="CurrentColor"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Button>
                  </Tooltip>

                  <Tooltip title={cancle} arrow placement="top">
                    <Button className="del" variant="text">
                      <CloseIcon />
                    </Button>
                  </Tooltip>
                </Box>
              </Box>
            ) : (
              <>-</>
            ),
        };
      })
      : [];








  const handleRefresh = () => {
    if (value === "maintab1") {
      dispatch(copyTradeDetails());
    } else if (value === "maintab2") {
      dispatch(copyPostionDetails());
    }
  };

  return (
    <>
      <Box className="tabs">
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12}>
            <TabContext value={value}>
              <Box
                sx={{
                  display: { xs: "block", lg: "flex" },
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <TabList className="main-tab" onChange={handleChangetab}>
                  <Tab label="Open Position" value="maintab1" />
                  <Tab label="Trade Logs" value="maintab2" />
                  <Tab label="Market Watch" value="maintab4" />
                  {/* <Tab label="Order Book" value="maintab3" /> */}
                </TabList>

                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <Typography sx={{ fontSize: "1.4rem" }}>
                      Refresh{" "}
                    </Typography>
                  }
                >
                  <Button onClick={handleRefresh} className="collapse-btn">
                    <RefreshIcon />
                  </Button>
                </Tooltip>
              </Box>

              <Box className={`tabelBox ${props.btnone}`}>
                {/* open position */}
                <TabPanel value="maintab1" sx={{ padding: 0 }}>
                  <Box
                    className="selectiondiv-box m-rb"
                    sx={{ display: props.opHeaderNone }}
                  >
                    {/* <Box className="selectionDiv searchFlex">
                      <Tablesearch
                        handlesearch={(event) => handlesearch(event)}
                      />
                    </Box> */}
                    <Button
                      className="squareOff-btn solidButton"
                      sx={{
                        width: "auto !important",
                        fontSize: "1.2rem",
                        marginRight: 1,
                        marginBottom: "1rem",
                      }}
                      onClick={handleClickOpen3}
                    >
                      Square Off All
                    </Button>
                    <Box
                      className="selectionDiv searchFlex"
                      sx={{
                        maxWidth: "auto !important",
                        width: "auto !important",
                      }}
                    >
                      {/* <Typography className="label" component={"label"}>
                        Select Trade Type
                      </Typography> */}
                      <ToggleButtonGroup
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        className="tradeType-toggle"
                      >
                        <ToggleButton value="paper">Paper</ToggleButton>
                        <ToggleButton value="live">Live</ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  </Box>
                  <Table col={col} rows={rows} />
                </TabPanel>
                {/* close position */}
                <TabPanel value="maintab2" sx={{ padding: 0 }}>
                  <Box
                    className="selectiondiv-box m-rb"
                    sx={{ display: props.opHeaderNone }}
                  >
                    {/* <Box className="selectionDiv searchFlex">
                      <Tablesearch
                        handlesearch={(event) => handlesearch(event)}
                      />
                    </Box> */}
                    <Box
                      className="selectionDiv searchFlex"
                      sx={{
                        maxWidth: "auto !important",
                        width: "auto !important",
                      }}
                    >
                      {/* <Typography className="label" component={"label"}>
                        Select Trade Type
                      </Typography> */}
                      <ToggleButtonGroup
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        className="tradeType-toggle"
                      >
                        <ToggleButton value="paper">Paper</ToggleButton>
                        <ToggleButton value="live">Live</ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  </Box>
                  <Table col={col2} rows={rows2} />
                </TabPanel>
                {/* order book */}
                <TabPanel value="maintab3" sx={{ padding: 0 }}>
                  <Box
                    className="selectiondiv-box m-rb"
                    sx={{ display: props.opHeaderNone }}
                  >
                    <Button
                      className="squareOff-btn solidButton"
                      sx={{
                        width: "auto !important",
                        fontSize: "1.2rem",
                        marginRight: 1,
                        marginBottom: "1rem",
                      }}
                    >
                      Cancel All
                    </Button>
                    {/* <Box className="selectionDiv searchFlex">
                      <Tablesearch />
                    </Box> */}
                    <Box
                      className="selectionDiv searchFlex"
                      sx={{
                        maxWidth: "auto !important",
                        width: "auto !important",
                      }}
                    >
                      {/* <Typography className="label" component={"label"}>
                        Select Trade Type
                      </Typography> */}
                      <ToggleButtonGroup
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        className="tradeType-toggle"
                      >
                        <ToggleButton value="paper">Paper</ToggleButton>
                        <ToggleButton value="live">Live</ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  </Box>
                  <Table col={col3} rows={rows3} />
                </TabPanel>

                <TabPanel value="maintab4" sx={{ padding: 0 }}>
                  <MarketWatch />
                </TabPanel>
              </Box>
            </TabContext>
          </Grid>
        </Grid>
      </Box>

      {/* Sqaure off */}
      <Dialog
        open={open}
        onClose={handleClose}
        className="commonModal squareOff"
      >
        <Box className="modalHeader" sx={{ justifyContent: "end" }}>
          <Typography component={"h4"}>Square Off</Typography>
          <Button onClick={handleClose} className="closeModal">
            <img src={close} />
          </Button>
        </Box>
        <DialogContent sx={{ padding: "0" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="alertIcons">
              <svg
                width="1052"
                height="1052"
                viewBox="0 0 1052 1052"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M558 334C558 316.3 543.7 302 526 302C508.3 302 494 316.3 494 334V590C494 607.7 508.3 622 526 622C543.7 622 558 607.7 558 590V334ZM526 750C536.609 750 546.783 745.786 554.284 738.284C561.786 730.783 566 720.609 566 710C566 699.391 561.786 689.217 554.284 681.716C546.783 674.214 536.609 670 526 670C515.391 670 505.217 674.214 497.716 681.716C490.214 689.217 486 699.391 486 710C486 720.609 490.214 730.783 497.716 738.284C505.217 745.786 515.391 750 526 750Z"
                  fill="#4987FE"
                />
                <circle
                  cx="526"
                  cy="526"
                  r="507"
                  stroke="#5086EE"
                  stroke-width="38"
                />
              </svg>
            </Box>
            <Typography
              component={"h4"}
              sx={{
                fontSize: "1.8rem",
                textAlign: "center",
                marginTop: "2rem",
              }}
              className="alertText"
            >
              Are You Sure You Want to Close Position !
            </Typography>

            <Box sx={{ display: { xs: "flex" }, marginTop: 2 }}>
              <Button
                onClick={handleClose}
                className="modal-btn btn-danger"
                sx={{ marginRight: "0.5rem" }}
              >
                Discard
              </Button>
              <Button
                disabled={load}
                onClick={handleCloseOrder}
                className="modal-btn btn-primary"
                sx={{ marginLeft: "0.5rem" }}
              >
                Confirm
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* Cancel All */}
      <Dialog
        open={open2}
        onClose={handleClose2}
        className="commonModal squareOff"
      >
        <Box className="modalHeader" sx={{ justifyContent: "end" }}>
          <Typography component={"h4"}>Cancel All Order</Typography>
          <Button onClick={handleClose2} className="closeModal">
            <img src={close} />
          </Button>
        </Box>
        <DialogContent sx={{ padding: "0" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="alertIcons">
              <svg
                width="1052"
                height="1052"
                viewBox="0 0 1052 1052"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M558 334C558 316.3 543.7 302 526 302C508.3 302 494 316.3 494 334V590C494 607.7 508.3 622 526 622C543.7 622 558 607.7 558 590V334ZM526 750C536.609 750 546.783 745.786 554.284 738.284C561.786 730.783 566 720.609 566 710C566 699.391 561.786 689.217 554.284 681.716C546.783 674.214 536.609 670 526 670C515.391 670 505.217 674.214 497.716 681.716C490.214 689.217 486 699.391 486 710C486 720.609 490.214 730.783 497.716 738.284C505.217 745.786 515.391 750 526 750Z"
                  fill="#4987FE"
                />
                <circle
                  cx="526"
                  cy="526"
                  r="507"
                  stroke="#5086EE"
                  stroke-width="38"
                />
              </svg>
            </Box>
            <Typography
              component={"h4"}
              sx={{
                fontSize: "1.8rem",
                textAlign: "center",
                marginTop: "2rem",
              }}
              className="alertText"
            >
              Are You Sure You Want to Cancel All order !
            </Typography>

            <Box sx={{ display: { xs: "flex" }, marginTop: 2 }}>
              <Button
                onClick={handleClose2}
                className="modal-btn btn-danger"
                sx={{ marginRight: "0.5rem" }}
              >
                Discard
              </Button>
              <Button
                onClick={handleCancelcopytrade}
                className="modal-btn btn-primary"
                sx={{ marginLeft: "0.5rem" }}
              >
                Confirm
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* Sqaure Off with Dropdown */}
      <Dialog
        open={open3}
        onClose={handleClose3}
        className="commonModal squareOff"
        fullWidth
      >
        <Box className="modalHeader">
          <Typography component={"h4"}>Square Off</Typography>
          <Button onClick={handleClose3} className="closeModal">
            <img src={close} />
          </Button>
        </Box>
        <DialogContent sx={{ padding: "0" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="alertIcons">
              <svg
                width="1052"
                height="1052"
                viewBox="0 0 1052 1052"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M558 334C558 316.3 543.7 302 526 302C508.3 302 494 316.3 494 334V590C494 607.7 508.3 622 526 622C543.7 622 558 607.7 558 590V334ZM526 750C536.609 750 546.783 745.786 554.284 738.284C561.786 730.783 566 720.609 566 710C566 699.391 561.786 689.217 554.284 681.716C546.783 674.214 536.609 670 526 670C515.391 670 505.217 674.214 497.716 681.716C490.214 689.217 486 699.391 486 710C486 720.609 490.214 730.783 497.716 738.284C505.217 745.786 515.391 750 526 750Z"
                  fill="#4987FE"
                />
                <circle
                  cx="526"
                  cy="526"
                  r="507"
                  stroke="#5086EE"
                  stroke-width="38"
                />
              </svg>
            </Box>
            <Typography
              component={"h4"}
              sx={{
                fontSize: "1.8rem",
                textAlign: "center",
                marginTop: "2rem",
              }}
              className="alertText"
            >
              Are You Sure You Want to Close Position !
            </Typography>
            <Box className="formBox">
              <Box className="formItems" sx={{ marginTop: 2 }}>
                {/* <Typography component={'label'} className='label important'>Select Trade Type:</Typography> */}
                <Box className="selectionDiv">
                  {/* <FormControl className="dropdown-ap" sx={{ width: "100%" }}>
                    <Select
                      value={tradetypeshand}
                      onChange={tradetypesdrp}
                      className="dropdown"
                    >
                      {tradetypes.map((vals, index) => (
                        <MenuItem value={index === 0 ? " " : index} key={index}>
                          {vals}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: { xs: "flex" }, marginTop: 2 }}>
              <Button
                onClick={handleClose3}
                className="modal-btn btn-danger"
                sx={{ marginRight: "0.5rem" }}
              >
                Discard
              </Button>
              <Button
                onClick={SqaureOffAll}
                className="modal-btn btn-primary"
                sx={{ marginLeft: "0.5rem" }}
              >
                Confirm
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={bsopen}
        onClose={() => {
          setBsopen(false);
        }}
        className={`commonModal buysellModal `}
      >
        <DialogContent sx={{ padding: "0 !important" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="tabs buysellModal-tabs">
              <Box
                className="tabBox"
                sx={{
                  border: "none !important",
                  padding: 1,
                  width: "auto !important",
                }}
              >
                <Box
                  className="formBox"
                  sx={{
                    border: "none",
                    display: "flex",
                    alignItems: "flex-start",
                    flexWrap: { xs: "wrap", md: "nowrap" },
                    marginTop: 1,
                  }}
                >
                  {/* <Box className="formItems">
                    <Typography
                      component={"label"}
                      className="label"
                      sx={{ fontSize: "1.4rem !important" }}
                    >
                      Quantity
                    </Typography>
                    <Box className="inputFields space">
                      <InputBase
                        onChange={(e) => setQty(Number(e.target.value))}
                        placeholder="Quantity"
                      />
                    </Box>
                  </Box> */}
                  <Box className="formItems" sx={{ padding: "0 0.8rem" }}>
                    <Typography
                      component={"label"}
                      className="label"
                      sx={{ fontSize: "1.4rem !important" }}
                    >
                      Price
                    </Typography>
                    <Box className="inputFields space">
                      <InputBase
                        onChange={(e) => setPrc(Number(e.target.value))}
                        placeholder="Price"
                      />
                    </Box>

                    {/* <Box className="formItems buysell-radio">
                      <Box className="inputFields space fullWidth">
                        <RadioGroup
                          row
                          value={orderType}
                          onChange={(e) => setOrderType(e.target.value)}
                        >
                          <FormControlLabel
                            value="Market"
                            sx={{ marginRight: "1rem" }}
                            control={<Radio />}
                            label="Market"
                          />
                          <FormControlLabel
                            value="Limit"
                            sx={{ marginRight: "1rem" }}
                            control={<Radio />}
                            label="Limit"
                          />
                        </RadioGroup>
                      </Box>
                    </Box> */}
                  </Box>
                  <Box className="formItems">
                    <Typography
                      component={"label"}
                      className="label"
                      sx={{ fontSize: "1.4rem !important" }}
                    >
                      TradeType
                    </Typography>
                    <Box
                      className="inputFields space fullWidth"
                      sx={{
                        "& > .selectionDiv": {
                          padding: "0 !important",
                          marginTop: "0 !important",
                          border: "none !important",
                        },
                      }}
                    >
                      <Dropdown
                        val={drpValue}
                        value={prdType}
                        handleSelect={handlePrdChange}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className="buysell-footer">
              <Box className="margin">
                {/* <Typography component={'p'}>Margin ₹ 493.70 Charges ₹ 2.17</Typography> */}
                {/* <Button><RefreshIcon /></Button> */}
              </Box>

              <Box className="footer-btn">
                <Button
                  style={{ backgroundColor: "#2f80ed" }}
                  onClick={() => modifyTrade()}
                >
                  Save
                </Button>
                <Button
                  onClick={() => {
                    setBsopen(false);
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* change copytrade price */}
      <Dialog
        open={bsopen2}
        onClose={() => {
          setBsopen2(false);
        }}
        className={`commonModal buysellModal `}
      >
        <DialogContent sx={{ padding: "0 !important" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="tabs buysellModal-tabs">
              <Box
                className="tabBox"
                sx={{
                  border: "none !important",
                  padding: 1,
                  width: "auto !important",
                }}
              >
                <Box
                  className="formBox"
                  sx={{
                    border: "none",
                    display: "flex",
                    alignItems: "flex-start",
                    flexWrap: { xs: "wrap", md: "nowrap" },
                    marginTop: 1,
                  }}
                >
                  <Box className="formItems" sx={{ padding: "0 0.8rem" }}>
                    <Typography
                      component={"label"}
                      className="label"
                      sx={{ fontSize: "1.4rem !important" }}
                    >
                      Price
                    </Typography>
                    <Box className="inputFields space">
                      <InputBase
                        onChange={(e) => setPrc2(Number(e.target.value))}
                        placeholder="Price"
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className="buysell-footer">
              <Box className="footer-btn">
                <Button
                  style={{ backgroundColor: "#2f80ed" }}
                  onClick={() => handleSubmit()}
                >
                  Save
                </Button>
                <Button
                  onClick={() => {
                    setBsopen2(false);
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
