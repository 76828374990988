import { API_URL } from "../../config";
import * as actionTypes from "../actionTypes";
import axios from "axios";
import { generatePopup } from "../../utils/popup";

export const nseExchange = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");

    axios
      .post(`${API_URL}/auth/exchange/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.NSE_EXCHANGE_SUCCESS,
            payload: res.data,
          });
          resolve(res.data);
        } else {
          dispatch({
            type: actionTypes.NSE_EXCHANGE_FAIL,
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.NSE_EXCHANGE_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const futureExchange = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");

    axios
      .post(`${API_URL}/auth/futuresymbol/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.FUTURE_SYMBOL_SUCCESS,
            payload: res.data,
          });
          resolve(res.data);
        } else {
          dispatch({
            type: actionTypes.FUTURE_SYMBOL_FAIL,
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.FUTURE_SYMBOL_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const expiryExchange = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");

    axios
      .post(`${API_URL}/auth/expiry/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.EXPIRY_EXCHANGE_SUCCESS,
            payload: res.data,
          });
          resolve(res.data);
        } else {
          dispatch({
            type: actionTypes.EXPIRY_EXCHANGE_FAIL,
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.EXPIRY_EXCHANGE_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const optExpiryExchange = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");

    axios
      .post(`${API_URL}/auth/optexpiry/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.OPTION_EXPIRY_DATA,
            payload: res.data,
          });
          resolve(res.data);
        } else {
          dispatch({
            type: actionTypes.EXPIRY_EXCHANGE_FAIL,
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.EXPIRY_EXCHANGE_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const strikePriceExchange = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");

    axios
      .post(`${API_URL}/auth/strikeprice/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("res :>> ", res);
        if (res.status === 200) {
          dispatch({
            type: actionTypes.OPTION_STRIKE_DATA,
            payload: res.data,
          });
          resolve(res.data);
        } else {
          dispatch({
            type: actionTypes.STRIKEPRICE_EXCHANGE_FAIL,
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.STRIKEPRICE_EXCHANGE_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const addWatchListData = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");

    axios
      .post(`${API_URL}/position/watchlistupdate/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("res :>> ", res);
        if (res.status === 200) {
          dispatch({
            type: actionTypes.ADD_WATCH_LIST_SUCCESS,
          });
          generatePopup(
            "success",
            res.data.message || "Symbol added successfully"
          );
        } else {
          dispatch({
            type: actionTypes.ADD_WATCH_LIST_FAIL,
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.ADD_WATCH_LIST_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const getWatchListData = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");

    axios
      .get(`${API_URL}/position/watchlistupdate/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.GET_WATCH_LIST_SUCCESS,
            payload: res.data
          });

        } else {
          dispatch({
            type: actionTypes.GET_WATCH_LIST_FAIL,
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.GET_WATCH_LIST_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const deleteWatchListData = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");

    axios
      .delete(`${API_URL}/position/watchlistupdate/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.DELETE_WATCH_LIST_SUCCESS,
          });
          generatePopup(
            "success",
            res.data.message || "Symbol added successfully"
          );
        } else {
          dispatch({
            type: actionTypes.DELETE_WATCH_LIST_FAIL,
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.DELETE_WATCH_LIST_FAIL,
          });
        } else if (error?.response?.status === 401) {
          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};

export const getWatchListUpdateData = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: actionTypes.GET_WATCH_LIST_LIVE_DATA_SUCCESS,
      payload: payload,
    });
    resolve();
  });
};

export const deleteWatchList = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let token = localStorage.getItem("authToken");

    axios
      .delete(`${API_URL}/position/bulkdelete/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: payload,
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: actionTypes.DELETE_WATCHLIST_SUCCESS,
          });
          generatePopup(
            "success",
            res.data.message || "Symbols deleted successfully"
          );
        } else {
          dispatch({
            type: actionTypes.DELETE_WATCHLIST_FAIL,
          });

          generatePopup("error", res.data.message || "Symbols not deleted");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({
            type: actionTypes.DELETE_WATCHLIST_FAIL,
          });
        } else if (error?.response?.status === 401) {
          console.log("====eror", error);

          generatePopup("error", "Token is invalid or expired.");
          localStorage.clear();
          window.location.replace("/");
        }
      });
  });
};
