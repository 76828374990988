import { Box, Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import "./Broker.scss";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { mandotValidation } from "../../../validation/broker";
import {
  BrokerStatus,
  mandotBroker,
} from "../../../redux/actions/brokerAction";

const Mandot = (props) => {
  const inputValue = { appkey: "", secretkey: "" };
  const [fields, setFields] = useState(inputValue);
  const [isSubmit, setIsSubmit] = useState(false);
  const [seeOtp, setSeeOtp] = useState(false);
  const [Error, setError] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const brokerStatus = useSelector((state) => state.Broker.brokerstatus);

  const dateField = {
    width: {
      xs: "100% !important",
      md: "100% !important",
    },
    border: "1px solid #bcbcbc",
    borderRadius: {
      xs: "3px",
    },
  };

  //form input
  const handleChange = (event) => {
    let keyName = event.target.name;
    let keyValue = event.target.value;
    setFields((prevState) => ({
      ...prevState,
      [keyName]: keyValue,
    }));
    if (isSubmit) {
      setError(mandotValidation({ ...fields, [keyName]: keyValue }));
    }
  };

  const brokerStatus = useSelector((state) => state.Broker.brokerstatus);

  useEffect(() => {
    dispatch(BrokerStatus());
  }, []);

  useEffect(() => {
    if (brokerStatus?.length > 0) {
      console.log("brokerStatus[0] :>> ", brokerStatus[0]);
      if (brokerStatus[0]?.appkey) {
        setFields({
          ...fields,
          secretkey: brokerStatus[0]?.secretkey,
          appkey: brokerStatus[0]?.appkey,
        });
      }
    }
  }, [brokerStatus]);

  // dispatch data
  const handleSubmit = () => {
    console.log("submit");
    setIsSubmit(true);
    setError(mandotValidation(fields));
    let error = mandotValidation(fields);
    console.log("error", error);
    if (Object.keys(error) == 0) {
      dispatch(mandotBroker({ ...fields, brokername: "mandot" }, navigate));
    }
  };

  const inputBorder = {
    borderRadius: "5px",
    border: "1px solid #bcbcbc",
  };

  //   useEffect(() => {
  //     dispatch(BrokerStatus());
  //   }, []);

  // useEffect(() => {
  //     if (brokerStatus?.length > 0) {
  //         if (brokerStatus[0]?.appkey) {
  //             setFields({
  //                 ...fields,
  //                 uid: brokerStatus[0]?.uid,
  //                 appkey: brokerStatus[0]?.appkey,
  //                 dob: brokerStatus[0]?.dob,
  //             });
  //         }
  //     }
  // }, [brokerStatus]);

  return (
    <>
      <Box className="border">
        <Box>
          <Grid item xs={12} lg={12}>
            <Box className="formBox">
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  App Key :
                </Typography>
                <TextField
                  placeholder="Enter App Key..."
                  style={dateField}
                  value={fields?.appkey}
                  className="inputFiled"
                  name="appkey"
                  id="appkey"
                  onChange={handleChange}
                />
                {Error && <Box className="error">{Error.appkey}</Box>}
              </Box>
              <Box className="formItems" sx={{ marginBottom: 1.5 }}>
                <Typography component={"label"} className="label">
                  SECRET KEY :
                </Typography>
                <TextField
                  placeholder="Enter Secret Key..."
                  className="inputFiled"
                  style={inputBorder}
                  name="secretkey"
                  onChange={handleChange}
                  value={fields?.secretkey}
                />
                {Error ? <Box className="error">{Error.secretkey}</Box> : ""}
              </Box>

              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  className="btn solidButton fetchReport-btn"
                  sx={{ width: "100% !important" }}
                  onClick={handleSubmit}
                >
                  Login
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default Mandot;
