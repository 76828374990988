import { Box, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { dhanValidation } from "../../../validation/broker";
import {
  dhanBroker,
  getDhanSession,
} from "../../../redux/actions/brokerAction";

const Dhan = () => {
  let inputFields = { token: "" };
  const [fields, setFields] = useState(inputFields);
  const [isSubmit, setIsSubmit] = useState(false);
  const [Error, setError] = useState();
  const [sessionId, setSessionId] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dhanSession = useSelector((state) => state.Broker.dhanSessionId);

  useEffect(() => {
    dispatch(getDhanSession());
  }, []);

  useEffect(() => {
    setSessionId(dhanSession);
  }, [dhanSession]);

  const inputBorder = {
    borderRadius: "5px",
    border: "1px solid #bcbcbc",
  };

  useEffect(() => {
    if (sessionId?.sessionid) {
      window.location.replace(
        `https://auth.dhan.co/consent-login?consentId=${sessionId?.sessionid}`
      );
    }
  }, [sessionId]);

  const handleChange = (event) => {
    let keyName = event.target.name;
    let keyValue = event.target.value;
    setFields((prevState) => ({
      ...prevState,
      [keyName]: keyValue,
    }));
    if (isSubmit) {
      setError(dhanValidation({ ...fields, [keyName]: keyValue }));
    }
  };

  const handleSubmit = () => {};
  return (
    <>
      <Box className="border-ap" sx={{ marginTop: 2 }}>
        {/* <Box className="formItems" style={{ marginTop: 10 }}>
            <Typography component={"label"} className="label">
              Token :
            </Typography>
            <TextField
              placeholder="Enter Token.."
              className="inputFiled"
              style={inputBorder}
              name="token"
              onChange={handleChange}
            />
            {Error ? <Box className="error">{Error.token}</Box> : ""}
          </Box> */}

        {/* <Button
          className="btn solidButton fetchReport-btn"
          sx={{ width: "100% !important" }}
          onClick={handleSubmit}
        >
          Login
        </Button> */}
      </Box>
    </>
  );
};

export default Dhan;
