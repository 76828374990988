import React, { useEffect, useState } from "react";
import "./Signform.scss";
import { Box, Typography, Button, TextField, Grid } from "@mui/material";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import signimg from "../../images/signimg.png";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useDispatch } from "react-redux";
// import { NavLink } from 'react-router-dom';
import ReactFlagsSelect from "react-flags-select";

import {
  validateOtp,
  validateRegister,
  validateValues,
} from "../../validation/signin";
import {
  authSignup,
  phonecheck,
  sendOtp,
  verifyOtp,
} from "../../redux/actions/authActions";
import { generatePopup } from "../../utils/popup";
import moment from "moment";
import { country_list } from "./countryList";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";

export default function Signform() {
  // Main tab
  const [otp, setotp] = React.useState("block");
  const [signin, setsignin] = React.useState("none");
  const [signup, setsignup] = React.useState("none");
  const [counter, setCounter] = React.useState(40);
  const [allcustomer, setallcustomer] = React.useState("");
  const [countrycode, setCountryCode] = useState("IN");
  const [number, setNumber] = useState("");
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");
  const [readOnly, setReadOnly] = useState(false);
  let countNum = 120;
  const [count, setCount] = useState(countNum);
  const [numberError, setNumberError] = useState(false);
  const [otpcode, setOtpCode] = useState("");
  const [Error, setErr] = useState(false);
  const [fields, setFields] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const [fielderror, setFieldError] = useState(false);
  const [seeOtp, setSeeOtp] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let country_array = [];
  let country_label = {};
  let country_labelName = {};
  let array = Object.keys(country_list).map((code, index) => {
    const country = country_list[code];
    country_array.push(code);
    country_label[code] = "+" + country.phone;
    country_labelName[code] = " " + country.name;
  });

  // Dropdown tab
  const allcustomerdrp = (event) => {
    setallcustomer(event.target.value);
  };

  const data = useLocation();
  useEffect(() => {
    if (data?.state) {
      setNumber(data?.state?.phone);
      setFormattedPhoneNumber(data?.state?.formattedPhoneNumber);
    }
  }, [data?.state]);

  // input number
  const handleChange = (event) => {
    const { value } = event.target;
    const formattedValue = value.replace(/\D/g, "");
    let formattedNumber = formattedValue;
    setNumberError(false);
    // Format the phone number with a hyphen after 5 characters for display

    if (formattedValue.length > 5) {
      formattedNumber =
        formattedValue.slice(0, 5) + "-" + formattedValue.slice(5);
    }
    if (event.key === "Backspace") {
      // Handle backspace key
      if (formattedValue.length === 6 && formattedValue.charAt(5) === "-") {
        // Remove hyphen when deleting the 6th character
        formattedNumber = formattedValue.slice(0, 5);
      }
      if (formattedValue.length === 5) {
        // Remove last character when deleting the 5th character
        formattedNumber = formattedValue.slice(0, 4);
      }
    }
    if (formattedValue.length <= 10) {
      setFormattedPhoneNumber(formattedNumber);
      setNumber(formattedValue);
    }
  };

  const handleCount = () => {
    setCount(countNum);
  };

  //otp send
  const sendotp = (e, flag = true) => {
    e.preventDefault();
    if (validateValues(number) === true) {
      let obj = {
        phone: country_list[countrycode].phone + number,
        method: "text",
      };
      dispatch(
        phonecheck({ phone: country_list[countrycode].phone + number })
      ).then((res) => {
        if (res.status) {
          dispatch(sendOtp(obj)).then((res) => {
            if (flag) {
              if (res) {
                if (otp === "block") {
                  setotp("none");
                  setsignin("block");
                } else {
                  setotp("block");
                  setsignin("none");
                }
              }
            }
            setReadOnly(true);
            setCount(countNum);
            handleCount();
            generatePopup("success", "OTP sent successfully !");
          });
        } else {
          navigate("/signup", {
            state: {
              phone: number,
              formattedPhoneNumber: formattedPhoneNumber,
            },
          });
          return generatePopup("error", "Mobile number not registered");
        }
      });
    } else {
      setNumberError(validateValues(number));
      return generatePopup("error", "OTP not sent !");
    }
  };

  const changenumber = () => {
    if (otp === "none") {
      setotp("block");
      setsignin("none");
    } else {
      setotp("block");
      setsignin("none");
    }
  };

  //input otp
  const onHandleChangeOtp = (event) => {
    const keyName = event.target.name;
    let keyValue = event.target.value;

    keyValue = keyValue.replace(/\D/g, "");
    if (keyValue.toString().length <= 6) {
      setOtpCode(keyValue);
      setErr(validateOtp(keyValue));
    }
  };

  //verify otp
  const signinform = (e) => {
    e.preventDefault();
    if (!validateOtp(otpcode) === true) {
      let obj = {
        phone: country_list[countrycode].phone + number,
        otp: otpcode,
      };
      dispatch(verifyOtp(obj, navigate))
        .then((res) => {
          if (res?.response?.data?.status === "False") {
            navigate("/signup");
          }
        })
        .catch((error) => {
          console.log("otp not verify :>> ", error.message);
        });
    } else {
      setErr("Please enter valid OTP");
    }
  };

  //input BOD signinform
  const handleBirthChange = (stringValue) => {
    setFields({
      ...fields,
      DOB: moment(new Date(stringValue)).format("YYYY-MM-DD"),
    });
    if (isSubmit) {
      setFieldError(
        validateRegister({
          ...fields,
          DOB: moment(stringValue).format("YYYY-MM-DD"),
        })
      );
    }
  };

  // Countdown
  useEffect(() => {
    const interval = setInterval(() => {
      setCount((oldCount) => {
        if (oldCount === 1) {
          setReadOnly(false);
        }
        return Math.max(oldCount - 1, 0);
      });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Box className="login_layer"></Box>
      <Box className="signForm-bg">
        <Box className="signForm-menu">
          <NavLink to={"/"} className="logoLight">
            {/* <img src={logo} height={"100%"} width={"100%"} /> */}
          </NavLink>
          <Box>
            <NavLink component={"a"} to={"/"} className="signForm-btn">
              Sign In{" "}
            </NavLink>
            <NavLink component={"a"} to={"/signup"} className="signForm-btn">
              Sign Up{" "}
            </NavLink>
          </Box>
        </Box>

        <Typography component={"p"} className="welcome-text">
          Welcome to the world of algorithmic trading – where innovation meets
          financial markets.
        </Typography>
        <Box className="signForm-box">
          <Box className="sign_form_left">
            <img src={signimg} height={"100%"} width={"100%"} />
          </Box>

          <Box className="sign_form_right">
            <Box className="from-bg">
              <Typography component={"p"} className="form-text">
                Welcome Back! 👋
              </Typography>
              <Typography component={"p"} className="form-welcome">
                Continue to Signin
              </Typography>
              <Typography component={"h4"} className="form-text">
                Sign In
              </Typography>

              {/*Send OTP */}
              <Box
                className="contactForm formBox"
                sx={{ display: otp, border: "none !important" }}
              >
                <Box className="formItems">
                  {/* <Typography component={'label'} className='label'>Enter Mobile Number</Typography> */}
                  <Box sx={{ display: "flex" }}>
                    {/* <FormControl className='dropdown-ap' sx={{
                        '& > .MuiInputBase-root': {
                          maxWidth: '8rem  !important',
                          paddingRight: '2rem !important'
                        },
                        '& > .MuiInputBase-root > .MuiSelect-select': {
                          height: '100%',
                          width: '100%',
                          padding: '0.5rem !important',
                          display: 'flex',
                          alignItems: 'center',
                        },
                        '& > .MuiInputBase-root >.MuiSelect-nativeInput': {
                          height: '100%',
                          width: '100%'
                        },
                        maxWidth: '8rem !important',
                      }}>
                        <Select value={allcustomer} onChange={allcustomerdrp} displayEmpty className='dropdown border-ap'>
                          {Object.keys(country_list).map((countryCode, index) => {
                            const country = country_list[countryCode];
                            return (
                              <MenuItem key={countryCode} value={index === 0 ? '' : country}>+{country.phone}</MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl> */}
                    <Box
                      className="dropdown-ap"
                      sx={{
                        width: "10rem !important",
                        marginRight: 1,
                        "& >div.ReactFlagsSelect-module_flagsSelect__2pfa2 > button > span":
                          {
                            padding: 0,
                            height: "1.6rem",
                            fontSize: "1.3rem",
                          },
                        "& >div.ReactFlagsSelect-module_flagsSelect__2pfa2>ul":
                          { maxWidth: "12rem", width: "12rem" },
                      }}
                    >
                      <ReactFlagsSelect
                        selected={countrycode}
                        countries={country_array}
                        customLabels={country_label}
                        onSelect={(code) => {
                          setCountryCode(code);
                        }}
                        placeholder=" "
                      />
                    </Box>
                    <Box
                      className="inputFields space fullWidth filled"
                      sx={{ marginLeft: 0 }}
                    >
                      <TextField
                        type="text"
                        value={formattedPhoneNumber}
                        id="otpInput"
                        name="phone"
                        onChange={handleChange}
                        label="Mobile Number"
                      />
                    </Box>
                  </Box>
                  {numberError && <p className="color">{numberError}</p>}
                </Box>
                <Button
                  type="submit"
                  sx={{ marginTop: "2rem !important" }}
                  className="signForm-btn"
                  onClick={sendotp}
                >
                  Send OTP
                </Button>

                <Box className="accoutnHere">
                  <Typography component={"p"}>
                    Don't have an Account ?
                  </Typography>
                  <NavLink component={"a"} className="signIn_go" to={"/signup"}>
                    {" "}
                    Sign up
                  </NavLink>
                </Box>
              </Box>

              {/* Otp  */}
              <Box
                className="contactForm formBox"
                sx={{ display: signin, border: "none !important" }}
              >
                <Box className="sendOTP-alert">
                  <TaskAltIcon
                    sx={{ fontSize: "2.4rem" }}
                    className="checkIcon"
                  />
                  <Box sx={{ marginLeft: "1rem" }}>
                    <Typography component={"h5"}>
                      An OTP has been sent to your Number on{" "}
                      <Typography component={"span"}>
                        {" "}
                        {" +" + country_list[countrycode].phone + number}.
                      </Typography>
                    </Typography>
                    <Typography component={"p"}>
                      Plese enter the OTP below to continue.
                    </Typography>
                  </Box>
                </Box>

                <Box className="formItems" sx={{ position: "relative" }}>
                  <Box className="inputFields space fullWidth filled">
                    <Typography component={"label"} className="label">
                      Enter OTP
                    </Typography>
                    <Box className="resend_otp">
                      <TextField
                        type={seeOtp ? "number" : "password"}
                        value={otpcode}
                        id="otp"
                        onChange={onHandleChangeOtp}
                        label="Enter OTP"
                      />

                      <Box
                        sx={{ display: "flex", alignItems: "center" }}
                        className="actions_otp"
                      >
                        <Button
                          onClick={() => {
                            setSeeOtp(!seeOtp);
                          }}
                          className="otp_viewHide"
                        >
                          {seeOtp ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityRoundedIcon />
                          )}
                        </Button>
                        <Button
                          className="resend_otp_btn"
                          onClick={(e) => sendotp(e, false)}
                          disabled={readOnly}
                          style={{
                            color: readOnly === true ? "gray" : "#0a53b5",
                          }}
                        >
                          RESEND OTP
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                  {Error ? <p className="color">{Error}</p> : ""}
                  {readOnly && (
                    <Typography className={"countdown"}>
                      You can resend the OTP in{" "}
                      <Typography component={"span"}>{count}</Typography>{" "}
                      seconds.
                    </Typography>
                  )}
                  {/* <Button
                    className="signForm-btn resendOtp"
                    onClick={(e) => sendotp(e, false)}
                    disabled={readOnly}
                    style={{
                      color: readOnly === true ? "gray" : "white",
                      marginTop: 10,
                    }}
                  >
                    RESEND OTP
                  </Button> */}
                </Box>

                <Box
                  sx={{
                    display: { sm: "flex" },
                    alignItems: "center",
                    marginTop: 2,
                  }}
                >
                  <Button
                    type="submit"
                    className={
                      otpcode.length === 6 ? "signForm-btn" : "disabled-btn"
                    }
                    disabled={!(otpcode.length === 6)}
                    onClick={signinform}
                    sx={{ margin: "0.2rem !important" }}
                  >
                    Verify OTP
                  </Button>
                  <Button
                    type="submit"
                    className="signForm-btn secound"
                    onClick={changenumber}
                    sx={{ margin: "0.2rem !important" }}
                  >
                    Change Number
                  </Button>
                </Box>
              </Box>

              <Typography
                my={3}
                className="form-welcome"
                sx={{ fontSize: "22px !important" }}
              >
                Download Now!
              </Typography>
              <Box
                sx={{
                  display: "flex !important",
                  justifyContent: "center",
                  gap: 2,
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
                my={2}
              >
                <Box md={5}>
                  <Link
                    style={{
                      display: "flex",
                    }}
                    target="_blank"
                    to="https://play.google.com/store/apps/details?id=dotin.tradearth"
                  >
                    <Box
                      sx={{
                        height: "50px",
                        width: "160px",
                      }}
                    >
                      <img
                        style={{ objectFit: "fill" }}
                        src={require("../../images/image 30.png")}
                      />
                    </Box>
                  </Link>
                </Box>
                <Box md={5}>
                  <Link
                    style={{
                      display: "flex",
                    }}
                    target="_blank"
                    to="https://apps.apple.com/in/app/trade-arth/id6470361389"
                  >
                    <Box
                      sx={{
                        height: "50px",
                        width: "160px",
                      }}
                    >
                      <img
                        style={{ objectFit: "fill" }}
                        src={require("../../images/apple-app-store-logo.jpg")}
                      />
                    </Box>
                  </Link>
                </Box>
              </Box>

              {/* Sign Up Form */}
              {/* <Box
                className="contactForm formBox"
                sx={{ display: signup, border: "none !important" }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box className="inputFields space fullWidth filled">
                      <Typography component={"label"} className="label">
                        First name
                      </Typography>
                      <TextField
                        type="text"
                        placeholder="First Name"
                        className="inputFiled"
                        id="FirstName"
                        name="firstname"
                        value={fields?.firstname}
                        onChange={handleInputChange}
                      />
                      {fielderror && (
                        <span className="color">{fielderror.firstname}</span>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box className="inputFields space fullWidth filled">
                      <Typography component={"label"} className="label">
                        Last name
                      </Typography>
                      <TextField
                        type="text"
                        placeholder="Last Name"
                        className="inputFiled"
                        id="LastName"
                        name="lastname"
                        value={fields?.lastname}
                        onChange={handleInputChange}
                      />{" "}
                      {fielderror && (
                        <span className="color">{fielderror.lastname}</span>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="inputFields space fullWidth filled">
                      <Typography component={"label"} className="label">
                        E mail
                      </Typography>
                      <TextField
                        type="email"
                        placeholder="E-mail"
                        className="inputFiled"
                        id="Email"
                        name="email"
                        value={fields?.email}
                        onChange={handleInputChange}
                      />{" "}
                      {fielderror && (
                        <span className="color">{fielderror.email}</span>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="inputFields space fullWidth filled">
                      <Typography component={"label"} className="label">
                        Mobile Number
                      </Typography>
                      <Box sx={{
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                        <TextField
                          type="email"
                          placeholder="Enter Mobile Number"
                          className="inputFiled"
                          id="Email"
                          name="email"
                          value={fields?.number}
                          onChange={handleInputChange}
                        />{" "}
                        {fielderror && (
                          <span className="color">{fielderror.number}</span>
                        )}

                        <Button className="verify-btn">Verify</Button>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="inputFields space fullWidth filled">
                      <Typography component={"label"} className="label">
                        OTP
                      </Typography>
                      <TextField
                        type="email"
                        placeholder="Enter OTP"
                        className="inputFiled"
                        id="Email"
                        name="email"
                        value={fields?.otp}
                        onChange={handleInputChange}
                      />{" "}
                      {fielderror && (
                        <span className="color">{fielderror.otp}</span>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="formItems">
                      <Typography component={"label"} className="label">
                        Promo Code
                      </Typography>
                      <TextField
                        type="email"
                        placeholder="Enter Promo code"
                        className="inputFiled"
                        id="Email"
                        name="email"
                        value={fields?.promoCode}
                        onChange={handleInputChange}
                      />{" "}
                      {fielderror && (
                        <span className="color">{fielderror.promoCode}</span>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  className="signForm-btn"
                  sx={{ marginTop: "2rem !important" }}
                  onClick={handleSubmit}
                >
                  Sign Up
                </Button>
              </Box> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
