import * as React from "react";
import { useEffect } from "react";
import {
  Table,
  TableBody,
  Box,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import "./Table.scss";
import noData from "../../images/page_456.jpg";

export default function Dashboard(props) {
  const { type, handlePageChange, pageno, totalCount } = props;
  // Table pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (type === "openPosition") {
      handlePageChange(newPage);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    if (type === "openPosition") {
      handlePageChange(0);
    }
  };

  let total = 0;
  (rowsPerPage > 0
    ? props?.rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : props.rows
  )?.map((row) => {
    total += Number(row?.profit?.props?.children);
  });

  useEffect(() => {
    setPage(0);
    // if (type === "openPosition") {
    //   handlePageChange(0);
    // }
  }, [props?.rows?.length]);

  return (
    <>
      <TableContainer sx={{ MarginTop: 16, maxHeight: 440 }}>
        <Table
          stickyHeader
          sx={{ minWidth: "100%" }}
          className={`table tableData ${props.small}`}
        >
          {props?.rows?.length > 0 && (
            <TableHead>
              <TableRow>
                {/* <TableCell>Sr No.</TableCell> */}
                {props?.col?.map((colum, index) => (
                  <TableCell key={index}>{colum}</TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {props?.rows?.length > 0 ? (
              <>
                {(rowsPerPage > 0
                  ? props?.rows?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : props.rows
                )?.map((row, index) => (
                  <TableRow key={index}>
                    {/* <TableCell ><span>{index + 1}</span></TableCell> */}

                    {Object.keys(row)?.map((element, index) => (
                      <TableCell key={index}>{row[element]}</TableCell>
                    ))}
                  </TableRow>
                ))}
                {props?.type === "plreport" && (
                  <TableRow key={"new"}>
                    <TableCell key={"new1"} colSpan={5}></TableCell>
                    <TableCell key={"new1"}>
                      Total :{" "}
                      {total >= 0 ? (
                        <Typography component={"span"} className="up">
                          {total?.toFixed(2)}
                        </Typography>
                      ) : (
                        <Typography component={"span"} className="down">
                          {total?.toFixed(2)}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell key={"new1"} colSpan={5}></TableCell>
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow className="noHover">
                <TableCell colSpan={12}>
                  <Box className="no-data">
                    <img alt="no data" src={noData} />
                  </Box>
                  <Typography component={"p"} className="no-dataText">
                    There is no data available!
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        className="tablePagination"
        sx={{
          display: "flex",
          justifyContent: { xs: "center", md: "end" },
          padding: "0 1rem",
        }}
      >
        <TablePagination
          rowsPerPageOptions={
            type === "openPosition"
              ? [10]
              : [10, 20, 30, { label: "All", value: -1 }]
          }
          count={totalCount ? totalCount : props?.rows?.length}
          rowsPerPage={type === "openPosition" ? 10 : rowsPerPage}
          SelectProps={{
            fontSize: "1.6rem",
            native: false,
          }}
          page={type === "openPosition" ? pageno : page}
          className="tablePagination"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
}
