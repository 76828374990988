import * as actionTypes from "../actionTypes";

const initState = {
  liveProfitdetails: [],
  paperProfitdetails: [],
  error: "",
};

const store = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.LIVE_PROFIT_DETAILS_INIT:
      return {
        ...state,
        error: "",
      };
    case actionTypes.LIVE_PROFIT_DETAILS_SUCCESS:
      return {
        ...state,
        liveProfitdetails: action.payload,
        error: "",
      };
    case actionTypes.LIVE_PROFIT_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.PAPER_PROFIT_DETAILS_INIT:
      return {
        ...state,
        error: "",
      };
    case actionTypes.PAPER_PROFIT_DETAILS_SUCCESS:
      return {
        ...state,
        liveProfitdetails: action.payload,
        error: "",
      };
    case actionTypes.PAPER_PROFIT_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default store;
