export const zebullValidation = (inputValue) => {
  let isValid = {};
  if (inputValue.uid === "") {
    isValid.uid = `Uid is required`;
  }
  if (inputValue.password === "") {
    isValid.password = `Password is required`;
  }
  if (inputValue.dob === "") {
    isValid.dob = `DOB is required`;
  }
  if (inputValue.appkey === "") {
    isValid.appkey = `Appkey is required`;
  }
  return isValid;
};

export const iciciValidation = (inputValue) => {
  const isValid = {};
  if (inputValue.AppKey === "") {
    isValid.AppKey = "AppKey is required!";
  }
  if (inputValue.secretkey === "") {
    isValid.secretkey = "secretkey is required!";
  }
  if (inputValue.clientId === "") {
    isValid.clientId = "Client ID is required!";
  }
  return isValid;
};

export const mandotValidation = (inputValue) => {
  const isValid = {};
  if (inputValue.appkey === "") {
    isValid.appkey = "AppKey is required!";
  }
  if (inputValue.secretkey === "") {
    isValid.secretkey = "secretkey is required!";
  }

  return isValid;
};

export const angleValidation = (inputValue) => {
  let isValid = {};
  if (inputValue.uid === "") {
    isValid.uid = "User id is required!";
  }
  if (inputValue.password === "") {
    isValid.password = "password is required!";
  }
  if (inputValue.totp === "") {
    isValid.totp = "Totp is required!";
  }

  if (inputValue.apikey === "") {
    isValid.apikey = "Api key is required!";
  }
  return isValid;
};

export const finvasiaValidation = (inputValue) => {
  let isValid = {};
  if (inputValue.uid === "") {
    isValid.uid = "User id is required!";
  }
  if (inputValue.password === "") {
    isValid.password = "password is required!";
  }
  if (inputValue.totp === "") {
    isValid.totp = "Totp is required!";
  }

  return isValid;
};

export const goodwillValidation = (inputValue) => {
  let isValid = {};
  if (inputValue.uid === "") {
    isValid.uid = "User id is required!";
  }
  if (inputValue.password === "") {
    isValid.password = "password is required!";
  }
  if (inputValue.totp === "") {
    isValid.totp = "Totp is required!";
  }

  return isValid;
};

export const profitMartValidation = (inputValue) => {
  let isValid = {};
  if (inputValue.uid === "") {
    isValid.uid = "User id is required!";
  }
  if (inputValue.password === "") {
    isValid.password = "password is required!";
  }
  if (inputValue.totp === "") {
    isValid.totp = "Totp is required!";
  }
  if (inputValue.appkey === "") {
    isValid.appkey = "AppKey is required!";
  }
  return isValid;
};

export const expressoValidation = (inputValue) => {
  let isValid = {};
  if (inputValue.uid === "") {
    isValid.uid = "User id is required!";
  }
  if (inputValue.appkey === "") {
    isValid.appkey = "AppKey is required!";
  }
  if (inputValue.secretkey === "") {
    isValid.secretkey = "secretkey is required!";
  }

  return isValid;
};

export const rmoneyValidation = (inputValue) => {
  let isValid = {};

  if (inputValue.appkey === "") {
    isValid.appkey = "AppKey is required!";
  }
  if (inputValue.secretkey === "") {
    isValid.secretkey = "secretkey is required!";
  }
  if (inputValue.clientId === "") {
    isValid.clientId = "clientId is required!";
  }
  return isValid;
};

export const zerodhaValidation = (inputValue) => {
  let isValid = {};
  if (inputValue.Appkey === "") {
    isValid.Appkey = "App key is required!";
  }
  if (inputValue.secretkey === "") {
    isValid.secretkey = "Secret key is required!";
  }
  if (inputValue.uid === "") {
    isValid.uid = "Uid is required!";
  }

  return isValid;
};

export const paisaValidation = (inputValue) => {
  let isValid = {};
  if (inputValue.uid === "") {
    isValid.uid = "UserId is required!";
  }
  return isValid;
};

export const upstoxValidation = (inputValue) => {
  let isValid = {};
  if (inputValue.appkey === "") {
    isValid.appkey = "Appkey is required!";
  }
  if (inputValue.secretkey === "") {
    isValid.secretkey = "Secret Key is required!";
  }
  if (inputValue.clientId === "") {
    isValid.clientId = "Client Id is required!";
  }

  return isValid;
};

export const NuvamaValidation = (inputValue) => {
  let isValid = {};
  if (inputValue.appkey === "") {
    isValid.appkey = "App key is required!";
  }
  if (inputValue.secretkey === "") {
    isValid.secretkey = "Secret key is required!";
  }
  if (inputValue.clientId === "") {
    isValid.clientId = "Client Id is required!";
  }

  return isValid;
};

export const kotakValidation = (inputValue) => {
  console.log("inputValue :>> ", inputValue);
  let isValid = {};

  if (inputValue.apikey === "") {
    isValid.apikey = "Api key is required!";
  }
  if (inputValue.apisecret === "") {
    isValid.apisecret = "Secret Key is required!";
  }
  if (inputValue.password === "") {
    isValid.password = "Password is required!";
  }
  if (inputValue.password1 === "") {
    isValid.password1 = "Password1 is required!";
  }
  if (inputValue.mobileno === "") {
    isValid.mobileno = "Mobile no. is required!";
  }
  if (inputValue.username === "") {
    isValid.username = "Username is required!";
  }
  return isValid;
};
export const kotakOtpValidation = (inputValue) => {
  let isValid = {};

  if (inputValue.otp === "") {
    isValid.otp = "OTP is required!";
  } else if (inputValue.otp?.length !== 4) {
    isValid.otp = "OTP is invalid!";
  }
  return isValid;
};
// export const kotakValidation = (inputValue) => {
//   let isValid = {};

//   if (inputValue.uid === "") {
//     isValid.uid = "User id is required!";
//   }
//   if (inputValue.token === "") {
//     isValid.token = "Token is required!";
//   }
//   if (inputValue.consumerkey === "") {
//     isValid.consumerkey = "Consumer key should be 10 digits only!";
//   }
//   return isValid;
// };

export const aliceValidation = (inputValue) => {
  let isValid = {};
  if (inputValue.appkey === "") {
    isValid.appkey = "App Key is required!";
  }
  if (inputValue.uid === "") {
    isValid.uid = "UID is required!";
  }

  return isValid;
};

export const motilalValidation = (inputValue) => {
  let isValid = {};
  if (inputValue.uid === "") {
    isValid.uid = "User id is required!";
  }
  if (inputValue.appkey === "" || inputValue.appkey?.toUpperCase() === "NA") {
    isValid.appkey = "App Key is required!";
  }

  return isValid;
};

export const fyersValidation = (inputValue) => {
  let isValid = {};
  if (inputValue.uid === "") {
    isValid.uid = "User id is required!";
  }

  return isValid;
};

export const dhanValidation = (inputValue) => {
  let isValid = {};
  if (inputValue.token === "") {
    isValid.token = "Token is required!";
  }
  if (inputValue.clientId === "") {
    isValid.clientId = "Client Id is required!";
  }

  return isValid;
};

export const SharekhanValidation = (inputValue) => {
  let isValid = {};
  if (inputValue.appkey === "") {
    isValid.appkey = "App key is required!";
  }
  if (inputValue.secretkey === "") {
    isValid.secretkey = "Secret key is required!";
  }
  if (inputValue.uid === "") {
    isValid.uid = "Uid key is required!";
  }
  return isValid;
};

export const FlattradeValidation = (inputValue) => {
  let isValid = {};
  if (inputValue.appkey === "") {
    isValid.appkey = "App key is required!";
  }
  if (inputValue.secretkey === "") {
    isValid.secretkey = "Secret key is required!";
  }
  if (inputValue.clientId === "") {
    isValid.clientId = "Client Id is required!";
  }

  return isValid;
};
