import React from "react";
import {
  Grid,
  Box,
  Typography,
  Tooltip,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  TextField,
} from "@mui/material";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import CloseIcon from "@mui/icons-material/Close";
import Table from "../Table/Table";
import Yearltyreport from "./Yearlyreport";
import Fullreport from "./Fullreport";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const col = [
  "Year",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
  "Total",
  "Max Drawdown",
  "Days for MDD",
  "*R/MDD (Yearly)",
];
const rows = [
  {
    year: 2022,
    jan: (
      <Typography component={"span"} className="open">
        0
      </Typography>
    ),
    Feb: (
      <Typography component={"span"} className="open">
        0
      </Typography>
    ),
    mar: (
      <Typography component={"span"} className="open">
        0
      </Typography>
    ),
    apr: (
      <Typography component={"span"} className="open">
        0
      </Typography>
    ),
    may: (
      <Typography component={"span"} className="open">
        0
      </Typography>
    ),
    jun: (
      <Typography component={"span"} className="open">
        0
      </Typography>
    ),
    july: (
      <Typography component={"span"} className="open">
        17,132
      </Typography>
    ),
    aug: (
      <Typography component={"span"} className="close">
        -8,172
      </Typography>
    ),
    sept: (
      <Typography component={"span"} className="close">
        -5,178
      </Typography>
    ),
    oct: (
      <Typography component={"span"} className="close">
        -5,001
      </Typography>
    ),
    now: (
      <Typography component={"span"} className="open">
        9,144
      </Typography>
    ),
    dec: (
      <Typography component={"span"} className="open">
        7,923
      </Typography>
    ),
    total: (
      <Typography component={"span"} className="open">
        0
      </Typography>
    ),
    maxdrawdow: (
      <Typography component={"span"} className="close">
        -30,208
      </Typography>
    ),
    mddday: [
      <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
        100
      </Typography>,
      <Typography component={"p"} sx={{ fontSize: "1.2rem" }}>
        {" "}
        [09/15/2022 to 12/23/2022]
      </Typography>,
    ],
    mddyear: <Typography component={"span"}>0.66</Typography>,
  },
];

const col2 = [
  { id: "Index", title: "Index" },
  { id: "Entry Date", title: "Entry Date" },
  { id: "Entry Time", title: "Entry Time" },
  { id: "Exit Date", title: "Exit Date" },
  { id: "Exit Time", title: "Exit Time" },
  { id: "Type", title: "Type" },
  { id: "Strike", title: "Strike" },
  { id: "B/S", title: "B/S" },
  { id: "Qty", title: "Qty" },
  { id: "Entry Price", title: "Entry Price" },
  { id: "Exit Price", title: "Exit Price" },
  { id: "P/L", title: "P/L" },
];
const rows2 = [
  {
    index: 1,
    entrydate: "2022-08-08",
    entrytime: "9:35:00 AM",
    exitdate: "2022-08-08",
    exittime: "3:15:00 PM",
    type: "BD",
    strike: " ",
    bs: " ",
    qty: " ",
    entryprice: " ",
    exitprice: " ",
    pl: (
      <Typography component={"span"} className="close">
        3821.25
      </Typography>
    ),
  },
  {
    index: 2,
    entrydate: "2022-08-08",
    entrytime: "9:35:00 AM",
    exitdate: "2022-08-08",
    exittime: "3:15:00 PM",
    type: "CE",
    strike: "37800",
    bs: (
      <Typography component={"span"} className="down">
        Buy
      </Typography>
    ),
    qty: 15,
    entryprice: 303.25,
    exitprice: 558.0,
    pl: (
      <Typography component={"span"} className="close">
        3821.25
      </Typography>
    ),
  },
  {
    index: 3,
    entrydate: "2022-08-08",
    entrytime: "9:35:00 AM",
    exitdate: "2022-08-08",
    exittime: "3:15:00 PM",
    type: "XY",
    strike: " ",
    bs: " ",
    qty: " ",
    entryprice: " ",
    exitprice: " ",
    pl: (
      <Typography component={"span"} className="open">
        3821.25
      </Typography>
    ),
  },
];
export default function Reviewdeploy() {
  const [open2, setOpen2] = React.useState(false);
  const years = [
    "NFO",
    "Intraday",
    "EarnTheta",
    "Directional",
    "Bullish",
    "Bearish",
    "Trendfollowing",
    "Momentum",
    "EventBased",
  ];
  const segementList = [
    "NSECASH",
    "BANKNIFTY OPTION",
    "NIFTY OPTION",
    "FINNIFTY OPTION",
    "CURRENCY OPTION",
    "STOCK OPTION",
    "COMMODITY OPTION",
    "MIDNIFTY OPTION",
    "BSEINDEX OPTION",
    "STOCK FUT",
    "COMMODITY FUT",
    "CURRENCY FUT",
    "BANKNIFTY FUT",
    "NIFTY FUT",
    "FINNIFTY FUT",
    "BANKNIFTY OPTION SELLING",
    "NIFTY OPTION SELLING",
  ];
  const [year, setyear] = React.useState(["NFO", "Intraday"]);
  const [segment, setsegment] = React.useState(["NFO", "Intraday"]);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleChangeyear = (event) => {
    const {
      target: { value },
    } = event;
    setyear(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeSegement = (event) => {
    const {
      target: { value },
    } = event;
    setsegment(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  return (
    <>
      <Box className="review_deploy_box">
        <Typography component={"h4"} className="heading">
          Trade Overview
        </Typography>
        <Box className="overview_mainbox">
          <Box className="overview_item">
            <Typography component={"h6"}>
              Net Profit
              <Tooltip
                placement="top"
                arrow
                title={
                  <Typography sx={{ fontSize: "1.4rem" }}>Test</Typography>
                }
              >
                <Button>
                  <ErrorRoundedIcon />
                </Button>
              </Tooltip>
            </Typography>
            <Typography component={"p"} className="down">
              -19,860.75 INR -19.86%
            </Typography>
          </Box>
          <Box className="overview_item">
            <Typography component={"h6"}>
              Total CLosed Traders
              <Tooltip
                placement="top"
                arrow
                title={
                  <Typography sx={{ fontSize: "1.4rem" }}>Test</Typography>
                }
              >
                <Button>
                  <ErrorRoundedIcon />
                </Button>
              </Tooltip>
            </Typography>
            <Typography component={"p"}>100</Typography>
          </Box>
          <Box className="overview_item">
            <Typography component={"h6"}>
              Precent Portfolio
              <Tooltip
                placement="top"
                arrow
                title={
                  <Typography sx={{ fontSize: "1.4rem" }}>Test</Typography>
                }
              >
                <Button>
                  <ErrorRoundedIcon />
                </Button>
              </Tooltip>
            </Typography>
            <Typography component={"p"} className="up">
              38%
            </Typography>
          </Box>
          <Box className="overview_item">
            <Typography component={"h6"}>
              Profit Factor
              <Tooltip
                placement="top"
                arrow
                title={
                  <Typography sx={{ fontSize: "1.4rem" }}>Test</Typography>
                }
              >
                <Button>
                  <ErrorRoundedIcon />
                </Button>
              </Tooltip>
            </Typography>
            <Typography component={"p"} className="down">
              0.872
            </Typography>
          </Box>
          <Box className="overview_item">
            <Typography component={"h6"}>
              Max Drawdown
              <Tooltip
                placement="top"
                arrow
                title={
                  <Typography sx={{ fontSize: "1.4rem" }}>Test</Typography>
                }
              >
                <Button>
                  <ErrorRoundedIcon />
                </Button>
              </Tooltip>
            </Typography>
            <Typography component={"p"} className="down">
              55,441.50 INR
            </Typography>
          </Box>
          <Box className="overview_item">
            <Typography component={"h6"}>
              Avg Trade
              <Tooltip
                placement="top"
                arrow
                title={
                  <Typography sx={{ fontSize: "1.4rem" }}>Test</Typography>
                }
              >
                <Button>
                  <ErrorRoundedIcon />
                </Button>
              </Tooltip>
            </Typography>
            <Typography component={"p"} className="down">
              -191.61 INR -0.03%
            </Typography>
          </Box>
          <Box className="overview_item">
            <Typography component={"h6"}>
              Avg #Bars in Traders
              <Tooltip
                placement="top"
                arrow
                title={
                  <Typography sx={{ fontSize: "1.4rem" }}>Test</Typography>
                }
              >
                <Button>
                  <ErrorRoundedIcon />
                </Button>
              </Tooltip>
            </Typography>
            <Typography component={"p"}>153</Typography>
          </Box>
        </Box>

        <Box className="report_box">
          <Yearltyreport rows={rows} col={col} />
        </Box>

        <Typography component={"h4"} className="heading">
          Full Report
        </Typography>
        <Box className="report_box">
          <Fullreport rows={rows2} col={col2} />
          <Box
            sx={{
              marginBottom: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button className="solidButton back">Back</Button>
            <Button
              className="solidButton fetchReport-btn"
              sx={{
                maxWidth: "auto !important",
                width: "auto !important",
                margin: "0 1rem",
              }}
            >
              Download PDF
            </Button>
            <Button className="formSolid-btn" onClick={handleClickOpen2}>
              Save & Deploy
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Save & Deploy Modal */}
      <Dialog
        open={open2}
        onClose={handleClose2}
        className="commonModal save_deploy_modal"
        fullWidth
      >
        <Box className="modalHeader">
          <Typography component={"h4"}>Stretagy</Typography>
          <Button onClick={handleClose2} className="closeModal">
            <CloseIcon />
          </Button>
        </Box>
        <DialogContent sx={{ padding: "0" }} className="modalBody">
          <DialogContentText sx={{ padding: "0" }}>
            <Box className="save_deploy_box">
              <Typography component={"h4"}>Disclaimer</Typography>
              <Typography component={"p"}>
                Algo Edge Capital or I am not a SEBI registered investment or
                financial advisor. Don't deploy our strategies purely based on
                past performance only. We are not responsible for your profit or
                loss. Although this strategy is fully automated, you are advised
                to keep a track on your account to monitor any deviations or
                errors. As option selling involves market risks, Please consult
                your financial advisor before investing.
              </Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box
                  className="selectionDiv  select_check"
                  sx={{ marginLeft: 0, display: "block !important" }}
                >
                  <Typography
                    component={"label"}
                    className="label"
                    sx={{ marginBottom: "0.6rem !important", display: "block" }}
                  >
                    Add Tag
                  </Typography>
                  <FormControl className="dropdown-ap" fullWidth>
                    <Select
                      multiple
                      value={year}
                      onChange={handleChangeyear}
                      renderValue={(selected) => selected.join(", ")}
                      defaultValue={"Select year"}
                    >
                      {years.map((name, index) => (
                        <MenuItem key={index} value={name}>
                          <Checkbox checked={year.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  className="selectionDiv  select_check"
                  sx={{ marginLeft: 0, display: "block !important" }}
                >
                  <Typography
                    component={"label"}
                    className="label"
                    sx={{ marginBottom: "0.6rem !important", display: "block" }}
                  >
                    Segment
                  </Typography>
                  <FormControl className="dropdown-ap" fullWidth>
                    <Select
                      multiple
                      value={segment}
                      onChange={handleChangeSegement}
                      renderValue={(selected) => selected.join(", ")}
                      defaultValue={"Select year"}
                    >
                      {segementList.map((name, index) => (
                        <MenuItem key={index} value={name}>
                          <Checkbox checked={year.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className="inputFields space fullWidth">
                  <Typography component={"label"} className="label">
                    Capital Requirement
                  </Typography>
                  <TextField
                    placeholder="Capital Requirement"
                    className="inputFiled"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  component={"label"}
                  className="label"
                  sx={{ marginBottom: "0.6rem !important", display: "block" }}
                >
                  Entry Time
                </Typography>
                <Box className="selectionDiv">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="datePicker"
                      sx={{ maxWidth: "100%", width: "100%" }}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  component={"label"}
                  className="label"
                  sx={{ marginBottom: "0.6rem !important", display: "block" }}
                >
                  Exit Time
                </Typography>
                <Box className="selectionDiv">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="datePicker"
                      sx={{ maxWidth: "100%", width: "100%" }}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className="inputFields space fullWidth">
                  <Typography component={"label"} className="label">
                    Strategy Target{" "}
                  </Typography>
                  <TextField
                    placeholder="Strategy Target "
                    className="inputFiled"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className="inputFields space fullWidth">
                  <Typography component={"label"} className="label">
                    Strategy Stop loss
                  </Typography>
                  <TextField
                    placeholder="Strategy Stop loss"
                    className="inputFiled"
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className="inputFields space fullWidth">
                  <Typography component={"label"} className="label">
                    Discription
                  </Typography>
                  <TextField
                    placeholder="Discription"
                    multiline
                    rows={3}
                    className="inputFiled"
                  />
                </Box>
              </Grid>
            </Grid>

            <Box sx={{ display: { xs: "flex" }, marginTop: 2 }}>
              <Button
                onClick={handleClose2}
                className="modal-btn btn-danger"
                sx={{ marginRight: "0.5rem" }}
              >
                Discard
              </Button>
              <Button
                onClick={handleClose2}
                className="modal-btn btn-primary"
                sx={{ marginLeft: "0.5rem" }}
              >
                Confirm
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
