import React from "react";
import {
  Box,
  Typography,
  TextField,
  Grid,
  Button,
  Tooltip,
} from "@mui/material";

export default function profilesetting() {
  const edit = <Typography sx={{ fontSize: "1.4rem" }}>Edit</Typography>;
  return (
    <>
      <Box className="contactDetails-box">
        <Grid container spacing={2} sx={{ marginBottom: "2rem" }}>
          <Grid item xs={12}>
            <Typography component={"h2"}>Profile Setting</Typography>
          </Grid>
        </Grid>
        <Box className="border-ap" sx={{ padding: 2, width: "auto " }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Box className="inputFields space fullWidth space-y">
                <Typography component={"label"} className="label">
                  First Name
                </Typography>
                <TextField
                  placeholder="First Name"
                  type="text"
                  id="FirstName"
                />
              </Box>

              <Box className="inputFields space fullWidth space-y">
                <Typography component={"label"} className="label">
                  Last Name
                </Typography>
                <TextField placeholder="Last Name" type="text" id="LastName" />
              </Box>

              <Box className="inputFields space fullWidth space-y">
                <Typography component={"label"} className="label">
                  Email
                </Typography>
                <TextField placeholder="E-mail" type="text" id="Email" />
              </Box>

              <Box className="inputFields space fullWidth space-y">
                <Typography component={"label"} className="label">
                  Phone Number
                </Typography>
                <TextField placeholder="Phone" type="text" id="Phone" />
              </Box>

              <Box className="inputFields space fullWidth space-y">
                <Tooltip title={edit} arrow placement="top">
                  <Button className="fetchReport-btn solidButton">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      {" "}
                      <path
                        d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </Button>
                </Tooltip>
                <Button className="formSolid-btn" sx={{ marginLeft: 1 }}>
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
